import { render, staticRenderFns } from "./ProgramsettingsAdditionalQuestions.vue?vue&type=template&id=e9a4a448&scoped=true&"
import script from "./ProgramsettingsAdditionalQuestions.vue?vue&type=script&lang=js&"
export * from "./ProgramsettingsAdditionalQuestions.vue?vue&type=script&lang=js&"
import style0 from "./ProgramsettingsAdditionalQuestions.vue?vue&type=style&index=0&id=e9a4a448&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9a4a448",
  null
  
)

export default component.exports