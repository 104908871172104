/** this mixin is only extended by draw import */
import { sortBy, groupBy, find, pick } from "lodash";
// TODO tidy up lazy loading import XLSX from "xlsx";
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      files: [],
      selectedVenue: {},
      foundVenues: [],
      venues: [],
      accept:
        "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      extensions: "xlsx,xls,csv"
    };
  },
  methods: {
    reformSavingObject(doc) {
      // transform into saving object
      const match = {};
      const alterName = (doc.bye || doc.tba) && doc.bye ? "BYE" : "TBA";
      match.status = "pre-game";
      match.name = `${doc.homeTeam || alterName} vs ${doc.awayTeam || alterName}`;
      const homeTeam = this.competition.teams.find(x => x.name === doc.homeTeam);
      match.homeTeam = (homeTeam && pick(homeTeam, ["_id", "name"])) || undefined;
      const awayTeam = this.competition.teams.find(x => x.name === doc.awayTeam);
      match.awayTeam = (awayTeam && pick(awayTeam, ["_id", "name"])) || undefined;
      const venue = this.venues && this.venues.find(x => x._id === +doc.venueID);
      match.venue = (venue && pick(venue, ["_id", "name", "venueTimezone"])) || undefined;
      match.meta = {
        isBye: doc.bye || false,
        isTba: doc.tba || false,
        fieldNo: doc.fieldNo
      };
      match.competition = pick(this.competition, ["_id", "name"]);
      match.scores = { homeTeam: 0, awayTeam: 0 };
      match.round = {
        type: doc.roundType,
        displayName: `Round ${doc.roundNumber}`,
        number: +doc.roundNumber
      };
      match.scoringEvents = [];
      match.playerMovements = [];
      match.gameStates = [];
      match.dateTime = this.moment(`${doc.date} ${doc.time}`, "DD/MM/YYYY h:mm a").valueOf();
      return match;
    },
    reformImportedResult(objects) {
      // showing uploaded result part
      const games = {
        Regular: [],
        Final: []
      };
      // transform to showing results
      const displayMapping = doc => {
        const result = { ...doc };
        if (result.venueID) {
          const venue = find(this.venues, { _id: +result.venueID });
          result.venue = venue.name || "N/A";
        }
        result.bye = result.bye ? "Yes" : "No";
        result.tba = result.tba ? "Yes" : "No";
        result.dateTime = this.moment(`${result.date} ${result.time}`, "DD/MM/YYYY h:m a");
        games[result.roundType].push(result);
      };

      objects.forEach(displayMapping);

      Object.keys(games).forEach(type => {
        games[type] = groupBy(games[type], "roundNumber");
      });
      return games;
    },
    // import results formatter
    dateTimeFormatter(row) {
      if (row.dateTime && this.moment(row.dateTime).isValid()) {
        return this.moment(row.dateTime).format("DD/MM/YYYY h:mm A");
      }
      return "NA";
    },
    // venue events
    removeVenue(value) {
      const idx = this.venues.findIndex(x => x._id === value);
      this.venues.splice(idx, 1);
    },
    selectedVenueChange(value) {
      const venue = this.foundVenues.find(x => x._id === value);
      this.venues.push(venue);
    },
    filterVenue(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then(response => {
              this.foundVenues = response.data.data;
            })
            .catch(() => {
              this.$customError();
            });
        }, 200);
      } else {
        this.foundVenues = [];
      }
    },
    getMinRedoRound(comp, matches, type = "Regular") {
      matches = matches.filter(x => x.round.type === type);
      matches = sortBy(matches, "round.number");
      const currentRoundIndex = matches.findIndex(
        x =>
          x.status === "pre-game" &&
          (!x.scoringEvents || !x.scoringEvents.length) &&
          (!x.playerMovements || !x.playerMovements.length)
      );

      if (currentRoundIndex >= 0) {
        // Found current round number by match condition (status: pre-game, no scoringEvents, no playerMovements)
        let currentRound = matches[currentRoundIndex].round.number;
        // Check if previous round has no match, change current round to previous round (For cases if delete matches between rounds)
        while (currentRound > 1) {
          // eslint-disable-next-line no-loop-func
          const previousRoundMatch = matches.filter(x => x.round.number === currentRound - 1);
          if (previousRoundMatch.length) break;
          else {
            // Previous round has no match, set current round to previous round
            currentRound -= 1;
          }
        }

        return currentRound;
      }

      /* For case if not found current round by previous logic */
      // No match in comp, return round 1
      if (!matches.length) {
        return 1;
      }

      // Find last match
      const lastMatch = matches[matches.length - 1];
      if (lastMatch) {
        const lastRound = lastMatch.round.number;
        if (lastRound === comp.regularSeasonRounds) {
          // All rounds completed
          return -1;
        }

        // Get current round by last round + 1 (For cases if delete all future matches)
        return lastRound + 1;
      }

      return 0;
    },
    processExcel(file) {
      if (file.length) {
        if (
          ![
            "text/csv",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ].includes(file[0].type)
        ) {
          this.$customError("Invalid file format. It should be .xls, .xlxs.");
          return new Promise(res => res(null));
        }

        return new Promise(res => {
          const reader = new FileReader();
          reader.onload = e => {
            // pre-process data
            let binary = "";
            const bytes = new Uint8Array(e.target.result);
            const length = bytes.byteLength;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i]);
            }

            /* read workbook */
            const wb = XLSX.read(binary, { type: "binary" });

            /* grab first sheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* generate JSON */
            const objects = XLSX.utils.sheet_to_json(ws, {
              raw: false,
              header: [
                "roundNumber",
                "roundType",
                "homeTeam",
                "awayTeam",
                "venueID",
                "fieldNo",
                "date",
                "time",
                "bye",
                "tba"
              ]
            });
            // remove header
            objects.shift();
            res(objects);
          };
          reader.readAsArrayBuffer(file[0].file);
        });
      }
      return new Promise(res => res(null));
    }
  },
  computed: {
    startTime: {
      get() {
        return (
          (this.competition &&
            this.competition.drawbuilder.defaultStartTime &&
            this.moment(this.competition.drawbuilder.defaultStartTime, "h:m").format("LT")) ||
          "N/A"
        );
      }
    },
    regularRounds: {
      get() {
        return (this.competition && this.competition.regularSeasonRounds) || 0;
      }
    },
    finalRounds: {
      get() {
        return (this.competition && this.competition.finalSeasonRounds) || 0;
      }
    }
  }
};
