<template>
  <div>
    <div>
      <div class="tw-flex tw-justify-center tw-w-full">
        <img
          v-if="currentLogo"
          :src="currentLogo"
          @error="displayPlaceholder"
          alt=""
          class="tw-w-24"
        />
        <img v-else src="~@/assets/shield_black_small.svg" alt="" class="avatar" />
      </div>

      <div class="tw-flex tw-justify-center tw-w-full tw-px-1">
        <vue-upload-component
          v-if="this.$route.params.type !== 'insert'"
          ref="upload"
          :drop="true"
          v-model="files"
          :extensions="extensions"
          :accept="accept"
          :post-action="uploadURL"
          :headers="uploadHeaders"
          :data="entityData"
          :multiple="true"
          @input-file="inputFile"
          @input-filter="inputFilter"
          class="el-button el-button--default upload"
        >
          Upload
        </vue-upload-component>
      </div>
    </div>
    <el-form v-if="program.type">
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-py-3">
        <el-col :span="6">
          <el-form-item label="Program Type">
            <el-input
              autocomplete="off"
              v-model="program.type"
              placeholder="Select a program type"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="isActive" label="Active">
            <el-select v-model="program.isActive">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="isContact" label="Contact">
            <el-select v-model="program.isContact">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="isVisibleToAllRegions" label="Visible to all regions">
            <el-select v-model="program.isVisibleToAllRegions">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col class="submit-action" :span="12">
          <el-button @click="updateSettingsStatus" type="success">Submit</el-button>
        </el-col>
      </div>
    </el-form>
    <el-tabs type="card">
      <el-tab-pane label="Fees" key="Fees">
        <products @update-program="updateProgram" />
      </el-tab-pane>
      <el-tab-pane label="Saleable Items" key="Items">
        <saleable-items :program="program" />
      </el-tab-pane>
      <el-tab-pane label="Additional Questions" key="Questions">
        <AdditionalQuestions :program="program" />
      </el-tab-pane>
      <el-tab-pane label="Discount Codes" v-if="isSuperAdmin" key="Codes">
        <DiscountsCode :program="program" />
      </el-tab-pane>
      <el-tab-pane
        label="Visibility"
        key="Visibility"
        v-if="
          isSuperAdmin &&
          (program.isVisibleToAllRegions === false || program.isVisibleToAllRegions === undefined)
        "
      >
        <GameDevRegionList :program="program" />
        <set-club-visibility :program="program" />
      </el-tab-pane>
      <el-tab-pane label="Assets" v-if="isSuperAdmin" key="assets">
        <Assets :program="program" />
      </el-tab-pane>
      <el-tab-pane label="Communications" v-if="isSuperAdmin" key="communications">
        <Communications :program="program" />
      </el-tab-pane>
      <el-tab-pane label="Financials" v-if="isSuperAdmin" key="financials">
        <Financials :program="program" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import Products from "./ProgramsettingsProducts.vue";
import SaleableItems from "./ProgramsettingsSaleableItems.vue";
import AdditionalQuestions from "./ProgramsettingsAdditionalQuestions.vue";
import DiscountsCode from "./DiscountsCode.vue";
import GameDevRegionList from "./GameDevRegionsList.vue";
import SetClubVisibility from "./SetClubVisibility.vue";
import Assets from "./ProgramsettingsAssets.vue";
import { getS3URL } from "../../utils/utilities";
import { uploadURL } from "../../utils/constants";
import Communications from "./ProgramSettingsComms.vue";
import Financials from "./ProgramsettingsFinancials.vue";

export default {
  name: "ProgramsettingsForm",
  components: {
    Products,
    SaleableItems,
    AdditionalQuestions,
    DiscountsCode,
    GameDevRegionList,
    SetClubVisibility,
    Assets,
    Communications,
    Financials,
  },
  data() {
    return {
      program: {
        type: undefined,
        options: [],
        saleableItems: [],
        additionalQuestions: [],
        isActive: null,
        isContact: null,
        isVisibleToAllRegions: null,
        meta: {
          avatar: "",
        },
        assets: [],
      },
      isSuperAdmin: this.$store.getters["user/isSuperAdmin"],
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png",
      files: [],
      type: null,
    };
  },
  methods: {
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    updateProgram(val) {
      _.assign(this.program, val);
    },
    updateSettingsStatus() {
      const { isActive, _id, isContact, isVisibleToAllRegions } = this.program;
      this.$store.commit("root/loading", true);
      const url = `/nrl/api/v1/admin/programs-settings/${_id}`;
      this.$http
        .put(url, { isActive, isContact, isVisibleToAllRegions })
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response.status === "success") {
          this.$store.commit("root/loading", false);
          if (this.program && this.type === null) this.type = "update";
          this.program.meta.avatar = newFile.response.url;

          if (this.type === "update") {
            const { avatar } = this.program.meta;
            const { _id } = this.program;

            this.$store.commit("root/loading", true);
            const update = { meta: { avatar } };

            const url = `/nrl/api/v1/admin/programs-settings/${_id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        } else {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }
      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
  },
  computed: {
    currentLogo() {
      return this.$route.params.id ? getS3URL(this.$route.params.id) : false;
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    uploadURL() {
      return uploadURL;
    },
    entityData() {
      return {
        entity_type: "programSettings",
        entity_id: this.$route.params.id,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.submit-action {
  float: right;
  width: auto;
}

.el-select {
  width: 100%;
}
</style>
