<template>
  <div class="member-search">
    <el-row class="user-profile">
      <el-row :gutter="10" class="box-card">
        <div class="avatar-wrap">
          <div :style="{ 'background-image': `url(${currentHeadshot})` }" alt class="avatar"></div>
        </div>
        <el-form
          v-if="currentMember._id"
          :model="currentMember"
          ref="verification"
          :rules="verificationRules"
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <h3 class="tw-font-bold">Member Details</h3>
            </el-col>
            <el-col :style="{ 'text-align': 'right' }" :span="12">
              <el-button id="editDetails" @click="handleEdit(currentMember._id)">Edit</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="NRLID">
                <el-input autocomplete="off" type="text" v-model="currentMember._id" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="First Name">
                <el-input
                  autocomplete="off"
                  type="text"
                  v-model="currentMember.profile.firstName"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Last Name">
                <el-input
                  autocomplete="off"
                  type="text"
                  v-model="currentMember.profile.lastName"
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="Email">
                <el-input
                  autocomplete="off"
                  type="text"
                  v-model="currentMember.profile.email"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Mobile">
                <el-input
                  autocomplete="off"
                  type="text"
                  v-model="currentMember.profile.mobile"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="DOB">
                <el-input
                  autocomplete="off"
                  type="text"
                  :value="dateFormatter(currentMember)"
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="Address Line 1">
                <el-input
                  autocomplete="off"
                  type="text"
                  v-model="currentMember.profile.address.addressLine1"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Address Line 2">
                <el-input
                  autocomplete="off"
                  type="text"
                  v-model="currentMember.profile.address.addressLine2"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Suburb">
                <el-input
                  autocomplete="off"
                  type="text"
                  v-model="currentMember.profile.address.suburb"
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="Postcode">
                <el-input
                  autocomplete="off"
                  type="text"
                  v-model="currentMember.profile.address.postcode"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="State">
                <el-input
                  autocomplete="off"
                  type="text"
                  v-model="currentMember.profile.address.state"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Registration Paid">
                <el-input
                  autocomplete="off"
                  type="text"
                  :value="
                    currentMember.registration && currentMember.registration[0].paid ? 'Yes' : 'No'
                  "
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row class="tw-pt-6 document-list">
            <h3 class="tw-font-bold">Documents</h3>
            <el-divider></el-divider>
            <el-table
              empty-text="No documents were uploaded"
              :data="currentMember.documents"
              layout="table"
              class="data-table"
            >
              <el-table-column fixed prop="_id" label="Id"></el-table-column>
              <el-table-column fixed prop="docType" label="Document Type"></el-table-column>
              <el-table-column fixed prop="id">
                <template slot-scope="scope">
                  <div style="text-align: center">
                    <el-button
                      class="operation-button"
                      type="primary"
                      icon="el-icon-search"
                      size="small"
                      @click="goToDocument(scope.row._id)"
                      >View</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-row>

          <el-row v-if="currentMember">
            <h3 class="tw-font-bold">Status</h3>
            <el-form-item v-if="currentMember.verification.declinedBy" label="Declined On">
              <span>{{ getFormattedDate(currentMember.verification.declinedAt) }}</span>
            </el-form-item>
            <el-divider></el-divider>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="Verification Status">
                  <small v-if="currentMember.clubAccessPendingRego"
                    >Registration to be approved by Club or League</small
                  >
                  <small v-else>Registration to be approved by League only</small>
                  <el-select
                    class="status"
                    placeholder="Select a status"
                    :disabled="
                      clearance === roles.clubAdmin && !currentMember.clubAccessPendingRego
                    "
                    v-model="currentMember.verificationStatus"
                  >
                    <el-option v-for="item in statuses" :key="item" :value="item" :label="item" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  prop="verification.declinedReason"
                  v-if="currentMember.verificationStatus === 'Declined'"
                  label="Decline Reason"
                >
                  <el-select
                    class="status"
                    placeholder="Select a status"
                    v-model="currentMember.verification.declinedReason"
                  >
                    <el-option
                      v-for="option in declinedReasons"
                      :key="option"
                      :value="option"
                      :label="option"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item prop="verification.notes" label="Verification Notes">
                <el-input
                  autocomplete="off"
                  type="textarea"
                  id="verificationNotes"
                  placeholder="Verification Notes"
                  v-model="currentMember.verification.notes"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                prop="confirmVerfication"
                v-if="currentMember.verificationStatus === 'Approved'"
              >
                <el-checkbox v-model="confirmVerification"
                  >Please confirm that this record has been verified and all documents submitted are
                  valid</el-checkbox
                >
              </el-form-item>
            </el-row>
          </el-row>
        </el-form>
      </el-row>
    </el-row>
    <el-row v-if="currentMember">
      <action-buttons
        class="text-left"
        submit
        cancel
        @submit="submitVerification()"
        @cancel="$router.push('/pending-registrations')"
      />
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, roles, memberTypes } from "../../utils/constants";

export default {
  name: "PendingVerification",
  components: { ActionButtons },
  data() {
    const notesValidator = (rule, value, callback) => {
      const { verificationStatus, verification } = this.currentMember;
      const declinedReason = verification.declinedReason || "";
      if (verificationStatus === "Declined" && !value && declinedReason.includes("Other")) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };

    const confirmValidator = (rule, value, callback) => {
      const { verificationStatus } = this.currentMember;
      const { confirmVerification } = this;
      if (verificationStatus === "Approved" && !confirmVerification) {
        return callback(new Error(errormsg.verification_confirm));
      }
      return callback();
    };

    return {
      // currentMember: {
      //   verification: {
      //     declinedReason: undefined,
      //   },
      // },
      currentMember: {
        verificationStatus: null,
        verification: {
          notes: null,
          declinedReason: null
        }
      },
      statuses: ["Approved", "Pending", "Declined"],
      declinedReasons: [
        "Existing Participant record",
        "Did not fulfill the registration criteria - Document Missing",
        "Did not fulfill the registration criteria - Photo Missing",
        "Registration capacity reached",
        "Participant no longer wishes to register"
        // "Other (Additional notes required)",
      ],
      verificationRules: {
        "verification.declinedReason": {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        },
        "verification.notes": {
          validator: notesValidator,
          trigger: "blur"
        },
        confirmVerfication: {
          validator: confirmValidator,
          trigger: "blur"
        }
      },
      confirmVerification: false,
      roles
    };
  },
  beforeMount() {
    const { member } = this.$route.params;
    if (!member || !member._id) {
      this.$router.push("/pending-registrations");
    }
    this.currentMember = _.merge(this.currentMember, member);
  },
  methods: {
    hasPhotoFormatter(row) {
      return row && row.profile.meta && row.profile.meta.avatar ? "Yes" : "No";
    },
    hasDocumentsFormatter(row) {
      return row && row.documents && row.documents.length > 0 ? "Yes" : "No";
    },
    clubNameFormatter(row) {
      return row.registration
        ? _.uniq(_.filter(_.map(row.registration, "club.name"), "length")).join(", ")
        : "N/A";
    },
    memberTypeFormatter(row) {
      return row.registration
        ? row.registration
            .filter(mt => mt.memberType !== "program")
            .map(mt => {
              const found = _.find(memberTypes, m => m.type === mt.memberType);
              return found && found.name;
            })
            .join(", ")
        : "N/A";
    },
    getFormattedDate(timestamp) {
      return this.moment(timestamp).format("DD-MM-YYYY");
    },
    dateFormatter(row) {
      return row.profile.dob
        ? this.moment(row.profile.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        : "NA";
    },
    statusFormatter(row) {
      if (row.verification.declinedBy) {
        return "Declined";
      }
      if (row.verification.approvedBy) {
        return "Approved";
      }
      return "Pending";
    },
    filterStatus(value, row) {
      let status = "Pending";
      if (row.verification.declinedBy) {
        status = "Declined";
      }
      if (row.verification.approvedBy) {
        status = "Approved";
      }
      return status === value;
    },
    submitVerification() {
      return new Promise((resolve, reject) => {
        this.$refs.verification.validate(valid => (valid ? resolve() : reject()));
      })
        .then(() => {
          const { verification, _id, verificationStatus } = this.currentMember;
          this.$store.commit("root/loading", true);

          if (verificationStatus === "Approved") {
            this.$confirm(
              "Are you sure you want to mark this member record as APPROVED? - This cannot be undone",
              "Warning",
              {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning"
              }
            )
              .then(() => {
                this.$store.commit("root/loading", true);
                const url = `/nrl/api/v1/admin/members/pending-registrations/${_id}/approve`;
                this.$http
                  .post(url, { verification })
                  .then(() => {
                    this.$store.commit("root/loading", false);
                    this.$customSuccess();
                    this.$router.push("/pending-registrations");
                  })
                  .catch(error => {
                    this.$store.commit("root/loading", false);
                    this.$customError(_.get(error, "response.data.message"));
                  });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
              });
          }

          if (verificationStatus === "Declined") {
            this.$confirm(
              "Are you sure you want to mark this member record as DECLINED? - This cannot be undone",
              "Warning",
              {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning"
              }
            )
              .then(() => {
                this.$store.commit("root/loading", true);
                const url = `/nrl/api/v1/admin/members/pending-registrations/${_id}/decline`;
                this.$http
                  .post(url, { verification })
                  .then(() => {
                    this.$store.commit("root/loading", false);
                    this.$customSuccess();
                    this.$router.push("/pending-registrations");
                  })
                  .catch(error => {
                    this.$store.commit("root/loading", false);
                    this.$customError(_.get(error, "response.data.message"));
                  });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
              });
          }

          if (verificationStatus === "Pending") {
            const url = `/nrl/api/v1/admin/members/pending-registrations/${_id}/update`;
            this.$http
              .post(url, { verification })
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(error => {
                this.$store.commit("root/loading", false);
                this.$customError(_.get(error, "response.data.message"));
              });
          }
        })
        .catch(() => {});
    },
    getStatus(verification) {
      const { pending, declinedReason } = verification;
      return pending ? (declinedReason ? "Declined" : "Pending") : "Approved";
    },
    goToDocument(id) {
      const { _id: memberId } = this.currentMember;
      this.$store.commit("root/loading", true);
      this.$http
        .get(
          `${this.$store.getters["root/apiUrl"]}/nrl/api/v1/admin/documents/${id}?memberId=${memberId}`
        )
        .then(response => {
          window.open(response.data.url, "_blank");
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    },
    handleRowClick(row) {
      const member = this.tableData.find(user => user._id === row._id);
      this.currentMember = Object.assign(
        {
          verificationStatus: member.verification.declinedBy ? "Declined" : "Pending"
        },
        member
      );
    },
    handleEdit(id) {
      this.$router.push({
        name: "members.update",
        params: {
          type: "update",
          id: id
        }
      });
    }
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    currentHeadshot() {
      const avatar = _.get(this.currentMember, "profile.meta.avatar");
      if (avatar) return avatar;
      return require("@/assets/player_placeholder.png");
    }
  },
  watch: {
    "currentMember.verificationStatus": function(status) {
      if (status !== "Declined") this.currentMember.verification.declinedReason = null;
      if (status === "Approved") this.currentMember.verification.pending = false;
      else {
        this.currentMember.verification.pending = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.text-left {
  text-align: left !important;
}
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}

.el-pagination {
  margin-top: 1rem !important;
}

.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.el-select,
.el-input {
  width: 100%;
}

.btn-container {
  text-align: right;
}

.go-back-button {
  float: left;
}

.avatar-wrap {
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .avatar {
    background-size: contain;
    background-position: center;
    height: 8rem;
  }
}
.box-card {
  padding: 1rem;
  text-align: left;

  .document-list {
    margin-bottom: 2rem;
  }
}
</style>
