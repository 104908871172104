<template>
  <div>
    <el-row type="flex" align="bottom" :gutter="10">
      <el-col :span="8">
        <el-form :model="setting" label-position="top" :rules="settingRules" ref="settingRules">
          <el-form-item prop="name" label="Competition Type - Title">
            <el-input
              autocomplete="off"
              placeholder="Enter competition type"
              v-model="setting.name"
              :disabled="!isSuperAdminNRL"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" align="bottom" :gutter="10">
      <el-col :span="16">
        <el-form :model="setting" label-position="top" :rules="settingRules" ref="settingRules">
          <el-form-item prop="description" label="Description">
            <el-input
              autocomplete="off"
              placeholder="Enter Description"
              v-model="setting.description"
              :disabled="!isSuperAdminNRL"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12" class="details">
        <el-form :model="setting" label-position="top" :rules="settingRules" ref="settingRules">
          <el-form-item prop="lawsOfGameUrl" label="Laws of Game">
            <el-input
              autocomplete="off"
              placeholder="Enter Laws of Game URL"
              v-model="setting.lawsOfGameUrl"
              :disabled="!isSuperAdminNRL"
              ><template slot="prepend">https://</template></el-input
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" align="bottom" :gutter="10" class="details">
      <el-col :span="24">
        <el-form :model="setting" label-position="top" :rules="settingRules" ref="settingRules">
          <el-form-item prop="associations" label="Associations who can use this type">
            <el-select
              v-model="selectedAssociations"
              filterable
              multiple
              placeholder="Select associations"
              :disabled="!isSuperAdminNRL || setting.isAllowedAllAssociations"
            >
              <el-option
                v-for="association in allAssociations"
                :label="association.name"
                :key="association._id"
                :value="association._id"
              >
              </el-option>
            </el-select>
            <el-checkbox v-model="setting.isAllowedAllAssociations" :disabled="!isSuperAdminNRL">
              Allow all associations
            </el-checkbox>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" align="bottom" :gutter="10" class="details">
      <el-form :model="setting" label-position="top" :rules="settingRules" ref="settingRules">
        <el-col :span="6">
          <el-form-item prop="isAllowedToScore" label="Allow Scores?">
            <el-select v-model="setting.isAllowedToScore" :disabled="!isSuperAdminNRL">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="isAllowedToUseLadders" label="Allow ladders?">
            <el-select v-model="setting.isAllowedToUseLadders" :disabled="!isSuperAdminNRL">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="isAllowedToUseFinals" label="Allow Finals?">
            <el-select v-model="setting.isAllowedToUseFinals" :disabled="!isSuperAdminNRL">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="isAllowedToDisplayLaddersAndResults"
            label="Allow Results and Ladders to Display?"
          >
            <el-select
              v-model="setting.isAllowedToDisplayLaddersAndResults"
              :disabled="!isSuperAdminNRL"
            >
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form :model="setting" label-position="top" :rules="settingRules" ref="settingRules">
            <el-form-item
              prop="isAllowedToUseAdditionalDetails"
              label="Allowed to use Additional Details?"
            >
              <el-select
                v-model="setting.isAllowedToUseAdditionalDetails"
                :disabled="!isSuperAdminNRL"
              >
                <el-option label="Yes" :value="true" />
                <el-option label="No" :value="false" />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-form>
    </el-row>
    <el-row type="flex" align="bottom" :gutter="10">
      <el-col :span="12">
        <el-form :model="setting" label-position="top" :rules="rules" ref="settingRules">
          <el-form-item prop="ageLevelSettings" label="Age Levels">
            <el-select
              v-model="selectedAgeLevels"
              filterable
              placeholder="Select Age Levels"
              :disabled="!isSuperAdminNRL"
              multiple
            >
              <el-option
                v-for="item in ageLevels.map((l) => l.name)"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" align="bottom" :gutter="10">
      <el-col :span="12">
        <el-form :model="setting" label-position="top" :rules="rules" ref="settingRules">
          <el-form-item prop="competitionSettings" label="Team List Types">
            <el-select
              v-model="selectedTeamListTypes"
              filterable
              placeholder="Select Team List Types"
              :disabled="!isSuperAdminNRL"
              multiple
            >
              <el-option
                v-for="competitionType in competitionSettings"
                :label="competitionType.name"
                :key="competitionType._id"
                :value="competitionType._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="6">
        <el-button @click="viewTeamListType">View Team List Type</el-button>
      </el-col>
    </el-row>
    <action-buttons
      submit
      cancel
      @submit="updateCompetitionTypes()"
      @cancel="cancelForm()"
      v-if="isSuperAdminNRL"
    />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { ageLevels } from "../../utils/constants";
import { errormsg, roles } from "../../utils/constants";

export default {
  name: 'CompetitionsForm',
  components: { ActionButtons },
  props: {},
  data () {
    const numberValidator = (_, value, callback) => {
      const existingNumbers = this.setting.details.map(p => p.number)
      if (!parseInt(value) || parseInt(value) < 0 || value % 1 !== 0) {
        return callback(new Error('Please enter a valid number'))
      }
      if (existingNumbers.includes(value)) {
        return callback(new Error('That position number has already been used!'))
      }
      return callback()
    }

    const orderValidator = (_, value, callback) => {
      const existingNumbers = this.setting.details.map(p => p.order)
      if (!parseInt(value) || parseInt(value) < 0 || value % 1 !== 0) {
        return callback(new Error('Please enter a valid number'))
      }
      if (existingNumbers.includes(value)) {
        return callback(new Error('That order number has already been used!'))
      }
      return callback()
    }

    return {
      type: 'insert',
      competitionSettings: [],
      setting: {
        name: '',
        description: '',
        lawsOfGameUrl: '',
        details: [],
        isAllowedToScore: undefined,
        isAllowedToUseLadders: undefined,
        isAllowedToUseFinals: undefined,
        isAllowedToUseAdditionalDetails: undefined,
        associations: []
      },
      selectedAssociations: [],
      selectedTeamListTypes: [],
      ageLevels,
      selectedAgeLevels: [],
      allAssociations: undefined,
      optionsdisabled: [],
      currentPosition: {
        name: null,
        abbreviation: null,
        number: null,
        order: null
      },
      settingRules: {
        name: {
          required: true,
          min: 3,
          message: errormsg.input_option
        },
        description: {
          required: true,
          message: errormsg.input_option,
          trigger: 'blur'
        }
      },
      rules: {
        name: {
          required: true,
          message: errormsg.input_option,
          trigger: 'blur'
        },
        abbreviation: {
          required: true,
          message: errormsg.input_option,
          trigger: 'blur'
        },
        number: {
          type: 'number',
          required: true,
          trigger: 'blur',
          validator: numberValidator
        },
        order: {
          type: 'number',
          required: true,
          trigger: 'blur',
          validator: orderValidator
        }
      },
      tableProps: {
        border: true
      }
    }
  },
  mounted () {
    this.$store.commit('root/loading', true)
    const { type, compSetting, id } = this.$route.params

    // get comp settings
    this.$http
      .get('/nrl/api/v1/admin/competitions-settings')
      .then(response => {
        this.competitionSettings = response.data.data
        this.loading = false
      })
      .catch(() => {
        this.loading = false
        this.$customError()
      })

    // get associations
    this.$http
      .get(`/nrl/api/v1/admin/associations`)
      .then(response => {
        this.allAssociations = response.data.data.map(item => ({
          _id: item._id,
          name: item.name
        }))
        this.$store.commit('root/loading', false)
      })
      .catch(() => {
        this.$store.commit('root/loading', false)
        this.$customError()
      })
    this.type = type || (id !== 'add' ? 'update' : 'insert')
    if (id && id !== '' && id !== 'add' && !compSetting) {
      this.$http
        .get(`/nrl/api/v1/admin/competition-types/${id}`)
        .then(response => {
          this.setting = response.data.data
          this.selectedAssociations =
            this.setting.associations && this.setting.associations.map(item => item._id)
          this.selectedTeamListTypes =
            this.setting.teamListTypes && this.setting.teamListTypes.map((item) => item._id);
          this.selectedAgeLevels =
            this.setting.ageLevels && this.setting.ageLevels.map((item) => item.name);
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit('root/loading', false)
          this.$customError()
        })
    } else {
      if (compSetting) {
        this.setting = compSetting
        this.selectedAssociations =
          this.setting.associations && this.setting.associations.map(item => item._id)
        this.selectedTeamListTypes =
          this.setting.teamListTypes && this.setting.teamListTypes.map((item) => item._id);
        this.selectedAgeLevels = this.ageLevels && this.ageLevels.map((item) => item.name);
      }
      if (this.setting.details.length > 0) {
        this.optionsdisabled = new Array(this.setting.details.length).fill(false)
      }
      this.$store.commit('root/loading', false)
    }
  },
  methods: {
    viewTeamListType () {
      this.$router.push(`/comp-or-team-list-types/${this.selectedTeamListType}`)
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const toAdd = Object.assign({}, this.currentPosition)
          this.setting.details.push(toAdd)
          this.currentPosition = {
            name: null,
            abbreviation: null,
            number: null,
            order: null
          }
          return true
        }
        return false
      })
    },
    updateCompetitionTypes () {
      new Promise((resolve, reject) => {
        this.$refs.settingRules.validate(valid => (valid ? resolve() : reject()))
      }).then(() => {
        if (this.type === 'update') {
          // Update setting
          this.$store.commit('root/loading', true)
          const teamListType = this.competitionSettings.find(
            setting => setting && setting._id === this.selectedTeamListType
          )
          const update = {
            details: this.setting.details,
            name: this.setting.name,
            description: this.setting.description,
            lawsOfGameUrl: this.setting.lawsOfGameUrl,
            isAllowedToScore: this.setting.isAllowedToScore,
            isAllowedToUseLadders: this.setting.isAllowedToUseLadders,
            isAllowedToUseFinals: this.setting.isAllowedToUseFinals,
            isAllowedToUseAdditionalDetails: this.setting.isAllowedToUseAdditionalDetails,
            isAllowedAllAssociations: this.setting.isAllowedAllAssociations,
            isAllowedToDisplayLaddersAndResults: this.setting.isAllowedToDisplayLaddersAndResults,
            associations: this.allAssociations
              .filter(item => this.selectedAssociations.includes(item._id))
              .map(item => ({ _id: item._id, name: item.name })),
            teamListTypes: this.competitionSettings
              .filter((item) => this.selectedTeamListTypes.includes(item._id))
              .map((item) => ({ _id: item._id, name: item.name })),
            ageLevels: this.ageLevels
              .filter((item) => this.selectedAgeLevels.includes(item.name))
              .map((item) => ({ name: item.name })),
          };

          const url = `/nrl/api/v1/admin/competition-types/${this.setting._id}`
          this.$http
            .put(url, update)
            .then(() => {
              this.$router.push({ name: 'competition-types.list' })
              this.$store.commit('root/loading', false)
              this.$customSuccess()
            })
            .catch(() => {
              this.$store.commit('root/loading', false)
              this.$customError()
            })
        } else {
          this.$store.commit('root/loading', true)
          const insert = {
            name: this.setting.name,
            description: this.setting.description,
            lawsOfGameUrl: this.setting.lawsOfGameUrl,
            isAllowedToScore: this.setting.isAllowedToScore,
            isAllowedToUseLadders: this.setting.isAllowedToUseLadders,
            isAllowedToUseFinals: this.setting.isAllowedToUseFinals,
            isAllowedAllAssociations: this.setting.isAllowedAllAssociations,
            isAllowedToDisplayLaddersAndResults: this.setting.isAllowedToDisplayLaddersAndResults,
            associations: this.allAssociations
              .filter(item => this.selectedAssociations.includes(item._id))
              .map(item => ({ _id: item._id, name: item.name })),
            details: this.setting.details,
            orgtree: {
              national: {
                _id: this.$store.getters['user/activeRole'].national_id,
                name: this.$store.getters['user/activeRole'].entity.name
              }
            },
            teamListTypes: this.competitionSettings
              .filter((item) => this.selectedTeamListTypes.includes(item._id))
              .map((item) => ({ _id: item._id, name: item.name })),
            ageLevels: this.ageLevels
              .filter((item) => this.selectedAgeLevels.includes(item._id))
              .map((item) => ({ name: item.name })),
          };

          const url = '/nrl/api/v1/admin/competition-types/'
          this.$http
            .post(url, insert)
            .then(() => {
              this.$router.push({ name: 'competition-types.list' })
              this.$customSuccess()
            })
            .catch(() => {
              this.$store.commit('root/loading', false)
              this.$customError()
            })
        }
      })
    },
    cancelForm () {
      this.$router.push({
        name: 'competition-types.list'
      })
    },
    handleEditRow (rowindex) {
      if (this.optionsdisabled[rowindex]) {
        const row = this.setting.details[rowindex]
        if (!parseInt(row.number) || parseInt(row.number) < 0) {
          this.$customError(errormsg.pos_number)
          return false
        }
        row.number = parseInt(row.number)
        if (!parseInt(row.order) || parseInt(row.order) < 0) {
          this.$customError(errormsg.order_number)
          return false
        }
        row.order = parseInt(row.order)
      }
      this.$set(this.optionsdisabled, rowindex, !this.optionsdisabled[rowindex])
      return true
    },
    handleRemoveRow (rowindex) {
      this.setting.details.splice(rowindex, 1)
      this.optionsdisabled.splice(rowindex, 1)
    }
  },
  computed: {
    isSuperAdminNRL () {
      const { type, national_id } = this.$store.getters['user/activeRole']
      return type === roles.superAdmin && national_id === 32
    }
  }
}
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.btn-container-add {
  float: right;
}

.data-table {
  margin-top: 2.5rem !important;
}

.table {
  margin-top: 2rem;
}

.details {
  margin-top: 1rem;
}
</style>
