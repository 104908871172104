import ClubForm from '../views/clubs/ClubForm.vue';
import RegionForm from '../views/regions/RegionForm.vue';
import StateForm from '../views/states/StateForm.vue';
import AssociationForm from '../views/associations/AssociationForm.vue';
import GameDevRegionForm from '../views/game-dev-regions/GameDevRegionForm.vue';

export default [
  {
    path: 'clubs/:id',
    name: 'my.clubs.update',
    props: true,
    meta: {
      displayName: 'My Club',
      layout: 'app',
      requiresAuth: true,
    },
    component: ClubForm,
  },
  {
    path: 'regions/:id',
    name: 'my.regions.update',
    props: true,
    meta: {
      displayName: 'My Region',
      layout: 'app',
      requiresAuth: true,
    },
    component: RegionForm,
  },
  {
    path: 'states/:id',
    name: 'my.states.update',
    props: true,
    meta: {
      displayName: 'My State',
      layout: 'app',
      requiresAuth: true,
    },
    component: StateForm,
  },
  {
    path: 'associations/:id',
    name: 'my.associations.update',
    props: true,
    meta: {
      displayName: 'My Association',
      layout: 'app',
      requiresAuth: true,
    },
    component: AssociationForm,
  },
  {
    path: 'game-dev-regions/:id',
    name: 'my.game-dev-regions.update',
    props: true,
    meta: {
      displayName: 'My Game Dev Region',
      layout: 'app',
      requiresAuth: true,
    },
    component: GameDevRegionForm,
  },
];
