<template>
  <div>
    <el-tabs type="card">
      <el-tab-pane label="Create/Manage Draw">
        <el-button icon="el-icon-plus" type="success" @click="handleAddDraw">Create Draw</el-button>
        <el-button icon="el-icon-upload" type="success" @click="handleUploadDraw"
          >Draw Import</el-button
        >

        <el-row class="search-bar">
          <el-col :span="6">
            <el-input autocomplete="off" v-model="search" placeholder="Search..."></el-input>
          </el-col>
        </el-row>

        <data-tables
          v-loading="loading"
          :data="
            tableData.filter(
              data => !search || data.competition.name.toLowerCase().includes(search.toLowerCase())
            )
          "
          :table-props="tableProps"
          :pagination-props="paginationProps"
          :page-size="pageSize"
          @row-click="handleRowClick"
          class="data-table"
        >
          <el-table-column
            prop="competition.name"
            label="Competition Name"
            width="auto"
          ></el-table-column>
          <el-table-column
            prop="competition.regularSeasonRounds"
            label="Regular Season Rounds"
            width="auto"
          ></el-table-column>
          <el-table-column
            prop="competition.finalSeasonRounds"
            label="Final Season Rounds"
            width="auto"
          ></el-table-column>
        </data-tables>
      </el-tab-pane>
      <el-tab-pane label="Exception Dates">
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <h2 class="tw-text-xl tw-font-bold">Add Exception Dates</h2>
        </div>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form label-position="top" :rules="rules">
            <el-row type="flex" align="bottom" :gutter="20">
              <el-col :span="6">
                <el-form-item label="Description">
                  <el-input type="text" v-model="description" autocomplete="off" placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Date From">
                  <el-date-picker
                    v-model="dateFrom"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="timestamp"
                    placeholder=""
                    style="width: 100%"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Date To">
                  <el-date-picker
                    v-model="dateTo"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="timestamp"
                    placeholder=""
                    style="width: 100%"
                /></el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="8">
            <el-button type="success" icon="el-icon-plus" @click="addExceptionDate"
              >Add Date</el-button
            >
          </el-col>
        </div>

        <data-tables
          v-if="exceptionDatesTable.length > 0"
          :data="exceptionDatesTable"
          :table-props="tableProps"
          layout="table"
          class="data-table-dates"
        >
          <el-table-column label="Description" width="200">
            <template slot-scope="scope">
              <el-input
                type="text"
                v-model="scope.row.description"
                autocomplete="off"
                placeholder=""
                :disabled="!optionsdisabled[scope.$index]"
              />
            </template>
          </el-table-column>
          <el-table-column label="Date From" width="250">
            <template slot-scope="scope">
              <el-date-picker
                type="date"
                format="dd/MM/yyyy"
                v-model="scope.row.dateFrom"
                value-format="timestamp"
                placeholder=""
                :disabled="!optionsdisabled[scope.$index]"
              />
            </template>
          </el-table-column>
          <el-table-column label="Date To" width="250">
            <template slot-scope="scope">
              <el-date-picker
                type="date"
                format="dd/MM/yyyy"
                v-model="scope.row.dateTo"
                value-format="timestamp"
                placeholder=""
                :disabled="!optionsdisabled[scope.$index]"
              />
            </template>
          </el-table-column>
          <el-table-column label="Entity" width="250">
            <template slot-scope="scope">
              <el-input
                type="text"
                :value="formatEntity(scope.row.refCollection)"
                :disabled="true"
              />
            </template>
          </el-table-column>
          <el-table-column label="Edit/Remove" fixed="right" width="300">
            <template slot-scope="scope">
              <el-button @click="handleEditRow(scope.$index)">
                <template v-if="!optionsdisabled[scope.$index]"> Edit </template>
                <template v-else> Save </template>
              </el-button>
              <el-button @click="handleRemoveRow(scope.$index)">Remove</el-button>
            </template>
          </el-table-column>
        </data-tables>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { roles } from "../../utils/constants";
export default {
  name: "DrawList",
  props: {},
  data() {
    return {
      search: "",
      loading: true,
      tableData: [],
      exceptionDatesTable: [],
      pageSize: 20,
      tableProps: {
        border: true
      },
      paginationProps: {
        pageSizes: [20, 50, 100]
      },
      description: "",
      rules: {
        description: {
          type: "text",
          required: true
        }
      },
      dateFrom: null,
      dateTo: null,
      exceptionDateinsert: {},
      optionsdisabled: []
    };
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/admin/draws")
      .then(response => {
        this.tableData = response.data.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.$customError();
      });
    // fetch exception dates
    this.$http
      .get("/nrl/api/v1/admin/exception-dates")
      .then(response => {
        this.exceptionDatesTable = response.data.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.$customError();
      });
  },
  // computed: {
  //   refCollection(row) {
  //     if (row.refCollection === "associations") {
  //       return "Association";
  //     } else if (row.refCollection === "states") {
  //       return "State";
  //     }
  //   },
  // },
  methods: {
    formatEntity(valString) {
      if (!valString) return "";
      const first = valString.charAt(0);
      const upper = first.toUpperCase();
      const singular = valString.slice(0, -1);
      return `${upper}${singular.slice(1)}`;
    },
    handleRowClick(row) {
      this.$router.push({
        name: "draws.update",
        params: {
          type: "update",
          draw: row,
          id: row._id
        }
      });
    },
    handleAddDraw() {
      this.$router.push({
        name: "draw.build"
      });
    },
    handleUploadDraw() {
      this.$router.push({
        name: "draw.import"
      });
    },
    addExceptionDate() {
      if (!this.description) {
        this.$customError();
        return;
      }
      let refCollection = "";
      const activeRole = this.$store.getters["user/activeRole"];

      if (activeRole.type === roles.associationAdmin) refCollection = "associations";
      if (activeRole.type === roles.stateAdmin) refCollection = "states";

      this.exceptionDateinsert = {
        description: this.description,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        refCollection,
        refId: activeRole.entity._id
      };
      this.$http
        .post("/nrl/api/v1/admin/exception-dates", this.exceptionDateinsert)
        .then(() => {
          this.exceptionDatesTable.push(this.exceptionDateinsert);
          this.$store.commit("root/loading", false);
          this.optionsdisabled.push(false);
          this.description = "";
          this.dateFrom = null;
          this.dateTo = null;
          this.$customSuccess();
        })
        .catch(error => {
          this.$store.commit("root/loading", false);
          this.$customError(_.get(error, "response.data.message"));
        });
    },
    handleRemoveRow(index) {
      const activeRole = this.$store.getters["user/activeRole"];
      if (activeRole.type !== roles.associationAdmin && activeRole.type !== roles.stateAdmin) {
        this.$customError();
        return;
      }
      this.$store.commit("root/loading", true);
      this.$confirm("Are you sure you want to remove this exception date?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.$http.get("/nrl/api/v1/admin/exception-dates").then(response => {
            this.exceptionDatesTable = response.data.data;
            this.loading = false;

            const expDate = this.exceptionDatesTable[index];
            if (expDate._id) {
              this.$http
                .delete(`/nrl/api/v1/admin/exception-dates/${expDate._id}`)
                .then(() => {
                  this.exceptionDatesTable.splice(index, 1);
                  this.optionsdisabled.splice(index, 1);
                  this.$store.commit("root/loading", false);
                  this.$customSuccess();
                })
                .catch(error => {
                  this.$store.commit("root/loading", false);
                  this.$customError(_.get(error, "response.data.message"));
                });
            }
          });
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
        });
    },
    handleEditRow(rowindex) {
      const activeRole = this.$store.getters["user/activeRole"];
      if (activeRole.type !== roles.associationAdmin && activeRole.type !== roles.stateAdmin) {
        this.$customError();
        return;
      }

      if (this.optionsdisabled[rowindex]) {
        this.$store.commit("root/loading", true);

        const dateRow = this.exceptionDatesTable[rowindex];
        const refCollection = dateRow.refCollection;

        this.dateFrom = dateRow.dateFrom;
        this.dateTo = dateRow.dateTo;
        const update = {
          description: dateRow.description,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          refCollection
        };
        const url = `/nrl/api/v1/admin/exception-dates/${dateRow._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.description = "";
            this.dateFrom = null;
            this.dateTo = null;
            this.$customSuccess();
          })
          .catch(error => {
            this.$store.commit("root/loading", false);
            this.$customError(_.get(error, "response.data.message"));
          });
      }
      this.$set(this.optionsdisabled, rowindex, !this.optionsdisabled[rowindex]);
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-table {
  width: 100%;
}
.data-table-dates {
  width: 100%;
  margin-top: 1rem !important;
}

.el-pagination {
  margin-top: 1rem !important;
}
</style>
