<template>
  <el-form>
    <el-row :gutter="10">
      <el-col :span="10">
        <el-form-item label="Internal Notes">
          <el-input v-model="adminNotes"></el-input>
        </el-form-item> </el-col></el-row
    ><el-row v-if="logs.length > 0">
      <el-col :span="24">
        <el-form-item label="Notes Log">
          <el-timeline style="padding-left: 10px">
            <el-timeline-item v-for="(item, _id) in logs" :key="_id" color="#098255">
              {{ item.note }}

              <!-- element ui built in classes -->
              <div class="el-timeline-item__timestamp is-bottom">
                Created by {{ item.createdBy }} on
                {{ formatTimestamp(item.createdAt) }}
              </div>
            </el-timeline-item>
          </el-timeline>
        </el-form-item></el-col
      ></el-row
    >
    <el-row :gutter="10" class="action-buttons">
      <el-col :span="8">
        <action-buttons submit cancel @submit="submitForm()" @cancel="cancelForm()" />
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";

export default {
  components: {
    ActionButtons,
  },
  props: ["program"],
  data() {
    return {
      logs: [],
      log: { note: "" },
      adminNotes: "",
    };
  },
  mounted() {
    this.fetchAssetData();
  },
  methods: {
    async fetchAssetData() {
      this.$store.commit("root/loading", true);
      const { id } = this.$route.params;
      if (id) {
        try {
          const response = await this.$http.get(`/nrl/api/v1/admin/programs/${id}`);
          const programData = response.data.data;
          if (programData && programData.logs && programData.logs.length > 0) {
            this.logs = programData.logs;
          }
          this.$store.commit("root/loading", false);
        } catch (error) {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    submitForm() {
      if (this.adminNotes !== "") {
        this.log.note = this.adminNotes;
        this.logs.push(this.log);
        const update = { logs: this.logs };
        const url = `/nrl/api/v1/admin/programs/${this.program._id}`;
        this.$http
          .put(url, update)
          .then((res) => {
            const program = res.data.data;
            if (program && program.logs) {
              this.logs = program.logs;
            }
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
    },
    cancelForm() {
      this.adminNotes = "";
    },
    formatTimestamp(time) {
      return this.moment(time).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
};
</script>
