<template>
  <div class="d-flex-column">
    <CheckBoxGroup
      :choices="[
        { name: 'Pending', type: 'pending' },
        { name: 'Declined', type: 'declined' },
      ]"
      :min="0"
      :max="1"
      name="Status"
      v-model="inputVal.status"
    />
    <CheckBoxGroup
      :choices="[
        { name: 'Yes', type: 'true' },
        { name: 'No', type: 'false' },
      ]"
      :min="0"
      name="Photo Uploaded"
      v-model="inputVal.photo"
    />
    <CheckBoxGroup
      :choices="[
        { name: 'Yes', type: 'true' },
        { name: 'No', type: 'false' },
      ]"
      :min="0"
      name="Documents Uploaded"
      v-model="inputVal.documents"
    />

    <div class="mt-2 d-flex-column">
      <div class="mt-1">
        <el-button @click="clear" class="full-width button-invisible">Clear</el-button>
        <el-button @click="submit" type="primary" class="full-width">Apply Filters</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBoxGroup from "@/components/CheckboxGroup.vue";
import { EventBus } from "../../bus";

export default {
  name: "FilterPage",
  components: { CheckBoxGroup },
  props: ["value"],
  methods: {
    submit() {
      this.$emit("submit", this.inputVal);
    },
    clear() {
      this.$set(this.inputVal, "status", []);
      this.$set(this.inputVal, "photo" || []);
      this.$set(this.inputVal, "documents" || []);

      EventBus.$emit("clear");
      this.$emit("clear", this.inputVal);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  data() {
    const {
      params: { status, photo, documents },
    } = this.$store.state.registrations.pendings;
    return {
      inputVal: {
        status,
        photo: [],
        documents: [],
      },
      photo: [
        { name: "Yes", type: true },
        { name: "No", type: false },
      ],
      documents: [
        { name: "Yes", type: true },
        { name: "No", type: false },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
