<template>
  <div>
    <el-label>Split Payment (in dollars) for the entities: </el-label>
    <el-form label-position="top" ref="financials" :model="program">
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="4">
          <el-form-item label="No. Sessions"
            ><el-select
              v-model="duration"
              placeholder="No. Of Sessions"
              filterable
              @change="selectValidAgeAndPrice"
            >
              <el-option v-for="item in alldurations" :label="item" :key="item" :value="item">
              </el-option> </el-select></el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="Age Range">
            <el-select
              v-model="ageRange"
              placeholder="Age Range"
              filterable
              :disabled="duration === ''"
            >
              <el-option v-for="item in allAgeRanges" :label="item" :key="item" :value="item">
              </el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="Price">
            <el-select
              v-model="price"
              placeholder="Price entered for the session"
              filterable
              :disabled="duration === ''"
            >
              <el-option v-for="item in allPrices" :label="item" :key="item" :value="item">
              </el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="NRL($)">
            <el-input
              type="number"
              autocomplete="off"
              v-model="nrlAmount"
              placeholder="NRL"
              :disabled="duration === ''"
            /> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="State($)">
            <el-input
              type="number"
              autocomplete="off"
              v-model="stateAmount"
              placeholder="State"
              :disabled="duration === ''"
            /> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="Region($)">
            <el-input
              type="number"
              autocomplete="off"
              v-model="regionAmount"
              placeholder="Region"
              :disabled="duration === ''"
            /> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="Association($)">
            <el-input
              type="number"
              autocomplete="off"
              v-model="associationAmount"
              placeholder="Association"
              :disabled="duration === ''"
            /> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="Club($)">
            <el-input
              type="number"
              autocomplete="off"
              v-model="clubAmount"
              placeholder="Club"
              :disabled="duration === ''"
            /> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="GamedevRegion($)">
            <el-input
              type="number"
              autocomplete="off"
              v-model="gamedevregionAmount"
              placeholder="GamedevRegion"
              :disabled="duration === ''"
            /> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="Refund for Cancelled Program($)">
            <el-input
              type="number"
              autocomplete="off"
              v-model="refundForCancelledProgram"
              placeholder="Refund Amount"
              :disabled="duration === ''"
            /> </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="Refund for Indiviual($)">
            <el-input
              type="number"
              autocomplete="off"
              v-model="refundForIndividual"
              placeholder="Refund Amount"
              :disabled="duration === ''"
            /> </el-form-item
        ></el-col>
      </el-row>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitForm()">Save</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>

    <data-tables-server
      :data="displayfinancials"
      :table-props="tableProps"
      class="data-table"
      :pagination-props="paginationProps"
      :page-size="financialTableData.pageSize"
      :current-page.sync="financialTableData.page"
      :total="financialTableData.count"
    >
      <el-table-column prop="duration" label="No. of Sessions" width="150"> </el-table-column>
      <el-table-column label="Age Range" width="160" :formatter="ageData">
        <template slot-scope="scope">
          {{ ageData(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="Price($)" width="120" prop="price"> </el-table-column>
      <el-table-column label="NRL($)" width="120">
        <template slot-scope="scope">
          <el-input
            type="number"
            autocomplete="off"
            v-model="scope.row.nrl"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="State($)" width="120">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.state"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Region($)" width="120">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.region"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Association($)" width="120">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.association"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Club($)" width="120">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.club"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="GameDevRegion($)" width="170">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.gamedevregion"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column label="Active Kids Voucher" width="auto" prop="activeKidsVoucher">
      </el-table-column>

      <el-table-column label="Refund (Cancelled Program)?" width="200">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.refundForCancelledProgram"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column label="Refund (Individual cancellation)?" width="200">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.refundForIndividual"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column label="Edit/Remove" fixed="right" width="300">
        <template slot-scope="scope">
          <el-button @click="handleEditRow(scope.$index)">
            <template v-if="!optionsdisabled[scope.$index]"> Edit </template>
            <template v-else> Save </template>
          </el-button>
          <el-button @click="handleRemoveRow(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>
<script>
import { active } from "sortablejs";

export default {
  name: "ProgramsettingsFinancials",
  props: ["program"],
  data() {
    return {
      loading: true,
      options: [],
      optionId: undefined,
      alldurations: [],
      duration: "",
      ageRange: "",
      price: 0,
      allPrices: [],
      allAgeRanges: [],
      nrlAmount: 0,
      stateAmount: 0,
      regionAmount: 0,
      associationAmount: 0,
      gamedevregionAmount: 0,
      clubAmount: 0,
      refundForCancelledProgram: 0,
      refundForIndividual: 0,
      optionsdisabled: [],
      financials: [],
      displayfinancials: [],
      financialData: {
        optionId: undefined,
        duration: "",
        minAge: 0,
        maxAge: 0,
        price: 0,
        nrl: 0,
        state: 0,
        region: 0,
        association: 0,
        gamedevregion: 0,
        club: 0,
        activeKidsVoucher: false,
        refundForCancelledProgram: 0,
        refundForIndividual: 0,
        createdBy: {},
        updated: undefined,
      },

      financialTableData: {
        page: 1,
        limit: 10,
        count: 0,
      },
      tableProps: {
        border: false,
        compact: true,
      },
      paginationProps: {
        pageSizes: [10, 20, 50, 100],
      },
    };
  },
  mounted() {
    this.fetchFinancialData();
  },
  methods: {
    filterAgeByDuration(options, duration) {
      return options
        .filter((obj) => obj.duration === duration) // Filter objects with matching duration
        .map((obj) => `${obj.minAge} years to ${obj.maxAge} years`); // Construct the desired string
    },
    filterPriceByDuration(options, duration) {
      return options
        .filter((obj) => obj.duration === duration) // Filter objects with matching duration
        .map((obj) => `$ ${obj.price / 100}`); // Construct the desired string
    },
    selectValidAgeAndPrice() {
      this.allAgeRanges = this.filterAgeByDuration(this.options, this.duration);
      this.allPrices = this.filterPriceByDuration(this.options, this.duration);
    },
    async fetchFinancialData() {
      this.$store.commit("root/loading", true);
      const { id } = this.$route.params;

      if (id) {
        try {
          const response = await this.$http.get(`/nrl/api/v1/admin/programs-settings/${id}`);
          if (response && response.data && response.data.data) {
            this.options = response.data.data.options ? response.data.data.options : [];
            this.financials = response.data.data.financials ? response.data.data.financials : [];

            if (this.options) {
              this.alldurations = this.options.map((o) => o.duration);
              this.allAgeRanges = this.options.map((o) => `${o.minAge} years to ${o.maxAge} years`);

              this.allAgeRanges = [...new Set(this.allAgeRanges)];

              this.allPrices = this.options.map((o) => o.price);

              this.allPrices = [...new Set(this.prices)];
            }

            this.displayfinancials = this.financials.map((finance) => {
              return {
                optionId: finance.optionId,
                duration: finance.duration,
                minAge: finance.minAge,
                maxAge: finance.maxAge,
                price: (parseFloat(finance.price) / 100).toFixed(2),
                nrl: (parseFloat(finance.nrl) / 100).toFixed(2),
                state: (parseFloat(finance.state) / 100).toFixed(2),
                region: (parseFloat(finance.region) / 100).toFixed(2),
                association: (parseFloat(finance.association) / 100).toFixed(2),
                club: (parseFloat(finance.club) / 100).toFixed(2),
                gamedevregion: (parseFloat(finance.gamedevregion) / 100).toFixed(2),
                activeKidsVoucher: finance.activeKidsVoucher === false ? "No" : "Yes",
                refundForCancelledProgram: (
                  parseFloat(finance.refundForCancelledProgram) / 100
                ).toFixed(2),
                refundForIndividual: (parseFloat(finance.refundForIndividual) / 100).toFixed(2),
                createdBy: finance.createdBy,
                updatedBy: finance.updatedBy,
              };
            });
          }
          this.$store.commit("root/loading", false);
        } catch (error) {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    async submitForm() {
      try {
        // Get MinAge and Max Age
        let [minAgeStr, maxAgeStr] = this.ageRange.split(" years to ");
        const minAge = parseInt(minAgeStr);

        const maxAge = parseInt(maxAgeStr);

        const price = parseFloat(this.price.replace(/\$|\s/g, "")) * 100;

        const optionSelected = this.options.find(
          (o) =>
            o.duration === this.duration &&
            o.minAge === minAge &&
            o.maxAge === maxAge &&
            o.price === price
        );
        this.optionId = optionSelected._id;

        if (
          (this.nrlAmount && parseFloat(this.nrlAmount) < 0) ||
          (this.stateAmount && parseFloat(this.stateAmount) < 0) ||
          (this.regionAmount && parseFloat(this.regionAmount) < 0) ||
          (this.associationAmount && parseFloat(this.associationAmount) < 0) ||
          (this.gamedevregionAmount && parseFloat(this.gamedevregionAmount) < 0) ||
          (this.clubAmount && parseFloat(this.clubAmount) < 0)
        ) {
          throw Error("Amount is invalid");
        }

        const total =
          this.nrlAmount +
          this.stateAmount +
          this.regionAmount +
          this.associationAmount +
          this.clubAmount +
          this.gamedevregion;
        if (total > price) throw Error("Total is more than the actual Price");

        const profile = this.$store.getters["user/profile"];
        const created = {
          firstName: profile.firstName,
          lastName: profile.lastName,
          _id: profile._id,
        };
        this.financialData.optionId = this.optionId;
        this.financialData.duration = this.duration;
        this.financialData.minAge = minAge;
        this.financialData.maxAge = maxAge;
        this.financialData.price = price;

        this.financialData.nrl = this.nrlAmount
          ? parseInt(parseFloat(this.nrlAmount).toFixed(2) * 100)
          : 0;
        this.financialData.state = this.stateAmount
          ? parseInt(parseFloat(this.stateAmount).toFixed(2) * 100)
          : 0;
        this.financialData.region = this.regionAmount
          ? parseInt(parseFloat(this.regionAmount).toFixed(2) * 100)
          : 0;
        this.financialData.association = this.associationAmount
          ? parseInt(parseFloat(this.associationAmount).toFixed(2) * 100)
          : 0;
        this.financialData.gamedevregion = this.gamedevregionAmount
          ? parseInt(parseFloat(this.gamedevregionAmount).toFixed(2) * 100)
          : 0;
        this.financialData.club = this.clubAmount
          ? parseInt(parseFloat(this.clubAmount).toFixed(2) * 100)
          : 0;
        this.financialData.refundForCancelledProgram = this.refundForCancelledProgram
          ? parseInt(parseFloat(this.refundForCancelledProgram).toFixed(2) * 100)
          : 0;

        this.financialData.refundForIndividual = this.refundForIndividual
          ? parseInt(parseFloat(this.refundForIndividual).toFixed(2) * 100)
          : 0;

        this.financialData.activeKidsVoucher = optionSelected.activeKidsVoucher;
        this.financialData.createdBy = created;
        this.financialData.updated = Date.now();

        const response = await this.$http.get(
          `/nrl/api/v1/admin/programs-settings/${this.program._id}`
        );
        if (response && response.data && response.data.data) {
          this.financials = response.data.data.financials ? response.data.data.financials : [];
        }

        this.financials.push(this.financialData);
        const update = { financials: this.financials };

        const url = `/nrl/api/v1/admin/programs-settings/${this.program._id}`;
        this.$http
          .put(url, update)
          .then((res) => {
            if (res && res.data && res.data.data && res.data.data.financials) {
              this.displayfinancials = res.data.data.financials.map((finance) => {
                return {
                  optionId: finance.optionId,
                  duration: finance.duration,
                  minAge: finance.minAge,
                  maxAge: finance.maxAge,
                  price: (parseFloat(finance.price) / 100).toFixed(2),
                  nrl: (parseFloat(finance.nrl) / 100).toFixed(2),
                  state: (parseFloat(finance.state) / 100).toFixed(2),
                  region: (parseFloat(finance.region) / 100).toFixed(2),
                  association: (parseFloat(finance.association) / 100).toFixed(2),
                  club: (parseFloat(finance.club) / 100).toFixed(2),
                  gamedevregion: (parseFloat(finance.gamedevregion) / 100).toFixed(2),
                  activeKidsVoucher: finance.activeKidsVoucher === false ? "No" : "Yes",
                  refundForCancelledProgram: (
                    parseFloat(finance.refundForCancelledProgram) / 100
                  ).toFixed(2),
                  refundForIndividual: (parseFloat(finance.refundForIndividual) / 100).toFixed(2),
                  createdBy: finance.createdBy,
                  updatedBy: finance.updatedBy,
                };
              });
            }
            this.duration = "";
            this.ageRange = "";
            this.price = 0;
            this.nrlAmount = 0;
            this.stateAmount = 0;
            this.regionAmount = 0;
            this.associationAmount = 0;
            this.gamedevregionAmount = 0;
            this.clubAmount = 0;
            this.refundForCancelledProgram = 0;
            this.refundForIndividual = 0;
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch((e) => {
            this.$store.commit("root/loading", false);
            this.$customError(e);
          });
      } catch (e) {
        this.$customError(e);
      }
    },
    ageData(row) {
      return `${row.minAge} to ${row.maxAge} years`;
    },
    handleEditRow(rowindex) {
      if (this.optionsdisabled[rowindex]) {
        const row = this.displayfinancials[rowindex];

        const updatedFinancials = this.financials;
        updatedFinancials[rowindex].activeKidsVoucher =
          this.financials[rowindex].activeKidsVoucher === "No" ? false : true;
        updatedFinancials[rowindex].price = parseInt(parseFloat(row.price) * 100);
        updatedFinancials[rowindex].nrl = parseInt(parseFloat(row.nrl) * 100);
        updatedFinancials[rowindex].state = parseInt(parseFloat(row.state) * 100);
        updatedFinancials[rowindex].region = parseInt(parseFloat(row.region) * 100);
        updatedFinancials[rowindex].association = parseInt(parseFloat(row.association) * 100);
        updatedFinancials[rowindex].gamedevregion = parseInt(parseFloat(row.gamedevregion) * 100);
        updatedFinancials[rowindex].club = parseInt(parseFloat(row.club) * 100);
        updatedFinancials[rowindex].refundForCancelledProgram = parseInt(
          parseFloat(row.refundForCancelledProgram) * 100
        );
        updatedFinancials[rowindex].refundForIndividual = parseInt(
          parseFloat(row.refundForIndividual) * 100
        );
        // Update program
        this.$store.commit("root/loading", true);
        const update = { financials: updatedFinancials };
        const url = `/nrl/api/v1/admin/programs-settings/${this.program._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.displayfinancials = this.financials.map((finance) => {
              return {
                optionId: finance.optionId,
                duration: finance.duration,
                minAge: finance.minAge,
                maxAge: finance.maxAge,
                price: (parseFloat(finance.price) / 100).toFixed(2),
                nrl: (parseFloat(finance.nrl) / 100).toFixed(2),
                state: (parseFloat(finance.state) / 100).toFixed(2),
                region: (parseFloat(finance.region) / 100).toFixed(2),
                association: (parseFloat(finance.association) / 100).toFixed(2),
                club: (parseFloat(finance.club) / 100).toFixed(2),
                gamedevregion: (parseFloat(finance.gamedevregion) / 100).toFixed(2),
                activeKidsVoucher: finance.activeKidsVoucher === false ? "No" : "Yes",
                refundForCancelledProgram: (
                  parseFloat(finance.refundForCancelledProgram) / 100
                ).toFixed(2),
                refundForIndividual: (parseFloat(finance.refundForIndividual) / 100).toFixed(2),
                createdBy: finance.createdBy,
                updatedBy: finance.updatedBy,
              };
            });
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
      this.$set(this.optionsdisabled, rowindex, !this.optionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRow(rowindex) {
      this.financials.splice(rowindex, 1);
      this.displayfinancials.splice(rowindex, 1);
      this.optionsdisabled.splice(rowindex, 1);
      this.$store.commit("root/loading", true);
      const update = { financials: this.financials };
      const url = `/nrl/api/v1/admin/programs-settings/${this.program._id}`;
      this.$http
        .put(url, update)
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.el-input {
  width: 100%;
  height: 50%;
}
</style>
