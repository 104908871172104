<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="24" class="btn-container">
        <el-button type="success" @click="saveDraw()">Save</el-button>
        <el-button type="success" @click="publishDraw()">Publish</el-button>
        <el-button type="success" @click="deleteDraw()">Delete</el-button>
        <el-button @click="cancelForm()">Cancel</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <div><h3>Regular Season</h3></div>
      </el-col>
    </el-row>

    <el-row v-for="pool in draw.pools" :key="pool.name">
      <h4><span v-if="draw.pools.length > 1"> Pool: </span> {{ pool.name }}</h4>
      <el-table
        v-if="pool.regularRoundMatches.length > 0"
        :data="pool.regularRoundMatches"
        :border="true"
      >
        <el-table-column align="center" prop="round.number" label="Round" width="100">
        </el-table-column>
        <el-table-column align="center" label="Home Team" width="auto">
          <template slot-scope="scope">
            <el-select
              value-key="_id"
              v-if="scope.row.homeTeam != null"
              v-model="scope.row.homeTeam"
              placeholder="Select team"
              filterable
            >
              <el-option v-for="item in allTeams" :label="item.name" :key="item._id" :value="item">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Away Team" width="auto">
          <template slot-scope="scope">
            <el-select
              value-key="_id"
              v-if="scope.row.awayTeam != null"
              v-model="scope.row.awayTeam"
              placeholder="Select team"
              filterable
            >
              <el-option v-for="item in allTeams" :label="item.name" :key="item._id" :value="item">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Date & Time" width="auto">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.dateTime"
              type="datetime"
              placeholder="Select date and time"
              format="dd/MM/yyyy HH:mm"
              value-format="timestamp"
              :clearable="false"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Venue" width="auto">
          <template slot-scope="scope">
            <el-select
              value-key="_id"
              v-model="scope.row.venue"
              placeholder="Select venue/type to search"
              :remote="true"
              :remote-method="filter"
              filterable
            >
              <el-option v-for="item in allVenues" :label="item.name" :key="item._id" :value="item">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Field No" width="auto">
          <template slot-scope="scope">
            <el-input maxlength="10" v-model="scope.row.meta.fieldNo" placeholder="Field No">
            </el-input>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <div><h3>Finals Rounds</h3></div>
      </el-col>
    </el-row>
    <el-table v-if="finalRoundMatches.length > 0" :data="finalRoundMatches" :border="true">
      <el-table-column align="center" prop="round.number" label="Round" width="100">
      </el-table-column>
      <el-table-column align="center" prop="round.displayName" label="Round Name" width="auto">
      </el-table-column>
      <el-table-column align="center" prop="homeTeam.name" label="Home Team" width="auto">
      </el-table-column>
      <el-table-column align="center" prop="awayTeam.name" label="Away Team" width="auto">
      </el-table-column>
      <el-table-column align="center" label="Date & Time" width="auto">
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.dateTime"
            type="datetime"
            placeholder="Select date and time"
            format="dd/MM/yyyy HH:mm"
            value-format="timestamp"
            :clearable="false"
          >
          </el-date-picker>
        </template>
      </el-table-column>
      <el-table-column align="center" label="Venue" width="auto">
        <template slot-scope="scope">
          <el-select
            value-key="_id"
            v-model="scope.row.venue"
            placeholder="Select venue/type to search"
            :remote="true"
            :remote-method="filter"
            filterable
          >
            <el-option v-for="item in allVenues" :label="item.name" :key="item._id" :value="item">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column align="center" label="Field No" width="auto">
        <template slot-scope="scope">
          <el-input maxlength="10" v-model="scope.row.meta.fieldNo" placeholder="Field No">
          </el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg, nationals } from "../../utils/constants";

// Remove Bye and TBA teams when publishing
const removeByeTBATeams = matches => {
  _.each(matches, match => {
    if (_.get(match, "homeTeam._id") === "BYE" || _.get(match, "homeTeam._id") === "TBA") {
      delete match.homeTeam;
    }
    if (_.get(match, "awayTeam._id") === "BYE" || _.get(match, "awayTeam._id") === "TBA") {
      delete match.awayTeam;
    }
  });
};

// Check if any regular round match has same home and away team
const checkSameTeam = matches =>
  !matches.some(
    match =>
      _.get(match, "homeTeam._id") !== "TBA" &&
      _.get(match, "homeTeam._id") === _.get(match, "awayTeam._id")
  );
const checkTbaByeMatch = matches =>
  matches.some(
    match =>
      (_.get(match, "homeTeam._id") === "TBA" && _.get(match, "awayTeam._id") === "BYE") ||
      (_.get(match, "homeTeam._id") === "BYE" && _.get(match, "awayTeam._id") === "TBA")
  );

export default {
  name: "DrawEdit",
  props: {},
  data() {
    return {
      nationals,
      draw: {
        pools: []
      },
      regularRoundMatches: [],
      finalRoundMatches: [],
      allVenues: [],
      allTeams: [],
      tableProps: {
        border: true
      },
      paginationPropsMatches: {
        pageSizes: [50]
      }
    };
  },
  mounted() {
    const params = { ...this.$route.params };
    this.$store.commit("root/loading", true);
    this.$http
      .get(`nrl/api/v1/admin/draws/${params.id}`)
      .then(response => {
        this.draw = _.merge({}, this.draw, response.data.data);
        this.draw.pools = this.draw.pools.map(pool => ({
          ...pool,
          regularRoundMatches: _.sortBy(pool.regularRoundMatches, ["round.number"], ["asc"])
        }));

        this.finalRoundMatches = this.draw.matches.filter(m => m.round.type === "Final");
        _.each(this.draw.matches, match => {
          this.allVenues.push(match.venue);
          this.allTeams.push(match.homeTeam);
          this.allTeams.push(match.awayTeam);
        });
        this.allVenues = _.uniqBy(this.allVenues, "_id");
        this.allTeams = _.uniqBy(this.allTeams, "_id");
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  methods: {
    publishDraw() {
      this.$store.commit("root/loading", true);

      const validate = this.draw.pools.every(pool => {
        // do not allow TBA and BYE in a match
        if (
          checkTbaByeMatch(pool.regularRoundMatches) ||
          checkTbaByeMatch(this.finalRoundMatches)
        ) {
          this.$store.commit("root/loading", false);
          this.$customError(errormsg.draw_tbabyematch);
          return false;
        }

        if (!checkSameTeam(pool.regularRoundMatches) || !checkSameTeam(this.finalRoundMatches)) {
          this.$store.commit("root/loading", false);
          this.$customError(errormsg.draw_sameteam);
          return false;
        }
        return true;
      });

      if (validate) {
        this.$confirm("This will publish the draw. Continue?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        })
          .then(() => {
            const formattedPools = this.draw.pools.map(pool => ({
              ...pool,
              regularRoundMatches: pool.regularRoundMatches.map(match => {
                if (match.homeTeam._id === "BYE" || match.homeTeam._id === "TBA") {
                  delete match.homeTeam;
                }
                if (match.awayTeam._id === "BYE" || match.awayTeam._id === "TBA") {
                  delete match.awayTeam;
                }
                return match;
              })
            }));

            removeByeTBATeams(this.finalRoundMatches);

            const url = "/nrl/api/v1/admin/matches/publish-draw";
            this.$http
              .post(
                url,
                _.flatMap(formattedPools, "regularRoundMatches").concat(this.finalRoundMatches)
              )
              .then(() => {
                this.$http
                  .delete(`/nrl/api/v1/admin/draws/${this.draw._id}`)
                  .then(() => {
                    this.$store.commit("root/loading", false);
                    this.$router.push({
                      name: "matches.list"
                    });
                  })
                  .catch(() => {
                    this.$store.commit("root/loading", false);
                    this.$customError();
                  });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
          });
      }
    },
    saveDraw() {
      this.$store.commit("root/loading", true);

      const validate = this.draw.pools.every(pool => {
        // do not allow TBA and BYE in a match
        if (
          checkTbaByeMatch(pool.regularRoundMatches) ||
          checkTbaByeMatch(this.finalRoundMatches)
        ) {
          this.$store.commit("root/loading", false);
          this.$customError(errormsg.draw_tbabyematch);
          return false;
        }

        if (!checkSameTeam(pool.regularRoundMatches) || !checkSameTeam(this.finalRoundMatches)) {
          this.$store.commit("root/loading", false);
          this.$customError(errormsg.draw_sameteam);
          return false;
        }
        return true;
      });

      if (validate) {
        const update = {
          matches: _.flatMap(this.draw.pools, "regularRoundMatches").concat(this.finalRoundMatches),
          pools: this.draw.pools
        };
        const url = `/nrl/api/v1/admin/draws/${this.draw._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
    },
    cancelForm() {
      this.$router.push({
        name: "draws.list"
      });
    },
    deleteDraw() {
      this.$confirm("This will permanently delete the draw. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.$store.commit("root/loading", true);
          const url = `/nrl/api/v1/admin/draws/${this.draw._id}`;
          this.$http
            .delete(url)
            .then(() => {
              this.$store.commit("root/loading", false);
              this.$router.push({ name: "draws.list" });
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
        });
    },
    filter(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then(response => {
              const allVenueIds = this.allVenues.map(v => v._id);
              for (let index = 0; index < response.data.data.length; index += 1) {
                const v = response.data.data[index];
                if (allVenueIds.includes(v._id) === false) {
                  allVenueIds.push(v._id);
                  this.allVenues.push(v);
                }
              }
            })
            .catch(() => {});
        }, 200);
      }
    }
  },
  computed: {}
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.btn-container {
  text-align: right;
}

.tba {
  margin-top: 3rem;
}
</style>
