<template>
  <div>
    <el-form ref="form" :model="additionalQuestion" :rules="rules" label-position="top">
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="type" label="Type">
            <el-select v-model="additionalQuestion.type" placeholder="E.g: Input" filterable>
              <el-option
                v-for="e in additionalQTypes"
                :label="e.name"
                :key="e.type"
                :value="e.type"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="fieldName" label="Report Column Name">
            <el-input
              v-model="additionalQuestion.fieldName"
              placeholder="E.g: Application Reason"
            />
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item prop="question" label="Question">
            <el-input
              type="textarea"
              v-model="additionalQuestion.question"
              maxlength="40"
              placeholder="E.g: Country / Area of Birth"
              :rows="3"
            />
          </el-form-item>
        </el-col>
        <el-col :span="13" v-if="[2, 4].includes(additionalQuestion.type)">
          <el-form-item prop="meta.options" label="Options">
            <div v-for="(option, idx) in additionalQuestion.meta.options" :key="idx">
              <div class="flex-row d-flex">
                <el-input
                  class="option"
                  type="text"
                  v-model="option.value"
                  maxlength="40"
                  :placeholder="'Option ' + (idx + 1)"
                />
                <el-button
                  class="option-delete"
                  type="text"
                  icon="el-icon-delete"
                  @click="additionalQuestion.meta.options.splice(idx, 1)"
                ></el-button>
              </div>
            </div>
            <div>
              <el-button
                type="text"
                icon="el-icon-circle-plus-outline"
                @click="addOption(additionalQuestion.meta.options)"
                >Add Option</el-button
              >
            </div>
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="9">
          <el-form-item prop="memberType" label="Member Type">
            <el-select
              v-model="additionalQuestion.memberType"
              placeholder="E.g: Volunteer"
              filterable
            >
              <el-option v-for="e in memberTypes" :label="e.name" :key="e.type" :value="e.type" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="gender" label="Gender">
            <el-select v-model="additionalQuestion.gender" placeholder="E.g: Female">
              <el-option v-for="e in genders" :label="e" :key="e" :value="e" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" v-if="!isTFA">
          <el-form-item prop="ageFrom" label="Age From">
            <el-input-number
              :min="1"
              :max="99"
              v-model="additionalQuestion.ageFrom"
              controls-position="right"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4" v-if="!isTFA">
          <el-form-item prop="ageTo" label="Age To">
            <el-input-number
              :min="1"
              :max="99"
              v-model="additionalQuestion.ageTo"
              controls-position="right"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4" v-if="isTFA">
          <el-form-item prop="dateFrom" label="DOB from">
            <el-date-picker
              v-model="additionalQuestion.dateFrom"
              type="date"
              placeholder="Select a Date"
              format="dd/MM/yyyy"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4" v-if="isTFA">
          <el-form-item prop="dateTo" label="DOB to">
            <el-date-picker
              v-model="additionalQuestion.dateTo"
              type="date"
              placeholder="Select a Date"
              format="dd/MM/yyyy"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="required" label="Required">
            <el-select v-model="additionalQuestion.required" placeholder="Yes / No">
              <el-option :value="true" label="Yes"></el-option>
              <el-option :value="false" label="No"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="isActive" label="Active">
            <el-select v-model="additionalQuestion.isActive" placeholder="Yes / No">
              <el-option :value="true" label="Yes"></el-option>
              <el-option :value="false" label="No"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="clearance == roles.superAdmin"
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="meta.entityType" label="Entity Type">
            <el-select v-model="additionalQuestion.meta.entityType" placeholder="E.g. State">
              <el-option value="state" label="State"></el-option>
              <el-option value="region" label="Region"></el-option>
              <el-option value="association" label="Association"></el-option>
              <el-option value="club" label="Club"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="18" v-if="additionalQuestion.meta.entityType">
          <el-form-item prop="meta.entityIds" label="Entities">
            <el-select
              multiple
              v-model="additionalQuestion.meta.entityIds"
              :remote="true"
              :remote-method="filter"
              placeholder="Enter name to start searching"
              filterable
            >
              <el-option
                v-for="item in entities"
                :label="item.name"
                :key="item._id"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="add">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <additional-questions-data
      :regiProduct="clubRegoAQ"
      v-if="clubVisible"
      id="club"
      :heading="'Club'"
      :editData="editableClub"
      :removeData="removalClub"
    />
    <additional-questions-data
      :regiProduct="assocRegoAQ"
      v-if="assocVisible"
      id="Association"
      :heading="'Association'"
      :editData="editableAssoc"
      :removeData="removalAssoc"
    />
    <additional-questions-data
      :regiProduct="nationalRegoAQ"
      v-if="nationalVisible"
      id="National"
      :heading="'National'"
      :editData="editableNational"
      :removeData="removalNational"
    />
    <additional-questions-data
      :regiProduct="stateRegoAQ"
      v-if="stateVisible"
      id="State"
      :heading="'State'"
      :editData="editableState"
      :removeData="removalState"
    />
    <additional-questions-data
      :regiProduct="regionRegoAQ"
      v-if="regionVisible"
      id="Region"
      :heading="'Region'"
      :editData="editableRegion"
      :removeData="removalRegion"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg, genders, additionalQTypes, memberTypes, roles } from "@/utils/constants";
import AdditionalQuestionsData from "./AdditionalQuestionsData.vue";

const initAdditionalQuestion = () =>
  _.cloneDeep({
    type: undefined,
    question: undefined,
    fieldName: undefined,
    ageFrom: undefined,
    ageTo: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    gender: undefined,
    memberType: undefined,
    required: undefined,
    isActive: true,
    meta: {
      options: undefined,
      entityType: undefined,
      entityIds: undefined,
    },
  });

const addNewOption = (options) => {
  options.push({ type: "string", value: "" });
};

export default {
  props: [
    "regiProduct",
    "nationalRegoAQ",
    "stateRegoAQ",
    "regionRegoAQ",
    "assocRegoAQ",
    "clubRegoAQ",
  ],
  components: {
    AdditionalQuestionsData,
  },
  data() {
    const defaultRequiredRule = { required: true, trigger: "blur", message: errormsg.input_option };
    const checkOptions = (rule, value, callback) => {
      if (!value || value.length === 0) return callback(new Error("Options is required"));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < value.length; i++) {
        if (value[i].value === "") return callback(new Error("Option value is required."));
      }
      return callback();
    };
    const validateAgeFrom = (rule, value, callback) => {
      if (!this.additionalQuestion.ageFrom && this.additionalQuestion.ageTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateAgeTo = (rule, value, callback) => {
      if (!this.additionalQuestion.ageTo && this.additionalQuestion.ageFrom) {
        return callback(new Error(errormsg.input_option));
      }
      if (parseInt(this.additionalQuestion.ageTo) < parseInt(this.additionalQuestion.ageFrom)) {
        return callback(new Error(errormsg.gte_age));
      }
      return callback();
    };

    const validateDateFrom = (rule, value, callback) => {
      if (!this.additionalQuestion.dateFrom && this.additionalQuestion.dateTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDateTo = (rule, value, callback) => {
      if (!this.additionalQuestion.dateTo && this.additionalQuestion.dateFrom) {
        return callback(new Error(errormsg.input_option));
      }
      if (this.additionalQuestion.dateTo < this.additionalQuestion.dateFrom) {
        return callback(new Error(errormsg.gte_date));
      }
      return callback();
    };
    return {
      memberTypes,
      entities: [],
      roles,
      additionalQuestion: initAdditionalQuestion(),
      additionalQTypes,
      genders,
      rules: {
        type: defaultRequiredRule,
        fieldName: [
          defaultRequiredRule,
          {
            min: 0,
            max: 25,
            message: "Length must be less than 25 chars",
            trigger: "blur",
          },
        ],
        question: defaultRequiredRule,
        memberType: { required: true, trigger: "blur", message: errormsg.input_option },
        gender: { required: true, trigger: "blur", message: errormsg.input_option },
        ageTo: {
          required: !this.$store.getters["user/activeRole"].national_id === 31,
          validator: validateAgeTo,
        },
        ageFrom: {
          required: !this.$store.getters["user/activeRole"].national_id === 31,
          validator: validateAgeFrom,
          message: errormsg.input_option,
        },
        dateTo: {
          required: this.$store.getters["user/activeRole"].national_id === 31,
          validator: validateDateTo,
        },
        dateFrom: {
          required: this.$store.getters["user/activeRole"].national_id === 31,
          validator: validateDateFrom,
          message: errormsg.input_option,
        },
        required: defaultRequiredRule,
        isActive: defaultRequiredRule,
        "meta.options": { trigger: "blur", validator: checkOptions },
        // 'meta.entityType': { trigger: 'blur', required: false, },
        // 'meta.entityIds': { trigger: 'blur', required: false, }
      },
      editingRows: [],
    };
  },
  computed: {
    clubVisible() {
      return this.$store.getters["user/activeRole"].type === 5;
    },
    nationalVisible() {
      return (
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 4 ||
        this.$store.getters["user/activeRole"].type === 1
      );
    },
    stateVisible() {
      return (
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 4 ||
        this.$store.getters["user/activeRole"].type === 2
      );
    },
    regionVisible() {
      return (
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 4 ||
        this.$store.getters["user/activeRole"].type === 3
      );
    },
    assocVisible() {
      return (
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 4
      );
    },
    editableClub() {
      if (this.$store.getters["user/activeRole"].type === 5) return true;
      else return false;
    },
    editableAssoc() {
      if (this.$store.getters["user/activeRole"].type === 4) return true;
      else return false;
    },
    editableState() {
      if (this.$store.getters["user/activeRole"].type === 2) return true;
      else return false;
    },
    editableRegion() {
      if (this.$store.getters["user/activeRole"].type === 3) return true;
      else return false;
    },
    editableNational() {
      if (this.$store.getters["user/activeRole"].type === 1) return true;
      else return false;
    },
    removalClub() {
      if (this.$store.getters["user/activeRole"].type === 5) return true;
      else return false;
    },
    removalAssoc() {
      if (this.$store.getters["user/activeRole"].type === 4) return true;
      else return false;
    },
    removalState() {
      if (this.$store.getters["user/activeRole"].type === 2) return true;
      else return false;
    },
    removalRegion() {
      if (this.$store.getters["user/activeRole"].type === 3) return true;
      else return false;
    },
    removalNational() {
      if (this.$store.getters["user/activeRole"].type === 1) return true;
      else return false;
    },
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
  },
  methods: {
    filter: _.debounce(function hello(query) {
      if (query !== "") {
        this.$store.commit("root/loading", true);
        this.$http
          .get("/nrl/api/v1/admin/entity-search", {
            params: { entity: this.additionalQuestion.meta.entityType, name: query },
          })
          .then((res) => {
            this.entities = res.data.data;
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      }
    }, 500),
    isAgeValid(object) {
      if (object.ageTo < object.ageFrom) {
        this.$customError(errormsg.gte_age);
        return false;
      }
      return true;
    },
    isDateValid(object) {
      if (object.dateTo < object.dateFrom) {
        this.$customError(errormsg.gte_date);
        return false;
      }
      return true;
    },
    isColumnNameValid(questions, question) {
      if (_.find(questions, (q) => q.fieldName === question.fieldName)) {
        this.$customError("Report column name already exists");
        return false;
      }
      return true;
    },
    add() {
      const isTFA = !this.$store.getters["user/activeRole"].national_id === 31;
      this.$refs.form.validate((valid) => {
        if (!valid) return;

        // Check if age is valid
        if (!isTFA)
          if (!this.isAgeValid(this.additionalQuestion)) return;
          else if (!this.isDateValid(this.additionalQuestion)) return;

        // Check if report column name exists
        if (!this.isColumnNameValid(this.regiProduct.additionalQuestions, this.additionalQuestion))
          return;

        // Trim
        this.additionalQuestion.fieldName = _.trim(this.additionalQuestion.fieldName);

        // Event Edit
        this.$store.commit("root/loading", true);
        this.$http
          .post(
            "/nrl/api/v1/admin/registration-products/additional-questions",
            this.additionalQuestion
          )
          .then((res) => {
            this.additionalQuestion._id = res.data.data._id;
            this.regiProduct.additionalQuestions.push(this.additionalQuestion);
            this.additionalQuestion = initAdditionalQuestion();
            this.$customSuccess("Successful");
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      });
    },
    edit(rowIndex) {
      const isTFA = !this.$store.getters["user/activeRole"].national_id === 31;
      if (this.editingRows[rowIndex]) {
        // Save row
        const additionalQuestion = this.regiProduct.additionalQuestions[rowIndex];
        additionalQuestion.fieldName = _.trim(additionalQuestion.fieldName);

        // Check if age is valid
        if (!isTFA)
          if (!this.isAgeValid(this.additionalQuestion)) return;
          else if (!this.isDateValid(this.additionalQuestion)) return;

        if (!isTFA) {
          delete this.additionalQuestion.dateTo;
          delete this.additionalQuestion.dateFrom;
        }

        // Check if report column name exists
        const checkQs = _.cloneDeep(this.regiProduct.additionalQuestions);
        _.pullAt(checkQs, [rowIndex]);
        if (!this.isColumnNameValid(checkQs, additionalQuestion)) return false;

        this.$store.commit("root/loading", true);
        this.$http
          .put(
            `/nrl/api/v1/admin/registration-products/additional-questions/${additionalQuestion._id}`,
            additionalQuestion
          )
          .then(() => {
            this.$set(this.editingRows, rowIndex, false);
            this.$customSuccess("Successful");
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
            this.$set(this.editingRows, rowIndex, true);
          });
      } else this.$set(this.editingRows, rowIndex, true);
      return true;
    },
    remove(rowIndex) {
      this.$confirm("Are you sure you want to remove this additional question?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        // Event Edit
        const additionalQuestion = this.regiProduct.additionalQuestions[rowIndex];
        this.$store.commit("root/loading", true);
        this.$http
          .delete(
            `/nrl/api/v1/admin/registration-products/additional-questions/${additionalQuestion._id}`
          )
          .then(() => {
            this.regiProduct.additionalQuestions.splice(rowIndex, 1);
            this.$customSuccess("Successful");
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      });
    },
    addOption(options) {
      addNewOption(options);
    },
  },
  watch: {
    // eslint-disable-next-line
    "additionalQuestion.type": function (val) {
      if (val === 2 || val === 4) {
        this.additionalQuestion.meta.options = [];
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
      } else this.additionalQuestion.meta.options = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-input-number {
  width: 100%;
}
</style>
