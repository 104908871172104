<template>
  <div>
    <el-col :span="18" class="btn-container mb-2">
      <h3 v-if="!!title">{{ title }}</h3>
    </el-col>
    <el-form label-position="top" :model="competition" id="drawbuilder" ref="drawbuilder">
      <el-row :gutter="10" align="bottom">
        <el-col :span="3">
          <el-form-item class="text-right">
            <el-button type="success" @click="handleBack()" v-if="currentStage > 0">
              Back</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="currentStage === 0">
        <el-col :span="12">
          <el-form-item label="Redo Draw from Round">
            <el-input-number
              :controls="false"
              :min="minRound"
              :max="maxRound"
              :precision="0"
              v-model="fromRound"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="currentStage === 1" class="mb-2 mt-2 text-center">
        <el-col :span="8">
          <h2>Teams</h2>
        </el-col>
        <el-col :span="16">
          <h2>Draw</h2>
        </el-col>
      </el-row>
      <div v-if="currentStage === 1">
        <el-row class="mb-2" :gutter="10" v-for="pool in pools" :key="pool.name">
          <el-col :span="8">
            <div class="seeding">Seeding ({{ pool.name }})</div>
            <draggable :list="pool.teams" @change="rebuildDraw">
              <el-card v-for="(team, index) in pool.teams" :key="index" shadow="hover"
                >{{ index + 1 }} {{ team.name }}</el-card
              >
            </draggable>
          </el-col>
          <el-col :span="16">
            <el-table :data="pool.matches" height="600">
              <el-table-column prop="round" label="Round" width="150"></el-table-column>
              <el-table-column label="Home Team" width="auto">
                <template slot-scope="scope">
                  <span>{{ scope.row.home.name }} ({{ scope.row.home.seed }})</span>
                </template>
              </el-table-column>
              <el-table-column label="Away Team" width="auto">
                <template slot-scope="scope">
                  <span>{{ scope.row.away.name }} ({{ scope.row.away.seed }})</span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div
        v-if="currentStage === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="12">
          <el-form-item label="Select a final template (optional)">
            <el-select
              value-key="type"
              v-model="finalTemplate"
              placeholder="Select a final template"
              filterable
              clearable
            >
              <el-option
                v-for="item in allFinalTemplates"
                :key="item.type"
                :label="item.type"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <el-table
        v-if="currentStage === 2 && finalTemplate.type !== ''"
        :data="finalTemplate.matches"
        :border="true"
      >
        <el-table-column prop="Round Display" label="Round Name" width="auto"></el-table-column>
        <el-table-column prop="Round Number" label="Round Number" width="auto"></el-table-column>
        <el-table-column prop="Match" label="Match Number" width="auto"></el-table-column>
        <el-table-column prop="matchup" label="Match Up" width="auto"></el-table-column>
      </el-table>
      <el-row :gutter="10" v-if="currentStage === 3">
        <el-col :span="12">
          <div>
            <h2>
              Dates For Regular Rounds
              <br />
              <br />
            </h2>
          </div>
        </el-col>
      </el-row>
      <div v-if="currentStage === 3">
        <el-row :gutter="10" v-for="pool in pools" :key="pool.name">
          <h4 class="pool-name">
            <span v-if="pools.length > 1">Pool:</span>
            {{ pool.name }}
          </h4>
          <el-col :span="12">
            <el-table :data="pool.roundDates" :border="true">
              <el-table-column prop="round" label="Round" width="150">
                <template slot-scope="scope">
                  <el-input
                    autocomplete="off"
                    type="number"
                    placeholder="Round"
                    v-model="scope.row.number"
                    disabled
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Round Start Date" width="auto">
                <template slot-scope="scope">
                  <el-date-picker
                    format="dd/MM/yyyy"
                    value-format="timestamp"
                    type="date"
                    v-model="scope.row.time"
                    placeholder="Enter round date"
                    :clearable="false"
                  />
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="10" v-if="currentStage === 3 && finalRoundDates.length > 0">
        <el-col :span="12">
          <div>
            <br />
            <br />
            <h2>Dates For Finals</h2>
            <br />
            <br />
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="currentStage === 3 && finalRoundDates.length > 0">
        <el-col :span="12">
          <el-table :data="finalRoundDates" :border="true">
            <el-table-column prop="round" label="Round" width="150">
              <template slot-scope="scope">
                <el-input
                  autocomplete="off"
                  type="number"
                  placeholder="Round"
                  v-model="scope.row.number"
                  disabled
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Round Start Date" width="auto">
              <template slot-scope="scope">
                <el-date-picker
                  format="dd/MM/yyyy"
                  value-format="timestamp"
                  type="date"
                  v-model="scope.row.time"
                  placeholder="Enter round date"
                  :clearable="false"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="currentStage === 4">
        <el-col :span="12">
          <div>
            <h2>Edit Draws</h2>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="currentStage === 4">
        <el-col :span="12">
          <div>
            <h3>Regular Season</h3>
          </div>
        </el-col>
      </el-row>
      <div v-if="currentStage === 4">
        <el-row class="mb-2" v-for="pool in pools" :key="pool.name">
          <h4>
            <span v-if="pools.length > 1">Pool:</span>
            {{ pool.name }}
          </h4>
          <el-table :data="pool.regularRoundMatches" :border="true">
            <el-table-column
              align="center"
              prop="round.number"
              label="Round"
              width="100"
            ></el-table-column>
            <el-table-column align="center" label="Home Team" width="auto">
              <template slot-scope="scope">
                <el-select
                  value-key="_id"
                  v-model="scope.row.homeTeam"
                  placeholder="Select team"
                  filterable
                >
                  <el-option
                    v-for="item in pool.teams"
                    :label="item.name"
                    :key="item._id"
                    :value="item"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Away Team" width="auto">
              <template slot-scope="scope">
                <el-select
                  value-key="_id"
                  v-model="scope.row.awayTeam"
                  placeholder="Select team"
                  filterable
                >
                  <el-option
                    v-for="item in pool.teams"
                    :label="item.name"
                    :key="item._id"
                    :value="item"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Date & Time" width="auto">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.dateTime"
                  type="datetime"
                  placeholder="Select date and time"
                  format="dd/MM/yyyy HH:mm"
                  value-format="timestamp"
                  :clearable="false"
                ></el-date-picker>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Venue" width="auto">
              <template slot-scope="scope">
                <el-select
                  value-key="_id"
                  v-model="scope.row.venue"
                  placeholder="Select venue/type to search"
                  :remote="true"
                  :remote-method="filter"
                  filterable
                >
                  <el-option
                    v-for="item in allVenues"
                    :label="item.name"
                    :key="item._id"
                    :value="item"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Field No" width="auto">
              <template slot-scope="scope">
                <el-input
                  maxlength="10"
                  v-model="scope.row.meta.fieldNo"
                  placeholder="Field No"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
      <el-row :gutter="10" v-if="currentStage === 4 && finalTemplate.matches.type !== ''">
        <el-col :span="12">
          <div>
            <h3>Finals Rounds</h3>
          </div>
        </el-col>
      </el-row>
      <el-table v-if="currentStage === 4" :data="finalRoundMatches" :border="true">
        <el-table-column
          align="center"
          prop="round.number"
          label="Round"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="round.displayName"
          label="Round Name"
          width="auto"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="homeTeam.name"
          label="Home Team"
          width="auto"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="awayTeam.name"
          label="Away Team"
          width="auto"
        ></el-table-column>
        <el-table-column align="center" label="Date & Time" width="auto">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.dateTime"
              type="datetime"
              placeholder="Select date and time"
              format="dd/MM/yyyy HH:mm"
              value-format="timestamp"
              :clearable="false"
            ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Venue" width="auto">
          <template slot-scope="scope">
            <el-select
              value-key="_id"
              v-model="scope.row.venue"
              placeholder="Select venue/type to search"
              :remote="true"
              :remote-method="filter"
              filterable
            >
              <el-option
                v-for="item in allVenues"
                :label="item.name"
                :key="item._id"
                :value="item"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Field No" width="auto">
          <template slot-scope="scope">
            <el-input
              maxlength="10"
              v-model="scope.row.meta.fieldNo"
              placeholder="Field No"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-col :span="21" class="btn-container mb-2">
        <el-button type="success" @click="handleNext()" v-if="currentStage < 4">Next</el-button>
        <el-button type="success" @click="handlePublishDraw()" v-if="currentStage == 4"
          >Publish Draw</el-button
        >
        <el-button @click="cancelForm()">Cancel</el-button>
      </el-col>
    </el-form>
  </div>
</template>

<script>
import moment from "moment-timezone";
import draggable from "vuedraggable";
import { errormsg, finalTemplates, nationals } from "../../utils/constants";
import drawImportMixin from "../../mixins/draw-import";

const _ = require("lodash");

// TMP
const roundrobin = (teams, nor, startRound = 0) => {
  const result = [];
  const teamSeeds = teams.map((team, index) => ({ team, seed: index + 1 }));
  const half = parseInt(teams.length / 2);
  const group1 = teams.slice(0, half);
  let group2 = teams.slice(half, teams.length);
  group2 = group2.reverse();
  for (let index = startRound; index < nor; index += 1) {
    for (let team = 0; team < half; team += 1) {
      // Flip home-away between rounds
      if (index % 2 !== 0) {
        result.push({
          round: index + 1,
          away: {
            ...group1[team],
            seed: teamSeeds.find(t => t.team.name === group1[team].name).seed
          },
          home: {
            ...group2[team],
            seed: teamSeeds.find(t => t.team.name === group2[team].name).seed
          }
        });
      } else {
        result.push({
          round: index + 1,
          home: {
            ...group1[team],
            seed: teamSeeds.find(t => t.team.name === group1[team].name).seed
          },
          away: {
            ...group2[team],
            seed: teamSeeds.find(t => t.team.name === group2[team].name).seed
          }
        });
      }
    }
    // ROUND ROBIN CLOCKWISE CIRCLE
    const dontmove = group1.shift(); // DO NOT MOVE
    const lastElement = group1.pop(); // LAST MOVE DOWN
    const elementFirst = group2.shift(); // FIRST ELEMENT MOVE UP
    group1.unshift(elementFirst);
    group1.unshift(dontmove);
    group2.push(lastElement);
  }
  return result;
};

// Check if any regular round match has same home and away team
const checkSameTeam = matches =>
  !matches.some(
    match =>
      _.get(match, "homeTeam._id") !== "TBA" &&
      _.get(match, "homeTeam._id") === _.get(match, "awayTeam._id")
  );
const checkTbaByeMatch = matches =>
  matches.some(
    match =>
      (_.get(match, "homeTeam._id") === "TBA" && _.get(match, "awayTeam._id") === "BYE") ||
      (_.get(match, "homeTeam._id") === "BYE" && _.get(match, "awayTeam._id") === "TBA")
  );

export default {
  name: "RegradingMatchesDrawBuilder",
  components: { draggable },
  props: {
    title: String,
    isDest: Boolean,
    value: Object,
    active: Number
  },
  mixins: [drawImportMixin],
  data() {
    return {
      fromRound: 0,
      minRound: 0,
      maxRound: 0,
      nationals,
      currentStage: 0,
      editingMatches: false,
      allComps: [],
      allVenues: [],
      roundDates: [],
      finalRoundDates: [],
      relatedClubs: [],
      currentTeams: [],
      currentDraw: [],
      finalDraw: [],
      regularRoundMatches: [],
      finalRoundMatches: [],
      allFinalTemplates: finalTemplates,
      finalTemplate: {
        type: "",
        teamnum: -1,
        matches: []
      },
      competition: undefined,
      pools: []
    };
  },
  mounted() {
    const id =
      this.active === 1 ? this.value.destCompetition._id : this.value.sourceCompetition._id;
    // Generate Round Robin Matches

    this.$store.commit("root/loading", true);
    Promise.all([
      this.$http.get(`/nrl/api/v1/admin/competitions/${id}`),
      this.$http.get(`/nrl/api/v1/admin/matches/competition/${id}`)
    ])
      .then(([compRes, matchRes]) => {
        this.$store.commit("root/loading", false);
        this.competition = compRes.data.data;

        if (this.active === 1) this.competition.teams.push(this.value.regradeTeam);
        if (this.active !== 1)
          this.competition.teams = this.competition.teams.filter(
            team => team._id !== this.value.regradeTeam._id
          );
        // if a destination
        // eslint-disable-next-line no-multi-assign
        this.fromRound = this.minRound = this.getMinRedoRound(this.competition, matchRes.data.data);
        this.maxRound = this.regularRounds;
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  methods: {
    cancelForm() {
      this.hideDrawBuilder();
      // this.$router.push({
      //   name: "matches.list",
      //   params: {
      //     currentCompetition: this.competition._id
      //   }
      // });
    },
    handleNext() {
      if (this.currentStage === 0) {
        // pools
        if (this.competition.hasPools && this.competition.pools && this.competition.pools.length) {
          this.pools = this.competition.pools;
          this.pools.forEach((pool, index) => {
            if (pool.teams.length % 2 !== 0)
              this.pools[index].teams.push({ _id: "BYE", name: "BYE " });
          });
        } else {
          this.pools = [{ name: this.competition.name, teams: this.competition.teams }];
          if (this.pools[0].teams.length % 2 !== 0)
            this.pools[0].teams.push({ _id: "BYE", name: "BYE" });
        }

        // pools
        this.pools = this.pools.map(pool => ({
          ...pool,
          matches: roundrobin(pool.teams, this.competition.regularSeasonRounds, this.fromRound - 1),
          roundDates: _.range(this.fromRound, this.competition.regularSeasonRounds + 1).map(
            round => ({
              number: round,
              time: moment(this.competition.drawbuilder.defaultStartDay)
                .add(this.competition.drawbuilder.daysBetweenRounds * (round - 1), "days")
                .valueOf()
            })
          )
        }));
        // Stage 1 - Drag and drop to change team seeding, set regular round dates is done in stage 0
        this.currentStage += 1;
      } else if (this.currentStage === 1) {
        this.currentStage += 1;
        // Stage 2 - Pick Finals Template, gives error if comp's num of team is less than required
        // Generates empty matches for finals
      } else if (this.currentStage === 2) {
        if (this.finalTemplate.type && this.finalTemplate.type !== "") {
          const compteamnum = this.competition.teams.length;
          if (compteamnum < this.finalTemplate.teamnum) {
            this.$customError(errormsg.draw_finalneedmoreteam);
            return false;
          }
          this.finalDraw = [];
          this.finalRoundDates = this.finalTemplate.matches
            .map(m => m["Round Number"])
            .filter((v, index, self) => self.indexOf(v) === index)
            .sort((a, b) => a - b)
            .map((r, index) => ({
              number: r,
              time:
                this.competition.drawbuilder.defaultStartDay != null &&
                this.competition.drawbuilder.daysBetweenRounds != null
                  ? moment(this.competition.drawbuilder.defaultStartDay)
                      .add(
                        this.competition.drawbuilder.daysBetweenRounds *
                          (this.competition.regularSeasonRounds + index),
                        "days"
                      )
                      .valueOf()
                  : ""
            }));

          this.finalTemplate.matches.forEach(match => {
            this.finalDraw.push({
              away: { _id: "TBA", name: "TBA" },
              home: { _id: "TBA", name: "TBA" },
              round: match["Round Number"],
              roundname: match["Round Display"]
            });
          });
        } else {
          this.finalDraw = [];
          this.finalRoundDates = [];
        }
        this.currentStage += 1;
        // Stage 3 - Calculate round dates based on compeition default data
      } else if (this.currentStage === 3) {
        this.$store.commit("root/loading", true);
        // NRL -- Needs venues from club.venue field
        const url = `/nrl/api/v1/admin/venues/competition/${this.competition._id}`;
        this.$http
          .post(url)
          .then(response => {
            const compTeamVenues = response.data.data;
            this.allVenues = _.uniqBy(_.map(compTeamVenues, "teamvenue"), "_id");

            if (this.isTFA) {
              this.allVenues = [this.competition.meta.venue];
            }

            this.pools.forEach((pool, index) => {
              this.pools[index] = {
                ...pool,
                regularRoundMatches: pool.matches.map(draw => ({
                  name: `${draw.home.name} vs ${draw.away.name}`,
                  status: "pre-game",
                  homeTeam: draw.home,
                  awayTeam: draw.away,
                  meta: {
                    isBye: draw.home._id === "BYE" || draw.away._id === "BYE",
                    isTba: false
                  },
                  competition: this.competition,
                  venue: this.isTFA
                    ? this.competition.meta.venue
                    : _.find(compTeamVenues, tm => tm._id === draw.home._id)
                    ? _.find(compTeamVenues, tm => tm._id === draw.home._id).teamvenue
                    : { _id: null, name: null, venueTimezone: null },
                  scores: { homeTeam: 0, awayTeam: 0 },
                  round: {
                    type: "Regular",
                    displayName: "Round".concat(" ").concat(draw.round.toString()),
                    number: draw.round
                  },
                  dateTime: moment(
                    moment(_.find(pool.roundDates, date => date.number === draw.round).time)
                      .format("YYYY-MM-DD")
                      .concat(" ")
                      .concat(this.competition.drawbuilder.defaultStartTime),
                    "YYYY-MM-DD HH:mm"
                  ).valueOf()
                }))
              };
            });

            // FINAL ROUND MATCHES
            this.finalRoundMatches = [];
            _.each(this.finalDraw, draw => {
              this.finalRoundMatches.push({
                name: `${draw.home.name} vs ${draw.away.name}`,
                status: "pre-game",
                homeTeam: draw.home,
                awayTeam: draw.away,
                meta: { isBye: false, isTba: true },
                competition: this.competition,
                venue: { _id: null, name: null, venueTimezone: null },
                scores: { homeTeam: 0, awayTeam: 0 },
                round: {
                  type: "Final",
                  displayName: draw.roundname,
                  number: draw.round
                },
                dateTime: moment(
                  moment(_.find(this.finalRoundDates, date => date.number === draw.round).time)
                    .format("YYYY-MM-DD")
                    .concat(" ")
                    .concat(this.competition.drawbuilder.defaultStartTime),
                  "YYYY-MM-DD HH:mm"
                ).valueOf()
              });
            });
            this.$store.commit("root/loading", false);
            this.currentStage += 1;
          })
          .catch(e => {
            this.$store.commit("root/loading", false);
            this.$customError(e);
          });
      }
      return true;
    },
    handleBack() {
      this.currentStage -= 1;
    },
    async handlePublishDraw() {
      const confirm = await this.$confirm(
        "Are you sure you want to replace existing matches? This cannot be undone.",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      );
      if (!confirm) return;

      const validate = this.pools.every(pool => {
        // do not allow TBA and BYE in a match
        if (checkTbaByeMatch(pool.regularRoundMatches)) {
          this.$store.commit("root/loading", false);
          this.$customError(errormsg.draw_tbabyematch);
          return false;
        }

        if (!checkSameTeam(pool.regularRoundMatches)) {
          this.$store.commit("root/loading", false);
          this.$customError(errormsg.draw_sameteam);
          return false;
        }
        return true;
      });

      if (!validate) return;

      this.$store.commit("root/loading", true);

      const formattedPools = this.pools.map(pool => ({
        ...pool,
        regularRoundMatches: pool.regularRoundMatches.map(match => {
          if (match.homeTeam._id === "BYE" || match.homeTeam._id === "TBA") {
            delete match.homeTeam;
          }
          if (match.awayTeam._id === "BYE" || match.awayTeam._id === "TBA") {
            delete match.awayTeam;
          }
          return match;
        })
      }));

      // Remove Bye and TBA teams when publishing on final page
      _.each(this.finalRoundMatches, match => {
        if (match.homeTeam._id === "BYE" || match.homeTeam._id === "TBA") {
          delete match.homeTeam;
        }
        if (match.awayTeam._id === "BYE" || match.awayTeam._id === "TBA") {
          delete match.awayTeam;
        }
      });

      const matches = _.flatMap(formattedPools, "regularRoundMatches").concat(
        this.finalRoundMatches
      );

      this.$http
        .post("/nrl/api/v1/admin/matches/redo-draw", {
          matches,
          competitionId: this.competition._id,
          redoFromRound: this.fromRound
        })
        .then(() => {
          this.$customSuccess();
          this.$store.commit("root/loading", false);
          this.hideDrawBuilder();
          this.$emit("nextStep");
          if (this.active === 2) {
            this.$router.push({
              name: "matches.list",
              params: {
                currentCompetition: this.competition._id
              }
            });
          }
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    rebuildDraw() {
      this.$store.commit("root/loading", true);
      this.pools = this.pools.map(pool => ({
        ...pool,
        matches: roundrobin(pool.teams, this.competition.regularSeasonRounds, this.fromRound - 1)
      }));
      this.$store.commit("root/loading", false);
    },
    filter(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then(response => {
              for (let index = 0; index < response.data.data.length; index += 1) {
                const v = response.data.data[index];
                const allVenuesUpdated = this.allVenues.filter(venue => venue._id !== v._id);
                allVenuesUpdated.push(v);
                this.allVenues = allVenuesUpdated;
              }
            })
            .catch(() => {});
        }, 1000);
      }
    },
    hideDrawBuilder() {
      this.$emit("showDrawBuilder", false);
    }
  },
  computed: {
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === nationals.TFA;
    }
  },
  watch: {
    finalTemplate(val) {
      if (!val || val === "") {
        this.finalTemplate = {
          type: "",
          teamnum: -1,
          matches: []
        };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.data-table {
  width: 100%;
}
.el-pagination {
  margin-top: 1rem !important;
}
h2 {
  margin: 0 !important;
}
li {
  list-style: none;
  margin-bottom: 1rem !important;
}
ul {
  padding-inline-start: 10px !important;
  padding-top: 20px;
}
.listcontainer {
  border-width: 1px;
  border-style: solid;
  border-color: #ebeef5;
  margin-top: 0px !important;
  .drag-item {
    cursor: pointer;
  }
}
.seeding {
  height: 48px;
  line-height: 48px;
  font-weight: bold;
  color: black;
  background-color: #ebeef5;
  padding-left: 10px;
}
.drawcontainer {
  max-height: 200px;
  overflow-y: scroll;
}
.btn-container {
  margin-top: 2rem;
  text-align: left;
}
.el-select {
  width: 80%;
}
.el-date-picker {
  width: 80%;
}

.el-card {
  cursor: pointer;
  background: transparent;
  background-color: transparent;

  .el-card__body {
    padding: 12px;
  }
}

.text-center {
  text-align: center;
}

.pool-name {
  margin-left: 5px;
}
</style>
