<template>
  <el-row class="btn-container">
    <el-button name="success" id="success" type="success" v-if="submit" @click="submitForm">{{
      submitMessage
    }}</el-button>
    <el-button v-if="cancel" @click="cancelForm"> {{ cancelMessage }} </el-button>
  </el-row>
</template>

<script>
export default {
  name: "ActionButtons",
  props: {
    submit: { type: Boolean },
    cancel: { type: Boolean },
    submitText: { type: String },
    cancelText: { type: String }
  },
  methods: {
    submitForm() {
      this.$emit("submit");
    },
    cancelForm() {
      this.$emit("cancel");
    }
  },
  data() {
    return {
      submitMessage: this.submitText || "Save",
      cancelMessage: this.cancelText || "Cancel"
    };
  }
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}
</style>
