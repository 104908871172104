<template>
  <div>
    <el-form
      label-position="top"
      :model="comp.meta"
      :rules="metaRules"
      id="metaRules"
      ref="metaRules"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="enableAwards" label="Awards">
            <el-select v-model="comp.meta.enableAwards">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="comp.meta.enableAwards">
        <el-col :span="12">
          <el-form-item prop="teamOfYearVoting" label="Team Of Year Voting">
            <el-select v-model="comp.meta.awardsSettings.teamOfYear">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="allowDemerits" label="Allow Demerit Points">
            <el-select v-model="comp.meta.awardsSettings.demerits">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="comp.meta.enableAwards">
        <el-col :span="8">
          <el-form-item prop="periodVoting" label="Interval Voting">
            <el-select v-model="allowPeriodVoting">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="allowPeriodVoting">
          <el-form-item prop="periodNo" label="Number of Intervals">
            <el-select
              v-model="comp.meta.awardsSettings.periodVoting"
              @change="updateIntervalObject"
            >
              <el-option label="1" :value="1" />
              <el-option label="2" :value="2" />
              <el-option label="3" :value="3" />
              <el-option label="4" :value="4" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        :gutter="10"
        v-if="comp.meta.enableAwards && allowPeriodVoting && comp.meta.awardsSettings.periodVoting"
      >
        <el-col :span="6" v-for="p in comp.meta.awardsSettings.periodVoting" :key="p">
          <el-form-item
            v-if="
              p === 1 ||
              (periodRangeObj[p - 1] && periodRangeObj[p - 1].length && periodRangeObj[p])
            "
            :label="`Add Round Ranges for Interval ${p}`"
          >
            <div class="interval-box tw-flex-wrap">
              <div class="min-width">
                <span>Start of Round</span>
                <el-input-number
                  v-model="periodRangeObj[p][0]"
                  :min="
                    p !== 1 && periodRangeObj[p - 1] && periodRangeObj[p - 1].length
                      ? periodRangeObj[p - 1][1] + 1
                      : 1
                  "
                  :max="
                    periodRangeObj &&
                    periodRangeObj[p + 1] &&
                    periodRangeObj[p + 1].length &&
                    periodRangeObj[p][1] &&
                    periodRangeObj[p][1] > periodRangeObj[p + 1][0] - 2
                      ? periodRangeObj[p + 1][0] - 2
                      : periodRangeObj[p] && periodRangeObj[p][1]
                      ? periodRangeObj[p][1] - 1
                      : comp.regularSeasonRounds || 25
                  "
                  @change="capture(p)"
                  size="small"
                  step-strictly
                ></el-input-number>
              </div>
              <div class="min-width">
                <span>End of Round</span>
                <el-input-number
                  v-model="periodRangeObj[p][1]"
                  :min="
                    periodRangeObj[p - 1] &&
                    periodRangeObj[p - 1].length &&
                    periodRangeObj[p][0] &&
                    periodRangeObj[p][0] < periodRangeObj[p - 1][1] + 1
                      ? periodRangeObj[p - 1][1] + 1
                      : periodRangeObj[p] && periodRangeObj[p][0]
                      ? periodRangeObj[p][0] + 1
                      : 2
                  "
                  :max="
                    periodRangeObj[p + 1] && periodRangeObj[p + 1].length
                      ? periodRangeObj[p + 1][0] - 1
                      : comp.regularSeasonRounds || 25
                  "
                  @change="capture(p)"
                  size="small"
                  step-strictly
                ></el-input-number>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="comp.meta.enableAwards">
        <el-col :span="12">
          <el-form-item prop="allowEOS" label="End of Season Categories">
            <el-checkbox-group v-model="comp.meta.awardsSettings.endOfSeasonVoting">
              <el-checkbox label="Captain"></el-checkbox>
              <el-checkbox label="Coach"></el-checkbox>
              <el-checkbox label="Rookie"></el-checkbox>
              <el-checkbox label="Team"></el-checkbox> </el-checkbox-group
          ></el-form-item> </el-col
      ></el-row>
      <el-row v-if="isSuperAdminNRL">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="Awards Settings List" name="AwardsSettingsList">
            <el-form
              @submit.prevent.native="addEmailToList()"
              label-position="top"
              :rules="emailListRules"
              id="emailListRules"
              ref="emailListRules"
            >
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item prop="newEmail">
                    <el-input
                      class="add-email"
                      type="text"
                      autocomplete="off"
                      v-model="newEmailData"
                      placeholder="Add an email"
                    >
                      <el-button
                        type="warning"
                        size="tiny"
                        slot="append"
                        icon="el-icon-circle-plus"
                        @click.prevent.native="addEmailToList()"
                      >
                      </el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <ul>
              <li shadow="hover" v-for="email in emailListData" :key="email" class="small-card">
                <span>{{ email }}</span>
                <i
                  class="el-icon-delete tw-border-2 tw-rounded-full tw-bg-white tw-cursor-pointer tw-p-2 tw-ml-2"
                  @click="removeEmail(email)"
                ></i>
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="Alerts Email List" name="AlertsEmailList">
            <el-form
              @submit.prevent.native="addAlertEmailToList()"
              label-position="top"
              :rules="emailListRules"
              id="emailListRules"
              ref="emailListRules"
            >
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item prop="newAlertEmail">
                    <el-input
                      class="add-email"
                      type="text"
                      autocomplete="off"
                      v-model="newAlertEmailData"
                      placeholder="Add an email"
                    >
                      <el-button
                        type="warning"
                        size="tiny"
                        slot="append"
                        icon="el-icon-circle-plus"
                        @click.prevent.native="addAlertEmailToList()"
                      >
                      </el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <ul>
              <li
                shadow="hover"
                v-for="email in alertsEmailListData"
                :key="email"
                class="small-card"
              >
                <span>{{ email }}</span>
                <i
                  class="el-icon-delete tw-border-2 tw-rounded-full tw-bg-white tw-cursor-pointer tw-p-2 tw-ml-2"
                  @click="removeAlertEmail(email)"
                ></i>
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="Alerts SMS List" name="AlertsSMSList">
            <el-form @submit.prevent.native="addAlertSMSToList()" label-position="top">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item prop="newName">
                    <el-input
                      class="add-name"
                      type="text"
                      autocomplete="off"
                      v-model="newNameData"
                      placeholder="Add a name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="newMobile">
                    <el-input
                      class="add-mobile"
                      type="text"
                      autocomplete="off"
                      v-model="newMobileData"
                      placeholder="Add a mobile number"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-button
                    type="border"
                    size="tiny"
                    icon="el-icon-circle-plus"
                    @click.prevent.native="addAlertSMSToList()"
                  >
                  </el-button>
                </el-col>
              </el-row>
            </el-form>
            <ul>
              <li
                shadow="hover"
                v-for="entry in alertsSMSListData"
                :key="entry.mobile"
                class="small-card"
              >
                <span>{{ entry.name }} - {{ entry.mobile }}</span>
                <i
                  class="el-icon-delete tw-border-2 tw-rounded-full tw-bg-white tw-cursor-pointer tw-p-2 tw-ml-2"
                  @click="removeAlertSMS(entry.mobile, entry.name)"
                ></i>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </el-row>
    </el-form>

    <action-buttons submit cancel @submit="updateMeta()" @cancel="cancelForm()" />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, roles } from "../../utils/constants";
import { EventBus } from "../../bus";

const validateContactEmail = (rule, value, callback) => {
  if (!value || !validateEmail(value)) {
    return callback(new Error(errormsg.email));
  }
  return callback();
};

export default {
  data() {
    const validateRoundRanges = (rule, value, callback) => {
      const { periodRanges, periodVoting } = this.comp.meta.awardsSettings;
      if (
        this.allowPeriodVoting &&
        periodVoting &&
        (!periodRanges || periodRanges.filter((p) => p).length !== periodVoting)
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validatePeriodNo = (rule, value, callback) => {
      const { allowPeriodVoting } = this;
      if (allowPeriodVoting && !this.comp.meta.awardsSettings.periodVoting) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    return {
      metaRules: {
        enableAwards: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        periodVoting: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        periodNo: {
          required: this.allowPeriodVoting,
          validator: validatePeriodNo,
          trigger: "blur",
        },
        teamOfYearVoting: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        allowDemerits: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        allowEOS: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        roundRanges: {
          required: false,
          validator: validateRoundRanges,
          trigger: "change",
        },
      },
      activeNames: [],
      emailListRules: {
        newEmail: {
          message: errormsg.input_option,
          trigger: "submit",
          validator: validateContactEmail,
        },
      },
      allowPeriodVoting: false,
      periodRanges: [],
      periodRangeObj: {},
      templates: {},
      newEmail: "",
      newEmailData: "",
      emailListData: [],
      newAlertEmail: "",
      newAlertEmailData: "",
      alertsEmailListData: [],
      newNameData: "",
      newMobileData: "",
      alertsSMSListData: [],
    };
  },
  components: { ActionButtons },
  props: {
    competition: { type: Object, required: true },
  },
  async mounted() {
    if (this.isSuperAdminNRL) {
      try {
        this.$store.commit("root/loading", true);
        const res = await this.$http.get("/nrl/api/v1/admin/assessment-templates");
        if (
          res &&
          res.data &&
          res.data.templates_response &&
          Array.isArray(res.data.templates_response)
        ) {
          for (const { _id, templates } of res.data.templates_response) {
            this.templates[_id] = templates;
          }
        }
        const intervalRanges =
          this.competition.meta &&
          this.competition.meta.awardsSettings &&
          this.competition.meta.awardsSettings.periodRanges;
        if (intervalRanges && Array.isArray(intervalRanges)) {
          intervalRanges.forEach((int, ind) => {
            const split = int.split("-");
            const numArr = split.map((n) => parseInt(n));
            this.periodRangeObj[ind + 1] = numArr;
          });
        }
        this.$store.commit("root/loading", false);
      } catch (error) {
        this.$store.commit("root/loading", false);
        this.$customError(error);
      }
    }
  },
  methods: {
    addEmailToList() {
      this.$store.commit("root/loading", true);
      if (this.newEmailData) {
        this.$http
          .post(`/nrl/api/v1/admin/competitions/${this.competition._id}/awards/add/email`, {
            email: this.newEmailData,
          })
          .then((response) => {
            if (this.emailListData && this.emailListData.length > 0) {
              this.emailListData.push(this.newEmailData);
            } else {
              this.emailListData = [this.newEmailData];
            }
            this.newEmailData = "";
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            console.error("Error adding email:", error);
            this.$store.commit("root/loading", false);
          });
      }
      this.$store.commit("root/loading", false);
    },
    removeEmail(email) {
      this.$store.commit("root/loading", true);
      const index = this.emailListData.indexOf(email);
      if (index !== -1) {
        this.$http
          .post(`/nrl/api/v1/admin/competitions/${this.competition._id}/awards/remove/email`, {
            email,
          })
          .then((response) => {
            this.emailListData.splice(index, 1);
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            console.error("Error removing email:", error);
            this.$store.commit("root/loading", false);
          });
      }
      this.$store.commit("root/loading", false);
    },
    addAlertEmailToList() {
      this.$store.commit("root/loading", true);
      if (this.newAlertEmailData) {
        this.$http
          .post(`/nrl/api/v1/admin/competitions/${this.competition._id}/awards-alerts/add/email`, {
            email: this.newAlertEmailData,
          })
          .then((response) => {
            if (this.alertsEmailListData && this.alertsEmailListData.length > 0) {
              this.alertsEmailListData.push(this.newAlertEmailData);
            } else {
              this.alertsEmailListData = [this.newAlertEmailData];
            }
            this.newAlertEmailData = "";
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            console.error("Error adding email:", error);
            this.$store.commit("root/loading", false);
          });
      }
      this.$store.commit("root/loading", false);
    },
    removeAlertEmail(email) {
      this.$store.commit("root/loading", true);
      const index = this.alertsEmailListData.indexOf(email);
      if (index !== -1) {
        this.$http
          .post(
            `/nrl/api/v1/admin/competitions/${this.competition._id}/awards-alerts/remove/email`,
            {
              email,
            }
          )
          .then((response) => {
            this.alertsEmailListData.splice(index, 1);
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            console.error("Error removing email:", error);
            this.$store.commit("root/loading", false);
          });
      }
      this.$store.commit("root/loading", false);
    },
    addAlertSMSToList() {
      this.$store.commit("root/loading", true);
      const mobileNumberPattern = /^[0-9]+$/;

      if (this.newNameData && this.newMobileData && mobileNumberPattern.test(this.newMobileData)) {
        this.$http
          .post(`/nrl/api/v1/admin/competitions/${this.competition._id}/awards-alerts/add/mobile`, {
            name: this.newNameData,
            mobile: this.newMobileData,
          })
          .then((response) => {
            this.alertsSMSListData.push({
              name: this.newNameData,
              mobile: this.newMobileData,
            });
            this.newNameData = "";
            this.newMobileData = "";
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            console.error("Error adding SMS alert:", error);
            this.$store.commit("root/loading", false);
          });
      } else {
        console.error(
          "Invalid input: name and mobile number are required, and mobile number must be numeric."
        );
        this.$store.commit("root/loading", false);
      }
    },
    removeAlertSMS(mobile, name) {
      this.$store.commit("root/loading", true);
      const index = this.alertsSMSListData.findIndex((entry) => entry.mobile === mobile);

      if (index !== -1) {
        this.$http
          .post(
            `/nrl/api/v1/admin/competitions/${this.competition._id}/awards-alerts/remove/mobile`,
            {
              mobile: mobile,
              name: name,
            }
          )
          .then((response) => {
            this.alertsSMSListData.splice(index, 1);
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            console.error("Error removing SMS alert:", error);
            this.$store.commit("root/loading", false);
          });
      }
    },
    capture(p) {
      const { periodRangeObj } = this;
      if (periodRangeObj[p] && Array.isArray(periodRangeObj[p]) && periodRangeObj[p].length === 2) {
        if (!this.comp.meta.awardsSettings.periodRanges) {
          this.comp.meta.awardsSettings.periodRanges = [];
        }
        this.comp.meta.awardsSettings.periodRanges[p - 1] = periodRangeObj[p].join("-");
      }
    },
    checkMin(p) {
      if (this.periodRangeObj[p - 1] && this.periodRangeObj[p - 1][1])
        return this.periodRangeObj[p - 1][1] + 1;
      return 1;
    },
    checkMax(p) {
      if (this.periodRangeObj[p + 1] && this.periodRangeObj[p + 1][0])
        return this.periodRangeObj[p + 1][0] - 1;
      return this.comp.regularSeasonRounds || 25;
    },
    updateIntervalObject() {
      const obj = {};
      const num_intervals = this.comp.meta.awardsSettings.periodVoting || 0;
      for (let index = 0; index < num_intervals; index++) {
        obj[index + 1] = [];
      }
      this.periodRangeObj = obj;
    },
    cancelForm() {
      this.$router.push({
        name: "competitions.list",
      });
    },
    updateMeta() {
      this.$refs.metaRules.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          if (!this.allowPeriodVoting) {
            this.comp.meta.awardsSettings.periodRanges = [];
            this.comp.meta.awardsSettings.periodVoting = 0;
          }
          if (
            this.allowPeriodVoting &&
            this.comp.meta.awardsSettings.periodVoting &&
            Array.isArray(this.comp.meta.awardsSettings.periodRanges) &&
            this.comp.meta.awardsSettings.periodRanges.length !==
              this.comp.meta.awardsSettings.periodVoting
          ) {
            this.comp.meta.awardsSettings.periodRanges.splice(
              this.comp.meta.awardsSettings.periodVoting
            );
          }
          const update = { meta: { ...this.comp.meta, notificationList: this.emailList } };
          const url = `/nrl/api/v1/admin/competitions/${this.comp._id}`;
          this.$http
            .put(url, update)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
              EventBus.$emit("updateCompetition", response.data.data);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
      });
    },
  },
  computed: {
    comp() {
      return { ...this.competition, meta: this.competition.meta || {} };
    },
    roundRanges() {
      const values = Object.values(this.periodRangeObj);
      const finalRanges = values.map((r) => r.join("-"));
      if (finalRanges.filter((r) => r).length === this.comp.meta.awardsSettings.periodVoting)
        this.comp.meta.awardsSettings.periodRanges = finalRanges;
      return finalRanges;
    },
    isSuperAdminNRL() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.superAdmin && national_id === 32;
    },
  },
  watch: {
    comp() {
      this.emailListData = this.competition.meta.awardsSettings
        ? this.competition.meta.awardsSettings.adminEmailList
        : [];
      this.alertsEmailListData = this.competition.meta.awardsSettings
        ? this.competition.meta.awardsSettings.alertsEmailList
        : [];
      this.alertsSMSListData = this.competition.meta.awardsSettings
        ? this.competition.meta.awardsSettings.alertsSmsList
        : [];
    },
    "comp.meta.awardsSettings.periodVoting": function (v) {
      if (v && v > 0) this.allowPeriodVoting = true;
      const obj = {};
      for (let i = 1; i <= v; i++) {
        obj[i] = [];
      }
      this.periodRangeObj = obj;
    },
    "comp.meta"() {
      const intervalRanges =
        this.competition.meta &&
        this.competition.meta.awardsSettings &&
        this.competition.meta.awardsSettings.periodRanges;
      if (intervalRanges && Array.isArray(intervalRanges)) {
        intervalRanges.forEach((int, ind) => {
          const split = int.split("-");
          const numArr = split.map((n) => parseInt(n));
          this.periodRangeObj[ind + 1] = numArr;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}

.small-card {
  margin: 0.5rem;
}

.add-email {
  text-align: center;
}

.margin-bottom {
  margin-bottom: 2rem;
}

.interval-box {
  display: flex;
  gap: 10px;
}
.min-width {
  // width: min-content;
  display: flex;
  flex-direction: column;
}
</style>
