import _ from "lodash";

export default {
  namespaced: true,
  state: {
    currentCompetition: undefined,
    filter: {
      round: undefined,
      dateRange: undefined,
      venue: undefined,
    },
    pagination: {
      currentPage: 1,
      pageSize: 15,
      total: 0,
    },
  },
  mutations: {
    update: (state, payload) => _.assign(state, payload),
  },
  getters: {
    currentComp: (state) => state.currentCompetition,
    activeFilters: (state) => state.filter,
    pagination: (state) => state.pagination,
  },
};
