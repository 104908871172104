<template>
  <div>
    <el-button icon='el-icon-plus'
      v-if="clearance === roles.superAdmin
        ||  clearance === roles.associationAdmin
        ||  clearance === roles.clubAdmin"
      type="success"
      @click="handleAddTeam" >
      Add Team
    </el-button>
    <el-row class="search-bar" :gutter="10">
      <el-col :span="6">
        <el-input
          autocomplete="off"
          v-model="queryFilterValue"
          placeholder="Search...">
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-button @click="filterVisible=true"> Filters </el-button>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>
    <data-tables-server
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      :total="total"
      @query-change="queryChange"
      @row-click="handleRowClick"
      class="data-table">
      <el-table-column
        fixed
        prop="_id"
        label="Id"
        width="100">
      </el-table-column>
      <el-table-column
        fixed
        prop="name"
        label="Name"
        width="250">
      </el-table-column>
      <el-table-column
        prop="orgtree.club.name"
        label="Club Name"
        width="250">
      </el-table-column>
      <el-table-column
        prop="orgtree.association.name"
        label="Association Name"
        width="250">
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="250">
      </el-table-column>
      <el-table-column
        prop="ageLvl"
        label="Age Group"
        width="250">
      </el-table-column>
      <el-table-column
        :formatter="competitionFormatter"
        prop="competitions"
        label="Competitions"
        width="250">
      </el-table-column>
    </data-tables-server>

  </div>
</template>

<script>
import { roles } from '@/utils/constants';
import FilterPage from '@/views/EntityFilterPage.vue';

const _ = require('lodash');

export default {
  name: 'TeamsList',
  components: { FilterPage },
  props: {
  },
  data() {
    const { data, filters, pagination: { total, pageSize, page } } = this.$store.state.entities.teams;
    return {
      loading: false,
      tableData: data,
      queryFilterValue: this.$store.state.entities.teams.criteria,
      pageSize,
      filters,
      currentPage: page,
      total,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      roles,
      queryInfo: {},
      filterVisible: false,
    };
  },
  mounted() {
  },
  methods: {
    queryChange(queryInfo) {
      this.queryInfo = queryInfo;
      this.fetchData();
    },
    fetchData() {
      const { pageSize, page } = this.queryInfo;
      this.loading = true;
      this.$http
        .get(`/nrl/api/v1/admin/teams/data-table/${pageSize}/${page}`, { params: { criteria: this.queryFilterValue, isActive: this.filters.isActive } })
        .then((response) => {
          this.total = response.data.data.total;
          this.tableData = response.data.data.pageData;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },
    sendFilter(e) {
      const { isActive } = e;
      this.filters.isActive = isActive;
      this.filterVisible = false;
      this.$store.commit('entities/updateFilters', { filters: this.filters, entity: 'teams' });
      this.fetchData();
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'teams.update',
        params: {
          type: 'update',
          team: row,
          id: row._id,
        },
      });
    },
    handleAddTeam() {
      this.$router.push({
        name: 'teams.add',
        params: {
          type: 'insert',
        },
      });
    },
    modifiedFormatter(row) {
      return (row.modifiedAt ? this.moment(row.modifiedAt).format('MMMM Do YYYY, h:mm:ss a') : 'NA');
    },
    competitionFormatter(row) {
      return (row.competitions && row.competitions.length ? row.competitions.join(', ') : 'NA');
    }
  },
  computed: {
    clearance() {
      return this.$store.getters['user/activeRole'].type;
    },
  },
  watch: {
    tableData(data) {
      this.$store.commit('entities/updateData', { data, entity: 'teams' });
      this.$store.commit('entities/updatePagination', { data: { ..._.pick(this.queryInfo, ['pageSize', 'page']), total: this.total }, entity: 'teams' });
    },
    // eslint-disable-next-line func-names
    queryFilterValue: _.debounce(function (criteria) {
      this.$store.commit('entities/updateCriteria', { criteria, entity: 'teams' });
      this.fetchData();
    }, 500)
  }
};
</script>

<style scoped lang="scss">
  .search-bar {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .data-table {
    width: 100%;
  }


</style>
