<template>
    <div class="postman-run-button item"
        data-postman-action="collection/fork"
        data-postman-var-1="21122875-5b0c1352-de73-4548-b4b7-25b463fdf0c3"
        data-postman-collection-url="entityId=21122875-5b0c1352-de73-4548-b4b7-25b463fdf0c3&entityType=collection&workspaceId=703d2f61-134e-4b1b-8395-a8d76b5b02f6">
    </div>
  </template>
  
  <script>
  
  export default {
    name: "RunInPostman",
    data() {
      return {
        code: ""
      };
    },
    created() {
        (function (p,o,s,t,m,a,n) {
            !p[s] && (p[s] = function () { (p[t] || (p[t] = [])).push(arguments); });
            !o.getElementById(s+t) && o.getElementsByTagName("head")[0].appendChild((
            (n = o.createElement("script")),
            (n.id = s+t), (n.async = 1), (n.src = m), n
            ));
        }(window, document, "_pm", "PostmanRunObject", "https://run.pstmn.io/button.js"));
    },
    watch: {
        '$route'() {
            this.$router.go(0);
        }
    },
    methods: {
    },
  };
  </script>
  
  <style scoped lang="scss">
  </style>
  