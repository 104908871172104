<template>
  <div>
    <el-row class="search-bar">
      <el-col :span="6">
        <el-input autocomplete="off" v-model="filters.criteria" placeholder="Search..."> </el-input>
      </el-col>
      <el-col :span="6" class="tw-ml-auto tw-mr-0">
        <div>
          <el-radio-group v-model="filters.compStatusArr" fill="#098255" @change="fetchData">
            <el-radio-button label="All" key="all"></el-radio-button>
            <el-radio-button label="Active" key="active"></el-radio-button>
            <el-radio-button label="Inactive" key="inactive"></el-radio-button>
          </el-radio-group>
        </div>
      </el-col>
    </el-row>
    <data-tables-server
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="queryInfo.pageSize"
      :current-page.sync="queryInfo.page"
      :total="total"
      @query-change="queryChange"
      @row-click="handleRowClick"
      class="data-table"
    >
      <el-table-column prop="_id" label="ID" width="auto"> </el-table-column>
      <el-table-column prop="competition.name" label="Competition" width="auto"> </el-table-column>
      <el-table-column prop="competition.season" label="Season" width="auto"> </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "LaddersList",
  props: {},
  data() {
    return {
      loading: false,
      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 20,
        page: 1,
      },
      filters: {
        criteria: this.$store.state.ladders.criteria,
        compStatusArr: 'All',
      },
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
    };
  },
  computed: {
    isTfa() {
      return this.$store.getters['user/activeRole'].national_id === 31;
    }
  },
  mounted() {
    if(this.isTfa) {
        this.filters.compStatusArr = 'Active';
        this.$store.commit('entities/updateFilters', { filters: this.filters, entity: 'squadManagement' });
    }
    this.fetchData();
  },
  methods: {
    fetchStore() {
      const {
        data,
        pagination: { total, pageSize, page },
      } = this.$store.state.ladders;
      if (data.length) {
        this.total = total;
        _.assign(this.queryInfo, { pageSize, page });
        this.tableData = data;
        return true;
      }
      return false;
    },
    queryChange(queryInfo) {
      this.queryInfo = queryInfo;
      if (!this.$store.state.ladders.data.length || queryInfo.type !== "init") this.fetchData();
    },
    fetchData() {
      console.log(this.filters.compStatusArr);
      this.loading = true;
      const { criteria: name, compStatusArr } = this.filters;
      let isActive;
      if (compStatusArr === "Active") {
        isActive = true;
      }
      if(compStatusArr === "Inactive") {
        isActive = false;
      }
      const { pageSize, page } = this.queryInfo;
      this.$http
        .get(`/nrl/api/v1/admin/ladders/data-table/${pageSize}/${page}`, {
          params: { name, isActive },
        })
        .then((response) => {
          this.tableData = response.data.data.pageData;
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },
    handleRowClick(row) {
      this.$router.push({
        name: "ladders.update",
        params: {
          type: "update",
          comp: row,
          id: row._id,
        },
      });
    },
    modifiedFormatter(row) {
      return row.modifiedAt ? this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
  },
  watch: {
    tableData(data) {
      this.$store.commit("ladders/updateData", data);
      this.$store.commit("ladders/updatePagination", {
        data: { ..._.pick(this.queryInfo, ["pageSize", "page"]), total: this.total },
      });
    },
    // eslint-disable-next-line func-names
    "filters.criteria": _.debounce(function (criteria) {
      this.$store.commit("ladders/updateCriteria", criteria);
      this.fetchData();
    }, 500),
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-bottom: 1rem;
  display: flex;
}

.data-table {
  width: 100%;
}
.el-pagination {
  margin-top: 1rem !important;
}
</style>
