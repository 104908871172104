<template>
  <el-container
    v-loading.fullscreen.lock="loading"
    element-loading-background="rgba(0,0,0,0.8)"
    :element-loading-text="message"
    >
  </el-container>
</template>

<script>
export default {
  name: 'LayoutLoading',
  props: ['message'],
  data() {
    return {
      loading: true,
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
