<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="settingIds" label="Program Type">
            <el-select
              v-model="filters.settingIds"
              multiple
              filterable
              placeholder="Select a program"
            >
              <el-option
                clearable
                v-for="item in programSettings"
                :key="item._id"
                :label="item.type"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" class="tw-w-full">
          <el-form-item class="" prop="dateTimeRange" label="Date Registered">
            <el-date-picker
              class=""
              v-model="filters.dateTimeRange"
              type="datetimerange"
              range-separator="|"
              start-placeholder="Start date"
              end-placeholder="End date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";

export default {
  name: "ProgramPackFulfilReport",
  components: {
    ActionButtons
  },
  data() {
    return {
      filters: {
        fileType: "csv",
        settingIds: [],
        dateTimeRange: null
      },
      filterRules: {},
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      // myprograms: [],
      programSettings: []
    };
  },
  mounted() {
    this.loading = true;
    // this.$http
    //   .get('/nrl/api/v1/admin/programs')
    //   .then((response) => {
    //     this.myprograms = response.data.data;
    //     this.$store.commit('root/loading', false);
    //     this.loading = false;
    //   })
    //   .catch(() => {
    //     this.$customError();
    //   });
    this.$http
      .get("/nrl/api/v1/admin/all/programs-settings")
      .then(response => {
        this.programSettings = response.data.data;
      })
      .catch(() => {
        this.$customError();
      });
  },
  methods: {
    formatStartDate(timestamp) {
      return moment(timestamp).format("DD/MM/YYYY HH:mm");
    },
    sendReport() {
      this.$refs.filters.validate(valid => {
        if (valid) {
          const { settingIds, dateTimeRange } = this.filters;
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "programPack-rego",
            fileType: this.filters.fileType,
            filters: { settingIds, dateTimeRange },
            meta: {
              timezone: moment.tz.guess()
            }
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then(response => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-input {
  width: 100% !important;
}
::v-deep .el-input__inner {
  width: 100% !important;
}
::v-deep .el-select {
  width: 100% !important;
}
</style>
