<template>
  <div>
    <el-form label-position="top">
      <el-table :data="players">
        <el-table-column prop="name" label="Name"> </el-table-column>
        <el-table-column label="Point">
          <template slot-scope="scope">
            <el-input-number
              :controls="false"
              :precision="0"
              v-model.number="players[scope.$index].point"
              :min="0"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <action-buttons submit @submit="update" />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";

export default {
  components: { ActionButtons },
  props: ["squadPlayers", "team"],
  data() {
    return {
      // players: [
      //   {
      //     name: "",
      //     memberId: "",
      //     point: 0,
      //   },
      // ],
    };
  },
  computed: {
    players() {
      if (typeof this.team.meta === "undefined") return [];
      if (
        this.team &&
        this.team.meta &&
        this.team.meta.playerPoints &&
        typeof this.team.meta.playerPoints.players === "undefined"
      )
        return [];
      if (this.team && typeof this.team.players === undefined) return [];
      else if (typeof this.team.players === "array" && this.team.players.length > 0)
        return this.team.players.filter((pl) => this.squadPlayers.indexOf(pl.memberId) > -1);
      else return [];
      // .map((p) => {
      //   const pointsArr = this.team.meta.playerPoints.players.filter(
      //     (pt) => pt.memberId === p.memberId
      //   );
      //   const points = Array.isArray(pointsArr) ? pointsArr[0] : { point: 0 };
      //   return { name: p.name, memberId: p.memberId, point: points.point };
      // });
    },
  },
  methods: {
    // mapPlayers(team, squad) {
    //   console.log({ squad });
    //   this.players = squad.map((x) => {
    //     const player =
    //       team.meta.playerPoints &&
    //       team.meta.playerPoints.players.length &&
    //       team.meta.playerPoints.players.find((p) => x._id === p.memberId);
    //     return {
    //       name: `${x.profile.firstName} ${x.profile.lastName}`,
    //       memberId: x._id,
    //       point: player ? player.point : 0,
    //     };
    //   });
    // },
    cancelForm() {
      this.$router.push({
        name: "competitions.list",
      });
    },
    update() {
      const meta = {
        playerPoints: {
          players: this.players.map((x) => ({
            ...x,
            point: x.point ? x.point : 0,
            name: undefined,
          })),
        },
      };
      this.$store.commit("root/loading", true);
      this.$http
        .put(`/nrl/api/v1/admin/teams/${this.team._id}/playerpoints`, meta)
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
  },
  // watch: {
  //   squadPlayers(v) {
  //     this.mapPlayers(this.team, v);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.display-form {
  margin-top: 0.45rem;
}

.el-input,
.el-select {
  width: 100%;
}
</style>
