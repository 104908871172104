<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="userLevel" label="User Level">
            <el-select v-model="filters.userLevel" placeholder="Select a user level">
              <el-option
                v-for="userLevel in userLevels"
                :label="userLevel.name"
                :key="userLevel.role"
                :value="userLevel.role"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, roles } from "../../../utils/constants";

export default {
  name: "AdminUsersReport",
  components: {
    ActionButtons,
  },
  data() {
    return {
      filters: {
        fileType: "csv",
        userLevel: "",
      },
      filterRules: {
        fileType: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        userLevel: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
    };
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    userLevels() {
      switch (this.clearance) {
        case roles.superAdmin:
          return [
            { name: "Super Admins", role: 1 },
            { name: "State Admins", role: 2 },
            { name: "Region Admins", role: 3 },
            { name: "Association Admins", role: 4 },
            { name: "Club Admins", role: 5 },
            // { name: "Team Admins", role: 6 },
            { name: "Gameday Admins", role: 50 },
            { name: "Game Dev Region Admins", role: 98 },
            { name: "Program Admins", role: 99 },
            { name: "Elite Gameday Admin", role: 51 },
            { name: "Elite Team Admin", role: 101 },
            { name: "Elite Partner", role: 102 },
          ];
        case roles.stateAdmin:
          return [
            { name: "Super Admins", role: 1 },
            { name: "State Admins", role: 2 },
            { name: "Region Admins", role: 3 },
            { name: "Association Admins", role: 4 },
            { name: "Club Admins", role: 5 },
            // { name: "Team Admins", role: 6 },
            { name: "Gameday Admins", role: 50 },
            { name: "Elite Gameday Admin", role: 51 },
            { name: "Elite Team Admin", role: 101 },
            { name: "Elite Partner", role: 102 },
          ].filter((o) => o.role >= this.clearance || [51, 101, 102].includes(o.role));
        case roles.regionAdmin:
          return [
            { name: "Super Admins", role: 1 },
            { name: "State Admins", role: 2 },
            { name: "Region Admins", role: 3 },
            { name: "Association Admins", role: 4 },
            { name: "Club Admins", role: 5 },
            // { name: "Team Admins", role: 6 },
            { name: "Gameday Admins", role: 50 },
          ].filter((o) => o.role >= this.clearance);
        case roles.associationAdmin:
          return [
            { name: "Super Admins", role: 1 },
            { name: "State Admins", role: 2 },
            { name: "Region Admins", role: 3 },
            { name: "Association Admins", role: 4 },
            { name: "Club Admins", role: 5 },
            { name: "Team Admins", role: 6 },
            { name: "Gameday Admins", role: 50 },
          ].filter((o) => o.role >= this.clearance);
        case roles.clubAdmin:
          return [
            { name: "Super Admins", role: 1 },
            { name: "State Admins", role: 2 },
            { name: "Region Admins", role: 3 },
            { name: "Association Admins", role: 4 },
            { name: "Club Admins", role: 5 },
            { name: "Team Admins", role: 6 },
          ].filter((o) => o.role >= this.clearance);
        case roles.teamAdmin:
          return [
            { name: "Super Admins", role: 1 },
            { name: "State Admins", role: 2 },
            { name: "Region Admins", role: 3 },
            { name: "Association Admins", role: 4 },
            { name: "Club Admins", role: 5 },
            { name: "Team Admins", role: 6 },
            { name: "Gameday Admins", role: 50 },
          ].filter((o) => o.role >= this.clearance);
        case roles.programAdmin:
          return [
            { name: "Super Admins", role: 1 },
            { name: "State Admins", role: 2 },
            { name: "Region Admins", role: 3 },
            { name: "Association Admins", role: 4 },
            { name: "Club Admins", role: 5 },
            { name: "Team Admins", role: 6 },
            { name: "Gameday Admins", role: 50 },
          ].filter((o) => o.role >= this.clearance);
        case roles.gamedevregionAdmin:
          return [
            { name: "Game Dev Region Admins", role: 98 },
            { name: "Program Admins", role: 99 },
          ];
        default:
          return [];
      }
    },
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "admin-users",
            fileType: this.filters.fileType,
            filters: {
              adminType: this.filters.userLevel,
            },
            meta: {
              timezone: moment.tz.guess(),
            },
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>
