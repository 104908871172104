<template>
  <div>
    <h2 class="mb-1 text-lg font-bold">Periodic Interval Interchange Overrides</h2>
    <el-collapse v-model="activeNames">
      <el-collapse-item
        v-for="(range, index) in periodRanges"
        :key="index"
        :title="range !== 'team-of-the-year' ? range : 'Team Of The Year'"
        :name="index"
      >
        <el-row :gutter="16">
          <el-form label-position="top" :id="`range${index}`" :ref="`range${index}`" class="m-1">
            <el-form-item
              v-for="(position, posIndex) in playerPositionArr"
              :key="posIndex"
              :label="position.name"
            >
              <!-- Dropdown select for players -->
              <el-row :gutter="10">
                <el-col :span="10">
                  <el-select
                    :value="selectedPlayerValue(range, position.type)"
                    placeholder="Select player..."
                    clearable
                    filterable
                    @input="handlePlayerSelection($event, position.type, range)"
                  >
                    <el-option
                      v-for="player in filteredPlayers(range)"
                      :key="position.type + player.playerId"
                      :label="player.name"
                      :value="player.playerId"
                    />
                  </el-select>
                  <!-- Add button to add selected player -->
                  <el-button type="default" @click="addPlayer(position.type, range)">
                    Add
                  </el-button>
                  <!-- Display selected players -->
                  <div
                    v-if="
                      selectedPlayers &&
                      selectedPlayers[range] &&
                      selectedPlayers[range][position.type] &&
                      selectedPlayers[range][position.type].length > 0
                    "
                    class="selected-players"
                  >
                    <div
                      v-for="selectedPlayer in selectedPlayers[range][position.type]"
                      :key="position.type + 'remove' + selectedPlayer.playerId"
                      class="selected-player tw-font-bold"
                    >
                      {{ selectedPlayer.name }}
                      <el-button
                        type="text"
                        @click="removeSelectedPlayer(position.type, range, selectedPlayer)"
                      >
                        Remove
                      </el-button>
                    </div>
                  </div>
                </el-col>
                <el-col :span="10">
                  <el-select
                    :value="removedPlayerValue(range, position.type)"
                    placeholder="Select player..."
                    clearable
                    filterable
                    @input="handleRemovedPlayerSelection($event, position.type, range)"
                  >
                    <el-option
                      v-for="player in filteredPlayers(range)"
                      :key="position.type + 'removed' + player.playerId"
                      :label="player.name"
                      :value="player.playerId"
                    />
                  </el-select>
                  <el-button type="default" @click="removePlayer(position.type, range)">
                    Remove
                  </el-button>
                  <!-- Display selected players -->
                  <div
                    v-if="
                      removedPlayers &&
                      removedPlayers[range] &&
                      removedPlayers[range][position.type] &&
                      removedPlayers[range][position.type].length > 0
                    "
                    class="selected-players"
                  >
                    <div
                      v-for="removedPlayer in removedPlayers[range][position.type]"
                      :key="position.type + 'add' + removedPlayer.playerId"
                      class="selected-player tw-font-bold"
                    >
                      {{ removedPlayer.name }}
                      <el-button
                        type="text"
                        @click="removeRemovedPlayer(position.type, range, removedPlayer)"
                      >
                        Add
                      </el-button>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </el-row>
        <el-button type="success" @click="savePlayerData()"> SAVE </el-button>
      </el-collapse-item>
      <el-collapse-item
        v-if="comp.meta.awardsSettings.endOfSeasonVoting"
        title="End of season"
        name="end-of-season"
      >
        <el-row :gutter="16">
          <el-form label-position="top" id="endOfSeason" ref="endOfSeason" class="m-1">
            <el-form-item
              v-for="category in filteredEndOfSeasonAwards"
              :key="category"
              :label="category"
            >
            <el-row :gutter="16">
              <el-col :span="18">
                <el-select
                  :placeholder="'Select ' + category"
                  clearable
                  filterable
                  @clear="clearAward(category)"
                  @input="updateSelectedAwards($event, category)"
                  :value="selectedEOSPlayerValue(category)"
                >
                  <el-option
                    v-for="player in getPlayersByType(category)"
                    :key="category + player.playerId"
                    :label="player.name"
                    :value="player.playerId"
                  />
                </el-select>
              </el-col>
              <el-col :span="6">
                <el-button type="default" @click="addEOSPlayer(category)"> Add </el-button>
              </el-col>
            </el-row>
              <div
                v-if="selectedEOSAwardsList[category] && selectedEOSAwardsList[category].length > 0"
                class="selected-players"
              >
                <div
                  v-for="selectedPlayer in selectedEOSAwardsList[category]"
                  :key="`eos-${selectedPlayer.memberId}`"
                  class="selected-player tw-font-bold"
                >
                  {{ selectedPlayer.memberName }}
                  <el-button type="text" @click="removeSelectedEOSPlayer(selectedPlayer, category)">
                    Remove
                  </el-button>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </el-row>
        <el-button type="success" @click="savePlayerData()"> SAVE </el-button>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { errormsg, roles } from '../../utils/constants'
import { EventBus } from '../../bus'

export default {
  data () {
    return {
      allowPeriodVoting: false,
      periodRangeObj: {},
      templates: {},
      playerPositionArr: [
        { type: 'Fullback', name: 'Fullback', playerType: 'player' },
        { type: 'Wing', name: 'Winger', playerType: 'player' },
        { type: 'Centre', name: 'Centre', playerType: 'player' },
        { type: 'Five-Eighth', name: 'Five-Eighth', playerType: 'player' },
        { type: 'Halfback', name: 'Halfback', playerType: 'player' },
        { type: 'Hooker', name: 'Hooker', playerType: 'player' },
        { type: 'Prop', name: 'Prop', playerType: 'player' },
        { type: 'SecondRow', name: 'Second Row', playerType: 'player' },
        { type: 'Lock', name: 'Lock', playerType: 'player' }
      ],
      players: [],
      removedPlayer: {},
      removedPlayers: {},
      selectedPlayer: {},
      selectedEOSPlayer: {},
      selectedPlayers: {},
      playerSearch: {},
      selectedEOSAwardsList: {
        coach: [],
        rookie: [],
        captain: []
      }
    }
  },
  props: {
    competition: { type: Object, required: true }
  },
  async created () {
    await this.fetchPlayerData()
  },
  computed: {
    selectedPlayerValue () {
      return (range, positionType) => {
        return this.selectedPlayer[range]?.[positionType] || null
      }
    },
    selectedEOSPlayerValue () {
      return award => {
        return this.selectedEOSPlayer[award] || null
      }
    },
    removedPlayerValue () {
      return (range, positionType) => {
        return this.removedPlayer[range]?.[positionType] || null
      }
    },
    selectedTOYPlayerValue () {
      return positionType => {
        return this.selectedTOYPlayers?.[positionType] || null
      }
    },
    removedTOYPlayerValue () {
      return positionType => {
        return this.removedTOYPlayers?.[positionType] || null
      }
    },
    filteredEndOfSeasonAwards () {
      if (
        !this.comp ||
        !this.comp.meta ||
        !this.comp.meta.awardsSettings ||
        !Array.isArray(this.comp.meta.awardsSettings.endOfSeasonVoting)
      ) {
        return []
      }

      return this.comp.meta.awardsSettings.endOfSeasonVoting
        .filter(award => !award.toLowerCase().includes('team'))
        .map(award => award.toLowerCase())
    },
    periodRanges () {
      if (
        this.comp.meta &&
        this.comp.meta.awardsSettings &&
        this.comp.meta.awardsSettings.periodRanges
      ) {
        const ranges = this.comp.meta.awardsSettings.periodRanges
        if (this.comp.meta.awardsSettings.teamOfYear && !ranges.includes('team-of-the-year')) {
          ranges.push('team-of-the-year')
        }
        return ranges
      } else {
        return []
      }
    },
    activeNames () {
      return this.periodRanges.map((range, index) => index.toString())
    },
    isSuperAdminNRL () {
      const { type, national_id } = this.$store.getters['user/activeRole']
      return type === roles.superAdmin && national_id === 32
    },
    comp () {
      return { ...this.competition, meta: this.competition.meta || {} }
    }
  },
  methods: {
    async fetchPlayerData () {
      this.$store.commit('root/loading', true)
      try {
        const url = `/nrl/api/v1/admin/competitions/${this.competition._id}/awards/players/interval-voting`
        const response = await this.$http.get(url)
        this.players = response.data.data

        this.periodRanges.forEach(range => {
          this.$set(this.selectedPlayers, range, {})
          this.$set(this.selectedPlayer, range, {})
          this.$set(this.removedPlayers, range, {})
          this.$set(this.removedPlayer, range, {})
          this.playerPositionArr.forEach(pos => {
            this.$set(this.selectedPlayers[range], pos.type, null)
            this.$set(this.selectedPlayer[range], pos.type, null)
            this.$set(this.removedPlayers[range], pos.type, null)
            this.$set(this.removedPlayer[range], pos.type, null)
          })
        })

        const interchangeData = await this.$http.get(
          `/nrl/api/v1/admin/interchange/competition/${this.competition._id}`
        )
        if (interchangeData.data.data && interchangeData.data.data.interval) {
          this.selectedPlayers = interchangeData.data.data.interval
        }
        if(interchangeData.data.data && interchangeData.data.data.endOfSeason) {
          this.selectedEOSAwardsList = interchangeData.data.data.endOfSeason;
        }
        if (interchangeData.data.data && interchangeData.data.data.removedInterval) {
          this.removedPlayers = interchangeData.data.data.removedInterval
        }
      } catch (error) {
        console.error('Error fetching player data:', error)
        this.$store.commit('root/loading', false)
      }
      this.$store.commit('root/loading', false)
    },
    updateSelectedAwards (value, award) {
      this.$set(this.selectedEOSPlayer, award, value)
    },

    filteredPlayers (interval) {
      if (interval === 'team-of-the-year') {
        const playerIdMap = new Map()
        const allPlayers = this.players.flatMap(interval => interval.teamPlayers)

        allPlayers.forEach(player => {
          playerIdMap.set(player.playerId, player)
        })

        return Array.from(playerIdMap.values())
      }

      const selectedInterval = this.players.find(intervalObj => intervalObj.interval === interval)

      if (!selectedInterval || !selectedInterval.teamPlayers) {
        return []
      }
      const playerIdMap = new Map()
      selectedInterval.teamPlayers.forEach(player => {
        playerIdMap.set(player.playerId, player)
      })

      return Array.from(playerIdMap.values())
    },
    findPlayerById (playerId) {
      for (const interval of this.players) {
        const players = interval.teamPlayers
        for (const player of players) {
          if (player.playerId === playerId) {
            return player
          }
        }
      }
      return null
    },
    addEOSPlayer (awardType) {
      const selectedPlayerId = this.selectedEOSPlayer[awardType]

      if (!selectedPlayerId) {
        console.warn('No player selected for award type:', awardType)
        return
      }

      const allPlayers = this.getPlayersByType(awardType)
      const selectedPlayer = allPlayers.find(player => player.playerId === selectedPlayerId)

      if (selectedPlayer) {
        const formattedPlayer = {
          memberName: selectedPlayer.name,
          memberId: selectedPlayer.playerId,
          teamName: selectedPlayer.team,
          teamId: selectedPlayer.teamId
        }

        if (!Array.isArray(this.selectedEOSAwardsList[awardType])) {
          this.$set(this.selectedEOSAwardsList, awardType, [])
        }

        if (
          !this.selectedEOSAwardsList[awardType].some(player => player.memberId === selectedPlayerId)
        ) {
          this.selectedEOSAwardsList[awardType].push(formattedPlayer)
        } else {
          console.warn(
            `Player with ID ${selectedPlayerId} is already in the selected awards for ${awardType}.`
          )
        }
      } else {
        console.warn('Player not found with ID:', selectedPlayerId)
      }

      this.selectedEOSPlayer[awardType] = null
    },
    addPlayer (positionType, range) {
      const selectedPlayerId = this.selectedPlayer[range][positionType]

      if (range === 'team-of-the-year') {
        const allPlayers = this.players.flatMap(interval => interval.teamPlayers)
        const selectedPlayer = allPlayers.find(player => player.playerId === selectedPlayerId)

        if (selectedPlayer) {
          const existingPlayers = this.selectedPlayers[range]?.[positionType]

          if (
            Array.isArray(existingPlayers) &&
            existingPlayers.some(player => player.playerId === selectedPlayerId)
          ) {
            console.warn(
              `Player with ID ${selectedPlayerId} already exists in ${range} ${positionType}.`
            )
            return
          }

          if (!this.selectedPlayers[range]) {
            this.$set(this.selectedPlayers, range, {})
          }
          if (!Array.isArray(this.selectedPlayers[range][positionType])) {
            this.$set(this.selectedPlayers[range], positionType, [])
          }

          this.selectedPlayers[range][positionType].push(selectedPlayer)
        } else {
          if (!this.selectedPlayers[range]) {
            this.$set(this.selectedPlayers, range, {})
          }
          this.$set(this.selectedPlayers[range], positionType, null)
        }

        this.selectedPlayer[range][positionType] = null
        return
      }

      const selectedInterval = this.players.find(intervalObj => intervalObj.interval === range)

      if (!selectedInterval || !selectedInterval.teamPlayers) {
        console.error('Interval or teamPlayers not found for range:', range)
        return
      }

      const selectedPlayer = selectedInterval.teamPlayers.find(
        player => player.playerId === selectedPlayerId
      )

      if (selectedPlayer) {
        const existingPlayers = this.selectedPlayers[range]?.[positionType]

        if (
          Array.isArray(existingPlayers) &&
          existingPlayers.some(player => player.playerId === selectedPlayerId)
        ) {
          console.warn(
            `Player with ID ${selectedPlayerId} already exists in ${range} ${positionType}.`
          )
          return
        }

        if (!this.selectedPlayers[range]) {
          this.$set(this.selectedPlayers, range, {})
        }
        if (!Array.isArray(this.selectedPlayers[range][positionType])) {
          this.$set(this.selectedPlayers[range], positionType, [])
        }

        this.selectedPlayers[range][positionType].push(selectedPlayer)
      } else {
        if (!this.selectedPlayers[range]) {
          this.$set(this.selectedPlayers, range, {})
        }
        this.$set(this.selectedPlayers[range], positionType, null)
      }

      this.selectedPlayer[range][positionType] = null
    },
    removePlayer (positionType, range) {
      const removedPlayerId = this.removedPlayer[range][positionType]

      if (range === 'team-of-the-year') {
        const allPlayers = this.players.flatMap(interval => interval.teamPlayers)
        const removedPlayer = allPlayers.find(player => player.playerId === removedPlayerId)

        if (removedPlayer) {
          const existingPlayers = this.removedPlayers[range]?.[positionType]

          if (
            Array.isArray(existingPlayers) &&
            existingPlayers.some(player => player.playerId === removedPlayerId)
          ) {
            console.warn(
              `Player with ID ${removedPlayerId} already exists in ${range} ${positionType}.`
            )
            return
          }

          if (!this.removedPlayers[range]) {
            this.$set(this.removedPlayers, range, {})
          }
          if (!Array.isArray(this.removedPlayers[range][positionType])) {
            this.$set(this.removedPlayers[range], positionType, [])
          }

          this.removedPlayers[range][positionType].push(removedPlayer)
        } else {
          if (!this.removedPlayers[range]) {
            this.$set(this.removedPlayers, range, {})
          }
          this.$set(this.removedPlayers[range], positionType, null)
        }

        this.removedPlayer[range][positionType] = null
        return
      }

      const selectedInterval = this.players.find(intervalObj => intervalObj.interval === range)

      if (!selectedInterval || !selectedInterval.teamPlayers) {
        console.error('Interval or teamPlayers not found for range:', range)
        return
      }

      const removedPlayer = selectedInterval.teamPlayers.find(
        player => player.playerId === removedPlayerId
      )

      if (removedPlayer) {
        const existingPlayers = this.removedPlayers[range]?.[positionType]

        if (
          Array.isArray(existingPlayers) &&
          existingPlayers.some(player => player.playerId === removedPlayerId)
        ) {
          console.warn(
            `Player with ID ${removedPlayerId} already exists in ${range} ${positionType}.`
          )
          return
        }

        if (!this.removedPlayers[range]) {
          this.$set(this.removedPlayers, range, {})
        }
        if (!Array.isArray(this.removedPlayers[range][positionType])) {
          this.$set(this.removedPlayers[range], positionType, [])
        }

        this.removedPlayers[range][positionType].push(removedPlayer)
      } else {
        if (!this.removedPlayers[range]) {
          this.$set(this.removedPlayers, range, {})
        }
        this.$set(this.removedPlayers[range], positionType, null)
      }

      this.removedPlayer[range][positionType] = null
    },
    clearPlayer (positionType) {
      this.selectedPlayers[positionType] = []
    },
    removeSelectedPlayer (positionType, range, playerToRemove) {
      // Check if selectedPlayers[range] is defined and is an object
      if (
        this.selectedPlayers &&
        this.selectedPlayers[range] &&
        this.selectedPlayers[range][positionType] &&
        Array.isArray(this.selectedPlayers[range][positionType])
      ) {
        // Find the index of the player to remove
        const indexToRemove = this.selectedPlayers[range][positionType].indexOf(playerToRemove)

        // Remove the player if the index is valid
        if (indexToRemove !== -1) {
          this.selectedPlayers[range][positionType].splice(indexToRemove, 1)
        }
      } else {
        console.error(
          `Selected players for position type '${positionType}' in range '${range}' is not correct.`
        )
      }
    },
    removeSelectedEOSPlayer (playerToRemove, category) {
      if (
        this.selectedEOSAwardsList &&
        this.selectedEOSAwardsList[category] &&
        Array.isArray(this.selectedEOSAwardsList[category])
      ) {
        const indexToRemove = this.selectedEOSAwardsList[category].indexOf(playerToRemove)

        if (indexToRemove !== -1) {
          this.selectedEOSAwardsList[category].splice(indexToRemove, 1)
        }
      } else {
        console.error(`Selected players for category '${category}' is not correct.`)
      }
    },
    removeRemovedPlayer (positionType, range, playerToRemove) {
      // Check if selectedPlayers[range] is defined and is an object
      if (
        this.removedPlayers &&
        this.removedPlayers[range] &&
        this.removedPlayers[range][positionType] &&
        Array.isArray(this.removedPlayers[range][positionType])
      ) {
        // Find the index of the player to remove
        const indexToRemove = this.removedPlayers[range][positionType].indexOf(playerToRemove)

        // Remove the player if the index is valid
        if (indexToRemove !== -1) {
          this.removedPlayers[range][positionType].splice(indexToRemove, 1)
        }
      } else {
        console.error(
          `Removed players for position type '${positionType}' in range '${range}' is not correct.`
        )
      }
    },
    async savePlayerData () {
      this.$store.commit('root/loading', true)
      try {
        await this.$http.post(`/nrl/api/v1/admin/interchange/competition/${this.competition._id}`, {
          intervalData: this.selectedPlayers,
          removedIntervalData: this.removedPlayers,
          endOfSeason: this.selectedEOSAwardsList
        })
      } catch (error) {
        console.error('Error posting player data:', error)
        this.$store.commit('root/loading', false)
      }
      this.$store.commit('root/loading', false)
    },
    clearAward (award) {
      this.selectedEOSAwardsList[award] = []
    },
    handlePlayerSelection (value, positionType, range) {
      this.$set(this.selectedPlayer[range], positionType, value)
    },
    handleRemovedPlayerSelection (value, positionType, range) {
      this.$set(this.removedPlayer[range], positionType, value)
    },
    getPlayersByType (type) {
      const playerMap = new Map()

      this.players.forEach(interval => {
        if (!interval) return

        let playersToAdd = []

        if (type === 'coach') {
          playersToAdd = (interval.teamNonPlayers || []).filter(player => player.role === 'coach')
        } else if (type === 'captain') {
          playersToAdd = (interval.teamPlayers || []).filter(player => player.isCaptain === true)
        } else if (type === 'rookie') {
            playersToAdd = interval.teamPlayers || []
            /**
             * (SAFE-3804)[https://nationalrugbyleague.atlassian.net/browse/SAFE-3804]
             * Business rules to push players from last season 
             */
            const players = this.getRookiePlayers();
            playersToAdd = [...playersToAdd, ...players[this.competition._id]];
          }

        playersToAdd.forEach(player => {
          playerMap.set(player.playerId, player)
        })
      })

      return Array.from(playerMap.values())
    },
    isPeriodInRange (index) {
      const range = this.periodRanges[index]
      if (!range) return false
      const [start, end] = range.split('-').map(Number)
      const periodVoting = this.comp.meta.awardsSettings.periodVoting || 0
      return start <= periodVoting && periodVoting <= end
    },
    getRookiePlayers() {
      /**
       * Hard coded as needed from business
       */
      const playersQA = {
          // NRL Premiership
          324353: [
            {
                name: "Sirius Black",
                playerId: 602674553,
                teamId: 46653296,
                team: "Raiders"
            },
            {
                name: "Cedric Diggory",
                playerId: 602632699,
                teamId: 46653296,
                team: "Raiders"
            }
          ],
          // NRLW Premiership
          46462720: [
            {
                name: "Sirius Black",
                playerId: 602674553,
                teamId: 46653296,
                team: "Raiders"
            },
            {
                name: "Cedric Diggory",
                playerId: 602632699,
                teamId: 46653296,
                team: "Raiders"
            }
          ]
      };

      const playersPROD = {
          // NRL Premiership
          49716836: [
              {
                name: "Mark Nawaqanitawase",
                playerId: 1716195,
                team: "Sydney Roosters",
                teamId: 50127790
              },
              {
                  name: "Kalani Going",
                  playerId: 2684081,
                  team: "New Zealand Warriors",
                  teamId: 50124095
              },
              {
                  name: "Robert Derby",
                  playerId: 2669273,
                  team: "North Queensland Cowboys",
                  teamId: 50124565
              },
              {
                  name: "Benjamin Lovett",
                  playerId: 2412048,
                  team: "South Sydney Rabbitohs",
                  teamId: 50127521
              },
              {
                  name: "Ryan Rivett",
                  playerId: 2198234,
                  team: "Newcastle Knights",
                  teamId: 50124208
              },
              {
                  name: "Zac Fulton",
                  playerId: 625346,
                  team: "Manly-Warringah Sea Eagles",
                  teamId: 50123660
              },
              {
                  name: "Cameron Munster",
                  playerId: 843326,
                  team: "Storms",
                  teamId: 50123919
              },
              {
                  name: "Mark Nicholls",
                  playerId: 177061,
                  team: "Dolphins",
                  teamId: 50123206
              },
              {
                  name: "Ryan Papenhuyzen",
                  playerId: 1126986,
                  team: "Storms",
                  teamId: 50123919
              },
              {
                  name: "Keagan Russell-Smith",
                  playerId: 2201195,
                  team: "Storms",
                  teamId: 50123919
              },
          ],
          // NRLW Premiership
          50666594: [
              {
                  playerId: 2380226,
                  name: "Ua Ravu",
                  team: "Raiders",
                  teamId: 50670191
              },
              {
                  playerId: 3191378,
                  name: "Shannon Rose",
                  team: "Roosters",
                  teamId: 50667024
              },
              {
                  playerId: 6202858,
                  name: "Eloise (Ella)  Ryan",
                  team: "Raiders",
                  teamId: 50670191
              }
          ]
      };
      return process.env.VUE_APP_ENV === "production" ? playersPROD : playersQA;
    }
  }
}
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}

.small-card {
  margin: 0.5rem;
}

.add-email {
  text-align: center;
}

.margin-bottom {
  margin-bottom: 2rem;
}
</style>
