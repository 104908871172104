<template>
  <div>
    <el-form
      label-position="top"
      :model="assignmentAllocation"
      id="assignmentAllocation"
      ref="assignmentAllocation"
    >
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r">
        <el-form-item prop="type" label="Category">
          <el-select
            filterable
            @change="fetchAssignmentsForCategory"
            v-model="assignmentAllocation.type"
            placeholder="Select a Category"
          >
            <el-option
              v-for="item in additionalVoting"
              :key="item.type"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3">
        <div :span="24" class="tw-flex transfer-box">
          <el-form-item>
            <el-transfer
              class="transfer"
              v-model="assignmentAllocation.assignees"
              :props="{
                key: '_id',
                label: 'mergedName',
                disabled: 'disabled',
              }"
              :button-texts="[
                'Remove from Competition Assignments',
                'Add To Competition Assignments',
              ]"
              :titles="['Available', 'Allocated']"
              :data="availableAssignees"
            >
            </el-transfer>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <action-buttons submit cancel @submit="updateAssignmentAllocation()" @cancel="cancelForm()" />
  </div>
</template>
<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { EventBus } from "../../bus";

const _ = require("lodash");

export default {
  data() {
    return {
      hasVoted: [],
      prevAssignments: {
        "captain-of-the-year": [],
        "coach-of-the-year": [],
        "rookie-of-the-year": [],
        "quarter_1-6": [],
        "quarter_7-13": [],
        "quarter_14-19": [],
        "quarter_20-25": [],
      },
    };
  },
  props: {
    assignmentAlloc: { type: Object, required: true },
    assignmentTeam: { type: Array, required: true },
    comp: { type: Object, required: true },
  },
  components: { ActionButtons },
  mounted() {
    EventBus.$on("updateCompetition", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    cancelForm() {
      this.$router.push({
        name: "competitions.list",
      });
    },
    async fetchAssignmentsForCategory() {
      try {
        this.$store.commit("root/loading", true);
        const category = this.assignmentAllocation.type;
        const typeOfOneOff = category.split("_");
        if (!typeOfOneOff.includes("quarter")) {
          const url = `/nrl/api/v1/admin/comp/${this.comp._id}/assignment-status/${category}`;
          const resAssignments = await this.$http.get(url);
          const previouslyAssigned =
            resAssignments && resAssignments.data && resAssignments.data.data
              ? resAssignments.data.data
              : [];
          this.prevAssignments[category] = previouslyAssigned.map((pa) => pa._id);
          const votesSubmittedList = previouslyAssigned.filter(
            (pa) => pa.assignedVoter && pa.hasVoted
          );
          const membersIdPrevVoted = votesSubmittedList.map((m) => m._id);
          this.hasVoted = membersIdPrevVoted;
          this.assignmentAllocation.assignees = this.prevAssignments[category];
          this.$store.commit("root/loading", false);
          return true;
        }

        if (typeOfOneOff.includes("quarter")) {
          const voteRes = await this.$http.get(
            `/nrl/api/v1/admin/comp/${this.comp._id}/vote-status?range=${typeOfOneOff[1]}`
          );
          let d = [];
          if (voteRes.data && voteRes.data.data) d = voteRes.data.data;
          this.prevAssignments[category] = d.compAssignments.map((ca) => ca.memberId);
          const votedQuarters = d.compAssignments.filter((ca) => {
            const statusArr = ca.status.split("_");
            return statusArr.includes("assigned") && statusArr.includes("voted");
          });
          this.hasVoted = votedQuarters.map((m) => m.memberId);
          this.assignmentAllocation.assignees = this.prevAssignments[category];
          this.$store.commit("root/loading", false);
          return true;
        }
      } catch (error) {
        this.$customError();
        this.$store.commit("root/loading", false);
      }
    },
    async updateAssignmentAllocation() {
      try {
        this.$store.commit("root/loading", true);
        const assignArr = this.assignmentAllocation.assignees;
        const lastSavedAssignments = this.prevAssignments[this.assignmentAllocation.type];
        const newAssignments = assignArr.filter((a) => !lastSavedAssignments.includes(a));
        const unassignments = lastSavedAssignments.filter((a) => !assignArr.includes(a));
        const assignmentArr = [];
        const category = this.assignmentAllocation.type;
        const typeOfOneOff = category.split("_");
        if (!typeOfOneOff.includes("quarter")) {
          newAssignments.forEach((assign) => {
            const voteStatus = this.hasVoted.includes(assign) ? "voted" : "novote";
            const updateObj = {
              comp: {
                id: this.comp._id,
                name: this.comp.name,
              },
              memberId: assign,
              status: `voter_assigned_open_${voteStatus}`,
              quarter: "end-of-season",
              type: this.assignmentAllocation.type,
            };
            assignmentArr.push(updateObj);
          });
          unassignments.forEach((assign) => {
            const voteStatus = this.hasVoted.includes(assign) ? "voted" : "novote";
            const updateObj = {
              comp: {
                id: this.comp._id,
                name: this.comp.name,
              },
              memberId: assign,
              status: `voter_unassigned_open_${voteStatus}`,
              quarter: "end-of-season",
              type: this.assignmentAllocation.type,
            };
            assignmentArr.push(updateObj);
          });
        }

        if (typeOfOneOff.includes("quarter")) {
          newAssignments.forEach((assign) => {
            const voteStatus = this.hasVoted.includes(assign) ? "voted" : "novote";
            const updateObj = {
              comp: {
                id: this.comp._id,
                name: this.comp.name,
              },
              memberId: assign,
              status: `voter_assigned_open_${voteStatus}`,
              quarter: typeOfOneOff[1],
            };
            assignmentArr.push(updateObj);
          });
          unassignments.forEach((assign) => {
            const voteStatus = this.hasVoted.includes(assign) ? "voted" : "novote";
            const updateObj = {
              comp: {
                id: this.comp._id,
                name: this.comp.name,
              },
              memberId: assign,
              status: `voter_unassigned_open_${voteStatus}`,
              quarter: typeOfOneOff[1],
            };
            assignmentArr.push(updateObj);
          });
        }
        await this.$http.post("/nrl/api/v1/admin/comp-assignment", {
          assignmentSubmissions: assignmentArr,
        });
        this.$store.commit("root/loading", false);
        this.$customSuccess();
        this.$router.push({
          name: "competitions.list",
        });
      } catch (error) {
        this.$customError();
        this.$store.commit("root/loading", false);
      }
    },
  },
  computed: {
    assignmentAllocation() {
      return this.assignmentAlloc;
    },
    availableAssignees() {
      if (!this.assignmentAllocation.type) return [];
      return this.assignmentTeam.map((a) => ({
        ...a,
        mergedName: `${a.profile ? a.profile.firstName + a.profile.lastName : "Member: " + a._id}`,
      }));
    },
    additionalVoting() {
      const array = [];
      if (this.comp.meta.awardsSettings && this.comp.meta.awardsSettings) {
        if (
          this.comp.meta.awardsSettings.periodRanges &&
          this.comp.meta.awardsSettings.periodRanges.length
        )
          this.comp.meta.awardsSettings.periodRanges.forEach((p) =>
            array.push({ label: `Period Votes Round (${p})`, type: p, value: `quarter_${p}` })
          );
        if (
          this.comp.meta.awardsSettings.endOfSeasonVoting &&
          this.comp.meta.awardsSettings.endOfSeasonVoting.length
        )
          this.comp.meta.awardsSettings.endOfSeasonVoting.forEach((cat) =>
            array.push({
              label: `${cat} of the Year`,
              type: cat.toLowerCase(),
              value: `${cat.toLowerCase()}-of-the-year`,
            })
          );
      }
      return array;
    },
  },
};
</script>
