<template>
  <div>
    <div class="tw-items-center tw-px-4 tw-pb-8 tw-bg-white tw-shadow-md tw-pt-3.5">
      <p class="tw-text-base tw-leading-14px tw-font-medium tw-pb-4">Symptom Record</p>
      <p class="tw-black tw-font-normal tw-text-sm tw-leading-18px tw-pb-4">
        Complete based on how the player feels now. A parent should help answer these questions if
        the player is 12 years old or younger.
      </p>
      <p class="tw-font-medium tw-text-black tw-text-left tw-text-sm tw-leading-18px tw-pb-8">
        Rank the severity of the specific symptom from 0-6, 0 being no pain, 1-2 mild, 3-4 moderate,
        and 5-6 being severe.
      </p>

      <div v-for="question in questions" :key="question.symptom" class="tw-h-60px tw-mb-10">
        <label class="tw-font-normal tw-text-sm tw-leading-14px tw-py-0 tw-block">{{
          question.label
        }}</label>
        <SummaryRecordSlidder :stepProp="question.step" @step="getStepValue($event, question)" />
      </div>
    </div>
  </div>
</template>

<script>
import SummaryRecordSlidder from "./SummaryRecordSlider.vue";
export default {
  components: { SummaryRecordSlidder },
  props: ["injury"],
  data() {
    return {
      stepName: "",
      symptomRecord: {
        headache: { step: 0, stepName: "None" },
        pressureInHead: { step: 0, stepName: "None" },
        neckPain: { step: 0, stepName: "None" },
        nauseaOrVomit: { step: 0, stepName: "None" },
        dizzy: { step: 0, stepName: "None" },
        blurVision: { step: 0, stepName: "None" },
        balanceProblem: { step: 0, stepName: "None" },
        lightSensitivity: { step: 0, stepName: "None" },
        noiseSensitivity: { step: 0, stepName: "None" },
        slowedDown: { step: 0, stepName: "None" },
        inAFog: { step: 0, stepName: "None" },
        notFeelRight: { step: 0, stepName: "None" },
        difficultyInConcentrating: { step: 0, stepName: "None" },
        difficultyRemembering: { step: 0, stepName: "None" },
        fatigued: { step: 0, stepName: "None" },
        confused: { step: 0, stepName: "None" },
        drowsy: { step: 0, stepName: "None" },
        emotional: { step: 0, stepName: "None" },
        irritable: { step: 0, stepName: "None" },
        sad: { step: 0, stepName: "None" },
        nervousOrAnxious: { step: 0, stepName: "None" },
      },
      questions: [
        {
          symptom: "headache",
          label: "Does the player have a headache?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "pressureInHead",
          label: "Does the player feel 'pressure in the head'?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "neckPain",
          label: "Does the player have neck pain?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "nauseaOrVomit",
          label: "Is there nausea or vomiting?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "dizzy",
          label: "Does the player feel dizzy?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "blurVision",
          label: "Does the player have blurred vision?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "balanceProblem",
          label: "Does the player have balance problems?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "lightSensitivity",
          label: "Is the player sensitive to light?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "noiseSensitivity",
          label: "Is the player sensitive to noise?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "slowedDown",
          label: "Does the player feel 'slowed down'?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "inAFog",
          label: "Does the player feel like they are 'in a fog'?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "notFeelRight",
          label: "Does the player just 'not feel right'?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "difficultyInConcentrating",
          label: "Does the player have difficulty concentrating?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "difficultyRemembering",
          label: "Does the player have difficulty remembering?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "fatigued",
          label: "Does the player feel fatigued or has low energy?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "confused",
          label: "Is the player confused?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "drowsy",
          label: "Does the player feel drowsy?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "emotional",
          label: "Is the player more emotional that normal?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "irritable",
          label: "Is the player irritable?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "sad",
          label: "Does the player feel sad?",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "nervousOrAnxious",
          label: "Is the player nervous or anxious?",
          stepName: "None",
          step: 0,
        },
      ],
      noStep: {
        symptom: "",
        label: "",
        stepName: "None",
        step: 0,
      },
    };
  },
  beforeMount() {
    const symptomsRec = this.injury?.symptomRecord;
    if (symptomsRec) {
      this.questions.forEach((ques) => {
        ques.symptom === "headache" ? Object.assign(ques, symptomsRec.headache) : this.noStep;
        ques.symptom === "pressureInHead"
          ? Object.assign(ques, symptomsRec.pressureInHead)
          : this.noStep;
        ques.symptom === "neckPain" ? Object.assign(ques, symptomsRec.neckPain) : this.noStep;
        ques.symptom === "nauseaOrVomit"
          ? Object.assign(ques, symptomsRec.nauseaOrVomit)
          : this.noStep;
        ques.symptom === "dizzy" ? Object.assign(ques, symptomsRec.dizzy) : this.noStep;
        ques.symptom === "blurVision" ? Object.assign(ques, symptomsRec.blurVision) : this.noStep;
        ques.symptom === "balanceProblem"
          ? Object.assign(ques, symptomsRec.balanceProblem)
          : this.noStep;
        ques.symptom === "lightSensitivity"
          ? Object.assign(ques, symptomsRec.lightSensitivity)
          : this.noStep;
        ques.symptom === "noiseSensitivity"
          ? Object.assign(ques, symptomsRec.noiseSensitivity)
          : this.noStep;
        ques.symptom === "slowedDown" ? Object.assign(ques, symptomsRec.slowedDown) : this.noStep;
        ques.symptom === "inAFog" ? Object.assign(ques, symptomsRec.inAFog) : this.noStep;
        ques.symptom === "notFeelRight"
          ? Object.assign(ques, symptomsRec.notFeelRight)
          : this.noStep;
        ques.symptom === "difficultyInConcentrating"
          ? Object.assign(ques, symptomsRec.difficultyInConcentrating)
          : this.noStep;
        ques.symptom === "difficultyRemembering"
          ? Object.assign(ques, symptomsRec.difficultyRemembering)
          : this.noStep;
        ques.symptom === "fatigued" ? Object.assign(ques, symptomsRec.fatigued) : this.noStep;
        ques.symptom === "confused" ? Object.assign(ques, symptomsRec.confused) : this.noStep;
        ques.symptom === "drowsy" ? Object.assign(ques, symptomsRec.drowsy) : this.noStep;
        ques.symptom === "emotional" ? Object.assign(ques, symptomsRec.emotional) : this.noStep;
        ques.symptom === "irritable" ? Object.assign(ques, symptomsRec.irritable) : this.noStep;
        ques.symptom === "sad" ? Object.assign(ques, symptomsRec.sad) : this.noStep;
        ques.symptom === "nervousOrAnxious"
          ? Object.assign(ques, symptomsRec.nervousOrAnxious)
          : this.noStep;
      });
    }
  },
  mounted() {},
  methods: {
    getStepValue(stepValue, question) {
      const identifiedQuestion = this.questions.find((ques) => ques.symptom === question.symptom);
      identifiedQuestion.stepName = stepValue.name;
      identifiedQuestion.step = stepValue.step;
      const { step, stepName, symptom } = identifiedQuestion;
      this.$emit("setSymptoms", step, stepName, symptom);
    },
  },
};
</script>

<style scoped>
select {
  background-position-y: "-1px";
  font-weight: 400;
}
select:not(:first-child) {
  font-weight: 500;
}
</style>
