<template>
  <div v-if="clearancePermit.member || clearancePermit.memberId">
    <!-- DETAILS -->
    <el-row class="tw-font-bold">
      <el-card class="box-card">
        <el-row>
          <el-col :span="8">
            <h4>
              Request Details - Status:
              <span class="uppercase">{{ clearancePermit.approval.status }}</span>
            </h4>
          </el-col>
          <el-col :span="16" class="text-right">
            <span v-if="clearance < 5" id="edit-permit" class="tw-mx-1">
              <el-button
                v-if="clearancePermit.type === 1 && editing === false"
                @click="beginEditing"
                type="success"
                class="tw-w-48 tw-h-10"
              >
                Edit
              </el-button>
              <el-button
                v-if="clearancePermit.type === 1 && editing === true"
                @click="saveEdit"
                type="success"
                class="tw-w-48 tw-h-10 bulk-edit"
              >
                Save Edit
              </el-button>
            </span>
            <el-button @click="regoHistoryVisible = true" type="success"
              >REGISTRATION HISTORY</el-button
            >
            <el-button @click="playerStatsVisible = true" type="success">PLAYER STATS</el-button>
            <el-button
              @click="sendClearanceFinalisationEmail"
              type="success"
              v-if="firstReviewStatus === 'Awaiting participant registration'"
              >RESEND CLEARANCE APPROVED EMAIL</el-button
            >
          </el-col>
        </el-row>
        <h5 class="tw-text-sm">
          {{ `Type: ${clearancePermit.type === 1 ? "Permit" : "Clearance"} ` }}
        </h5>
        <div v-if="clearancePermit.type === 1">
          <h5 v-if="editing" class="tw-text-sm">Start Date:</h5>
          <el-date-picker
            v-if="editing"
            v-model="editData.startDate"
            type="date"
            placeholder="Select a Date"
            format="yyyy/MM/dd"
            value-format="timestamp"
            :picker-options="adminEditDates"
          >
          </el-date-picker>
          <h5 id="start-date" v-else class="tw-text-sm">
            {{ `Start Date: ${permitStartDate} ` }}
          </h5>
        </div>
        <div v-if="clearancePermit.type === 1">
          <h5 v-if="editing" class="tw-text-sm">End Date:</h5>
          <el-date-picker
            v-if="editing"
            v-model="editData.endDate"
            type="date"
            placeholder="Select a Date"
            format="yyyy/MM/dd"
            value-format="timestamp"
            :picker-options="adminEditDates"
            @change="setToEndOfDay"
          >
          </el-date-picker>
          <h5 id="end-date" v-else class="tw-text-sm">
            {{ `End Date: ${permitEndDate} ` }}
          </h5>
        </div>

        <h5 class="tw-text-sm">Requested: {{ `${permitRequestDate}` }}</h5>
        <br />

        <h5 class="tw-text-sm">
          {{
            `${clearancePermit.member.profile.firstName} ${clearancePermit.member.profile.lastName}`
          }}
        </h5>
        <h5 class="tw-text-sm">{{ clearancePermit.member.profile.gender }}</h5>
        <h5 class="tw-text-sm">{{ formatDOB }}</h5>
        <h5 class="tw-text-sm">{{ clearancePermit.member.profile.email }}</h5>
        <h5 class="tw-text-sm">{{ `NRLID: ${clearancePermit.member._id}` }}</h5>
        <h5 class="tw-text-sm">Member Type: {{ formartMemberType }}</h5>
        <h5 class="tw-text-sm">Reason: {{ clearancePermit.reason }}</h5>
        <br />
        <h5 class="tw-text-sm tw-bg-red-500" v-if="clearancePermit.member.defaulter">
          Defaulter: Yes
        </h5>
        <h5
          class="tw-text-sm tw-bg-red-500"
          v-if="
            clearancePermit.member.profile.meta &&
            clearancePermit.member.profile.meta.developmentFee
          "
        >
          Development fees may apply
        </h5>

        <el-row :gutter="10">
          <el-col :span="12">
            <h4 class="tw-pb-6">From Entity Details</h4>
            <h5 class="tw-text-sm">Club: {{ clearancePermit.fromClub.name }}</h5>
            <h5 class="tw-text-sm">
              Association: {{ clearancePermit.fromClub.orgtree.association.name }}
            </h5>
          </el-col>
          <el-col :span="12">
            <h4 class="tw-pb-6">To Entity Details</h4>
            <h5 class="tw-text-sm">Club: {{ clearancePermit.toClub.name }}</h5>
            <h5 class="tw-text-sm">
              Association: {{ clearancePermit.toClub.orgtree.association.name }}
            </h5>
          </el-col>
        </el-row>
      </el-card>
    </el-row>

    <!-- AUDIT -->
    <el-row>
      <h4 class="tw-pb-4 tw-font-bold tw-text-md">Timeline</h4>
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in reviews"
          :key="index"
          :color="item.color"
          :timestamp="item.timestamp"
        >
          {{ item.name }} ({{ item.entityType }})
          <br />
          <span class="uppercase">Status: {{ item.status }}</span>
          <br />
          Notes: {{ item.notes }}
        </el-timeline-item>
      </el-timeline>
    </el-row>

    <!-- SUBMIT APPROVAL/REJECTION -->
    <el-row
      v-if="
        (clearance === roles.superAdmin && clearancePermit.approval.status !== 'approved') ||
        (clearancePermit &&
          clearancePermit.approval.status === 'pending' &&
          !(clearancePermit.type === 1 && isToClub))
      "
    >
      <h4>Submit a review</h4>
      <el-form
        label-position="top"
        :model="update"
        :rules="rules"
        @submit.native.prevent
        ref="updateClerancePermit"
      >
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item prop="status" label="Status">
              <el-select v-model="update.status" placeholder="Select status">
                <el-option
                  v-for="item in status"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item prop="notes" label="Notes">
              <el-input
                type="text"
                autocomplete="off"
                v-model="update.notes"
                placeholder="Enter notes"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <action-buttons submit cancel @submit="submitForm()" @cancel="cancelForm()" />
    </el-row>

    <el-dialog
      title="Player Stats"
      :visible.sync="playerStatsVisible"
      width="70%"
      @opened="loadPlayerStats()"
      @closed="playerStatsVisible = false"
      :close-on-click-modal="false"
    >
      <el-table v-loading="playerStatsLoading" size="small" :data="playerStats" :border="true">
        <el-table-column prop="competition.season" label="Season" width="auto"> </el-table-column>
        <el-table-column
          prop="competition.orgtree.association.name"
          label="Association"
          width="250"
        >
        </el-table-column>
        <el-table-column prop="club.name" label="Club" width="350"> </el-table-column>
        <el-table-column prop="competition.name" label="Competition" width="300"> </el-table-column>
        <el-table-column prop="competition.ageLvl" label="Competition Age Group" width="100">
        </el-table-column>
        <el-table-column prop="team.name" label="Team" width="200"> </el-table-column>
        <el-table-column prop="games" label="Games Played" width="auto"> </el-table-column>
        <el-table-column prop="tries" label="Tries" width="100"> </el-table-column>
        <el-table-column prop="goals" label="Goals" width="100"> </el-table-column>
        <el-table-column prop="fieldGoals" label="1pt Field Goal" width="100"> </el-table-column>
        <el-table-column prop="fieldGoals2pt" label="2pt Field Goal" width="100"> </el-table-column>
        <el-table-column prop="totalPoints" label="Total Points" width="100"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="playerStatsVisible = false">Close</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Registration History"
      :visible.sync="regoHistoryVisible"
      width="70%"
      @opened="loadRegoHistory()"
      @closed="regoHistoryVisible = false"
      :close-on-click-modal="false"
    >
      <el-table v-loading="regoHistoryLoading" :data="regoHistory" size="small" :border="true">
        <el-table-column prop="club.name" label="Club Name" width="auto"> </el-table-column>
        <el-table-column prop="club.orgtree.association.name" label="Association Name" width="150">
        </el-table-column>
        <el-table-column prop="entityType" label="Entity Type" width="100"> </el-table-column>
        <el-table-column prop="memberType" label="Member Type" width="120"> </el-table-column>
        <el-table-column prop="season" label="Season" width="100"> </el-table-column>
        <el-table-column :formatter="statusFormatter" label="Status" width="100"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="regoHistoryVisible = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { orderBy, get } from "lodash";
import ActionButtons from "@/components/ActionButtons.vue";
import {
  clearancePermitStatuses,
  currentSeason,
  errormsg,
  memberTypes,
  roles,
} from "../../utils/constants";
import clearancePermit from "../../stores/clearance-permit";
import store from "../../store";

export default {
  name: "ClearancePermitForm",
  props: {},
  components: {
    ActionButtons,
  },
  data() {
    const valValidate = (obj, value, callback) => {
      if (value == null) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    return {
      status: [
        {
          value: "approved",
          name: "Approved",
        },
        {
          value: "declined",
          name: "Declined",
        },
      ],
      editing: false,
      editData: {},
      playerStatsVisible: false,
      playerStatsLoading: true,
      regoHistoryVisible: false,
      regoHistoryLoading: true,
      playerStats: [],
      regoHistory: [],
      update: {
        status: "",
        notes: "",
      },
      rules: {
        status: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
          validator: valValidate,
        },
        notes: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
      },
      clearancePermit: {},
      reviews: [],
      roles,
      regoToClub: {},
    };
  },
  beforeMount() {
    const activeRoleId = this.$store.getters["user/activeRole"].entity._id;
    const data = this.$route.params.clearancePermit;
    if (this.clearance === roles.superAdmin) {
      this.status = [
        {
          value: "pending",
          name: "Pending",
        },
        {
          value: "approved",
          name: "Approved",
        },
      ];
    }
    if (!data) {
      this.$router.push({ name: "clearance-permit.list" });
    } else {
      this.clearancePermit = _.cloneDeep(data);
      if (this.clearance === roles.associationAdmin) {
        if (data.approval) {
          const approvalStatus = this.mapApproval(data.approval, data.createdAt);
          this.reviews = approvalStatus;
        }
      } else {
        // Load the Registration History
        if (this.regoHistory.length === 0 && this.clearancePermit && this.clearancePermit.member) {
          this.$http
            .get(
              `/nrl/api/v1/admin/members/${this.clearancePermit.member._id}/registration-history/`
            )
            .then((response) => {
              const regoHistory = response.data.data.memberRegos;
              // Check for TO CLUB
              if (data.toClub) {
                const regoToClub = regoHistory.find(
                  (rego) =>
                    rego?.club?._id === this.clearancePermit?.toClub?._id &&
                    rego.season === currentSeason
                );
                this.regoToClub = _.cloneDeep(regoToClub);

                // Check if the registration doesnot exists
                if (
                  !regoToClub &&
                  this.clearancePermit.type === 2 &&
                  // activeRoleId === this.clearancePermit?.toClub?._id &&
                  (this.clearancePermit.approval.status === "approved" ||
                    this.clearancePermit.approval.status === "Awaiting participant registration")
                ) {
                  // No registration in TO CLUB, add a new status
                  this.newStatus = "Awaiting participant registration";
                  // add an extra step in Timeline for missing registration to TO CLUB:
                  const findToClub = this.clearancePermit.approval.clubs.find(
                    (c) => c._id === data.toClub._id
                  );
                  // If To Club doesnot exists in the approval.clubs
                  if (!findToClub) {
                    this.clearancePermit.approval.clubs.push({
                      _id: data.toClub._id,
                      name: data.toClub.name,
                      status: this.newStatus,
                    });
                    this.clearancePermit.approval.status = "Awaiting participant registration";
                  } else {
                    // To club exists in approval.clubs but member is not registered to To club
                    this.clearancePermit.approval.status = "Awaiting participant registration";
                  }

                  const url = `/nrl/api/v1/admin/clearance-permits/approval/${this.clearancePermit._id}`;
                  this.$http
                    .put(url, {
                      status: this.clearancePermit.approval.status,
                      notes: "",
                    })
                    .then((response) => {
                      this.$store.commit("app/loading", false);
                      this.update = { status: "", notes: "" };
                      // Response data only contains the updated approval object
                      const approvalStatus = this.mapApproval(
                        this.clearancePermit.approval,
                        this.clearancePermit.createdAt,
                        this.clearancePermit.toClub
                      );
                      // Update Overall status to hide form if approved
                      this.clearancePermit.approval.status = response.data.data.status;
                      // Update timeline
                      this.reviews = approvalStatus;
                      this.$customSuccess();
                    })
                    .catch((error) => {
                      this.$store.commit("app/loading", false);
                      this.$customError(
                        get(error, "response.data.message", `Error updating clearance: ${error}`)
                      );
                    });
                } else {
                  // If the approval.status is awaiting and registeration is done for the To Club
                  if (
                    this.clearancePermit.approval.status === "Awaiting participant registration"
                  ) {
                    let approval = data.approval;
                    const club = approval?.clubs.find(
                      (c) => c._id === this.clearancePermit.toClub._id
                    );

                    if (this.regoToClub?.club?._id === club?._id) {
                      for (let club of approval.clubs) {
                        if (club._id === this.regoToClub?.club?._id) club.status = "approved";
                      }
                    }
                    const approvalStatus = this.mapApproval(approval, data.createdAt);
                    this.reviews = approvalStatus;

                    // Finally update the status
                    const url = `/nrl/api/v1/admin/clearance-permits/approval/${this.clearancePermit._id}`;
                    this.$http
                      .put(url, {
                        status: "approved",
                        notes: "",
                      })
                      .then((response) => {
                        this.$store.commit("app/loading", false);
                        this.update = { status: "", notes: "" };
                        // Response data only contains the updated approval object
                        const approvalStatus = this.mapApproval(
                          response.data.data,
                          this.clearancePermit.createdAt,
                          this.clearancePermit.toClub
                        );

                        // Update Overall status to hide form if approved
                        this.clearancePermit.approval.status = response.data.data.status;

                        // Update timeline
                        this.reviews = approvalStatus;

                        this.$customSuccess();
                      })
                      .catch((error) => {
                        this.$store.commit("app/loading", false);
                        this.$customError(
                          get(error, "response.data.message", `Error updating clearance: ${error}`)
                        );
                      });
                  } else {
                    if (data.approval) {
                      const approvalStatus = this.mapApproval(data.approval, data.createdAt);
                      this.reviews = approvalStatus;
                    }
                  }
                }
              }
            });
        }
      }
    }
  },
  methods: {
    loadPlayerStats() {
      if (this.clearancePermit && this.clearancePermit.member) {
        const url = `/nrl/api/v1/admin/members/${this.clearancePermit.member._id}/player-stats/`;
        this.$http
          .get(url)
          .then((response) => {
            this.playerStats = orderBy(response.data.data, ["competition.season"], ["desc"]);
            this.playerStatsLoading = false;
          })
          .catch(() => {
            this.playerStatsLoading = false;
            this.$customError();
          });
      } else {
        this.playerStatsLoading = false;
      }
    },
    loadRegoHistory() {
      if (this.regoHistory.length === 0 && this.clearancePermit && this.clearancePermit.member) {
        const url = `/nrl/api/v1/admin/members/${this.clearancePermit.member._id}/registration-history/`;
        this.$http
          .get(url)
          .then((response) => {
            this.regoHistory = response.data.data.memberRegos;
            this.regoHistoryLoading = false;
          })
          .catch(() => {
            this.regoHistoryLoading = false;
            this.$customError();
          });
      } else {
        this.regoHistoryLoading = false;
      }
    },
    async submitForm() {
      if (this.clearance === roles.superAdmin && this.update.status === "approved") {
        const data = { id: this.clearancePermit._id, status: "Awaiting participant registration" };
        const manyClearancePermit = await this.$http.put(
          `/nrl/api/v1/admin/clearance-permits/member/${this.clearancePermit.member._id}`,
          data
        );
        const oneClearance = manyClearancePermit.data.data;

        if (!oneClearance) {
          this.$alert(
            "Other Clerances Permit exists for this member with Awaiting participant registration status"
          );
        }
      }
      new Promise((resolve, reject) => {
        this.$refs.updateClerancePermit.validate((validated) => (validated ? resolve() : reject()));
      })
        .then(() => {
          if (this.clearance === roles.superAdmin) {
            let url;

            if (this.update.status === "pending")
              url = `/nrl/api/v1/admin/clearance-permits/pending/${this.clearancePermit._id}`;
            else if (this.update.status === "approved") {
              url = `/nrl/api/v1/admin/clearance-permits/approval/${this.clearancePermit._id}`;
            }

            if (!url) return;
            this.$http
              .put(url, { status: this.update.status, notes: this.update.notes })
              .then((response) => {
                this.$store.commit("app/loading", false);
                this.update = { status: "", notes: "" };
                // Response data only contains the updated approval object
                const approvalStatus = this.mapApproval(
                  response.data.data,
                  this.clearancePermit.createdAt
                );
                // Update Overall status to hide form if approved
                this.clearancePermit.approval.status = response.data.data.status;
                // Update timeline
                this.reviews = approvalStatus;
                this.$customSuccess();
                // temp reset
                // this.$store.commit('clearancePermit/reset');
              })
              .catch((error) => {
                this.$store.commit("app/loading", false);
                this.$customError(get(error, "response.data.message"));
              });
          } else {
            const url = `/nrl/api/v1/admin/clearance-permits/approval/${this.clearancePermit._id}`;
            this.$http
              .put(url, { status: this.update.status, notes: this.update.notes })
              .then((response) => {
                this.$store.commit("app/loading", false);
                this.update = { status: "", notes: "" };
                // Response data only contains the updated approval object
                const approvalStatus = this.mapApproval(
                  response.data.data,
                  this.clearancePermit.createdAt
                );
                // Update Overall status to hide form if approved
                this.clearancePermit.approval.status = response.data.data.status;
                // Update timeline
                this.reviews = approvalStatus;
                this.$customSuccess();
                // temp reset
                // this.$store.commit('clearancePermit/reset');
              })
              .catch((error) => {
                this.$store.commit("app/loading", false);
                this.$customError(
                  get(error, "response.data.message", `Error updating clearance: ${error}`)
                );
              });
          }
        })
        .catch(() => {});
    },
    cancelForm() {
      this.$router.push({
        name: "clearance-permit.list",
      });
    },
    mapApproval(approval, createdAt) {
      // eslint-disable-next-line prefer-const
      let approvalStatus = [];
      Object.entries(approval).forEach(
        // eslint-disable-next-line no-unused-vars
        ([category, value]) => {
          if (value instanceof Array) {
            const found = value.filter((obj) => {
              obj.entityType =
                category.charAt(0).toUpperCase() + category.slice(1, category.length - 1);
              obj.color =
                obj.status === "approved"
                  ? "green"
                  : obj.status === "pending" || obj.status === "Awaiting participant registration"
                  ? "yellow"
                  : "red";
              obj.timestamp = this.moment(obj.date || createdAt).format("MMMM Do YYYY, h:mm:ss a");
              if (!obj.date) obj.date = createdAt;
              return obj.status;
            });
            approvalStatus = approvalStatus.concat(found);
          }
        }
      );
      // approvalStatus = orderBy(approvalStatus, ["date"], ["desc"]);
      approvalStatus = orderBy(approvalStatus, ["color"], ["desc"]);
      return approvalStatus;
    },
    dateFormatter(row) {
      return row.date ? this.moment(row.date).format("MMMM Do YYYY") : "NA";
    },
    statusFormatter(row) {
      if (row.expired === false && row.deregistered === false) {
        return "Active";
      }
      if (row.expired === false && row.deregistered === true) {
        return "Deregistered";
      }
      return "Inactive";
    },
    beginEditing() {
      if (this.clearancePermit.expired)
        return this.$customError("This permit has already expired. Unable to edit permit.");
      this.editData = _.cloneDeep(this.clearancePermit);
      this.editing = true;
    },
    async saveEdit() {
      try {
        this.$store.commit("root/loading", true);

        // no update data
        if (this.editData && Object.keys(this.editData).length === 0)
          throw { msg: "Unable to submit updates" };

        // request is not a permit
        if (this.editData.type !== 1)
          throw { msg: "This action can only be performed on permit requests" };

        // no start or end date for permit
        if (!this.editData.startDate || !this.editData.endDate)
          throw { msg: "To complete this action both Start Date and End Date is required" };

        // start date must be before end date
        if (this.editData.startDate > this.editData.endDate)
          throw { msg: "Start Date must be before End Date" };

        // start date must be after request date
        if (this.editData.startDate < this.clearancePermit.createdAt - 24 * 60 * 60 * 1000)
          throw { msg: "Start Date must be after Request Date" };

        // end date must be after request date
        if (this.editData.endDate < this.clearancePermit.createdAt - 24 * 60 * 60 * 1000)
          throw { msg: "End Date must be after Request Date" };

        // update edit
        const updates = {};
        const keys = Object.keys(this.clearancePermit);
        keys.forEach((k) => {
          const updateObj = {
            before: {},
            change: {},
          };
          if (JSON.stringify(this.clearancePermit[k]) !== JSON.stringify(this.editData[k])) {
            updateObj.before[k] = this.clearancePermit[k];
            updateObj.change[k] = this.editData[k];
            _.merge(updates, updateObj);
          }
        });

        await this.$http.put(`/nrl/api/v1/admin/clearance-permits/${this.editData._id}`, {
          update: this.editData,
          changes: updates,
        });

        this.clearancePermit = _.cloneDeep(this.editData);
        this.editData = {};
        this.editing = false;
        this.$store.commit("root/loading", false);
        return true;
      } catch (error) {
        const errMsg =
          error.response && error.response.data ? error.response.data.message : undefined;
        this.$store.commit("root/loading", false);
        this.$customError(errMsg || error.msg);
      }
    },
    disabledDate(time) {
      let disabled = false;
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentSeason = currentMonth < 11 ? now.getFullYear() : now.getFullYear() + 1;
      const currentYear = now.getFullYear();
      const yesterday = new Date(currentYear, now.getMonth(), now.getDate());
      const seasonEnd = new Date(currentSeason, 11, 1);
      let requestDate = this.clearancePermit.createdAt;
      const before = requestDate - 24 * 60 * 60 * 1000 || yesterday.getTime();

      if (before > time.getTime()) disabled = true;
      if (time.getTime() >= seasonEnd.getTime()) disabled = true;
      return disabled;
    },
    setToEndOfDay(value) {
      const endDate = new Date(value);
      const endOfDay = endDate.setHours(23, 59, 59, 999);
      this.editData.endDate = endOfDay;
    },
    sendClearanceFinalisationEmail() {
      this.$store.commit("root/loading", true);
      this.$http
        .post(
          store.getters["root/emailNotificationUrl"],
          {
            memberId: this.clearancePermit.member._id,
            notificationType: "clearance-finalisation",
          },
          {
            headers: {
              authorization: store.getters["user/bearerToken"],
            },
          }
        )
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess("Email sent successfully");
        });
    },
  },
  computed: {
    adminEditDates() {
      return {
        disabledDate: this.disabledDate,
      };
    },
    firstReviewStatus() {
      if (this.reviews.length > 0) return this.reviews[0].status;
      else return "NA";
    },
    isToClub() {
      const toClubId = get(this, "clearancePermit.toClub._id");
      const { entity } = this.$store.getters["user/activeRole"];
      if (entity) return toClubId === entity._id;
      return false;
      // return this.clearancePermit && this.clearancePermit.toClub && this.clearancePermit.toClub._id === this.$store.getters['user/activeRole'].entity._id;
    },
    permitRequestDate() {
      return (
        this.clearancePermit &&
        this.clearancePermit.createdAt &&
        this.moment(this.clearancePermit.createdAt).format("DD/MM/YYYY")
      );
    },
    permitStartDate() {
      return (
        this.clearancePermit &&
        this.clearancePermit.startDate &&
        this.moment(this.clearancePermit.startDate).format("DD/MM/YYYY")
      );
    },
    permitEndDate() {
      return (
        this.clearancePermit &&
        this.clearancePermit.endDate &&
        this.moment(this.clearancePermit.endDate).format("DD/MM/YYYY")
      );
    },
    formatDOB() {
      return (
        this.clearancePermit &&
        this.moment(this.clearancePermit.member.profile.dob).format("DD/MM/YYYY")
      );
    },
    formartMemberType() {
      const memberType =
        this.clearancePermit && memberTypes.find((x) => x.type === this.clearancePermit.memberType);
      return (memberType && memberType.name) || "NA";
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
  },
};
</script>

<style scoped lang="scss">
.el-select,
.el-input {
  width: 100%;
}
.box-card {
  margin-bottom: 1.5rem;
}
h5 {
  margin-top: 0;
  margin-bottom: 5px;
}
.red {
  color: red;
}
.uppercase {
  text-transform: capitalize;
}
.text-right {
  text-align: right;
}
</style>
