<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="8" v-if="programs.length">
          <el-form-item prop="program" label="Program">
            <el-select
              v-model="filters.programs"
              multiple
              filterable
              placeholder="Select a Program"
            >
              <el-option
                v-for="program in programs"
                :label="`${program.name} - ${formatStartDate(program.startDate)}`"
                :key="program._id"
                :value="program._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="includeDOB" label="Include Date of Birth">
            <el-checkbox v-model="filters.includeDOB" @change="checkDOB">
              Include DOB in report
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      title="Important Information"
      :visible.sync="showDOBInfo"
      width="30%"
      :before-close="closeDOBInfo"
    >
      <span v-html="dobPrivacyPolicy" :style="{'display': 'inline-block', 'wordBreak': 'break-word'}">
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDOBInfo">I understand</el-button>
      </span>
    </el-dialog>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { dobPrivacyPolicy } from "../../../utils/constants";
export default {
  name: "EntityDetailsReport",
  components: {
    ActionButtons,
  },
  data() {
    return {
      filters: {
        fileType: "csv",
        programs: [],
        includeDOB: false,
      },
      filterRules: {},
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      programs: [],
      showDOBInfo: false,
      dobPrivacyPolicy,
    };
  },
  async mounted() {
    try {
      this.$store.commit("root/loading", true);
      const activeRole = this.$store.getters["user/activeRole"];
      if (activeRole.type === 99 || activeRole.type === 98) {
        const programsRes = await this.$http.get("/nrl/api/v1/admin/programs");
        if (programsRes.data && programsRes.data.data && programsRes.data.data.length)
          this.programs = programsRes.data.data;
      }
      this.$store.commit("root/loading", false);
    } catch (error) {
      this.$store.commit("root/loading", false);
      this.$customError();
    }
  },
  methods: {
    formatStartDate(timestamp) {
      return moment(timestamp).format("DD/MM/YYYY HH:mm");
    },
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          const activeRole = this.$store.getters["user/activeRole"];
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "accreditation",
            fileType: this.filters.fileType,
            filters: {},
            meta: {
              timezone: moment.tz.guess(),
              activeRole,
            },
          };
          if (Array.isArray(this.filters.programs) && this.filters.programs.length) {
            body.filters.programs = this.filters.programs;
          }
          if (this.filters.includeDOB) {
            body.filters.includeDOB = true;
          }
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
    checkDOB() {
      if (this.filters.includeDOB) {
        this.showDOBInfo = true; // Show pop-up when DOB checkbox is checked
      }
    },
    closeDOBInfo() {
      this.showDOBInfo = false; // Close the modal
    },
  },
};
</script>
