<template>
  <div id="my-entity">
    <router-view/>
  </div>
</template>

<script>
import { roles } from '@/utils/constants';
import store from '@/store';

export default {
  beforeRouteEnter(to, from, next) {
    const entityId = store.getters['user/activeRole'].entity._id;
    const clearance = store.getters['user/activeRole'].type;
    if (to.path === '/my' || to.path === '/my/') {
      switch (clearance) {
        case roles.stateAdmin:
          next(`/my/states/${entityId}`);
          break;
        case roles.regionAdmin:
          next(`/my/regions/${entityId}`);
          break;
        case roles.associationAdmin:
          next(`/my/associations/${entityId}`);
          break;
        case roles.clubAdmin:
          next(`/my/clubs/${entityId}`);
          break;
        case roles.teamAdmin:
          next(`/my/teams/${entityId}`);
          break;
        case roles.gamedevregionAdmin:
          next(`/my/game-dev-regions/${entityId}`);
          break;
        default:
          next('/');
      }
    } else {
      next();
    }
  },
};
</script>
