<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="season" label="Season">
            <el-select clearable filterable v-model="filters.season" placeholder="Select a season">
              <el-option v-for="ss in historicSeasons" :label="ss" :key="ss" :value="ss">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="competitionId" label="Competition">
            <el-select
              clearable
              filterable
              v-model="filters.competitionId"
              placeholder="Select a competition"
            >
              <el-option
                v-for="entity in competitions"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="Team" label="Team">
            <el-select clearable v-model="filters.teamId" placeholder="Select a team">
              <el-option v-for="{ name, _id } in teams" :label="name" :key="_id" :value="_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { historicSeasons, roles, errormsg } from "@/utils/constants";

export default {
  name: "HistoricMatchStatsReport",
  components: {
    ActionButtons,
  },
  mounted() {},
  data() {
    return {
      historicSeasons,
      competitions: [],
      teams: [],
      filters: {
        fileType: "csv",
        season: undefined,
        competitionId: undefined,
        teamId: undefined,
      },
      filterRules: {
        season: {
          required: true,
          message: errormsg.select_option,
        },
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      roles,
    };
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "historic-match-stats",
            fileType: this.filters.fileType,
            filters: _.pick(this.filters, ["season", "competitionId", "teamId"]),
            meta: {
              timezone: moment.tz.guess(),
            },
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    // teams() {
    //   if (this.filters.competitionId) {
    //     const comp = _.find(this.competitions, { _id: this.filters.competitionId });
    //     const teamIds = _.map(comp.teams, t => t._id);
    //     return _.filter(this.myteams, t => _.includes(teamIds, t._id));
    //   }
    //   return this.myteams;
    // }
  },
  watch: {
    "filters.season": function (season) {
      this.filters.competitionId = undefined;
      if (!season) {
        this.competitions = [];
        return;
      }
      this.$store.commit("root/loading", true);
      this.$http
        .get(`/nrl/api/v1/admin/legacy-competitions/seasons/${season}`)
        .then((res) => {
          this.competitions = res.data.data;
        })
        .finally(() => this.$store.commit("root/loading", false));
    },
    "filters.competitionId": function (_id) {
      this.filters.teamId = undefined;
      if (!_id) {
        this.teams = [];
        return;
      }
      const comp = _.find(this.competitions, { _id });
      let entityName = "";
      const { type, entity } = this.$store.getters["user/activeRole"];
      switch (type) {
        case 1:
          entityName = "national";
          break;
        case 2:
          entityName = "state";
          break;
        case 3:
          entityName = "region";
          break;
        case 4:
          entityName = "association";
          break;
        case 5:
          entityName = "club";
          break;
        default:
          break;
      }
      this.teams = _.filter(comp.teams, (t) => {
        const id = _.get(t, `orgtree.${entityName}._id`);
        return id && id === entity._id;
      });
    },
  },
};
</script>

<style scoped></style>
