<template>
  <div>
    <div class="heading">{{ name }}</div>
    <el-checkbox-group
      v-model="inputVal"
      id="CheckBoxGroup"
      class="checkboxes"
      :min="min"
      :max="max"
    >
      <el-checkbox
        v-for="choice in choices"
        :id="choice.type"
        :label="choice.type"
        :key="choice.type"
        border
      >
        {{ choice.name }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import { EventBus } from "../bus";

export default {
  name: "CheckBoxGroup",
  props: {
    choices: Array, // can be [object or string]
    name: String,
    value: Array,
    min: Number,
    max: Number
  },
  computed: {},
  mounted() {
    EventBus.$on("clear", () => {
      this.handleAll();
    });
  },
  data() {
    return {
      inputVal: this.value
    };
  },
  methods: {
    handleAll() {
      this.$set(this, "inputVal", []);
    }
  },
  watch: {
    inputVal(val) {
      let data = val;
      if (data.length === this.choices.length) {
        data = [];
      }
      this.inputVal = data;
      this.$emit("input", data);
    },
    value(val) {
      this.inputVal = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-checkbox {
  word-break: normal;
}

.heading {
  margin: 5px 15px 0px;
}

.checkboxes {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  ::v-deep label {
    border-radius: 0;
    border: 1px solid lightgrey;
    text-align: center;
    font-size: 1rem;
    display: inline-block;
    margin: 15px 0 0 15px !important;
    padding: 20px 5% 40px;
    /*min-height: 70px;*/
    flex-grow: 1;
    width: calc(100% * (1 / 3) - 15px - 1px);
    max-width: calc(100% * (1 / 3) - 15px - 1px);
  }

  ::v-deep .el-checkbox {
    padding-top: 0;
    padding-bottom: 0;
    height: 50px;

    // truncating lines
    // https://css-tricks.com/line-clampin/
    overflow: hidden;
  }

  // affect children deeply with this
  ::v-deep .el-checkbox__input {
    display: none; // hide the checkbox
  }

  ::v-deep .el-checkbox__label {
    font-size: 80%;
    padding-left: 0;
    color: $primary;
    white-space: normal;

    // https://www.w3schools.com/howto/howto_css_center-vertical.asp
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    // https://css-tricks.com/line-clampin/
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .is-checked {
    border: 2px solid black;
  }
}
</style>
