const moment = require("moment");

/**
 * Parse google address to our address format
 * @param {Object} addressData: vue2-google-maps addressData
 * @param {Object} place: google api original place result
 */
const parseGoogleAddress = (addressData, place) => {
  const address = {
    formatted: place.formatted_address,
    lat: addressData.latitude,
    lng: addressData.longitude,
    addressLine1:
      (addressData.street_number ? `${addressData.street_number} ` : "") + addressData.route,
    addressLine2: "",
    suburb: addressData.locality,
    state: addressData.administrative_area_level_1,
    postcode: addressData.postal_code,
    country: addressData.country,
  };
  return address;
};

/**
 * S3 URL Entity - Resize
 * @param {Number} id
 */
const getS3URL = (id) => `${process.env.VUE_APP_S3}/logos/resize/${id}.png?v=${moment().valueOf()}`;

/**
 * Calculate age based on day of birth
 * @param {string} dob: day of birth
 * @param {boolean} endOfYear: calculate age by start of birth year, default: false
 * @param {string} format: day of birth format, default: 'YYYY-MM-DD'
 * @param {string} endOfSeason
 */
// const date = new Date();
// const currentMonth = date.getMonth();
// const currentSeason = currentMonth < 10 ? date.getFullYear() : date.getFullYear() + 1;

const calculateAge = (dob, startOfYear = false, format = "YYYY-MM-DD", endOfSeason) => {
  if (endOfSeason === null || endOfSeason === undefined) {
    const date = new Date();
    const currentSeason = date.getMonth() <= 10 ? date.getFullYear() : date.getFullYear() + 1;
    endOfSeason = `${currentSeason}-12-01`;
  }
  if (startOfYear) {
    const startOfYearBirth = moment(dob, format).startOf("year");
    return moment(endOfSeason, "YYYY-MM-DD").diff(startOfYearBirth, "years");
  }
  const birth = moment(dob, format);
  return moment().diff(birth, "years");
};

const validatePhoneNumber = (value) => {
  const re =
    /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
  return re.test(value);
};

const validateEmail = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

module.exports = {
  parseGoogleAddress,
  getS3URL,
  calculateAge,
  validateEmail,
  validatePhoneNumber,
};
