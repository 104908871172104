<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="selectedCompetition" label="Competition">
            <el-select
              filterable
              multiple
              v-model="filters.selectedCompetition"
              placeholder="Select a competition"
            >
              <el-option
                v-for="entity in competitions"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="roundNumber" label="Round">
            <el-select v-model="filters.roundNumber" placeholder="Select a round number">
              <el-option v-for="round in roundNumbers" :label="round" :key="round" :value="round">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg } from "@/utils/constants";

export default {
  name: "MatchesReport",
  components: {
    ActionButtons,
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then((res) => {
        this.competitions = res.data.data;
      })
      .catch(() => {
        this.$customError("Unable to fetch competitions");
      });
  },
  data() {
    return {
      competitions: [],
      filters: {
        fileType: "csv",
        selectedCompetition: [], //null,
        roundNumber: null,
      },
      filterRules: {
        selectedCompetition: {
          required: true,
          trigger: "blur",
          message: errormsg.select_option,
        },
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      roundNumbers: [...Array(51).keys()].filter((r) => r !== 0),
    };
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "matches-noparticipant",
            fileType: this.filters.fileType,
            filters: {
              competitionIds: this.filters.selectedCompetition,
              roundNumber: this.filters.roundNumber,
            },
            meta: {
              timezone: moment.tz.guess(),
            },
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
  },
};
</script>
