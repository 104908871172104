<template>
  <div>
    <el-form
    label-position="top"
    :rules="rules"
    :model="currentAutoApproval"
    ref="autoapproval">
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="6">
          <el-form-item prop="memberType" label="Member Type">
            <el-select
              v-model="currentAutoApproval.memberType"
              placeholder="Select type">
              <el-option
                v-for="item in filteredMemberTypes"
                :key="item.type"
                :label="item.name"
                :value="item.type"
                >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
            <el-form-item prop="dobFrom" label="DOB From">
                <el-date-picker
                type="date"
                v-model="currentAutoApproval.dobFrom"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder=""/>
            </el-form-item>
        </el-col>
        <el-col :span="6">
            <el-form-item prop="dobTo" label="DOB To">
                <el-date-picker
                type="date"
                v-model="currentAutoApproval.dobTo"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="" />
            </el-form-item>
        </el-col>
        <el-col :span="6">
            <el-form-item prop="autoApproval" label="Set Auto-approvals for">
                <el-select
                v-model="currentAutoApproval.autoApproval"
                placeholder="">
                    <el-option
                        v-for="{type, name} in autoApprovals"
                        :label="name"
                        :key="type"
                        :value="type">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitForm('autoapproval')">Add</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <data-tables
        v-if="entityApprovals && entityApprovals.length > 0"
      :data="entityApprovals"
      :table-props="tableProps"
      layout="table"
      class="data-table">
      <el-table-column label="Member Type" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.memberType"
            :disabled="!optionsdisabled[scope.$index]"
            placeholder="Member Type" filterable>
              <el-option
                v-for="{name, type} in filteredMemberTypes"
                :key="type"
                :label="name"
                :value="type">
              </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Date of Birth From" width="200">
          <template slot-scope="scope">
            <el-date-picker
                type="date"
                v-model="scope.row.dobFrom"
                :disabled="!optionsdisabled[scope.$index]"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder=""/>
          </template>
      </el-table-column>
      <el-table-column label="Date of Birth To" width="200">
          <template slot-scope="scope">
            <el-date-picker
                type="date"
                v-model="scope.row.dobTo"
                :disabled="!optionsdisabled[scope.$index]"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder=""/>
          </template>
      </el-table-column>
       <el-table-column label="Auto-approvals for" width="200">
          <template slot-scope="scope">
            <el-select
            v-model="scope.row.autoApproval"
            :disabled="!optionsdisabled[scope.$index]"
            placeholder="">
                <el-option
                    v-for="{type, name} in autoApprovals"
                    :label="name"
                    :key="type"
                    :value="type">
                </el-option>
            </el-select>
          </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" fixed="right" width="300">
        <template slot-scope="scope">
          <el-button @click="handleEditRow(scope.$index)">
            <template v-if="!optionsdisabled[scope.$index]">
                Edit
            </template>
            <template v-else>
                Save
            </template>
          </el-button>
          <el-button @click="handleRemoveRow(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  errormsg,
  memberTypes,
  autoApprovals
} from '../../utils/constants';

export default {
  name: 'EntityAutoApproval',
  props: [
    'entity',
    'type' // clearance or permit
  ],
  mounted() {
  },
  data() {
    const validateDOBTo = (rule, value, callback) => {
      if (!this.currentAutoApproval.dobTo) {
        return callback(new Error(errormsg.select_option));
      }
      if (this.currentAutoApproval.dobFrom && !this.validateDOB(this.currentAutoApproval.dobFrom, this.currentAutoApproval.dobTo)) {
        return callback(new Error(errormsg.gte_dob));
      }
      return callback();
    };

    return {
      metaKey: this.type === 'clearance' ? 'autoApprovals' : 'permitAutoApprovals',
      autoApprovals,
      currentAutoApproval: {
        memberType: '',
        dobFrom: '',
        dobTo: '',
        autoApproval: ''
      },
      optionsdisabled: [],
      tableProps: {
        border: true,
      },
      rules: {
        memberType: {
          required: true,
          message: errormsg.select_option,
          trigger: 'change',
        },
        dobFrom: {
          required: true,
          message: errormsg.select_option,
          trigger: 'blur',
        },
        dobTo: {
          required: true,
          validator: validateDOBTo,
          trigger: 'blur',
        },
        autoApproval: {
          required: true,
          message: errormsg.select_option,
          trigger: 'change',
        }
      }
    };
  },
  methods: {
    validateDOB(dobFrom, dobTo) {
      const from = this.moment(dobFrom, 'YYYY-MM-DD').valueOf();
      const to = this.moment(dobTo, 'YYYY-MM-DD').valueOf();
      return from <= to;
    },
    overlapping(currentApproval, listApproval) {
      return listApproval && listApproval.length && listApproval.find(x => currentApproval.memberType === x.memberType
          && currentApproval.autoApproval === x.autoApproval
          && this.moment(currentApproval.dobTo, 'YYYY-MM-DD').valueOf() >= this.moment(x.dobFrom, 'YYYY-MM-DD').valueOf()
          && this.moment(currentApproval.dobFrom, 'YYYY-MM-DD').valueOf() <= this.moment(x.dobTo, 'YYYY-MM-DD').valueOf());
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.overlapping(this.currentAutoApproval, this.entityApprovals)) {
            this.$customError(errormsg.dob_overlap);
            return false;
          }

          this.$store.commit('root/loading', true);

          const url = `/nrl/api/v1/admin/auto-approvals/${this.type}`;
          this.$http
            .post(url, this.currentAutoApproval)
            .then((response) => {
              this.currentAutoApproval._id = response.data.data._id;
              this.entityApprovals.push(_.cloneDeep(this.currentAutoApproval));
              this.$store.commit('root/loading', false);
              this.optionsdisabled.push(false);
              this.$set(this.currentAutoApproval, 'dobFrom', '');
              this.$set(this.currentAutoApproval, 'dobTo', '');
              this.$customSuccess();
            })
            .catch((error) => {
              this.$store.commit('root/loading', false);
              this.$customError(_.get(error, 'response.data.message'));
            });
          return true;
        }
        return false;
      });
    },
    handleEditRow(rowIndex) {
      if (this.optionsdisabled[rowIndex]) {
        const approval = this.entityApprovals[rowIndex];

        if (!approval.dobFrom) {
          this.$customError(errormsg.dob_from);
          return false;
        }

        if (!approval.dobTo) {
          this.$customError(errormsg.dob_to);
          return false;
        }

        if (!this.validateDOB(approval.dobFrom, approval.dobTo)) {
          this.$customError(errormsg.gte_dob);
          return false;
        }

        if (this.overlapping(approval, this.entityApprovals.filter(x => x._id !== approval._id))) {
          this.$customError(errormsg.dob_overlap);
          return false;
        }

        this.$store.commit('root/loading', true);
        const url = `/nrl/api/v1/admin/auto-approvals/${this.type}/${approval._id}`;
        this.$http
          .put(url, approval)
          .then(() => {
            this.$store.commit('root/loading', false);
            this.$customSuccess();
          })
          .catch((error) => {
            this.$store.commit('root/loading', false);
            this.$customError(_.get(error, 'response.data.message'));
          });
      }
      this.$set(this.optionsdisabled, rowIndex, !this.optionsdisabled[rowIndex]);
      return true;
    },
    handleRemoveRow(rowIndex) {
      this.$store.commit('root/loading', true);
      this.$confirm('Are you sure you want to remove this auto-approval rule?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        // remove auto approval rule
        const approval = this.entityApprovals[rowIndex];
        this.$http
          .delete(`/nrl/api/v1/admin/auto-approvals/${this.type}/${approval._id}`)
          .then(() => {
            this.entityApprovals.splice(rowIndex, 1);
            this.optionsdisabled.splice(rowIndex, 1);
            this.$store.commit('root/loading', false);
            this.$customSuccess();
          })
          .catch((error) => {
            this.$store.commit('root/loading', false);
            this.$customError(_.get(error, 'response.data.message'));
          });
      }).catch(() => {
        this.$store.commit('root/loading', false);
      });
    }
  },
  computed: {
    filteredMemberTypes() {
      return memberTypes.filter(m => ['player', 'player-league-tag', 'referee'].includes(m.type));
    },
    entityApprovals: {
      get() {
        return this.entity.meta[this.metaKey] || [];
      },
      set(val) {
        if (!this.entity.meta[this.metaKey]) {
          this.entity.meta[this.metaKey] = [];
        }
        this.entity.meta[this.metaKey].push(val);
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .data-table {
      width: 100%;
      margin-top: 1rem !important;
  }
  .el-pagination {
      margin-top: 1rem !important;
  }
  .el-select, .el-input {
    width: 100%;
  }
  .btn-container{
    text-align: right;
  }
</style>
