<template>
  <div class="roles-holder">
    <el-row>
      <el-button type="success" @click="refreshRoles"> Refresh Roles </el-button>
    </el-row>
    <el-row class="mt-1">
      <el-input
        placeholder="Type to filter entities.."
        prefix-icon="el-icon-search"
        v-model="filterRole"
      >
      </el-input>
    </el-row>
    <el-row v-for="role in formattedRole" :key="role.id" class="role" :gutter="20">
      <div style="width: 100%; height: 100%" @click="switchRole($event, role)">
        <el-col :span="6" style="height: 100%">
          <div class="img-container">
            <img v-if="role.logo" :src="role.logo" @error="displayPlaceholder" class="role-logo" />
            <img v-else src="~@/assets/shield_black.png" alt="" class="role-logo" />
          </div>
        </el-col>
        <el-col :span="14" style="height: 100%">
          <h4 class="role-name">{{ role.name }}</h4>
          <h5 class="role-entity">{{ role.entityName }}</h5>
        </el-col>
        <el-col v-if="isCurrentRole(role._id)" :span="4">
          <i circle class="active-role el-icon-check"></i>
        </el-col>
      </div>
    </el-row>
  </div>
</template>
<script>
import { orgtree } from "../utils/constants";
import store from "../store";
import { getS3URL } from "../utils/utilities";

export default {
  name: "RoleSwitcher",
  data() {
    return {
      store,
      filterRole: "",
    };
  },
  computed: {
    formattedRole() {
      const filteredRoles = this.$store.getters["user/roles"]
        .filter(
          (role) =>
            role.type !== 51 &&
            role.type !== 101 &&
            role.type !== 102 &&
            role.type !== 103 &&
            role.type !== 104
        )
        .map((role) => ({
          ...role,
          name:
            role.type === 105
              ? "Club Program Admin"
              : role.type !== 99
              ? orgtree[role.type].name
              : "Program Admin",
          entityName: role.entity ? role.entity.name : "",
          logo: role.entity ? getS3URL(role.entity._id) : null,
        }))
        .sort((a, b) => (a.type > b.type ? 1 : a.type < b.type ? -1 : 0));

      // Apply filtering based on role.entityName
      return filteredRoles.filter((role) =>
        role.entityName.toLowerCase().includes(this.filterRole.toLowerCase())
      );
    },
  },
  methods: {
    displayPlaceholder(event) {
      event.target.src = require("../assets/shield_black.png");
    },
    switchRole(event, role) {
      this.store.commit("user/switchRole", role);
      // reset states
      this.store.commit("entities/reset");
      this.store.commit("competitions/reset");
      this.store.commit("clearancePermit/reset");
      this.store.commit("members/reset");
      this.store.commit("users/reset");
      this.store.commit("duplicateMembers/reset");
      // TODO clean up
      //  if (this.$route.path !== "/") this.$router.push("/");
      this.$router.go();
      this.$emit("closeModal");
    },
    isCurrentRole(id) {
      return id === this.$store.getters["user/activeRoleId"];
    },
    refreshRoles() {
      this.$store.commit("root/loading", true);
      this.$http
        .get("/nrl/api/v1/admin/users/roles")
        .then((response) => {
          this.$store.commit("user/updateRoles", response.data.data);
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.img-container {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roles-holder {
  height: 26rem;
  overflow: scroll;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.role {
  text-align: left;
  color: black;
  width: 95%;
  margin: 1rem auto !important;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
  overflow: hidden;

  .role-name {
    font-weight: 700;
    padding-top: 1.5rem;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold !important;
  }

  .role-entity {
    margin-top: 0;
  }

  .img-container {
    overflow: hidden;
    .role-logo {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      height: 4.5rem;
    }
  }

  .active-role {
    padding: 15px;
    border-radius: 50%;
    font-size: 0.75rem;
    position: absolute;
    right: 6%;
    top: 30%;
    background: $secondary;
    color: white;
  }
}
</style>
