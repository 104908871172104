<template>
  <div>
    <el-form label-position="top" :model="laddertemplate" :rules="rules" ref="laddertemplate">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="name" label="Template Name">
            <el-input
              autocomplete="off"
              placeholder="Enter template name"
              v-model="laddertemplate.name"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-6 ">
        <el-col :span="6">
          POINTS
        </el-col>
      </div>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item prop="pointsWin" label="Win">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="laddertemplate.pointsWin"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="pointsLoss" label="Loss">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="laddertemplate.pointsLoss"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="pointsDraw" label="Draw">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="laddertemplate.pointsDraw"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item prop="pointsBye" label="Bye">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="laddertemplate.pointsBye"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="pointsFF" label="Forfeit (Receiving)">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="laddertemplate.pointsFF"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="pointsWO" label="Wash Out">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="laddertemplate.pointsWO"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-6 ">
        <el-col :span="18">
          FORFEITS
        </el-col>
      </div>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item prop="ffCountAsWin" label="Received Count as Wins">
            <el-select v-model="laddertemplate.ffCountAsWin" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="ffCountAsLoss" label="Given Count as Loss">
            <el-select v-model="laddertemplate.ffCountAsLoss" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="defaultScoreFFReceived" label="Default Score">
            <el-input
              autocomplete="off"
              placeholder="Enter default score"
              v-model.number="laddertemplate.defaultScoreFFReceived"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-6 ">
        <el-col :span="18">
          COUNT AS PLAYED
        </el-col>
      </div>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item prop="woCountAsPlayed" label="Wash Out">
            <el-select v-model="laddertemplate.woCountAsPlayed" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="byeCountAsPlayed" label="Bye">
            <el-select v-model="laddertemplate.byeCountAsPlayed" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="ffCountAsPlayed" label="Forfeit">
            <el-select v-model="laddertemplate.ffCountAsPlayed" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="btn-container">
        <el-button type="success" @click="submitForm('laddertemplate')">Save</el-button>
        <el-button @click="cancelForm()">Cancel</el-button>
      </el-row>
      <el-row :gutter="10" class="drag-drop">
        <el-col :span="6">
          Sort By Options, Drag to Change Order
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <ul id="items">
            <li v-for="(item, index) in laddertemplate.sortBy" :key="item" :data-option="item">
              <el-card> {{ index + 1 }} {{ item }} </el-card>
            </li>
          </ul>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { errormsg, sortBy } from "../../utils/constants";

const _ = require("lodash");

const setupDragging = (set, sortby, sortable, store) => {
  const allSortItems = document.getElementById("items");
  sortable.create(allSortItems, {
    onEnd() {
      const el = document.getElementById("items");
      const sortByOptions = [].map.call(
        el.querySelectorAll("[data-option]"),
        ele => ele.dataset.option
      );
      _.each(sortByOptions, (o, index) => set(sortby, index, sortByOptions[index]));
    }
  });
  store.commit("root/loading", false);
};

export default {
  name: "LadderTemplate",
  props: {},
  data() {
    const positiveIntValidate = (obj, value, callback) => {
      if (!_.isInteger(value)) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    return {
      type: null,
      laddertemplate: {
        orgtree: {
          national: {
            _id: null,
            name: null
          }
        },
        name: null,
        pointsWin: null,
        pointsLoss: null,
        pointsDraw: null,
        pointsBye: null,
        pointsFF: null,
        pointsWO: null,
        ffCountAsWin: false,
        ffCountAsLoss: false,
        ffCountAsPlayed: false,
        defaultScoreFFReceived: null,
        woCountAsPlayed: false,
        byeCountAsPlayed: false,
        sortBy
      },
      rules: {
        name: { required: true, message: errormsg.input_option, trigger: "blur" },
        pointsWin: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: positiveIntValidate
        },
        pointsLoss: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: positiveIntValidate
        },
        pointsDraw: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: positiveIntValidate
        },
        pointsBye: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: positiveIntValidate
        },
        pointsFF: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: positiveIntValidate
        },
        pointsWO: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: positiveIntValidate
        },
        ffCountAsWin: { required: true },
        ffCountAsLoss: { required: true },
        ffCountAsPlayed: { required: true },
        defaultScoreFFReceived: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: positiveIntValidate
        },
        woCountAsPlayed: { required: true },
        byeCountAsPlayed: { required: true }
      }
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, ladderTemplate, id } = this.$route.params;
    this.type = type;
    if (id && id !== "" && id !== "new") {
      this.type = "update";
      if (ladderTemplate) {
        this.laddertemplate = { ...ladderTemplate };
        setupDragging(this.$set, this.laddertemplate.sortBy, this.sortable, this.$store);
        this.$store.commit("root/loading", false);
      } else {
        this.$http
          .get(`/nrl/api/v1/admin/ladder-templates/${id}`)
          .then(response => {
            this.laddertemplate = response.data.data;
            setupDragging(this.$set, this.laddertemplate.sortBy, this.sortable, this.$store);
            this.$store.commit("root/loading", false);
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
    } else {
      this.type = "insert";
      setupDragging(this.$set, this.laddertemplate.sortBy, this.sortable, this.$store);
      this.$store.commit("root/loading", false);
    }
  },
  methods: {
    submitForm(formname) {
      this.$refs[formname].validate(valid => {
        if (valid) {
          if (this.type === "insert") {
            const insert = { ...this.laddertemplate };
            const { entity } = this.$store.getters["user/activeRole"];
            insert.orgtree.national._id = entity._id;
            insert.orgtree.national.name = entity.name;
            const url = "/nrl/api/v1/admin/ladder-templates";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$router.push({ name: "laddertemplate.list" });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            const url = `/nrl/api/v1/admin/ladder-templates/${this.laddertemplate._id}`;
            this.$http
              .put(url, this.laddertemplate)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
          return true;
        }
        return false;
      });
    },
    cancelForm() {
      this.$router.push({ name: "laddertemplate.list" });
    }
  },
  computed: {}
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.btn-container-add {
  float: right;
}

.data-table {
  margin-top: 2.5rem !important;
}

#items {
  list-style-type: none;
  padding-left: 0;
}

.drag-drop {
  margin-top: 5rem;
}
</style>
