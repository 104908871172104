<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br>
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select
              v-model="filters.fileType"
              placeholder="Select a file type"
            >
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="clearance === roles.associationAdmin">
        <el-col :span="8">
          <el-form-item prop="club" label="Club">
            <el-select
              v-model="filters.selectedClub"
              placeholder="Select a club"
              multiple
            >
              <el-option
                v-for="{ name, _id } in clubs"
                :label="name"
                :key="_id"
                :value="_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons
      submit
      submitText="Submit"
      :cancel="false"
      @submit="sendReport"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import ActionButtons from '@/components/ActionButtons.vue';
import {
  roles
} from '@/utils/constants';

export default {
  name: 'UnassignedMembersReport',
  components: {
    ActionButtons
  },
  mounted() {
    if (this.clearance === roles.associationAdmin) {
      this.$store.commit('root/loading', true);
      this.$http.get('/nrl/api/v1/admin/clubs')
        .then((res) => {
          this.clubs = res.data.data;
          this.$store.commit('root/loading', false);
        })
        .catch(() => {
          this.$customError('Unable to fetch clubs');
          this.$store.commit('root/loading', false);
        });
    }
  },
  data() {
    return {
      clubs: [],
      filters: {
        fileType: 'csv',
        selectedClub: null,
      },
      fileTypes: [
        { name: 'CSV', type: 'csv', default: true }
      ],
      roles,
    };
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit('root/loading', true);
          const body = {
            reportType: 'unassigned-members',
            fileType: this.filters.fileType,
            filters: {
              clubFilter: this.filters.selectedClub,
            },
            meta: {
              timezone: moment.tz.guess(),
            }
          };
          const url = '/nrl/api/v1/admin/reports';
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit('root/loading', false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit('root/loading', false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  },
  computed: {
    clearance() {
      return this.$store.getters['user/activeRole'].type;
    },
  },
};
</script>
