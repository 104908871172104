<template>
  <div>
    <el-form
      label-position="top"
      :model="newpool"
      :rules="poolRules"
      id="poolRules"
      ref="poolRules"
    >
      <el-row align="center" :gutter="10">
        <el-col :span="12">
          <el-form-item prop="newPoolName" label="Pool Name">
            <el-input placeholder="Pool Name" v-model="newpool.newPoolName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="teamsToAddToPool" label="Teams To Add">
            <el-select v-model="newpool.teamsToAddToPool" multiple placeholder="Select" :key="key">
              <el-option
                v-for="item in availableTeamsForPools"
                :key="item.name"
                :label="item.team_label"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 ">
        <el-col :span="4">
          <el-button type="success" @click="addToPool"> Add Pool </el-button>
        </el-col>
      </div>
    </el-form>
    <el-row>
      <el-table
        v-for="pool in competition.pools"
        :key="pool._id"
        :data="pool.teams"
        class="pool-table"
        style="width: 100%"
      >
        <el-table-column align="center" :label="pool.name">
          <el-table-column align="center" prop="team_label" label="Name" width="auto">
          </el-table-column>
          <el-table-column align="center" label="Operations">
            <template slot-scope="scope">
              <el-button @click="removeFromPool(scope.row._id, pool.name)">
                Remove From Pool
              </el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import { errormsg } from "../../utils/constants";
import { EventBus } from "../../bus";

export default {
  data() {
    return {
      key: 0,
      newpool: {
        newPoolName: "",
        teamsToAddToPool: []
      },
      poolRules: {
        newPoolName: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        teamsToAddToPool: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        }
      },
      mycomp: this.comp
    };
  },
  props: {
    comp: { type: Object, required: true }
  },
  mounted() {
    EventBus.$on("updateCompetition", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    removeFromPool(teamid, poolName) {
      this.$store.commit("root/loading", true);
      const { _id } = this.competition;
      this.$http
        .post(`/nrl/api/v1/admin/competitions/${_id}/remove-from-pool`, { teamid, poolName })
        .then(response => {
          this.$store.commit("root/loading", false);
          this.$set(this.competition, "pools", response.data.data);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    addToPool() {
      this.$refs.poolRules.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const { competition, newpool } = this;
          const { teamsToAddToPool, newPoolName: poolName } = newpool;
          const { _id } = competition;
          const teams = competition.teams.filter(team => teamsToAddToPool.includes(team._id));
          this.$http
            .post(`/nrl/api/v1/admin/competitions/${_id}/add-to-pool`, { teams, poolName })
            .then(response => {
              this.$customSuccess();
              this.$set(this.competition, "pools", response.data.data);
              this.$set(this.newpool, "teamsToAddToPool", []);
              this.$set(this.newpool, "newPoolName", "");
              this.$store.commit("root/loading", false);
            })
            .catch(() => {
              this.$customError();
              this.$store.commit("root/loading", false);
            });
        }
      });
      return false;
    }
  },
  computed: {
    availableTeamsForPools() {
      const { teams, pools } = this.competition;
      for (let i = 0; i < teams.length; i++) {
        teams[i].team_label = `${teams[i].name} - ${
          teams[i].orgtree.club ? teams[i].orgtree.club.name : ""
        }`;
      }
      return pools && pools.length > 0
        ? teams.filter(
            team => !pools.some(pool => pool.teams.findIndex(t => t._id === team._id) !== -1)
          )
        : teams;
    },
    competition() {
      if (this.comp.pools && this.comp.pools.length > 0) {
        this.comp.pools.forEach(pool => {
          pool.teams.forEach(team => {
            team.team_label = `${team.name} - ${team.orgtree.club ? team.orgtree.club.name : ""}`;
          });
        });
      }
      return this.comp;
    }
  }
};
</script>

<style lang="scss" scoped>
.pool-table {
  margin-top: 1.25rem;
}

.el-input,
.el-select {
  width: 100%;
}
</style>
