<template>
  <div>
    <el-form label-position="top" id="programType" ref="programType">
      <div
        id="request"
        class="tw-flex tw-flex-wrap tw-items-end tw-w-full tw-max-w-5xl tw-gap-4 tw-pt-4"
      >
        <div class="tw-w-full sm:tw-w-auto">
          <el-form-item label="Select a Program Type Setting">
            <el-select filterable v-model="progType" placeholder="Select a Program Type Setting">
              <el-option
                v-for="item in allProgramTypeSettings"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="tw-flex tw-justify-start tw-flex-1 sm:tw-pr-3 tw-align-middle">
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <el-button name="success" id="success" type="success" @click="requestprogramTypeSetting"
              >Request</el-button
            >
          </div>
        </div>
      </div>
    </el-form>

    <el-row>
      <h4 class="mt-2 tw-font-bold">Previously Requested</h4>
      <data-tables
        :data="requestedProgramType"
        :table-props="tableProps"
        layout="table"
        class="data-table"
      >
        <el-table-column fixed prop="type" label="Program Type Setting"> </el-table-column>
        <el-table-column prop="createdByName" label="Requested By"></el-table-column>
        <el-table-column prop="createdAt" :formatter="getFormattedDate" label="Requested At">
        </el-table-column>
        <el-table-column prop="status" label="Status"> </el-table-column>
      </data-tables>
    </el-row>

    <!-- <action-buttons cancel @submit="updatePlayerPoints()" @cancel="cancelForm()" /> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      tableData: [],
      tableProps: {
        border: true,
      },
      allProgramTypeSettings: [],
      progType: undefined,
      requestedProgramType: [],
    };
  },
  mounted() {
    const entityId = this.$store.getters["user/activeRole"].entity._id;

    try {
      const url = "/nrl/api/v1/admin/programs-settings";
      this.$http
        .get(url)
        .then((response) => {
          this.$store.commit("root/loading", true);
          if (response && response.data && response.data.data) {
            const visbilityData = response.data.data;
            this.allProgramTypeSettings = visbilityData.map((v) => {
              return { _id: v._id, name: v.type, type: v.type };
            });
          }
          this.$http
            .get(`/nrl/api/v1/admin/requested-programs-settings/${entityId}`)
            .then((res) => {
              this.requestedProgramType = res.data.data;
              this.requestedProgramType.sort((a, b) => b.createdAt - a.createdAt);
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(get(error, "response.data.message"));
            });
        })
        .catch((error) => {
          this.$store.commit("root/loading", false);
          this.$customError(get(error, "response.data.message"));
        });
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    requestprogramTypeSetting() {
      const entityId = this.$store.getters["user/activeRole"].entity._id;

      const selectedProgramSetting = this.allProgramTypeSettings.find(
        (p) => p._id === this.progType
      );
      this.$store.commit("root/loading", true);
      this.$http
        .post(`/nrl/api/v1/admin/programs-type-settings/request/${entityId}`, {
          programTypeId: selectedProgramSetting._id,
          type: selectedProgramSetting.type,
          name: selectedProgramSetting.name,
        })
        .then((response) => {
          this.requestedProgramType = response.data.data;
          this.requestedProgramType.sort((a, b) => b.createdAt - a.createdAt);
          this.$store.commit("root/loading", false);

          this.$customSuccess();
        })
        .catch((error) => {
          this.$store.commit("root/loading", false);
          this.$customError(get(error, "response.data.message"));
        });
    },
    getFormattedDate(row) {
      return this.moment(row.createdAt).format("DD-MM-YYYY");
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.display-form {
  margin-top: 0.45rem;
}

.el-input,
.el-select {
  width: 100%;
}
</style>
