import Vue from "vue";
import Router from "vue-router";
import moment from "moment";
import routes from "./routes";
import httpnoauth from "./axios/httpnoauth";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   // Clean notifications on navigation
//   store.commit("views/CLEAR_NOTIFICATION", to.name);

//   const permission = hasPermission(to);
//   const redirect = regoRedirect(to);

//   if (process.env.NODE_ENV === "development" && to.query.debug) {
//     // Allow debug mode that skip any redirect
//     console.log("debugging mode on");
//     next();
//   } else if (permission) {
//     if (redirect) {
//       next(redirect);
//       return;
//     }
//     // check if debugging
//     const maintenanceCleared = store.getters["user/maintenanceCleared"];
//     const maintenanceBegin = store.getters["root/maintenanceBegin"];
//     const maintenanceEnd = store.getters["root/maintenanceEnd"];
//     const now = Date.now();
//     if ((now < maintenanceBegin || now > maintenanceEnd) && to.path == "/maintenance") {
//       next("/");
//     }
//     if (
//       now > maintenanceBegin &&
//       now < maintenanceEnd &&
//       !maintenanceCleared &&
//       to.path !== "/maintenance"
//     ) {
//       next("/maintenance");
//     }
//     next();
//   } else {
//     store.dispatch("views/updateLoginRedirect", to ? to.fullPath : undefined);
//     next("/login");
//   }
// });

// Pre Route Hook -- AUTH Check
router.beforeEach((to, from, next) => {
  store.commit("app/setPath", from.fullPath);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const now = moment().valueOf();
    const expiry = store.getters["user/expiry"];
    const userid = store.getters["user/userid"];
    const isAuthenticated = store.getters["user/isAuthenticated"];
    const refreshToken = store.getters["user/refreshToken"];
    const verified = store.getters["user/isVerified"];
    const roles = store.getters["user/roles"];
    const roleType = store.getters["user/activeRole"].type;
    if (roleType === 50) {
      store.commit("user/logout");
      router.go("/login");
    }

    if (isAuthenticated && expiry - 300 > now) {
      // Valid Token
      if (!verified) {
        // Account not verified, verification code
        store.commit("root/loading", true);
        next("/verify");
      } else if (roles.length <= 0) {
        // Account verified, but no roles
        next("/pending");
      } else {
        // Account Verified
        store.commit("root/loading", false);
        next();
      }
    } else if (expiry - 300 <= now && refreshToken && userid) {
      // Expired Token
      store.commit("root/loading", true);
      // Refresh Token before going to page
      httpnoauth
        .post("/nrl/api/v1/admin/authorize", {
          refresh_token: refreshToken,
          user_id: userid
        })
        .then(response => {
          // Refresh Successful - Login User
          store.commit("user/login", response.data);
          store.commit("root/loading", false);
          next();
        })
        .catch(() => {
          // Failed Refresh - Logout User

          store.commit("user/setRedirectUriPath", `${to.fullPath}`);
          store.commit("user/logout");
          store.commit("root/loading", false);
          router.go("/login");
        });
    } else {
      // No Data - Redirect to Login

      store.commit("user/setRedirectUriPath", `${to.fullPath}`);
      store.commit("user/logout");
      store.commit("root/loading", false);
      next("/login");
    }
  } else {
    const maintenanceCleared = store.getters["user/maintenanceCleared"];
    const maintenanceBegin = store.getters["root/maintenanceBegin"];
    const maintenanceEnd = store.getters["root/maintenanceEnd"];
    const now = Date.now();

    if ((now < maintenanceBegin || now > maintenanceEnd) && to.path == "/maintenance") {
      next("/");
    }
    if (
      now > maintenanceBegin &&
      now < maintenanceEnd &&
      !maintenanceCleared &&
      to.path !== "/maintenance"
    ) {
      next("/maintenance");
    }
    next();
  }
});

// Adapted from vue-gtm, custom methods + fields
router.afterEach(to => {
  const { dataLayer } = window;
  const event = "v_page_view";
  const page_name = to.name;
  const nrl_id = store.getters["user/userid"];
  const page_path = to.fullPath;

  dataLayer.push({
    event,
    page_path,
    page_name,
    nrl_id
  });
});

export default router;
