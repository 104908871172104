/* eslint-disable no-return-assign */
import _ from 'lodash';

const initState = () => ({
  states: {
    search: '',
    filters: {
      isActive: []
    },
    data: [],
  },
  regions: {
    search: '',
    filters: {},
    data: [],
  },
  associations: {
    search: '',
    filters: {},
    data: [],
  },
  clubs: {
    criteria: '',
    filters: {},
    params: {},
    data: [],
    pagination: {
      total: 0,
      pageSize: 0,
      page: 0
    }
  },
  teams: {
    criteria: '',
    filters: {
      isActive: undefined
    },
    data: [],
    pagination: {
      total: 0,
      pageSize: 20,
      page: 1
    }
  },
  gamedevregions: {
    search: '',
    filters: {},
    data: [],
  },
  venues: {
    criteria: '',
    filters: {},
    data: [],
    pagination: {
      total: 0,
      pageSize: 0,
      page: 0
    }
  },
  squadManagement: {
    criteria: '',
    filters: {
      isActive: undefined
    },
    data: [],
    pagination: {
      total: 0,
      pageSize: 20,
      page: 1
    }
  }
});

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateSearch: (state, payload) => state[payload.entity].search = payload.search,
    updateCriteria: (state, payload) => state[payload.entity].criteria = payload.criteria,
    updateFilters: (state, payload) => state[payload.entity].filters = payload.filters,
    updateParam: (state, payload) => state[payload.entity].param = payload.param,
    updateData: (state, payload) => state[payload.entity].data = payload.data,
    updatePagination: (state, payload) => _.assign(state[payload.entity].pagination, payload.data),
    updateDataMember: (state, payload) => {
      if (state[payload.entity].data.length) {
        const index = _.findIndex(state[payload.entity].data, x => x._id === payload.data._id);
        _.merge(state[payload.entity].data[index], payload.data);
      }
    }
  }
};
