<template>
  <div>
    <el-form>
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="10">
          <el-form-item label="Can Clubs use this Program type ?">
            <el-col>
              <div v-for="{ type } in visibilityOptions" :key="type">
                <el-radio :label="type" v-model="clubVisibility" fill="#098255">{{
                  type
                }}</el-radio>
              </div>
            </el-col>
            <el-col :span="15">
              <div v-for="{ type, option } in activeClubs" :key="option">
                <el-radio
                  :label="type"
                  v-if="clubVisibility === 'Yes'"
                  v-model="activeClub"
                  fill="#098255"
                  >{{ type }}</el-radio
                >
              </div>
              <el-select
                :span="10"
                v-model="name"
                placeholder="Which Clubs ?"
                filterable
                v-if="clubVisibility === 'Yes' && activeClub === 'Select Club'"
              >
                <el-option
                  v-for="row in clubList"
                  :label="row.name"
                  :key="row._id"
                  :value="row._id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col class="submit-action" v-if="clubVisibility === 'Yes'">
          <el-button @click="addClubs" type="success">Add</el-button>
        </el-col>
      </el-row>
    </el-form>
    <data-tables
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      class="data-table"
    >
      <el-table-column prop="_id" label="ID" width="auto"> </el-table-column>
      <el-table-column prop="name" label="Name" width="auto"> </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="auto"
      >
      </el-table-column>
      <el-table-column label="Action" width="200">
        <template slot-scope="scope">
          <el-button @click="handleRemoveRowClub(scope.row._id)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
export default {
  name: "SetClubVisibility",
  props: ["program"],
  data() {
    return {
      clubVisibility: "No",
      visibilityOptions: [
        {
          type: "No",
        },
        {
          type: "Yes",
        },
      ],
      activeClub: "",
      activeClubs: [
        { option: "Y", type: "All Active Club" },
        { option: "N", type: "Select Club" },
      ],
      allActiveClubs: [],
      clubList: [],
      tableData: [],
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      name: "",
      programSettings: {},
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http
      .get("/nrl/api/v1/admin/clubs")
      .then((response) => {
        this.clubList = response.data.data;
        this.allActiveClubs = this.clubList.map((c) => parseInt(c._id));
        this.programSettings = this.program;
        this.filterClubsData(this.clubList);
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$customError("Could not generate list for Clubs");
        this.$store.commit("root/loading", false);
      });
  },
  methods: {
    modifiedFormatter(row) {
      return row.modifiedAt ? this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    filterClubsData(data) {
      this.tableData = data.filter((row) => {
        return (
          this.programSettings.visibleToClubs &&
          this.programSettings.visibleToClubs.includes(row._id)
        );
      });
    },
    addClubs() {
      let visibleToClubs;
      const { _id } = this.programSettings;
      if (this.programSettings.visibleToClubs) {
        visibleToClubs = this.programSettings.visibleToClubs;
        if (this.activeClub === "All Active Club") {
          visibleToClubs = [...visibleToClubs, ...this.allActiveClubs];
        } else visibleToClubs.push(this.name);
      } else {
        if (this.activeClub === "All Active Club") {
          visibleToClubs = [...this.allActiveClubs];
        } else visibleToClubs = [this.name];
      }
      visibleToClubs = [...new Set([...visibleToClubs])];
      this.updateClubs(visibleToClubs, _id);
      this.name = "";
    },
    handleRemoveRowClub(id) {
      const indexToDelete = this.tableData.findIndex((c) => c._id === id);
      this.tableData.splice(indexToDelete, 1);
      let visibleToClubs = this.tableData.map((club) => club._id);
      const { _id } = this.programSettings;
      this.updateClubs(visibleToClubs, _id);
      this.updateClubOrgtree(id, _id);
    },
    updateClubOrgtree(clubId, programSettingId) {
      this.$store.commit("root/loading", true);
      const clubToBeRemoved = this.clubList.find((c) => c._id === clubId);

      if (
        clubToBeRemoved &&
        clubToBeRemoved.orgtreeV2Read &&
        clubToBeRemoved.orgtreeV2Read.programsSettings &&
        clubToBeRemoved.orgtreeV2Write &&
        clubToBeRemoved.orgtreeV2Write.programsSettings
      ) {
        clubToBeRemoved.orgtreeV2Read.programsSettings =
          clubToBeRemoved.orgtreeV2Read.programsSettings.filter((id) => id !== programSettingId);
        clubToBeRemoved.orgtreeV2Write.programsSettings =
          clubToBeRemoved.orgtreeV2Write.programsSettings.filter((id) => id !== programSettingId);
      }
      const url = `/nrl/api/v1/admin/clubs/${clubId}`;
      this.$http
        .put(url, {
          orgtreeV2Read: clubToBeRemoved.orgtreeV2Read,
          orgtreeV2Write: clubToBeRemoved.orgtreeV2Write,
        })
        .then((response) => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError("Could not update visibility Tab for Clubs");
        });
    },
    updateClubs(visibleToClubs, _id) {
      this.$store.commit("root/loading", true);
      const url = `/nrl/api/v1/admin/programs-settings/${_id}`;
      this.$http
        .put(url, { visibleToClubs })
        .then((response) => {
          this.programSettings = response.data.data;
          this.filterClubsData(this.clubList);
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError("Could not update visibility Tab for Clubs");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 2.5rem !important;
}
</style>
