<template>
  <div>
    <div class="tw-flex tw-text-sm search-bar">
      <div class="tw-w-1/4 tw-mr-3">
        <input
          class="tw-box-border tw-inline-block tw-w-full tw-h-10 tw-px-4 tw-font-normal tw-leading-10 tw-text-gray-600 tw-placeholder-gray-600 tw-transition tw-bg-white tw-border"
          autocomplete="off"
          @keyup="debouncedInput"
          v-model="search"
          placeholder="Search..."
        />
      </div>
      <div>
        <button
          class="tw-box-border tw-flex tw-items-center tw-h-10 tw-py-3 tw-font-medium tw-tracking-widest tw-text-gray-900 tw-uppercase tw-transition tw-bg-white tw-border-2 tw-border-gray-500 tw-rounded hover:tw-bg-gray-200 hover:tw-border-gray-200 tw-flex-nowrap tw-px-7"
          @click="filterVisible = true"
        >
          Filters
        </button>
      </div>
    </div>

    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <FilterPage18MRP @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>

    <data-tables-server
      :data="tableData"
      :filters="filters"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      :total="total"
      :current-page.sync="currentPage"
      @query-change="queryChange"
      @row-click="handleRowClick"
      class="data-table"
    >
      <el-table-column
        prop="dispType"
        width="200"
        fixed
        label="Dispensation Category"
      ></el-table-column>
      <el-table-column
        prop="dispDisp"
        :formatter="cellValueRenderer"
        width="170"
        fixed
        label="Dispensation Outcome"
      ></el-table-column>
      <!-- <el-table-column width="150" fixed label="Dispensation Date">
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.member &&
                scope.row.member.dispensations &&
                scope.row.member.dispensations.date
            "
            >{{ formatDispensatedDate(scope.row.member.dispensations.date) }}</span
          ><span v-else>{{ "" }}</span></template
        ></el-table-column
      > -->
      <el-table-column width="150" fixed label="Dispensation Date">
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.member &&
              scope.row.dispensations &&
              scope.row.dispDate &&
              scope.row.dispensations.state !== 'pending'
            "
            >{{ formatDispensatedDate(scope.row.dispDate) }}</span
          ><span v-else>{{ "" }}</span></template
        ></el-table-column
      >
      <el-table-column
        width="150"
        :formatter="getFormattedDate"
        fixed
        prop="createdAt"
        label="Registration Date"
      ></el-table-column>
      <el-table-column fixed prop="memberId" width="100" label="NRL ID"></el-table-column>
      <el-table-column
        prop="member.profile.firstName"
        width="200"
        label="First Name"
      ></el-table-column>
      <el-table-column
        prop="member.profile.lastName"
        width="200"
        label="Last Name"
      ></el-table-column>
      <el-table-column
        :formatter="dateFormatter"
        prop="member.profile.dob"
        width="100"
        label="DOB"
      ></el-table-column>
      <el-table-column prop="member.profile.gender" width="100" label="Gender"></el-table-column>
      <el-table-column
        prop="member.profile.meta.height"
        width="100"
        label="Height"
      ></el-table-column>
      <el-table-column width="100" prop="member.profile.meta.weight" label="Weight">
      </el-table-column>
      <el-table-column prop="club.name" width="300" label="Club Name"></el-table-column>
      <el-table-column
        prop="meta.applicationReason"
        width="300"
        label="18-Month Reason"
      ></el-table-column>
      <el-table-column
        prop="meta.applicationInformation"
        width="300"
        label="18-Month Notes"
      ></el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import _ from "lodash";
import FilterPage18MRP from "./FilterPage18MRP.vue";
import { currentSeason } from "../../utils/constants";

export default {
  name: "EighteenMRP",
  components: { FilterPage18MRP },
  data() {
    const {
      criteria,
      filters: { status, season },
      pagination: { pageSize, currentPage, total },
    } = this.$store.state.eighteenmrp.eighteenmrp;
    return {
      tableProps: {
        border: true,
      },
      filterVisible: false,
      search: criteria,
      filterSelected: {
        status,
        season,
      },
      filters: [{ value: criteria }],
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      pageSize: 20,
      currentPage: 1,
      total: 0,
      tableData: [],
    };
  },
  mounted() {},
  methods: {
    queryChange(queryInfo) {
      this.pageSize = queryInfo.pageSize;
      this.currentPage = queryInfo.page;
      this.sendQuery();
    },
    debouncedInput: _.debounce(function debouncedInput() {
      if (this.search.length >= 3 || this.search.length === 0) {
        this.filters[0].value = this.search;
      }
    }, 500),
    getFormattedDate(row) {
      return this.moment(row.createdAt).format("DD-MM-YYYY");
    },

    dateFormatter(row) {
      if (row.member && row.member.profile && row.member.profile.dob) {
        return row.member.profile.dob
          ? this.moment(row.member.profile.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
          : "NA";
      }
    },
    formatDispensatedDate(date) {
      return this.moment(date).format("DD-MM-YYYY");
    },
    cellValueRenderer(row, column, cellValue) {
      let outcome = "";
      const dispStatus =
        row.dispensations && row.dispensations.state ? row.dispensations.state : undefined;
      if (dispStatus) {
        const finalStatus = { pending: "Pending", approved: "Approved", denied: "Denied" };
        return finalStatus[dispStatus] || "";
      } else if (cellValue === "") {
        return outcome;
      } else if (cellValue) {
        outcome = "Approved";
      } else {
        outcome = "Denied";
      }
      return outcome;
    },

    handleRowClick(row) {
      const member = this.tableData.find((user) => user.memberId === row.memberId);
      this.$router.push({
        name: "members.update",
        params: {
          type: "update",
          id: row.memberId,
        },
      });
    },
    sendFilter(event) {
      const { status: statusArray, season: year } = event;
      const status = statusArray && statusArray.length > 0 ? statusArray[0] : null;
      const season = year ? year : currentSeason;
      const params = {
        status,
        season,
      };
      this.$store.commit("eighteenmrp/updateEighteenMRPParams", params);

      this.$store.commit("eighteenmrp/updateEighteenMRPFilters", params);
      this.filterSelected.status = status;
      this.filterSelected.season = season;

      this.sendQuery();
      this.filterVisible = false;
    },
    getEighteenMRPData() {
      this.$http
        .get(`/nrl/api/v1/admin/eighteenMRP/data-table/${this.pageSize}/${this.currentPage}`, {
          params: {
            search: this.filters[0].value,
            statusFilter: this.filterSelected.status,
            seasonFilter: this.filterSelected.season,
          },
        })
        .then((response) => {
          this.tableData = response.data.data;

          this.total = response.data.total;
          this.$store.commit("eighteenmrp/updateEighteenMRP", {
            criteria: this.search,
            data: this.tableData,
            pagination: {
              pageSize: this.pageSize,
              page: this.currentPage,
              total: this.total,
            },
          });
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    sendQuery() {
      this.$store.commit("root/loading", true);

      if (!this.filterSelected.season) {
        this.$http
          .get("/nrl/api/v1/admin/seasons/current")
          .then((seasonRes) => {
            this.filterSelected.season = seasonRes.data.data.season;
            this.getEighteenMRPData();
          })
          .catch(() => {
            this.$customError();
          });
      } else {
        this.getEighteenMRPData();
      }
    },
  },

  watch: {
    tableData(data) {
      this.$store.commit("eighteenmrp/updateData", data);
      this.$store.commit("eighteenmrp/updatePagination", {
        data: _.pick(this, ["pageSize", "currentPage", "total"]),
      });
    },
  },
};
</script>

<style scoped></style>
