<template>
  <div>
    <el-tabs type="card">
      <el-tab-pane label="Region Details">
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="6" class="avatar-box entity-card">
            <img
              v-if="currentLogo"
              :src="currentLogo"
              @error="displayPlaceholder"
              alt=""
              class="avatar"
            />
            <img v-else src="~@/assets/shield_black_small.svg" alt="" class="avatar" />
            <vue-upload-component
              v-if="this.$route.params.type !== 'insert'"
              ref="upload"
              :drop="true"
              v-model="files"
              :extensions="extensions"
              :accept="accept"
              :post-action="uploadURL"
              :headers="uploadHeaders"
              :data="entityData"
              :multiple="true"
              @input-file="inputFile"
              @input-filter="inputFilter"
              class="el-button el-button--default upload"
            >
              Upload
            </vue-upload-component>
          </el-col>
          <el-col :span="18">
            <el-form
              label-position="top"
              :model="region"
              :rules="rules"
              ref="region"
              class="login-form"
            >
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="state" label="State">
                    <el-select
                      v-model="state"
                      placeholder="Select a state"
                      filterable
                      :disabled="type === 'update'"
                    >
                      <el-option
                        v-for="item in states"
                        :label="item.name"
                        :key="item._id"
                        :value="item._id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="name" label="Name">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="regionName"
                      placeholder="Enter a name"
                      :disabled="clearance !== roles.superAdmin"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.abbreviation" label="Abbreviation">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="region.meta.abbreviation"
                      placeholder="Enter an abbreviation"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.facebook" label="Facebook Link">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="region.meta.facebook"
                      placeholder=""
                    >
                      <template slot="prepend">https://facebook.com/</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contact.name" label="Contact Name">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="region.contact.name"
                      placeholder="Enter a contact name"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contact.number" label="Contact Number">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="region.contact.number"
                      placeholder="Enter a contact number"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contact.email" label="Contact Email">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="region.contact.email"
                      placeholder="Enter a contact email"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="isActive" label="Active">
                    <el-select v-model="region.isActive">
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.gstRegistered" label="GST Registered">
                    <el-select
                      v-model="region.meta.gstRegistered"
                      :disabled="
                        ![roles.regionAdmin, roles.stateAdmin, roles.superAdmin].includes(clearance)
                      "
                    >
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.abn" label="ABN">
                    <el-select
                      v-model="region.meta.abn"
                      :disabled="
                        ![roles.regionAdmin, roles.stateAdmin, roles.superAdmin].includes(clearance)
                      "
                      filterable
                      remote
                      placeholder="Search by name"
                      :loading="abnLoading"
                      :remote-method="searchAbn"
                    >
                      <el-option
                        v-for="item in abnOptions"
                        :key="item.value"
                        :label="`${item.label} (${item.value})`"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.website" label="Website">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="region.meta.website"
                      placeholder="Enter a website"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>
            </el-form>
            <action-buttons
              submit
              :cancel="!isMyEntity"
              @submit="submitForm('region')"
              @cancel="cancelForm()"
            />
          </el-col>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="Clearance Auto Approvals"
        v-if="
          clearance === roles.regionAdmin &&
          this.$store.getters['user/activeRole'].national_id === 32
        "
      >
        <auto-approvals :entity="region" key="clearance" type="clearance" />
      </el-tab-pane>
      <el-tab-pane
        label="Permit Auto Approvals"
        v-if="
          clearance === roles.regionAdmin &&
          this.$store.getters['user/activeRole'].national_id === 32
        "
      >
        <auto-approvals :entity="region" key="permit" type="permit" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, uploadURL, roles } from "../../utils/constants";
import { validateEmail, validatePhoneNumber } from "../../utils/utilities";

import AutoApprovals from "../auto-approvals/AutoApprovals.vue";

export default {
  name: "RegionForm",
  props: {},
  components: {
    ActionButtons,
    AutoApprovals,
  },
  data() {
    const validateState = (rule, value, callback) => {
      const { region } = this;
      if (!region || !region.orgtree.state._id || region.orgtree.state._id === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    const validateContactEmail = (rule, value, callback) => {
      const { contact } = this.region;
      if (!contact || !contact.email || !validateEmail(contact.email)) {
        return callback(new Error(errormsg.email));
      }
      return callback();
    };

    const validateContactNumber = (rule, value, callback) => {
      const { contact } = this.region;
      if (!contact || !contact.number || !validatePhoneNumber(contact.number)) {
        return callback(new Error(errormsg.phone));
      }
      return callback();
    };

    const validateWebsite = (rule, val, callback) => {
      const { meta } = this.region;
      const expression =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      const regex = new RegExp(expression);

      if (meta && meta.website) {
        if (meta.website.length && !meta.website.match(regex)) {
          return callback(new Error(errormsg.invalidUrl));
        }
      }
      return callback();
    };
    return {
      type: "insert",
      nameUpdated: false,
      states: [],
      files: [],
      abnOptions: [],
      abnLoading: false,
      region: {
        name: "",
        orgtree: {
          state: {
            _id: "",
            name: "",
            type: "",
          },
        },
        contact: {
          name: "",
          number: "",
          email: "",
        },
        meta: {
          abbreviation: "",
          avatar: "",
          facebook: "",
          gstRegistered: false,
          abn: "",
          website: "",
        },
      },
      rules: {
        state: [
          {
            type: "object",
            required: true,
            validator: validateState,
            message: errormsg.select_option,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: errormsg.input_option,
            trigger: "blur",
          },
        ],
        "meta.abbreviation": {
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.gstRegistered": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.abn": {
          message: errormsg.select_option,
          trigger: "blur",
        },
        "contact.name": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "contact.number": {
          required: true,
          message: errormsg.phone,
          trigger: "blur",
          validator: validateContactNumber,
        },
        "contact.email": {
          required: true,
          message: errormsg.email,
          trigger: "blur",
          validator: validateContactEmail,
        },
        isActive: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.website": {
          validator: validateWebsite,
          trigger: "blur",
        },
      },
      roles,
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png",
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, region, id } = this.$route.params;
    this.type = type || (id !== "add" ? "update" : "insert");
    // Load Region and States
    if (id && id !== "" && id !== "add" && !region) {
      this.$http
        .get(`/nrl/api/v1/admin/regions/${id}`)
        .then((regionRes) => {
          this.region = Object.assign({}, this.region, regionRes.data.data);
          if (this.region.gstRegistered !== true)
            this.region.meta = Object.assign({}, this.region.meta, { gstRegistered: false });

          if (!this.isMyEntity) {
            this.$http
              .get("/nrl/api/v1/admin/states/")
              .then((stateRes) => {
                this.states = stateRes.data.data;
                this.$store.commit("root/loading", false);
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          } else {
            this.states = [this.region.orgtree.state];
            this.$store.commit("root/loading", false);
          }
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    } else {
      // Load States
      this.$http
        .get("/nrl/api/v1/admin/states/")
        .then((response) => {
          this.states = response.data.data;
          if (region) this.region = Object.assign({}, this.region, region);
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }
  },
  methods: {
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response.status === "success") {
          this.$store.commit("root/loading", false);
          this.region.meta.avatar = newFile.response.url;
          if (this.type === "update") {
            const { avatar } = this.region.meta;
            const { _id } = this.region;
            this.$store.commit("root/loading", true);
            const update = { meta: { avatar } };
            const url = `/nrl/api/v1/admin/regions/${_id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        } else {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }
      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const currentRegion = this.region;
          if (this.type === "update") {
            this.$store.commit("root/loading", true);
            const update = {
              ...currentRegion,
              orgtree: currentRegion.orgtree,
            };

            if (this.nameUpdated) update.name = currentRegion.name;
            const url = `/nrl/api/v1/admin/regions/${currentRegion._id}`;

            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                this.$customSuccess();
                // update data member in store
                this.$store.commit("entities/updateDataMember", {
                  entity: "regions",
                  data: update,
                });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            const insert = currentRegion;
            const url = "/nrl/api/v1/admin/regions/";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$store.commit("root/loading", false);
                this.$router.push({
                  name: "regions",
                });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
          return true;
        }
        return false;
      });
    },
    cancelForm() {
      this.$router.push({
        name: "regions",
      });
    },
    searchAbn(query) {
      if (query !== "") {
        this.abnLoading = true;
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/abr-proxy/name", { name: query, maxResult: 10 })
            .then((response) => {
              this.abnLoading = false;
              if (response.data.data.length > 0) {
                this.abnOptions = response.data.data.map((o) => ({ label: o.text, value: o.abn }));
              }
            })
            .catch(() => {
              this.$customError();
            });
        }, 200);
      }
    },
  },
  computed: {
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    entityData() {
      return {
        entity_type: "region",
        entity_id: this.region._id,
      };
    },
    uploadURL() {
      return uploadURL;
    },
    currentLogo() {
      return this.region.meta && this.region.meta.avatar ? this.region.meta.avatar : false;
    },
    regionName: {
      get() {
        return this.region.name;
      },
      set(value) {
        this.nameUpdated = true;
        this.region.name = value;
      },
    },
    state: {
      get() {
        return this.region.orgtree.state._id;
      },
      set(stateid) {
        const foundState = this.states.find((obj) => obj._id === stateid);
        if (foundState) {
          const { orgtree, _id, name, type } = foundState;
          orgtree.state = { _id, name, type };
          this.region.orgtree = orgtree;
        }
      },
    },
    isMyEntity() {
      return this.$route.path.split("/").includes("my");
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.entity-card {
  height: 36rem;
}

.avatar-box {
  overflow: hidden;
  .avatar {
    margin: 0 auto;
    height: 12.5rem;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .avatar-entity-name {
    text-align: center;
    display: block;
    font-size: 1.05rem;
    font-style: oblique;
    margin-bottom: 1rem;
  }
  .upload {
    margin: 0 auto;
    display: block;
    max-width: 70%;
  }
}

.operation {
  el-button {
    width: 100%;
    height: 100%;
  }
}
</style>
