<template>
  <div>
    <el-table
      v-if="member.accreditations && member.accreditations.length"
      size="small"
      :data="member.accreditations"
      :border="true">
      <el-table-column
        prop="skillId"
        label="Accreditation Id"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="skillName"
        label="Name"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="skillDescription"
        label="Type"
        width="auto">
      </el-table-column>
      <el-table-column
        :formatter="achievedValueFormatter"
        prop="achievedValue"
        label="Status"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="expiry"
        label="Expiry"
        width="auto">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import _ from 'lodash';
import { EventBus } from '../../bus';

export default {
  mounted() {
    EventBus.$on('memberUpdated', () => {
      this.$forceUpdate();
    });
  },
  props: {
    member: { type: Object, required: true }
  },
  computed: {
  },
  methods: {
    achievedValueFormatter(row) {
      if (row.achievedValue !== undefined) {
        switch (true) {
          case _.inRange(row.achievedValue, 0, 1): return 'Expired';
          case _.inRange(row.achievedValue, 50, 51): return 'In Training';
          case _.inRange(row.achievedValue, 1, 50):
          case _.inRange(row.achievedValue, 51, 101): return 'Complete';
          default: return 'N/A';
        }
      }
      return 'N/A';
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
