<template>
  <div>
    <option id="message"> {{ message }}</option>
  </div>
</template>
<script>
export default {
  beforeMount() {
    this.fetchData();
  },
  data() {
    return {
      message: "waiting for api"
    };
  },
  components: {},
  mounted() {
    //  this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.$store.commit("root/loading", true);
        const response = await this.$http.get(`/nrl/api/v1/healthchecks/1`, {
          params: {}
        });
        this.message = response?.data?.message;
        this.$store.commit("root/loading", false);
      } catch (e) {
        this.$store.commit("root/loading", false);
        this.message = "error";
        this.$customError();
      }
    }
  },
  computed: {}
};
</script>
<style lang="scss" scoped></style>
