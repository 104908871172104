<template>
  <div>
    <el-button
      icon="el-icon-plus"
      type="success"
      v-if="this.$store.getters['user/activeRole'].type === 1"
      @click="handleAddProgramType"
    >
      Add Program Type Setting
    </el-button>
     <!-- Search Program By Name -->
    <el-row class="search-content" v-if = "isSuperAdmin">
      <el-col :span="12">
        <el-col :span="18">
        <el-input autocomplete="off" v-model="filters.criteria" placeholder="Search..."> </el-input>
        </el-col>
        <el-button icon="el-icon-search" type="success" @click="fetchData" class="search-button"/>
      </el-col>
      <el-col :span="12"  class="tw-ml-auto tw-mr-0">
            <el-radio-group v-model="filters.progStatusArr" fill="#098255" @change="fetchData" class="tw-float-right">
              <el-radio-button label="All" key="all"></el-radio-button>
              <el-radio-button label="Active" key="active"></el-radio-button>
              <el-radio-button label="Inactive" key="inactive"></el-radio-button>
            </el-radio-group>
      </el-col>
    </el-row>
    <data-tables
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table"
    >
      <el-table-column prop="type" label="Type" width="auto"> </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="auto"
      >
      </el-table-column>
      <el-table-column
        :formatter="statusFormatter"
        prop="isActive"
        label="Active Status"
        width="auto"
      >
      </el-table-column>
      <el-table-column
        :formatter="contactFormatter"
        prop="isContact"
        label="Contact Status"
        width="auto"
      >
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { roles } from "../../utils/constants";
export default {
  name: "ProgramsettingsList",
  props: {},
  data() {
    return {
      loading: true,
      tableData: [],
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      filters: {
        criteria: "",
        progStatusArr: 'All',
      },
    };
  },
  mounted() {
   this.fetchData();
  },
  methods: {
    fetchData(){
      const { criteria: name, progStatusArr } = this.filters;
      let isActive;
      if (progStatusArr === "Active") {
          isActive = true;
      }
      if(progStatusArr === "Inactive") {
          isActive = false;
      }

      this.$http
      .get("/nrl/api/v1/admin/programs-settings", {
          params: { name, isActive },
        })
      .then((response) => {
        this.tableData = response.data.data;
        this.loading = false;
      })
      .catch(() => {
        this.$customError();
      });
    },

    handleAddProgramType() {
      this.$router.push({
        name: "programsetting.add",
        params: {
          type: "insert",
        },
      });
    },
    handleRowClick(row) {
      this.$router.push({
        name: "programsetting.update",
        params: {
          type: "update",
          program: row,
          id: row._id,
        },
      });
    },
    modifiedFormatter(row) {
      return row.modifiedAt ? this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    statusFormatter(row) {
      return row.isActive === true || row.isActive == undefined ? "Active" : "Inactive";
    },
    contactFormatter(row) {
      return row.isContact === false || row.isContact == undefined ? "Non-contact" : "Contact";
    },
  },
  computed: {
    isSuperAdmin() {
      return (
        this.$store.getters["user/activeRole"].type === roles.superAdmin
      );
    },
  }
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
}
.el-pagination {
  margin-top: 1rem !important;
}
.search-button{
  margin-left: 20px;
}
.search-content {
  margin-top: 25px;
}
</style>
