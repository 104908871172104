/* eslint-disable no-return-assign */
import _ from 'lodash';

const initState = () => ({
  search: {
    type: ''
  },
  data: [],
  graphqlData: [],
  filters: {
    regoFinancial: undefined
  },
  graphqlSearch: {}
});

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateSearch: (state, payload) => {
      _.assign(state.search, payload);
      state.filters = {};
    },
    updateGraphqlSearch: (state, payload) => {
      _.assign(state.graphqlSearch, payload);
      state.filters = {};
    },
    updateFilters: (state, payload) => {
      _.assign(state.filters, payload);
      state.search = {};
    },
    updateData: (state, data) => state.data = data,
    updateGraphqlData: (state, data) => state.graphqlData = data,
    updateMember: (state, member) => {
      if (state.data.length) {
        const index = _.findIndex(state.data, x => x._id === member._id);
        _.merge(state.data[index], member);
      }
    }
  }
};
