<template>
  <div class="container" v-if="currentMember && currentMember.profile">
    <div v-if="!currentMember.activeRegos">
      <el-alert
        title="New member, no active registrations."
        type="info"
        show-icon
      >
    </el-alert>
    </div>
    <el-form>
      <el-form-item>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="_id" label="NRL ID">
              <el-input
                autocomplete="off"
                placeholder=""
                v-model="currentMember._id"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.firstName" label="First Name">
              <el-input
                autocomplete="off"
                placeholder=""
                v-model="currentMember.profile.firstName"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.middleName" label="Middle Name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.middleName"
                placeholder=""
                disabled
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.lastName" label="Last Name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.lastName"
                placeholder=""
                disabled
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          v-if="currentMember.profile.meta"
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.meta.preferredName" label="Preferred Name">
              <el-input
                autocomplete="off"
                placeholder=""
                v-model="currentMember.profile.meta.preferredName"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </div>
        <div
          v-if="currentMember.profile.meta"
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.meta.maidenName" label="Maiden Name">
              <el-input
                autocomplete="off"
                placeholder=""
                v-model="currentMember.profile.meta.maidenName"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.mobile" label="Mobile Phone">
              <el-input
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.mobile"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.dob" label="Date of Birth">
              <el-input
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.dob"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.gender" label="Gender">
              <el-input
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.gender"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.email" label="Email">
              <el-input
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.email"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.address.addressLine1" label="Address Line 1">
              <el-input
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.address.addressLine1"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.address.addressLine2" label="Address Line 2">
              <el-input
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.address.addressLine2"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.address.suburb" label="Suburb">
              <el-input
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.address.suburb"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.address.postcode" label="Postcode">
              <el-input
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.address.postcode"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.address.state" label="State">
              <el-input
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.address.state"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </div>
        <div
          v-if="currentMember.profile.meta"
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.meta.parentOrGuardian1.name" label="Parent Name">
              <el-input
                v-if="$data._.get(currentMember, 'profile.meta.parentOrGuardian1', false)"
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.meta.parentOrGuardian1.name"
                placeholder=""
              />
              <el-input disabled type="text" v-else></el-input>
            </el-form-item>
          </el-col>
        </div>
        <div
          v-if="currentMember.profile.meta"
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.meta.parentOrGuardian1.email" label="Parent Email">
              <el-input
                v-if="$data._.get(currentMember, 'profile.meta.parentOrGuardian1', false)"
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.meta.parentOrGuardian1.email"
                placeholder=""
              />
              <el-input disabled type="text" v-else></el-input>
            </el-form-item>
          </el-col>
        </div>
        <div
          v-if="currentMember.profile.meta"
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="24">
            <el-form-item prop="profile.meta.parentOrGuardian1.mobile" label="Parent Mobile">
              <el-input
                v-if="$data._.get(currentMember, 'profile.meta.parentOrGuardian1', false)"
                disabled
                type="text"
                autocomplete="off"
                v-model="currentMember.profile.meta.parentOrGuardian1.mobile"
                placeholder=""
              />
              <el-input disabled type="text" v-else></el-input>
            </el-form-item>
          </el-col>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    memberId: { type: Number, required: true }
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _,
      currentMember: false,
      loading: false
    };
  },
  mounted() {
    this.loading = true;
    this.$http
      .get(`/nrl/api/v1/admin/members/${this.memberId}`)
      .then(response => {
        this.currentMember = response.data.data;
        this.loading = false;
      })
      .catch(error => {
        this.$customError(
          "This duplicate may have already been merged. Please double check for resolved records."
        );
        this.loading = false;
      });
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.el-input.is-disabled .el-input__inner {
  font-weight: bold;
  color: $nrlGreen;
}

.container {
  display: flex;
  flex-direction: column;
}

.table {
  flex: 1;
}
</style>
