<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="6">
        <el-select v-model="search.clubId" placeholder="Select a club" filterable clearable>
          <el-option
            v-for="{ _id, name } in clubs"
            :label="name"
            :key="_id"
            :value="_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select
          v-model="search.competitionId"
          placeholder="Select a competition"
          filterable
          clearable
        >
          <el-option
            v-for="{ _id, name } in competitions"
            :label="name"
            :key="_id"
            :value="_id"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="mt-1">
      <el-col :span="12">
        <el-input
          type="text"
          autocomplete="off"
          @keyup.native.enter="searchTeams"
          v-model="search.criteria"
          placeholder="Team name"
        />
      </el-col>
      <el-button type="success" @click="searchTeams">Search</el-button>
    </el-row>
    <el-row type="flex" :gutter="20" justify="end">
      <el-button
        v-if="tableData.length > 0"
        @click="restrict"
        type="danger"
        :disabled="!$refs.teamTable.selection.length"
      >
        Restrict</el-button
      >
      <el-button
        v-if="tableData.length > 0"
        @click="unrestrict"
        type="success"
        :disabled="!$refs.teamTable.selection.length"
      >
        Unrestrict
      </el-button>
    </el-row>
    <el-row class="mt-1">
      <el-col :span="24">
        <el-table ref="teamTable" :data="tableData">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column fixed prop="_id" label="Id" width="100"></el-table-column>
          <el-table-column fixed prop="name" label="Name" width="150"></el-table-column>
          <el-table-column prop="ageLvl" label="Age Group" width="150"></el-table-column>
          <el-table-column label="Restricted Squad" width="200">
            <template slot-scope="scope">
              <span> {{ formatBool(scope.row.meta.restrictedSquad) }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="orgtree.club.name" label="Club Name" width="150"></el-table-column>
          <el-table-column
            prop="orgtree.association.name"
            label="Association Name"
            width="150"
          ></el-table-column>
          <el-table-column
            :formatter="dateTimeFormatter"
            prop="modifiedAt"
            label="Last Modified"
            width="250"
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  data() {
    return {
      competitions: [],
      clubs: [],
      tableData: [],
      search: {
        competitionId: undefined,
        clubId: undefined,
        criteria: undefined,
      },
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    Promise.all([
      this.$http.get("/nrl/api/v1/admin/competitions"),
      this.$http.get("/nrl/api/v1/admin/clubs"),
    ])
      .then(([compRes, clubRes]) => {
        this.competitions =
          compRes.data && compRes.data.data ? compRes.data.data.filter((c) => c.isActive) : [];
        this.clubs = clubRes.data.data;
      })
      .catch(() => {
        this.$customError();
      })
      .finally(() => this.$store.commit("root/loading", false));
  },
  methods: {
    formatBool(val) {
      return val ? "Yes" : "No";
    },
    dateTimeFormatter(row) {
      if (row.modifiedAt) {
        return this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
    markAsEdited(scope) {
      scope.row.edited = true;
    },
    searchTeams() {
      _.forEach(this.search, (value, key) => {
        if (!value) this.search[key] = undefined;
      });

      this.$http
        .post("/nrl/api/v1/admin/teams/search", this.search)
        .then((res) => {
          this.tableData = res.data.data;
        })
        .catch(() => this.$customError())
        .finally(() => this.$store.commit("root/loading", false));
    },
    restrict() {
      const selectedRows = this.$refs.teamTable.selection;
      const update = selectedRows.map((e) => ({ _id: e._id, restrictedSquad: true }));
      this.$store.commit("root/loading", true);
      this.$http
        .post("nrl/api/v1/admin/teams/bulk-restrict-squad", { update })
        .then(() => {
          for (const { _id, restrictedSquad } of update) {
            const idx = _.findIndex(this.tableData, { _id });
            this.$set(this.tableData[idx].meta, "restrictedSquad", restrictedSquad);
          }
          this.$customSuccess();
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    },
    unrestrict() {
      const selectedRows = this.$refs.teamTable.selection;
      const update = selectedRows.map((e) => ({ _id: e._id, restrictedSquad: false }));
      this.$store.commit("root/loading", true);
      this.$http
        .post("nrl/api/v1/admin/teams/bulk-restrict-squad", { update })
        .then(() => {
          for (const { _id, restrictedSquad } of update) {
            const idx = _.findIndex(this.tableData, { _id });
            this.$set(this.tableData[idx].meta, "restrictedSquad", restrictedSquad);
          }
          this.$customSuccess();
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}
</style>
