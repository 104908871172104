<template>
  <div>
    <el-tabs type="card">
      <el-tab-pane label="GameDevRegion Details">
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="6" class="avatar-box entity-card">
            <img
              v-if="currentLogo"
              :src="currentLogo"
              @error="displayPlaceholder"
              alt=""
              class="avatar"
            />
            <img v-else src="~@/assets/shield_black_small.svg" alt="" class="avatar" />
            <vue-upload-component
              v-if="this.$route.params.type !== 'insert'"
              ref="upload"
              :drop="true"
              v-model="files"
              :extensions="extensions"
              :accept="accept"
              :post-action="uploadURL"
              :headers="uploadHeaders"
              :data="entityData"
              :multiple="true"
              @input-file="inputFile"
              @input-filter="inputFilter"
              class="el-button el-button--default upload"
            >
              Upload
            </vue-upload-component>
          </el-col>
          <el-col :span="18">
            <el-form
              label-position="top"
              :model="gamedevregion"
              :rules="rules"
              ref="gamedevregion"
              class="login-form"
            >
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="national" label="National">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="gamedevregion.orgtree.national.name"
                      placeholder="National Name"
                      :disabled="readOnly"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="name" label="Name">
                    <el-input
                      autocomplete="off"
                      placeholder="Enter name"
                      v-model="gamedevregion.name"
                      :disabled="readOnly"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item label="Abbreviation">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="gamedevregion.meta.abbreviation"
                      placeholder="Enter an abbreviation"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Facebook Link">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="gamedevregion.meta.facebook"
                      placeholder=""
                    >
                      <template slot="prepend">https://facebook.com/</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contactName" label="Contact Name">
                    <el-input
                      autocomplete="off"
                      placeholder="Enter contact name"
                      v-model="gamedevregion.contactName"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contactNumber" label="Contact Number">
                    <el-input
                      autocomplete="off"
                      placeholder="Enter contact name"
                      v-model="gamedevregion.contactNumber"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contactEmail" label="Contact Email">
                    <el-input
                      autocomplete="off"
                      placeholder="Enter contact email"
                      v-model="gamedevregion.contactEmail"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Address"
                    prop="contact.address.formatted"
                    :error="isError ? 'Select from drop down' : null"
                  >
                    <div class="el-input">
                      <google-auto-complete
                        class="auto-complete"
                        id="map"
                        ref="map"
                        @placechanged="placeChanged"
                        :value="gamedevregion.contact.address.formatted"
                        @inputChange="errorOnTyping"
                        placeholder="Enter an address"
                      >
                      </google-auto-complete>
                    </div>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item label="Active">
                    <el-select v-model="gamedevregion.isActive" :disabled="readOnly">
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item label="Business ID">
                    <el-input
                      autocomplete="off"
                      placeholder="Enter a Business ID"
                      v-model="businessId"
                      :disabled="readOnly"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Location ID">
                    <el-input
                      autocomplete="off"
                      placeholder="Enter a Location ID"
                      v-model="locationId"
                      :disabled="readOnly"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.abn" label="ABN">
                    <el-select
                      v-model="gamedevregion.meta.abn"
                      :disabled="readOnly"
                      filterable
                      remote
                      placeholder="Search by name"
                      :loading="abnLoading"
                      :remote-method="searchAbn"
                    >
                      <el-option
                        v-for="item in abnOptions"
                        :key="item.value"
                        :label="`${item.label} (${item.value})`"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.gstRegistered" label="GST Registered">
                    <el-select v-model="gamedevregion.meta.gstRegistered" :disabled="readOnly">
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item label="Website">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="gamedevregion.meta.website"
                      placeholder="Enter a website"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item label="Enable D365 Invoice generation">
                    <el-select
                      v-model="gamedevregion.d365Invoice"
                      :disabled="clearance !== roles.superAdmin"
                    >
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Enable Refund">
                    <el-select
                      v-model="gamedevregion.refund"
                      :disabled="clearance !== roles.superAdmin"
                    >
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
            </el-form>
            <action-buttons
              submit
              :cancel="!isMyEntity"
              @submit="submitForm('gamedevregion')"
              @cancel="cancelForm()"
            />
          </el-col>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="Program Type"
        v-if="clearance === roles.gamedevregionAdmin || clearance === roles.superAdmin"
        ><GameDevRegionPrograms></GameDevRegionPrograms> </el-tab-pane
    ></el-tabs>
  </div>
</template>

<script>
import { isNaN } from "lodash";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, uploadURL, roles } from "../../utils/constants";
import { getS3URL } from "../../utils/utilities";
import GameDevRegionPrograms from "./GameDevRegionPrograms.vue";
import { parseGoogleAddress } from "../../utils/utilities";

export default {
  name: "GameDevRegionForm",
  props: {},
  components: {
    ActionButtons,
    GameDevRegionPrograms,
  },
  data() {
    const validateWebsite = (rule, val, callback) => {
      const { meta } = this.gamedevregion;
      const expression =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      const regex = new RegExp(expression);

      if (typeof meta.website === "string" && !meta.website.match(regex)) {
        return callback(new Error(errormsg.invalidUrl));
      }

      return callback();
    };

    return {
      isError: false,
      isSelected: false, //toggle on when the dropdown selected in Address
      type: null,
      roles,
      abnLoading: false,
      abnOptions: [],
      businessId: "",
      locationId: "",
      gamedevregion: {
        orgtree: {
          national: {
            _id: null,
            name: null,
          },
        },
        name: null,
        contactName: null,
        contactNumber: null,
        contactEmail: null,
        isActive: true,
        meta: {
          akv: {
            businessId: "",
            locationId: "",
          },
          abn: "",
          gstRegistered: false,
          abbreviation: "",
          avatar: "",
          facebook: "",
          website: "",
        },
        contact: {
          name: "",
          email: "",
          number: "",
          address: {},
        },
        d365Invoice: false,
        refund: false,
      },
      files: [],
      rules: {
        name: { required: true, message: errormsg.input_option, trigger: "blur" },
        contactName: { required: true, message: errormsg.input_option, trigger: "blur" },
        contactNumber: { required: true, message: errormsg.input_option, trigger: "blur" },
        contactEmail: { required: true, message: errormsg.input_option, trigger: "blur" },
        "meta.gstRegistered": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.abn": {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        "meta.akv.businessId": {
          required: false,
          trigger: "blur",
          message: errormsg.akvBusinessId,
          validator: (rule, val, cb) => {
            return cb();
          },
        },
        "meta.akv.locationId": {
          required: false,
          trigger: "blur",
          message: errormsg.locationId,
          validator: (rule, val, cb) => {
            return cb();
          },
        },
        "meta.website": {
          required: false,
          validator: validateWebsite,
          trigger: "blur",
        },
        "contact.address.formatted": {
          required: true,
        },
      },
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png",
    };
  },

  mounted() {
    this.$store.commit("root/loading", true);
    const { type, gamedevregion, id } = this.$route.params;
    this.type = type || (id === "add" ? "insert" : "update");
    if (id && id !== "" && id !== "add") {
      if (gamedevregion) {
        this.gamedevregion = gamedevregion;
        if (this.gamedevregion.meta.akv) {
          if (this.gamedevregion.meta.akv.businessId) {
            this.businessId = this.gamedevregion.meta.akv.businessId;
          }
          if (this.gamedevregion.meta.akv.locationId) {
            this.locationId = this.gamedevregion.meta.akv.locationId;
          }
        }
        if (typeof this.gamedevregion.isActive == "boolean")
          this.gamedevregion.isActive = this.gamedevregion.isActive;
        else this.gamedevregion.isActive = true;

        if (typeof this.gamedevregion.d365Invoice == "boolean")
          this.gamedevregion.d365Invoice = this.gamedevregion.d365Invoice;
        else this.gamedevregion.d365Invoice = false;

        if (typeof this.gamedevregion.refund == "boolean")
          this.gamedevregion.refund = this.gamedevregion.refund;
        else this.gamedevregion.refund = false;

        this.gamedevregion.contact = this.gamedevregion.contact ? this.gamedevregion.contact : {};
        this.gamedevregion.contact.address = this.gamedevregion.contact.address
          ? this.gamedevregion.contact.address
          : {};

        this.$store.commit("root/loading", false);
      } else {
        this.$http
          .get(`/nrl/api/v1/admin/game-dev-regions/${id}`)
          .then((response) => {
            this.gamedevregion = response.data.data;
            if (this.gamedevregion.meta.akv) {
              if (this.gamedevregion.meta.akv.businessId) {
                this.businessId = this.gamedevregion.meta.akv.businessId;
              }
              if (this.gamedevregion.meta.akv.locationId) {
                this.locationId = this.gamedevregion.meta.akv.locationId;
              }
            }

            if (!this.gamedevregion.meta.akv) {
              this.gamedevregion.meta.akv = {
                businessId: "",
                locationId: "",
              };
            }

            if (typeof this.gamedevregion.isActive == "boolean")
              this.gamedevregion.isActive = this.gamedevregion.isActive;
            else this.gamedevregion.isActive = true;

            this.gamedevregion.contact = this.gamedevregion.contact
              ? this.gamedevregion.contact
              : {};
            this.gamedevregion.contact.address = this.gamedevregion.contact.address
              ? this.gamedevregion.contact.address
              : {};

            this.$store.commit("root/loading", false);
          })
          .catch(() => {
            this.$customError();
            this.$store.commit("root/loading", false);
          });
      }
      this.$store.commit("root/loading", false);
    } else {
      const { entity } = this.$store.getters["user/activeRole"];
      this.gamedevregion.orgtree.national._id = entity._id;
      this.gamedevregion.orgtree.national.name = entity.name;
      this.$store.commit("root/loading", false);
    }
  },
  methods: {
    searchAbn(query) {
      if (query !== "") {
        this.abnLoading = true;
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/abr-proxy/name", { name: query, maxResult: 10 })
            .then((response) => {
              this.abnLoading = false;
              if (response.data.data.length > 0) {
                this.abnOptions = response.data.data.map((o) => ({ label: o.text, value: o.abn }));
              }
            })
            .catch(() => {
              this.$customError();
            });
        }, 200);
      }
    },
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    submitForm(formname) {
      if (this.gamedevregion.contact.address.formatted === undefined) this.isError = true;

      if (this.isError) return false;
      this.$refs[formname].validate((valid) => {
        this.$refs.map.update(this.gamedevregion.contact.address.formatted);
        if (valid) {
          this.gamedevregion.meta.akv = {
            businessId: this.businessId,
            locationId: this.locationId,
          };
          this.isSelected = true;
          if (this.type === "insert") {
            this.$store.commit("root/loading", true);
            const insert = this.gamedevregion;

            const url = "/nrl/api/v1/admin/game-dev-regions";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$router.push({ name: "game-dev-region.list" });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            const url = `/nrl/api/v1/admin/game-dev-regions/${this.gamedevregion._id}`;

            this.$http
              .put(url, this.gamedevregion)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        }
      });
    },
    cancelForm() {
      this.$router.push({ name: "game-dev-region.list" });
    },
    errorOnTyping() {
      if (!this.isSelected) this.isError = true;
      this.isSelected = false;
    },
    placeChanged(addressData, place) {
      this.isSelected = true;
      this.gamedevregion.contact.address = parseGoogleAddress(addressData, place);
      this.isError = false;
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response.status === "success") {
          this.$store.commit("root/loading", false);
          this.gamedevregion.meta.avatar = newFile.response.url;
          if (this.type === "update") {
            const { avatar } = this.gamedevregion.meta;
            const { _id } = this.gamedevregion;
            this.$store.commit("root/loading", true);
            const update = { meta: { avatar } };
            const url = `/nrl/api/v1/admin/game-dev-regions/${_id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        } else {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }
      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    readOnly() {
      if (this.$store.getters["user/activeRole"].type === roles.superAdmin) return false;
      return true;
    },
    currentLogo() {
      const { entity } = this.$store.getters["user/activeRole"];
      return this.gamedevregion.meta && this.gamedevregion.meta.avatar
        ? this.gamedevregion.meta.avatar
        : entity
        ? getS3URL(entity._id)
        : false;
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    uploadURL() {
      return uploadURL;
    },
    entityData() {
      return {
        entity_type: "gamedevregion",
        entity_id: this.gamedevregion._id,
      };
    },
    isMyEntity() {
      return this.$route.path.split("/").includes("my");
    },
    currentFormattedAddress() {
      if (
        this.gamedevregion &&
        this.gamedevregion.contact &&
        this.gamedevregion.contact.address &&
        this.gamedevregion.contact.address.formatted
      )
        return this.gamedevregion.contact.address.formatted;
      else return null;
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.entity-card {
  height: 40rem;
}

.avatar-box {
  overflow: hidden;
  .avatar {
    margin: 0 auto;
    height: 12.5rem;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .avatar-entity-name {
    text-align: center;
    display: block;
    font-size: 1.05rem;
    font-style: oblique;
    margin-bottom: 1rem;
  }
  .upload {
    margin: 0 auto;
    display: block;
    max-width: 70%;
  }
}

.operation {
  el-button {
    width: 100%;
    height: 100%;
  }
}
</style>
