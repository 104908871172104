<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="24" class="add-to-team">
        <el-button @click="addToTeamVisible = true" type="success" icon="el-icon-plus">Add To Team</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col class="" :span="24">
        <el-table
        v-if="currentMember.teams && currentMember.teams.length > 0"
        :data="currentMember.teams"
        :border="true"
        class="squads-table">
          <el-table-column prop="_id" label="Team ID" width="100" />
          <el-table-column prop="name" label="Team Name" width="200" />
          <el-table-column prop="orgtree.club.name" label="Club" width="200" />
          <el-table-column prop="orgtree.association.name" label="Association" width="200" />
          <el-table-column prop="orgtree.state.name" label="State" width="200" />
          <el-table-column prop="assignedBySuper" :formatter="boolFormatter" label="Added Manually" width="130" />
          <el-table-column width="150" label="Remove">
            <template slot-scope="scope">
              <el-button v-if="scope.row.assignedBySuper" @click="handleSquadDelete(scope.$index, scope.row)">Remove</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog
    :visible.sync="addToTeamVisible"
    class="squads-dialog"
    @opened="addToTeamOpened"
    @closed="addToTeamClosed"
    :close-on-click-modal="false">
      <h5 slot="title" class="centered-title" > Add To Team </h5>
      <div class="add-to-team-box">
        <el-row :gutter="10" class="select-team">
          <el-form label-position="left" label-width="110px" size="medium" v-loading="loadingSquadEntity" element-loading-background="rgba(255,255,255,0.5)">
            <el-form-item label="State">
              <el-select v-model="squadEntityId.state"  placeholder="Please select a state" @change="squadEntityChange($event, 'state')" filterable>
                <el-option v-for="entity in squadEntity.states"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Region">
              <el-select v-model="squadEntityId.region" placeholder="Please select a region" @change="squadEntityChange($event, 'region')" filterable>
                <el-option v-for="entity in squadEntity.regions"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Association">
              <el-select v-model="squadEntityId.association" placeholder="Please select an association" @change="squadEntityChange($event, 'association')" filterable>
                <el-option v-for="entity in squadEntity.associations"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Club">
              <el-select v-model="squadEntityId.club" placeholder="Please select a club" @change="squadEntityChange($event, 'club')" filterable>
                <el-option v-for="entity in squadEntity.clubs"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Team">
              <el-select v-model="squadEntityId.team" placeholder="Please select a team" filterable>
                <el-option v-for="entity in squadEntity.teams"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-row>
        <el-row class="operations">
          <el-button @click="addToTeamVisible = false">Cancel</el-button>
          <el-button type="success" @click="confirmAddToTeam">Confirm</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { EventBus } from '../../bus';
import { nationals } from '../../utils/constants';

export default {
  mounted() {
    EventBus.$on('memberUpdated', () => {
      this.$forceUpdate();
    });
  },
  props: {
    member: { type: Object, required: true }
  },
  data() {
    return {
      nationals,
      squadEntity: {
        states: [],
        regions: [],
        associations: [],
        clubs: [],
        teams: []
      },
      squadEntityId: {
        state: undefined,
        region: undefined,
        association: undefined,
        club: undefined,
        team: undefined
      },
      addToTeamVisible: false,
      loadingSquadEntity: false,
    };
  },
  methods: {
    addToTeamOpened() {
      this.loadingSquadEntity = true;
      this.$http.get('/nrl/api/v1/admin/states')
        .then((response) => {
          this.loadingSquadEntity = false;
          this.squadEntity.states = response.data.data;
        })
        .catch((e) => {
          this.loadingSquadEntity = false;
          this.$customError(e.response.data.message);
        });
    },
    squadEntityChange(id, entityType) {
      this.loadingSquadEntity = true;
      const entityLevels = ['state', 'region', 'association', 'club', 'team'];

      const entityIndex = entityLevels.indexOf(entityType);
      const childEntityName = `${entityLevels[entityIndex + 1]}s`;

      this.$http.get(`/nrl/api/v1/admin/${childEntityName}/${entityType}/${id}`)
        .then((response) => {
          this.loadingSquadEntity = false;

          // Set squad entity
          this.squadEntity[childEntityName] = response.data.data;

          // Clear all levels below
          const clearEntities = _.takeRightWhile(entityLevels, t => t !== entityType);
          for (const e of clearEntities) this.squadEntityId[e] = undefined;
        })
        .catch((e) => {
          this.loadingSquadEntity = false;
          this.$customError(e.response.data.message);
        });

      if (entityType === 'association' && this.nationalId === nationals.TFA) {
        this.loadingSquadEntity = true;
        this.$http.get(`/nrl/api/v1/admin/teams/association/${id}`)
          .then((response) => {
            this.loadingSquadEntity = false;
            // Set squad entity
            this.squadEntity.teams = response.data.data;
          })
          .catch((e) => {
            this.loadingSquadEntity = false;
            this.$customError(e.response.data.message);
          });
      }
    },
    confirmAddToTeam() {
      const memberId = this.currentMember._id;
      const teamId = this.squadEntityId.team;

      // Validation for team
      if (teamId === undefined) {
        this.$customError('Please select a team.');
        return;
      }

      this.$store.commit('root/loading', true);
      this.$http.put(`/nrl/api/v1/admin/members/${memberId}/teams`, { teamId })
        .then((response) => {
          this.$store.commit('root/loading', false);
          this.currentMember.teams = response.data.data;
          this.addToTeamVisible = false;
        })
        .catch((e) => {
          this.$store.commit('root/loading', false);
          this.$customError(e.response.data.message);
        });
    },
    addToTeamClosed() {
      this.addToTeamVisible = false;
      this.squadEntityId = {
        state: undefined,
        region: undefined,
        association: undefined,
        club: undefined,
        team: undefined
      };
    },
    handleSquadDelete(index, row) {
      this.$confirm(
        'Are you sure you want to remove this member from this team?',
        'Remove Member From Team',
        { confirmButtonText: 'OK', cancelButtonText: 'Cancel', }
      ).then(() => {
        const memberId = this.currentMember._id;
        const teamId = row._id;

        this.$store.commit('root/loading', true);
        this.$http.delete(`/nrl/api/v1/admin/members/${memberId}/teams/${teamId}`)
          .then((response) => {
            this.$store.commit('root/loading', false);
            this.currentMember.teams = response.data.data;
          })
          .catch((e) => {
            this.$store.commit('root/loading', false);
            this.$customError(e.response.data.message);
          });
      });
    },
    boolFormatter(row, column, cellValue) {
      return cellValue === true ? 'Yes' : 'No';
    },
  },
  computed: {
    currentMember() {
      return this.member || { teams: [] };
    },
    nationalId() {
      return this.$store.getters['user/activeRole'].national_id;
    }
  }
};
</script>

<style lang="scss" scoped>
  .add-to-team {
    margin-bottom: 1rem;
  }

  .el-dialog__body {
    .add-to-team-box {
      height: 365px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .operations {
        margin-top: 1.75rem;
        margin-bottom: 1.55rem;
      }
    }
  }

  .el-dialog__header {
    padding-bottom: 0px;
  }

  .centered-title {
    width: 100%;
    font-size: 1.2rem;
    font-weight: normal;
    text-align: center;
  }

</style>
