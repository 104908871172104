<template>
  <div>
    <el-row class="search-bar">
      <el-col :span="6">
        <el-input autocomplete="off" v-model="filters[0].value" placeholder="Search..."> </el-input>
      </el-col>
    </el-row>
    <data-tables
      v-loading="loading"
      :filters="filters"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table"
    >
      <el-table-column prop="name" label="Type" width="auto"> </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="auto"
      >
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import {
  nationals,
} from "../../utils/constants";

export default {
  name: "CompetitionsSettingList",
  props: {},
  data() {
    return {
      loading: true,
      tableData: [],
      pageSize: 20,
      filters: [
        {
          value: "",
          prop: "name",
        },
        {
          value: [],
        },
      ],
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
    };
  },
  computed: {
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === nationals.TFA;
    }
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/admin/competitions-settings")
      .then((response) => {
        this.tableData = response.data.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.$customError();
      });
  },
  methods: {
    addCompSetting() {
      this.$router.push({
        name: "comp-or-team-list-types.update",
        params: { type: "add", id: "add" },
      });
    },
    handleRowClick(row) {
      this.$router.push({
        name: "comp-or-team-list-types.update",
        params: {
          type: "update",
          compSetting: row,
          id: row._id,
        },
      });
    },
    modifiedFormatter(row) {
      return row.modifiedAt ? this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-table {
  width: 100%;
}
</style>
