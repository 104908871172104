/* eslint-disable no-return-assign */
import _ from 'lodash';

const initState = () => ({
  search: '',
  data: [],
  filters: {
    type: [],
    requiresAction: [],
    status: [],
    gender: [],
    ageLevel: [],
    fromClub: undefined,
    toClub: undefined
  }
});

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateSearch: (state, payload) => state.search = payload,
    updateFilters: (state, payload) => {
      const {
        type,
        requiresAction,
        status,
        gender,
        ageLevel,
        fromClub,
        toClub
      } = payload;
      _.assign(state.filters, {
        type,
        requiresAction,
        status,
        gender,
        ageLevel,
        fromClub,
        toClub
      });
    },
    updateData: (state, data) => state.data = data
  }
};
