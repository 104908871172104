<template>
  <div class="navbar">
    <el-row type="flex" align="middle">
      <el-col :span="10">
        <img
          class="logo"
          v-if="$route.name === 'login'"
          src="@/assets/MySideline_MobileLogo_black.svg"
          alt="My Sideline"
        />
        <h2 v-else :key="headerName">{{ headerName }} <span>/</span></h2>
      </el-col>
      <el-col class="navigator" :offset="2" :span="12" v-if="isAuthenticated">
        <el-button @click="displayUserRoles = true" v-if="hasRoles" class="switch-role is-medium">
          Switch Role
          <!--            <i class="el-icon-user"/>-->
        </el-button>
        <el-button @click="clickLogout">
          {{ myname }} <i class="el-icon-switch-button"></i>
        </el-button>
        <el-dialog
          width="40%"
          top="10vh"
          title="Switch Role"
          class="role-switch-dialog"
          :visible.sync="displayUserRoles"
        >
          <h3 slot="title">Switch Role</h3>
          <role-switcher @closeModal="displayUserRoles = false" />
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import RoleSwitcher from "./RoleSwitcher.vue";
import {
  nationals,
} from "../utils/constants";

export default {
  name: "NavBar",
  components: {
    RoleSwitcher,
  },
  props: {},
  data() {
    return {
      displayUserRoles: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["user/isAuthenticated"];
    },
    myname() {
      return this.$store.getters["user/userName"];
    },
    headerName() {
      if(this.$route.meta.tfaDisplayName && this.$store.getters["user/activeRole"].national_id === nationals.TFA) {
        return this.$route.meta.tfaDisplayName;
      }
      return this.$route.meta.displayName;
    },
    hasRoles() {
      return this.$store.getters["user/roles"] && this.$store.getters["user/roles"].length > 0;
    },
  },
  methods: {
    clickLogout() {
      this.$store.commit("user/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  height: 40px;
  padding-left: 20px;
}

.switch-role {
  letter-spacing: 0.2em;
}

.navbar {
  h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    padding-left: 30px;
    color: $primary;
    font-family: $fontRegular;
    letter-spacing: 0.2rem;
    span {
      color: $secondary;
    }
  }
  height: 80px;
  background-color: $white;
  width: 100%;
  box-shadow: $shadow;
  .el-row {
    height: 100%;
  }
  .text-center {
    text-align: center;
  }

  .navigator {
    margin-right: 1rem;
    text-align: right;
  }

  .role-switch-dialog {
    h3 {
      padding-bottom: -2rem;
      margin: 0;
      text-align: left !important;
      color: $secondary;
      font-weight: 900;
    }
  }
}
</style>
