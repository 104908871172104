<template>
    <div>
      <el-form>
        <el-row type="flex" align="bottom" :gutter="10">
          <el-col :span="12">
            <el-form-item prop="name" label="Name">
                <el-input
                  autocomplete="off"
                  placeholder="Enter api key name"
                  v-model="name"
                ></el-input>
            </el-form-item>
          </el-col>
          <el-col class="submit-action" :span="6">
            <el-button @click="addApiKey" type="success">Create</el-button>
          </el-col>
          <el-col align="right">
            <el-button
              @click="getApiKeys"
              type="success"
              icon="el-icon-refresh-left"
              class="tw-w-48 tw-h-10"
            >
              Refresh
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-card class="box-card" shadow="hover">
        <div class="clearfix">
          <span>Get started by clicking the button below or <a class="link" href="https://www.postman.com/somarjun-nrl/workspace/mysideline/collection/21122875-5b0c1352-de73-4548-b4b7-25b463fdf0c3" target="_blank"><span>click here</span></a>. Before you run the collection, please update the collection variables with generated api key.</span>
          <br/>
          <RunInPostman />
        </div>
      </el-card>
      <data-tables
        :data="tableData"
        :table-props="tableProps"
        :pagination-props="paginationProps"
        :page-size="pageSize"
        class="data-table"
        layout="table"
        >
        <el-table-column
          prop="id"
          label="ID"
          width="auto">
        </el-table-column>
        <el-table-column
          prop="name"
          label="Name"
          width="auto">
        </el-table-column>
        <el-table-column label="Enable/Disable" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button @click="updateStatusOfApiKey(scope.$index, true)" v-if="tableData[scope.$index].disabled">
              Enable
            </el-button>
            <el-button @click="updateStatusOfApiKey(scope.$index, false)" v-if="!tableData[scope.$index].disabled">
              Disable
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="Delete" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button @click="deleteApiKey(scope.$index)">Delete</el-button>
          </template>
        </el-table-column>
      </data-tables>
    </div>
  </template>
  
  <script>
  
  import RunInPostman from './Postman.vue';
  
  export default {
    name: 'ApiKeyManagement',
    components: { RunInPostman },
    data() {
      return {
        tableData: [],
        pageSize: 20,
        tableProps: {
          border: true,
        },
        paginationProps: {
          pageSizes: [20, 50, 100],
        },
        name: '',
        apiKey: 't'
      };
    },
    mounted() {
      this.getApiKeys();
    },
    methods: {
        getApiKeys() {
          this.$store.commit('root/loading', true);
          this.$httpauth_apikeys_beta
            .get('/api/v1/get-api-keys')
            .then((response) => {
                this.tableData = response.data;
                this.$store.commit('root/loading', false);
            })
            .catch(() => {
                this.$customError();
                this.$store.commit('root/loading', false);
            });
        },
        addApiKey() {
          this.$store.commit('root/loading', true);
          if(this.name === '') {
            this.$customError('Please enter name for API key');
            this.$store.commit('root/loading', false);
            return;
          }

          const isNameExist = this.tableData.find((item) => item.name === this.name);
          if(isNameExist) {
            this.$customError('API key with provided name already exist');
            this.$store.commit('root/loading', false);
            return;
          }
          this.$httpauth_apikeys_beta
            .post('/api/v1/add-api-key', {name: this.name})
            .then((response) => {
                this.open(response.data.key);
                this.$customSuccess('New Api key successfully created');
                delete response.data.key;
                this.tableData.push(response.data);
                this.$store.commit('root/loading', false);
            })
            .catch(() => {
                this.$customError();
                this.$store.commit('root/loading', false);
            });
        },
        deleteApiKey(index) {
          const id = this.tableData[index].id;
          this.$store.commit('root/loading', true);
          this.$httpauth_apikeys_beta
            .delete(`/api/v1/delete-api-key/${id}`)
            .then(() => {
                this.$customSuccess('Deleted api key successfully');
                this.tableData = this.tableData.filter((item) => item.id !== id);
                this.$store.commit('root/loading', false);
            })
            .catch(() => {
                this.$customError();
                this.$store.commit('root/loading', false);
            });
        },
        updateStatusOfApiKey(index, enable) {
          const id = this.tableData[index].id;
          this.$store.commit('root/loading', true);
          this.$httpauth_apikeys_beta
            .put(`/api/v1/update-api-key/${id}`, {enable: enable})
            .then(() => {
                this.$customSuccess('Updated api key successfully');
                this.tableData[index].disabled = !enable;
                this.$store.commit('root/loading', false);
            })
            .catch(() => {
                this.$customError();
                this.$store.commit('root/loading', false);
            });
        },
        open(data) {
          this.$alert(`${data.substring(1,8)}********`, 'API key has been generated successfully.', {
            confirmButtonText: 'Copy',
            center: true,
            callback: action => {
              navigator.clipboard.writeText(data);
              this.$message({
                type: 'success',
                message: `API key is copied to clipboard`
              });
            }
        });
      }
    },
  };
  </script>
  
  <style scoped lang="scss">
      .data-table {
        width: 100%;
        margin-top: 2.5rem !important;
      }
      .box-card {
        margin-top: 1rem;
      }
      .item {
        margin-top: 0.5rem;
      }
      .link {
        color: #409EFF;
        cursor: pointer;
      }
  </style>
  