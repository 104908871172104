/* eslint-disable no-return-assign */
import _ from 'lodash';

const initState = () => ({
  data: [],
  filters: {
    search: '',
    isActive: undefined
  },
  pagination: {
    total: 0,
    pageSize: 20,
    currentPage: 1
  }
});
export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateSearch: (state, payload) => state.filters.search = payload,
    updateFilterIsActive: (state, payload) => state.filters.isActive = payload,
    updateData: (state, data) => state.data = data,
    updatePagination: (state, payload) => _.assign(state.pagination, payload.data),
  }
};
