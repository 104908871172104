<template>
  <div>
    <el-form
      label-position="top">
      <el-table
      :data="players">
        <el-table-column
        prop="name"
        label="Name">
        </el-table-column>
        <el-table-column
            label="Point">
          <template slot-scope="scope">
            <el-input-number :controls="false" :precision="0" v-model.number="players[scope.$index].point" :min="0"/>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <action-buttons
      submit
      @submit="update"
      />
  </div>
</template>

<script>

import ActionButtons from '@/components/ActionButtons.vue';

export default {
  components: { ActionButtons },
  props: [
    'squadPlayers',
    'team'
  ],
  data() {
    return {
      players: [{
        name: '',
        memberId: '',
        point: 0
      }]
    };
  },
  methods: {
    mapPlayers(team, squad) {
      this.players = squad.map((x) => {
        const player = team.meta.playerPoints && team.meta.playerPoints.players.length && team.meta.playerPoints.players.find(p => x._id === p.memberId);
        const preferFirstName = x.profile.preferFirstName || x.profile.firstName;
      const preferLastName = x.profile.preferLastName || x.profile.lastName;
        return {

          name: `${preferFirstName} ${preferLastName}`,
          memberId: x._id,
          point: player ? player.point : 0
        };
      });
    },
    cancelForm() {
      this.$router.push({
        name: 'competitions.list',
      });
    },
    update() {
      const meta = {
        playerPoints: {
          players: this.players.map(x => ({
            ...x,
            point: x.point ? x.point : 0,
            name: undefined
          }))
        }
      };
      this.$store.commit('root/loading', true);
      this.$http
        .put(`/nrl/api/v1/admin/teams/${this.team._id}/playerpoints`, meta)
        .then(() => {
          this.$store.commit('root/loading', false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit('root/loading', false);
          this.$customError();
        });
    }
  },
  watch: {
    squadPlayers(v) {
      this.mapPlayers(this.team, v);
    }
  }
};
</script>

<style lang="scss" scoped>
  .display-form {
    margin-top: 0.45rem;
  }

  .el-input, .el-select {
    width: 100%;
  }

</style>
