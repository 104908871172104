<template>
  <div>
    <el-form
      label-position="top"
      :model="broadcastForm"
      :rules="broadcastRules"
      ref="broadcastRules"
    >
      <div
        class="tw-flex tw-flex-wrap tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-form-item prop="liveUrl" style="width: 50%" label="Live URL">
          <el-input
            autocomplete="off"
            placeholder="for example www.facebook.com/groups/919418121533861"
            type="text"
            v-model="broadcastForm.liveUrl"
            ><template slot="prepend">https://</template></el-input
          >
        </el-form-item>

        <el-form-item prop="liveDateTime" style="width: 20%" label="Live Date & Time">
          <el-date-picker
            value-format="timestamp"
            v-model="broadcastForm.liveDateTime"
            type="datetime"
            placeholder="Date and time"
            format="yyyy/MM/dd HH:mm"
            :picker-options="{
              format: 'hh:mm',
            }"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="crowdNumber" style="width: 20%" label="Crowd Number">
          <el-input
                  autocomplete="off"
                  placeholder="Crowd Number"
                  type="number"
                  v-model.number="broadcastForm.crowdNumber"
                ></el-input>
        </el-form-item>
        <div class="tw-flex tw-w-full tw-gap-3 tw-pt-3">
          <label class="el-form-item__label"
            >Live Feed date and time is the same as the match</label
          >
          <el-form-item label="">
            <el-checkbox label="Yes" v-model="broadcastForm.liveMatchtime"></el-checkbox>
          </el-form-item>
        </div>
        <div class="tw-flex tw-gap-3">
          <label class="el-form-item__label"
            >Videographer has agreed to the NRL Code of Conduct and NRL Privacy policy?</label
          >
          <el-form-item prop="liveAgreement" label="">
            <el-checkbox label="Yes" v-model="broadcastForm.liveAgreement"></el-checkbox>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <action-buttons submit cancel @submit="updateBroadcast()" @cancel="cancelForm()" />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";

export default {
  name: "BroadcastDetails",
  components: {
    ActionButtons,
  },
  props: {
    match: Object,
    cancelForm: Function,
  },
  data() {
    const onValidator = (_, value, callback) => {
      if (this.broadcastForm.liveAgreement !== true && this.broadcastForm.liveUrl )
        return callback(new Error("A live agreement must be in place"));
      return callback();
    };
    const liveUrlValidator = (_, value, callback) => {
      if(!this.broadcastForm.crowdNumber || (this.broadcastForm.crowdNumber && this.broadcastForm.liveUrl)){
        if (!this.broadcastForm.liveUrl) return callback(new Error("Must have a live url"));
        if (
          typeof this.broadcastForm.liveUrl === "string" &&
          this.broadcastForm.liveUrl.toLowerCase().includes("://")
        )
          return callback(new Error("Please do not type https:// as that is already included."));
        if (this.broadcastForm.liveDateTime && !this.broadcastForm.liveUrl) {
          return callback(new Error("Field is required"));
        }
        return callback();
      }
      return callback();
    };
    // const liveDateTimeValidator = (_, value, callback) => {
    //   if (this.broadcastForm.liveUrl && !this.broadcastForm.liveDateTime) {
    //     return callback(new Error("Field is required"));
    //   }
    //   return callback();
    // };

    return {
      broadcastForm: {
        liveUrl: undefined,
        liveDateTime: undefined,
        liveAgreement: undefined,
        liveMatchtime: undefined,
        crowdNumber:undefined,
      },
      broadcastRules: {
        liveUrl: {
          trigger: "blur",
          validator: liveUrlValidator,
        },
        liveAgreement: {
          trigger: "blur",
          validator: onValidator,
        },
        crowdNumber: {
          required: false,
          trigger: "change",
          // validator: crowdNumberValidator,
        },
        // liveDateTime: {
        //   trigger: "blur",
        //   validator: liveDateTimeValidator,
        // },
      },
    };
  },
  mounted() {
    if (this.match.meta && this.match.meta.liveUrl)
      this.broadcastForm.liveUrl = this.match.meta.liveUrl;
    if (this.match.meta && this.match.meta.liveDateTime)
      this.broadcastForm.liveDateTime = this.match.meta.liveDateTime;
    if (this.match.meta && this.match.meta.liveAgreement) this.broadcastForm.liveAgreement = true;
    if (this.match.meta && this.match.meta.liveDateTime == this.match.dateTime)
      this.broadcastForm.liveMatchtime = true;
    if(this.match.meta && this.match.meta.crowdNumber)
       this.broadcastForm.crowdNumber = this.match.meta.crowdNumber;
  },
  methods: {
    updateBroadcast() {
      this.$refs.broadcastRules.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const url = `/nrl/api/v1/admin/matches/${this.match._id}`;

          if (this.broadcastForm.liveMatchtime)
            this.broadcastForm.liveDateTime = this.match.dateTime;
          if (!this.broadcastForm.liveUrl || !this.broadcastForm.liveUrl.trim())
            this.broadcastForm.liveUrl = null;
          if (!this.broadcastForm.liveDateTime) this.broadcastForm.liveDateTime = null;
          if (this.broadcastForm.liveUrl)
            this.broadcastForm.liveUrl = this.broadcastForm.liveUrl.replace(/\s+/g, "");
          if (!this.broadcastForm.crowdNumber) this.broadcastForm.crowdNumber = 0;
          this.$http
            .put(url, { meta: this.broadcastForm })
            .then(() => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
        return true;
      });
    },
  },
};
</script>
