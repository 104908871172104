<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form label-position="top" :model="filters" ref="filters" class="login-form">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="season" label="Season">
            <el-select
              @change="updateCompetitions"
              v-model="filters.season"
              filterable
              multiple
              placeholder="Select a season"
            >
              <el-option
                v-for="season in seasons"
                :label="season.name"
                :key="season.value"
                :value="season.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="clearance <= roles.associationAdmin">
        <el-col :span="12">
          <el-form-item prop="selectedCompetition" label="Competition">
            <el-select
              clearable
              filterable
              v-model="filters.selectedCompetition"
              placeholder="Select a competition"
            >
              <el-option
                v-for="entity in competitions"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="Team" label="Team">
            <el-select clearable v-model="filters.selectedTeam" placeholder="Select a team">
              <el-option v-for="{ name, _id } in teams" :label="name" :key="_id" :value="_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8" v-if="this.isTfa">
          <el-form-item prop="status" label="Status">
            <el-select
              v-model="filters.status"
              placeholder="Select a file type"
            >
              <el-option
                v-for="status in statuses"
                :label="status.name"
                :key="status.type"
                :value="status.type"
                :default="status.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { roles, mysidelineSeasons } from "@/utils/constants";

export default {
  name: "MatchesReport",
  components: {
    ActionButtons
  },
  mounted() {
    this.$store.commit("root/loading", true);
    if (this.clearance <= roles.associationAdmin) {
      this.$http
        .get("/nrl/api/v1/admin/competitions")
        .then(res => {
          this.competitions = res.data.data;
        })
        .catch(() => {
          this.$customError("Unable to fetch competitions");
        });
    }

    this.$http
      .get("/nrl/api/v1/admin/teams")
      .then(res => {
        this.myteams = res.data.data;
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
      });
  },
  data() {
    return {
      competitions: [],
      filters: {
        fileType: "csv",
        selectedCompetition: null,
        selectedTeam: null,
        season: "",
        status: "all",
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      myteams: [],
      roles,
      statuses: [{ name: 'All', type: 'all', default: true }, { name: 'Active', type: 'active' }, { name: 'Inactive', type: 'inactive' }]
    };
  },
  methods: {
    async updateCompetitions(seasons) {
      this.$store.commit("root/loading", true);
      if (this.clearance <= roles.associationAdmin) {
        let url = "/nrl/api/v1/admin/competitions?";
        if (seasons.length === 1) {
          url += "season=" + seasons[0];
        } else {
          seasons.forEach((szn, i) => {
            url += "season=" + szn;
            if(seasons.length - 1 !== i) url+= "&";
          });
        }
        try {
          const res = await this.$http.get(url);
          this.competitions = res.data.data;
          this.$store.commit("root/loading", false);
        } catch (e) {
          this.$store.commit("root/loading", false);
          this.$customError("Unable to fetch competitions");
        }
      }
      let url_teams = "/nrl/api/v1/admin/teams?season=";
      if (seasons.length === 1) {
        url_teams += seasons[0];
      } else {
        url_teams += "all";
      }
      try {
        const res_teams = await this.$http.get(url_teams);

        this.myteams = res_teams.data.data;
        this.$store.commit("root/loading", false);
      } catch (e) {
        this.$store.commit("root/loading", false);
      }
    },
    sendReport() {
      this.$refs.filters.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "match-stats",
            fileType: this.filters.fileType,
            filters: {
              competitionId: this.filters.selectedCompetition,
              teamFilter: this.filters.selectedTeam,
              seasons: this.filters.season,
              status: this.filters.status,
            },
            meta: {
              timezone: moment.tz.guess()
            }
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then(response => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  },
  computed: {
    seasons() {
      return mysidelineSeasons.map(v => ({ name: v.toString(), value: v }));
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    teams() {
      if (this.filters.selectedCompetition) {
        const comp = _.find(this.competitions, c => c._id === this.filters.selectedCompetition);
        if (!comp) return this.myteams;
        const teamIds = _.map(comp.teams, t => t._id);
        return _.filter(this.myteams, t => _.includes(teamIds, t._id));
      }
      return this.myteams;
    },
    isTfa() {
      return this.$store.getters['user/activeRole'].national_id === 31;
    }
  }
};
</script>
