<template>
  <scroll-sync horizontal class="tracks tw-flex tw-overflow-y-hidden syncscroll tw-gap-x-2">
    <div class="scrollLeft"></div>
    <div class="track time tw-sticky tw-left-0 tw-z-10">
      <div
        v-for="ts in setTimes"
        :key="ts.time"
        :data-timeslot="ts.dataTimeslot"
        :data-timeslot-increment="ts.dataTimeIncrement"
        class="entry"
      >
        <time v-text="ts.time"></time>
      </div>
    </div>
    <template v-if="!gridRefresh">
      <div
        v-for="venue in grid.groupsLvl1"
        class="track tw-grid tw-gap-x-1"
        :style="`flex-basis: calc(11% * ${venue.venueFields.length}); grid-template-columns: repeat(${venue.venueFields.length}, minmax(266px, ${columnMaxWidth}));`"
        :key="venue.id"
        :data-venue-id="venue.id"
        :data-venue-timezone="getVenueTz(venue)"
      >
        <div
          class="tw-grid"
          :style="`grid-template-rows: repeat(${timeslotsRequired}, minmax(0, auto)); background-color: #f6f6f6`"
          v-for="(field, i) in venue.venueFields"
          :key="`${field.id}-${i}`"
          :data-venue="field.id"
        >
          <div
            v-for="time in timeslotMerge"
            :key="`${time.numericHour}:${time.mins}`"
            :data-time-hour="parseInt(time.hour)"
            :data-time-mins="parseInt(time.mins)"
            :data-start-time="`${time.numericHour}:${time.mins}`"
            :data-end-time="time.timeEnd"
            class="entry tw-relative dropzone"
          >
            <template
              v-if="
                sortedMatches[venue.id] &&
                sortedMatches[venue.id][field.id] &&
                sortedMatches[venue.id][field.id][time.hour] &&
                sortedMatches[venue.id][field.id][time.hour].length
              "
            >
              <template v-for="match in sortedTimes(venue.id, field.id, time.hour, time.timeEnd)">
                <Match-Card
                  :key="match.id"
                  :match="match"
                  :colors="colors"
                  :compList="grid.competitions"
                />
              </template>
            </template>
          </div>
        </div>
      </div>
      <div class="scrollRight"></div>
    </template>
  </scroll-sync>
</template>
<script>
import MatchCard from "./MatchCard.vue";
import { fixtureMinutes } from "@/utils/constants";
import ScrollSync from "vue-scroll-sync";

export default {
  name: "Fixture-Grid",
  props: {
    colors: Array,
    grid: Object,
    gridRefresh: Boolean,
    timesBetween: Object,
    timeslotIncrements: Number,
    timezone: String,
  },
  components: {
    MatchCard,
    ScrollSync,
  },
  data() {
    return {
      fixtureMinutes,
      sortedMatches: {},
    };
  },
  mounted() {
    this.sortedMatches = this.grid.items;

    const btnR = document.querySelector(".btn-right");
    const btnL = document.querySelector(".btn-left");
    const tracks = document.querySelector(".tracks");

    const tracksW = tracks.scrollWidth;
    const tracksVisible = tracks.offsetWidth;
    const colsPerJump = Math.round(tracksW / tracksVisible);
    const movement = colsPerJump * 266;
    if (btnR)
      btnR.addEventListener("click", (_) => {
        tracks.scrollBy({
          left: movement - 64,
          behavior: "smooth",
        });
      });

    if (btnL)
      btnL.addEventListener("click", (_) => {
        tracks.scrollBy({
          left: -movement + 64,
          behavior: "smooth",
        });
      });
  },
  computed: {
    columnMaxWidth() {
      if (this.noCols === 1) return "700px";
      if (this.noCols === 2) return "500px";
      if (this.noCols >= 3) return "266px";
      return "1fr";
    },
    noCols() {
      let gridNoCols = 0;
      this.grid.groupsLvl1.forEach((v) => {
        gridNoCols += v.venueFields.length;
      });
      return gridNoCols;
    },
    setTimes() {
      const { timeslotIncrements, timesBetween, fixtureMinutes } = this;
      const timeArr = [];
      for (let i = timesBetween.start; i < timesBetween.finish; i++) {
        const ta = fixtureMinutes[timeslotIncrements];
        ta.forEach((mins) => {
          let minsStr = mins;
          if (i > 11) minsStr += "pm";
          else minsStr += "am";
          const timeObj = {
            dataTimeslot: i,
            dataTimeIncrement: mins,
            time: `${i}:${minsStr}`,
          };
          timeArr.push(timeObj);
        });
      }
      return timeArr;
    },
    timeslotsRequired() {
      const { timeslotIncrements, timesBetween } = this;
      let hourSplits;
      if (timeslotIncrements === 60) hourSplits = 1;
      if (timeslotIncrements === 30) hourSplits = 2;
      if (timeslotIncrements === 15) hourSplits = 4;
      if (timeslotIncrements === 5) hourSplits = 12;
      return hourSplits * (timesBetween.finish - timesBetween.start);
    },
    timeslotMerge() {
      const { timesBetween, timeslotIncrements, fixtureMinutes } = this;
      const { start, finish } = timesBetween;
      const arr = [];
      for (let i = start; i < finish; i++) {
        const hourlyBreakdown = fixtureMinutes[timeslotIncrements];
        hourlyBreakdown.forEach((mins) => {
          const hourStr = `${i}`;
          const hour = `${hourStr.length > 1 ? i : "0" + i}`;
          const endTime = `${hour}:${
            hourlyBreakdown.length === 1
              ? "59"
              : parseInt(mins)
              ? parseInt(mins) + timeslotIncrements - 1
              : hourlyBreakdown[1] - 1
          }`;
          let endTimeStr = `${endTime}`;
          if (endTimeStr.length === 1) endTimeStr = "0" + endTimeStr;
          const timeslotObj = {
            numericHour: i,
            hour,
            mins,
            timeEnd: endTimeStr,
          };
          arr.push(timeslotObj);
        });
      }
      return arr;
    },
  },
  methods: {
    getVenueTz(venue) {
      const tz = venue && venue.venueTz ? venue.venueTz : this.timezone || "Austrlia/Sydney";
      return tz;
    },
    sortedTimes(v, f, ts, te) {
      const { grid, timeslotIncrements } = this;
      const { items } = grid;
      const matchesInHour = items[v][f][ts];
      const endIncrement = parseInt(te.split(":")[1]);
      const matchesForThisTimeslot = matchesInHour.filter((m) => {
        const startMins = parseInt(m.startTime.mins);
        const diffToInc = endIncrement - startMins;
        if (diffToInc <= 0) return false;
        if (timeslotIncrements < diffToInc) return false;
        return true;
      });
      return matchesForThisTimeslot;
    },
  },
};
</script>
<style scoped>
.entry {
  height: min-content;
  min-height: 72px;
  display: flex;
  flex-direction: column;
}
.entry.dropzone {
  display: block;
  background: #f6f6f6;
  padding: 0 8px;
}

.entry.dropzone:last-of-type {
  border-radius: 0 0 2px 2px;
}

.headers .time {
  z-index: 20;
}
.scrollRight,
.scrollLeft {
  position: absolute;
  width: 32px;
  height: calc(100% - 56px);
  z-index: 50;
}
.scrollLeft {
  left: -32px;
}
.scrollRight {
  right: -32px;
}

.time {
  flex: 0 0 64px !important;
  position: -webkit-sticky;
  overflow-x: hidden;
}
.time .entry {
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #e6e6e6;
  position: relative;
  transition: height 0.3s ease-out;
}
.time .entry:first-of-type {
  border-top: solid 1px #e6e6e6;
}
.time .entry:last-of-type {
  border-bottom-color: transparent;
}
time {
  font-weight: 400;
  font-size: 9px;
  line-height: 8px;
  color: #333333;
  text-align: center;
}

.tracks {
  -webkit-overflow-scrolling: touch;
}
.tracks {
  overflow: auto;
}
.tracks::-webkit-scrollbar {
  display: none;
}
.tracks .time {
  z-index: 15;
}

.track {
  flex: 1 0 11%;
}
.track:last-of-type > div {
  border-right: 0;
}
.track.time {
  background-color: #f6f6f6;
  border-radius: 0 0 2px 2px;
}
.track + .track {
  margin-left: -1px;
}

@media (max-width: 767px) {
  .track:not(.time) {
    flex: 1 0 calc(50% + 7px);
  }
}
</style>
