<template>
  <div>
    <el-row>
      <el-button @click="addLadderTemplate()" type="success" icon="el-icon-plus"> Add Ladder Template </el-button>
    </el-row>
    <data-tables
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table">
      <el-table-column
        fixed
        align="left"
        prop="name"
        label="Name"
        width="auto">
      </el-table-column>
      <el-table-column
        fixed
        align="left"
        prop="createdAt"
        label="Date Created"
        :formatter="createdFormatter"
        width="auto">
      </el-table-column>
      <el-table-column
        fixed
        align="left"
        prop="modifiedAt"
        label="Last Modified"
        :formatter="modifiedFormatter"
        width="auto">
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>

export default {
  name: 'LadderTemplateList',
  props: {
  },
  data() {
    return {
      tableData: [],
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
    };
  },
  mounted() {
    this.$store.commit('root/loading', true);
    this.$http
      .get('/nrl/api/v1/admin/ladder-templates')
      .then((response) => {
        this.tableData = response.data.data;
        this.$store.commit('root/loading', false);
      })
      .catch(() => {
        this.$customError();
        this.$store.commit('root/loading', false);
      });
  },
  methods: {
    handleRowClick(row) {
      this.$router.push({
        name: 'laddertemplate.update',
        params: {
          type: 'update',
          ladderTemplate: row,
          id: row._id
        },
      });
    },
    addLadderTemplate() {
      this.$router.push({
        name: 'laddertemplate.add',
        params: {
          type: 'insert',
          ladderTemplate: null,
          id: 'new',
        },
      });
    },
    modifiedFormatter(row) {
      return (row.modifiedAt ? this.moment(row.modifiedAt).format('MMMM Do YYYY, h:mm:ss a') : 'NA');
    },
    createdFormatter(row) {
      return (row.createdAt ? this.moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a') : 'NA');
    },
  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
  .el-input, .el-select {
    width: 100%;
  }

  .btn-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-align: left;
  }

  .btn-container-add {
    float: right;
  }

  .data-table {
    margin-top: 2.5rem !important;
  }
</style>
