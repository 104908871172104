<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="season" label="Season">
            <el-select clearable filterable v-model="filters.season" placeholder="Select a season">
              <el-option v-for="ss in mysidelineSeasons" :label="ss" :key="ss" :value="ss">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { mysidelineSeasons, roles, errormsg } from "@/utils/constants";

export default {
  name: "competition-report",
  components: {
    ActionButtons,
  },
  mounted() {},
  data() {
    return {
      mysidelineSeasons,
      filters: {
        fileType: "csv",
        season: undefined,
      },
      filterRules: {
        season: {
          required: true,
          message: errormsg.select_option,
        },
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      roles,
    };
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "competition",
            fileType: this.filters.fileType,
            filters: _.pick(this.filters, ["season"]),
            meta: {
              timezone: moment.tz.guess(),
            },
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
  },
  watch: {},
};
</script>

<style scoped></style>
