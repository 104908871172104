<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-mt-24">
    <div class="tw-p-4">
      <img src="~@/assets/shield_black.svg" alt="nrl" class="logo" />
    </div>
    <button
      class="tw-py-2 tw-text-sm tw-font-medium tw-tracking-widest tw-text-white tw-uppercase tw-bg-green-700 tw-rounded hover:tw-bg-green-300 tw-px-7 hover:tw-text-black"
      id="login"
      type="success"
      @click="redirect()"
    >
      Login with NRL Account
    </button>
    <!-- button left as an example of before -->
    <!-- <el-button id="login" type="success" @click="redirect()">Login with NRL Account</el-button> -->
  </div>
</template>

<script>
export default {
  name: "Login",
  props: {},
  created() {
    if (this.$store.getters["user/isAuthenticated"]) {
      this.$router.push("/");
    }
  },
  data() {
    return {};
  },
  methods: {
    redirect() {
      const m =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://nrl-qa.nrl.digital/"
          : "https://www.nrl.com/";
      const clientId = process.env.VUE_APP_PIN_ENV === "test" ? "grassrootstest" : "grassroots";
      const redirectUri = this.$store.getters["root/redirectUri"];
      const url = `/account/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid email profile offline_access verified&response_type=code`;
      const a = new URL(url, m);
      window.location = a;
    }
  }
};
</script>

<style scoped lang="scss">
.wrap {
  height: 117px;
  max-height: 117px;
  text-align: center;
  .logo {
    max-height: 100%;
  }
}

.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .login-form {
    text-align: center;
    position: relative;
    width: 400px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
  .btn-container {
    margin-top: 50px;
  }
  .el-input {
    display: inline-block;
    width: 100%;
  }
  .el-button {
    width: 90%;
    margin-top: 2rem;
  }
  @media only screen and (max-width: $sm) {
    .login-form {
      width: auto;
    }
  }
}
</style>
