<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-alert title="If from date and to date are same the report considers start of the day and end of the day." type="info" show-icon> </el-alert>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="season" label="Season">
            <el-select v-model="filters.season" filterable multiple placeholder="Select a season">
              <el-option
                v-for="season in seasons"
                :label="season.name"
                :key="season.value"
                :value="season.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="fromDate" label="From">
            <el-date-picker
              format="dd/MM/yyyy"
              type="date"
              v-model="filters.fromDate"
              placeholder="DD/MM/YYYY"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="toDate" label="To">
            <el-date-picker
              format="dd/MM/yyyy"
              type="date"
              v-model="filters.toDate"
              placeholder="DD/MM/YYYY"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { nationals, mysidelineSeasons, errormsg } from "../../../utils/constants";

export default {
  name: "DisbursementsReport",
  components: {
    ActionButtons,
  },
  data() {
    return {
      filters: {
        fileType: "csv",
        season: "",
        fromDate: null,
        toDate: null,
      },
      filterRules: {
        fileType: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        season: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      defaultFromDate: moment().subtract(7, "days").format("DD/MM/YYYY"),
      defaultToDate: moment().format("DD/MM/YYYY"),
    };
  },
  computed: {
    seasons() {
      return mysidelineSeasons.map((v) => ({ name: v.toString(), value: v }));
    },
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const fromDate = this.filters.fromDate ? this.filters.fromDate.getTime() : moment().subtract(7, "days").valueOf()
          const toDate = this.filters.toDate ? this.filters.toDate.getTime() :  moment().valueOf()
          const body = {
            reportType: "disbursements",
            fileType: this.filters.fileType,
            filters: {
              seasons: this.filters.season,
              fromDate: fromDate,
              toDate: toDate,
            },
            meta: {
              timezone: moment.tz.guess(),
            },
          };
          if (this.filters.fromDate && this.filters.toDate) {
            // Convert fromDate to UNIX timestamp and set it to the start of the day
            const fromDate = new Date(this.filters.fromDate);
            fromDate.setHours(0, 0, 0, 0);
            body.filters.fromDate = fromDate.getTime();

            // Convert toDate to UNIX timestamp and set it to the end of the day
            const toDate = new Date(this.filters.toDate);
            toDate.setHours(23, 59, 59, 999);
            body.filters.toDate = toDate.getTime();
          }

          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
  },
};
</script>
