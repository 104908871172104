<template>
  <div>
    <el-row class="search-bar">
      <el-button
        type="success"
        icon="el-icon-plus"
        class="add-venue"
        v-if="isSuperAdmin"
        @click="handleAddVenue"
      >
        Add venue
      </el-button>
    </el-row>
    <el-row class="search-bar">
      <el-col :span="6">
        <el-input autocomplete="off" v-model="queryFilterValue" placeholder="Search..."> </el-input>
      </el-col>
    </el-row>
    <data-tables-server
      v-loading="loading"
      :data="allVenues"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      :total="total"
      @query-change="queryChange"
      @cell-click="handleRowClick"
      class="data-table"
    >
      <el-table-column fixed prop="_id" label="Id" width="100"> </el-table-column>
      <el-table-column fixed prop="name" label="Name"> </el-table-column>
      <el-table-column prop="address.formatted" label="Address"> </el-table-column>
      <el-table-column prop="venueTimezone" label="Timezone" width="150"> </el-table-column>
      <el-table-column prop="status" label="Status" width="200" v-if="isSuperAdmin">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="statuChange(row)"
            active-text="Active"
            inactive-text="InActive"
          >
          </el-switch>
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
const _ = require("lodash");

export default {
  name: "VenuesList",
  props: {},
  data() {
    return {
      loading: true,
      allVenues: [],
      queryFilterValue: this.$store.state.entities.venues.criteria,
      searchVenues: "",
      pageSize: 20,
      currentPage: 1,
      total: 0,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      queryInfo: {},
      isSuperAdmin: this.$store.getters["user/isSuperAdmin"],
    };
  },
  mounted() {
    this.fetchStore();
    this.loading = false;
  },
  methods: {
    fetchStore() {
      const {
        data,
        pagination: { total, pageSize, page },
      } = this.$store.state.entities.venues;

      if (data.length) {
        this.total = total;
        this.pageSize = pageSize;
        this.currentPage = page;
        this.allVenues = data;
        return true;
      }
      return false;
    },
    queryChange(queryInfo) {
      this.queryInfo = queryInfo;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get(`/nrl/api/v1/admin/venues/data-table/${this.pageSize}/${this.currentPage}`, {
          params: { criteria: this.queryFilterValue },
        })
        .then((response) => {
          if (response.data.data.pageData && response.data.data.pageData.length > 0) {
            const newData = response.data.data.pageData.map((row) => {
              if (row.status === undefined) {
                this.$set(row, "status", false); // Ensure row is reactive
              }
              return row;
            });
            this.allVenues = newData;
            this.total = response.data.data.total;
          } else {
            this.allVenues = [];
            this.total = 0;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },
    updateVenue(row) {
      this.loading = true;
      const { _id } = row;
      if (_id) {
        this.$http
          .put(`/nrl/api/v1/admin/venues/${_id}`, row)
          .then(() => {
            this.loading = false;
            this.$customSuccess();
          })
          .catch(() => {
            this.loading = false;
            this.$customError();
          });
      }
    },
    handleRowClick(row, column) {
      if (column.label != "Status") {
        this.$router.push({
          name: "venues.form",
          params: {
            type: "update",
            venue: row,
            id: row._id,
          },
        });
      }
    },

    statuChange(row) {
      this.updateVenue(row);
    },
    handleAddVenue() {
      this.$router.push({
        name: "venues.form",
        params: {
          type: "add",
          id: "add",
        },
      });
    },
  },
  watch: {
    allVenues(data) {
      this.$store.commit("entities/updateData", { data, entity: "venues" });
      this.$store.commit("entities/updatePagination", {
        data: { ..._.pick(this.queryInfo, ["pageSize", "page"]), total: this.total },
        entity: "venues",
      });
    },
    // eslint-disable-next-line func-names
    queryFilterValue: _.debounce(function (criteria) {
      this.$store.commit("entities/updateCriteria", { criteria, entity: "venues" });
      this.fetchData();
    }, 500),
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-table {
  width: 100%;
}
</style>
