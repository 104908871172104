<template>
  <el-tabs type="card" class="match-grid-tabs">
    <el-tab-pane class="tw-mt-6">
      <div class="tw-flex tw-gap-x-0.5 tw-items-center" slot="label">
        <span>Add New Venue</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          style="width: 18px; height: 18px"
          class="icon-default"
        >
          <path
            fill-rule="evenodd"
            d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <el-form ref="helperForm" label-position="top" :model="helper" :rules="helperRules">
        <div class="tw-flex tw-flex-wrap tw-gap-4 tw-ml-6 tw-mb-6 tw-items-end">
          <el-form-item prop="venueId" label="Add new venues" class="admin-input__std">
            <el-select
              v-model="helper.venueId"
              :remote="true"
              :remote-method="onHelperFilterVenue"
              placeholder="Search venues"
              filterable
              clearable
              size="small"
              @clear="helper.venueId = undefined"
            >
              <el-option
                v-for="{ _id, name } in helperVenues"
                :label="name"
                :key="_id"
                :value="_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="fieldNo" label="Field Number" class="admin-input__std">
            <el-input
              controls-position="right"
              maxlength="10"
              v-model="helper.fieldNo"
              placeholder="Field Number"
              size="small"
            ></el-input>
          </el-form-item>
          <button
            @click.prevent="addNewVenue"
            class="admin-button__medium admin-bg-highlight"
            type="success"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="tw-w-4 tw-h-4"
              style="color: #222222"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clip-rule="evenodd"
              />
            </svg>
            <span style="color: #222222">Add</span>
          </button>
        </div>
      </el-form>
    </el-tab-pane>
    <el-tab-pane>
      <div class="tw-flex tw-gap-x-0.5 tw-items-center" slot="label">
        <span>
          Competitions <span class="comp-badge">{{ compLabel }}</span></span
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          style="width: 18px; height: 18px"
          class="icon-default"
        >
          <path
            fill-rule="evenodd"
            d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="tw-flex tw-overflow-x-scroll tw-gap-x-3 tw-px-6 tw-py-6 tw-items-end">
        <div
          v-for="({ _id, name }, i) in grid.competitions"
          :key="_id"
          class="tw-flex tw-flex-row tw-justify-center tw-align-center tw-gap-2.5 tw-min-w-max"
          :style="compColour(i)"
          style="padding: 3px 12px; border-radius: 12px"
        >
          <span class="competition-tags">{{ name }}</span>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane>
      <div class="tw-flex tw-gap-x-0.5 tw-items-center" slot="label">
        <span>
          Search the Match Grid
          <span v-if="activeSearches > 0" class="comp-badge">{{ activeSearches }}</span>
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          style="width: 18px; height: 18px"
          class="icon-default"
        >
          <path
            fill-rule="evenodd"
            d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <el-form
        ref="searchForm"
        class="search-form tw-pl-6 tw-py-6"
        label-position="top"
        :model="search"
      >
        <div class="tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-6">
          <el-form-item prop="venue" label="Venue" class="admin-input__std admin-option__tag">
            <el-select
              v-model="search.venueIds"
              :remote="true"
              :remote-method="onFilterVenue"
              placeholder="Search venue"
              filterable
              clearable
              multiple
              size="small"
              @clear="search.venueIds = undefined"
              @change="heightWatcher"
            >
              <el-option
                v-for="{ _id, name } in allVenues"
                :label="name"
                :key="_id"
                :value="_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="fieldNo"
            label="Field Number"
            class="admin-input__std admin-option__tag"
          >
            <el-select
              v-model="search.fieldNo"
              multiple
              filterable
              allow-create
              size="small"
              placeholder="Search by field numbers"
              no-data-text="Type and select field number"
              @change="heightWatcher"
            >
            </el-select>
          </el-form-item>
          <el-form-item
            prop="competitionId"
            label="Competition"
            class="admin-input__std admin-option__tag"
          >
            <el-select
              v-model="search.competitionIds"
              placeholder="Select a Competition"
              multiple
              filterable
              clearable
              size="small"
              @clear="search.competitionId = undefined"
              @change="heightWatcher"
            >
              <el-option
                v-for="{ _id, name } in competitions"
                :key="_id"
                :value="_id"
                :label="name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="ageLvl" label="Age Level" class="admin-input__std admin-option__tag">
            <el-select
              v-model="search.ageLvls"
              placeholder="Select an Age Level"
              filterable
              multiple
              clearable
              size="small"
              @clear="search.ageLvl = undefined"
              @change="heightWatcher"
            >
              <el-option
                v-for="{ name } in ageLevels"
                :key="name"
                :value="name"
                :label="name"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tw-flex tw-gap-x-4" style="padding-top: 14px; height: min-content">
            <el-form-item prop="isClashOnly" class="admin-checkbox shaded">
              <el-checkbox
                v-model="search.isClashOnly"
                label="Show match clashes only"
              ></el-checkbox>
            </el-form-item>
            <el-form-item prop="showByes" class="admin-checkbox shaded">
              <el-checkbox v-model="search.showByes" label="Show Bye matches"></el-checkbox>
            </el-form-item>
            <button @click.prevent="searchMatches" class="admin-button__medium admin-bg-highlight">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="tw-w-4 tw-h-4 tw-mr-1"
                style="color: #000000"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
              <span style="color: #000000">Search</span>
            </button>
          </div>
        </div>
      </el-form>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { errormsg, ageLevels } from "@/utils/constants";
import _ from "lodash";

export default {
  name: "Grid-Settings-Tabs",
  props: {
    colors: Array,
    competitions: Array,
    grid: Object,
    gridRefresh: Boolean,
    search: Object,
    venues: Array,
  },
  components: {},
  data() {
    return {
      ageLevels,
      allVenues: this.venues,
      helper: {
        venueId: undefined,
        fieldNo: "",
      },
      helperRules: {
        fieldNo: {
          required: false,
          trigger: "blur",
          message: errormsg.input_option,
        },
        venueId: {
          required: true,
          trigger: "blur",
          message: errormsg.select_option,
        },
      },
      helperVenues: [],
      rerender: this.gridRefresh,
    };
  },
  computed: {
    activeSearches() {
      const queries = Object.keys(this.search);
      const queryAlert = queries.filter((q) => {
        return (
          this.search[q] &&
          q !== "dateRange" &&
          ((Array.isArray(this.search[q]) && this.search[q].length) ||
            (!Array.isArray(this.search[q]) && this.search[q]))
        );
      });
      return queryAlert.length;
    },
    compLabel() {
      return this.grid.competitions.length;
    },
  },
  methods: {
    addNewVenue() {
      this.$refs.helperForm.validate().then((val) => {
        if (!val) return;

        const venue = _.find(this.helperVenues, { _id: this.helper.venueId });
        if (!venue) return;

        if (!this.grid.items || !this.grid.groupsLvl1.length)
          return this.$customError("Cannot add venue to unscheduled day.");

        this.rerender = !this.rerender;
        const venueIndex = _.map(this.grid.groupsLvl1, "id").indexOf(`${venue._id}`);
        if (venueIndex >= 0) {
          // 1. venue already had in grid then we only need to update field no in case it is declared
          if (this.helper.fieldNo) {
            const parent = this.grid.groupsLvl1[venueIndex];
            const nestedId = `${parent.id}-${this.helper.fieldNo}`;
            const fieldsArr = parent.venueFields.map((f) => f.id);
            if (!fieldsArr.includes(nestedId))
              parent.venueFields.push({
                id: nestedId,
                treeLevel: 2,
                content: this.helper.fieldNo,
              });
          } else {
            const parent = this.grid.groupsLvl1[venueIndex];
            const nestedId = `${parent.id}-undefined`;
            const fieldsArr = parent.venueFields.map((f) => f.id);
            if (!fieldsArr.includes(nestedId))
              parent.venueFields.push({
                id: nestedId,
                treeLevel: 2,
                content: "NA",
              });
          }
        } else {
          const venueFields = [];
          // add field number and venue
          if (this.helper.fieldNo) {
            const nestedId = `${venue._id}-${this.helper.fieldNo}`;
            venueFields.push({
              id: nestedId,
              treeLevel: 2,
              content: this.helper.fieldNo,
            });
          } else {
            const nestedId = `${venue._id}-undefined`;
            venueFields.push({
              id: nestedId,
              treeLevel: 2,
              content: "NA",
            });
          }
          const existGroup = _.some(this.grid.groupsLvl1, { id: `${venue._id}` });
          if (!existGroup) {
            this.grid.groupsLvl1.push({
              id: `${venue._id}`,
              content: venue.name,
              treeLevel: 1,
              venueFields,
            });
          }
        }

        this.$emit("orderGroup");
        this.rerender = !this.rerender;
        setTimeout(() => {
          this.$emit("populateGrid", true);
        }, 500);

        this.helper.venueId = undefined;
        this.helper.fieldNo = undefined;

        this.$customSuccess();
      });
    },
    compColour(i) {
      return `border: 2px solid ${this.colors[i % this.colors.length][1]}`;
    },
    heightWatcher() {
      setTimeout(() => {
        const activeTab = document.querySelector(".tab-active");
        const adjustedHeight =
          activeTab && activeTab.scrollHeight ? activeTab.scrollHeight : undefined;
        if (!adjustedHeight) return;
        const tab = document.querySelector(".el-tabs__content");
        tab.style.maxHeight = `${adjustedHeight}px`;
        this.$emit("updatePanelHeight", adjustedHeight);
        const venueNav = document.querySelector(".headers.tw-sticky");
        const stickyMenu = document.querySelector(".sticky-menu");
        setTimeout(() => {
          venueNav.style.top = `${stickyMenu.scrollHeight + 2}px`;
        }, 300);
      }, 325);
    },
    // eslint-disable-next-line func-names
    onFilterVenue: _.debounce(function (query) {
      if (query.length >= 3) {
        this.allVenues = [];
        this.$http
          .post("/nrl/api/v1/admin/venues/search", { criteria: query })
          .then((response) => {
            this.allVenues = response.data.data;
          })
          .catch(() => {
            this.$customError();
          });
      }
    }, 500),
    // eslint-disable-next-line func-names
    onHelperFilterVenue: _.debounce(function (query) {
      if (query.length >= 3) {
        this.helperVenues = [];
        this.$http
          .post("/nrl/api/v1/admin/venues/search", { criteria: query })
          .then((response) => {
            this.helperVenues = response.data.data;
          })
          .catch(() => {
            this.$customError();
          });
      }
    }, 500),
    async searchMatches() {
      try {
        this.rerender = !this.rerender;
        const validSubmit = await this.$refs.searchForm.validate();
        if (!validSubmit) throw new Error("Form not complete");
        this.$store.commit("matchGrid/updateSearch", this.search);
        this.$store.commit("matchGrid/updateVenues", this.venues);

        this.$store.commit("root/loading", true);
        const { start, end } = this.grid.date;
        this.$emit("getMatches", [start, end]);
        setTimeout(() => {
          this.$store.commit("root/loading", false);
        }, 500);
      } catch (error) {
        this.$customError();
        this.$store.commit("root/loading", false);
      }
    },
  },
};
</script>
<style scoped>
.comp-badge {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 6px 2px;
  background: rgba(0, 219, 0, 0.15);
  border-radius: 4px;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
}

.competition-tags {
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  color: #333333;
  transition: color 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}
.competition-tags:hover {
  color: #222;
}

.match-grid-tabs {
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-radius: 0 0 8px 8px;
}

.search-form .el-select {
  width: 100%;
}
</style>
