var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      this.$store.getters['user/activeRole'].type === 1 ||
      this.$store.getters['user/activeRole'].type === 98 ||
      this.$store.getters['user/activeRole'].type === 99 ||
      this.$store.getters['user/activeRole'].type === 5 ||
      this.$store.getters['user/activeRole'].type === 105
    )?_c('el-button',{staticClass:"add-program-button",attrs:{"icon":"el-icon-plus","type":"success"},on:{"click":_vm.handleAddProgram}},[_vm._v(" Add Program ")]):_vm._e(),_c('el-row',[_c('el-row',{staticClass:"search-bar",attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":6}},[_c('el-input',{attrs:{"id":"filterSearch","autocomplete":"off","placeholder":"Filter search..."},model:{value:(_vm.filters[0].value),callback:function ($$v) {_vm.$set(_vm.filters[0], "value", $$v)},expression:"filters[0].value"}})],1),_c('el-col',{attrs:{"span":18}},[_c('el-button',{on:{"click":function($event){_vm.filterVisible = true}}},[_vm._v("Filters")]),_c('el-button',{staticStyle:{"float":"right"},attrs:{"disabled":_vm.showAll === false,"type":_vm.showAll === false ? 'error' : 'success',"icon":"el-icon-refresh-left"},on:{"click":_vm.applyFilters}},[_vm._v("Undo")]),_c('el-button',{staticStyle:{"float":"right"},attrs:{"disabled":_vm.showAll === true,"type":_vm.showAll === true ? 'error' : 'success'},on:{"click":_vm.clearFilters}},[_vm._v("Show All Programs")])],1)],1)],1),_c('el-dialog',{attrs:{"visible":_vm.filterVisible},on:{"update:visible":function($event){_vm.filterVisible=$event},"close":function($event){_vm.filterVisible = false}}},[_c('filter-page',{on:{"cancel":function($event){_vm.filterVisible = false},"submit":_vm.sendFilter,"clear":_vm.sendFilter}})],1),_c('data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"data-table",attrs:{"filters":_vm.filters,"data":_vm.tableData,"table-props":_vm.tableProps,"pagination-props":_vm.paginationProps,"page-size":_vm.pageSize},on:{"row-click":_vm.onRowClicked}},[_c('el-table-column',{attrs:{"fixed":"","prop":"settings.type","label":"Program Type","width":"auto"}}),_c('el-table-column',{attrs:{"fixed":"","prop":"name","label":"Program Name","width":"auto"}}),(
        this.$store.getters['user/activeRole'].type === 1 ||
        this.$store.getters['user/activeRole'].type === 2 ||
        this.$store.getters['user/activeRole'].type === 3 ||
        this.$store.getters['user/activeRole'].type === 4
      )?_c('el-table-column',{attrs:{"label":"Entity Name","width":"auto"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(_vm.entityName(scope.row)))]}}],null,false,2690696878)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"settings.duration","label":"No. of Sessions","width":"auto"}}),_c('el-table-column',{attrs:{"prop":"members","label":"Members","width":"auto"}}),_c('el-table-column',{attrs:{"prop":"venue.name","label":"Venue","width":"auto"}}),_c('el-table-column',{attrs:{"prop":"gdo.name","label":"GDO Contact Name","width":"auto"}}),_c('el-table-column',{attrs:{"label":"Start Date","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.formatDate(scope.row.startDate))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"Program Status","width":"auto"}}),(this.$store.getters['user/activeRole'].type === 1)?_c('el-table-column',{attrs:{"label":"Remove","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{on:{"click":function($event){return _vm.handleRemoveRow(scope.row, scope.$index)}}},[_vm._v("Remove")])]}}],null,false,3095417580)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }