<template>
  <div>
    <!-- Find and search for referee associations to request shared resources -->
    <el-alert
      title="Link referee associations to this competition"
      type="warning"
      :closable="false"
    >
    </el-alert>
    <el-form label-position="top" id="associationAllocation" ref="associationAllocation">
      <div
        id="search-row-association"
        class="tw-flex tw-flex-wrap tw-items-end tw-w-full tw-max-w-5xl tw-gap-4 tw-pt-4"
      >
        <div class="tw-w-full sm:tw-w-auto">
          <el-form-item label="Select a Referee Association">
            <el-select
              filterable
              @change="updateSelectedAssociation"
              v-model="selectedRefereeAssociation._id"
              placeholder="Select an association"
            >
              <el-option
                v-for="item in refereeAssociations"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="tw-flex tw-justify-start tw-flex-1 sm:tw-pr-3 tw-align-middle">
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <el-button
              name="success"
              id="success"
              type="success"
              @click="requestRefereeAssociation()"
              >Request Association</el-button
            >
          </div>
        </div>
      </div>
    </el-form>

    <el-row>
      <h4 class="mt-2 tw-font-bold">Previously Requested Referee Associations</h4>
      <data-tables
        :data="requestedAssociations"
        :table-props="tableProps"
        layout="table"
        class="data-table"
      >
        <el-table-column fixed prop="associationId" label="Association Id"> </el-table-column>
        <el-table-column prop="associationName" label="Association Name"> </el-table-column>
        <el-table-column prop="createdBy" label="Requested By (User Id)"></el-table-column>
        <el-table-column prop="createdAt" :formatter="getFormattedDate" label="Requested At">
        </el-table-column>
        <el-table-column prop="status" label="Status"> </el-table-column>
      </data-tables>
    </el-row>

    <el-form label-position="top" id="clubAllocation" ref="clubAllocation">
      <div
        id="search-row-club"
        class="mt-2 tw-flex tw-flex-wrap tw-items-end tw-w-full tw-max-w-5xl tw-gap-4 tw-pt-4"
      >
        <div class="tw-w-full sm:tw-w-auto">
          <el-form-item label="Select a Referee Club">
            <el-select
              filterable
              @change="updateSelectedClub"
              v-model="selectedRefereeClub._id"
              placeholder="Select a Club"
            >
              <el-option
                v-for="item in refereeClubs"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="tw-flex tw-justify-start tw-flex-1 sm:tw-pr-3 tw-align-middle">
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <el-button name="success" id="success" type="success" @click="requestRefereeClub()"
              >Request Club</el-button
            >
          </div>
        </div>
      </div>
    </el-form>

    <el-row>
      <h4 class="mt-2 tw-font-bold">Previously Requested Clubs</h4>
      <data-tables
        :data="requestedClubs"
        :table-props="tableProps"
        layout="table"
        class="data-table"
      >
        <el-table-column fixed prop="clubId" label="Club Id"> </el-table-column>
        <el-table-column prop="clubName" label="Club Name"> </el-table-column>
        <el-table-column prop="createdBy" label="Requested By (User Id)"></el-table-column>
        <el-table-column prop="createdAt" :formatter="getFormattedDate" label="Requested At">
        </el-table-column>
        <el-table-column prop="status" label="Status"> </el-table-column>
      </data-tables>
    </el-row>
    <action-buttons cancel @submit="updatePlayerPoints()" @cancel="cancelForm()" />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";

export default {
  components: { ActionButtons },
  props: {
    comp: { type: Object, required: true },
  },
  methods: {
    getFormattedDate(row) {
      return this.moment(row.createdAt).format("DD-MM-YYYY");
    },
    cancelForm() {
      this.$router.push({
        name: "competitions.list",
      });
    },
    async getRequestedAssociations() {
      const url = "/nrl/api/v1/admin/referee/associations/requested";
      try {
        const response = await this.$http.get(url);
        return response.data.data;
      } catch (err) {
        console.log(err);
        this.$message({
          message: "Error getting requested requests",
          type: "error",
        });
      }
    },
    async getRequestedClubs() {
      const url = "/nrl/api/v1/admin/referee/clubs/requested";
      try {
        const response = await this.$http.get(url);
        return response.data.data;
      } catch (err) {
        console.log(err);
        this.$message({
          message: "Error getting requested requests",
          type: "error",
        });
      }
    },
    updateSelectedAssociation() {},
    async requestRefereeAssociation() {
      this.$store.commit("root/loading", true);
      const associationId = this.selectedRefereeAssociation._id;
      const url = `/nrl/api/v1/admin/referee/associations/request/${associationId}`;

      try {
        const response = await this.$http.post(url, {
          competitionId: this.comp._id,
        });
        if (response) {
          this.$message({
            message: "Referee association requested",
            type: "success",
          });
          this.requestedAssociations = await this.getRequestedAssociations();
          this.requestedAssociations = this.requestedAssociations.filter(
            (requestedAssociation) => requestedAssociation.requestedCompetitionId === this.comp._id
          );
        }
      } catch (err) {
        console.log(err);
        this.$message({
          message: "Error requesting referee association",
          type: "error",
        });
      }
      this.$store.commit("root/loading", false);
    },
    async requestRefereeClub() {
      this.$store.commit("root/loading", true);
      const clubId = this.selectedRefereeClub._id;
      const url = `/nrl/api/v1/admin/referee/clubs/request/${clubId}`;

      try {
        const response = await this.$http.post(url, {
          competitionId: this.comp._id,
        });
        if (response) {
          this.$message({
            message: "Referee club requested",
            type: "success",
          });
          this.requestedClubs = await this.getRequestedClubs();
          this.requestedClubs = this.requestedClubs.filter(
            (requestedClub) => requestedClub.requestedCompetitionId === this.comp._id
          );
        }
      } catch (err) {
        console.log(err);
        this.$message({
          message: "Error requesting referee club",
          type: "error",
        });
      }
      this.$store.commit("root/loading", false);
    },
    updateSelectedClubs() {},
  },
  data() {
    return {
      refereeAssociations: [],
      selectedRefereeAssociation: {},
      requestedAssociations: [],
      tableData: [],
      refereeClubs: [],
      selectedRefereeClub: {},
      requestedClubs: [],
      tableProps: {
        border: true,
      },
    };
  },
  async mounted() {
    try {
      this.requestedAssociations = await this.getRequestedAssociations();
      this.requestedAssociations = this.requestedAssociations.filter(
        (requestedAssociation) => requestedAssociation.requestedCompetitionId === this.comp._id
      );
      this.requestedClubs = await this.getRequestedClubs();
      this.requestedClubs = this.requestedClubs.filter(
        (requestedClub) => requestedClub.requestedCompetitionId === this.comp._id
      );
      const [refereeAssociationsResponse, refereeClubsResponse] = await Promise.all([
        this.$http.get("/nrl/api/v1/admin/referee/associations"),
        this.$http.get("/nrl/api/v1/admin/referee/clubs"),
      ]);

      if (this.requestedAssociations && this.requestedAssociations.length > 0) {
        this.refereeAssociations = refereeAssociationsResponse.data.data.filter((association) => {
          return !this.requestedAssociations.some((requestedAssociation) => {
            return (
              requestedAssociation.associationId === association._id &&
              this.comp._id === requestedAssociation.requestedCompetitionId
            );
          });
        });
      } else {
        this.refereeAssociations = refereeAssociationsResponse.data?.data;
      }

      if (this.requestedClubs && this.requestedClubs.length > 0) {
        this.refereeClubs = refereeClubsResponse.data.data.filter((club) => {
          return !this.requestedClubs.some((requestedClub) => {
            return (
              requestedClub.clubId === club._id &&
              this.comp._id === requestedClub.requestedCompetitionId
            );
          });
        });
      } else {
        this.refereeClubs = refereeClubsResponse.data?.data;
      }
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    competition() {
      return {
        ...this.comp,
        teams: this.comp.teams.map((team) => ({
          ...team,
          maxPlayerPoints: team.maxPlayerPoints || 0,
        })),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.display-form {
  margin-top: 0.45rem;
}

.el-input,
.el-select {
  width: 100%;
}
</style>
