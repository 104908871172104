<template>
  <div id="memberList">
    <el-form :model="search" @submit.native.prevent ref="memberSearch">
      <!-- legacy -->
      <!-- <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="6">
          <el-form-item prop="firstName" label="Search by name">
            <el-input
              autocomplete="off"
              type="text"
              @keyup.native.enter="findMember('name')"
              placeholder="First name"
              v-model="search.firstName"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="lastName">
            <el-input
              autocomplete="off"
              type="text"
              @keyup.native.enter="findMember('name')"
              placeholder="Last name"
              v-model="search.lastName"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="dob" label="Search by DOB">
            <el-date-picker
              @keyup.native.enter="findMember('name')"
              type="date"
              v-model="search.dob"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button @click="findMember('name')" type="success">Search</el-button>
            <el-button @click="clear('name')" type="default">Clear</el-button>
          </el-form-item>
        </el-col>
      </el-row> -->

      <div
        id="search-row"
        class="tw-grid tw-w-full tw-max-w-5xl tw-grid-cols-2 tw-gap-2 tw-pb-8 tw-border-b tw-border-gray-200 xl:tw-grid-cols-5"
      >
        <div class="tw-flex tw-flex-wrap tw-items-end tw-w-full tw-col-span-2 tw-gap-4 ">
          <div class="tw-flex-1 tw-w-full sm:tw-w-auto">
            <el-form-item prop="firstName" label="Search by name">
              <el-input
                autocomplete="off"
                type="text"
                @keyup.native.enter="findMember('name')"
                placeholder="First name"
                v-model="search.firstName"
              />
            </el-form-item>
          </div>
          <div class="tw-flex-1 tw-w-full sm:tw-w-auto">
            <el-form-item prop="lastName" class="">
              <el-input
                autocomplete="off"
                type="text"
                @keyup.native.enter="findMember('name')"
                placeholder="Last name"
                v-model="search.lastName"
              />
            </el-form-item>
          </div>
        </div>
        <div class="tw-flex tw-flex-wrap tw-items-end tw-w-full tw-col-span-3 tw-gap-4">
          <div class="tw-w-full sm:tw-w-auto">
            <el-form-item prop="dob" label="Search by DOB">
              <el-date-picker
                class="datewidth"
                @keyup.native.enter="findMember('name')"
                type="date"
                v-model="search.dob"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </div>
          <div class="tw-flex tw-justify-end tw-flex-1 sm:tw-pr-3">
            <div class="tw-flex tw-flex-wrap tw-gap-2">
              <button
                class="tw-w-32 tw-py-3 tw-text-sm tw-font-medium tw-leading-3 tw-tracking-widest tw-text-white tw-uppercase tw-bg-green-700 tw-rounded tw-px-auto hover:tw-bg-green-300 hover:tw-text-black"
                @click="findMember('name')"
                type="success"
              >
                Search
              </button>
              <button
                class="tw-w-32 tw-py-3 tw-text-sm tw-font-medium tw-leading-3 tw-tracking-widest tw-text-black tw-uppercase tw-bg-white tw-border tw-border-black tw-rounded tw-px-auto hover:tw-bg-gray-300 hover:tw-border-gray-300"
                @click="clear('name')"
                type="default"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="search-row-email"
        class="tw-flex tw-flex-wrap tw-items-end tw-w-full tw-max-w-5xl tw-gap-4 tw-pt-4 "
      >
        <div class="tw-w-full sm:tw-w-auto">
          <el-form-item prop="mobile" label="Search by NRLID / Mobile / Email">
            <el-input
              class="datewidth"
              autocomplete="off"
              type="text"
              @keyup.native.enter="findMember('mobile-email-nrlid')"
              placeholder="NRLID/Mobile/Email"
              v-model="search.generic"
            />
          </el-form-item>
        </div>
        <div class="tw-flex tw-justify-end tw-flex-1 sm:tw-pr-3">
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <button
              class="tw-w-32 tw-py-3 tw-text-sm tw-font-medium tw-leading-3 tw-tracking-widest tw-text-white tw-uppercase tw-bg-green-700 tw-rounded tw-px-auto hover:tw-bg-green-300 hover:tw-text-black"
              @click="findMember('mobile-email-nrlid')"
              type="success"
            >
              Search
            </button>
            <button
              class="tw-w-32 tw-py-3 tw-text-sm tw-font-medium tw-leading-3 tw-tracking-widest tw-text-black tw-uppercase tw-bg-white tw-border tw-border-black tw-rounded tw-px-auto hover:tw-bg-gray-300 hover:tw-border-gray-300"
              @click="clear('mobile-email-nrlid')"
              type="default"
            >
              Clear
            </button>
          </div>
        </div>

        <!-- <div>
          <div class="tw-flex tw-flex-wrap ">
            <el-button @click="findMember('mobile-email-nrlid')" type="success">Search</el-button>
            <el-button @click="clear('mobile-email-nrlid')" type="default">Clear</el-button>
          </div>
        </div> -->
      </div>

      <!-- legacy element ui -->
      <!-- <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="6">
          <el-form-item prop="mobile" label="Search by NRLID / Mobile / Email">
            <el-input
              autocomplete="off"
              type="text"
              @keyup.native.enter="findMember('mobile-email-nrlid')"
              placeholder="NRLID/Mobile/Email"
              v-model="search.generic"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button @click="findMember('mobile-email-nrlid')" type="success">Search</el-button>
            <el-button @click="clear('mobile-email-nrlid')" type="default">Clear</el-button>
          </el-form-item>
        </el-col>
      </el-row> -->
    </el-form>
    <div
      class="tw-flex tw-flex-col tw-max-w-5xl tw-gap-4 tw-py-4 tw-mt-8 tw-border-t sm:tw-flex-row sm:tw-pr-3"
      v-if="isClubAdmin || isAssociationAdmin"
    >
      <div>
        <el-input
          class="tw-w-full sm:tw-w-64"
          id="filterSearch"
          autocomplete="off"
          v-model="filters[0].value"
          placeholder="Filter search..."
        >
        </el-input>
      </div>
      <div class="tw-flex tw-justify-end tw-flex-1">
        <button
          class="tw-w-32 tw-py-3 tw-text-sm tw-font-medium tw-leading-3 tw-tracking-widest tw-text-black tw-uppercase tw-bg-white tw-border tw-border-black tw-rounded tw-px-auto hover:tw-bg-gray-300 hover:tw-border-gray-300"
          @click="filterVisible = true"
          type="default"
        >
          Filters
        </button>
      </div>
    </div>

    <!-- <el-row
      type="flex"
      align="bottom"
      :gutter="10"
      class="search-bar"
      v-if="isClubAdmin || isAssociationAdmin"
    >
      <el-col :span="6">
        <el-input
          id="filterSearch"
          autocomplete="off"
          v-model="filters[0].value"
          placeholder="Search..."
        >
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-button @click="filterVisible = true"> Filters </el-button>
      </el-col>
    </el-row> -->

    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>

    <el-row id="member">
      <data-tables
        :filters="filters"
        :data="tableData"
        :table-props="tableProps"
        :pagination-props="paginationProps"
        :page-size="pageSize"
        @row-click="handleRowClick"
        class="data-table"
      >
        <el-table-column fixed id="memberId" prop="_id" label="Id" width="100"> </el-table-column>
        <el-table-column
          id="firstName"
          fixed
          prop="profile.firstName"
          label="First Name"
          width="130"
        >
        </el-table-column>
        <el-table-column fixed prop="profile.lastName" label="Last Name" width="130">
        </el-table-column>
        <el-table-column prop="profile.gender" label="Gender" width="100"> </el-table-column>
        <el-table-column :formatter="ageFormatter" prop="profile.dob" label="Age" width="120">
        </el-table-column>
        <el-table-column :formatter="dobFormatter" prop="profile.dob" label="DOB" width="150">
        </el-table-column>
        <el-table-column prop="profile.email" label="Email" width="auto"> </el-table-column>
        <el-table-column :formatter="createdAtFormatter" label="Last Registration Date" width="150">
        </el-table-column>
        <el-table-column
          :formatter="memberTypeFormatter"
          prop="memberTypes"
          label="Member Type"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="regoFinancial"
          :formatter="(r, c, v) => (v ? 'Yes' : 'No')"
          label="Registration Financial"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="verification.pending"
          :formatter="pendingFormatter"
          label="Pending"
          width="100"
        >
        </el-table-column>
      </data-tables>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import { calculateAge } from "../../utils/utilities";
import { roles, memberTypes } from "../../utils/constants";
import FilterPage from "./FilterPage.vue";

const dobToAge = member => {
  if (member) {
    const age = calculateAge(member.profile.dob, true);
    return age;
  }
  return "N/A";
};

export default {
  name: "MemberManagement",
  props: {},
  components: {
    FilterPage
  },
  data() {
    return {
      filterVisible: false,
      search: {
        nrlid: "",
        firstName: "",
        lastName: "",
        dob: null,
        email: "",
        mobile: "",
        generic: ""
      },
      pageSize: 50,
      tableProps: {
        border: true
      },
      paginationProps: {
        pageSizes: [50]
      },
      filters: [
        {
          value: "",
          filterFn: (row, filter) =>
            Object.keys(row).some(prop => {
              if (!filter.value || filter.value === "") return false;
              if (prop === "profile") {
                const { firstName, lastName, email } = row[prop];
                const firstNameFilter = firstName || "";
                const lastNameFilter = lastName || "";
                const emailFilter = email || "";
                const filterRegex = new RegExp(_.escapeRegExp(filter.value), "i");
                return (
                  firstNameFilter.match(filterRegex) ||
                  lastNameFilter.match(filterRegex) ||
                  emailFilter.match(filterRegex)
                );
              }
              return false;
            })
        },
        {
          value: []
        }
      ],
      tableData: []
    };
  },
  mounted() {
    const dataStore = this.fetchStore();
    // If the user is club admin or an association get all club members and put them in the tableData
    if (!dataStore && (this.isClubAdmin || this.isAssociationAdmin)) {
      this.$store.commit("root/loading", true);
      this.$http
        .get("/nrl/api/v1/admin/members/my-members")
        .then(memberRes => {
          this.tableData = _.orderBy(
            memberRes.data.data,
            [user => user.profile.lastName.toLowerCase()],
            ["profile.lastName"],
            ["asc"]
          );
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    }
  },
  methods: {
    fetchStore() {
      const {
        data,
        search: { type, firstName, lastName, generic }
      } = this.$store.state.members;
      if (data.length) {
        this.tableData = data;
        if (type === "name") _.assign(this.search, { firstName, lastName });
        else _.assign(this.search, { generic });
        return true;
      }
      return false;
    },
    memberTypeFormatter(row) {
      // get member type name
      return (
        row.memberTypes &&
        (row.memberTypes
          .map(mt => {
            const memberType = _.find(memberTypes, m => m.type === mt);
            return (memberType && memberType.name) || "";
          })
          .join(", ") ||
          "N/A")
      );
    },
    pendingFormatter(row) {
      return row && row.verification && row.verification.pending ? "Yes" : "No";
    },
    handleRowClick(row) {
      this.$router.push({
        name: "members.update",
        params: {
          type: "update",
          id: row._id
        }
      });
    },
    ageFormatter(row) {
      return row && row.profile && row.profile.dob ? dobToAge(row) : "N/A";
    },
    dobFormatter(row) {
      // row.profile.dob format is YYYY-MM-DD
      const splits = row.profile.dob.split("-"); // [YYYY, MM, DD]
      if (splits.length !== 3) return "";
      return `${splits[2]}-${splits[1]}-${splits[0]}`; // DD-MM-YYYY
    },
    reducer(accumulator, currentValue) {
      return currentValue.createdAt > accumulator.createdAt ? currentValue : accumulator;
    },

    createdAtFormatter(row) {
      if (!row.lastRegistration || !row.lastRegistration.createdAt) return "";
      const latest = row.lastRegistration;
      return latest.createdAt
        ? this.moment(latest.createdAt).format("MMMM Do YYYY, h:mm:ss a")
        : "NA";
    },
    sendFilter(event) {
      this.$store.commit("root/loading", true);
      const {
        ageLvl: ageArray,
        gender: genderArray,
        memberType: memberTypeArray,
        regoFinancial: regoFinancialArray,
        lastRegistrationDateOrder: lastRegistrationDateOrderArray,
      } = event;
      const ageLevel = ageArray; // ageArray.length > 0 ? ageArray[0] : null;
      const gender = genderArray.length > 0 ? genderArray[0] : null;
      const memberType = memberTypeArray; // memberTypeArray.length > 0 ? memberTypeArray[0] : null;
      const regoFinancial =
        regoFinancialArray && regoFinancialArray.length > 0 ? regoFinancialArray[0] : null;
      const lastRegistrationDateOrder =
        lastRegistrationDateOrderArray && lastRegistrationDateOrderArray.length > 0 ? lastRegistrationDateOrderArray[0] : {name: 'Ascending', type: 'asc'};
      const params = {
        ageLevel,
        gender,
        memberType,
        regoFinancial,
        lastRegistrationDateOrder
      };

      this.$store.commit("members/updateFilters", params);

      this.$http
        .get("/nrl/api/v1/admin/members/my-members", { params })
        .then(response => {
          this.tableData = response.data.data;
          this.$store.commit("root/loading", false);
          this.filterVisible = false;
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
          this.filterVisible = false;
        });
    },
    clear(type) {
      switch (type) {
        case "name":
          this.search.firstName = "";
          this.search.lastName = "";
          this.search.dob = null;
          break;
        case "mobile-email-nrlid":
          this.search.generic = "";
          break;
        default:
          break;
      }
      this.tableData = [];
      // pull mymembers if criteria met
      if (this.isClubAdmin || this.isAssociationAdmin) {
        this.$store.commit("root/loading", true);
        this.$http
          .get("/nrl/api/v1/admin/members/my-members")
          .then(memberRes => {
            this.tableData = _.orderBy(memberRes.data.data, ["profile.lastName"], ["asc"]);
            this.$store.commit("root/loading", false);
          })
          .catch(() => {
            this.$customError();
            this.$store.commit("root/loading", false);
          });
      }
      // reset store
      this.$store.commit("members/reset");
    },
    findMember(type) {
      new Promise((resolve, reject) => {
        this.$refs.memberSearch.validate(validated => (validated ? resolve() : reject()));
      })
        .then(() => {
          this.$store.commit("app/loading", true);
          let query;
          const { firstName, lastName, generic, dob } = this.search;
          switch (type) {
            case "name":
              query = { firstName, lastName };
              if (dob) query.dob = dob;
              break;
            case "mobile-email-nrlid":
              query = { generic };
              break;
            default:
              break;
          }
          if (type === "name") {
            if (firstName.length < 2 && lastName.length < 2 && !dob) {
              this.$store.commit("app/loading", false);
              return;
            }
          }

          // store query values
          this.$store.commit("members/updateSearch", { ...query, type });

          this.filters[0].value = "";
          this.$http
            .post("/nrl/api/v1/admin/members/search", query)
            .then(response => {
              this.tableData = _.orderBy(response.data.data, ["profile.lastName"], ["asc"]);
              this.$store.commit("app/loading", false);
            })
            .catch(e => {
              this.$store.commit("app/loading", false);
              this.$customError(
                e.response && e.response.data
                  ? e.response.data.message
                  : `Error searching for members ${e}`
              );
            });
        })
        .catch(() => {});
    }
  },
  computed: {
    isClubAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.clubAdmin;
    },
    isSuperAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.superAdmin;
    },
    isStateAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.stateAdmin;
    },
    isAssociationAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.associationAdmin;
    }
  },
  watch: {
    tableData(val) {
      this.$store.commit("members/updateData", val);
    }
  }
};
</script>

<style lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}

.el-form-item {
  margin-bottom: 0px !important;
}
::v-deep .el-input {
  width: 100% !important;
  margin-bottom: 0px !important;
}
::v-deep .el-input__inner {
  width: 100% !important;
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 768px) {
  .datewidth {
    min-width: 256px !important;
    width: 100% !important;
  }
}
@media only screen and (min-width: 768px) {
  .datewidth {
    width: 256px !important;
  }
}
</style>
