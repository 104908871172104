<template>
  <div>
    <!-- MEMBER LOOKUP -->
    <el-row v-if="!currentMember" :gutter="10">
      <el-form :model="searchUser" @submit.native.prevent ref="userSearch">
        <el-row type="flex" align="bottom" :gutter="10">
          <el-col :span="6">
            <el-form-item prop="firstName" label="Search by name">
              <el-input
                autocomplete="off"
                type="text"
                @keyup.native.enter="findMember('name')"
                placeholder="First name"
                v-model="searchUser.firstName"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="lastName">
              <el-input
                type="text"
                @keyup.native.enter="findMember('name')"
                placeholder="Last name"
                v-model="searchUser.lastName"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button @click="findMember('name')" type="success">Search</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" align="bottom" :gutter="10">
          <el-col :span="6" class="expandOptions">
            <div v-on:click.self="showSearchOptions = !showSearchOptions" class="cursor">
              {{ showSearchOptions ? "Less search options" : "More search options" }}
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="bottom" :gutter="10" v-if="showSearchOptions">
          <el-col :span="6">
            <el-form-item prop="nrlid" label="Search by NRLID">
              <el-input
                autocomplete="off"
                type="number"
                @keyup.native.enter="findMember('id')"
                placeholder="NRLID"
                v-model.number="searchUser.nrlid"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button @click="findMember('id')" type="success">Search</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" align="bottom" :gutter="10" v-if="showSearchOptions">
          <el-col :span="6">
            <el-form-item prop="mobile" label="Search by Mobile Number">
              <el-input
                autocomplete="off"
                type="text"
                @keyup.native.enter="findMember('mobile')"
                placeholder="Mobile number"
                v-model="searchUser.mobile"
              />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button @click="findMember('mobile')" type="success">Search</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="email" label="Search by Email Address">
              <el-input
                autocomplete="off"
                type="text"
                @keyup.native.enter="findMember('email')"
                placeholder="Email address"
                v-model="searchUser.email"
              />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button @click="findMember('email')" type="success">Search</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>

    <el-row v-if="!currentMember">
      <data-tables
        :data="tableData"
        :table-props="tableProps"
        layout="table"
        @row-click="handleRowClick"
        class="data-table"
      >
        <el-table-column fixed prop="_id" label="Id"> </el-table-column>
        <el-table-column prop="profile.firstName" label="First Name"> </el-table-column>
        <el-table-column prop="profile.lastName" label="Last Name"> </el-table-column>
        <el-table-column prop="profile.dob" label="DOB"> </el-table-column>
      </data-tables>
    </el-row>

    <!-- CLEARANCE/PERMIT REQUEST -->
    <el-row v-if="currentMember">
      <el-row class="go-back">
        <el-button @click="back" icon="el-icon-arrow-left" class="fl-left"> Go Back </el-button>
        <el-button @click="submitRequest" type="success" icon="el-icon-plus" class="fl-right">
          Request
        </el-button>
      </el-row>
    </el-row>

    <el-row v-if="currentMember">
      <el-card class="box-card">
        <h4>Request Details</h4>
        <h5>{{ `${currentMember.profile.firstName} ${currentMember.profile.lastName}` }}</h5>
        <h5>{{ currentMember.profile.dob }}</h5>
        <!-- <h5>{{ currentMember.profile.email }}</h5> -->
        <br />
        <h5 v-if="currentMember.defaulter" class="red">Defaulter: Yes</h5>
        <h5
          v-if="currentMember.profile.meta && currentMember.profile.meta.developmentFee"
          class="red"
        >
          Development fees may apply
        </h5>

        <el-row :gutter="10">
          <el-col :span="12">
            <h4>From Entity Details</h4>
            <h5>Club: {{ fromEntity.club }}</h5>
            <h5>Association: {{ fromEntity.association }}</h5>
          </el-col>
          <el-col :span="12">
            <h4>To Entity Details (Current Active Role)</h4>
            <h5>{{ `Club: ${toClubName}` }}</h5>
          </el-col>
        </el-row>
      </el-card>
    </el-row>

    <el-form
      v-if="currentMember"
      label-position="top"
      :model="request"
      :rules="requestRules"
      @submit.native.prevent
      ref="submitRequest"
    >
      <el-row class="req-card" :gutter="10">
        <el-col :span="8">
          <el-form-item prop="type" label="Type">
            <el-select v-model="request.type" placeholder="Select type">
              <el-option
                v-for="item in types"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item v-if="request.type === 1" prop="startDate" label="Start Date">
            <el-date-picker
              format="dd/MM/yyyy"
              value-format="timestamp"
              v-model="request.startDate"
              type="date"
              placeholder="Select an START date"
              :picker-options="validDates"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item v-if="request.type === 1" prop="endDate" label="End Date">
            <el-date-picker
              format="dd/MM/yyyy"
              value-format="timestamp"
              v-model="request.endDate"
              type="date"
              placeholder="Select an END date"
              :picker-options="validDates"
              @change="setToEndOfDay"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="req-card" :gutter="10">
        <el-col :span="8">
          <el-form-item prop="memberType" label="Member Type">
            <el-select v-model="request.memberType" placeholder="Select type">
              <el-option
                v-for="item in filteredMemberTypes"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="reason" label="Reason">
            <el-input
              autocomplete="off"
              type="textarea"
              :rows="4"
              placeholder="Please input a reason"
              v-model="request.reason"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg, roles, memberTypes } from "../../utils/constants";

export default {
  name: "ClearancePermitRequest",
  props: {},
  components: {},
  data() {
    /* --- START CUSTOM VALIDATORS --- */
    const nrlIdValidator = (__, value, callback) => {
      if (!parseInt(value) || parseInt(value) < 0) {
        return callback(new Error(""));
      }
      return callback();
    };
    const startDateValidator = (__, value, callback) => {
      if (
        this.request.type === 1 &&
        (!parseInt(this.request.startDate) || parseInt(this.request.startDate) < 0)
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const endDateValidator = (__, value, callback) => {
      if (
        this.request.type === 1 &&
        (!parseInt(this.request.endDate) || parseInt(this.request.endDate) < 0)
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    /* --- END CUSTOM VALIDATORS --- */
    return {
      searchUser: {
        nrlid: "",
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
      },
      rules: {
        nrlid: {
          type: "number",
          validator: nrlIdValidator,
          required: true,
          message: errormsg.nrlid,
          trigger: "blur",
        },
      },
      currentMember: false,
      showSearchOptions: false,
      tableProps: {
        border: true,
      },
      tableData: [],
      types: [
        {
          type: 1,
          name: "Permit",
        },
        {
          type: 2,
          name: "Clearance",
        },
      ],
      fromEntity: { club: "", association: "" },
      request: {
        type: 2,
        memberType: "",
        startDate: undefined,
        endDate: undefined,
        reason: "",
      },
      requestRules: {
        type: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
        memberType: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
        startDate: {
          required: true,
          validator: startDateValidator,
          message: errormsg.input_option,
          trigger: "blur",
        },
        endDate: {
          required: true,
          validator: endDateValidator,
          message: errormsg.input_option,
          trigger: "blur",
        },
        reason: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
      },
      validDates: {
        disabledDate(time) {
          let disabled = false;
          const now = new Date();
          const currentMonth = now.getMonth();
          const currentSeason = currentMonth < 11 ? now.getFullYear() : now.getFullYear() + 1;
          const currentYear = now.getFullYear();
          const yesterday = new Date(currentYear, now.getMonth(), now.getDate());
          const seasonEnd = new Date(currentSeason, 11, 1);
          if (yesterday.getTime() > time.getTime()) disabled = true;
          if (time.getTime() >= seasonEnd.getTime()) disabled = true;

          return disabled;
        },
      },
    };
  },
  mounted() {
    // Active Role Checker
    if (this.$store.getters["user/activeRole"].type !== roles.clubAdmin) {
      this.$router.push("/");
    }
  },
  methods: {
    findMember(type) {
      new Promise((resolve, reject) => {
        this.$refs.userSearch.validate((validated) => (validated ? resolve() : reject()));
      })
        .then(() => {
          this.$store.commit("root/loading", true);
          let query;
          const { firstName, lastName, email, mobile, nrlid } = this.searchUser;
          switch (type) {
            case "name":
              query = { firstName, lastName };
              break;
            case "mobile":
              query = { mobile };
              break;
            case "email":
              query = { email };
              break;
            case "id":
              query = { nrlid };
              break;
            default:
              break;
          }
          if (type === "name") {
            if (firstName.length < 2 && lastName.length < 2) {
              this.$store.commit("root/loading", false);
              return;
            }
          }
          this.$http
            .post("/nrl/api/v1/admin/clearance-permits/search-member", query)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.tableData = response.data.data;
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(_.get(error, "response.data.message"));
            });
        })
        .catch(() => {});
    },
    handleRowClick(row) {
      const selectedUser = this.tableData.find((user) => user._id === row._id);
      this.currentMember = selectedUser;
    },
    back() {
      this.currentMember = false;
    },
    setToEndOfDay(value) {
      const endDate = new Date(value);
      const endOfDay = endDate.setHours(23, 59, 59, 999);
      this.request.endDate = endOfDay;
    },
    submitRequest() {
      new Promise((resolve, reject) => {
        this.$refs.submitRequest.validate((validated) => (validated ? resolve() : reject()));
      })
        .then(() => {
          this.$store.commit("app/loading", true);
          let url = "/nrl/api/v1/admin/clearance-permits/permits";
          if (this.request.type === 2) url = "/nrl/api/v1/admin/clearance-permits/clearances";
          // eslint-disable-next-line prefer-const
          let req = this.request;
          req.memberId = this.currentMember._id;
          this.$http
            .post(url, this.request)
            .then(() => {
              this.$store.commit("app/loading", false);
              this.$store.commit("clearancePermit/reset");
              this.$router.push({ name: "clearance-permit.list" });
            })
            .catch((error) => {
              this.$store.commit("app/loading", false);
              this.$customError(_.get(error, "response.data.message"));
            });
        })
        .catch(() => {});
    },
  },
  computed: {
    filteredMemberTypes() {
      return memberTypes.filter(
        (m) => m.type === "player" || m.type === "player-league-tag" || m.type === "referee"
      );
    },
    currentMemberType() {
      return this.request.memberType;
    },
    toClubName() {
      const { entity } = this.$store.getters["user/activeRole"];
      return _.get(entity, "name");
    },
  },
  watch: {
    currentMemberType() {
      const { memberType } = this.request;
      const { regos, legacyRegos } = this.currentMember;
      if (memberType) {
        const fromEntity = regos.some((r) => r.rego)
          ? regos.find((rego) =>
              memberType === "referee"
                ? rego.rego.memberType === memberType
                : rego.rego.memberType === "player-league-tag" || rego.rego.memberType === "player"
            )
          : legacyRegos.some((r) => r.rego)
          ? legacyRegos.find((rego) =>
              memberType === "referee"
                ? rego.rego.memberType === memberType
                : rego.rego.memberType === "player-league-tag" || rego.rego.memberType === "player"
            )
          : null;

        if (fromEntity) {
          const { name: clubName, orgtree } = fromEntity.club;
          const { name: associationName } = orgtree.association;
          this.fromEntity = { club: clubName, association: associationName };
        } else {
          this.fromEntity = { club: null, association: null };
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-select,
.el-input {
  width: 100%;
}
.box-card {
  margin-top: 1.5rem;
}
.req-card {
  padding-top: 1.5rem;
}
.fl-left {
  float: left;
}
.fl-right {
  float: right;
}
h5 {
  margin-top: 0;
  margin-bottom: 5px;
}
.red {
  color: red;
}
.expandOptions {
  text-decoration: underline;
}
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
</style>
