<template>
  <el-form :model="regrading" label-position="top" ref="regrading">
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12">
        <el-form-item label="Source Competition">
          <el-input disabled type="text" v-model="regrading.sourceCompetition.name" />
        </el-form-item>
      </el-col>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12">
        <el-form-item label="Team to Regrade">
          <el-input disabled type="text" v-model="regrading.regradeTeam.name" />
        </el-form-item>
      </el-col>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12">
        <el-form-item label="Destination Competition">
          <el-input disabled type="text" v-model="regrading.destCompetition.name" />
        </el-form-item>
      </el-col>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12">
        <el-form-item label="Team Designation in Destination Competition">
          <div v-for="{ type, name } in designationOptions" :key="type">
            <el-radio :label="type" v-model="regrading.designationOption" disabled>{{
              name
            }}</el-radio>
          </div>
          <el-form-item prop="destTeamId">
            <el-input
              disabled
              type="text"
              v-model="regrading.replaceTeam.name"
              placeholder="Select team to replace from destination competition"
            />
          </el-form-item>
        </el-form-item>
      </el-col>
    </div>
    <div
      v-if="regrading.designationOption === 'new'"
      class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
    >
      <el-col :span="12">
        <el-form-item label="Destination Competition Matches">
          <div v-for="{ type, name } in destMatchOptions" :key="type">
            <el-radio :label="type" v-model="regrading.destMatchOption" disabled>{{
              name
            }}</el-radio>
          </div>
        </el-form-item>
      </el-col>
    </div>

    <div
      v-if="regrading.designationOption === 'new'"
      class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
    >
      <el-col :span="12">
        <el-form-item label="Source Competition Matches">
          <div v-for="{ type, name } in sourceMatchOptions" :key="type">
            <el-radio :label="type" v-model="regrading.sourceMatchOption" disabled>{{
              name
            }}</el-radio>
          </div>
        </el-form-item>
      </el-col>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12">
        <el-form-item label="Ladder Options">
          <div v-for="{ type, name } in ladderOptions" :key="type">
            <el-radio :label="type" v-model="regrading.ladderOption" disabled>{{ name }}</el-radio>
          </div>
        </el-form-item>
      </el-col>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12">
        <el-form-item label="Regraded By">
          <el-input disabled type="text" v-model="fullName" />
        </el-form-item>
      </el-col>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12">
        <el-form-item label="Regraded At">
          <el-input
            :formatter="dateFormatter"
            disabled
            type="text"
            :value="dateFormatter(regrading.createdAt)"
          />
        </el-form-item>
      </el-col>
    </div>
    <action-buttons cancel cancelText="Back" @cancel="cancelForm()" />
  </el-form>
</template>
<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { get } from "lodash";

export default {
  components: {
    ActionButtons
  },
  data() {
    return {
      regrading: {
        sourceCompetition: {},
        regradeTeam: {},
        destCompetition: {},
        replaceTeam: {},
        designationOption: "new",
        sourceMatchOption: "replace",
        destMatchOption: "replace",
        ladderOption: "nocopy",
        destDraw: [],
        sourceDraw: [],
        createdBy: {
          profile: {
            firstName: "",
            lastName: ""
          }
        },
        createdAt: 0
      },
      designationOptions: [
        {
          type: "new",
          name: "Create as a new team"
        },
        {
          type: "replace",
          name: "Replace an existing team"
        }
      ],
      sourceMatchOptions: [
        {
          type: "replace",
          name: "Replace migrated team with bye in future matches"
        },
        {
          type: "redo",
          name: "Redo draw for future matches"
        }
      ],
      destMatchOptions: [
        {
          type: "replace",
          name: "Replace future byes with re-graded team (If byes exist)"
        },
        {
          type: "redo",
          name: "Redo draw for future matches"
        }
      ],
      ladderOptions: [
        {
          type: "copyfull",
          name: "Copy full ladder details into Competition(s)"
        },
        {
          type: "copypoint",
          name: "Copy total ladder points only into Competition(s)"
        },
        {
          type: "nocopy",
          name: "Do not copy ladder details into Competition(s)"
        }
      ]
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { id } = this.$route.params;
    // load competitions
    this.$http
      .get(`/nrl/api/v1/admin/team-regradings/${id}`)
      .then(regrading => {
        this.regrading = Object.assign(this.regrading, regrading.data.data);
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  methods: {
    cancelForm() {
      this.$router.push({ name: "regrading.list" });
    },
    dateFormatter(data) {
      return data ? this.moment(data).format("MMMM Do YYYY, h:mm a") : "NA";
    }
  },
  computed: {
    fullName() {
      return `${get(this.regrading, "createdBy.profile.firstName", "")} ${get(
        this.regrading,
        "createdBy.profile.lastName",
        ""
      )}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
