<template>
  <div id="app">
    <component :is="layout" v-if="!isMobile">
      <loading-layout v-if="loading" :message="loadingMessage"></loading-layout>
      <router-view></router-view>
    </component>
    <UnsupportedScreen v-else />
  </div>
</template>

<script>
import UnsupportedScreen from "./components/UnsupportedScreen.vue";

const defaultLayout = "login";
export default {
  components: {
    UnsupportedScreen
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || defaultLayout}-layout`;
    },
    loading() {
      return this.$store.getters["root/rootLoading"];
    },
    loadingMessage() {
      return this.$store.getters["root/rootLoadingMessage"];
    },
    isMobile() {
      if (
        this.$mq !== "md" &&
        this.$mq !== "lg" &&
        !this.$store.getters["user/ignoreResponsiveWarning"]
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
html,
body,
#app {
  margin: 0;
  padding: 0;
  background-color: #f6f6f6;
}

.v-modal {
  z-index: 5 !important;
}

.text-center {
  text-align: center;
}

.el-drawer,
.ltr {
  background: $primary;
  width: 380px;
  @media (min-width: 640px) {
    width: 280px;
  }
  // width: 70% !important;
  .el-drawer__header {
    color: #fff !important;
    margin-bottom: 0px !important;
  }
}

.el-dialog__body {
  padding: 15px 20px;
}

.pagination-bar {
  margin-top: 1rem;
}

.el-tree-node__content {
  &:hover {
    background: none !important;
    cursor: default;
  }
  margin-bottom: 0.5rem;
}

#squad-management,
#teamAllocation {
  .el-transfer__button {
    width: 100% !important;
  }
  .el-checkbox {
    display: block;
  }

  .el-transfer-panel__item.el-checkbox .el-checkbox__label {
    overflow: hidden;
  }
  .el-transfer-panel {
    width: 24vw;
    text-align: left;
  }
}

#teamlist,
#np-teamlist,
#movements-table,
#compTypePositions {
  .el-pagination {
    display: none;
  }
}

#verificationNotes {
  min-height: 12rem !important;
}

.blue {
  color: blue !important;
}
.red {
  color: red;
}
.green {
  color: green;
}
.gray {
  color: gray;
}
.yellow {
  color: yellow !important;
}

.el-table .cell {
  word-break: normal;
}
.vdatetime-popup__header,
.vdatetime-calendar__month__day--selected > span > span {
  background: $nrlGreen !important;
}

.vdatetime-time-picker__item--selected {
  color: $nrlGreen !important;
}

.el-input__inner {
  font-size: 16px; // Fix for zooming issue on iOS
}

.el-loading-spinner {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
}

// .tail-select {
//   @apply tw-w-full tw-px-2 tw-text-sm tw-text-gray-800 tw-placeholder-gray-800 tw-bg-transparent tw-border-b-2 tw-border-gray-200 tw-appearance-none tw-h-9;
// }

// .tail-label {
//   @apply tw-text-10px tw-px-2 tw-leading-10px tw-font-normal tw-pt-2.5 tw-flex tw-justify-between tw-mb-2.5;
// }
</style>
