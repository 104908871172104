<template>
  <div>
    <el-row type="flex" align="middle">
      <el-col style="width: 100%" :span="24">
        <h4 class="tw-pt-6 tw-mt-6 tw-font-bold">Team Name - {{ team.name }}</h4>
        <h4 class="tw-pt-6 tw-mt-6 tw-font-bold">Last Updated - {{ modifiedAt }}</h4>
      </el-col>
    </el-row>
    <h4 class="tw-pt-6 tw-mt-6 tw-font-bold">Players</h4>
    <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12" class="add-player">
        <el-form label-position="top" :model="add" :rules="teamListRules" ref="teamListRules">
          <el-form-item prop="_id">
            <el-select v-model="add._id" placeholder="Player" filterable>
              <el-option
                v-for="(item, index) in playerList"
                :key="index"
                :value="item._id"
                :label="`${item.firstName} ${item.lastName}`"
                >{{ `${item.firstName} ${item.lastName}` }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="position">
            <el-select v-model="addPosition" placeholder="Position" value-key="name" filterable>
              <el-option
                v-for="(item, index) in positions"
                :key="index"
                :label="item.name"
                :value="item.number"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="shirtNum">
            <el-input autocomplete="off" v-model="add.shirtNum" placeholder="Shirt #"></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="add.isCaptain"> Captain </el-checkbox>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12" class="add-player-col">
        <el-button type="success" @click="handleAddPlayer"> Add Player </el-button>
      </el-col>
    </div>
    <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <data-tables
        v-if="defaultSquad.length > 0"
        :data="defaultSquad"
        :table-props="tableProps"
        :page-size="pageSize"
        layout="table"
        @row-click="removeFromPlayerTeamList"
        id="defaultSquad"
        class="data-table"
      >
        <el-table-column fixed prop="_id" label="Id" width="100"> </el-table-column>
        <el-table-column prop="firstName" label="First Name"> </el-table-column>
        <el-table-column prop="lastName" label="Last Name"> </el-table-column>
        <el-table-column prop="position.name" label="Position"> </el-table-column>
        <el-table-column prop="shirtNum" label="Shirt #"> </el-table-column>
        <el-table-column align="center" prop="isCaptain" label="Captain">
          <template slot-scope="scope">
            <i v-if="scope.row.isCaptain" class="el-icon-success captain-icon"></i>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="Actions">
          <template>
            <i class="el-icon-delete remove-icon"></i>
          </template>
        </el-table-column>
      </data-tables>
    </div>
    <h4 class="tw-font-bold">Non-Players</h4>

    <el-form
      class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      label-position="top"
      :model="addNP"
      :rules="npteamListRules"
      ref="npteamListRules"
    >
      <el-form-item prop="role">
        <el-select
          @change="clearNonplayer"
          v-model="addNP.role"
          placeholder="Role"
          value-key="name"
          clearable
          filterable
        >
          <el-option
            v-for="(item, index) in squadRoles"
            :key="index.type"
            :label="item.name"
            :value="item.type"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="_id">
        <el-select v-model="addNP._id" placeholder="Member" clearable filterable>
          <el-option
            v-for="(item, index) in nonPlayerList"
            :key="index"
            :value="item._id"
            :label="`${item.firstName} ${item.lastName}`"
            >{{ `${item.firstName} ${item.lastName}` }}
          </el-option>
        </el-select>
      </el-form-item>
      <el-button type="success" @click="handleAddNonPlayer"> Add Non Player </el-button>
    </el-form>

    <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <data-tables
        v-if="nonPlayerDefaultSquad"
        :data="nonPlayerDefaultSquad"
        :table-props="tableProps"
        :page-size="pageSize"
        @row-click="removeFromNonPlayerTeamList"
        id="np-defaultSquad"
        class="data-table"
      >
        <el-table-column fixed prop="_id" label="Id" width="100"> </el-table-column>
        <el-table-column prop="firstName" label="First Name"> </el-table-column>
        <el-table-column prop="lastName" label="Last Name"> </el-table-column>
        <el-table-column prop="role" label="Role">
          <template slot-scope="scope">
            {{ squadRoles.find((role) => role.type === scope.row.role).name }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="Actions">
          <template>
            <i class="el-icon-delete remove-icon"></i>
          </template>
        </el-table-column>
      </data-tables>
    </div>
    <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col style="width: 100%" :span="6" class="add-non-player"> </el-col>
      <el-col :span="12">
        <el-button class="long-btn" type="success" @click="submitTeamList">
          SUBMIT TEAM LIST
        </el-button>
      </el-col>
    </div>
  </div>
</template>

<script>
import { errormsg, nonPlayerRoles, nonPlayerRolesTFA } from "../../utils/constants";
import moment from "moment";

export default {
  name: "DefaultSquad",
  props: {
    team: Object,
    teamid: Number,
    positions: Array,
    possibles: Array,
  },
  data() {
    return {
      squadRoles: nonPlayerRoles,
      add: {
        _id: null,
        position: { number: null },
        shirtNum: null,
        isCaptain: false,
      },
      addNP: {
        _id: null,
        role: null,
      },
      teamListRules: {
        _id: { required: true, message: errormsg.select_option, trigger: "blur" },
        //  position: { required: true, message: errormsg.select_option, trigger: "blur" },
        // shirtNum: { required: true, message: errormsg.select_option, trigger: "blur" }
      },
      npteamListRules: {
        _id: { required: true, message: errormsg.select_option, trigger: "blur" },
        role: { required: true, message: errormsg.select_option, trigger: "blur" },
      },
      resubmit: false,
      tableProps: {
        border: true,
      },
      pageSize: 100,
      nonPlayerRoles,
      defaultSquad: [],
      nonPlayerDefaultSquad: [],
      playerList: [],
      nonPlayerList: [],
    };
  },
  // async created() {
  //   try {
  //     const { team, teamid } = this;
  //     this.$store.commit("root/loading", true);
  //     // await Promise.all([
  //     //   this.$store.dispatch("squad-management/getPlayers", teamid),
  //     //   this.$store.dispatch("squad-management/getDefaultSquad", teamid),
  //     // ])
  //     // .then(([getPlayers],[getDefaultSquad]) => {

  //     this.$store.commit("root/loading", false);
  //     //  })
  //   } catch (e) {
  //     this.$store.commit("root/loading", false);
  //     this.$customError();
  //   }
  //   // .catch(() => {
  //   //   this.$store.commit("root/loading", false);
  //   //   this.$customError();
  //   // });
  // },
  mounted() {
    this.squadRoles = this.isTfa ? nonPlayerRolesTFA : nonPlayerRoles;
    this.defaultSquad =
      this.team && this.team.defaultSquad && this.team.defaultSquad.players
        ? this.team.defaultSquad.players
        : [];
    this.nonPlayerDefaultSquad =
      this.team && this.team.defaultSquad && this.team.defaultSquad.nonPlayers
        ? this.team.defaultSquad.nonPlayers
        : [];

    // Players
    this.playerList = this.team && this.team.squadPlayers ? this.team.squadPlayers : [];

    if (this.defaultSquad) {
      const selectedPlayers = this.defaultSquad.map((d) => d._id);

      const pl = this.playerList.filter((p) => {
        return !selectedPlayers.find((sp) => {
          return sp == p._id;
        });
      });

      this.playerList = pl;
    }

    // Non Players

    this.nonPlayerList = this.team && this.team.squadNon ? this.team.squadNon : [];

    this.possibleNonByRole();

    if (this.nonPlayerDefaultSquad.length > 0) {
      const selectedPlayers = this.nonPlayerDefaultSquad.map((d) => {
        return { _id: d._id, role: d.role };
      });

      const pl = this.nonPlayerList.filter((p) => {
        return selectedPlayers.find((sp) => sp !== p._id);
      });

      this.nonPlayerList = pl;
    }
  },
  methods: {
    possibleNonByRole() {
      if (!this.addNP?.role?.type) return [];
      const pl = [];
      const unique = {};

      for (const p of this.possibles) {
        let pass = true;
        pass = p.memberType === this.addNP.role.memberType;
        if (pass && unique[`${p.memberId}`]) pass = false;
        if (pass) unique[`${p.memberId}`] = true;
        if (pass) {
          const found = this.nonPlayerDefaultSquad.find(
            (s) => s.memberId === p.memberId && s.role === p.role
          );
          if (found !== undefined) pass = false;
        }
        if (pass) pl.push(p);
      }
      this.nonPlayerList = pl.sort((a, b) => {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
        if (a.firstName.toLowerCase() == b.firstName.toLowerCase()) return 0;
      });
    },
    clearNonplayer() {
      this.addNP._id = null;
      this.possibleNonByRole();
      if (this.addNP.role !== null || this.add.role !== undefined) {
        const roleType = nonPlayerRolesTFA.find((n) => n.type === this.addNP.role);

        if (!roleType || !roleType.memberType) return;

        // this.nonPlayerList = this.possibles.filter((np) => np.memberType == roleType.memberType);
        this.nonPlayerList = this.team.squadNon.filter(
          (np) => np.memberType == roleType.memberType
        );
        const selectedPlayers = this.nonPlayerDefaultSquad.map((d) => {
          return { _id: d._id, role: this.addNP.role };
        });

        const pl = this.nonPlayerList.filter((p) => {
          return !selectedPlayers.find((sp) => {
            return sp._id == p.memberId;
          });
        });

        this.nonPlayerList = pl;
      }
      // this.$set(this.addNP, "_id", null);
    },
    async handleAddPlayer() {
      try {
        await new Promise((resolve, reject) => {
          this.$refs.teamListRules.validate((valid) => (valid ? resolve() : reject()));
        });
        const { _id, position, shirtNum, isCaptain } = this.add;

        const player = this.team.squadPlayers.find((p) => p._id === _id);

        if (!position.name) {
          const positionDetails = this.positions.find((p) => p.number === position.number);
          this.$set(this.add, "position", positionDetails);
        }

        const found = this.defaultSquad.find((tl) => tl.position.number === position.number);

        if (found) {
          this.$customError(errormsg.same_position);
          throw new Error(errormsg.same_position);
        }

        this.$http
          .get(`/nrl/api/v1/admin/members/${player.memberId}`)
          .then((response) => {
            const member = response.data.data;

            if (member && member.available && member.available.is === false) {
              const error =
                member.available.notes && member.available.notes !== ""
                  ? `${errormsg.player_unavailable} due to ${member.available.notes}`
                  : errormsg.player_unavailable;
              throw new Error(error);
            }

            this.defaultSquad.push({
              _id: member._id,
              shirtNum,
              firstName: member.profile.firstName,
              middleName: member.profile.middleName,
              lastName: member.profile.lastName,
              preferredName:
                member.profile && member.profile.preferFirstName && member.profile.preferLastName
                  ? `${member.profile.preferFirstName} ${member.profile.preferLastName}`
                  : "",
              isPrivate:
                member.profile && member.profile.isPrivate ? member.profile.isPrivate : false,
              position,
              isCaptain,
            });

            this.$set(this.add, "_id", null);
            this.$set(this.add, "position", { number: null });
            this.$set(this.add, "shirtNum", null);
            this.$set(this.add, "isCaptain", false);
          })
          .catch((error) => {
            this.$customError(error);
          });

        // Update Player list
        this.playerList = this.playerList.filter((p) => p._id !== player._id);
      } catch (e) {
        this.$customError("Could not add Player");
        throw new Error("Could not add Player");
      }
    },
    async handleAddNonPlayer() {
      try {
        await new Promise((resolve, reject) => {
          this.$refs.npteamListRules.validate((valid) => (valid ? resolve() : reject()));
        });

        const { _id, role } = this.addNP;

        const player = this.nonPlayerList.find((p) => p._id === _id);

        // const { available, profile } = player;
        const { firstName, lastName, isPrivate } = player;

        this.$http.get(`/nrl/api/v1/admin/members/${player.memberId}`).then((response) => {
          const member = response.data.data;

          if (member.available && member.available.is === false) {
            const error =
              member.available.notes && member.available.notes !== ""
                ? `${errormsg.player_unavailable} due to ${member.available.notes}`
                : errormsg.player_unavailable;
            // this.$customError(error);
            throw new Error(error);
          }

          this.nonPlayerDefaultSquad.push({
            _id: member._id,
            role,
            firstName: member.profile.firstName,
            middleName: member.profile.middleName,
            lastName: member.profile.lastName,
            preferredName:
              member.profile && member.profile.preferFirstName && member.profile.preferLastName
                ? `${member.profile.preferFirstName} ${member.profile.preferLastName}`
                : "",
            isPrivate:
              member.profile && member.profile.isPrivate ? member.profile.isPrivate : false,
          });

          this.$set(this.addNP, "_id", null);
          this.$set(this.addNP, "role", null);
        });

        // Update Player list
        this.nonPlayerList = this.nonPlayerList.filter((p) => p._id !== player._id);
      } catch (e) {
        this.$customError(e);
        // throw new Error("Could not add Non-Player");
      }
    },
    removeFromPlayerTeamList(row) {
      this.$confirm(
        "Are you sure you want to remove this player from the team list",
        "Remove player",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.defaultSquad.splice(
            this.defaultSquad.findIndex((p) => p._id === row._id),
            1
          );
          const playerToAdd = this.team.squadPlayers.find((p) => p.memberId === row._id);
          this.playerList.push(playerToAdd);
        })
        .catch(() => {});
    },
    removeFromNonPlayerTeamList(row) {
      this.$confirm(
        "Are you sure you want to remove this non-player from the team list",
        "Remove non-player",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.nonPlayerDefaultSquad.splice(
            this.nonPlayerDefaultSquad.findIndex((p) => p._id === row._id),
            1
          );
          const playerToAdd = this.possibles.find((p) => p.memberId === row._id);

          this.nonPlayerList.push(playerToAdd);
        })
        .catch(() => {});
    },
    submitTeamList() {
      const { defaultSquad, nonPlayerDefaultSquad } = this;
      this.$confirm(
        "Submitting this team list will override existing default squad. Do you want to continue?",
        "Submit Team List",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.$store.commit("root/loading", true);
          const updateTime = moment().valueOf();
          this.modifiedAt = updateTime;
          this.$http
            .put(`/nrl/api/v1/admin/teams/${this.teamid}/defaultsquad`, {
              players: this.defaultSquad,
              nonplayers: this.nonPlayerDefaultSquad,
              updateTime: updateTime,
            })
            .then(() => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message) {
                this.$customError(e.response.data.message);
              } else {
                this.$customError();
              }

              this.$store.commit("root/loading", false);
            });
        })
        .catch(() => {});
    },
  },
  computed: {
    isTfa() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    currentNPType() {
      return this.addNP.role
        ? this.nonPlayerRoles.find((p) => p.type === this.addNP.role).memberType
        : null;
    },
    // availablep() {
    //   return this.$store.getters["squad-management/availablePlayers"];
    // },
    // availablenp() {
    //   return this.$store.getters["squad-management/availableNonPlayers"];
    // },
    // defaultSquad: {
    //   get() {
    //     const currentTeamList = this.$store.getters["squad-management/defaultSquad"];
    //     if (currentTeamList && typeof currentTeamList === "array")
    //       return currentTeamList.sort((a, b) =>
    //         a.position.order > b.position.order ? 1 : a.position.order < b.position.order ? -1 : 0
    //       );
    //     else return [];
    //   },
    //   set(data) {
    //     this.$store.commit("squad-management/setDefaultSquad", { data, type: "player" });
    //   },
    // },
    // nonPlayerDefaultSquad: {
    //   get() {
    //     return this.$store.getters["squad-management/npDefaultSquad"];
    //   },
    //   set(data) {
    //     this.$store.commit("squad-management/setDefaultSquad", { data, type: "nonplayer" });
    //   },
    // },
    modifiedAt: {
      get() {
        return moment(this.$store.getters["squad-management/squadLastModified"]).format(
          "MMMM Do YYYY, h:mm:ss a"
        );
      },
      set(data) {
        return this.$store.commit("squad-management/setModifiedSquadTime", {
          modifiedAt: data,
          modifiedBy: 1,
        });
      },
    },
    // availablePlayersToAdd() {
    //   if (this.playerList.length <= 0) return [];
    //   const available = this.playerList.map((p) => {
    //     return { _id: p._id, name: `${p.firstName} ${p.lastName}` };
    //   });

    //   return available ? available : [];
    //   // const available = this.availablep;
    //   // const list = this.defaultSquad;
    //   // return available ? available.filter((p) => !list.some((pl) => pl._id === p._id)) : [];
    // },
    // availableNonPlayersToAdd() {
    //   // For ease of management on the front-end, NP [activeRegos] are mapped to an array of roles without the key `memberType`
    //   // const available = this.availablenp;
    //   // const list = this.nonPlayerDefaultSquad;
    //   // return available
    //   //   ? available
    //   //       .filter((p) => !list.some((pl) => pl._id === p._id))
    //   //       .filter((p) =>
    //   //         this.currentNPType ? p.activeRegos.includes(this.currentNPType.toLowerCase()) : false
    //   //       )
    //   //   : [];
    //   if (this.nonPlayerList.length <= 0) return [];
    //   const squadNon = this.nonPlayerList.filter((n) => n.role === this.addNP.role);
    //   const available = squadNon.map((np) => {
    //     return { _id: np._id, name: `${np.firstName} ${np.lastName}` };
    //   });

    //   return available ? available : [];
    // },
    addPosition: {
      get() {
        return this.add.position.number;
      },
      set(value) {
        const position = this.positions.find((p) => p.number === value);
        if (position) {
          this.add.shirtNum = position.number.toString();
        }
        this.add.position = position;
      },
    },
  },
  watch: {
    // "addNP.role": function() {
    //   this.$set(this.addNP, "_id", null);
    // },
    //   teamid(teamid) {
    //     this.$store
    //       .dispatch("squad-management/getPlayers", teamid)
    //       .then(() => {
    //         const playerDefaultSquad = this.defaultSquad.players;
    //         const npDefaultSquad = this.defaultSquad.nonPlayers;
    //         this.$store.commit("squad-management/setDefaultSquad", {
    //           data: playerDefaultSquad.filter((player) =>
    //             this.availablePlayersToAdd.some((p) => p._id === player._id)
    //           ),
    //           type: "player",
    //         });
    //         this.$store.commit("squad-management/setDefaultSquad", {
    //           data: npDefaultSquad.filter((player) =>
    //             this.availableNonPlayersToAdd.some((p) => p._id === player._id)
    //           ),
    //           type: "nonplayers",
    //         });
    //       })
    //       .catch(() => {
    //         this.$customError();
    //       });
    //   },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  .captain-icon {
    color: green;
    font-size: 2rem;
  }
  .remove-icon {
    color: red;
    font-size: 2rem;
  }
}

.add-player {
  .el-form {
    .el-form-item {
      max-width: 25%;
      float: left;
      padding-right: 1rem;
    }
  }
  margin-bottom: 0rem;
}
.long-btn {
  width: 16rem !important;
  height: 2.3rem;
}

.add-non-player {
  .el-form {
    .el-form-item {
      max-width: 100%;
      float: left;
      padding-right: 1rem;
    }
  }
  margin-bottom: 1.5rem;
}

.el-col-6 {
  text-align: right;
}
.el-button {
  width: 14rem;
}
</style>
