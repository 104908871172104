<template>
  <div>
    <el-button icon='el-icon-plus'
      v-if="clearance === roles.superAdmin"
      type="success"
      @click="handleAddClub" >
      Add Club
    </el-button>
    <el-row class="search-bar" :gutter="10">
      <el-col :span="6">
        <el-input
          autocomplete="off"
          v-model="queryFilterValue"
          placeholder="Search...">
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-button @click="filterVisible=true"> Filters </el-button>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>

    <data-tables-server
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      :total="total"
      @query-change="queryChange"
      @row-click="handleRowClick"
      class="data-table">
      <el-table-column
        fixed
        prop="_id"
        label="Id"
        width="100">
      </el-table-column>
      <el-table-column
        fixed
        prop="name"
        label="Name"
        width="250">
      </el-table-column>
      <el-table-column
        prop="orgtree.association.name"
        label="Association Name"
        width="250">
      </el-table-column>
      <el-table-column
        prop="orgtree.state.name"
        label="State Name"
        width="250">
      </el-table-column>
      <el-table-column
        :formatter="statusFormatter"
        prop="isActive"
        label="Status"
        width="250"
      >
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="250">
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import { roles } from '@/utils/constants';
import FilterPage from '@/views/EntityFilterPage.vue';

const _ = require('lodash');

export default {
  name: 'ClubsList',
  components: { FilterPage },
  props: {
  },
  data() {
    return {
      loading: true,
      tableData: [],
      queryFilterValue: this.$store.state.entities.clubs.criteria,
      pageSize: 20,
      currentPage: 1,
      total: 0,
      currentSearch: false,
      filterParams: this.$store.state.entities.clubs.param,
      filterVisible: false,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      roles,
      allStatus: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false }
      ],
      queryInfo: {}
    };
  },
  mounted() {
    const dataStored = this.fetchStore();
    if (!dataStored) {
      this.$http
        .get(`/nrl/api/v1/admin/clubs/data-table/${this.pageSize}/${this.currentPage}`)
        .then((response) => {
          this.tableData = response.data.data.pageData;
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    } else {
      this.loading = false;
    }
  },
  methods: {
    fetchStore() {
      const {
        data, pagination: { total, pageSize, page }
      } = this.$store.state.entities.clubs;
      if (data.length) {
        this.total = total;
        this.pageSize = pageSize;
        this.currentPage = page;
        this.tableData = data;
        return true;
      }
      return false;
    },
    sendFilter(event) {
      // store filter
      this.$store.commit('entities/updateFilters', { filters: { isActive: event.isActive }, entity: 'clubs' });
      const { isActive: activeArray } = event;
      const isActive = activeArray.length > 0 ? activeArray[0] : null;
      const params = { isActive };
      this.$store.commit('entities/updateParam', { param: params, entity: 'clubs' });
      this.filterParams = params;
      this.filterVisible = false;
      this.fetchData();
    },
    filterStatus(value, row) {
      return value === row.isActive;
    },
    queryChange(queryInfo) {
      this.queryInfo = queryInfo;
      if (!this.$store.state.entities.clubs.data.length || queryInfo.type !== 'init') this.fetchData();
    },
    fetchData() {
      this.loading = true;
      const { pageSize, page } = this.queryInfo;
      this.$http
        .get(`/nrl/api/v1/admin/clubs/data-table/${pageSize}/${page}`, { params: { ...this.filterParams, criteria: this.queryFilterValue } })
        .then((response) => {
          this.total = response.data.data.total;
          this.tableData = response.data.data.pageData;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'clubs.update',
        params: {
          type: 'update',
          club: row,
          id: row._id,
        },
      });
    },
    handleAddClub() {
      this.$router.push({
        name: 'clubs.add',
        params: {
          type: 'insert',
        },
      });
    },
    modifiedFormatter(row) {
      return (row.modifiedAt ? this.moment(row.modifiedAt).format('MMMM Do YYYY, h:mm:ss a') : 'NA');
    },
    statusFormatter(row) {
      return row.isActive ? 'Active' : 'Inactive';
    }
  },
  computed: {
    clearance() {
      return this.$store.getters['user/activeRole'].type;
    },
  },
  watch: {
    tableData(data) {
      this.$store.commit('entities/updateData', { data, entity: 'clubs' });
      this.$store.commit('entities/updatePagination', { data: { ..._.pick(this.queryInfo, ['pageSize', 'page']), total: this.total }, entity: 'clubs' });
    },
    // eslint-disable-next-line func-names
    queryFilterValue: _.debounce(function (criteria) {
      this.$store.commit('entities/updateCriteria', { criteria, entity: 'clubs' });
      this.fetchData();
    }, 500)
  }
};
</script>

<style scoped lang="scss">
  .search-bar {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .data-table {
    width: 100%;
  }

  .el-pagination {
    margin-top: 1rem !important;
  }


</style>
