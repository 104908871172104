<template>
  <el-row :gutter="30" class="d-flex">
    <el-col :span="12">
      <el-table v-if="master && master.memberRegos" :data="master.memberRegos" :border="true">
        <el-table-column prop="season" label="Season" width="100"> </el-table-column>
        <el-table-column label="Entity Name" width="200">
          <template slot-scope="scope">
            <span>{{ entityName(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="memberType" label="Member Type" width="100"> </el-table-column>
        <el-table-column :formatter="statusFormatter" label="Status" width="auto">
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :span="12">
      <el-table v-if="slave && slave.memberRegos" :data="slave.memberRegos" :border="true">
        <el-table-column prop="season" label="Season" width="100"> </el-table-column>
        <el-table-column label="Entity Name" width="200">
          <template slot-scope="scope">
            <span>{{ entityName(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="memberType" label="Member Type" width="100"> </el-table-column>
        <el-table-column :formatter="statusFormatter" label="Status" width="auto">
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    masterId: { type: Number },
    slaveId: { type: Number }
  },
  data() {
    return {
      master: {},
      slave: {}
    };
  },
  mounted() {
    const { masterId, slaveId } = this;

    if (masterId) {
      this.$http
        .get(`/nrl/api/v1/admin/members/${masterId}`)
        .then(res => {
          this.master = res.data.data;
        })
        .catch(() => {});
    }

    if (slaveId) {
      this.$http
        .get(`/nrl/api/v1/admin/members/${slaveId}`)
        .then(res => {
          this.slave = res.data.data;
        })
        .catch(() => {});
    }
  },
  methods: {
    statusFormatter(row) {
      if (row.expired === true) return row.deregistered === true ? "Deregistered" : "Inactive";
      return "Active";
    },
    entityName(row) {
      if (row.entityType === "club") return _.get(row, "club.name");
      return (
        (row.team && _.get(row, "team.orgtree.association.name", false)) ||
        (row.association && _.get(row, "association.name", false)) ||
        (row.club && _.get(row, "club.orgtree.association.name", ""))
      );
    }
  }
};
</script>

<style></style>
