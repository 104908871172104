<template>
  <div class="d-flex main align-text-center align-center">
    <div class="flex-1">
      <h3 class="align-text-center">
        A verification code has been sent to:
      </h3>

      <h3 class="align-text-center">
        {{ sentTo }}
      </h3>

      <h3 class="align-text-center">
        Please provide the code below to create your account.
      </h3>

      <div class="align-text-center">
        <input
            v-for="k in Object.keys(code)"
            :key="k"
            type="number"
            class="square"
            v-model="code[k]"
            @input="ensureOnlyOneDigitMax($event, k)"
            @paste.prevent="onPaste"
        />
      </div>

      <el-button
          type="success"
          @click="verifyCode"
          class="m-1"
          :disabled="joinedCode.length < 4"
          ref="verify">
        Verify
      </el-button>
    </div>

    <el-card class="mt-2 flex-0">
      <div class="flex-1 align-text-center m-1">
        Didn't receive the message?
      </div>
      <el-button
          @click="handleResend"
          type="success"
          class="button-light button-thin">
        Resend message
        </el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Verify',
  props: {
  },
  created() {
    if (!this.$store.getters['user/isAuthenticated']) {
      this.$store.commit('root/loading', false);
      this.$router.push('/login');
    } else {
      this.$store.commit('root/loading', true);
      this.$http
        .post('/nrl/api/v1/admin/verify/user/send-code', {})
        .then(() => {
          this.$store.commit('root/loading', false);
        })
        .catch((error) => {
          this.$store.commit('root/loading', false);
          this.$customError(error.response.data.message);
        });
    }
  },
  data() {
    return {
      code: {
        1: null,
        2: null,
        3: null,
        4: null,
      },
      error: '',
      fail: 0,
    };
  },
  methods: {
    onPaste(evt) {
      const result = evt.clipboardData.getData('text').replace(' ', '');
      if (result.length === 4) {
        [1, 2, 3, 4].forEach(
          (i) => {
            this.code[i] = result[i - 1];
          },
        );
      }
    },
    ensureOnlyOneDigitMax($event, index) {
      // only leave new entry, also strip any invalid
      this.code[index] = this.code[index].replace(/\D/g, '')
        .slice(-1);
      // only refocus if has value
      if (this.code[index]) {
        try {
          $event.target.nextElementSibling.focus();
        } catch (e) {
          // reached the end of inputs, focus on submit button.
          this.$refs.verify.$el.focus();
        }
      }
    },
    verifyCode() {
      this.loading = true;
      this.$http
        .post('/nrl/api/v1/admin/verify/user/', { code: this.joinedCode })
        .then(() => {
          this.$store.commit('root/loading', false);
          this.$store.commit('user/updateVerification', true);
          this.$router.push('/');
        })
        .catch((error) => {
          this.fail += 1;
          if (this.fail >= 3) {
            this.$store.commit('user/logout');
            this.$router.go('/login');
          } else {
            this.$store.commit('root/loading', false);
            this.$customError(error.response.data.message);
          }
        });
    },
    handleResend() {
      this.$store.commit('root/loading', true);
      this.$http
        .post('/nrl/api/v1/admin/verify/user/send-code', {})
        .then(() => {
          this.$store.commit('root/loading', false);
        })
        .catch((error) => {
          this.$store.commit('root/loading', false);
          this.$customError(error.response.data.message);
        });
    },
  },
  computed: {
    joinedCode() {
      return Object.values(this.code).join('');
    },
    sentTo() {
      return this.$store.getters['user/email'];
    }
  },
};
</script>

<style scoped lang="scss">
  .main {
    margin: 0 auto;
  }
  .square {
    width: 40px;
    height: 40px;
    margin: 8px;
    box-shadow: 0 0 6px 2px rgba(200, 200, 200, 0.3);
    border: none;
    text-align: center;
    font-size: 25px;

  }
  .square::-webkit-inner-spin-button,
  .square::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
