<template>
  <el-form>
    <div v-if="program.refundStatus">
      <el-row :gutter="10">
        <el-col :span="10">
          <el-form-item label="Refund requested details">
            <div>- Requested by : {{ program.refundStatus.refundRequestedBy.name }}</div>
            <div>
              - Requested at : {{ formatTimestamp(program.refundStatus.refundRequestedAt) }}
            </div>
          </el-form-item>
        </el-col></el-row
      ><el-row class="tw-mt-2">
        <el-col :span="24">
          <el-form-item label="Refund Status">
            <div>- Successful refunds : {{ successfulRefund }}</div>
            <div>- Failed Refund : {{ failedRefund }}</div>
            <div>- Pending Refund : {{ pendingRefund }}</div>
            <div>- Awaiting Refund : {{ awaitingRefund }}</div>
            <div v-if="awaiting && awaiting.length > 0">
              Manual Refund need to be done for these Members :
              <div v-for="refund in awaiting" :key="refund._id">
                - {{ refund.profile.firstName }} {{ refund.profile.lastName }} with NRL Id:
                {{ refund._id }} with registration Id: {{ refund.registrationId }}
              </div>
            </div>
          </el-form-item></el-col
        ></el-row
      >
    </div>

    <div v-else-if="clearance === roles.superAdmin">
      <el-row :gutter="10" class="tw-mt-2">
        <el-button type="success" @click="refundInitiated">
          click refund for Cancelled Program</el-button
        >
      </el-row>
    </div>
  </el-form>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { roles } from "../../utils/constants";

export default {
  components: {
    ActionButtons,
  },
  props: ["program", "memberRes"],
  data() {
    return {
      roles,
      refundStatus: {
        status: "refund-initiated",
        refundRequestedBy: { userId: 0, name: "" },
        refundRequestedAt: new Date().valueOf(),
      },
      successfulRefund: 0,
      failedRefund: 0,
      pendingRefund: 0,
      awaitingRefund: 0,
      awaiting: [],
      refundForCancelledProgram: 0,
    };
  },
  mounted() {
    if (this.program.refundStatus) {
      this.memberRes.forEach((mem) => {
        if (mem.refundStatus) {
          switch (mem.refundStatus.status) {
            case "refund-succeeded":
              this.successfulRefund += 1;
              break;
            case "refund-failed":
              this.failedRefund += 1;
              break;
            case "refund-pending":
              this.pendingRefund += 1;
              break;
            case "refund-awaiting":
              this.awaitingRefund += 1;
              this.awaiting.push(mem);
              break;
          }
        }
      });
    } else {
      this.fetchProgramSettings();
    }
  },

  methods: {
    async fetchProgramSettings() {
      try {
        if (this.program && this.program.settings && this.program.settings._id) {
          const url = `/nrl/api/v1/admin/programs-settings/${this.program.settings._id}`;
          const res = await this.$http.get(url);
          if (res && res.data && res.data.data) {
            const programSettings = res.data.data;

            if (programSettings) {
              const financials = programSettings.financials;

              if (financials) {
                const setting = financials.find(
                  (financial) =>
                    financial.activeKidsVoucher === this.program.settings.activeKidsVoucher &&
                    financial.duration === this.program.settings.duration &&
                    financial.maxAge === this.program.settings.maxAge &&
                    financial.minAge === this.program.settings.minAge &&
                    financial.price === this.program.settings.price
                );

                this.refundForCancelledProgram = setting.refundForCancelledProgram;
              }
            }
          }
        }
      } catch (error) {
        // Handle error here
        console.error("Error fetching data:", error);
        throw error; // Re-throw the error if necessary
      }
    },
    refundInitiated() {
      this.$confirm(
        `Are you sure you want to apply Refund for this cancelled Program: ${this.program.name} (${this.program._id}) ?`,
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          const userId = this.$store.getters["user/userid"];
          this.refundStatus.refundRequestedBy.userId = userId;
          this.refundStatus.refundRequestedBy.name = this.$store.getters["user/userName"];

          const update = { refundStatus: this.refundStatus };

          const url = `/nrl/api/v1/admin/programs/${this.program._id}`;

          this.$http
            .put(url, update)
            .then(async (res) => {
              const program = res.data.data;

              this.program.refundStatus =
                program && program.refundStatus ? program.refundStatus : {};

              if (program && program.refundStatus) {
                this.refundStatus = program.refundStatus;
              }
              console.log(this.memberRes);
              this.$http
                .post(`/nrl/api/v1/admin/refund-requests`, {
                  refundFor: "program",
                  programId: this.program._id,
                  programName: this.program.name,
                  programRegistrations: this.memberRes,
                })
                .then((response) => {
                  this.$customSuccess(`Application for this program refund request is submitted `);
                })
                .catch(() => {
                  this.$customError();
                });

              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        })
        .catch(() => {});
    },

    formatTimestamp(time) {
      return this.moment(time).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
  },
};
</script>
