<template>
  <div>
    <el-tabs type="card">
      <el-tab-pane label="Association Details">
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="6" class="avatar-box entity-card">
            <img v-if="currentLogo" :src="currentLogo" @error="displayPlaceholder" alt="" class="avatar" />
            <img v-else src="~@/assets/shield_black_small.svg" alt="" class="avatar" />
            <vue-upload-component v-if="this.$route.params.type !== 'insert'" ref="upload" :drop="true" v-model="files"
              :extensions="extensions" :accept="accept" :post-action="uploadURL" :headers="uploadHeaders"
              :data="entityData" :multiple="true" @input-file="inputFile" @input-filter="inputFilter"
              class="el-button el-button--default upload">
              Upload
            </vue-upload-component>
          </el-col>
          <el-col :span="18">
            <el-form label-position="top" :model="association" :rules="rules" ref="association" class="login-form">
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="region" label="Region">
                    <el-select v-model="region" placeholder="Select a region" filterable :disabled="type === 'update'">
                      <el-option v-for="item in regions" :label="item.name" :key="item._id" :value="item._id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="name" label="Name">
                    <el-input type="text" autocomplete="off" v-model="associationName" placeholder="Enter a name"
                      :disabled="clearance !== roles.superAdmin" />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.abbreviation" label="Abbreviation">
                    <el-input type="text" autocomplete="off" v-model="association.meta.abbreviation"
                      placeholder="Enter an abbreviation" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.facebook" label="Facebook Link">
                    <el-input type="text" autocomplete="off" v-model="association.meta.facebook" placeholder="">
                      <template slot="prepend">https://facebook.com/</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contact.name" label="Contact Name">
                    <el-input type="text" autocomplete="off" v-model="association.contact.name"
                      placeholder="Enter a contact name" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contact.number" label="Contact Number">
                    <el-input type="text" autocomplete="off" v-model="association.contact.number"
                      placeholder="Enter a contact number" />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contact.email" label="Contact Email">
                    <el-input type="text" autocomplete="off" v-model="association.contact.email"
                      placeholder="Enter a contact email" />
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item prop="contact.address.formatted" label="Address"
                    :error="isError ? 'Select from drop down' : null">
                    <div class="el-input">
                      <google-auto-complete class="auto-complete" id="map" ref="map" @placechanged="placeChanged"
                        :value="association.contact.address.formatted" @inputChange="errorOnTyping"
                        placeholder="Enter an address">
                      </google-auto-complete>
                    </div>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="isActive" label="Active">
                    <el-select v-model="association.isActive">
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.gstRegistered" label="GST Registered">
                    <el-select v-model="association.meta.gstRegistered" :disabled="![
                      roles.associationAdmin,
                      roles.regionAdmin,
                      roles.stateAdmin,
                      roles.superAdmin,
                    ].includes(clearance)
                      ">
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12" v-if="!isTFAAdmin">
                  <el-form-item prop="meta.18MRegistration" label="18 Month Registration Option">
                    <el-select v-model="association.meta['18MRegistration']"
                      :disabled="![roles.stateAdmin, roles.superAdmin].includes(clearance)">
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="!isTFAAdmin">
                  <el-form-item prop="clubAccessPendingRego" label="Club Access Pending Registrations">
                    <el-select v-model="association.clubAccessPendingRego">
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.abn" label="ABN">
                    <el-select v-model="association.meta.abn" :disabled="![
                      roles.associationAdmin,
                      roles.regionAdmin,
                      roles.stateAdmin,
                      roles.superAdmin,
                    ].includes(clearance)
                      " filterable remote placeholder="Search by name" :loading="abnLoading"
                      :remote-method="searchAbn">
                      <el-option v-for="item in abnOptions" :key="item.value" :label="`${item.label} (${item.value})`"
                        :value="item.value" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.website" label="Website">
                    <el-input type="text" autocomplete="off" v-model="association.meta.website"
                      placeholder="Enter a website">
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12" v-if="isTFAAdmin">
                  <el-form-item label="Business ID (OPTIONAL)">
                    <el-input type="text" autocomplete="off" v-model="businessId" placeholder="Enter a Business ID"
                      :disabled="type === 'update' && !isSuperAdmin"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="isTFAAdmin">
                  <el-form-item label="Location Id (Optional)">
                    <el-input type="text" autocomplete="off" v-model="locationId"
                      placeholder="Enter association's Location Id" :disabled="type === 'update' && !isSuperAdmin" />
                  </el-form-item>
                </el-col>
              </div>
            </el-form>
            <action-buttons submit :cancel="!isMyEntity" @submit="submitForm('association')" @cancel="cancelForm()" />
          </el-col>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Competition Types" v-if="isNRLAdmin">
        <competition-types-list :competitionTypes="this.competitionTypes" />
      </el-tab-pane>
      <el-tab-pane label="Team List Types" v-if="
        (clearance === roles.associationAdmin ||
          clearance === roles.stateAdmin ||
          clearance === roles.regionAdmin) &&
        isNRLAdmin
      ">
        <team-list-type />
      </el-tab-pane>
      <el-tab-pane label="Clearance Auto Approvals" v-if="clearance === roles.associationAdmin && isNRLAdmin">
        <auto-approvals key="clearance" :entity="association" type="clearance" />
      </el-tab-pane>
      <el-tab-pane label="Permit Auto Approvals" v-if="clearance === roles.associationAdmin && isNRLAdmin">
        <auto-approvals key="permit" :entity="association" type="permit" />
      </el-tab-pane>
      <el-tab-pane label="Permissions" v-if="isSuperAdmin && !isTFAAdmin">
        <el-row>
          <el-collapse>
            <el-collapse-item title="MyMedical Special Permissions" name="MyMedicalSpecialPermissions">
              <el-form label-position="top" :model="permissionsForm" :rules="emailListRules" ref="permissionsForm"
                class="permissionsForm" @submit.native.prevent="addEmailToList('permissionsForm')">
                <el-form-item prop="newEmailData">
                  <el-input class="add-email" type="email" autocomplete="off" v-model="permissionsForm.newEmailData"
                    placeholder="Add an email">
                  </el-input>
                </el-form-item>
                <el-form-item prop="newMyMedicalPermissions">
                  <el-checkbox-group v-model="permissionsForm.newMyMedicalPermissions">
                    <el-checkbox v-for="choice in choices" :id="choice.type" :key="choice.type" :label="choice.type"
                      border :disabled="choice.disabled">{{ choice.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <action-buttons submit cancel cancelText="Clear" @submit="addEmailToList('permissionsForm')"
                  @cancel="clearForm('permissionsForm')" />
              </el-form>
              <el-row class="small-card" type="flex" align="middle">
                <el-col :span="6"><span>Email</span></el-col>
                <el-col> <span>Permissions</span></el-col>
              </el-row>
              <el-row shadow="hover" v-for="[email, permissions] in emailListData" :key="email" class="small-card"
                type="flex" align="middle">
                <el-col :span="6">
                  {{ email }}
                </el-col>
                <el-col class="tw-flex tw-flex-wrap tw-gap-4">
                  <div v-for="permission in permissions" :key="permission">
                    {{ findPermission(permission) }}
                    <i class="el-icon-delete tw-border-2 tw-rounded-full tw-bg-white tw-cursor-pointer tw-p-2 tw-ml-2"
                      @click="removeEmail(email, permission)"></i>
                  </div>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
        <el-row v-if="isNRLAdmin">
          <el-collapse>
            <el-collapse-item title="Team Registration Permissions" name="TeamRegistrationPermissions" class="text-sm">
              <el-form label-position="top" :model="registrationPermissionForm" ref="registrationPermissionForm"
                class="permissionsForm" @submit.native.prevent="teamRegSubmission()">
                <el-col :span="24" class="ml-50">
                  <el-col :span="6">
                    Allow Team registrations
                  </el-col>
                  <el-col :span="18">
                    <el-switch v-model="registrationPermissionForm.teamRegPermAllow" active-color="#13ce66"
                      inactive-color="#ff4949" active-text="Yes" inactive-text="No"
                      @change="statusChange('teamRegPermAllow')">
                    </el-switch>
                  </el-col>
                </el-col>

                <div class="section-title ml-30">Hide menu options</div>

                <el-col :span="24" class="ml-50">
                  <el-col :span="6">
                    Clearances/Permits
                  </el-col>
                  <el-col :span="18">
                    <el-switch v-model="registrationPermissionForm.hideClearanceApp" active-color="#13ce66"
                      inactive-color="#ff4949" active-text="Yes" inactive-text="No"
                      @change="statusChange('hideClearanceApp')">
                    </el-switch>
                  </el-col>
                </el-col>

                <el-col :span="24" class="ml-50">
                  <el-col :span="6">
                   18 Month Dispensation
                  </el-col>
                  <el-col :span="18">
                    <el-switch v-model="registrationPermissionForm.eighteenMonthApp" active-color="#13ce66"
                      inactive-color="#ff4949" active-text="Yes" inactive-text="No"
                      @change="statusChange('eighteenMonthApp')">
                    </el-switch>
                  </el-col>
                </el-col>

                <el-col :span="24" class="ml-50">
                  <el-col :span="6">
                    Pending registrations
                  </el-col>
                  <el-col :span="18">
                    <el-switch v-model="registrationPermissionForm.pendingRegistApp" active-color="#13ce66"
                      inactive-color="#ff4949" active-text="Yes" inactive-text="No"
                      @change="statusChange('pendingRegistApp')">
                    </el-switch>
                  </el-col>
                </el-col>

                <el-col :span="24" class="ml-30 ">
                  <label for="memberTypes" class="font-bold">Rugby League Team Types Allowed</label>
                  <el-checkbox-group v-model="registrationPermissionForm.memberTypes" class="">
                    <div class="d-flex checkBoxGroup">
                      <div class="d-flex-column">
                        <el-checkbox v-for="type in fpfilter((o) => o.type.includes('player'))(memberTypes)"
                          :key="type.name" :label="type.type">
                          {{ type.name }}
                        </el-checkbox>
                      </div>
                      <div class="d-flex-column">
                        <el-checkbox v-for="type in fpfilter((o) => !o.type.includes('player'))(memberTypes)"
                          :key="type.name" :label="type.type">
                          {{ type.name }}
                        </el-checkbox>
                      </div>
                    </div>
                  </el-checkbox-group>
                </el-col>
                <action-buttons submit :cancel="!isMyEntity" @submit="teamRegSubmission()" @cancel="cancelForm()" />
              </el-form>

            </el-collapse-item>
          </el-collapse>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { isNaN, min } from "lodash";
import fpfilter from "lodash/fp/filter";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, uploadURL, roles, memberTypes } from "../../utils/constants";
import AutoApprovals from "../auto-approvals/AutoApprovals.vue";
import CompetitionTypesList from "./CompetitionTypes.vue";
import CheckBoxGroup from "@/components/CheckboxGroup.vue";
import { parseGoogleAddress, validateEmail, validatePhoneNumber } from "../../utils/utilities";

export default {
  name: "AssociationForm",
  props: {},
  components: {
    ActionButtons,
    AutoApprovals,
    CheckBoxGroup,
    CompetitionTypesList,
  },
  data() {
    const validateRegion = (rule, value, callback) => {
      const { association } = this;
      if (
        !association ||
        !association.orgtree.region._id ||
        association.orgtree.region._id === ""
      ) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    const validateContactEmail = (rule, value, callback) => {
      const { contact } = this.association;
      if (!contact || !contact.email || !validateEmail(contact.email)) {
        return callback(new Error(errormsg.email));
      }
      return callback();
    };

    const validatePermissionEmail = (rule, value, callback) => {
      if (!value || !validateEmail(value)) {
        return callback(new Error(errormsg.email));
      }
      return callback();
    };
    const validatePermissionsEnabled = (rule, value, callback) => {
      if (!value || !Array.isArray(value) || !value.length) {
        return callback(new Error("Minimum one permission selection required."));
      }
      return callback();
    };

    const validateContactNumber = (rule, value, callback) => {
      const { contact } = this.association;
      if (!contact || !contact.number || !validatePhoneNumber(contact.number)) {
        return callback(new Error(errormsg.phone));
      }
      return callback();
    };

    const validateWebsite = (rule, val, callback) => {
      const { meta } = this.association;
      const expression =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      const regex = new RegExp(expression);

      if (meta && meta.website) {
        if (meta.website.length && !meta.website.match(regex)) {
          return callback(new Error(errormsg.invalidUrl));
        }
      }
      return callback();
    };

    return {
      isError: false,
      isSelected: false, //toggle on when the dropdown selected in Address
      type: "insert",
      nameUpdated: false,
      regions: [],
      files: [],
      legalNames: [],
      abnOptions: [],
      abnLoading: false,
      businessId: "",
      locationId: "",
      competitionTypes: [],
      association: {
        name: "",
        orgtree: {
          region: {
            _id: "",
            name: "",
          },
        },
        meta: {
          abbreviation: "",
          avatar: "",
          facebook: "",
          "18MRegistration": false,
          gstRegistered: false,
          abn: "",
          autoApproval: [],
          website: "",
          akv: {
            businessId: "",
            locationId: "",
          },
        },
        contact: {
          name: "",
          email: "",
          number: "",
          address: {},
        },
      },
      rules: {
        region: [
          {
            type: "object",
            required: true,
            validator: validateRegion,
            message: errormsg.select_option,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: errormsg.input_option,
            trigger: "blur",
          },
        ],
        "meta.akv.businessId": {
          required: false,
          trigger: "blur",
          message: errormsg.akvBusinessId,
          validator: (rule, val, cb) => {
            return cb();
          },
        },
        "meta.akv.locationId": {
          required: false,
          trigger: "blur",
          message: errormsg.locationId,
          validator: (rule, val, cb) => {
            return cb();
          },
        },
        "meta.abbreviation": {
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.18MRegistration": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.gstRegistered": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.abn": {
          message: errormsg.input_option,
          trigger: "blur",
        },
        "contact.name": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "contact.number": {
          required: true,
          message: errormsg.phone,
          trigger: "blur",
          validator: validateContactNumber,
        },
        "contact.email": {
          required: true,
          message: errormsg.email,
          trigger: "blur",
          validator: validateContactEmail,
        },
        "contact.address.formatted": {
          required: true,
        },
        isActive: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        clubAccessPendingRego: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.website": {
          validator: validateWebsite,
          trigger: "blur",
        },
      },
      emailListRules: {
        newEmailData: {
          required: true,
          message: errormsg.input_option,
          trigger: "submit",
          validator: validatePermissionEmail,
        },
        newMyMedicalPermissions: {
          trigger: "blur",
          validator: validatePermissionsEnabled,
        },
      },
      roles,
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png",
      permissionsForm: {
        newEmailData: "",
        newMyMedicalPermissions: [],
      },
      choices: [
        { name: "Association CMO Role Allocation", type: "associationAdminAllocation" },
        {
          name: "MyMedical Report Access",
          type: "myMedicalReportAccess",
        },
        {
          name: "MyMedical Assessment Removal Auth",
          type: "myMedicalAssessmentDeleteAuth",
        },
      ],
      registrationPermissionForm: {
        memberTypes: [],
        teamRegPermAllow: false,
        hideClearanceApp: false,
        eighteenMonthApp: false,
        pendingRegistApp: false,

      },
      memberTypes,
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, association, id } = this.$route.params;
    this.type = type || (id !== "add" ? "update" : "insert");
    // Load Association and Regions
    if (id && id !== "" && id !== "add") {
      this.$http
        .get(`/nrl/api/v1/admin/associations/${id}`)
        .then((assocRes) => {
          this.association = Object.assign({}, this.association, assocRes.data.data);
          if (this.association.meta.akv) {
            if (this.association.meta.akv.businessId) {
              this.businessId = this.association.meta.akv.businessId;
            }
            if (this.association.meta.akv.locationId) {
              this.locationId = this.association.meta.akv.locationId;
            }
          }
          if (!this.association.meta.akv) {
            this.association.meta.akv = {
              businessId: "",
              locationId: "",
            };
          }
          if (this.association && this.association.permissions) {
            this.registrationPermissionForm = Object.assign(
              {},
              this.registrationPermissionForm,
              this.association.permissions
            );
          }

          this.$http
            .get(`/nrl/api/v1/admin/competition-types/association/${id}`)
            .then((response) => {
              this.competitionTypes = response.data.data;
              this.$store.commit("root/loading", false);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });

          if (this.association.meta["18MRegistration"] !== true)
            this.association.meta = Object.assign({}, this.association.meta, {
              "18MRegistration": false,
            });
          if (this.association.meta.gstRegistered !== true)
            this.association.meta = Object.assign({}, this.association.meta, {
              gstRegistered: false,
            });

          if (!this.isMyEntity) {
            this.$http
              .get("/nrl/api/v1/admin/regions/")
              .then((regionRes) => {
                this.regions = regionRes.data.data;
                this.$store.commit("root/loading", false);
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          } else {
            this.regions = [this.association.orgtree.region];
            this.$store.commit("root/loading", false);
          }
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    } else {
      // Load Regions
      this.$http
        .get("/nrl/api/v1/admin/regions/")
        .then((response) => {
          this.regions = response.data.data;
          // Include Assoc Data if update and passed through props
          if (association) {
            this.association = Object.assign({}, this.association, association);
            this.$http
              .get(`/nrl/api/v1/admin/competition-types/association/${association._id}`)
              .then((response) => {
                this.competitionTypes = response.data.data;
                this.$store.commit("root/loading", false);
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
          if (this.association.meta.akv) {
            if (this.association.meta.akv.businessId) {
              this.businessId = this.association.meta.akv.businessId;
            }
            if (this.association.meta.akv.locationId) {
              this.locationId = this.association.meta.akv.locationId;
            }
          }

          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }
  },
  methods: {
    addEmailToList(formName) {
      this.$store.commit("root/loading", true);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post(`/nrl/api/v1/admin/associations/${this.association._id}/mymedical/add/email`, {
              email: this.permissionsForm.newEmailData,
              permissions: this.permissionsForm.newMyMedicalPermissions,
            })
            .then((response) => {
              const update = response && response.data && response.data.associationUpdate;
              if (update) this.association = Object.assign({}, this.association, update);
              this.permissionsForm.newEmailData = "";
              this.permissionsForm.newMyMedicalPermissions = [];
              this.$store.commit("root/loading", false);
            })
            .catch((error) => {
              console.error("Error adding email:", error);
              this.$store.commit("root/loading", false);
            });
        } else {
          this.$store.commit("root/loading", false);
          return false;
        }
      });
      this.$store.commit("root/loading", false);
    },
    removeEmail(email_query, permission) {
      const found = this.emailListData.find(([email, permissions]) => {
        return email_query === email && permissions.includes(permission);
      });
      if (found) {
        this.$store.commit("root/loading", true);
        this.$http
          .post(`/nrl/api/v1/admin/associations/${this.association._id}/mymedical/remove/email`, {
            email: email_query,
            permissions: [permission],
          })
          .then((response) => {
            const update = response && response.data && response.data.associationUpdate;
            if (update) this.association = Object.assign({}, this.association, update);
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            console.error("Error removing email:", error);
            this.$store.commit("root/loading", false);
          });
      }
    },
    clearForm(formName) {
      this.$refs[formName].resetFields();
    },
    findPermission(permission) {
      return this.choices.find((c) => c.type === permission).name;
    },
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response.status === "success") {
          this.$store.commit("root/loading", false);
          this.association.meta.avatar = newFile.response.url;
          if (this.type === "update") {
            const { avatar } = this.association.meta;
            const { _id } = this.association;
            this.$store.commit("root/loading", true);
            const update = { meta: { avatar } };
            const url = `/nrl/api/v1/admin/associations/${_id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        } else {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }
      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    submitForm(formName) {
      if (this.isError) return false;
      this.$refs[formName].validate((valid) => {
        this.$refs.map.update(this.association.contact.address.formatted);
        if (valid) {
          this.association.meta.akv = {
            businessId: this.businessId,
            locationId: this.locationId,
          };
          this.isSelected = true;
          const currentAssociation = this.association;
          if (this.type === "update") {
            this.$store.commit("root/loading", true);
            const update = {
              ...currentAssociation,
              orgtree: currentAssociation.orgtree,
            };
            if (this.nameUpdated) update.name = currentAssociation.name;
            const url = `/nrl/api/v1/admin/associations/${currentAssociation._id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                this.$customSuccess();
                // update data member in store
                this.$store.commit("entities/updateDataMember", {
                  entity: "associations",
                  data: update,
                });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            const insert = currentAssociation;
            // add orgtreeV2Read and orgtreeV2Write to insert object
            insert["orgtreeV2Read"] = {
              region: [
                currentAssociation.orgtree.region._id && currentAssociation.orgtree.region._id,
              ],
              state: [currentAssociation.orgtree.state._id && currentAssociation.orgtree.state._id],
              national: [
                currentAssociation.orgtree.national._id && currentAssociation.orgtree.national._id,
              ],
            };
            insert["orgtreeV2Write"] = {
              region: [
                currentAssociation.orgtree.region._id && currentAssociation.orgtree.region._id,
              ],
              state: [currentAssociation.orgtree.state._id && currentAssociation.orgtree.state._id],
              national: [
                currentAssociation.orgtree.national._id && currentAssociation.orgtree.national._id,
              ],
            };
            const url = "/nrl/api/v1/admin/associations/";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$store.commit("root/loading", false);
                this.$router.push({
                  name: "associations",
                });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
          return true;
        }
        return false;
      });
    },
    cancelForm() {
      this.$router.push({
        name: "associations",
      });
    },
    errorOnTyping() {
      if (!this.isSelected) this.isError = true;
      this.isSelected = false;
    },
    placeChanged(addressData, place) {
      this.isSelected = true;
      this.association.contact.address = parseGoogleAddress(addressData, place);
      this.isError = false;
    },
    searchAbn(query) {
      if (query !== "") {
        this.abnLoading = true;
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/abr-proxy/name", { name: query, maxResult: 10 })
            .then((response) => {
              this.abnLoading = false;
              if (response.data.data.length > 0) {
                this.abnOptions = response.data.data.map((o) => ({ label: o.text, value: o.abn }));
              }
            })
            .catch(() => {
              this.$customError();
            });
        }, 200);
      }
    },
    fpfilter,
    teamRegSubmission() {
      const currentAssociation = this.association;
      const url = `/nrl/api/v1/admin/associations/${currentAssociation._id}/update-permissions`;
      this.$store.commit("root/loading", true);
      const body = {
        permissions: this.registrationPermissionForm,
      }
      this.$http
        .post(url, body)
        .then(() => {
          this.$customSuccess();
          this.$store.commit("root/loading", false);
        })
        .catch(err => {
          this.$store.commit("root/loading", false);
          this.$customError();
        })

    },
    statusChange(fieldName) {
      const newValue = this.registrationPermissionForm[fieldName];
      if (fieldName === 'teamRegPermAllow') {
        this.registrationPermissionForm.teamRegPermAllow = newValue ? true : false;
      } else if (fieldName === 'hideClearanceApp') {
        this.registrationPermissionForm.hideClearanceApp = newValue ? true : false;
      } else if (fieldName === 'eighteenMonthApp') {
        this.registrationPermissionForm.eighteenMonthApp = newValue ? true : false;
      } else if (fieldName === 'pendingRegistApp') {
        this.registrationPermissionForm.pendingRegistApp = newValue ? true : false;
      }
    }
  },
  computed: {
    isTFAAdmin() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    isNRLAdmin() {
      return this.$store.getters["user/activeRole"].national_id === 32;
    },
    isSuperAdmin() {
      return this.$store.getters["user/activeRole"].type === 1;
    },
    currentFormattedAddressOld() {
      return this.association.contact.address ? this.association.contact.address.formatted : null;
    },

    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    entityData() {
      return {
        entity_type: "assoc",
        entity_id: this.association._id,
      };
    },
    uploadURL() {
      return uploadURL;
    },
    currentLogo() {
      return this.association.meta && this.association.meta.avatar
        ? this.association.meta.avatar
        : false;
    },
    associationName: {
      get() {
        return this.association.name;
      },
      set(value) {
        this.nameUpdated = true;
        this.association.name = value;
      },
    },
    region: {
      get() {
        return this.association.orgtree.region._id;
      },
      set(regionid) {
        const foundRegion = this.regions.find((obj) => obj._id === regionid);
        if (foundRegion) {
          const { orgtree, _id, name } = foundRegion;
          orgtree.region = { _id, name };
          this.association.orgtree = orgtree;
        }
      },
    },
    isMyEntity() {
      return this.$route.path.split("/").includes("my");
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    default18M() {
      return this.association.meta["18MRegistration"] !== true;
    },
    emailListData() {
      const allowedPermissions =
        this.association && this.association.meta && this.association.meta.mymedicalEmailList;
      if (allowedPermissions) {
        const permissionEntries = Object.entries(allowedPermissions);
        const obj = {};
        permissionEntries.forEach(([permissionType, emails]) => {
          emails.forEach((email) => {
            if (!obj[email]) obj[email] = [];
            obj[email].push(permissionType);
          });
        });
        const entries = Object.entries(obj);
        return entries;
      }
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.entity-card {
  height: 40rem;
}

.avatar-box {
  overflow: hidden;

  .avatar {
    margin: 0 auto;
    height: 12.5rem;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .avatar-entity-name {
    text-align: center;
    display: block;
    font-size: 1.05rem;
    font-style: oblique;
    margin-bottom: 1rem;
  }

  .upload {
    margin: 0 auto;
    display: block;
    max-width: 70%;
  }
}

.operation {
  el-button {
    width: 100%;
    height: 100%;
  }
}

.permissionsForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-email {
  text-align: center;
  width: 66%;
  min-width: 328px;
  max-width: 565px;
}

.small-card {
  margin: 0.5rem;
}
.section-title {
  font-weight: bold;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}
.ml-50{
  padding-left: 50px;
}
.ml-30 {
  padding-left: 30px;
}
.checkBoxGroup{
  left: 27%;
  position: relative;
}
</style>
