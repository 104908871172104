var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"regrading",attrs:{"model":_vm.value,"rules":_vm.regradingRules,"label-position":"top"}},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"prop":"sourceCompetition._id","label":"Source Competition"}},[_c('el-select',{attrs:{"placeholder":"Select a source competition","filterable":"","clearable":""},model:{value:(_vm.value.sourceCompetition._id),callback:function ($$v) {_vm.$set(_vm.value.sourceCompetition, "_id", $$v)},expression:"value.sourceCompetition._id"}},_vm._l((_vm.sourceCompetitions),function(ref){
var _id = ref._id;
var name = ref.name;
return _c('el-option',{key:("source_" + _id),attrs:{"label":name,"value":_id}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"prop":"regradeTeam._id","label":"Team to Regrade"}},[_c('el-select',{attrs:{"placeholder":"Select a team to regrade","filterable":""},model:{value:(_vm.value.regradeTeam._id),callback:function ($$v) {_vm.$set(_vm.value.regradeTeam, "_id", $$v)},expression:"value.regradeTeam._id"}},_vm._l((_vm.regradeTeams),function(ref){
var _id = ref._id;
var name = ref.name;
return _c('el-option',{key:("regrade_" + _id),attrs:{"label":name,"value":_id}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"prop":"destCompetition._id","label":"Destination Competition"}},[_c('el-select',{attrs:{"placeholder":"Select a destination competition","filterable":"","clearable":""},model:{value:(_vm.value.destCompetition._id),callback:function ($$v) {_vm.$set(_vm.value.destCompetition, "_id", $$v)},expression:"value.destCompetition._id"}},_vm._l((_vm.destCompetitions),function(ref){
var _id = ref._id;
var name = ref.name;
return _c('el-option',{key:("destination_" + _id),attrs:{"label":name,"value":_id}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Team Designation in Destination Competition"}},[_vm._l((_vm.designationOptions),function(ref){
var type = ref.type;
var name = ref.name;
return _c('div',{key:type},[_c('el-radio',{attrs:{"label":type},model:{value:(_vm.value.designationOption),callback:function ($$v) {_vm.$set(_vm.value, "designationOption", $$v)},expression:"value.designationOption"}},[_vm._v(_vm._s(name))])],1)}),_c('el-form-item',{attrs:{"prop":"replaceTeam._id"}},[_c('el-select',{attrs:{"placeholder":"Select team to replace from destination competition","filterable":"","disabled":_vm.value.designationOption !== 'replace'},model:{value:(_vm.value.replaceTeam._id),callback:function ($$v) {_vm.$set(_vm.value.replaceTeam, "_id", $$v)},expression:"value.replaceTeam._id"}},_vm._l((_vm.replaceTeams),function(ref){
var _id = ref._id;
var name = ref.name;
return _c('el-option',{key:("replace_" + _id),attrs:{"label":name,"value":_id}})}),1)],1)],2)],1)],1),(_vm.value.designationOption === 'new')?_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Destination Competition Matches"}},_vm._l((_vm.destMatchOptions),function(ref){
var type = ref.type;
var name = ref.name;
return _c('div',{key:type},[_c('el-radio',{attrs:{"label":type},model:{value:(_vm.value.destMatchOption),callback:function ($$v) {_vm.$set(_vm.value, "destMatchOption", $$v)},expression:"value.destMatchOption"}},[_vm._v(_vm._s(name))])],1)}),0)],1)],1):_vm._e(),(_vm.value.designationOption === 'new')?_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Source Competition Matches"}},_vm._l((_vm.sourceMatchOptions),function(ref){
var type = ref.type;
var name = ref.name;
return _c('div',{key:type},[_c('el-radio',{attrs:{"label":type},model:{value:(_vm.value.sourceMatchOption),callback:function ($$v) {_vm.$set(_vm.value, "sourceMatchOption", $$v)},expression:"value.sourceMatchOption"}},[_vm._v(_vm._s(name))])],1)}),0)],1)],1):_vm._e(),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Ladder Options"}},_vm._l((_vm.ladderOptions),function(ref){
var type = ref.type;
var name = ref.name;
return _c('div',{key:type},[_c('el-radio',{attrs:{"label":type},model:{value:(_vm.value.ladderOption),callback:function ($$v) {_vm.$set(_vm.value, "ladderOption", $$v)},expression:"value.ladderOption"}},[_vm._v(_vm._s(name))])],1)}),0)],1)],1),_c('el-row',{staticClass:"mb-2"},[_c('el-col',{attrs:{"span":20}},_vm._l((_vm.errors),function(error,i){return _c('el-alert',{key:i,attrs:{"type":"error","description":error,"show-icon":""}})}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }