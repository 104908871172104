import axios from 'axios';
import store from "../../store";

// GraphQL beta API to get access_token which gives access to the GraphQL API
export const httpnoauth_appservices_beta = axios.create({
  baseURL: store.getters['root/appServicesAuthApiUrl'],
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});
const getChartsJwt = () => {
  return httpnoauth_appservices_beta
    .get('/api/v1/issue-token', { 'headers': { 'apitoken': store.getters["user/idTokenNoRoles"] } })
    .then((response) => {
      // add response to store as graphql_token
      return response.data.graphql.access_token;
    })
    .catch(() => {
      customError();
      store.commit('root/loading', false);
    });
};

export default getChartsJwt;
