<template>
  <center>
    <table
      align="center"
      border="0"
      cellpadding="0"
      cellspacing="0"
      height="100%"
      width="100%"
      id="bodyTable"
    >
      <tr>
        <td
          align="center"
          valign="top"
          id="bodyCell"
        >
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            class="templateContainer"
          >
            <tr>
              <td
                valign="top"
                id="templatePreheader"
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  class="mcnImageBlock"
                  style="min-width:100%;"
                >
                  <tbody class="mcnImageBlockOuter">
                    <tr>
                      <td
                        valign="top"
                        style="padding:0px"
                        class="mcnImageBlockInner"
                      >
                        <table
                          align="left"
                          width="100%"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          class="mcnImageContentContainer"
                          style="min-width:100%;"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="mcnImageContent"
                                valign="top"
                                style="padding-right: 0px; padding-left: 0px; padding-top: 0; padding-bottom: 0; text-align:center;"
                              >
                                <img
                                  align="center"
                                  alt=""
                                  src="https://mcusercontent.com/dab2f91df91fb68ae65e3d60e/images/863e6a06-8dd6-4d96-bc97-0994507c9999.jpg"
                                  width="100%"
                                  style="max-width:600px; padding-bottom: 0; display: inline !important; vertical-align: bottom;"
                                  class="mcnImage"
                                >

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                id="templateHeader"
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  class="mcnTextBlock"
                  style="min-width:100%;"
                >
                  <tbody class="mcnTextBlockOuter">
                    <tr>
                      <td
                        valign="top"
                        class="mcnTextBlockInner"
                        style="padding-top:9px;"
                      >
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          style="max-width:310px;"
                          width="100%"
                          class="mcnTextContentContainer"
                        >
                          <tbody>
                            <tr>

                              <td
                                valign="top"
                                class="mcnTextContent"
                                style="padding-top:0; padding-left:18px; padding-bottom:9px; padding-right:18px;"
                              >

                                <div style="text-align: left;"><span style="font-size:16px"><strong>You have received a notification from:</strong></span><br>
                                  <span style="font-size:14px"><strong>{{ name }}</strong><br>
                                    {{ contact && contact.number}}</span></div>

                                <div style="text-align: left;"><span style="font-size:14px">{{ contact && contact.email}}</span></div>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          style="max-width:210px;"
                          width="100%"
                          class="mcnTextContentContainer"
                        >
                          <tbody>
                            <tr>

                              <td
                                valign="top"
                                class="mcnTextContent"
                                style="padding-top:0; padding-left:18px; padding-bottom:9px; padding-right:18px;"
                              >

                                <img
                                  data-file-id="1074743"
                                  height="123"
                                  :src="logo"
                                  style="border: 0px initial ; width: 150px; height: 123px; margin: 0px;"
                                  width="150"
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  class="mcnDividerBlock"
                  style="min-width:100%;"
                >
                  <tbody class="mcnDividerBlockOuter">
                    <tr>
                      <td
                        class="mcnDividerBlockInner"
                        style="min-width:100%; padding:18px;"
                      >
                        <table
                          class="mcnDividerContent"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="min-width: 100%;border-top: 2px solid #000000;"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <span></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                id="templateBody"
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  class="mcnTextBlock"
                  style="min-width:100%;"
                >
                  <tbody class="mcnTextBlockOuter">
                    <tr>
                      <td
                        valign="top"
                        class="mcnTextBlockInner"
                        style="padding-top:9px;"
                      >
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          style="max-width:100%; min-width:100%;"
                          width="100%"
                          class="mcnTextContentContainer"
                        >
                          <tbody>
                            <tr>

                              <td
                                valign="top"
                                class="mcnTextContent"
                                style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;"
                              >

                                <div style="text-align: center;"><span style="font-size:24px"><strong>{{subject}}</strong></span><br>
                                  &nbsp;</div>

                                <div
                                  style="text-align: left;"
                                  v-html="content"
                                >
                                </div>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                id="templateFooter"
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  class="mcnTextBlock"
                  style="min-width:100%;"
                >
                  <tbody class="mcnTextBlockOuter">
                    <tr>
                      <td
                        valign="top"
                        class="mcnTextBlockInner"
                        style="padding-top:9px;"
                      >
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          style="max-width:100%; min-width:100%;"
                          width="100%"
                          class="mcnTextContentContainer"
                        >
                          <tbody>
                            <tr>

                              <td
                                valign="top"
                                class="mcnTextContent"
                                style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;"
                              >

                                <span style="color:#FFFFFF"><strong>This message was sent to you by MySideline on behalf of your club, association or program deliverer.</strong></span><br>
                                <br>
                                <img
                                  data-file-id="1074760"
                                  height="32"
                                  src="https://mcusercontent.com/dab2f91df91fb68ae65e3d60e/images/fdeb3765-daa4-4b66-b5f9-1559d6a8099b.png"
                                  style="border: 0px initial ; width: 200px; height: 32px; margin: 0px;"
                                  width="200"
                                ><br>
                                <br>
                                <strong><a
                                    href="https://profile.mysideline.com.au/register/"
                                    target="_blank"
                                  ><span style="color:#FFFFFF">Register&nbsp;</span></a><span style="color:#FFFFFF"> &nbsp;|&nbsp; &nbsp;</span><a
                                    href="https://profile.mysideline.com.au/profile/"
                                    target="_blank"
                                  ><span style="color:#FFFFFF">My Profile</span></a><span style="color:#FFFFFF">&nbsp; &nbsp;|&nbsp; &nbsp;</span><a
                                    href="http://support.playrugbyleague.com"
                                    target="_blank"
                                  ><span style="color:#FFFFFF">Support</span></a></strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </center>
</template>
<script>
export default {
  props: ['subject', 'content', 'contact', 'logo', 'name']
};
</script>
<style scoped>
p {
  margin: 10px 0;
  padding: 0;
}
table {
  border-collapse: collapse;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin: 0;
  padding: 0;
}
img,
a img {
  border: 0;
  height: auto;
  outline: none;
  text-decoration: none;
}
body,
#bodyTable,
#bodyCell {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
.mcnPreviewText {
  display: none !important;
}
#outlook a {
  padding: 0;
}
img {
  -ms-interpolation-mode: bicubic;
}
.ReadMsgBody {
  width: 100%;
}
.ExternalClass {
  width: 100%;
}
a[href^="tel"],
a[href^="sms"] {
  color: inherit;
  cursor: default;
  text-decoration: none;
}
p,
a,
li,
td,
body,
table,
blockquote {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
.ExternalClass,
.ExternalClass p,
.ExternalClass td,
.ExternalClass div,
.ExternalClass span,
.ExternalClass font {
  line-height: 100%;
}
a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}
#bodyCell {
  padding: 10px;
}
.templateContainer {
  max-width: 600px !important;
}
a.mcnButton {
  display: block;
}
.mcnImage,
.mcnRetinaImage {
  vertical-align: bottom;
}
.mcnTextContent {
  word-break: break-word;
}
.mcnTextContent img {
  height: auto !important;
}
.mcnDividerBlock {
  table-layout: fixed !important;
}
/*
@tab Page
@section Background Style
@tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
*/
body,
#bodyTable {
  /*@editable*/
  background-color: #fafafa;
}
/*
@tab Page
@section Background Style
@tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
*/
#bodyCell {
  /*@editable*/
  border-top: 0;
}
/*
@tab Page
@section Email Border
@tip Set the border for your email.
*/
.templateContainer {
  /*@editable*/
  border: 0;
}
/*
@tab Page
@section Heading 1
@tip Set the styling for all first-level headings in your emails. These should be the largest of your headings.
@style heading 1
*/
h1 {
  /*@editable*/
  color: #202020;
  /*@editable*/
  font-family: Helvetica;
  /*@editable*/
  font-size: 26px;
  /*@editable*/
  font-style: normal;
  /*@editable*/
  font-weight: bold;
  /*@editable*/
  line-height: 125%;
  /*@editable*/
  letter-spacing: normal;
  /*@editable*/
  text-align: left;
}
/*
@tab Page
@section Heading 2
@tip Set the styling for all second-level headings in your emails.
@style heading 2
*/
h2 {
  /*@editable*/
  color: #202020;
  /*@editable*/
  font-family: Helvetica;
  /*@editable*/
  font-size: 22px;
  /*@editable*/
  font-style: normal;
  /*@editable*/
  font-weight: bold;
  /*@editable*/
  line-height: 125%;
  /*@editable*/
  letter-spacing: normal;
  /*@editable*/
  text-align: left;
}
/*
@tab Page
@section Heading 3
@tip Set the styling for all third-level headings in your emails.
@style heading 3
*/
h3 {
  /*@editable*/
  color: #202020;
  /*@editable*/
  font-family: Helvetica;
  /*@editable*/
  font-size: 20px;
  /*@editable*/
  font-style: normal;
  /*@editable*/
  font-weight: bold;
  /*@editable*/
  line-height: 125%;
  /*@editable*/
  letter-spacing: normal;
  /*@editable*/
  text-align: left;
}
/*
@tab Page
@section Heading 4
@tip Set the styling for all fourth-level headings in your emails. These should be the smallest of your headings.
@style heading 4
*/
h4 {
  /*@editable*/
  color: #202020;
  /*@editable*/
  font-family: Helvetica;
  /*@editable*/
  font-size: 18px;
  /*@editable*/
  font-style: normal;
  /*@editable*/
  font-weight: bold;
  /*@editable*/
  line-height: 125%;
  /*@editable*/
  letter-spacing: normal;
  /*@editable*/
  text-align: left;
}
/*
@tab Preheader
@section Preheader Style
@tip Set the background color and borders for your email's preheader area.
*/
#templatePreheader {
  /*@editable*/
  background-color: #fafafa;
  /*@editable*/
  background-image: none;
  /*@editable*/
  background-repeat: no-repeat;
  /*@editable*/
  background-position: center;
  /*@editable*/
  background-size: cover;
  /*@editable*/
  border-top: 0;
  /*@editable*/
  border-bottom: 0;
  /*@editable*/
  padding-top: 9px;
  /*@editable*/
  padding-bottom: 9px;
}
/*
@tab Preheader
@section Preheader Text
@tip Set the styling for your email's preheader text. Choose a size and color that is easy to read.
*/
#templatePreheader .mcnTextContent,
#templatePreheader .mcnTextContent p {
  /*@editable*/
  color: #656565;
  /*@editable*/
  font-family: Helvetica;
  /*@editable*/
  font-size: 12px;
  /*@editable*/
  line-height: 150%;
  /*@editable*/
  text-align: left;
}
/*
@tab Preheader
@section Preheader Link
@tip Set the styling for your email's preheader links. Choose a color that helps them stand out from your text.
*/
#templatePreheader .mcnTextContent a,
#templatePreheader .mcnTextContent p a {
  /*@editable*/
  color: #656565;
  /*@editable*/
  font-weight: normal;
  /*@editable*/
  text-decoration: underline;
}
/*
@tab Header
@section Header Style
@tip Set the background color and borders for your email's header area.
*/
#templateHeader {
  /*@editable*/
  background-color: transparent;
  /*@editable*/
  background-image: none;
  /*@editable*/
  background-repeat: no-repeat;
  /*@editable*/
  background-position: center;
  /*@editable*/
  background-size: cover;
  /*@editable*/
  border-top: 0;
  /*@editable*/
  border-bottom: 0;
  /*@editable*/
  padding-top: 9px;
  /*@editable*/
  padding-bottom: 0;
}
/*
@tab Header
@section Header Text
@tip Set the styling for your email's header text. Choose a size and color that is easy to read.
*/
#templateHeader .mcnTextContent,
#templateHeader .mcnTextContent p {
  /*@editable*/
  color: #202020;
  /*@editable*/
  font-family: Helvetica;
  /*@editable*/
  font-size: 16px;
  /*@editable*/
  line-height: 150%;
  /*@editable*/
  text-align: left;
}
/*
@tab Header
@section Header Link
@tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
*/
#templateHeader .mcnTextContent a,
#templateHeader .mcnTextContent p a {
  /*@editable*/
  color: #007c89;
  /*@editable*/
  font-weight: normal;
  /*@editable*/
  text-decoration: underline;
}
/*
@tab Body
@section Body Style
@tip Set the background color and borders for your email's body area.
*/
#templateBody {
  /*@editable*/
  background-color: #ffffff;
  /*@editable*/
  background-image: none;
  /*@editable*/
  background-repeat: no-repeat;
  /*@editable*/
  background-position: center;
  /*@editable*/
  background-size: cover;
  /*@editable*/
  border-top: 0;
  /*@editable*/
  border-bottom: 2px solid #eaeaea;
  /*@editable*/
  padding-top: 0;
  /*@editable*/
  padding-bottom: 9px;
}
/*
@tab Body
@section Body Text
@tip Set the styling for your email's body text. Choose a size and color that is easy to read.
*/
#templateBody .mcnTextContent,
#templateBody .mcnTextContent p {
  /*@editable*/
  color: #202020;
  /*@editable*/
  font-family: Helvetica;
  /*@editable*/
  font-size: 16px;
  /*@editable*/
  line-height: 150%;
  /*@editable*/
  text-align: left;
}
/*
@tab Body
@section Body Link
@tip Set the styling for your email's body links. Choose a color that helps them stand out from your text.
*/
#templateBody .mcnTextContent a,
#templateBody .mcnTextContent p a {
  /*@editable*/
  color: #007c89;
  /*@editable*/
  font-weight: normal;
  /*@editable*/
  text-decoration: underline;
}
/*
@tab Footer
@section Footer Style
@tip Set the background color and borders for your email's footer area.
*/
#templateFooter {
  /*@editable*/
  background-color: #000000;
  /*@editable*/
  background-image: none;
  /*@editable*/
  background-repeat: no-repeat;
  /*@editable*/
  background-position: center;
  /*@editable*/
  background-size: cover;
  /*@editable*/
  border-top: 0;
  /*@editable*/
  border-bottom: 0;
  /*@editable*/
  padding-top: 9px;
  /*@editable*/
  padding-bottom: 9px;
}
/*
@tab Footer
@section Footer Text
@tip Set the styling for your email's footer text. Choose a size and color that is easy to read.
*/
#templateFooter .mcnTextContent,
#templateFooter .mcnTextContent p {
  /*@editable*/
  color: #656565;
  /*@editable*/
  font-family: Helvetica;
  /*@editable*/
  font-size: 12px;
  /*@editable*/
  line-height: 150%;
  /*@editable*/
  text-align: center;
}
/*
@tab Footer
@section Footer Link
@tip Set the styling for your email's footer links. Choose a color that helps them stand out from your text.
*/
#templateFooter .mcnTextContent a,
#templateFooter .mcnTextContent p a {
  /*@editable*/
  color: #656565;
  /*@editable*/
  font-weight: normal;
  /*@editable*/
  text-decoration: underline;
}
</style>
