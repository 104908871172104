<template>
  <div class="d-flex-column">
    <CheckBoxGroup
      :choices="genders"
      :min="0"
      :max="1"
      name="Genders"
      v-model="inputVal.gender"
    />
    <!-- <CheckBoxGroup
      :choices="ageLevels"
      :min="0"
      :max="1"
      name="Age Levels"
      v-model="inputVal.ageLvl"
    /> -->
    <CheckBoxGroup
      :choices="ageLevels"
      :min="0"
      name="Age Levels"
      v-model="inputVal.ageLvl"
    />
    <!-- <CheckBoxGroup
      :min="0"
      :max="1"
      :choices="memberTypes"
      name="Member Types"
      v-model="inputVal.memberType"
    /> -->
    <CheckBoxGroup
      :min="0"
      :choices="memberTypes"
      name="Member Types"
      v-model="inputVal.memberType"
    />

    <CheckBoxGroup
      :min="0"
      :choices="regoFinancials"
      name="Registration Financial"
      v-model="inputVal.regoFinancial"
    />

    <CheckBoxGroup
      :min="0"
      :max="1"
      :choices="lastRegistrationDateOrders"
      name="Last registration date"
      v-model="inputVal.lastRegistrationDateOrder"
    />

    <div class="mt-2 d-flex-column">
      <div class="m-1">
        <el-button
            @click="clear"
            class="full-width button-invisible">Clear</el-button>
        <el-button
            @click='submit'
            type="primary"
            class="full-width">Apply Filters</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import CheckBoxGroup from '@/components/CheckboxGroup.vue';
import { memberTypes, ageLevels, nationals } from '../../utils/constants';
import { EventBus } from '../../bus';

export default {
  name: 'FilterPage',
  components: { CheckBoxGroup },
  props: ['value'],
  methods: {
    submit() {
      this.$emit('submit', this.inputVal);
      this.$emit('cancel');
    },
    clear() {
      this.$set(this.inputVal, 'gender', []);
      this.$set(this.inputVal, 'ageLvl', []);
      this.$set(this.inputVal, 'memberType', []);
      this.$set(this.inputVal, 'regoFinancial', []);
      this.$set(this.inputVal, 'lastRegistrationDateOrder', []);
      EventBus.$emit('clear');
      this.$emit('clear', this.inputVal);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  computed: {
    memberTypes() {
      if (this.$store.getters['user/activeRole'].national_id === nationals.TFA) {
        return memberTypes.filter(x => x.tfa);
      }
      return memberTypes;
    }
  },
  data() {
    return {
      inputVal: {
        gender: [],
        ageLvl: [],
        memberType: [],
        regoFinancial: [],
        lastRegistrationDateOrder: [],
      },
      genders: ['Male', 'Female'].map(g => ({ name: g, type: g })),
      ageLevels: ageLevels.map(age => ({ name: age.name, type: age.name })),
      regoFinancials: [{ name: 'Yes', type: true }, { name: 'No', type: false }],
      lastRegistrationDateOrders: [{name: 'Ascending', type: 'asc'}, {name: 'Descending', type: 'desc'}],
    };
  },
  mounted() {
    const {
      gender, ageLevel, memberType, regoFinancial, lastRegistrationDateOrder
    } = this.$store.state.members.filters;
    this.$set(this.inputVal, 'gender', gender || []);
    this.$set(this.inputVal, 'ageLvl', ageLevel || []);
    this.$set(this.inputVal, 'memberType', memberType || []);
    this.$set(this.inputVal, 'regoFinancial', regoFinancial === undefined ? [] : [regoFinancial]);
    this.$set(this.inputVal, 'lastRegistrationDateOrder', lastRegistrationDateOrder || []);
  },
};
</script>

<style lang="scss" scoped>

</style>
