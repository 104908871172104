<template>
  <div>
    <el-form
      label-position="top"
      :model="regiProduct"
      :rules="saleableitemsrules"
      ref="regisaleableitems"
    >
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="8">
          <el-form-item prop="competitionId" label="Competition">
            <el-select v-model="competitionId" placeholder="Competition" filterable>
              <el-option
                v-for="comp in availableCompetitions"
                :label="comp.name"
                :key="comp._id"
                :value="comp._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="saleableitemname" label="Name">
            <el-input
              type="text"
              autocomplete="off"
              v-model="saleableitemname"
              placeholder="Name"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="saleableitemdesc" label="Description">
            <el-input
              type="text"
              autocomplete="off"
              v-model="saleableitemdesc"
              placeholder="Description"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="saleableitemprice" label="Price">
            <el-input
              type="text"
              autocomplete="off"
              v-model="saleableitemprice"
              placeholder="Price"
            />
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <div>
          <el-form-item prop="requireSizes" label="Require Sizes">
            <el-select v-model="currentSalebleItem.requireSizes" placeholder="Require Sizes">
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="mandatory" label="Mandatory">
            <el-select v-model="currentSalebleItem.mandatory" placeholder="Require Sizes">
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="seasonPass" label="Single season purchase">
            <el-select v-model="currentSalebleItem.seasonPass" placeholder="Single season purchase">
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitSaleableitem('regisaleableitems')"
              >Add</el-button
            >
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <data-tables
      v-if="regiProduct.competitionSaleableItems.length > 0"
      :data="regiProduct.competitionSaleableItems"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="Competition" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.competitionId"
            placeholder="Competition"
            :disabled="!saleableoptionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="comp in availableCompetitions"
              :label="comp.name"
              :key="comp._id"
              :value="comp._id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Name" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.name"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Description" width="300">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.description"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Price" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.price"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Require Sizes" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.requireSizes"
            placeholder="Require sizes"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Mandatory" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.mandatory"
            placeholder="Mandatory"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Single season" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.seasonPass"
            placeholder="Single season purchase"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column label="Edit/Remove" width="300">
        <template slot-scope="scope">
          <el-button @click="handleEditRowSaleble(scope.$index)">
            <template v-if="!saleableoptionsdisabled[scope.$index]"> Edit </template>
            <template v-else> Save </template>
          </el-button>
          <el-button @click="handleRemoveRowSaleble(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { errormsg } from "../../utils/constants";

export default {
  name: "CompetitionSaleableItems",
  props: [],
  data() {
    const validateCompetitionId = (rule, value, callback) => {
      if (this.currentSalebleItem.competitionId === undefined) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const validateSalebleName = (rule, value, callback) => {
      if (!this.currentSalebleItem.name || this.currentSalebleItem.name === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSalebleDesc = (rule, value, callback) => {
      if (!this.currentSalebleItem.description || this.currentSalebleItem.description === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSaleblePrice = (rule, value, callback) => {
      if (!this.currentSalebleItem.price || parseFloat(this.currentSalebleItem.price) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };

    return {
      regiProduct: {
        _id: "",
        entity_type: "",
        entity_id: -1,
        products: [],
        discounts: [],
        familyDiscounts: [],
        saleableitems: [],
        competitionProducts: [],
        competitionSaleableItems: [],
        competitionDiscounts: [],
        additionalQuestions: [],
        competitionAdditionalQuestions: [],
      },

      entities: [],
      competitions: [],
      currentSalebleItem: {
        competitionId: undefined,
        name: undefined,
        description: undefined,
        price: undefined,
        requireSizes: false,
        mandatory: false,
        seasonPass: false,
        meta: {
          options: null,
          entityType: "",
          entityIds: [],
        },
      },
      saleableoptionsdisabled: [],
      saleableitemsrules: {
        competitionId: {
          required: true,
          validator: validateCompetitionId,
          message: errormsg.select_option,
          trigger: "change",
        },
        saleableitemname: {
          required: true,
          validator: validateSalebleName,
          message: errormsg.input_option,
          trigger: "blur",
        },
        saleableitemdesc: {
          required: true,
          validator: validateSalebleDesc,
          message: errormsg.input_option,
          trigger: "blur",
        },
        saleableitemprice: {
          required: true,
          validator: validateSaleblePrice,
          message: errormsg.input_option,
          trigger: "blur",
        },
      },
      tableProps: {
        border: true,
      },
    };
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then((response) => {
        this.competitions = response.data.data;
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$customError();
      });
    this.$http.get("/nrl/api/v1/admin/registration-products").then((response) => {
      const product =
        response.data.data && response.data.data.length > 0 ? response.data.data[0] : null;

      if (product) {
        const {
          _id,
          entityType: entity_type,
          entityId: entity_id,
          competitionProducts,
          products,
          discounts,
          familyDiscounts,
          saleableItems,
          competitionSaleableItems,
        } = product;

        if (entity_type === "") return;

        this.regiProduct = {
          _id,
          entity_type,
          entity_id,
          products: products.map((p) => ({
            _id: p._id,
            role: p.memberType,
            gender: p.gender,
            agefrom: p.ageFrom,
            ageto: p.ageTo,
            datefrom: p.dateFrom,
            dateto: p.dateTo,
            price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
            price_int: p.price,
            status: p.isRegoOpen,
            payment: p.onlinePaymentRequired,
          })),
          saleableitems: saleableItems
            ? saleableItems.map((p) => ({
                _id: p._id,
                name: p.name,
                description: p.description,
                types: p.memberTypes,
                price_int: p.price,
                price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                requireSizes: p.requireSizes || false,
                mandatory: p.mandatory || false,
                seasonPass: p.seasonPass || false,
                seasonPassFrom: p.seasonPassFrom,
                seasonPassTo: p.seasonPassTo,
                seasonPassExComps: p.seasonPassExComps,
                gender: p.gender || "",
                ageFrom: p.ageFrom,
                ageTo: p.ageTo,
                dateFrom: p.dateFrom,
                dateTo: p.dateTo,
                url: p.url,
                meta: {
                  options: _.get(p, "meta.options", []),
                  entityIds: _.get(p, "meta.entityIds", []),
                  entityType: _.get(p, "meta.entityType", undefined),
                },
              }))
            : [],
          discounts: discounts
            ? discounts.map((p) => ({
                _id: p._id,
                type: p.type === "dollar" ? "$" : "%",
                discountName: p.name,
                discountFrom: p.fromDate,
                discountTo: p.toDate,
                discountAmount:
                  p.type === "dollar"
                    ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                    : p.amount.toString(),
                discountAmount_int: p.amount,
                discountMemberType: p.memberType,
              }))
            : [],
          familyDiscounts: familyDiscounts
            ? familyDiscounts.map((p) => ({
                _id: p._id,
                familyFrom: p.familyFrom,
                familyTo: p.familyTo,
                discountAmount: (parseFloat(p.amount) / 100).toFixed(2).toString(),
                discountAmount_int: p.amount,
                discountMemberType: p.memberType,
              }))
            : [],
          competitionProducts: competitionProducts
            ? competitionProducts.map((p) => ({
                _id: p._id,
                competitionId: p.competitionId,
                type: p.type,
                price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
                price_int: p.price,
                status: p.isRegoOpen,
                payment: p.onlinePaymentRequired,
              }))
            : [],
          competitionSaleableItems: competitionSaleableItems
            ? competitionSaleableItems.map((p) => ({
                _id: p._id,
                competitionId: p.competitionId,
                name: p.name,
                description: p.description,
                price_int: p.price,
                price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                requireSizes: p.requireSizes || false,
                mandatory: p.mandatory || false,
                seasonPass: p.seasonPass || false,
              }))
            : [],
        };

        this.optionsdisabled = new Array(this.regiProduct.products.length).fill(false);
        this.discountoptionsdisabled = new Array(this.regiProduct.discounts.length).fill(false);
        this.familydiscountoptionsdisabled = new Array(
          this.regiProduct.familyDiscounts.length
        ).fill(false);
        this.saleableoptionsdisabled = new Array(this.regiProduct.saleableitems.length).fill(false);
      }
      this.$store.commit("root/loading", false);
    });
  },
  methods: {
    submitSaleableitem(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // Check if item name already exists
          const foundItems = this.regiProduct.competitionSaleableItems.filter(
            (d) =>
              d.competitionId === this.currentSalebleItem.competitionId &&
              d.name === this.currentSalebleItem.name
          );
          if (foundItems && foundItems.length > 0) {
            this.$customError(errormsg.regiproduct_saleableitemsamename);
            return false;
          }
          this.$store.commit("root/loading", true);
          const compSaleableItem = {
            competitionId: this.currentSalebleItem.competitionId,
            name: this.currentSalebleItem.name,
            description: this.currentSalebleItem.description,
            price_int: parseInt(parseFloat(this.currentSalebleItem.price).toFixed(2) * 100),
            price: parseFloat(this.currentSalebleItem.price).toFixed(2),
            requireSizes: this.currentSalebleItem.requireSizes,
            mandatory: this.currentSalebleItem.mandatory,
            seasonPass: this.currentSalebleItem.seasonPass,
            meta: this.currentSalebleItem.meta,
          };

          const insert = {
            competitionId: compSaleableItem.competitionId,
            name: compSaleableItem.name,
            description: compSaleableItem.description,
            price: compSaleableItem.price_int,
            requireSizes: compSaleableItem.requireSizes,
            mandatory: compSaleableItem.mandatory,
            seasonPass: compSaleableItem.seasonPass,
            meta: compSaleableItem.meta,
          };

          const url = "/nrl/api/v1/admin/registration-products/competition-saleable-items";
          this.$http
            .post(url, insert)
            .then((response) => {
              compSaleableItem._id = response.data.data._id;
              this.regiProduct.competitionSaleableItems.push(compSaleableItem);
              this.$store.commit("root/loading", false);
              this.saleableoptionsdisabled.push(false);
              this.$customSuccess();
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(error.response.data.message);
            });

          this.currentSalebleItem = {
            competitionId: undefined,
            name: undefined,
            description: undefined,
            price: undefined,
            requireSizes: false,
            mandatory: false,
            seasonPass: false,
          };

          return true;
        }
        return false;
      });
    },
    handleEditRowSaleble(rowindex) {
      if (this.saleableoptionsdisabled[rowindex]) {
        const row = this.regiProduct.competitionSaleableItems[rowindex];
        if (!row.name || row.name === "") {
          this.$customError(errormsg.input_option);
          return false;
        }
        if (Number.isNaN(parseFloat(row.price)) || parseFloat(row.price) < 0) {
          this.$customError(errormsg.price_invalid);
          return false;
        }
        const foundItems = this.regiProduct.competitionSaleableItems.filter(
          (d, index) =>
            d.competitionId === row.competitionId && d.name === row.name && index !== rowindex
        );
        if (foundItems && foundItems.length > 0) {
          this.$customError(errormsg.regiproduct_saleableitemsamename);
          return false;
        }
        this.$store.commit("root/loading", true);
        this.regiProduct.competitionSaleableItems[rowindex].price = parseFloat(row.price).toFixed(
          2
        );
        this.regiProduct.competitionSaleableItems[rowindex].price_int = parseInt(
          parseFloat(row.price).toFixed(2) * 100
        );

        const compSaleableItem = this.regiProduct.competitionSaleableItems[rowindex];
        const update = {
          competitionId: compSaleableItem.competitionId,
          name: compSaleableItem.name,
          description: compSaleableItem.description,
          price: compSaleableItem.price_int,
          requireSizes: compSaleableItem.requireSizes,
          mandatory: compSaleableItem.mandatory,
          seasonPass: compSaleableItem.seasonPass,
        };
        if (compSaleableItem.meta) update.meta = compSaleableItem.meta;
        const url = `/nrl/api/v1/admin/registration-products/competition-saleable-items/${compSaleableItem._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
            this.$set(
              this.saleableoptionsdisabled,
              rowindex,
              !this.saleableoptionsdisabled[rowindex]
            );
          })
          .catch((error) => {
            this.$store.commit("root/loading", false);
            this.$customError(error.response.data.message);
          });
        return true;
      }
      this.$set(this.saleableoptionsdisabled, rowindex, !this.saleableoptionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRowSaleble(index) {
      this.$store.commit("root/loading", true);
      const compSaleableItem = this.regiProduct.competitionSaleableItems[index];
      this.$http
        .delete(
          `/nrl/api/v1/admin/registration-products/competition-saleable-items/${compSaleableItem._id}`
        )
        .then(() => {
          this.regiProduct.competitionSaleableItems.splice(index, 1);
          this.saleableoptionsdisabled.splice(index, 1);
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch((error) => {
          this.$store.commit("root/loading", false);
          this.$customError(error.response.data.message);
        });
    },
  },
  computed: {
    availableCompetitions() {
      return this.competitions.filter((c) =>
        this.regiProduct.competitionProducts.map((p) => p.competitionId).includes(c._id)
      );
    },
    competitionId: {
      get() {
        return this.currentSalebleItem.competitionId;
      },
      set(value) {
        this.currentSalebleItem.competitionId = value || undefined;
      },
    },
    saleableitemname: {
      get() {
        return this.currentSalebleItem.name;
      },
      set(value) {
        this.currentSalebleItem.name = value;
      },
    },
    saleableitemdesc: {
      get() {
        return this.currentSalebleItem.description;
      },
      set(value) {
        this.currentSalebleItem.description = value;
      },
    },
    saleableitemprice: {
      get() {
        return this.currentSalebleItem.price;
      },
      set(value) {
        this.currentSalebleItem.price =
          !Number.isNaN(parseFloat(value)) && parseFloat(value) >= 0 ? value : null;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
</style>
