<template>
  <div>
    <el-row v-if="isSuperAdmin" :gutter="10" class="headers">
      <el-col :span="24">
        <el-button type="success" @click="addRegistration" icon="el-icon-plus">
          Add Registration
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-if="currentMember.memberRegos && currentMember.memberRegos.length > 0">
      <el-col :span="24">
        <el-table :data="currentMember.memberRegos" :border="true" scrollY :maxHeight="innerHeight">
          <el-table-column fixed="fixed" label="Team Name" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.team && scope.row.team.name }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="fixed" label="Club Name" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.club && scope.row.club.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Association Name" fixed="fixed" width="150">
            <template slot-scope="scope">
              <span>{{
                (scope.row.team && scope.row.team.orgtree.association.name) ||
                (scope.row.association && scope.row.association.name) ||
                (scope.row.club && scope.row.club.orgtree.association.name)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Game Dev Region Name" fixed="fixed" width="200">
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row &&
                  scope.row.program &&
                  scope.row.program.orgtree &&
                  scope.row.program.orgtree.gamedevregion &&
                  scope.row.program.orgtree.gamedevregion.name
                "
                >{{ scope.row.program.orgtree.gamedevregion.name }}</span
              >
              <span
                v-else-if="
                  scope.row &&
                  scope.row.program &&
                  scope.row.program.orgtree &&
                  scope.row.program.orgtree.club &&
                  scope.row.program.orgtree.club.name
                "
                >{{ scope.row.program.orgtree.club.name }}</span
              >
              <span v-else>{{ "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Program Name" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.program && scope.row.program.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="entityType" label="Entity Type" width="100"> </el-table-column>
          <el-table-column prop="memberType" label="Member Type" width="120"> </el-table-column>
          <el-table-column prop="season" label="Season" width="100"> </el-table-column>
          <el-table-column :formatter="statusFormatter" label="Status" width="100">
          </el-table-column>
          <el-table-column label="Registration Financial?" width="200">
            <template slot-scope="scope">
              <el-select
                v-model="currentMember.memberRegos[scope.$index].paid"
                :disabled="
                  currentMember.memberRegos[scope.$index].paid ||
                  currentMember.memberRegos[scope.$index].expired
                "
                @change="handleChangePaid(scope)"
              >
                <el-option
                  v-for="item in [
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },
                  ]"
                  :label="item.label"
                  :key="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Notes" width="300">
            <template slot-scope="scope">
              <el-button @click="handleNotes(scope)">Add/View Notes</el-button>
            </template>
          </el-table-column>
          <el-table-column label="Deregistration" width="200">
            <template
              slot-scope="scope"
              v-if="canDeregister(currentMember.memberRegos[scope.$index])"
            >
              <el-button @click="handleDeregister(scope)">Deregister</el-button>
            </template>
          </el-table-column>
          <el-table-column label="Resend Receipt" width="250">
            <template
              slot-scope="scope"
              v-if="
                canSendReceipt(currentMember.memberRegos[scope.$index]) &&
                !isManual(currentMember.memberRegos[scope.$index])
              "
            >
              <el-button @click="sendReceipt(currentMember.memberRegos[scope.$index]._id)"
                >Resend Receipt</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog
      title="Add Note"
      :visible.sync="addNotesVisible"
      width="60%"
      class="squads-dialog"
      :close-on-click-modal="false"
    >
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in regoNotes"
          :key="index"
          color="#098255"
          :timestamp="formatTimestamp(item.createdAt)"
        >
          {{ item.note }}
        </el-timeline-item>
      </el-timeline>
      <el-form label-position="top" ref="notes">
        <el-form-item prop="note" label="Add Registration Note">
          <el-input
            autocomplete="off"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 20 }"
            v-model="regoNote"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addNotesVisible = false">Cancel</el-button>
        <el-button type="success" @click="handleAddRegoNote()">Add Note</el-button>
      </span>
    </el-dialog>

    <!-- NRL Manual Registration -->
    <el-dialog
      :visible.sync="addToClubVisible"
      class="squads-dialog"
      @opened="addToClubOpened"
      @closed="addToClubClosed"
      :close-on-click-modal="false"
    >
      <h4 slot="title" class="centered-title dialog-title">Add To Club</h4>
      <div class="add-to-club-box">
        <el-row :gutter="10" class="select-team">
          <el-form
            label-position="left"
            label-width="110px"
            size="medium"
            v-loading="loadingSquadEntity"
            element-loading-background="rgba(255,255,255,0.5)"
          >
            <el-form-item label="State">
              <el-select
                filterable
                v-model="newRegistration.state"
                placeholder="Please select a state"
                @change="squadEntityChange($event, 'state')"
              >
                <el-option
                  v-for="entity in squadEntity.states"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Region">
              <el-select
                filterable
                v-model="newRegistration.region"
                placeholder="Please select a region"
                @change="squadEntityChange($event, 'region')"
              >
                <el-option
                  v-for="entity in squadEntity.regions"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Association">
              <el-select
                filterable
                v-model="newRegistration.association"
                placeholder="Please select an association"
                @change="squadEntityChange($event, 'association')"
              >
                <el-option
                  v-for="entity in squadEntity.associations"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Club">
              <el-select
                filterable
                v-model="newRegistration.club"
                placeholder="Please select a club"
                @change="squadEntityChange($event, 'club')"
              >
                <el-option
                  v-for="entity in squadEntity.clubs"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Member Type">
              <el-select
                filterable
                v-model="newRegistration.memberType"
                placeholder="Please select a member type"
              >
                <el-option
                  v-for="option in allMemberTypes"
                  :key="option.value"
                  :label="option.name"
                  :value="option.value"
                >
                  {{ option.name }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-row>
        <el-row class="operations">
          <el-button @click="addToClubVisible = false">Cancel</el-button>
          <el-button type="success" @click="confirmAddToClub">Confirm</el-button>
        </el-row>
      </div>
    </el-dialog>

    <!-- TFA Manual Registration -->
    <el-dialog
      v-if="isTFA"
      :visible.sync="manualTFAVisible"
      class="squads-dialog"
      @opened="addToClubOpened"
      @closed="addToClubClosed"
      :close-on-click-modal="false"
    >
      <h3 slot="title" class="centered-title dialog-title">Manual Registration</h3>
      <div class="d-flex manual-rego-choices" v-if="manualTFAStep === 1">
        <el-card
          class="manual-rego-choice-card"
          shadow="hover"
          @click.native="selectManualTFA('team')"
        >
          Add To Team
        </el-card>
        <el-card
          class="manual-rego-choice-card"
          shadow="hover"
          @click.native="selectManualTFA('association')"
        >
          Add To Association
        </el-card>
      </div>
      <div class="add-to-club-box" v-if="manualTFAStep === 2">
        <el-row :gutter="10" class="select-team">
          <el-form
            label-position="left"
            label-width="110px"
            size="medium"
            v-loading="loadingSquadEntity"
            element-loading-background="rgba(255,255,255,0.5)"
          >
            <el-form-item label="State">
              <el-select
                filterable
                v-model="newRegistration.state"
                placeholder="Please select a state"
                @change="squadEntityChange($event, 'state')"
              >
                <el-option
                  v-for="entity in squadEntity.states"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Region">
              <el-select
                filterable
                v-model="newRegistration.region"
                placeholder="Please select a region"
                @change="squadEntityChange($event, 'region')"
              >
                <el-option
                  v-for="entity in squadEntity.regions"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Association">
              <el-select
                filterable
                v-model="newRegistration.association"
                placeholder="Please select an association"
                @change="squadEntityChange($event, 'association')"
              >
                <el-option
                  v-for="entity in squadEntity.associations"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Club" v-if="manualTFAEntity === 'team'">
              <el-select
                filterable
                v-model="newRegistration.club"
                placeholder="Please select a club"
                @change="squadEntityChange($event, 'club')"
              >
                <el-option
                  v-for="entity in squadEntity.clubs"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Team" v-if="manualTFAEntity === 'team'">
              <el-select
                filterable
                v-model="newRegistration.team"
                placeholder="Please select a team"
              >
                <el-option
                  v-for="entity in squadEntity.teams"
                  :value="entity._id"
                  :label="entity.name"
                  :key="entity._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Member Type">
              <el-select
                filterable
                v-model="newRegistration.memberType"
                placeholder="Please select a member type"
              >
                <el-option
                  v-for="option in manualTFAMemberTypes"
                  :key="option.value"
                  :label="option.name"
                  :value="option.value"
                >
                  {{ option.name }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-row>
        <el-row class="operations">
          <el-button @click="manualTFAVisible = false">Cancel</el-button>
          <el-button type="success" @click="confirmManualTFARego">Confirm</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

import { roles, memberTypes, nationals } from "../../utils/constants";
import { EventBus } from "../../bus";

export default {
  props: {
    member: { type: Object, required: true },
  },
  data() {
    return {
      squadEntity: {
        states: [],
        regions: [],
        associations: [],
        clubs: [],
        teams: [],
      },
      newRegistration: {
        state: undefined,
        region: undefined,
        association: undefined,
        club: undefined,
        team: undefined,
      },
      addToClubVisible: false,
      loadingSquadEntity: false,
      addNotesVisible: false,
      addNotesRegoId: false,
      regoNote: "",
      roles,
      nationals,
      manualTFAVisible: false,
      manualTFAEntity: "team",
      manualTFAStep: 1,
      innerHeight: window.innerHeight - 286 < 286 ? 286 : window.innerHeight - 250,
    };
  },
  mounted() {
    EventBus.$on("memberUpdated", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    isManual(rego) {
      return _.get(rego, "meta.manual", false);
    },
    addRegistration() {
      if (!this.isTFA) this.addToClubVisible = true;
      else this.manualTFAVisible = true;
    },
    selectManualTFA(type) {
      this.manualTFAEntity = type;
      this.manualTFAStep = 2;
    },
    addToClubOpened() {
      this.loadingSquadEntity = true;
      this.$http
        .get("/nrl/api/v1/admin/states")
        .then((response) => {
          this.loadingSquadEntity = false;
          this.squadEntity.states = response.data.data;
        })
        .catch((e) => {
          this.loadingSquadEntity = false;
          this.$customError(e.response.data.message);
        });
    },
    squadEntityChange(id, entityType) {
      this.loadingSquadEntity = true;
      const entityLevels = ["state", "region", "association", "club", "team"];

      const entityIndex = entityLevels.indexOf(entityType);
      const childEntityName = `${entityLevels[entityIndex + 1]}s`;

      this.$http
        .get(`/nrl/api/v1/admin/${childEntityName}/${entityType}/${id}`)
        .then((response) => {
          this.loadingSquadEntity = false;

          // Set squad entity
          this.squadEntity[childEntityName] = response.data.data;

          // Clear all levels below
          const clearEntities = _.takeRightWhile(entityLevels, (t) => t !== entityType);
          for (const e of clearEntities) this.newRegistration[e] = undefined;

          // Fetching association teams for TFA - Club selection is optional
          if (entityType === "association" && this.isTFA) {
            this.$http
              .get("/nrl/api/v1/admin/teams")
              .then((res) => {
                const myteams = res.data.data;
                this.squadEntity.teams = myteams.filter(
                  (t) => t.orgtree.association && t.orgtree.association._id === id
                );
              })
              .catch(() => {
                this.$customError("Error fetching association teams");
              });
          }
        })
        .catch((e) => {
          this.loadingSquadEntity = false;
          this.$customError(e.response.data.message);
        });
    },
    confirmAddToClub() {
      const memberId = this.currentMember._id;
      const entityId = this.newRegistration.club;
      const { memberType } = this.newRegistration;

      // Validation for club
      if (entityId === undefined) {
        this.$customError("Please select a club.");
        return;
      }

      this.$store.commit("root/loading", true);
      this.$http
        .post("/nrl/api/v1/admin/members/create-registration", { memberId, entityId, memberType })
        .then((response) => {
          this.$store.commit("root/loading", false);
          this.addToClubVisible = false;
          this.$customSuccess();
          this.currentMember.memberRegos = response.data.data;
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e.response.data.message);
        });
    },
    confirmManualTFARego() {
      const memberId = this.currentMember._id;
      const entityId = this.newRegistration[this.manualTFAEntity];
      const entityType = this.manualTFAEntity;
      const { memberType } = this.newRegistration;
      // Validation for entity
      if (entityId === undefined) {
        this.$customError(`Please select a ${this.manualTFAEntity}.`);
        return;
      }

      this.$store.commit("root/loading", true);
      this.$http
        .post("/nrl/api/v1/admin/members/create-tfa-registration", {
          memberId,
          entityId,
          entityType,
          memberType,
        })
        .then((response) => {
          this.$store.commit("root/loading", false);
          this.manualTFAVisible = false;
          this.$customSuccess();
          this.currentMember.memberRegos = response.data.data;
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e.response.data.message);
        });
    },
    addToClubClosed() {
      this.addToClubVisible = false;
      this.newRegistration = {
        state: undefined,
        region: undefined,
        association: undefined,
        club: undefined,
        team: undefined,
        memberType: undefined,
      };

      // Reset manual TFA to show first screen
      this.manualTFAVisible = false;
      this.manualTFAStep = 1;
    },
    canDeregister(rego) {
      if (rego.expired) return false;
      if (this.isSuperAdmin || this.isGDRAdmin) return true;
      if (!rego.club && !rego.team && !rego.association) return false;
      if (
        this.clearance <= roles.associationAdmin &&
        rego.national_id === this.$store.getters["user/activeRole"].national_id
      )
        return true;
      return false;
    },
    canSendReceipt(rego) {
      return (
        rego.paid &&
        !rego.expired &&
        this.$store.getters["user/activeRole"].type === roles.superAdmin
      );
    },
    sendReceipt(regoid) {
      this.$store.commit("root/loading", true);
      this.$http
        .get(`/nrl/api/v1/admin/members/regofinancial/${regoid}/resend-receipt`)
        .then(() => {
          this.$customSuccess("Receipt has been resent successfully");
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError("We encountered problems sending your receipt");
          this.$store.commit("root/loading", false);
        });
    },
    handleChangePaid(scope) {
      if (this.currentMember.memberRegos[scope.$index].paid) {
        this.$confirm(
          "Are you sure you want to mark this registration as financial? Note: By marking this registration as financial you are confirming you have taken payment from the member. Once approved this cannot be undone",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            const rego = this.currentMember.memberRegos[scope.$index];
            const update = {
              paid: rego.paid,
            };
            this.$store.commit("root/loading", true);
            const url = `/nrl/api/v1/admin/members/regofinancial/${rego._id}`;
            this.$http
              .put(url, update)
              .then(() => {
                const updatedMemberRegos = [...this.currentMember.memberRegos];
                updatedMemberRegos[scope.$index].paid = true;
                this.$set(this.currentMember, "memberRegos", updatedMemberRegos);
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch((e) => {
                const updatedMemberRegos = [...this.currentMember.memberRegos];
                updatedMemberRegos[scope.$index].paid = false;
                this.$set(this.currentMember, "memberRegos", updatedMemberRegos);
                this.$store.commit("root/loading", false);
                this.$customError(e.response.data.message);
              });
          })
          .catch(() => {
            this.currentMember.memberRegos[scope.$index].paid = false;
          });
      }
    },
    handleNotes(scope) {
      if (scope && scope.row && scope.row._id) {
        this.addNotesVisible = true;
        this.addNotesRegoId = scope.row._id;
      } else {
        this.addNotesRegoId = false;
      }
    },
    handleAddRegoNote() {
      const regoid = this.addNotesRegoId;
      const note = this.regoNote;
      const rego = _.find(this.currentMember.memberRegos, (el) => el._id === regoid);
      if (regoid && note !== "" && rego) {
        this.$store.commit("root/loading", true);
        const url = `/nrl/api/v1/admin/members/registration-notes/${regoid}`;
        this.$http
          .post(url, { note })
          .then(() => {
            this.addNotesRegoId = false;
            this.addNotesVisible = false;
            this.regoNote = "";

            const foundIndex = _.findIndex(
              this.currentMember.memberRegos,
              (el) => el._id === regoid
            );
            if (foundIndex !== -1) {
              if (_.get(this.currentMember.memberRegos[foundIndex], "meta.notes")) {
                this.currentMember.memberRegos[foundIndex].meta.notes.push({
                  note,
                  createdAt: moment().valueOf(),
                });
              } else {
                this.currentMember.memberRegos[foundIndex].meta.notes = [
                  {
                    note,
                    createdAt: moment().valueOf(),
                  },
                ];
              }
            }
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      } else {
        this.$customError();
      }
    },
    formatTimestamp(time) {
      return moment(time).format("MMMM Do YYYY, h:mm:ss a");
    },
    handleDeregister(scope) {
      if (this.currentMember.memberRegos[scope.$index]) {
        this.$confirm(
          "Are you sure you want to remove this registration for this member? This cannot be undone.",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            const rego = this.currentMember.memberRegos[scope.$index];
            this.$store.commit("root/loading", true);
            const url = `/nrl/api/v1/admin/members/deregister/${rego._id}`;
            this.$http
              .put(url)
              .then(() => {
                this.currentMember.memberRegos[scope.$index].expired = true;
                this.currentMember.memberRegos[scope.$index].deregistered = true;

                const { memberRegos } = this.currentMember;
                this.currentMember.memberRegos = [];
                this.currentMember.memberRegos = memberRegos;

                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch((e) => {
                this.$store.commit("root/loading", false);
                this.$customError(e.response.data.message);
              });
          })
          .catch(() => {});
      }
    },
    statusFormatter(row) {
      if (row.expired === true) return row.deregistered === true ? "Deregistered" : "Inactive";
      return "Active";
    },
  },
  computed: {
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === this.nationals.TFA;
    },
    isSuperAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.superAdmin;
    },
    isGDRAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.gamedevregionAdmin;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    regoNotes() {
      const regoid = this.addNotesRegoId;
      const rego = this.currentMember.memberRegos.find((el) => el._id === regoid);
      return rego && rego.meta && rego.meta.notes
        ? _.orderBy(rego.meta.notes, ["createdAt"], ["desc"])
        : [];
    },
    currentMember() {
      return this.member || { memberRegos: [] };
    },
    allMemberTypes() {
      return memberTypes.map((type) => ({ name: type.name, value: type.type }));
    },
    manualTFAMemberTypes() {
      const { manualTFAEntity } = this;
      if (manualTFAEntity === "association") {
        return memberTypes
          .filter((mt) => mt.type === "referee" || mt.type === "volunteer")
          .map((type) => ({ name: type.name, value: type.type }));
      }
      return memberTypes
        .filter((mt) => mt.type === "coach" || mt.type === "player-touch")
        .map((type) => ({ name: type.name, value: type.type }));
    },
  },
};
</script>

<style lang="scss" scoped>
.headers {
  margin-bottom: 1rem;
}

.add-to-club {
  margin-bottom: 1rem;
}

.dialog-title {
  font-weight: 500;
  letter-spacing: 0.05rem;
}

.el-dialog__body {
  .add-to-club-box {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .operations {
      margin-top: 1.75rem;
      margin-bottom: 1.55rem;
    }
  }

  .manual-rego-choices {
    justify-content: center;
    margin-bottom: 3rem;
    .manual-rego-choice-card {
      margin: 1rem;
      width: 20em;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.1rem;
      letter-spacing: 0.0694rem;
      font-weight: 400;
      &:hover {
        border: 1px solid;
        cursor: pointer;
      }
    }
  }
}

.el-dialog__header {
  padding-bottom: 0px;
}

.centered-title {
  width: 100%;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
}
</style>
