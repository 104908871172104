<template>
  <div>
    <CheckBoxGroup
      :choices="[
        { name: 'Approved', type: 'approved' },
        { name: 'Denied', type: 'denied' },
        { name: 'Not Actioned', type: 'not actioned' },
      ]"
      :min="0"
      :max="1"
      name="Dispensated"
      v-model="inputVal.status"
    />
    <div class="heading">Season</div>
    <el-select v-model="inputVal.season" placeholder="Select a season" class="heading">
      <el-option v-for="season in seasons" :label="season" :key="season" :value="season">
      </el-option>
    </el-select>
    <div class="mt-2 d-flex-column">
      <div class="mt-1 heading">
        <el-button @click="clear" class="full-width button-invisible">Clear</el-button>
        <el-button @click="submit" type="primary" class="full-width"
          >Apply Filters</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import CheckBoxGroup from "@/components/CheckboxGroup.vue";
import { EventBus } from "../../bus";
import { allSeasons, currentSeason } from "../../utils/constants";

export default {
  name: "FilterPage18MRP",
  components: { CheckBoxGroup },
  props: ["value"],
  methods: {
    submit() {
      this.$emit("submit", this.inputVal);
    },
    clear() {
      this.$set(this.inputVal, "status", []);
      this.$set(this.inputVal, "season", currentSeason);
      EventBus.$emit("clear");
      this.$emit("clear", this.inputVal);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  data() {
    const {
      params: { status, season },
    } = this.$store.state.eighteenmrp.eighteenmrp;
    return {
      inputVal: {
        status,
        season,
      },
      seasons: allSeasons,
    };
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 5px 15px 10px;
}
</style>
