/* eslint-disable no-return-assign */
import _ from "lodash";

const initState = () => ({
  pendings: {
    criteria: "",
    filters: {
      status: null,
    },
    data: [],
    pagination: {
      page: 1,
      pageSize: 20,
      total: 0,
    },
    params: {
      status: [],
      photo: [],
      documents: [],
    },
  },
});

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: (state) => _.assign(state, initState()),
    updatePending: (state, data) => _.assign(state.pendings, data),
    updatePendingParams: (state, data) => (state.pendings.params.status = data),
    updatePendingFilters: (state, payload, photo, documents) => (
      (state.pendings.filters.status = payload),
      (state.pendings.filters.photo = photo),
      (state.pendings.filters.documents = documents)
    ),
  },
};
