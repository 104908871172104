<template>
  <div class="tw-flex tw-justify-center">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-max-w-2xl tw-p-6 tw-mt-12 tw-bg-white tw-border tw-shadow-lg"
    >
      <div class="tw-p-4">
        <img src="~@/assets/shield_black.svg" alt="nrl" class="logo" />
      </div>

      <h4 class="tw-mt-2 tw-font-bold ">
        Welcome to MySideline - your competition and participant management system.
      </h4>
      <h4 class="tw-py-4 tw-text-justify">
        To access your club information please contact your League representative or simply attend
        an on-boarding session near you soon. For more information please visit our information
        <a
          class="tw-text-blue-700 tw-underline"
          href="https://www.playrugbyleague.com/clubs/upgrade/clubsleagues/mysideline-preview"
          target="_blank"
        >
          page
        </a>
      </h4>
      <h4 class="tw-text-justify ">
        By progressing to MySideline, you agree to abide by the policies of NRL as published at<a
          class="tw-px-2 tw-text-blue-700 tw-underline"
          href="https://www.playrugbyleague.com/policies"
          target="_blank"
        >
          https://www.playrugbyleague.com/policies</a
        >AND to access, use and/disclose information regarding registered participants only for the
        purposes of club administration and not to misuse or share any data contained within
        MySideline.
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "PendingRole",
  props: {},
  created() {},
  data() {
    return {};
  },
  methods: {},
  computed: {}
};
</script>

<style scoped lang="scss">
.wrap {
  height: 117px;
  max-height: 117px;
  text-align: center;
  .logo {
    max-height: 100%;
  }
}
.notfound-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .notfound-card {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 50%;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
  }
  @media only screen and (max-width: $sm) {
    .notfound-card {
      width: 80%;
    }
  }
}
</style>
