<template>
  <div>
    <el-form
      label-position="top"
      :model="program"
      :rules="saleableitemsrules"
      ref="regisaleableitems"
      :disabled="clearance === roles.programAdmin"
    >
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="4">
          <el-form-item prop="saleableitemname" label="Name">
            <el-input
              type="text"
              autocomplete="off"
              v-model="saleableitemname"
              placeholder="Name"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="saleableitemdesc" label="Description">
            <el-input
              type="text"
              autocomplete="off"
              v-model="saleableitemdesc"
              placeholder="Description"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="saleableitemprice" label="Price">
            <el-input
              type="text"
              autocomplete="off"
              v-model="saleableitemprice"
              placeholder="Price"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="requireSizes" label="Require Sizes">
            <el-select v-model="currentSaleableItem.requireSizes" placeholder="Member types">
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="mandatory" label="Mandatory">
            <el-select v-model="currentSaleableItem.mandatory" placeholder="Mandatory">
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="seasonPass" label="Single season purchase">
            <el-select
              v-model="currentSaleableItem.seasonPass"
              placeholder="Single season purchase"
            >
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="gender" label="Gender">
            <el-select v-model="currentSaleableItem.gender" placeholder="Gender">
              <el-option
                v-for="item in [
                  { label: 'All', value: '' },
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="m-1">
        <el-col :span="6">
          <el-form-item prop="ageFrom" label="Age from">
            <el-input
              type="number"
              autocomplete="off"
              v-model="currentSaleableItem.ageFrom"
              placeholder="Age from"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="ageTo" label="Age to">
            <el-input
              type="number"
              autocomplete="off"
              v-model="currentSaleableItem.ageTo"
              placeholder="Age to"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item prop="requestFulfillmentPack" label="Request Fulfillment Pack">
            <el-select
              v-model="currentSaleableItem.requestFulfillmentPack"
              placeholder=""
            >
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div id="child-img" class="tw-flex tw-flex-col tw-items-center tw-w-auto lg:tw-w-1/3">
        <label
          class="tw-self-start tw-hidden tw-pb-5 tw-mt-5 tw-text-sm tw-text-gray-500 tw-uppercase lg:tw-block"
        >
          Image
        </label>
        <div
          v-if="
            currentSaleableItem &&
            currentSaleableItem.url &&
            currentSaleableItem.url.length > 0 &&
            currentUpload
          "
          class="tw-relative tw-flex tw-flex-col tw-items-center tw-w-full tw-bg-white tw-divide-y tw-divide-gray-200"
        >
          <div class="tw-px-6 tw-py-4 tw-whitespace-nowrap">
            <div class="tw-flex tw-items-center tw-justify-center">
              <div class="tw-flex-shrink-0">
                <div class="avatar-wrap">
                  <div
                    :style="{
                      'background-image': `url(${this.currentSaleableItem.url})`,
                    }"
                    alt=""
                    class="avatar"
                  ></div>
                  <div
                    @click="replaceNewProdImg"
                    style="cursor: pointer"
                    title="Replace Current Image"
                    class="upload-logo"
                  >
                    <svg
                      id="camera"
                      class="tw-mx-auto"
                      width="18px"
                      height="16px"
                      viewBox="0 0 18 16"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Symbols"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Artboard"
                          transform="translate(-47.000000, -88.000000)"
                          fill="#FFFFFF"
                          fill-rule="nonzero"
                        >
                          <path
                            d="M48.9756098,103.852104 C47.8887171,103.852104 47,102.913299 47,101.765148 L47,92.4897855 C47,91.3416348 47.8887171,90.402829 48.9756098,90.402829 L51.7538,90.402829 L52.5220927,88.3810806 C52.5867013,88.2038545 52.74706,88.0861414 52.9268293,88.0839791 L59.0731707,88.0839791 C59.25294,88.0861414 59.4132987,88.2038545 59.4779073,88.3810806 L60.2462,90.402829 L63.0243902,90.402829 C64.1112829,90.402829 65,91.3416348 65,92.4897855 L65,101.765148 C65,102.913299 64.1112829,103.852104 63.0243902,103.852104 L48.9756098,103.852104 Z M48.9756098,102.924568 L63.0243902,102.924568 C63.6400341,102.924568 64.1219512,102.41549 64.1219512,101.765148 L64.1219512,92.4897855 C64.1219512,91.8394435 63.6400341,91.3303652 63.0243902,91.3303652 L59.9512195,91.3303652 C59.7731474,91.3293876 59.6132696,91.2148899 59.5464829,91.0405101 L58.7781902,89.0115154 L53.2218098,89.0115154 L52.4535171,91.0405101 C52.3867304,91.2148899 52.2268526,91.3293876 52.0487805,91.3303652 L48.9756098,91.3303652 C48.3599659,91.3303652 47.8780488,91.8394435 47.8780488,92.4897855 L47.8780488,101.765148 C47.8780488,102.41549 48.3599659,102.924568 48.9756098,102.924568 Z M56,101.30138 C53.8230317,101.30138 52.0487805,99.4271536 52.0487805,97.1274667 C52.0487805,94.8277565 53.8230317,92.9535536 56,92.9535536 C58.1770122,92.9535536 59.9512195,94.8277797 59.9512195,97.1274667 C59.9512195,99.4271536 58.1770122,101.30138 56,101.30138 Z M56,100.373843 C57.7024707,100.373843 59.0731707,98.9258667 59.0731707,97.1274667 C59.0731707,95.3290435 57.7024707,93.8810899 56,93.8810899 C54.2975732,93.8810899 52.9268293,95.3290435 52.9268293,97.1274667 C52.9268293,98.9258667 54.2975732,100.373843 56,100.373843 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-text-sm tw-text-gray-900 tw-absolute tw-right-0 tw-top-0.5">
            <el-dropdown @command="onCommandDropdown">
              <span style="" class="el-dropdown-link">
                <i class="el-icon-remove-outline el-icon--right" style="font-size: 1.6rem"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="replace-image">Replace image</el-dropdown-item>
                <el-dropdown-item command="remove-image-product">Remove image</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-w-full tw-p-4 tw-bg-white tw-border tw-border-gray-300"
          v-show="!this.currentSaleableItem.url"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="margin: 0 auto; opacity: 0.25"
            class="tw-w-20 tw-h-20"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
              clip-rule="evenodd"
            />
          </svg>
          <h4 class="tw-text-center">Drop files anywhere to upload<br />or</h4>
          <vue-upload-component
            v-if="this.$route.params.type !== 'insert'"
            ref="upload"
            inputId="upload"
            :drop="true"
            v-model="uploads"
            :extensions="extensions"
            :accept="accept"
            :post-action="productUploadURL"
            :headers="uploadHeaders"
            :data="saleableItemData"
            :multiple="true"
            @input-file="inputFile"
            @input-filter="inputFilter"
            class="el-button el-button--default upload"
            :class="{ hasUpload: currentSaleableItem.url }"
          >
            <span>Select File</span>
          </vue-upload-component>
        </div>
      </div>
      <el-row>
        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
          <h3>Drop files to upload</h3>
        </div>
      </el-row>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3">
        <el-col :span="6">
          <el-form-item>
            <el-button
              class="btn-container"
              v-if="clearance !== roles.programAdmin"
              type="success"
              @click="submitSaleableitem('regisaleableitems')"
              >Add</el-button
            >
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <label
      v-if="
        programSetting && programSetting.saleableItems && programSetting.saleableItems.length > 0
      "
      class="tw-w-full el-form-item__label tw-mt-4 tw-pb-0"
      >Program Type Settings Saleable Items</label
    >
    <data-tables
      v-if="
        programSetting && programSetting.saleableItems && programSetting.saleableItems.length > 0
      "
      :data="programSetting.saleableItems"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="Image" width="150">
        <template slot-scope="scope">
          <div v-if="saleableoptionsdisabled[scope.$index]">
            <vue-upload-component
              :ref="`productUpload-${scope.$index}`"
              :inputId="`productUpload-${scope.$index}`"
              :drop="false"
              v-model="editedUploadsObj[scope.$index]"
              :extensions="extensions"
              :accept="accept"
              :post-action="productUploadURL"
              :headers="uploadHeaders"
              :data="{ name: scope.row.name, programId: programId }"
              :multiple="true"
              @input-file="(newFile, oldFile) => inputEditFile(newFile, oldFile, scope)"
              @input-filter="
                (newFile, oldFile, prevent) => inputEditFilter(newFile, oldFile, prevent, scope)
              "
              class="el-button el-button--default upload"
              :class="{ hasEditUpload: scope.row.url }"
              :disabled="!saleableoptionsdisabled[scope.$index]"
            >
              <span v-if="!scope.row.url">Upload</span>
              <div v-else class="avatar-wrap">
                <div
                  :style="{ 'background-image': `url(${scope.row.url})` }"
                  alt=""
                  class="avatar"
                ></div>
                <div class="upload-logo">
                  <svg
                    width="18px"
                    height="16px"
                    viewBox="0 0 18 16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        id="Artboard"
                        transform="translate(-47.000000, -88.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <path
                          d="M48.9756098,103.852104 C47.8887171,103.852104 47,102.913299 47,101.765148 L47,92.4897855 C47,91.3416348 47.8887171,90.402829 48.9756098,90.402829 L51.7538,90.402829 L52.5220927,88.3810806 C52.5867013,88.2038545 52.74706,88.0861414 52.9268293,88.0839791 L59.0731707,88.0839791 C59.25294,88.0861414 59.4132987,88.2038545 59.4779073,88.3810806 L60.2462,90.402829 L63.0243902,90.402829 C64.1112829,90.402829 65,91.3416348 65,92.4897855 L65,101.765148 C65,102.913299 64.1112829,103.852104 63.0243902,103.852104 L48.9756098,103.852104 Z M48.9756098,102.924568 L63.0243902,102.924568 C63.6400341,102.924568 64.1219512,102.41549 64.1219512,101.765148 L64.1219512,92.4897855 C64.1219512,91.8394435 63.6400341,91.3303652 63.0243902,91.3303652 L59.9512195,91.3303652 C59.7731474,91.3293876 59.6132696,91.2148899 59.5464829,91.0405101 L58.7781902,89.0115154 L53.2218098,89.0115154 L52.4535171,91.0405101 C52.3867304,91.2148899 52.2268526,91.3293876 52.0487805,91.3303652 L48.9756098,91.3303652 C48.3599659,91.3303652 47.8780488,91.8394435 47.8780488,92.4897855 L47.8780488,101.765148 C47.8780488,102.41549 48.3599659,102.924568 48.9756098,102.924568 Z M56,101.30138 C53.8230317,101.30138 52.0487805,99.4271536 52.0487805,97.1274667 C52.0487805,94.8277565 53.8230317,92.9535536 56,92.9535536 C58.1770122,92.9535536 59.9512195,94.8277797 59.9512195,97.1274667 C59.9512195,99.4271536 58.1770122,101.30138 56,101.30138 Z M56,100.373843 C57.7024707,100.373843 59.0731707,98.9258667 59.0731707,97.1274667 C59.0731707,95.3290435 57.7024707,93.8810899 56,93.8810899 C54.2975732,93.8810899 52.9268293,95.3290435 52.9268293,97.1274667 C52.9268293,98.9258667 54.2975732,100.373843 56,100.373843 Z"
                          id="Shape"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </vue-upload-component>
            <div
              v-if="scope.row && scope.row.url && saleableoptionsdisabled[scope.$index]"
              style="text-align: left; padding-left: calc(5em + 12px); margin-top: -10px"
            >
              <el-dropdown @command="(command) => onCommandDropdown(command, scope)">
                <span style="" class="el-dropdown-link">
                  <i class="el-icon-remove-outline el-icon--right" style="font-size: 1.6rem"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="replace-image-edit">Replace image</el-dropdown-item>
                  <el-dropdown-item command="remove-image">Remove image</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div
            v-else-if="scope.row.url && !saleableoptionsdisabled[scope.$index]"
            class="avatar-wrap"
          >
            <div
              :style="{ 'background-image': `url(${scope.row.url})` }"
              :alt="scope.row.name + ' product image'"
              class="product-img"
            ></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Name" width="150">
        <template slot-scope="scope">
          <el-input autocomplete="off" type="text" v-model="scope.row.name" disabled></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Description" width="300">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.description"
            disabled
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Price" width="150">
        <template slot-scope="scope">
          <el-input autocomplete="off" type="text" v-model="scope.row.price" disabled></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Require Sizes" width="200">
        <template slot-scope="scope">
          <el-select v-model="scope.row.requireSizes" placeholder="Require sizes" disabled>
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Mandatory" width="200">
        <template slot-scope="scope">
          <el-select v-model="scope.row.mandatory" placeholder="Mandatory" disabled>
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Single season" width="200">
        <template slot-scope="scope">
          <el-select v-model="scope.row.seasonPass" placeholder="Single season purchase" disabled>
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Gender" width="200">
        <template slot-scope="scope">
          <el-select v-model="scope.row.gender" placeholder="Gender" disabled>
            <el-option
              v-for="item in [
                { label: 'All', value: '' },
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Age From" width="100">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.ageFrom"
            disabled
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Age To" width="100">
        <template slot-scope="scope">
          <el-input autocomplete="off" type="number" v-model="scope.row.ageTo" disabled></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Request Fulfillment pack" width="200">
        <template slot-scope="scope">
          <el-select v-model="scope.row.requestFulfillmentPack" placeholder="" disabled>
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="Edit/Remove"
        width="300"
        v-if="clearance === roles.superAdmin || clearance === roles.gamedevregionAdmin"
      >
        <template slot-scope="scope">
          <el-button @click="handleEditRowSaleble(scope.$index)">
            <template v-if="!saleableoptionsdisabled[scope.$index]"> Edit </template>
            <template v-else> Save </template>
          </el-button>
          <el-button @click="handleRemoveRowSaleble(scope.$index)">Remove</el-button>
        </template>
      </el-table-column> -->
    </data-tables>
    <label
      v-if="program && program.saleableItems && program.saleableItems.length > 0"
      class="tw-w-full el-form-item__label tw-mt-4 tw-pb-0"
      >Program Saleable Items</label
    >
    <data-tables
      v-if="program && program.saleableItems && program.saleableItems.length > 0"
      :data="program.saleableItems"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="Image" width="150">
        <template slot-scope="scope">
          <div v-if="saleableoptionsdisabled[scope.$index]">
            <vue-upload-component
              :ref="`productUpload-${scope.$index}`"
              :inputId="`productUpload-${scope.$index}`"
              :drop="false"
              v-model="editedUploadsObj[scope.$index]"
              :extensions="extensions"
              :accept="accept"
              :post-action="productUploadURL"
              :headers="uploadHeaders"
              :data="{ name: scope.row.name, programId: programId }"
              :multiple="true"
              @input-file="(newFile, oldFile) => inputEditFile(newFile, oldFile, scope)"
              @input-filter="
                (newFile, oldFile, prevent) => inputEditFilter(newFile, oldFile, prevent, scope)
              "
              class="el-button el-button--default upload"
              :class="{ hasEditUpload: scope.row.url }"
              :disabled="!saleableoptionsdisabled[scope.$index]"
            >
              <span v-if="!scope.row.url">Upload</span>
              <div v-else class="avatar-wrap">
                <div
                  :style="{ 'background-image': `url(${scope.row.url})` }"
                  alt=""
                  class="avatar"
                ></div>
                <div class="upload-logo">
                  <svg
                    width="18px"
                    height="16px"
                    viewBox="0 0 18 16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        id="Artboard"
                        transform="translate(-47.000000, -88.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <path
                          d="M48.9756098,103.852104 C47.8887171,103.852104 47,102.913299 47,101.765148 L47,92.4897855 C47,91.3416348 47.8887171,90.402829 48.9756098,90.402829 L51.7538,90.402829 L52.5220927,88.3810806 C52.5867013,88.2038545 52.74706,88.0861414 52.9268293,88.0839791 L59.0731707,88.0839791 C59.25294,88.0861414 59.4132987,88.2038545 59.4779073,88.3810806 L60.2462,90.402829 L63.0243902,90.402829 C64.1112829,90.402829 65,91.3416348 65,92.4897855 L65,101.765148 C65,102.913299 64.1112829,103.852104 63.0243902,103.852104 L48.9756098,103.852104 Z M48.9756098,102.924568 L63.0243902,102.924568 C63.6400341,102.924568 64.1219512,102.41549 64.1219512,101.765148 L64.1219512,92.4897855 C64.1219512,91.8394435 63.6400341,91.3303652 63.0243902,91.3303652 L59.9512195,91.3303652 C59.7731474,91.3293876 59.6132696,91.2148899 59.5464829,91.0405101 L58.7781902,89.0115154 L53.2218098,89.0115154 L52.4535171,91.0405101 C52.3867304,91.2148899 52.2268526,91.3293876 52.0487805,91.3303652 L48.9756098,91.3303652 C48.3599659,91.3303652 47.8780488,91.8394435 47.8780488,92.4897855 L47.8780488,101.765148 C47.8780488,102.41549 48.3599659,102.924568 48.9756098,102.924568 Z M56,101.30138 C53.8230317,101.30138 52.0487805,99.4271536 52.0487805,97.1274667 C52.0487805,94.8277565 53.8230317,92.9535536 56,92.9535536 C58.1770122,92.9535536 59.9512195,94.8277797 59.9512195,97.1274667 C59.9512195,99.4271536 58.1770122,101.30138 56,101.30138 Z M56,100.373843 C57.7024707,100.373843 59.0731707,98.9258667 59.0731707,97.1274667 C59.0731707,95.3290435 57.7024707,93.8810899 56,93.8810899 C54.2975732,93.8810899 52.9268293,95.3290435 52.9268293,97.1274667 C52.9268293,98.9258667 54.2975732,100.373843 56,100.373843 Z"
                          id="Shape"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </vue-upload-component>
            <div
              v-if="scope.row && scope.row.url && saleableoptionsdisabled[scope.$index]"
              style="text-align: left; padding-left: calc(5em + 12px); margin-top: -10px"
            >
              <el-dropdown @command="(command) => onCommandDropdown(command, scope)">
                <span style="" class="el-dropdown-link">
                  <i class="el-icon-remove-outline el-icon--right" style="font-size: 1.6rem"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="replace-image-edit">Replace image</el-dropdown-item>
                  <el-dropdown-item command="remove-image">Remove image</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div
            v-else-if="scope.row.url && !saleableoptionsdisabled[scope.$index]"
            class="avatar-wrap"
          >
            <div
              :style="{ 'background-image': `url(${scope.row.url})` }"
              :alt="scope.row.name + ' product image'"
              class="product-img"
            ></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Name" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.name"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Description" width="300">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.description"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Price" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.price"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Require Sizes" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.requireSizes"
            placeholder="Require sizes"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Mandatory" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.mandatory"
            placeholder="Mandatory"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Single season" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.seasonPass"
            placeholder="Single season purchase"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Gender" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.gender"
            placeholder="Gender"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'All', value: '' },
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Age From" width="100">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.ageFrom"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Age To" width="100">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.ageTo"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Request Fulfillment Pack" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.requestFulfillmentPack"
            placeholder=""
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="Edit/Remove"
        width="300"
        v-if="clearance === roles.superAdmin || clearance === roles.gamedevregionAdmin"
      >
        <template slot-scope="scope">
          <el-button @click="handleEditRowSaleble(scope.$index)">
            <template v-if="!saleableoptionsdisabled[scope.$index]"> Edit </template>
            <template v-else> Save </template>
          </el-button>
          <el-button @click="handleRemoveRowSaleble(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg, roles, productUploadURL } from "../../utils/constants";

const updateProgram = (self, saleableItems, onSuccess) => {
  const update = {
    saleableItems: _.reduce(
      saleableItems,
      (items, item) => {
        item.price = item.price_int;
        delete item.price_int;

        if (!item.gender || item.gender === "") delete item.gender;

        if (item.ageFrom && item.ageFrom !== "") item.ageFrom = parseInt(item.ageFrom);
        else delete item.ageFrom;

        if (item.ageTo && item.ageTo !== "") item.ageTo = parseInt(item.ageTo);
        else delete item.ageTo;

        items.push(item);
        return items;
      },
      []
    ),
  };
  const url = `/nrl/api/v1/admin/programs/${self.program._id}`;
  self.$http
    .put(url, update)
    .then((response) => {
      const program = response.data.data;
      const parsedItems = program.saleableItems.map((o) => {
        o.price_int = parseInt(o.price);
        o.price = (parseFloat(o.price) / 100).toFixed(2);
        if (!o.gender) o.gender = "";
        return o;
      });
      onSuccess(parsedItems);
      self.$store.commit("root/loading", false);
      self.$customSuccess();
    })
    .catch(() => {
      self.$store.commit("root/loading", false);
      self.$customError();
    });
};

export default {
  name: "ProgramsSaleableItems",
  props: ["program", "programSetting"],
  data() {
    const validateSalebleName = (rule, value, callback) => {
      if (!this.currentSaleableItem.name || this.currentSaleableItem.name === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSalebleDesc = (rule, value, callback) => {
      if (!this.currentSaleableItem.description || this.currentSaleableItem.description === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSaleblePrice = (rule, value, callback) => {
      if (!this.currentSaleableItem.price || parseFloat(this.currentSaleableItem.price) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSalebleAgeFrom = (rule, value, callback) => {
      if (!this.currentSaleableItem.ageFrom && this.currentSaleableItem.ageTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSalebleAgeTo = (rule, value, callback) => {
      if (!this.currentSaleableItem.ageTo && this.currentSaleableItem.ageFrom) {
        return callback(new Error(errormsg.input_option));
      }
      if (parseInt(this.currentSaleableItem.ageTo) < parseInt(this.currentSaleableItem.ageFrom)) {
        return callback(new Error(errormsg.gte_age));
      }
      return callback();
    };

    return {
      roles,
      currentSaleableItem: {
        name: null,
        description: null,
        price: null,
        types: [],
        requireSizes: false,
        mandatory: false,
        seasonPass: false,
        gender: "",
        ageFrom: null,
        ageTo: null,
        url: null,
        requestFulfillmentPack: null,
      },
      saleableoptionsdisabled: [],
      saleableitemsrules: {
        saleableitemname: {
          required: true,
          validator: validateSalebleName,
          message: errormsg.input_option,
          trigger: "blur",
        },
        saleableitemdesc: {
          required: true,
          validator: validateSalebleDesc,
          message: errormsg.input_option,
          trigger: "blur",
        },
        saleableitemprice: {
          required: true,
          validator: validateSaleblePrice,
          message: errormsg.input_option,
          trigger: "blur",
        },
        ageTo: {
          validator: validateSalebleAgeTo,
        },
        ageFrom: {
          validator: validateSalebleAgeFrom,
          message: errormsg.input_option,
        },
      },
      tableProps: {
        border: true,
      },
      extensions: "jpg,jpeg,png",
      accept: "image/png,image/jpg,image/jpeg",
      updating: false,
      uploads: [],
      editedUploadsObj: {},
      productImages: {},
    };
  },
  methods: {
    async onCommandDropdown(command, scope) {
      // new product commands
      if (command === "replace-image") {
        this.removeImageS3(this.currentSaleableItem);
        const uploader = this.$refs.upload;
        uploader.$children[0].$el.click();
      }
      if (command === "remove-image-product") {
        this.uploads = [];
        this.removeImageS3(this.currentSaleableItem);
        this.$store.commit("root/loading", true);
      }

      // edit products command
      if (command === "replace-image-edit") {
        const uploader = this.$refs[`productUpload-${scope.$index}`];
        uploader.$children[0].$el.click();
      }
      if (command === "remove-image") {
        try {
          this.editedUploadsObj[scope.$index] = [];
          this.$store.commit("root/loading", true);
          // await this.$http.post(`${productUploadURL}`, { productid: scope.row._id });
          scope.row.url = null;
          this.$store.commit("root/loading", false);
        } catch (e) {
          this.$store.commit("root/loading", false);
        }
      }
    },
    removeImageS3(newItem) {
      const image = typeof newItem.url === "string" ? newItem.url : newItem.url[0];
      const imageUrl = `products/resize/${image.split("products/resize/")[1]}`;
      const url = "/nrl/api/v1/admin/s3/image/remove";
      this.$http
        .post(url, { imageId: imageUrl })
        .then(() => {
          newItem.url = null;
          this.$store.commit("root/loading", false);
        })
        .catch((error) => {
          this.$store.commit("root/loading", false);
          this.$customError(error.response.data.message);
        });
    },
    replaceNewProdImg() {
      this.removeImageS3(this.currentSaleableItem);
      const uploader = this.$refs.upload;
      uploader.$children[0].$el.click();
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
            this.$refs.upload.update(newFile, { error: "size" });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
          this.$store.commit("root/loading", false);
          this.$customError("Product Image upload has failed");
          this.uploads = this.uploads.filter((file) => file.id !== newFile.id);
        }
        if (newFile.success && !oldFile.success) {
          // success
          setTimeout(() => {
            this.$set(this.currentSaleableItem, "url", newFile.response.url);
            this.$store.commit("root/loading", false);
          }, 2500);
        }
      }

      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      return undefined;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }

      if (
        newFile &&
        newFile.error === "" &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }

      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    inputEditFile(newFile, oldFile, scope) {
      if (newFile && newFile.error) {
        // error
        this.$store.commit("root/loading", false);
        this.$customError("Product Image upload has failed");
        //here
        this.editedUploadsObj[scope.$index] = this.editedUploadsObj[scope.$index].filter(
          (file) => file.id !== newFile.id
        );
      } else if (newFile && !newFile.active && newFile.response) {
        // success
        setTimeout(() => {
          scope.row.url = new Array(newFile.response.url);
          const prodID = scope.row._id;
          if (this.productImages[prodID] && this.productImages[prodID].length > 0) {
            if (scope.row.url && scope.row.url.length > 0)
              this.productImages[prodID].push(scope.row.url[0]);
          } else {
            if (scope.row.url && scope.row.url.length > 0)
              this.productImages[prodID] = [...scope.row.url];
          }
          this.$store.commit("root/loading", false);
        }, 2500);
      }
      return undefined;
    },
    inputEditFilter(newFile, oldFile, prevent, scope) {
      if (newFile && !/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }

      if (
        newFile &&
        newFile.error === "" &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }

      this.$refs[`productUpload-${scope.$index}`].active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    submitSaleableitem(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const saleableitemsamename = this.program.saleableItems.filter(
            (d) => d.name === this.currentSaleableItem.name
          );
          if (saleableitemsamename && saleableitemsamename.length > 0) {
            this.$customError(errormsg.regiproduct_saleableitemsamename);
            return false;
          }
          this.$store.commit("root/loading", true);

          const insert = {
            name: this.currentSaleableItem.name,
            description: this.currentSaleableItem.description,
            price_int: parseInt(parseFloat(this.currentSaleableItem.price).toFixed(2) * 100),
            price: parseFloat(this.currentSaleableItem.price).toFixed(2),
            requireSizes: this.currentSaleableItem.requireSizes,
            mandatory: this.currentSaleableItem.mandatory,
            seasonPass: this.currentSaleableItem.seasonPass,
          };

          if (this.currentSaleableItem.gender) insert.gender = this.currentSaleableItem.gender;
          if (this.currentSaleableItem.ageFrom)
            insert.ageFrom = parseInt(this.currentSaleableItem.ageFrom);
          if (this.currentSaleableItem.ageTo)
            insert.ageTo = parseInt(this.currentSaleableItem.ageTo);
          if (this.currentSaleableItem.url) insert.url = this.currentSaleableItem.url;
          if(this.currentSaleableItem.requestFulfillmentPack) insert.requestFulfillmentPack = this.currentSaleableItem.requestFulfillmentPack;

          const saleableItems = _.cloneDeep(this.program.saleableItems);
          saleableItems.push(insert);

          updateProgram(this, saleableItems, (parsedItems) => {
            this.program.saleableItems = parsedItems;
            this.currentSaleableItem = {
              name: null,
              description: null,
              price: null,
              types: [],
              requireSizes: false,
              mandatory: false,
              seasonPass: false,
              gender: "",
              ageFrom: null,
              ageTo: null,
              url: null,
              requestFulfillmentPack: null,
            };
          });

          return true;
        }
        return false;
      });
    },
    handleEditRowSaleble(rowindex) {
      if (this.saleableoptionsdisabled[rowindex]) {
        const row = this.program.saleableItems[rowindex];
        if (!row.name || row.name === "") {
          this.$customError(errormsg.input_option);
          return false;
        }
        if (Number.isNaN(parseFloat(row.price)) || parseFloat(row.price) < 0) {
          this.$customError(errormsg.price_invalid);
          return false;
        }
        const saleableitemsamename = this.program.saleableItems.filter(
          (d, index) => d.name === row.name && index !== rowindex
        );
        if (saleableitemsamename && saleableitemsamename.length > 0) {
          this.$customError(errormsg.regiproduct_saleableitemsamename);
          return false;
        }
        this.$store.commit("root/loading", true);
        // this.program.saleableItems[rowindex].price = parseFloat(row.price).toFixed(2);
        this.program.saleableItems[rowindex].price_int = parseInt(
          parseFloat(row.price).toFixed(2) * 100
        );

        updateProgram(this, _.cloneDeep(this.program.saleableItems), (parsedItems) => {
          this.program.saleableItems = parsedItems;
          this.$set(
            this.saleableoptionsdisabled,
            rowindex,
            !this.saleableoptionsdisabled[rowindex]
          );
        });

        return true;
      }
      this.$set(this.saleableoptionsdisabled, rowindex, !this.saleableoptionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRowSaleble(index) {
      this.$store.commit("root/loading", true);
      const saleableItems = _.cloneDeep(this.program.saleableItems);
      saleableItems.splice(index, 1);
      updateProgram(this, saleableItems, () => {
        this.program.saleableItems.splice(index, 1);
        this.saleableoptionsdisabled.splice(index, 1);
      });
    },
  },
  computed: {
    programId() {
      const { _id } = this.program;
      return _id;
    },
    saleableitemurl: {
      get() {
        return this.currentSaleableItem.url;
      },
      set(value) {
        this.currentSaleableItem.url = value;
      },
    },
    currentUpload() {
      if (this.uploads && this.uploads.length > 0) {
        const mostRecent = this.uploads.length - 1;
        return this.uploads[mostRecent];
      }
      return null;
    },
    saleableItemData() {
      const { _id } = this.program;
      const { name } = this.currentSaleableItem;
      return { programId: _id, name };
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    productUploadURL() {
      return productUploadURL;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    saleableitemname: {
      get() {
        return this.currentSaleableItem.name;
      },
      set(value) {
        this.currentSaleableItem.name = value;
      },
    },
    saleableitemdesc: {
      get() {
        return this.currentSaleableItem.description;
      },
      set(value) {
        this.currentSaleableItem.description = value;
      },
    },
    saleableitemprice: {
      get() {
        return this.currentSaleableItem.price;
      },
      set(value) {
        this.currentSaleableItem.price =
          !Number.isNaN(parseFloat(value)) && parseFloat(value) >= 0 ? value : null;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
.hasUpload {
  border-radius: 25%;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.hasEditUpload {
  border-radius: 25%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  width: 100%;
}
.hasEditUpload .avatar-wrap {
  width: 100%;
  height: 100px;
}
.hasEditUpload .avatar {
  height: 100%;
}
.avatar,
.product-img {
  background-size: contain;
  background-position: center;
  height: 100%;
}
.product-img {
  height: 100% !important;
}
.avatar-wrap {
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid #fff;
  -webkit-box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  border-radius: 25%;
  width: 8rem;
  height: 8rem;
  margin-bottom: 8px;
}
.upload-logo {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 3.5px;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: black;
  opacity: 0.6;
}
.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.product-upload {
  padding: 15px;
  border: 1px solid #ebeef5;
  background-color: #fff;
}

.el-input input {
  padding: 0 30px !important;
  border: 1px solid red;
}

.el-tag {
  margin-bottom: 0.8em;
  margin-right: 0.4em;
  // text inside color #666
}
</style>
