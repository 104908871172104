
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    search: {
      competitionId: undefined,
      dateRange: undefined,
      roundType: undefined,
      roundNumber: undefined,
      status: undefined,
    }
  },
  mutations: {
    update: (state, payload) => _.assign(state.search, payload)
  }
};
