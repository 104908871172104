<template>
  <div>
    <el-row :gutter="10">
      <el-button @click="addGameDevRegion" type="success" icon="el-icon-plus"> Add Game Dev Region </el-button>
    </el-row>
    <data-tables
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table">
      <el-table-column
        prop="_id"
        label="ID"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="name"
        label="Name"
        width="auto">
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="auto">
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
export default {
  name: 'GameDevRegionList',
  props: {
  },
  data() {
    return {
      tableData: [],
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
    };
  },
  mounted() {
    this.$store.commit('root/loading', true);
    this.$http
      .get('/nrl/api/v1/admin/game-dev-regions')
      .then((response) => {
        this.tableData = response.data.data;
        this.$store.commit('root/loading', false);
      })
      .catch(() => {
        this.$customError();
        this.$store.commit('root/loading', false);
      });
  },
  methods: {
    addGameDevRegion() {
      this.$router.push({ name: 'game-dev-region.add', params: { type: 'insert', id: 'add' }, });
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'game-dev-region.update',
        params: {
          type: 'update',
          gamedevregion: row,
          id: row._id,
        },
      });
    },
    modifiedFormatter(row) {
      return (row.modifiedAt ? this.moment(row.modifiedAt).format('MMMM Do YYYY, h:mm:ss a') : 'NA');
    },
  },
};
</script>

<style scoped lang="scss">
    .data-table {
      width: 100%;
      margin-top: 2.5rem !important;
    }
</style>
