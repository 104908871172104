<template>
  <div class="d-flex-column">
    <CheckBoxGroup
      :choices="[{name: 'Pending', type: 'pending'}, {name: 'Resolved', type: 'resolved'}]"
      :min="0"
      :max="1"
      name="Status"
      v-model="inputVal.status"
    />

    <div class="mt-2 d-flex-column">
      <div class="m-1">
        <el-button
            @click="clear"
            class="full-width button-invisible">Clear</el-button>
        <el-button
            @click='submit'
            type="primary"
            class="full-width">Apply Filters</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBoxGroup from '@/components/CheckboxGroup.vue';
import { EventBus } from '../../bus';

export default {
  name: 'FilterPage',
  components: { CheckBoxGroup },
  props: ['value'],
  methods: {
    submit() {
      this.$emit('submit', this.inputVal);
    },
    clear() {
      this.$set(this.inputVal, 'status', []);
      EventBus.$emit('clear');
      this.$emit('clear', this.inputVal);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  data() {
    return {
      inputVal: {
        status: [],
      },
    };
  },
  mounted() {
    const { status } = this.$store.state.duplicateMembers.filters;
    this.$set(this.inputVal, 'status', status || []);
  },
};
</script>

<style lang="scss" scoped>

</style>
