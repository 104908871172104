<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="dateTimeRange" label="Date Time Range">
            <el-date-picker
              v-model="filters.dateTimeRange"
              type="datetimerange"
              range-separator="|"
              start-placeholder="Start date"
              end-placeholder="End date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="selectedSeasons" label="Season">
            <el-select
              @change="updateCompetitionsOrTeams"
              clearable
              filterable
              multiple
              v-model="filters.selectedSeasons"
              placeholder="Select a season"
            >
              <el-option
                v-for="entity in seasons"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="selectedVenues" label="Venue">
            <el-select
              style="width: 25rem"
              clearable
              filterable
              multiple
              v-model="filters.selectedVenues"
              placeholder="Select a venue"
            >
              <el-option
                v-for="entity in venues"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="roleId < roles.clubAdmin">
          <el-form-item prop="selectedCompetition" label="Competition">
            <el-select
              style="width: 25rem"
              clearable
              filterable
              multiple
              v-model="filters.selectedCompetitions"
              placeholder="Select a competition"
            >
              <el-option
                v-for="entity in competitions"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-else-if="roleId === roles.clubAdmin">
          <el-form-item label="Teams">
            <el-select
              style="width: 25rem"
              clearable
              filterable
              multiple
              v-model="filters.selectedTeams"
              placeholder="Select a team"
            >
              <el-option
                v-for="entity in teams"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8" v-if="this.isTfa">
          <el-form-item prop="status" label="Status">
            <el-select v-model="filters.status" placeholder="Select a file type">
              <el-option
                v-for="status in statuses"
                :label="status.name"
                :key="status.type"
                :value="status.type"
                :default="status.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import moment from "moment-timezone";
import { errormsg, roles, mysidelineSeasons } from "../../../utils/constants";

export default {
  name: "MatchesReport",
  components: {
    ActionButtons,
  },
  computed: {
    isTfa() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
  },
  mounted() {
    this.roleId = this.$store.getters["user/activeRole"].type;
    if (this.roleId <= roles.associationAdmin) {
      this.$http
        .get("/nrl/api/v1/admin/competitions")
        .then((res) => {
          this.competitions = res.data.data;
        })
        .catch(() => {
          this.$customError("Unable to fetch competitions");
        });
    }
    if (this.roleId === roles.clubAdmin) {
      this.$http
        .get("/nrl/api/v1/admin/teams")
        .then((res) => {
          this.teams = res.data.data;
        })
        .catch(() => {
          this.$customError("Unable to fetch teams");
        });
    }
    this.$http
      .get("/nrl/api/v1/admin/venues")
      .then((res) => {
        this.venues = res.data.data;
      })
      .catch(() => {
        this.$customError("Unable to fetch venues");
      });
  },
  data() {
    return {
      roles,
      seasons: mysidelineSeasons.map((year) => ({ name: year, _id: year })),
      venues: [],
      competitions: [],
      teams: [],
      filters: {
        dateTimeRange: null,
        fileType: "csv",
        selectedCompetitions: null,
        selectedTeams: null,
        selectedSeasons: null,
        selectedVenues: null,
        status: "all",
      },
      filterRules: {
        selectedCompetitions: {
          required: true,
          trigger: "blur",
          message: errormsg.select_option,
        },
        selectedTeams: {
          required: true,
          trigger: "blur",
          message: errormsg.select_option,
        },
        selectedSeasons: {
          required: true,
          trigger: "blur",
          message: errormsg.select_option,
        },
        dateTimeRange: {
          required: false,
          trigger: "blur",
          // validator: (obj, val, callback) => {
          //   if (!val) {
          //     return callback(new Error(errormsg.select_option));
          //   }

          //   return callback();
          // }
        },
        status: {
          required: false,
          message: errormsg.select_option,
          trigger: "blur",
        },
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      roleId: 0,
      statuses: [
        { name: "All", type: "all", default: true },
        { name: "Active", type: "active" },
        { name: "Inactive", type: "inactive" },
      ],
    };
  },
  methods: {
    updateCompetitionsOrTeams(seasons) {
      let url = "";
      this.$store.commit("root/loading", true);
      if (this.roleId < roles.clubAdmin) {
        // if (this.clearance > roles.associationAdmin) return this.$store.commit("root/loading", false);
        url = "/nrl/api/v1/admin/competitions?";
        if (seasons.length === 1) {
          url += "season=" + seasons[0];
        } else {
          seasons.forEach((szn, i) => {
            url += "season=" + szn;
            if (seasons.length - 1 !== i) url += "&";
          });
        }
      } else if (this.roleId === roles.clubAdmin) {
        url = "/nrl/api/v1/admin/teams?";
        if (seasons.length === 1) {
          url += "season=" + seasons[0];
        } else {
          seasons.forEach((szn, i) => {
            url += "season=" + szn;
            if (seasons.length - 1 !== i) url += "&";
          });
        }
      } else if (this.roleId > roles.clubAdmin) return this.$store.commit("root/loading", false);
      this.$http
        .get(url)
        .then((res) => {
          this.roleId === roles.clubAdmin
            ? (this.teams = res.data.data)
            : (this.competitions = res.data.data);
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError("Unable to fetch competitions");
        });
    },
    sendReport() {
      this.$refs.filters.validate(async (valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          let body = {};
          if (this.roleId === roles.clubAdmin) {
            this.isRefereeClub = false;
            const response = await this.$http.get("/nrl/api/v1/admin/referee/club");
            if (response && response.data && response.data.club.orgtree.state.type === "referee") {
              this.isRefereeClub = true;
            }

            body = {
              reportType: "matches-detail-club",
              fileType: this.filters.fileType,
              filters: {
                teamIds: this.filters.selectedTeams,
                dateTimeRange: this.filters.dateTimeRange,
                seasonIds: this.filters.selectedSeasons,
                venueIds: this.filters.selectedVenues,
                status: this.filters.status,
                isRefereeClub: this.isRefereeClub,
              },
              meta: {
                timezone: moment.tz.guess(),
              },
            };
          } else {
            body = {
              reportType: "matches-detail",
              fileType: this.filters.fileType,
              filters: {
                competitionIds: this.filters.selectedCompetitions,
                dateTimeRange: this.filters.dateTimeRange,
                seasonIds: this.filters.selectedSeasons,
                venueIds: this.filters.selectedVenues,
                status: this.filters.status,
              },
              meta: {
                timezone: moment.tz.guess(),
              },
            };
          }
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
  },
};
</script>
