<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br>
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="paid" label="Program Registration Financial">
            <el-select
              v-model="filters.paid"
              placeholder="Select an option"
            >
              <el-option
                v-for="option in paidOptions"
                :label="option.name"
                :key="option.type"
                :value="option.type"
                :default="option.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="season" label="Season">
            <el-select
              v-model="filters.season"
              placeholder="Select a season"
            >
              <el-option
                v-for="season in seasons"
                :label="season.name"
                :key="season.value"
                :value="season.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="fileType" label="File Type">
            <el-select
              v-model="filters.fileType"
              placeholder="Select a file type"
            >
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="includeDOB" label="Include Date of Birth">
            <el-checkbox v-model="filters.includeDOB" @change="checkDOB">
              Include DOB in report
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      title="Important Information"
      :visible.sync="showDOBInfo"
      width="30%"
      :before-close="closeDOBInfo"
    >
      <span v-html="dobPrivacyPolicy" :style="{'display': 'inline-block', 'wordBreak': 'break-word'}"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDOBInfo">I understand</el-button>
      </span>
    </el-dialog>
    <action-buttons
      submit
      submitText="Submit"
      :cancel="false"
      @submit="sendReport"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import ActionButtons from '@/components/ActionButtons.vue';
import { mysidelineSeasons, errormsg, dobPrivacyPolicy } from '../../../utils/constants';

export default {
  name: 'ProgramRegoPaymentDetailReport',
  components: {
    ActionButtons
  },
  data() {
    return {
      filters: {
        paid: undefined,
        fileType: 'csv',
        season: '',
        includeDOB: false,
      },
      filterRules: {
        fileType: {
          required: true,
          message: errormsg.select_option,
          trigger: 'blur',
        },
        season: {
          required: true,
          message: errormsg.select_option,
          trigger: 'blur',
        }
      },
      fileTypes: [
        { name: 'CSV', type: 'csv', default: true }
      ],
      paidOptions: [
        { name: 'All', type: undefined, default: true },
        { name: 'Yes', type: true },
        { name: 'No', type: false }
      ],
      showDOBInfo: false,
      dobPrivacyPolicy,
    };
  },
  computed: {
    seasons() {
      return mysidelineSeasons.map(v => ({ name: v.toString(), value: v }));
    }
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          const reportType = 'program-rego-payment-details';
          this.$store.commit('root/loading', true);
          const body = {
            reportType,
            fileType: this.filters.fileType,
            filters: {
              paid: this.filters.paid,
              season: this.filters.season,
            },
            meta: {
              timezone: moment.tz.guess(),
            }
          };
          if (this.filters.includeDOB) {
            body.filters.includeDOB = true;
          }
          const url = '/nrl/api/v1/admin/reports';
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit('root/loading', false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit('root/loading', false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
    checkDOB() {
      if (this.filters.includeDOB) {
        this.showDOBInfo = true; // Show pop-up when DOB checkbox is checked
      }
    },
    closeDOBInfo() {
      this.showDOBInfo = false; // Close the modal
    },
  }
};
</script>
