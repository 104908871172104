<template>
  <div v-if="isTfa">
    <p class="tw-py-4">
      Press submit to check squad summary. The report will display on your screen. <br />
    </p>
    <el-form label-position="top" :model="filters" ref="filters">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="season" label="Season">
            <el-select v-model="filters.season" filterable placeholder="Select a season">
              <el-option
                v-for="season in seasons"
                :label="season.name"
                :key="season.value"
                :value="season.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col :span="4">
          <action-buttons submit submitText="Preview" :cancel="false" @submit="sendReport()" />
        </el-col>
        <el-col :span="4">
          <action-buttons
            submit
            submitText="Generate download"
            :cancel="false"
            @submit="sendLegacyReport()"
          />
        </el-col>
      </el-row>
      <el-row :gutter="10"> </el-row>
    </el-form>
    <el-row>
      <el-col :span="20">
        <p class="tw-py-4">Previously generated reports. Showing last 10 reports.<br /></p>
      </el-col>
      <el-col :span="4">
        <el-button class="update" icon="el-icon-refresh" @click="refreshHistory">
          Refresh
        </el-button>
      </el-col>
    </el-row>
    <el-table :data="history" style="width: 100%">
      <el-table-column :formatter="dateFormatter" prop="createdAt" label="Date" width="240">
      </el-table-column>
      <el-table-column prop="fileType" label="File type" width="180"> </el-table-column>
      <el-table-column :formatter="filtersFormatter" prop="filters" label="Filters" width="180">
      </el-table-column>
      <el-table-column :align="'right'" prop="reportUrl">
        <template slot-scope="scope">
          <el-button @click="download(scope.row.reportUrl)" type="text" size="small"
            >Download</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="Squad Summary"
      :visible.sync="this.rawHtml"
      width="30%"
      :before-close="handleClose"
      :fullscreen="true"
    >
      <div ref="report" v-html="rawHtml"></div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, mysidelineSeasons, roles, ageLevels } from "@/utils/constants";
import _ from "lodash";
import moment from "moment-timezone";

export default {
  name: "SquadSummaryReport",
  components: {
    ActionButtons,
  },
  mounted() {
    const { load, skip, pages } = this.$route.query;
    if (load === "auto") {
      this.sendReport(skip, pages);
    }
    this.$store.commit("root/loading", true);
    this.refreshHistory();
  },
  data() {
    return {
      history: undefined,
      rawHtml: undefined,
      showDialog: false,
      competitions: [],
      filters: {
        season: undefined,
        fileType: "csv",
      },
      roles,
    };
  },
  methods: {
    handleClose() {
      this.$confirm("Are you sure to close this dialog?")
        .then((_) => {
          this.rawHtml = undefined;
        })
        .catch((_) => {});
    },
    download(url) {
      window.open(url, "_blank");
    },
    refreshHistory() {
      const userProfile = this.$store.getters["user/profile"];
      this.$store.commit("root/loading", true);
      this.$httpms
        .post(`/getReportHistory`, {
          userId: userProfile._id,
          reportName: "squad-summary",
        })
        .then((response) => {
          if (response.data) this.history = response.data;
          this.$store.commit("root/loading", false);
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    },
    sendLegacyReport() {
      this.$store.commit("root/loading", true);
      const body = {
        reportType: "squad-summary",

        fileType: this.filters.fileType,
        filters: {
          season: this.filters.season,
        },
        meta: {
          timezone: moment.tz.guess(),
        },
      };
      const url = "/nrl/api/v1/admin/reports";
      this.$http
        .post(url, body)
        .then((response) => {
          this.$store.commit("root/loading", false);
          this.$customSuccess(response.data.message);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    sendReport(skip, pages) {
      const { entity } = this.$store.getters["user/activeRole"];
      if (!skip) skip = 0;
      if (!pages) pages = 1;
      this.$store.commit("root/loading", true);
      this.$httpms
        .post(`/squadSummaryReport?skip=${skip}&pages=${pages}`, {
          season: this.filters.season,
        })
        .then((response) => {
          if (response.data) this.rawHtml = response.data;
          this.$store.commit("root/loading", false);
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    },
    dateFormatter(row) {
      return row.createdAt ? this.moment(row.createdAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    filtersFormatter(row) {
      return row.filters
        ? Object.entries(row.filters)
            .map(([key, value]) => `${key} - ${value}`)
            .join("\n")
        : "NA";
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    seasons() {
      return mysidelineSeasons.map((v) => ({ name: v.toString(), value: v }));
    },
    isTfa() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
  },
};
</script>
