<template>
  <div class="tw-flex tw-items-center tw-mb-10 tw-h-46px">
    <div
      id="connector1"
      class="tw-w-1 tw-transition tw-duration-500 tw-mb-1 tw-ease-in-out tw-border tw-h-1 tw-rounded-l"
      :class="
        step > 0 ? 'tw-border-green-700 tw-bg-green-700' : 'tw-border-gray-1000 tw-bg-gray-1000'
      "
    ></div>
    <div
      id="None"
      class="tw-flex tw-flex-col tw-z-10 tw-items-center tw-justify-center tw-pt-3.5 tw-w-5 tw-h-46px tw-text-center"
      @click="setStep(0)"
    >
      <div v-if="step === 0">
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-5 tw-h-5 tw-justify-center tw-transition tw-duration-500 tw-ease-in-out tw-border-2 tw-bg-green-700 tw-border-green-700 tw-rounded-full"
        ></div>
        <label
          class="tw-text-xs tw-pt-1 tw-font-normal tw-text-black tw-h-18px tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          0
        </label>
      </div>
      <div v-else>
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-29px tw-h-1 tw-justify-center tw-p-0 tw-transition tw-duration-500 tw-ease-in-out tw-border-2"
          :class="
            step > 0
              ? 'tw-bg-green-700 tw-border-green-700'
              : ' tw-bg-gray-1000 tw-border-gray-1000'
          "
        ></div>

        <label
          class="tw-text-xs tw-font-normal tw-text-black tw-h-18px tw-pt-3 tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          0
        </label>
      </div>
    </div>
    <div
      id="connector2"
      class="tw-flex-1 tw-transition tw-duration-500 tw-mb-1 tw-ease-in-out tw-border tw-h-1 tw-rounded-l"
      :class="
        step >= 1 ? 'tw-border-green-700 tw-bg-green-700' : 'tw-border-gray-1000 tw-bg-gray-1000'
      "
    ></div>
    <div
      id="Mild1"
      class="tw-flex tw-z-10 tw-flex-col tw-items-center tw-justify-center tw-pt-3.5 tw-w-5 tw-h-46px tw-text-center"
      @click="setStep(1)"
    >
      <div v-if="step === 1">
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-5 tw-h-5 tw-justify-center tw-transition tw-duration-500 tw-ease-in-out tw-border-2 tw-bg-green-700 tw-border-green-700 tw-rounded-full"
        ></div>
        <label
          class="tw-text-xs tw-pt-1 tw-text-center tw-font-normal tw-text-black tw-h-18px tw-leading-10px tw-flex tw-flex-col"
        >
          1
        </label>
      </div>
      <div v-else>
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-29px tw-h-1 tw-justify-center tw-p-0 tw-transition tw-duration-500 tw-ease-in-out tw-border-2"
          :class="
            step >= 1
              ? 'tw-bg-green-700 tw-border-green-700'
              : ' tw-bg-gray-1000 tw-border-gray-1000'
          "
        ></div>

        <label
          class="tw-text-xs tw-font-normal tw-text-black tw-h-18px tw-pt-3 tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          1
        </label>
      </div>
    </div>
    <div
      id="connector3"
      class="tw-flex-1 tw-transition tw-duration-500 tw-ease-in-out tw-mb-1 tw-border tw-h-1 tw-rounded-l"
      :class="
        step >= 2 ? 'tw-border-green-700 tw-bg-green-700' : 'tw-border-gray-1000 tw-bg-gray-1000'
      "
    ></div>
    <div
      id="Mild2"
      class="tw-flex tw-z-10 tw-flex-col tw-items-center tw-justify-center tw-pt-3.5 tw-w-5 tw-h-46px tw-text-center"
      @click="setStep(2)"
    >
      <div v-if="step === 2">
        <div
          class="tw-flex tw-items-center tw-w-5 tw-m-auto tw-h-5 tw-justify-center tw-transition tw-duration-500 tw-ease-in-out tw-border-2 tw-bg-green-700 tw-border-green-700 tw-rounded-full"
        ></div>

        <label
          class="tw-text-xs tw-pt-1 tw-font-normal tw-text-black tw-h-18px tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          2
        </label>
      </div>
      <div v-else>
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-29px tw-h-1 tw-justify-center tw-p-0 tw-transition tw-duration-500 tw-ease-in-out tw-border-2"
          :class="
            step >= 2
              ? 'tw-bg-green-700 tw-border-green-700'
              : ' tw-bg-gray-1000 tw-border-gray-1000'
          "
        ></div>

        <label
          class="tw-text-xs tw-font-normal tw-text-black tw-h-18px tw-pt-3 tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          2
        </label>
      </div>
    </div>

    <div
      id="connector4"
      class="tw-flex-1 tw-transition tw-duration-500 tw-mb-1 tw-ease-in-out tw-border tw-h-1 tw-rounded-l"
      :class="
        step >= 3 ? 'tw-border-green-700 tw-bg-green-700' : 'tw-border-gray-1000 tw-bg-gray-1000'
      "
    ></div>
    <div
      id="Moderate3"
      class="tw-flex tw-z-10 tw-flex-col tw-items-center tw-justify-center tw-pt-3.5 tw-w-5 tw-h-46px tw-text-center"
      @click="setStep(3)"
    >
      <div v-if="step === 3">
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-5 tw-h-5 tw-justify-center tw-transition tw-duration-500 tw-ease-in-out tw-border-2 tw-bg-green-700 tw-border-green-700 tw-rounded-full"
        ></div>
        <label
          class="tw-text-xs tw-pt-1 tw-font-normal tw-text-black tw-h-18px tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          3
        </label>
      </div>
      <div v-else>
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-29px tw-h-1 tw-justify-center tw-p-0 tw-transition tw-duration-500 tw-ease-in-out tw-border-2"
          :class="
            step >= 3
              ? 'tw-bg-green-700 tw-border-green-700'
              : ' tw-bg-gray-1000 tw-border-gray-1000'
          "
        ></div>

        <label
          class="tw-text-xs tw-font-normal tw-text-black tw-h-18px tw-pt-3 tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          3
        </label>
      </div>
    </div>
    <div
      id="connector5"
      class="tw-flex-1 tw-transition tw-duration-500 tw-mb-1 tw-ease-in-out tw-border tw-h-1 tw-rounded-r"
      :class="
        step >= 4 ? 'tw-border-green-700 tw-bg-green-700' : 'tw-border-gray-1000 tw-bg-gray-1000'
      "
    ></div>
    <div
      id="Moderate4"
      class="tw-flex tw-z-10 tw-flex-col tw-items-center tw-justify-center tw-pt-3.5 tw-w-5 tw-h-46px tw-text-center"
      @click="setStep(4)"
    >
      <div v-if="step === 4">
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-5 tw-h-5 tw-justify-center tw-transition tw-duration-500 tw-ease-in-out tw-border-2 tw-bg-green-700 tw-border-green-700 tw-rounded-full"
        ></div>
        <label
          class="tw-text-xs tw-pt-1 tw-font-normal tw-text-black tw-h-18px tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          4
        </label>
      </div>
      <div v-else>
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-29px tw-h-1 tw-justify-center tw-p-0 tw-transition tw-duration-500 tw-ease-in-out tw-border-2"
          :class="
            step >= 4
              ? 'tw-bg-green-700 tw-border-green-700'
              : ' tw-bg-gray-1000 tw-border-gray-1000'
          "
        ></div>

        <label
          class="tw-text-xs tw-font-normal tw-text-black tw-h-18px tw-pt-3 tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          4
        </label>
      </div>
    </div>
    <div
      id="connector6"
      class="tw-flex-1 tw-transition tw-duration-500 tw-mb-1 tw-ease-in-out tw-border tw-h-1 tw-rounded-r"
      :class="
        step >= 5 ? 'tw-border-green-700 tw-bg-green-700' : 'tw-border-gray-1000 tw-bg-gray-1000'
      "
    ></div>
    <div
      id="Severe5"
      class="tw-flex tw-z-10 tw-flex-col tw-items-center tw-justify-center tw-pt-3.5 tw-w-5 tw-h-46px tw-text-center"
      @click="setStep(5)"
    >
      <div v-if="step === 5">
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-5 tw-h-5 tw-justify-center tw-transition tw-duration-500 tw-ease-in-out tw-border-2 tw-bg-green-700 tw-border-green-700 tw-rounded-full"
        ></div>
        <label
          class="tw-text-xs tw-pt-1 tw-font-normal tw-text-black tw-h-18px tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          5
        </label>
      </div>
      <div v-else>
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-29px tw-h-1 tw-justify-center tw-p-0 tw-transition tw-duration-500 tw-ease-in-out tw-border-2"
          :class="
            step >= 5
              ? 'tw-bg-green-700 tw-border-green-700'
              : ' tw-bg-gray-1000 tw-border-gray-1000'
          "
        ></div>

        <label
          class="tw-text-xs tw-font-normal tw-text-black tw-h-18px tw-pt-3 tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          5
        </label>
      </div>
    </div>
    <div
      id="connector7"
      class="tw-flex-1 tw-transition tw-duration-500 tw-mb-1 tw-ease-in-out tw-border tw-h-1 tw-rounded-r"
      :class="
        step >= 6 ? 'tw-border-green-700 tw-bg-green-700' : 'tw-border-gray-1000 tw-bg-gray-1000'
      "
    ></div>
    <div
      id="Severe6"
      class="tw-flex tw-z-10 tw-flex-col tw-items-center tw-justify-center tw-pt-3.5 tw-w-5 tw-h-46px tw-text-center"
      @click="setStep(6)"
    >
      <div v-if="step === 6">
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-5 tw-h-5 tw-justify-center tw-transition tw-duration-500 tw-ease-in-out tw-border-2 tw-bg-green-700 tw-border-green-700 tw-rounded-full"
        ></div>
        <label
          class="tw-text-xs tw-pt-1 tw-font-normal tw-text-black tw-h-18px tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          6
        </label>
      </div>
      <div v-else>
        <div
          class="tw-flex tw-items-center tw--ml-0.5 tw-w-29px tw-h-1 tw-justify-center tw-p-0 tw-transition tw-duration-500 tw-ease-in-out tw-border-2"
          :class="
            step >= 6
              ? 'tw-bg-green-700 tw-border-green-700'
              : ' tw-bg-gray-1000 tw-border-gray-1000'
          "
        ></div>

        <label
          class="tw-text-xs tw-font-normal tw-text-black tw-h-18px tw-pt-3 tw-text-center tw-leading-10px tw-flex tw-flex-col"
        >
          6
        </label>
      </div>
    </div>
    <div
      id="connector8"
      class="tw-w-1 tw-transition tw-duration-500 tw-mb-1 tw-ease-in-out tw-border tw-h-1 tw-rounded-r"
      :class="
        step >= 6 ? 'tw-border-green-700 tw-bg-green-700' : ' tw-bg-gray-1000 tw-border-gray-1000'
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: "SummaryRecordSlidder",
  props: ["stepProp"],
  data() {
    return {
      step: 0,
      textColor: "",
      steps: [
        { step: 0, name: "None" },
        { step: 1, name: "Mild" },
        { step: 2, name: "Mild +" },
        { step: 3, name: "Moderate" },
        { step: 4, name: "Moderate +" },
        { step: 5, name: "Severe" },
        { step: 6, name: "Severe +" },
      ],
    };
  },
  mounted() {
    this.step = this.stepProp;
  },
  methods: {
    setConnectorColor() {
      if (step === 1) {
        this.connector1 = "tw-border-green-700";
        this.connector2 = "tw-border-green-700";
      } else if (step === 2) {
        this.connector1 = "tw-border-green-700";
        this.connector2 = "tw-border-green-700";
        this.connector3 = "tw-border-green-700";
      } else if (step === 2) {
        this.connector1 = "tw-border-green-700";
        this.connector2 = "tw-border-green-700";
        this.connector3 = "tw-border-green-700";
        this.connector4 = "tw-border-green-700";
        this.connector5 = "tw-border-green-700";
      }
    },
    setStep(stepValue) {
      let stepSelected = {
        step: 0,
        name: "None",
      };
      this.step = stepValue;
      if (this.step > 0) {
        this.textColor = "tw-border-green-700 tw-bg-green-700";
      }
      stepSelected = this.steps.find((s) => s.step === this.step);
      this.$emit("step", stepSelected);
    },
  },
};
</script>

<style scoped></style>
