<template>
  <div>
    <div class="tw-flex tw-justify-end tw-gap-2 tw--mt-4">
      <el-button type="text" @click="handleMatchGrid">
        Switch to latest version
      </el-button>
    </div>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="Match Search" name="search">
        <el-form ref="searchForm" label-position="top" :model="search" :rules="searchRules">
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="8">
              <el-form-item prop="dateRange" label="Date Range">
                <el-date-picker
                  v-model="search.dateRange"
                  type="daterange"
                  value-format="timestamp"
                  range-separator="|"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="venue" label="Venue">
                <el-select
                  v-model="search.venueIds"
                  :remote="true"
                  :remote-method="onFilterVenue"
                  placeholder="Search venue"
                  filterable
                  clearable
                  multiple
                  @clear="search.venueIds = undefined"
                >
                  <el-option
                    v-for="{ _id, name } in venues"
                    :label="name"
                    :key="_id"
                    :value="_id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="fieldNo" label="Field Number">
                <el-input
                  controls-position="right"
                  maxlength="10"
                  v-model="search.fieldNo"
                  style="width:100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="8">
              <el-form-item prop="competitionId" label="Competition">
                <el-select
                  v-model="search.competitionIds"
                  placeholder="Select a Competition"
                  multiple
                  filterable
                  clearable
                  @clear="search.competitionId = undefined"
                >
                  <el-option
                    v-for="{ _id, name } in competitions"
                    :key="_id"
                    :value="_id"
                    :label="name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="ageLvl" label="Age Level">
                <el-select
                  v-model="search.ageLvls"
                  placeholder="Select an Age Level"
                  filterable
                  multiple
                  clearable
                  @clear="search.ageLvl = undefined"
                >
                  <el-option
                    v-for="{ name } in ageLevels"
                    :key="name"
                    :value="name"
                    :label="name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="isClashOnly">
                <el-checkbox
                  v-model="search.isClashOnly"
                  label="Show match clashes only"
                  border
                ></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="align-text-left">
                <el-button
                  @click="searchMatches"
                  type="success"
                  class="mw200px"
                  icon="el-icon-search"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </div>
        </el-form>
      </el-collapse-item>
      <el-collapse-item title="Grid Helper" name="helper">
        <el-form ref="helperForm" label-position="top" :model="helper" :rules="helperRules">
          <el-row :gutter="10" class="d-flex align-flex-end">
            <el-col :span="6">
              <el-form-item label="Duration (Hours)">
                <el-select
                  v-model="search.duration"
                  style="width:100%"
                  @change="onDurationChange"
                  clearable
                >
                  <el-option
                    v-for="i in durations"
                    :key="i"
                    :label="formatHours(i)"
                    :value="i"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="venueId" label="Add new venue">
                <el-select
                  v-model="helper.venueId"
                  :remote="true"
                  :remote-method="onHelperFilterVenue"
                  placeholder="Search venue"
                  filterable
                  clearable
                  @clear="helper.venueId = undefined"
                >
                  <el-option
                    v-for="{ _id, name } in helperVenues"
                    :label="name"
                    :key="_id"
                    :value="_id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="fieldNo" label="Field Number">
                <el-input
                  controls-position="right"
                  maxlength="10"
                  v-model="helper.fieldNo"
                  style="width:100%"
                  placeholder="Field Number"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="">
                <el-button @click="addNewVenue" class="mw200px" type="success" icon="el-icon-plus"
                  >Add</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <el-row class="mt-2">
      <el-row :gutter="10" class="" type="flex" align="bottom">
        <el-col :span="20">
          <div></div>
          <el-tag
            v-for="({ _id, name }, i) in grid.competitions"
            :key="_id"
            type
            @click="onFocus(_id)"
            :class="`pointer ${colors[i % colors.length]}`"
            effect="dark"
          >
            {{ name }}
          </el-tag>
        </el-col>

        <el-col :span="4" class="d-flex-column flex-justify-center" v-if="matches.length">
          <el-row>
            <el-col :span="24" class="mt-4 mb-2 d-flex flex-justify-center">
              <el-button
                @click="onBulkEdit"
                class="mw200px"
                type="success"
                icon="el-icon-setting"
                :disabled="!bulk || !bulk.length"
                >Save</el-button
              >
            </el-col>
          </el-row>
          <el-row class="d-flex flex-justify-center">
            <el-button-group>
              <el-button
                round
                size="small"
                icon="el-icon-zoom-in"
                style="font-size:20px;"
                @click="grid.timeline.zoomIn(1)"
              ></el-button>
              <el-button
                round
                size="small"
                icon="el-icon-zoom-out"
                style="font-size:20px;"
                @click="grid.timeline.zoomOut(1)"
              ></el-button>
            </el-button-group>
          </el-row>
        </el-col>
      </el-row>
      <el-col :span="24" class="mt-2">
        <div id="visualization"></div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import _ from "lodash";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";
import { Timeline, DataSet } from "vis-timeline/standalone";

import { errormsg, ageLevels } from "@/utils/constants";

export default {
  data() {
    const {
      search: { dateRange, venueIds, fieldNo, competitionIds, ageLvls, duration, isClashOnly },
      venues
    } = this.$store.state.matchGrid;
    return {
      activeNames: ["search"],
      colors: ["red", "orange", "butter", "green", "purple", "grey"],
      search: {
        dateRange,
        venueIds,
        fieldNo,
        competitionIds,
        ageLvls,
        duration,
        isClashOnly
      },
      searchRules: {
        dateRange: [
          {
            required: true,
            trigger: "blur",
            validator: (obj, val, callback) => {
              if (!val) {
                return callback(new Error(errormsg.select_option));
              }
              const [start, end] = val;
              const days = this.moment(end).diff(start, "days");
              if (days > 7) {
                return callback(new Error(errormsg.one_week_ranges));
              }
              return callback();
            }
          }
        ]
      },
      grid: {
        timeline: undefined,
        competitions: [],
        groups: [],
        items: [],
        groupsLvl1: [],
        isSetWindow: false
      },
      competitions: [],
      venues,
      helperVenues: [],
      ageLevels,
      matches: [],
      bulk: [],
      durations: _.sortBy(_.range(0.5, 24.5, 0.5).concat(0.75)),
      helper: {
        venueId: undefined,
        fieldNo: undefined
      },
      helperRules: {
        venueId: {
          required: true,
          trigger: "blur",
          message: errormsg.select_option
        },
        fieldNo: {
          required: false,
          trigger: "blur",
          message: errormsg.input_option
        }
      }
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then(response => {
        this.competitions = response.data.data;
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$customError();
        this.$store.commit("root/loading", false);
      });

    // eslint-disable-next-line no-unused-vars
    const that = this;
    this.grid.timeline = new Timeline(document.getElementById("visualization"), [], {
      zoomable: false,
      // zoomMin: 10 * 100 * 60 * 60, // to minutes
      zoomMax: 315360000000000 / 10000 / 12 / 4, // to week
      zoomFriction: 40,
      showCurrentTime: false,
      minHeight: 300,
      multiselect: true,
      orientation: "top",
      editable: {
        add: false,
        updateTime: true,
        updateGroup: true,
        remove: false,
        overrideItems: false
      },
      onMove(item, callback) {
        that.bulk = _.filter(that.bulk, x => x.id !== item.id);
        that.bulk.push(item);
        callback(item);
      }
    });

    this.grid.timeline.on("doubleClick", e => {
      if (e.what === "item") {
        this.$router.push({
          name: "matches.update",
          params: {
            type: "update",
            id: e.item
          }
        });
      }
    });

    // trigger search if dateRange is avaiable
    if (this.search.dateRange) {
      this.reloadGrid();
    }
  },
  methods: {
    handleMatchGrid() {
      this.$router.push({
        name: "match-grid",
      });
    },
    formatHours(val) {
      return val;
    },
    getTeamName(team, match) {
      if (team && team.name) return team.name;
      if (match.meta && match.meta.isBye) return "BYE";
      if (match.meta && match.meta.isTba) return "TBA";
      return "";
    },
    onFocus(compId) {
      const ids = _.map(
        _.filter(this.matches, x => _.get(x, "competition._id") === compId),
        "_id"
      );
      this.grid.timeline.focus(ids);
    },
    // eslint-disable-next-line func-names
    onFilterVenue: _.debounce(function(query) {
      if (query.length >= 3) {
        this.venues = [];
        this.$http
          .post("/nrl/api/v1/admin/venues/search", { criteria: query })
          .then(response => {
            this.venues = response.data.data;
          })
          .catch(() => {
            this.$customError();
          });
      }
    }, 500),
    // eslint-disable-next-line func-names
    onHelperFilterVenue: _.debounce(function(query) {
      if (query.length >= 3) {
        this.helperVenues = [];
        this.$http
          .post("/nrl/api/v1/admin/venues/search", { criteria: query })
          .then(response => {
            this.helperVenues = response.data.data;
          })
          .catch(() => {
            this.$customError();
          });
      }
    }, 500),
    searchMatches() {
      this.$refs.searchForm
        .validate()
        .then(val => {
          if (val) {
            this.$store.commit("matchGrid/clearWindow");
            this.$store.commit("matchGrid/updateSearch", this.search);
            this.$store.commit("matchGrid/updateVenues", this.venues);
            this.$store.commit("root/loading", true);
            // const body = this.search;
            // body.venueIds = _.map(this.search.venueItems, '_id');
            this.$http
              .post("nrl/api/v1/admin/matches/grid/search", this.search)
              .then(res => {
                this.matches = res.data.data;
                this.activeNames = [];
                this.$store.commit("root/loading", false);
              })
              .catch(() => {
                this.$customError();
                this.$store.commit("root/loading", false);
              });
          }
        })
        .catch(() => {});
    },
    reloadGrid() {
      this.$store.commit("root/loading", true);
      this.$http
        .post("nrl/api/v1/admin/matches/grid/search", this.search)
        .then(res => {
          this.matches = res.data.data;
          this.activeNames = [];
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    },
    addNewVenue() {
      this.$refs.helperForm.validate().then(val => {
        if (!val) return;

        const venue = _.find(this.helperVenues, { _id: this.helper.venueId });
        if (!venue) return;

        const venueIndex = _.map(this.grid.groups, "id").indexOf(`${venue._id}`);
        if (venueIndex >= 0) {
          // 1. venue already had in grid then we only need to update field no in case it is declared
          if (this.helper.fieldNo) {
            const parent = this.grid.groups[venueIndex];
            const nestedId = `${parent.id}-${this.helper.fieldNo}`;
            parent.nestedGroups.push(nestedId);
            const exists = _.some(this.grid.groups, { id: nestedId });
            if (!exists) {
              this.grid.groups.push({
                id: nestedId,
                treeLevel: 2,
                content: this.helper.fieldNo
              });
            }
          }
        } else {
          const nestedGroups = [];
          // add field number and venue
          if (this.helper.fieldNo) {
            const nestedId = `${venue._id}-${this.helper.fieldNo}`;
            nestedGroups.push(nestedId);
            const exists = _.some(this.grid.groups, { id: nestedId });
            if (!exists) {
              this.grid.groups.push({
                id: nestedId,
                treeLevel: 2,
                content: this.helper.fieldNo
              });
            }
          }
          const existGroup = _.some(this.grid.groups, { id: `${venue._id}` });
          if (!existGroup) {
            this.grid.groups.push({
              id: `${venue._id}`,
              content: venue.name,
              treeLevel: 1,
              nestedGroups
            });
          }
        }

        this.orderGroup();

        this.grid.timeline.setGroups(new DataSet(this.grid.groups));
        // // this.grid.timeline.fit();
        this.helper.venueId = undefined;
        this.helper.fieldNo = undefined;

        this.$customSuccess();
      });
    },
    onBulkEdit() {
      this.$confirm("Are you sure you want to submit these changes?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          const { start, end } = this.grid.timeline.getWindow();
          this.$store.commit("matchGrid/updateWindow", {
            start: +this.moment(start),
            end: +this.moment(end)
          });
          this.$store.commit("root/loading", true);
          const matches = this.bulk.map(x => {
            const [venueId, fieldNo] = x.group.split("-");
            return {
              _id: x.id,
              venueId: +venueId,
              fieldNo: fieldNo,
              dateTime: +this.moment(x.start)
            };
          });
          this.$http
            .put("nrl/api/v1/admin/matches/grid/bulk-edit", { matches })
            .then(() => {
              this.bulk = [];
              this.$store.commit("root/loading", false);
              this.$customSuccess();
              this.reloadGrid();
            })
            .catch(err => {
              this.$customError(_.get(err, "response.data.message"));
              this.$store.commit("root/loading", false);
            });
        })
        .catch(() => {});
    },
    onDurationChange() {
      this.reloadGrid();
    },
    orderGroup() {
      this.grid.groups = _.sortBy(this.grid.groups, x => +x.content);
      // move N/A to head of the group
      const naElement = _.find(this.grid.groups, { id: "undefined" });
      if (naElement) {
        this.grid.groups = _.remove(this.grid.groups, x => x.id !== "undefined");
        this.grid.groups.unshift(naElement);
      }
    },
    resetWindow() {
      this.grid.isSetWindow = true;
      let [startDate] = this.search.dateRange;
      const closestDate = _.min(_.map(this.matches, "dateTime"));
      if (closestDate) startDate = closestDate;
      const { start, end } = this.$store.state.matchGrid.timeline.window;
      if (start && end) this.grid.timeline.setWindow(start, end);
      else this.grid.timeline.setWindow(startDate, +this.moment(startDate).add(12, "hours"));
      this.grid.timeline.redraw();
    },
    formatGrid() {
      this.grid.groups = [];
      // compute indicators competitions
      // eslint-disable-next-line no-unused-vars
      this.grid.competitions = _.map(
        _.toPairs(_.groupBy(this.matches, "competition._id")),
        ([key, items]) => _.get(_.head(items), "competition")
      );
      // compute groups and nested groups
      // eslint-disable-next-line func-names
      this.grid.groupsLvl1 = _.map(
        _.toPairs(_.groupBy(this.matches, "venue._id")),
        ([venueId, itemsLvl1]) => {
          const groupsLvl2 = _.map(
            _.toPairs(_.groupBy(itemsLvl1, "meta.fieldNo")),
            ([fieldNo, itemsLvl2]) => ({
              id: `${venueId}-${fieldNo}`,
              treeLevel: 2,
              content: _.get(_.head(itemsLvl2), "meta.fieldNo", "NA")
            })
          );
          this.grid.groups = _.concat(this.grid.groups, groupsLvl2);
          return {
            id: `${venueId}`,
            content: _.get(_.head(itemsLvl1), "venue.name", "NA"),
            treeLevel: 1,
            nestedGroups: _.map(groupsLvl2, "id")
          };
        }
      );
      this.grid.groups = _.concat(this.grid.groups, this.grid.groupsLvl1);

      this.orderGroup();

      // compute items
      this.grid.items = _.map(
        this.matches,
        (
          { _id, venue, homeTeam, competition, awayTeam, dateTime, canEdit, round, meta, isClash },
          index
        ) => {
          const contentPrefix = canEdit ? "" : '<i class="el-icon-lock"></i>';
          const titlePostfix = `(${_.get(round, "displayName")}, ${this.moment(dateTime).format(
            "HH:mm"
          )})`;
          return {
            id: _id,
            group: `${_.get(venue, "_id", "undefined")}-${_.get(meta, "fieldNo", "undefined")}`,
            content: `${contentPrefix} ${this.getTeamName(
              homeTeam,
              this.matches[index]
            )} vs ${this.getTeamName(awayTeam, this.matches[index])}`,
            title: `${this.getTeamName(homeTeam, this.matches[index])} vs ${this.getTeamName(
              awayTeam,
              this.matches[index]
            )} ${titlePostfix}`,
            start: this.moment(dateTime).format(),
            end: this.moment(dateTime)
              .add(this.search.duration, "hours")
              .format(),
            selectable: canEdit,
            className: `${canEdit ? (isClash ? "clash" : "") : "read-only"} ${
              this.colors[
                _.findIndex(this.grid.competitions, { _id: _.get(competition, "_id") }) %
                  this.colors.length
              ]
            } `
          };
        }
      );

      // assign to timeline
      this.grid.timeline.setGroups(new DataSet(this.grid.groups));
      this.grid.timeline.setItems(new DataSet(this.grid.items));

      this.resetWindow();
    }
  },
  watch: {
    matches(matches) {
      this.$store.commit("matchGrid/updateMatches", matches);
      // reset bulk
      this.bulk = [];
      this.formatGrid();
    }
  },
  beforeRouteLeave(to, from, next) {
    const { start, end } = this.grid.timeline.getWindow();

    if (this.bulk.length) {
      this.$confirm("You have unsaved changes that will be lost", "Unsaved Changes")
        .then(() => {
          this.$store.commit("matchGrid/updateWindow", {
            start: +this.moment(start),
            end: +this.moment(end)
          });
          next();
        })
        .catch(() => {
          next(false);
        });
    } else {
      this.$store.commit("matchGrid/updateWindow", {
        start: +this.moment(start),
        end: +this.moment(end)
      });
      next();
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-input-number .el-input__inner {
  text-align: left;
}
.el-date-editor,
.el-select,
.el-input {
  width: 100%;
}
.mw200px {
  min-width: 200px;
}

.el-tag {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  border: 1px solid;
  display: inline-block;
  border-radius: 2px;
  font-size: 15px;
}
.pointer {
  cursor: pointer;
}
.el-tag.blue {
  background-color: #d5ddf6;
  border-color: #97b0f8;
  color: #000;
}
.el-tag.red {
  background-color: #f6d5d5;
  border-color: #f89696;
  color: #000;
}
.el-tag.orange {
  background-color: #f6e6d5;
  border-color: #f8c796;
  color: #000;
}
.el-tag.butter {
  background-color: #f6f6d5;
  border-color: #d8d80e;
  color: #000;
}
.el-tag.green {
  background-color: #d5f6d5;
  border-color: #0ed80e;
  color: #000;
}
.el-tag.purple {
  background-color: #ddd5f6;
  border-color: #ae96f8;
  color: #000;
}
.el-tag.grey {
  background-color: #e6e6e6;
  border-color: #8c8c8c;
  color: #000;
}

.notes {
  font-size: 14px;
  color: $secondary;
  margin: 7px 0px;
}
</style>

<style>
.read-only {
  opacity: 0.6;
}

.clash {
  border-color: red !important;
  border-width: 0.125rem !important;
}
/*
* visualization
*/
.vis-item.red {
  background-color: #f6d5d5;
  border-color: #f89696;
  color: #1a1a1a;
}
.vis-item.orange {
  background-color: #f6e6d5;
  border-color: #f8c796;
  color: #1a1a1a;
}
.vis-item.butter {
  background-color: #f6f6d5;
  border-color: #d8d80e;
  color: #1a1a1a;
}
.vis-item.green {
  background-color: #d5f6d5;
  border-color: #0ed80e;
  color: #1a1a1a;
}
.vis-item.purple {
  background-color: #ddd5f6;
  border-color: #ae96f8;
  color: #1a1a1a;
}
.vis-item.grey {
  background-color: #e6e6e6;
  border-color: #8c8c8c;
  color: #1a1a1a;
}
.vis-item.vis-selected {
  border-color: #ffc200;
  background-color: #fff785;
  z-index: 2;
}
</style>
