<template>
  <div class="" v-if="record && record.possibleDuplicates && record.possibleDuplicates.length">
    <el-row class="d-flex align-center">
      <el-col :span="12" class="go-back">
        <el-button @click="cancelForm" class="go-back-button" icon="el-icon-arrow-left">
          Go Back
        </el-button>
      </el-col>
      <el-col :span="12" class="merge-members">
        <el-button type="danger" @click="displayMergeDialog = true" v-if="hasDuplicates">
          Merge Selected Members
        </el-button>
        <h3 class="resolved-text" v-else>Resolved</h3>
      </el-col>
    </el-row>
    <div>
      <h2 class="tw-py-6 tw-text-2xl tw-text-red-500 tw-font-bold">
        Important: Please read before proceeding
      </h2>
      <p class="tw-pb-4">
        The below function allows you to merge multiple records by first selecting the Primary
        participant record – this should be the most accurate existing record as the Primary record
        member details (name, date of birth, address & contact details etc.) will remain following
        the merge. If the Secondary record contains member data that the Primary record does not
        then this will be kept on the Primary record.
      </p>
      <p class="tw-pb-10">
        Select any related Secondary records and complete the merge. Once completed, any selected
        Secondary records and their accompanying data (accreditation, registration, game stats,
        etc.) will be merged into the primary record. Note: Any records that are merged cannot be
        undone.
      </p>
    </div>
    <el-row :gutter="30" v-if="record.merges">
      <el-col :span="24" class="mb-3">
        <h4 class="tw-text-md tw-font-bold tw-pb-8">AUDIT - COMPLETED MERGES</h4>
        <el-table :data="record.merges" style="width: 100%" row-class-name="success-row">
          <el-table-column prop="primaryMember._id" label="Primary" width="auto"> </el-table-column>
          <el-table-column prop="secondaryMember._id" label="Secondary" width="auto">
          </el-table-column>
          <el-table-column prop="mergedByName" label="Merged By" width="auto"> </el-table-column>
          <el-table-column prop="notes" label="Notes" width="auto"> </el-table-column>
          <el-table-column :formatter="mergedAtFormatter" label="Date & Time" width="auto">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="30" v-if="record.ignores && record.ignores.length">
      <el-col :span="24" class="mb-3">
        <h4 class="tw-text-lg tw-font-bold tw-pb-8">AUDIT - IGNORED RECORDS</h4>
        <el-table :data="record.ignores" style="width: 100%" row-class-name="success-row">
          <el-table-column prop="memberId" label="Member ID" width="auto"> </el-table-column>
          <el-table-column prop="ignoredByName" label="Ignored By" width="auto"> </el-table-column>
          <el-table-column prop="notes" label="Notes" width="auto"> </el-table-column>
          <el-table-column :formatter="ignoredAtFormatter" label="Date & Time" width="auto">
          </el-table-column>
          <el-table-column label="Undo">
            <template slot-scope="scope">
              <el-button size="mini" @click="undoIgnore(scope.row.memberId)">
                Undo Ignore
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="30" class="mt-2">
      <el-col :span="12">
        <div>
          <el-row :gutter="10" class="mb-3">
            <el-col :span="12">
              <h4 class="tw-text-md tw-font-bold ">PRIMARY</h4>
            </el-col>
            <el-col :span="12" v-if="selectedMasterId" class="align-text-right">
              <el-button
                size="mini"
                v-if="hasDuplicates"
                @click="
                  selectedIgnoreId = selectedMasterId;
                  displayIgnoreDialog = true;
                "
              >
                Ignore This Record
              </el-button>
            </el-col>
          </el-row>
          <el-select
            class="record-select"
            name="select-master"
            id="select-master"
            v-model="selectedMasterId"
            placeholder="Primary Record"
          >
            <el-option
              v-for="{ _id, firstName, lastName, TFA } in record.possibleDuplicates"
              :value="_id"
              :label="`${firstName} ${lastName} (${_id}) - ${TFA ? 'TFA' : 'NRL'}`"
              :key="_id"
            >
            </el-option>
          </el-select>
        </div>
        <member-data v-if="selectedMasterId" :key="selectedMasterId" :memberId="selectedMasterId" />
      </el-col>
      <el-col :span="12" v-if="hasDuplicates">
        <div class="">
          <el-row :gutter="10" class="mb-3">
            <el-col :span="12">
              <h4 class="no-margin">SECONDARY</h4>
            </el-col>
            <el-col :span="12" v-if="selectedSlaveId" class="align-text-right">
              <el-button
                size="mini"
                v-if="hasDuplicates"
                @click="
                  selectedIgnoreId = selectedSlaveId;
                  displayIgnoreDialog = true;
                "
              >
                Ignore This Record
              </el-button>
            </el-col>
          </el-row>
          <el-select
            class="record-select"
            name="select-slave"
            id="select-slave"
            v-model="selectedSlaveId"
            placeholder="Secondary Record"
          >
            <el-option
              v-for="{ _id, firstName, lastName, TFA } in record.possibleDuplicates.filter(
                r => r._id !== selectedMasterId
              )"
              :value="_id"
              :label="`${firstName} ${lastName} (${_id}) - ${TFA ? 'TFA' : 'NRL'}`"
              :key="_id"
            >
            </el-option>
          </el-select>
        </div>
        <member-data v-if="selectedSlaveId" :key="selectedSlaveId" :memberId="selectedSlaveId" />
      </el-col>
    </el-row>

    <el-collapse v-model="activeNames" v-if="selectedMasterId || selectedSlaveId">
      <el-collapse-item title="Registrations" name="1">
        <registrations
          :key="`${selectedMasterId} + ${selectedSlaveId}`"
          :masterId="selectedMasterId"
          :slaveId="selectedSlaveId"
        />
      </el-collapse-item>
      <el-collapse-item title="Accreditations" name="2">
        <accreditations
          :key="`${selectedMasterId} + ${selectedSlaveId}`"
          :masterId="selectedMasterId"
          :slaveId="selectedSlaveId"
        />
      </el-collapse-item>
    </el-collapse>

    <el-row :gutter="10" class="note-section">
      <el-divider> Notes </el-divider>
      <el-col :span="24">
        <el-input
          autocomplete="off"
          type="textarea"
          :rows="7"
          placeholder="Add Notes To This Record"
          v-model="newNote"
        >
        </el-input>
      </el-col>
      <el-col :span="24" class="add-note-button">
        <el-button
          class="tw-bg-black tw-border-none hover:tw-bg-gray-800"
          type="primary"
          @click="addCurrentNote"
        >
          Add Note
        </el-button>
      </el-col>
    </el-row>
    <el-timeline>
      <el-timeline-item
        v-for="note in recordNotes"
        :key="note._id"
        :timestamp="formatNoteTimestamp(note.createdAt)"
        placement="top"
      >
        <el-card>
          <h4>{{ note.createdByName }}</h4>
          <p>{{ note.note }}</p>
        </el-card>
      </el-timeline-item>
    </el-timeline>

    <el-dialog
      width="60%"
      top="10vh"
      title="Confirm Ignore Member"
      class="merge-dialog"
      v-if="selectedIgnore"
      :before-close="
        () => {
          displayIgnoreDialog = false;
          selectedIgnoreId = null;
        }
      "
      :visible.sync="displayIgnoreDialog"
    >
      <h3 slot="title"></h3>
      <div class="important">
        <h2 class="text-center">Important: Please verify that all the information is correct.</h2>
      </div>
      <el-row :gutter="20" class="compare-records">
        <el-col :span="24" class="d-flex-column">
          <el-card>
            <div slot="header" class="clearfix text-center">
              <h4>Ignoring Record</h4>
            </div>
            <p class="green">NRLID: {{ selectedIgnoreId }}</p>
            <p>First Name: {{ selectedIgnore.firstName }}</p>
            <p>Last Name: {{ selectedIgnore.lastName }}</p>
            <p>DOB: {{ selectedIgnore.dob }}</p>
          </el-card>
        </el-col>
      </el-row>
      <el-input
        type="textarea"
        class="merge-note-area"
        :rows="5"
        placeholder="Add note to this ignore"
        v-model="ignoreNote"
      >
      </el-input>
      <el-row class="d-flex flex-justify-center confirm-merge">
        <el-button type="success" @click="ignoreRecord(selectedIgnoreId)">
          Confirm
        </el-button>
      </el-row>
    </el-dialog>

    <el-dialog
      width="60%"
      top="10vh"
      title="Confirm Merge Member"
      class="merge-dialog"
      v-if="hasDuplicates && selectedMasterId && selectedSlaveId"
      :visible.sync="displayMergeDialog"
    >
      <h3 slot="title"></h3>
      <div class="important">
        <h2 class="text-center">
          Important: Please verify that all the information is correct before merging.
        </h2>
      </div>
      <el-row :gutter="20" class="compare-records">
        <el-col :span="11" class="d-flex-column">
          <el-card>
            <div slot="header" class="clearfix text-center">
              <h4>Primary Record</h4>
            </div>
            <p class="green">NRLID: {{ selectedMasterId }}</p>
            <p>First Name: {{ selectedMaster.firstName }}</p>
            <p>Last Name: {{ selectedMaster.lastName }}</p>
            <p>DOB: {{ selectedMaster.dob }}</p>
          </el-card>
        </el-col>
        <el-col :span="2" class="icon-merge">
          <i class="el-icon-back" />
        </el-col>
        <el-col :span="11" class="d-flex-column">
          <el-card>
            <div slot="header" class="clearfix text-center">
              <h4>Secondary Record</h4>
            </div>
            <p class="green">NRLID: {{ selectedSlaveId }}</p>
            <p>First Name: {{ selectedSlave.firstName }}</p>
            <p>Last Name: {{ selectedSlave.lastName }}</p>
            <p>DOB: {{ selectedSlave.dob }}</p>
          </el-card>
        </el-col>
      </el-row>
      <el-input
        type="textarea"
        class="merge-note-area"
        :rows="5"
        placeholder="Add note to this merge"
        v-model="mergeNote"
      >
      </el-input>
      <el-row class="d-flex flex-justify-center confirm-merge">
        <el-button type="success" @click="mergeMembers()">
          Confirm
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import MemberData from "./MemberData.vue";
import Registrations from "./Registrations.vue";
import Accreditations from "./Accreditations.vue";

export default {
  components: {
    MemberData,
    Registrations,
    Accreditations
  },
  data() {
    return {
      activeNames: null,
      selectedMasterId: null,
      selectedSlaveId: null,
      selectedIgnoreId: null,
      displayMergeDialog: false,
      newNote: "",
      mergeNote: "",
      ignoreNote: "",
      record: {}
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { id } = this.$route.params;
    this.$http
      .get(`/nrl/api/v1/admin/duplicate-members/${id}`)
      .then(res => {
        this.record = res.data.data;
        this.record.possibleDuplicates = this.record.possibleDuplicates.filter(d => !d.ignored);
        if (this.hasDuplicates) {
          this.selectedMasterId = this.record.possibleDuplicates[0]._id;
          this.selectedSlaveId = this.record.possibleDuplicates.filter(
            r => r._id !== this.selectedMasterId
          )[0]._id;
        }
        this.$store.commit("root/loading", false);
      })
      .catch(err => {
        this.$customError(`Error fetching duplicate member record: ${err}`);
        this.$store.commit("root/loading", false);
      });
  },
  methods: {
    cancelForm() {
      this.$router.push("/duplicate-members");
    },
    addCurrentNote() {
      const { newNote, record } = this;
      if (newNote) {
        const { _id: recordid } = record;
        this.$store.commit("root/loading", true);
        this.$http
          .post(`/nrl/api/v1/admin/duplicate-members/${recordid}/notes`, { note: newNote })
          .then(res => {
            this.record.notes = this.record.notes.concat(res.data.data);
            this.$store.commit("root/loading", false);
            this.newNote = "";
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
    },
    formatNoteTimestamp(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD");
    },
    mergedAtFormatter(row) {
      if (row.mergedAt) {
        return this.moment(row.mergedAt).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
    ignoredAtFormatter(row) {
      if (row.ignoredAt) {
        return this.moment(row.ignoredAt).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
    mergeMembers() {
      const { selectedMasterId, selectedSlaveId, record, mergeNote } = this;
      const { _id: recordid } = record;
      this.displayMergeDialog = false;
      this.$store.commit("root/loading", true);
      this.$http
        .post(`/nrl/api/v1/admin/duplicate-members/${recordid}/merge`, {
          primaryId: selectedMasterId,
          secondaryId: selectedSlaveId,
          notes: mergeNote || ""
        })
        .then(res => {
          this.selectedSlaveId = null;
          this.selectedMasterId = null;
          this.record = res.data.data;
          this.record.possibleDuplicates = this.record.possibleDuplicates.filter(d => !d.ignored);
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
          this.displayMergeDialog = false;
        });
    },
    ignoreRecord(nrlid) {
      this.$store.commit("root/loading", true);
      const { record, ignoreNote } = this;
      const { _id: recordid } = record;
      this.$http
        .post(`/nrl/api/v1/admin/duplicate-members/${recordid}/ignore`, {
          memberId: nrlid,
          notes: ignoreNote || ""
        })
        .then(res => {
          this.selectedSlaveId = null;
          this.selectedMasterId = null;
          this.selectedIgnoreId = null;
          this.displayIgnoreDialog = false;
          this.record = res.data.data;
          this.record.possibleDuplicates = this.record.possibleDuplicates.filter(d => !d.ignored);
          if (this.hasDuplicates) {
            this.selectedMasterId = this.record.possibleDuplicates[0]._id;
            this.selectedSlaveId = this.record.possibleDuplicates.filter(
              r => r._id !== this.selectedMasterId
            )[0]._id;
          }
          this.ignoreNote = "";
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.selectedIgnoreId = null;
          this.displayIgnoreDialog = false;
          this.ignoreNote = "";
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    undoIgnore(nrlid) {
      this.$store.commit("root/loading", true);
      const { record } = this;
      const { _id: recordid } = record;
      this.$http
        .post(`/nrl/api/v1/admin/duplicate-members/${recordid}/ignore/undo`, { memberId: nrlid })
        .then(res => {
          this.selectedSlaveId = null;
          this.selectedMasterId = null;
          this.selectedIgnoreId = null;
          this.displayIgnoreDialog = false;
          this.record = res.data.data;
          this.record.possibleDuplicates = this.record.possibleDuplicates.filter(d => !d.ignored);
          if (this.hasDuplicates) {
            this.selectedMasterId = this.record.possibleDuplicates[0]._id;
            this.selectedSlaveId = this.record.possibleDuplicates.filter(
              r => r._id !== this.selectedMasterId
            )[0]._id;
          }
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }
  },
  computed: {
    selectedSlave() {
      return this.record.possibleDuplicates.find(record => record._id === this.selectedSlaveId);
    },
    selectedMaster() {
      return this.record.possibleDuplicates.find(record => record._id === this.selectedMasterId);
    },
    selectedIgnore() {
      return this.record.possibleDuplicates.find(record => record._id === this.selectedIgnoreId);
    },
    hasDuplicates() {
      return this.record.possibleDuplicates.length > 1;
    },
    recordNotes() {
      const { notes } = this.record;
      if (notes.length) {
        return _.orderBy(notes, ["createdAt"], ["desc"]);
      }
      return [];
    }
  },
  watch: {
    selectedMasterId() {
      if (this.hasDuplicates && this.selectedMasterId) {
        this.selectedSlaveId = this.record.possibleDuplicates.filter(
          r => r._id !== this.selectedMasterId
        )[0]._id;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.important {
  h2 {
    color: red;
  }

  p {
    font-family: $fontRegular;
    font-size: 12px;
  }
}

.resolved-text {
  color: #098255 !important;
}

.record-select {
  width: 100%;
}
.go-back-button {
  margin-bottom: 1rem;
}

::v-deep .el-dialog__body {
  padding: 22px 55px;
}

::v-deep .el-card__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .el-card__header {
  padding: 0;
}

.success-row {
  background: $nrlGreen;
  color: white;
}

.merge-dialog {
  .compare-records {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    height: 19rem;
  }

  .merge-note-area {
    margin-top: 0.5rem;
    margin-bottom: 3rem;
  }

  .icon-merge {
    display: flex;
    justify-content: center;
    height: 100%;
    i {
      display: flex;
      align-items: center;
      font-size: 2.5rem;
      font-weight: bold;
    }
  }

  .confirm-merge {
    margin-bottom: 1.5rem;
  }
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.merge-members {
  display: flex;
  justify-content: flex-end;
}

.note-section {
  margin-top: 1rem;
  margin-bottom: 2rem;

  .add-note-button {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.no-margin {
  margin: 0;
}
</style>
