<template>
  <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-16">
    <div class="tw-flex tw-flex-col">
      <p class="tw-w-64 tw-p-4 tw-mb-4 tw-border tw-border-gray-300 tw-rounded">
        Step 1. Get pending transfers from pin-transfers and bundle into the pin-wip collection
        <br />
      </p>
      <button class="el-button el-button--success" @click="step1()">Step 1</button>
    </div>
    <div class="tw-flex tw-flex-col">
      <p class="tw-w-64 tw-p-4 tw-mb-4 tw-border tw-border-gray-300 tw-rounded">
        Step 2. Send bundled transfers from pin-wip to pin for processing
        <br />
      </p>
      <button class="el-button el-button--success" @click="step2()">Step 2</button>
    </div>
    <div class="tw-flex tw-flex-col">
      <p class="tw-w-64 tw-p-4 tw-mb-4 tw-border tw-border-gray-300 tw-rounded">
        Step 3. Update the pin-transfers for the disbursement report
        <br />
      </p>

      <button class="el-button el-button--success" @click="step3()">Step 3</button>
    </div>
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, mysidelineSeasons } from "@/utils/constants";

export default {
  name: "FailedTransfersReport",
  components: {
    ActionButtons,
  },
  mounted() {
    const { load, skip, pages } = this.$route.query;
    if (load === "auto") {
      this.sendReport(skip, pages);
    }
  },
  data() {
    return {};
  },
  methods: {
    step1() {
      // send to endpoint

      this.$httpms
        .get("/cronsPinTransfersBatch")
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    },
    step2() {
      this.$httpms
        .get("/cronsPinTransfersSend")
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    },
    step3() {
      this.$httpms
        .get("/cronsPinTransfersUpdateFinal")
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    },
  },
  computed: {
    seasons() {
      return mysidelineSeasons.map((v) => ({ name: v.toString(), value: v }));
    },
  },
};
</script>
