<template>
  <div>
    <div>
      <div class="tw-flex tw-justify-center tw-w-full">
        <img
          v-if="currentLogo"
          :src="currentLogo"
          @error="displayPlaceholder"
          alt=""
          class="tw-w-24"
        />
        <img v-else src="~@/assets/shield_black_small.svg" alt="" class="avatar" />
      </div>
    </div>

    <el-form>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-py-3">
        <el-col :span="6">
          <el-form-item label="Program Type">
            <el-input
              autocomplete="off"
              v-model="program.type"
              placeholder="create a program type"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="isActive" label="Active">
            <el-select v-model="program.isActive">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="isContact" label="Contact">
            <el-select v-model="program.isContact">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="isVisibleToAllRegions" label="Visible to all regions">
            <el-select v-model="program.isVisibleToAllRegions">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col class="submit-action" :span="12">
          <el-button @click="addSettingsStatus" type="success">Submit</el-button>
        </el-col>
      </div>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";

import { getS3URL } from "../../utils/utilities";
import { uploadURL } from "../../utils/constants";

export default {
  name: "ProgramsettingsForm",
  components: {},
  data() {
    return {
      program: {
        type: undefined,
        name: undefined,
        orgtree: { national: {} },
        options: [],
        saleableItems: [],
        additionalQuestions: [],
        isActive: null,
        isContact: null,
        isVisibleToAllRegions: null,
        visibleToRegions: [],
        meta: {
          avatar: "",
        },
        _id: undefined,
      },
      isSuperAdmin: this.$store.getters["user/isSuperAdmin"],
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png",
      files: [],
      type: null,
    };
  },
  methods: {
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },

    addSettingsStatus() {
      this.$confirm("Are you sure this is a new Program Type?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(() => {
          const url = "/nrl/api/v1/admin/programs-settings";

          const activeRole = this.$store.getters["user/activeRole"];

          this.program.orgtree.national._id = activeRole.entity._id;
          this.program.orgtree.national.name = activeRole.entity.name;
          this.$http
            .post(url, this.program)
            .then((response) => {
              const programSettings = response;
              this.program._id = programSettings._id;
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch((e) => {
              this.$store.commit("root/loading", false);
              this.$customError(e);
            });
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
        });
    },
  },
  computed: {
    currentLogo() {
      return this.$route.params.id ? getS3URL(this.$route.params.id) : false;
    },
  },
};
</script>

<style scoped lang="scss">
.submit-action {
  float: right;
  width: auto;
}

.el-select {
  width: 100%;
}
</style>
