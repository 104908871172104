<template>
  <div>
    <el-form :model="value" :rules="regradingRules" label-position="top" ref="regrading">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="sourceCompetition._id" label="Source Competition">
            <el-select
              v-model="value.sourceCompetition._id"
              placeholder="Select a source competition"
              filterable
              clearable
            >
              <el-option
                v-for="{ _id, name } in sourceCompetitions"
                :label="name"
                :key="`source_${_id}`"
                :value="_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="regradeTeam._id" label="Team to Regrade">
            <el-select
              v-model="value.regradeTeam._id"
              placeholder="Select a team to regrade"
              filterable
            >
              <el-option
                v-for="{ _id, name } in regradeTeams"
                :label="name"
                :key="`regrade_${_id}`"
                :value="_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="destCompetition._id" label="Destination Competition">
            <el-select
              v-model="value.destCompetition._id"
              placeholder="Select a destination competition"
              filterable
              clearable
            >
              <el-option
                v-for="{ _id, name } in destCompetitions"
                :label="name"
                :key="`destination_${_id}`"
                :value="_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Team Designation in Destination Competition">
            <div v-for="{ type, name } in designationOptions" :key="type">
              <el-radio :label="type" v-model="value.designationOption">{{ name }}</el-radio>
            </div>
            <el-form-item prop="replaceTeam._id">
              <el-select
                v-model="value.replaceTeam._id"
                placeholder="Select team to replace from destination competition"
                filterable
                :disabled="value.designationOption !== 'replace'"
              >
                <el-option
                  v-for="{ _id, name } in replaceTeams"
                  :label="name"
                  :key="`replace_${_id}`"
                  :value="_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="value.designationOption === 'new'">
        <el-col :span="12">
          <el-form-item label="Destination Competition Matches">
            <div v-for="{ type, name } in destMatchOptions" :key="type">
              <el-radio :label="type" v-model="value.destMatchOption">{{ name }}</el-radio>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="value.designationOption === 'new'">
        <el-col :span="12">
          <el-form-item label="Source Competition Matches">
            <div v-for="{ type, name } in sourceMatchOptions" :key="type">
              <el-radio :label="type" v-model="value.sourceMatchOption">{{ name }}</el-radio>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Ladder Options">
            <div v-for="{ type, name } in ladderOptions" :key="type">
              <el-radio :label="type" v-model="value.ladderOption">{{ name }}</el-radio>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="mb-2">
        <el-col :span="20">
          <el-alert
            v-for="(error, i) in errors"
            :key="i"
            type="error"
            :description="error"
            show-icon
          >
          </el-alert>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { sortBy } from "lodash";
import { errormsg } from "../../utils/constants";

export default {
  props: ["value"],
  data() {
    const validatereplaceTeam = (rule, value, callback) => {
      if (this.value.designationOption === "replace" && !this.value.replaceTeam._id) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    return {
      regradingRules: {
        sourceCompetition: {
          _id: {
            required: true,
            message: errormsg.select_option,
            trigger: "blur",
          },
        },
        regradeTeam: {
          _id: {
            required: true,
            message: errormsg.select_option,
            trigger: "blur",
          },
        },
        destCompetition: {
          _id: {
            required: true,
            message: errormsg.select_option,
            trigger: "blur",
          },
        },
        replaceTeam: {
          _id: {
            required: true,
            message: errormsg.select_option,
            trigger: "blur",
            validator: validatereplaceTeam,
          },
        },
      },
      competitions: [],
      sourceCompetitions: [],
      regradeTeams: [],
      regradeMatches: [],
      destCompetitions: [],
      replaceTeams: [],
      replaceMatches: [],
      designationOptions: [
        {
          type: "new",
          name: "Create as a new team",
        },
        {
          type: "replace",
          name: "Replace an existing team",
        },
      ],
      sourceMatchOptions: [
        {
          type: "replace",
          name: "Replace migrated team with bye in future matches",
        },
        {
          type: "redo",
          name: "Redo draw for future matches",
        },
      ],
      destMatchOptions: [
        {
          type: "replace",
          name: "Replace future byes with re-graded team (If byes exist)",
        },
        {
          type: "redo",
          name: "Redo draw for future matches",
        },
      ],
      ladderOptions: [
        {
          type: "copyfull",
          name: "Copy full ladder details into Competition(s)",
        },
        {
          type: "copypoint",
          name: "Copy total ladder points only into Competition(s)",
        },
        {
          type: "nocopy",
          name: "Do not copy ladder details into Competition(s)",
        },
      ],
      errors: [],
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then((response) => {
        this.competitions =
          response.data && response.data.data ? response.data.data.filter((c) => c.isActive) : [];
        this.sourceCompetitions = this.competitions;
        this.destCompetitions = this.competitions;
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  methods: {
    getMinRedoRound(comp, matches, type = "Regular") {
      matches = matches.filter((x) => x.round.type === type);
      matches = sortBy(matches, "round.number");
      const currentRoundIndex = matches.findIndex(
        (x) =>
          x.status === "pre-game" &&
          (!x.scoringEvents || !x.scoringEvents.length) &&
          (!x.playerMovements || !x.playerMovements.length)
      );

      if (currentRoundIndex >= 0) {
        // Found current round number by match condition (status: pre-game, dateTime > current moment, no scoringEvents, no playerMovements)
        let currentRound = matches[currentRoundIndex].round.number;
        // Check if previous round has no match, change current round to previous round (For cases if delete matches between rounds)
        while (currentRound > 1) {
          // eslint-disable-next-line no-loop-func
          const previousRoundMatch = matches.filter((x) => x.round.number === currentRound - 1);
          if (previousRoundMatch.length) break;
          else {
            // Previous round has no match, set current round to previous round
            currentRound -= 1;
          }
        }

        return currentRound;
      }

      /* For case if not found current round by previous logic */
      // No match in comp, return round 1
      if (!matches.length) {
        return 1;
      }

      // Find last match
      const lastMatch = matches[matches.length - 1];
      if (lastMatch) {
        const lastRound = lastMatch.round.number;
        if (lastRound === comp.regularSeasonRounds) {
          // All rounds completed
          return -1;
        }

        // Get current round by last round + 1 (For cases if delete all future matches)
        return lastRound + 1;
      }

      return 0;
    },
    validation() {
      const errors = [];

      // Comps
      const sourceComp = this.competitions.find((x) => x._id === this.value.sourceCompetition._id);
      const destComp = this.competitions.find((x) => x._id === this.value.destCompetition._id);

      if (sourceComp.ageLvl !== destComp.ageLvl) {
        errors.push("Source Competition and Destination Competition must have the same age level");
      }

      if (this.value.designationOption === "new") {
        if (sourceComp.hasPools || (sourceComp.pools && sourceComp.pools.length > 0)) {
          errors.push(
            "Cannot re-grade team from the 'Source Competition', because the competition has pools."
          );
        }
        if (destComp.hasPools || (destComp.pools && destComp.pools.length > 0)) {
          errors.push(
            "Cannot re-grade team to the 'Destination Competition', because the competition has pools."
          );
        }
      }

      if (this.value.sourceMatchOption === "redo") {
        // number of regrade teams must be greater than 3 after regrade
        if (sourceComp.teams.length < 4)
          errors.push("4 or more teams are required in the source competition");

        // Current Round for source and destinaton competitions
        const sourceCurrentRound = this.getMinRedoRound(sourceComp, this.regradeMatches);
        // Validation for if all rounds has completed
        if (sourceCurrentRound < 1)
          errors.push(
            "Cannot re-grade team from the 'Source Competition', because all rounds in the competition have completed."
          );

        // Validation for any matches after current round (include current round) has result status
        const sourceStarteddMatch = this.regradeMatches.find(
          (x) =>
            x.round.number >= sourceCurrentRound &&
            x.round.type === "Regular" &&
            x.status !== "pre-game"
        );
        if (sourceStarteddMatch)
          errors.push(
            `Cannot re-grade team from the 'Source Competition', because some matches have a status of 'Result', 'Forfeit' or 'Disputed' in current round (Round ${sourceCurrentRound}) or later rounds.`
          );

        // Validation for game in past round not marked as result
        const sourceResultedMatches = this.regradeMatches.find(
          (x) =>
            ["pre-game", "disputed"].includes(x.status) &&
            x.round.number < sourceCurrentRound &&
            x.round.type === "Regular"
        );

        if (sourceResultedMatches)
          errors.push(
            `Matches in the 'Source Competition' must not have a status of 'pre-game' or 'disputed' in any round prior to the current round (Round ${sourceCurrentRound}). Please amend before processing the regrade.`
          );
      }

      if (this.value.destMatchOption === "redo") {
        // number of regrade teams must be greater than 3 after regrade
        if (destComp.teams.length < 2)
          errors.push("2 or more teams are required in the destination competition");

        // Current Round for source and destinaton competitions
        const destCurrentRound = this.getMinRedoRound(destComp, this.replaceMatches);
        // Validation for if all rounds has completed
        if (destCurrentRound < 1)
          errors.push(
            "Cannot re-grade team to the 'Destination Competition', because all rounds in the competition have completed."
          );

        // Validation for any matches after current round (include current round) has result status
        const destStarteddMatch = this.replaceMatches.find(
          (x) =>
            x.round.number >= destCurrentRound &&
            x.round.type === "Regular" &&
            x.status !== "pre-game"
        );
        if (destStarteddMatch)
          errors.push(
            `Cannot re-grade team to the 'Destination Competition', because some matches have a status of 'Result', 'Forfeit' or 'Disputed' in current round (Round ${destCurrentRound}) or later rounds.`
          );

        // Validation for game in past round not marked as result
        const destResultedMatches = this.replaceMatches.find(
          (x) =>
            ["pre-game", "disputed"].includes(x.status) &&
            x.round.number < destCurrentRound &&
            x.round.type === "Regular"
        );

        if (destResultedMatches)
          errors.push(
            `Matches in the 'Destination Competition' must not have a status of 'pre-game' or 'disputed' in any round prior to the current round (Round ${destCurrentRound}). Please amend before processing the regrade.`
          );
      }
      return errors;
    },
    submit() {
      this.errors = [];

      return this.$refs.regrading.validate().then((val) => {
        if (val) {
          this.errors = this.validation();
          if (this.errors && this.errors.length > 0) {
            return false;
          }
          this.$emit("input", this.value);
        }
        return val;
      });
    },
  },
  watch: {
    "value.designationOption": function () {},
    "value.sourceMatchOption": function () {
      this.$emit("input", this.value);
    },
    "value.destMatchOption": function () {
      this.$emit("input", this.value);
    },
    "value.regradeTeam._id": function (id) {
      Object.assign(
        this.value.regradeTeam,
        this.regradeTeams.find((x) => x._id === id) || { _id: null, name: null }
      );
    },
    "value.replaceTeam._id": function (id) {
      Object.assign(
        this.value.replaceTeam,
        this.replaceTeams.find((x) => x._id === id) || { _id: null, name: null }
      );
    },
    "value.sourceCompetition._id": function (id) {
      const comp = this.competitions.find((x) => x._id === id);
      // get name from comp
      this.value.sourceCompetition.name = (comp && comp.name) || null;
      // get teams from comp
      this.regradeTeams = (comp && comp.teams) || [];
      // reset regrade team
      this.value.regradeTeam._id = null;
      // remove selected comp in dest competitions if any
      this.destCompetitions = this.competitions.filter((x) => x._id !== id) || [];

      if (!id) return;

      this.$store.commit("root/loading", true);
      this.$http
        .get(`/nrl/api/v1/admin/matches/competition/${id}`)
        .then((matches) => {
          this.regradeMatches = matches.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    "value.destCompetition._id": function (id) {
      const comp = this.competitions.find((x) => x._id === id);
      // added name into regrading destCompetition
      this.value.destCompetition.name = (comp && comp.name) || null;
      // get teams from comp
      this.replaceTeams = (comp && comp.teams) || [];

      // remove selected comp in source competitions if any
      this.sourceCompetitions = this.competitions.filter((x) => x._id !== id) || [];

      if (!id) return;

      this.$store.commit("root/loading", true);

      this.$http
        .get(`/nrl/api/v1/admin/matches/competition/${id}`)
        .then((matches) => {
          this.replaceMatches = matches.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
