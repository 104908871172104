<template>
  <div>
    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page
        @cancel="filterVisible = false"
        @submit="sendFilter"
        @clear="sendFilter"
        :rounds="rounds"
        :filterType="filterType"
      />
    </el-dialog>
    <el-row class="mb-2" v-if="clearance === roles.associationAdmin">
      <el-col :span="6">
        <el-button @click="add" type="success" icon="el-icon-plus"> Add Case </el-button>
      </el-col>
    </el-row>
    <el-row class="search-bar" :gutter="20">
      <el-col :span="6">
        <el-input autocomplete="off" v-model="filters[0].value" placeholder="Search..."> </el-input>
      </el-col>
    </el-row>
    <el-tabs type="card">
      <el-tab-pane label="Tribunal">
        <el-row class="search-bar" :gutter="20">
          <el-col :span="6">
            <el-button @click="() => openFilter(1)">Filters</el-button>
          </el-col>
        </el-row>
        <data-tables
          :filters="filters"
          :data="tribunalData"
          :table-props="tableProps"
          :pagination-props="paginationProps"
          :page-size="pageSize"
          @row-click="handleRowClick"
          class="data-table"
        >
          <el-table-column prop="member.profile.firstName" label="First Name" width="150">
          </el-table-column>
          <el-table-column prop="member.profile.lastName" label="Last Name" width="150">
          </el-table-column>
          <el-table-column :formatter="typeFormatter" prop="type" label="Type" width="100">
          </el-table-column>
          <el-table-column
            :formatter="(row) => tribunalDateFormatter(row, 'incident')"
            prop="meta.dateTimeIncident"
            label="Date of Incident"
            width="150"
          >
          </el-table-column>
          <el-table-column prop="round.displayName" label="Round" width="100"> </el-table-column>
          <el-table-column prop="status" label="Status" width="200"> </el-table-column>
          <el-table-column prop="meta.carryOverPts" label="Carry Over Points" width="150">
          </el-table-column>
          <el-table-column prop="meta.competition.name" label="Competition" width="200">
          </el-table-column>
          <el-table-column
            :formatter="createdByFormatter"
            label="Form Submitted By"
            width="200"
          ></el-table-column>
          <el-table-column
            :formatter="(row) => tribunalDateFormatter(row, 'mod')"
            prop="modifiedAt"
            label="Last Modified"
            width="200"
          >
          </el-table-column>
        </data-tables>
      </el-tab-pane>
      <el-tab-pane label="Disciplinary">
        <el-row class="search-bar" :gutter="20">
          <el-col :span="6">
            <el-button @click="() => openFilter(3)">Filters</el-button>
          </el-col>
        </el-row>
        <data-tables
          :filters="filters"
          :data="disciplinaryData"
          :table-props="tableProps"
          :pagination-props="paginationProps"
          :page-size="pageSize"
          @row-click="handleRowClick"
          class="data-table"
        >
          <el-table-column prop="member.profile.firstName" label="First Name" width="150">
          </el-table-column>
          <el-table-column prop="member.profile.lastName" label="Last Name" width="150">
          </el-table-column>
          <el-table-column :formatter="typeFormatter" prop="type" label="Type" width="100">
          </el-table-column>
          <el-table-column
            :formatter="(row) => tribunalDateFormatter(row, 'incident')"
            prop="meta.dateTimeIncident"
            label="Date of Incident"
            width="150"
          >
          </el-table-column>
          <el-table-column prop="round.displayName" label="Round" width="100"> </el-table-column>
          <el-table-column prop="status" label="Status" width="200"> </el-table-column>
          <el-table-column prop="meta.carryOverPts" label="Carry Over Points" width="150">
          </el-table-column>
          <el-table-column prop="meta.competition.name" label="Competition" width="200">
          </el-table-column>
          <el-table-column
            :formatter="createdByFormatter"
            label="Form Submitted By"
            width="200"
          ></el-table-column>
          <el-table-column
            :formatter="(row) => tribunalDateFormatter(row, 'mod')"
            prop="modifiedAt"
            label="Last Modified"
            width="200"
          >
          </el-table-column>
        </data-tables>
      </el-tab-pane>
      <el-tab-pane label="Injury">
        <data-tables
          :filters="filters"
          :data="injuryData"
          :table-props="tableProps"
          :pagination-props="paginationProps"
          :page-size="pageSize"
          @row-click="handleRowClick"
          class="data-table"
        >
          <el-table-column prop="orgtree.club.name" label="Club Name" width="200">
          </el-table-column>
          <el-table-column
            v-if="
              clearance === roles.superAdmin &&
              this.$store.getters['user/activeRole'].national_id === 32
            "
            prop="orgtree.program.name"
            label="Program Name"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="member._id" label="NRL ID Number" width="120"></el-table-column>
          <el-table-column
            prop="member.profile.firstName"
            label="First Name"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="member.profile.lastName"
            label="Surname"
            width="120"
          ></el-table-column>
          <el-table-column prop="status" label="Status" width="120"> </el-table-column>
          <el-table-column
            :formatter="sceneFormatter"
            prop="scene"
            label="Scene"
            width="120"
          ></el-table-column>
          <el-table-column
            :formatter="dateFormatter"
            prop="dateOfInjury"
            label="Date and Time of Incident"
            width="200"
          ></el-table-column>
          <el-table-column
            :formatter="symptomsFormatter"
            label="Concussion symptoms?"
            width="100"
          ></el-table-column>
          <el-table-column
            :formatter="concussionFormatter"
            label="Concussion Confirmed"
            width="100"
          ></el-table-column>
          <el-table-column
            :formatter="createdByFormatter"
            label="Form Submitted By"
            width="200"
          ></el-table-column>
        </data-tables>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import _ from "lodash";
import { roles, compCasesTypes, compCaseScenes, tribunalStatus } from "@/utils/constants";
import FilterPage from "./FilterPage.vue";

export default {
  components: { FilterPage },
  data() {
    return {
      tribunalStatus,
      filterVisible: false,
      statusFilter: undefined,
      compCasesTypes,
      roles,
      originalData: [],
      pageSize: 20,
      filters: [
        {
          value: "",
          filterFn: (row, filter) =>
            Object.keys(row).some((prop) => {
              if (prop === "member") {
                const { firstName, lastName } = row[prop].profile;
                const { _id } = row[prop];
                const filterRegex = new RegExp(filter.value, "i");
                return (
                  _id.toString().match(filterRegex) ||
                  firstName.match(filterRegex) ||
                  lastName.match(filterRegex)
                );
              }
              return false;
            }),
        },
        {
          value: [],
        },
      ],
      filterType: 1,
      filterTables: false,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http
      .get("/nrl/api/v1/admin/competition-cases")
      .then((response) => {
        // eslint-disable-next-line no-multi-assign

        this.originalData = response.data.data
          ? _.filter(response.data.data, { isActive: true })
          : [];
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  methods: {
    concussionFormatter(row) {
      return row.concussionStatusUpdate && row.concussionStatusUpdate.concussionConfirmed
        ? row.concussionStatusUpdate.concussionConfirmed
        : "NA";
    },
    add() {
      this.$router.push({ name: "competition-cases.add" });
    },
    openFilter(v) {
      this.filterVisible = true;
      this.filterType = v;
    },
    handleRowClick(row) {
      this.$router.push({
        name: "competition-cases.update",
        params: {
          id: row._id,
        },
      });
    },
    dateFormatter(row) {
      const timeGiven =
        typeof row.timeOfInjury === "number"
          ? this.moment(row.timeOfInjury).format("HH:mm")
          : row.timeOfInjury;
      const time = timeGiven ? timeGiven + ", " : "";
      return row.dateOfInjury ? time + this.moment(row.dateOfInjury).format("MMMM Do YYYY") : "NA";
    },
    tribunalDateFormatter(row, col) {
      switch (col) {
        case "incident":
          const dateInc =
            row.meta && row.meta.dateTimeIncident
              ? this.moment(row.meta.dateTimeIncident).format("DD/MM/YY")
              : undefined;

          return dateInc ? dateInc : "NA";
        case "mod":
          const dateMod = row.modifiedAt
            ? this.moment(row.modifiedAt).format("HH:mm, MMMM Do YYYY")
            : undefined;
          let name;
          if (row.updatedBy) {
            name =
              row.updatedBy.profile &&
              row.updatedBy.profile.firstName &&
              row.updatedBy.profile.lastName
                ? `${row.updatedBy.profile.firstName + " " + row.updatedBy.profile.lastName}`
                : "";
          }
          const updateStr = dateMod && name ? `${dateMod} by ${name}` : dateMod;
          return dateMod && updateStr ? updateStr : dateMod ? dateMod : "NA";
        default:
          return "NA";
      }
    },
    typeFormatter(row) {
      // get member type name
      return compCasesTypes.find((m) => m.type === row.type).name;
    },
    injuryFormatter(row) {
      const bodypart = row.natureOfInjury.map((injuryCase) => {
        return `<li>${injuryCase.name} : ${injuryCase.type}</li>`;
      });
      return "<ol>" + bodypart.join("") + "</ol>";
    },
    sceneFormatter(row) {
      const capture = compCaseScenes.find((m) => m.type === row.scene);
      if (capture) return capture.name;
      return "NA";
    },
    symptomsFormatter(row) {
      return row.symptons ? "Yes" : "No";
    },
    treatPlayerFormatter(row) {
      return row.treatPlayer ? "Yes" : "No";
    },
    createdByFormatter(row) {
      if (row.submittedBy && row.submittedBy.profile && row.submittedBy.profile.firstName) {
        const name = `${row.submittedBy.profile.firstName} ${
          row.submittedBy.profile.lastName || ""
        }`;
        const words = name.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
        const fullName = words.join(" ");
        return fullName;
      }
      return "NA";
    },
    updatedByFormatter(row) {
      if (row.updatedBy && row.updatedBy.profile && row.updatedBy.profile.firstName) {
        const name = `${row.updatedBy.profile.firstName} ${row.updatedBy.profile.lastName}`;
        const words = name.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
        const fullName = words.join(" ");
        return fullName;
      }
      return "NA";
    },
    sendFilter(event) {
      const { active, status, matchDateRange, rounds, onFieldAction } = event;
      const filtersToAdd = {};

      // filter by active
      if (active && active.length) filtersToAdd.isActive = active[0];

      // filter by status
      if (status && status.length) filtersToAdd.status = status;

      // filter by onFieldAction
      if (onFieldAction && onFieldAction.length) filtersToAdd.onFieldAction = onFieldAction;

      // filter by match date range
      if (matchDateRange && matchDateRange.length) filtersToAdd.matchDateRange = matchDateRange;

      // filter by status
      if (rounds && rounds.length) filtersToAdd.rounds = rounds;

      this.filterTables = filtersToAdd;
      this.filterVisible = false;
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    rounds() {
      const data = this.tribunalData
        .filter((t) => t.round && t.round.value && t.round.displayName)
        .map((d) => ({
          value: d.round.value,
          displayName: d.round.displayName,
        }));
      const res = [...new Map(data.map((item) => [item["value"], item])).values()];
      return res;
    },
    tribunalData() {
      let filters = { type: 1 };
      let filteredData = _.filter(this.originalData, filters);
      const { filterTables } = this;
      if (filterTables) {
        if (filterTables.isActive)
          filteredData = _.filter(filteredData, { isActive: filterTables.isActive });
        if (filterTables.status)
          filteredData = _.filter(filteredData, (c) => _.includes(filterTables.status, c.status));
        if (filterTables.onFieldAction)
          filteredData = _.filter(
            filteredData,
            (c) => c.meta && _.includes(filterTables.onFieldAction, c.meta.onFieldAction)
          );
        if (filterTables.matchDateRange) {
          const [start, end] = filterTables.matchDateRange;
          filteredData = _.filter(
            filteredData,
            (c) =>
              start <= _.get(c, "match.dateTime") &&
              _.get(c, "match.dateTime") <= +this.moment(end).add(1, "day")
          );
        }
        if (filterTables.rounds)
          filteredData = _.filter(filteredData, (c) => {
            const roundNum = c.round && c.round.value ? c.round.value : undefined;
            return _.includes(filterTables.rounds, roundNum);
          });
      }

      return filteredData;
    },
    injuryData() {
      let filters = { type: 2 };
      let filteredData = _.filter(this.originalData, filters);
      return filteredData;
    },
    disciplinaryData() {
      let filters = { type: 3 };
      let filteredData = _.filter(this.originalData, filters);
      const { filterTables } = this;
      if (filterTables) {
        if (filterTables.isActive)
          filteredData = _.filter(filteredData, { isActive: filterTables.isActive });
        if (filterTables.status)
          filteredData = _.filter(filteredData, (c) => _.includes(filterTables.status, c.status));
        if (filterTables.onFieldAction)
          filteredData = _.filter(
            filteredData,
            (c) => c.meta && _.includes(filterTables.onFieldAction, c.meta.onFieldAction)
          );
        if (filterTables.matchDateRange) {
          const [start, end] = filterTables.matchDateRange;
          filteredData = _.filter(
            filteredData,
            (c) =>
              start <= _.get(c, "match.dateTime") &&
              _.get(c, "match.dateTime") <= +this.moment(end).add(1, "day")
          );
        }
        if (filterTables.rounds)
          filteredData = _.filter(filteredData, (c) => {
            const roundNum = c.round && c.round.value ? c.round.value : undefined;
            return _.includes(filterTables.rounds, roundNum);
          });
      }

      return filteredData;
    },
  },
};
</script>
<style lang="scss" scoped>
.search-bar {
  margin-bottom: 1rem;
}

.data-table {
  width: 100%;
}
.el-pagination {
  margin-top: 1rem !important;
}
</style>
