<template>
  <div class="app-layout">
    <el-container>
      <el-col :span="4">
        <side-bar />
      </el-col>
      <el-col :span="20" v-loading="appLoading">
        <nav-bar />
        <div class="content">
          <slot />
        </div>
      </el-col>
    </el-container>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";

export default {
  components: {
    NavBar,
    SideBar,
  },
  name: "AppLayout",
  props: {},
  computed: {
    appLoading() {
      return this.$store.getters["app/appLoading"];
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.app-layout {
  height: 100%;
  .el-container {
    height: 100%;
  }
  .content {
    padding: 32px;
  }
}
::v-deep .el-loading-spinner {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
}
</style>
