<template>
  <div>
    <el-form
      label-position="top"
      :model="compcase"
      :rules="rules"
      ref="compcase"
      class="compcase-form"
    >
      <h4 class="tw-pt-6 tw-mt-6 tw-font-bold">Case Details</h4>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="6">
          <el-form-item prop="type" label="Active">
            <el-select v-model="compcase.isActive" placeholder="">
              <el-option label="Yes" :value="true"></el-option>
              <el-option label="No" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="type" label="Type">
            <el-select v-model="compcase.type" placeholder="">
              <el-option
                v-for="{ type, name } in compCasesTypes"
                :label="name"
                :key="`${type}-${name}`"
                :value="type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="scene" label="Scene">
            <el-select v-model="compcase.scene" placeholder="">
              <el-option
                v-for="({ type, name }, i) in sceneType"
                :label="name"
                :key="`${i}-${name}-${type}`"
                :value="type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col v-if="compcase.type !== 2" :span="6">
          <el-form-item prop="meta.dateTimeIncident" label="Date And Time Of Incident">
            <el-date-picker
              v-model="compcase.meta.dateTimeIncident"
              type="datetime"
              placeholder="Select"
              value-format="timestamp"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col v-if="compcase.type === 2" :span="6">
          <el-form-item prop="dateOfInjury">
            <el-input
              class="input"
              v-model="compcase.dateOfInjuryStr"
              type="date"
              @change="changeDateForBackend"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="compcase.type === 2" :span="6">
          <el-form-item prop="timeOfInjury">
            <el-input
              class="input"
              v-model="compcase.timeOfInjuryStr"
              type="time"
              @change="changeTimeForBackend"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="compcase.type !== 2">
          <el-form-item prop="meta.onFieldAction" label="On-field Action">
            <el-select v-model="compcase.meta.onFieldAction" placeholder="Select" clearable>
              <el-option
                v-for="item in onFieldActions"
                :label="item"
                :key="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="compcase.scene === 1" class="tw-flex tw-flex-wrap">
          <label class="tw-w-full el-form-item__label">Game Time</label>
          <el-form-item class="tw-w-1/2" prop="meta.timeOfIncident.mins">
            <el-input
              class="input"
              type="number"
              max="199"
              min="0"
              @change="(e) => gameTimeChecks(e, 'mins')"
              v-model="compcase.meta.timeOfIncident.mins"
              placeholder="mins"
            />
          </el-form-item>
          <el-form-item class="tw-w-1/2" prop="meta.timeOfIncident.secs">
            <el-input
              class="input"
              type="number"
              max="59"
              min="0"
              @change="(e) => gameTimeChecks(e, 'secs')"
              v-model="compcase.meta.timeOfIncident.secs"
              placeholder="secs"
            />
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="6" v-if="compcase.scene === 1">
          <el-form-item prop="meta.competition._id" label="match information">
            <div class="note">The team members will display on the table below.</div>
            <el-select
              v-model="compcase.meta.competition"
              placeholder="Competition"
              filterable
              clearable
              value-key="_id"
            >
              <el-option
                v-for="{ _id, name } in competitions"
                :label="name"
                :key="_id"
                :value="{ _id, name }"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="compcase.scene === 1">
          <el-form-item prop="round" label="">
            <el-select
              value-key="displayName"
              v-model="round"
              placeholder="Round"
              filterable
              clearable
              :disabled="compcase.scene === 2"
            >
              <el-option
                v-for="item in rounds"
                :label="item.displayName"
                :key="item.displayName"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="compcase.scene === 1">
          <el-form-item prop="meta.matchId" label="">
            <el-select
              v-model="compcase.meta.matchId"
              placeholder="Match"
              filterable
              clearable
              :disabled="compcase.scene === 2"
            >
              <el-option
                v-for="({ _id, homeTeam, awayTeam, meta }, i) in filteredMatches"
                :label="`${(homeTeam && homeTeam.name) || (meta.isBye ? 'BYE' : 'NA')} vs ${
                  (awayTeam && awayTeam.name) || (meta.isBye ? 'BYE' : 'NA')
                }`"
                :key="`${i}-${_id}-${i}`"
                :value="_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="compcase.scene !== 2">
          <el-form-item prop="meta.teamId" label="">
            <el-select v-model="compcase.meta.teamId" placeholder="Team" filterable clearable>
              <el-option
                v-for="({ _id, name }, i) in teams"
                :label="name"
                :key="`${_id}-${i}`"
                :value="_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <!-- VENUE TABLE  -->
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="6">
          <el-form-item
            label="Search venue by name"
            prop="venue.name"
            :rules="{
              required: true,
              message: 'Field is required',
              trigger: 'blur',
            }"
          >
            <el-input
              class="search"
              v-model="injuryVenue.name"
              placeholder="Enter venue name"
              @keyup.native.enter="suggest"
              autocomplete="on"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="success" @click="suggest">Search</el-button>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="24">
          <el-table :data="allVenues" border @current-change="selectVenue" highlight-current-row>
            <el-table-column label="Venue ID" prop="_id" width="150"> </el-table-column>
            <el-table-column label="Venue Name" width="auto">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ get(scope, "row.name") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Address" width="auto">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  get(scope, "row.address.formatted") || null
                }}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="200">
              <template slot-scope="scope">
                <span v-if="scope.row._id === compcase.venue._id">
                  <i class="el-icon-check" style="font-size: 18px; color: green"></i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="24">
          <el-form-item prop="venue.formatted">
            <input
              class="tw-w-full tw-px-2 tw-text-sm tw-text-gray-800 tw-placeholder-gray-800 tw-bg-transparent tw-border-b-2 tw-border-gray-200 tw-shadow-none focus:tw-outline-none tw-h-9"
              autocomplete="off"
              placeholder="Venue address"
              v-model="compcase.venue.formatted"
              readonly
            />
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="6">
          <el-form-item label="Search member by name">
            <el-input
              autocomplete="off"
              placeholder="First Name"
              v-model="search.firstName"
              @keyup.native.enter="searchMember"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="lastName">
            <el-input
              autocomplete="off"
              placeholder="Last Name"
              v-model="search.lastName"
              @keyup.native.enter="searchMember"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="">
          <el-form-item>
            <el-button type="success" @click="searchMember">Search</el-button>
          </el-form-item>
        </el-col>
      </div>
      <!-- MEMBERS TABLE  -->
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col>
          <data-tables :data="members" border @current-change="selectMember" highlight-current-row>
            <el-table-column label="NRL ID" prop="_id" width="150"> </el-table-column>
            <el-table-column label="First Name" prop="profile.firstName" width="250">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  get(scope, "row.profile.firstName") || get(scope, "row.firstName")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Last Name" prop="profile.lastName" width="250">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  get(scope, "row.profile.lastName") || get(scope, "row.lastName")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="DOB" prop="profile.dob" width="200">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ get(scope, "row.profile.dob") }}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="200">
              <template slot-scope="scope">
                <span v-if="scope.row._id === compcase.memberId">
                  <i class="el-icon-check" style="font-size: 18px; color: green"></i>
                </span>
              </template>
            </el-table-column>
          </data-tables>
        </el-col>
      </div>
      <template v-if="compcase.type === 2">
        <h4 class="tw-pt-6 tw-pb-2 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400">
          Injuries
        </h4>
        <div class="tw-w-full">
          <el-tabs
            type="card"
            v-model="tabPosition"
            class="front-btns"
            :class="{ 'error-field': compcase.natureOfInjury.length === 0 }"
          >
            <el-tab-pane label="FRONT">
              <svg
                id="af086e76-5151-45da-a2e8-f4977f3f2710"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="220 0 560 1330"
                style="max-height: 600px"
                class="tw-mx-auto"
              >
                <path
                  d="M411.33,269.88c-29.28,6.37-63.48,5.56-73.34,40-14.48,50.59-5.85,115.47-5.85,115.47s26.37-9.75,37.82.43,23.76,26.94,23.76,26.94,22.9-6.22,50.7-53.35c21.95-37.22,24.42-66.89,18.37-99.51-3.22-17.39-18.37-42.71-18.37-42.71S440.6,263.52,411.33,269.88Z"
                  @click="chooseBodyParts('FRONT', 'Right Shoulder')"
                  :fill="isActive('FRONT', 'Right Shoulder')"
                />
                <path
                  d="M588,269.88c29.27,6.37,63.48,5.56,73.34,40,14.47,50.59,5.85,115.47,5.85,115.47s-26.38-9.75-37.83.43-23.75,26.94-23.75,26.94-22.91-6.22-50.7-53.35c-22-37.22-24.42-66.89-18.38-99.51,3.23-17.39,18.38-42.71,18.38-42.71S558.73,263.52,588,269.88Z"
                  @click="chooseBodyParts('FRONT', 'Left Shoulder')"
                  :fill="isActive('FRONT', 'Left Shoulder')"
                />
                <path
                  d="M329.87,433.43s21.29-11.38,33-3.5,25.06,25.77,25.06,25.77-7.88,11.69-9.07,32.69-2.54,45-11.1,69.71c-11.57,33.38-27.8,45.79-42.83,83.73,0,0-7.32-7.56-16.23-11.21-7.95-3.26-19.8-3.11-19.8-3.11,7.39-46.77,6.68-77.31,13.06-98.33,5.64-18.56,25.35-49.86,26.31-64.41C329.29,448.82,329.87,433.43,329.87,433.43Z"
                  @click="chooseBodyParts('FRONT', 'Right Arm/Elbow')"
                  :fill="isActive('FRONT', 'Right Arm/Elbow')"
                />
                <path
                  d="M669.38,433.43s-21.28-11.38-33-3.5-25,25.77-25,25.77,7.87,11.69,9.06,32.69,2.55,45,11.1,69.71c11.57,33.38,27.8,45.79,42.84,83.73,0,0,7.31-7.56,16.22-11.21,7.95-3.26,19.81-3.11,19.81-3.11-7.4-46.77-6.68-77.31-13.07-98.33C691.68,510.62,672,479.32,671,464.77,670,448.82,669.38,433.43,669.38,433.43Z"
                  @click="chooseBodyParts('FRONT', 'Left Arm/Elbow')"
                  :fill="isActive('FRONT', 'Left Arm/Elbow')"
                />
                <path
                  d="M396.2,587.39s43.56,37.74,34.19,85.43c-10.22,52-51.74,88.78-51.74,88.78s-10.13-56.93-7.48-83.05C376.74,623.67,396.2,587.39,396.2,587.39Z"
                  @click="chooseBodyParts('FRONT', 'Right Hip')"
                  :fill="isActive('FRONT', 'Right Hip')"
                />
                <path
                  d="M602.88,587.39s-43.57,37.74-34.2,85.43c10.22,52,51.74,88.78,51.74,88.78s10.13-56.93,7.48-83.05C622.33,623.67,602.88,587.39,602.88,587.39Z"
                  @click="chooseBodyParts('FRONT', 'Left Hip')"
                  :fill="isActive('FRONT', 'Left Hip')"
                />
                <path
                  d="M378.15,766.87l17.5,101.5,4.6,56.64A47.1,47.1,0,0,1,435,910.16c20.81,0,35.4,17.08,35.4,17.08L491.8,763.16s-22.65-28.74-57.09-28.74C405.83,734.42,378.15,766.87,378.15,766.87Z"
                  @click="chooseBodyParts('FRONT', 'Right Upper Leg')"
                  :fill="isActive('FRONT', 'Right Upper Leg')"
                />
                <path
                  d="M621.18,766.87l-17.5,101.5L599.09,925a47.1,47.1,0,0,0-34.73-14.85c-20.81,0-35.41,17.08-35.41,17.08L507.53,763.16s22.65-28.74,57.09-28.74C593.5,734.42,621.18,766.87,621.18,766.87Z"
                  @click="chooseBodyParts('FRONT', 'Left Upper Leg')"
                  :fill="isActive('FRONT', 'Left Upper Leg')"
                />
                <path
                  d="M402.69,931.05s13.52-14.31,33.44-14.31c16.83,0,32.42,17.18,32.42,17.18l-4.29,30.54s-10.84,12.06-30.55,11.14c-13.62-.64-32.31-18.3-32.31-18.3L403.65,943Z"
                  @click="chooseBodyParts('FRONT', 'Right Knee')"
                  :fill="isActive('FRONT', 'Right Knee')"
                />
                <path
                  d="M596.64,931.05s-13.52-14.31-33.43-14.31c-16.84,0-32.43,17.18-32.43,17.18l4.3,30.54s10.81,11.14,30.54,11.14c12.89,0,32.32-18.3,32.32-18.3L595.69,943Z"
                  @click="chooseBodyParts('FRONT', 'Left Knee')"
                  :fill="isActive('FRONT', 'Left Knee')"
                />
                <path
                  d="M398.42,963.51s14.17,18,33.9,19.15c16.68.94,31.1-7.64,31.1-7.64s8.22,37.17,4.78,68.19-5.66,42.16,0,87.34c0,0-9,3.5-24.43,2-8.86-.86-20-9.49-21.32-13.62-9.35-30.39-28.32-86-28.32-114.39C394.13,975.44,398.42,963.51,398.42,963.51Z"
                  @click="chooseBodyParts('FRONT', 'Right Lower Leg')"
                  :fill="isActive('FRONT', 'Right Lower Leg')"
                />
                <path
                  d="M600.91,963.51s-14.17,18-33.89,19.15c-16.68.94-31.11-7.64-31.11-7.64s-8.22,37.17-4.78,68.19,5.67,42.16,0,87.34c0,0,9,3.5,24.43,2,8.87-.86,20.06-9.49,21.33-13.62,9.35-30.39,28.32-86,28.32-114.39C605.21,975.44,600.91,963.51,600.91,963.51Z"
                  @click="chooseBodyParts('FRONT', 'Left Lower Leg')"
                  :fill="isActive('FRONT', 'Left Lower Leg')"
                />
                <path
                  d="M469.36,1137.39s8.43,49,10.34,54.73,11.73,30.54,10.4,34.84-6.41,7.16-13.57,6.68-20.54,1.27-20.54,1.27l-26.11-5.57s-13.66,1-13.66-9.54,11.46-37.23,11.46-37.23,3.66-22.75,2.2-34.84a135.25,135.25,0,0,0-2.68-14.8s12.25,7.64,22.42,7.85A53.48,53.48,0,0,0,469.36,1137.39Z"
                  @click="chooseBodyParts('FRONT', 'Right Ankle/Foot')"
                  :fill="isActive('FRONT', 'Right Ankle/Foot')"
                />
                <path
                  d="M530,1137.39s-8.44,49-10.34,54.73-11.74,30.54-10.4,34.84,6.4,7.16,13.56,6.68,20.54,1.27,20.54,1.27l26.11-5.57s13.66,1,13.66-9.54-11.45-37.23-11.45-37.23-3.66-22.75-2.21-34.84a135.25,135.25,0,0,1,2.68-14.8s-12.25,7.64-22.42,7.85A53.46,53.46,0,0,1,530,1137.39Z"
                  @click="chooseBodyParts('FRONT', 'Left Ankle/Foot')"
                  :fill="isActive('FRONT', 'Left Ankle/Foot')"
                />
                <path
                  d="M287.16,633.71a39.16,39.16,0,0,1,19.95,3.35A38.24,38.24,0,0,1,322.23,650l-3.34,50.58s3.26,45.28-1.44,45.82c-6.2.72-11.69-32-11.69-32s-.95,46.77-6.92,46.77c-5.25,0-9.78-48.68-9.78-48.68s-6.45,50.11-11.54,48.68c-5.78-1.63-2.3-49.64-2.3-49.64s-17.66,37.23-22.67,34.6c-4.73-2.47,13.6-55.84,13.6-55.84S248,711,241.89,705.47s14.41-37.38,14.41-37.38Z"
                  @click="chooseBodyParts('FRONT', 'Right Wrist/Hand')"
                  :fill="isActive('FRONT', 'Right Wrist/Hand')"
                />
                <path
                  d="M712.17,633.71a39.16,39.16,0,0,0-19.95,3.35A38.25,38.25,0,0,0,677.11,650l3.34,50.58s-3.27,45.28,1.43,45.82c6.2.72,11.69-32,11.69-32s1,46.77,6.92,46.77c5.25,0,9.79-48.68,9.79-48.68s6.44,50.11,11.53,48.68c5.78-1.63,2.31-49.64,2.31-49.64s17.66,37.23,22.67,34.6c4.72-2.47-13.61-55.84-13.61-55.84s18.14,20.76,24.27,15.2S743,668.09,743,668.09Z"
                  @click="chooseBodyParts('FRONT', 'Left Wrist/Hand')"
                  :fill="isActive('FRONT', 'Left Wrist/Hand')"
                />
                <path
                  d="M598.86,583l6.27-123.69c-24.57-10.72-56.78-42.48-71.15-96.81-18-68.21,16-105.31,16-105.31s-35.72,20.36-50.35,20.36-50.35-20.36-50.35-20.36,34.07,37.1,16,105.31C451,416.8,418.77,448.56,394.2,459.28L400.48,583s32.8,18.32,38,67.16c2.58,23.94-6.24,45.31-10.69,54.22s-18.14,30.07-18.14,30.07,13.13-6.68,38.42-3.82c23.05,2.61,44.74,22.27,44.74,22.27V742.06l6.81.24,6.8-.24v10.81s21.7-19.66,44.74-22.27c25.3-2.86,38.42,3.82,38.42,3.82S576,713.26,571.49,704.35s-13.26-30.28-10.69-54.22C566.05,601.29,598.86,583,598.86,583Z"
                  @click="chooseBodyParts('FRONT', 'Chest/Abdomin')"
                  :fill="isActive('FRONT', 'Chest/Abdomin')"
                />
                <path
                  d="M447.09,250.79s4.13-13-2.55-17.81c-5.4-3.87-19.72,0-19.72,0s-15.91,2.54-21.32-3.19l-5.6-17.5L394.2,204V185.89s-14.57-2.55-13.93-5.1,13.93-22.9,13.93-22.9,1.84-7,0-12.73-7-16.4-6.29-22.27c6.29-55.37,53.52-55.12,79.86-56.32,49-2.23,87.82,26.09,81.45,86.54-1.51,14.41-18.49,29.7-16.86,39.78,3.5,21.63,19,57.9,19,57.9s-29.49,20.37-51.66,20.37S447.09,250.79,447.09,250.79Z"
                  @click="chooseBodyParts('FRONT', 'Head/Face/Neck')"
                  :fill="isActive('FRONT', 'Head/Face/Neck')"
                />
              </svg>
            </el-tab-pane>
            <el-tab-pane label="BACK">
              <svg
                id="b893dbae-14b8-486b-ac29-384470045217"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="190 0 540 1330"
                style="max-height: 600px"
                class="tw-mx-auto"
              >
                <path
                  id="head"
                  d="M290.06,665.66A33.31,33.31,0,0,0,273,652.78c-10.61-3.51-16.72.19-16.72.19l-19.4,64s-19,41.23-14.8,43.39c5.55,2.85,22.21-25.81,22.21-25.81s-15.59,44.11-10,46.21c4.91,1.85,26.3-42.11,26.3-42.11s-11.62,49.17-6.35,49.62c6,.52,19.64-45.64,19.64-45.64s3.42,41.06,9,40.37c5.3-.65,6.94-57,6.94-57s9.66,25.82,17.36,22.76-.33-40-.33-40Z"
                  @click="chooseBodyParts('BACK', 'Left Wrist/Hand')"
                  :fill="isActive('BACK', 'Left Wrist/Hand')"
                />
                <path
                  d="M638.05,665.66a33.31,33.31,0,0,1,17.11-12.88c10.6-3.51,16.72.19,16.72.19l19.4,64s19,41.23,14.8,43.39c-5.56,2.85-22.21-25.81-22.21-25.81s15.58,44.11,10,46.21c-4.91,1.85-26.31-42.11-26.31-42.11s11.63,49.17,6.36,49.62c-6,.52-19.65-45.64-19.65-45.64s-3.41,41.06-9,40.37c-5.3-.65-6.94-57-6.94-57s-9.66,25.82-17.36,22.76.32-40,.32-40Z"
                  @click="chooseBodyParts('BACK', 'Right Wrist/Hand')"
                  :fill="isActive('BACK', 'Right Wrist/Hand')"
                />
                <path
                  d="M516.57,247.79s-4.13-10,2.55-14.81c5.4-3.87,19.72,0,19.72,0s15.91,2.54,21.32-3.19l5.6-17.5,3.7-8.27V185.89s14.57-2.55,13.93-5.1-13.93-22.9-13.93-22.9-1.84-7,0-12.73,7-16.4,6.29-22.27c-6.29-55.37-53.52-55.12-79.86-56.32-49-2.23-87.82,26.09-81.45,86.54,1.51,14.41,18.49,29.7,16.86,39.78-3.5,21.63-19,54.9-19,54.9s29.49,20.37,51.66,20.37S516.57,247.79,516.57,247.79Z"
                  @click="chooseBodyParts('BACK', 'Head/Face/Neck')"
                  :fill="isActive('BACK', 'Head/Face/Neck')"
                />
                <path
                  d="M592.08,566.67c11.29,32.58,27.84,55.37,42.51,92.39,0,0,7.14-7.38,15.83-10.95,7.76-3.18,19.33-3,19.33-3-7.22-45.64-5.83-70.26-12.06-90.76a54.54,54.54,0,0,1-29.62,13C611.46,569,592.08,566.67,592.08,566.67Z"
                  @click="chooseBodyParts('BACK', 'Right Lower Arm')"
                  :fill="isActive('BACK', 'Right Lower Arm')"
                />
                <path
                  d="M639.71,503.19c-11.29-32.57-14.84-49-14.37-66.24,0,0,11.91-91.15-6.27-130.73-21.27-46.3-52-29.8-52-29.8,36.2,90.8,4.66,183.93,4.66,183.93s.46,19.09,11,53.42a54.48,54.48,0,0,1,29.62-13C629,499.09,639.71,503.19,639.71,503.19Z"
                  @click="chooseBodyParts('BACK', 'Right Upper Arm/Shoulder')"
                  :fill="isActive('BACK', 'Right Upper Arm/Shoulder')"
                />
                <path
                  d="M335.38,566.67c-11.29,32.58-27.84,55.37-42.51,92.39,0,0-7.14-7.38-15.83-10.95-7.76-3.18-19.32-3-19.32-3,7.21-45.64,5.83-70.26,12-90.76a54.59,54.59,0,0,0,29.62,13C316,569,335.38,566.67,335.38,566.67Z"
                  @click="chooseBodyParts('BACK', 'Left Lower Arm')"
                  :fill="isActive('BACK', 'Left Lower Arm')"
                />
                <path
                  d="M611.28,507.5c22.38-3.53,35.26,6.21,35.26,6.21l9.16,33.41s-8.53,9.22-28.87,13.35c-17.84,3.61-39.1-6.14-39.1-6.14l-5.29-34.53S592.09,510.53,611.28,507.5Z"
                  @click="chooseBodyParts('BACK', 'Right Elbow')"
                  :fill="isActive('BACK', 'Right Elbow')"
                />
                <path
                  d="M287.84,503.19c11.29-32.57,14.84-49,14.37-66.24,0,0-11.91-91.15,6.27-130.73,21.27-46.3,52-29.8,52-29.8-36.2,90.8-4.66,183.93-4.66,183.93s-.46,19.09-11,53.42a54.53,54.53,0,0,0-29.62-13C298.6,499.09,287.84,503.19,287.84,503.19Z"
                  @click="chooseBodyParts('BACK', 'Left Upper Arm/Shoulder')"
                  :fill="isActive('BACK', 'Left Upper Arm/Shoulder')"
                />
                <path
                  d="M316.26,507.5C293.89,504,281,513.71,281,513.71l-9.15,33.41s8.53,9.22,28.87,13.35c17.84,3.61,39.1-6.14,39.1-6.14l5.29-34.53S335.46,510.53,316.26,507.5Z"
                  @click="chooseBodyParts('BACK', 'Left Elbow')"
                  :fill="isActive('BACK', 'Left Elbow')"
                />
                <path
                  d="M589.11,740.35l-20.18,137-11.64,78.94s-4.26-3.43-26.15-2.33c-22.13,1.12-31.23,10.29-31.23,10.29L473.94,841l-4.71-81.07c7.92,4.19,35,16.35,56.86,14.2C554.13,771.4,589.11,740.35,589.11,740.35Z"
                  @click="chooseBodyParts('BACK', 'Right Upper Leg')"
                  :fill="isActive('BACK', 'Right Upper Leg')"
                />
                <path
                  d="M338.81,740.35l20.18,137,11.64,78.94s4.26-3.43,26.15-2.33c22.13,1.12,31.23,10.29,31.23,10.29L454,841l4.71-81.07c-7.92,4.19-35,16.35-56.86,14.2C373.79,771.4,338.81,740.35,338.81,740.35Z"
                  @click="chooseBodyParts('BACK', 'Left Upper Leg')"
                  :fill="isActive('BACK', 'Left Upper Leg')"
                />
                <path
                  d="M501.1,969.76s12.63-8,32-9.52c18-1.43,25.15,2.72,25.15,2.72l10.31,41c-5.26,4.52-21.94,8.21-41.19,8.21-12.57,0-26.73-10.94-26.73-10.94L502,979.4Z"
                  @click="chooseBodyParts('BACK', 'Right Knee')"
                  :fill="isActive('BACK', 'Right Knee')"
                />
                <path
                  d="M427,969.76s-12.63-8-32-9.52c-18-1.43-25.15,2.72-25.15,2.72l-10.31,41c5.26,4.52,21.94,8.21,41.19,8.21,12.57,0,26.73-10.94,26.73-10.94l-1.4-21.88Z"
                  @click="chooseBodyParts('BACK', 'Left Knee')"
                  :fill="isActive('BACK', 'Left Knee')"
                />
                <path
                  d="M569.56,1011.8s-20.32,7.31-39.56,8.39c-16.28.92-32.15-12.32-32.15-12.32s-7.22,49.14-3.87,79.4,5.53,33.14,0,77.22c0,0,7.79,4.41,22.84,3,8.65-.84,20.56-10.26,21.81-14.29,9.12-29.65,24.48-76.08,27.63-103.6C568.56,1029.38,569.56,1011.8,569.56,1011.8Z"
                  @click="chooseBodyParts('BACK', 'Right Lower Leg')"
                  :fill="isActive('BACK', 'Right Lower Leg')"
                />
                <path
                  d="M358.55,1011.8s20.31,7.31,39.56,8.39c16.28.92,32.15-12.32,32.15-12.32s7.22,49.14,3.86,79.4-5.52,33.14,0,77.22c0,0-7.78,4.41-22.83,3-8.65-.84-20.57-10.26-21.81-14.29-9.12-29.65-24.49-76.08-27.63-103.6C359.55,1029.38,358.55,1011.8,358.55,1011.8Z"
                  @click="chooseBodyParts('BACK', 'Left Lower Leg')"
                  :fill="isActive('BACK', 'Left Lower Leg')"
                />
                <path
                  d="M493.32,1169.41s-2.69,26.45-6.25,40.66c-2.55,10.18-10.36,22.54,6.38,23.75,14.57,1.06,47.63,2.73,47.63-2.79,0-10.25-10.66-39.74-9.25-51.53a131.89,131.89,0,0,1,2.62-14.45s-12,7.46-21.88,7.66A52.11,52.11,0,0,1,493.32,1169.41Z"
                  @click="chooseBodyParts('BACK', 'Right Ankle/Foot')"
                  :fill="isActive('BACK', 'Right Ankle/Foot')"
                />
                <path
                  d="M434.79,1169.41s2.69,26.45,6.25,40.66c2.55,10.18,10.36,22.54-6.38,23.75-14.57,1.06-47.64,2.73-47.64-2.79,0-10.25,10.67-39.74,9.26-51.53a134.74,134.74,0,0,0-2.62-14.45s11.95,7.46,21.87,7.66A52.14,52.14,0,0,0,434.79,1169.41Z"
                  @click="chooseBodyParts('BACK', 'Left Ankle/Foot')"
                  :fill="isActive('BACK', 'Left Ankle/Foot')"
                />
                <polygon
                  points="361.67 461.75 364.98 591.66 563.35 591.66 566.65 461.75 361.67 461.75"
                  @click="chooseBodyParts('BACK', 'Lower Back')"
                  :fill="isActive('BACK', 'Lower Back')"
                />
                <path
                  d="M566.65,597.41h-205s-25.43,41-22,133.79c0,0,39.15,36.36,62.71,36.51,32.91.2,61.81-17,61.81-17s28.91,17.15,61.82,17c23.56-.15,62.71-36.51,62.71-36.51C592.08,638.38,566.65,597.41,566.65,597.41Z"
                  @click="chooseBodyParts('BACK', 'Hip')"
                  :fill="isActive('BACK', 'Hip')"
                />
                <path
                  d="M561.28,275.88s-33.39-28.52-44-22.28C501.1,263.07,478.34,273,464.16,273s-36.94-9.91-53.08-19.38c-10.64-6.24-44,22.28-44,22.28-38.49,100-5.38,181.1-5.38,181.1h205S599.77,375.9,561.28,275.88Z"
                  @click="chooseBodyParts('BACK', 'Upper Back')"
                  :fill="isActive('BACK', 'Upper Back')"
                />
              </svg>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="tw-flex tw-items-end tw-w-full tw-gap-3 tw-py-3">
          <el-col :span="8" v-if="headInjuryExists">
            <el-form-item prop="symptons" label="Concussion Symptoms?">
              <el-select
                v-model="compcase.symptons"
                placeholder="Is the player displaying symptoms of a concussion?"
                @change="isConcussed"
              >
                <!-- <el-option value="undefined" :disabled="true"
                  >Is the player displaying symptoms of a concussion?</el-option
                > -->
                <el-option label="Yes" :value="true"></el-option>
                <el-option label="No" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="howPlayerInjured" label="How was the player injured?">
              <el-select
                v-model="compcase.howPlayerInjured"
                placeholder="How was the player injured?"
                @change="otherCheck(compcase.howPlayerInjured, 'howPlayerInjuredOther')"
              >
                <el-option value="undefined" :disabled="true"
                  >How was the player injured?</el-option
                >
                <el-option
                  v-for="item in howPlayerInjuredList"
                  :key="item.option"
                  :value="item.value"
                >
                  {{ item.option }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="compcase.howPlayerInjured === 'Other'">
            <el-form-item prop="howPlayerInjuredOther">
              <el-input
                v-model="compcase.howPlayerInjuredOther"
                placeholder="How was the player injured?"
              />
            </el-form-item>
          </el-col>
        </div>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="24">
            <el-table
              :data="compcase.natureOfInjury"
              class="injury-table"
              border
              highlight-current-row
            >
              <el-table-column label="Body Area" prop="bodyArea" width="auto"></el-table-column>
              <el-table-column label="Body Part" width="auto" prop="bodyPart"></el-table-column>
              <el-table-column label="Injury" width="250">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`natureOfInjury.${scope.$index}.injury`"
                    :rules="{
                      required: true,
                      message: 'Please select an option',
                      trigger: 'blur',
                    }"
                  >
                    <el-select
                      placeholder="Enter the nature of the injury"
                      v-model="scope.row.injury"
                      :required="true"
                    >
                      <el-option value="" :disabled="true">
                        What was the nature of the injury?
                      </el-option>
                      <el-option
                        v-for="op in natureOfInjuryOptions"
                        :key="op.value"
                        :label="op.option"
                        :value="op.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </div>
      </template>
      <template v-else>
        <h4 class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400">Charges</h4>
        <el-form
          label-position="top"
          :model="charge"
          :rules="chargeRules"
          ref="charge"
          class="charge-form"
        >
          <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-py-3">
            <div class="tw-flex-1">
              <el-form-item prop="id" label="Offence">
                <el-select v-model="charge.id" clearable filterable>
                  <el-option
                    v-for="{ type, name } in caseCharges"
                    :label="name"
                    :key="type"
                    :value="type"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="tw-flex-1" :span="8">
              <el-form-item prop="description" label="Action Identified">
                <el-input autocomplete="off" v-model="charge.description"></el-input>
              </el-form-item>
            </div>
            <div class="w-24" :span="4">
              <el-button type="success" @click="addCharge">Add</el-button>
            </div>
          </div>
        </el-form>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col>
            <el-table :data="compcase.charges" border>
              <el-table-column label="Type" prop="name" width="auto"> </el-table-column>
              <el-table-column label="Description" prop="description" width="auto">
              </el-table-column>
              <el-table-column fixed="right" width="200">
                <template slot-scope="scope">
                  <el-button type="danger" @click="removeCharge(scope.$index)">Delete</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </div>
      </template>
      <div>
        <el-collapse v-if="compcase.symptons && headInjuryExists" class="tw-w-full">
          <el-collapse-item title="Concussion Summary" name="1">
            <div
              v-if="compcase && compcase.symptons"
              class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
            >
              <h4 class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400">
                You have identified that signs/symptoms of concussion are present, you will now be
                guided through a series of questions to help you record the signs and symptoms and
                manage the injury.
              </h4>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.clinicalFeatures"
                  label="Are there clinical features of a potentially serious or structural head and/or neck injury, including prolonged loss of consciousness?"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.clinicalFeatures" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.lossOfConsciousness"
                  label="Loss of consciousness (or prolonged loss of movement >1-2 seconds) or not responding appropriately to trainers, referees or other players"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.lossOfConsciousness" placeholder="">
                    <el-option
                      v-for="item in yesNoOptions"
                      :key="item.value"
                      :label="item.option"
                      :value="item.value"
                    ></el-option>
                    <!-- <el-option label="Yes -Observed" :value="Yes - Observed"></el-option>
                    <el-option label="Yes -Reported" :value="Yes - Reported"></el-option>
                    <el-option label="No" :value="No"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.protectiveAction"
                  label="No protective action in fall to the ground (not bracing for impact/floppy or stiff)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.protectiveAction" placeholder="">
                    <el-option
                      v-for="item in yesNoOptions"
                      :key="item.value"
                      :label="item.option"
                      :value="item.value"
                    ></el-option>
                    <!-- <el-option label="Yes -Observed" :value="Yes - Observed"></el-option>
                    <el-option label="Yes -Reported" :value="Yes - Reported"></el-option>
                    <el-option label="No" :value="No"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.impact"
                  label="Impact seizure/convulsion/fit (stiffening or shaking of arms and/or legs on impact)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.impact" placeholder="">
                    <el-option
                      v-for="item in yesNoOptions"
                      :key="item.value"
                      :label="item.option"
                      :value="item.value"
                    ></el-option>
                    <!-- <el-option label="Yes -Observed" :value="Yes - Observed"></el-option>
                    <el-option label="Yes -Reported" :value="Yes - Reported"></el-option>
                    <el-option label="No" :value="No"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.balanceDisturbance"
                  label="Balance disturbance (unable to stand steadily or walk unassisted) or clumsy or slow to get up following a possible head injury (10-15 seconds)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.balanceDisturbance" placeholder="">
                    <el-option
                      v-for="item in yesNoOptions"
                      :key="item.value"
                      :label="item.option"
                      :value="item.value"
                    ></el-option>
                    <!-- <el-option label="Yes -Observed" :value="Yes - Observed"></el-option>
                    <el-option label="Yes -Reported" :value="Yes - Reported"></el-option>
                    <el-option label="No" :value="No"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.dazedOrBlank"
                  label="Dazed or blank/vacant stare or not their normal selves/not reacting appropriately to surroundings."
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.dazedOrBlank" placeholder="">
                    <el-option
                      v-for="item in yesNoOptions"
                      :key="item.value"
                      :label="item.option"
                      :value="item.value"
                    ></el-option>
                    <!-- <el-option label="Yes -Observed" :value="Yes - Observed"></el-option>
                    <el-option label="Yes -Reported" :value="Yes - Reported"></el-option>
                    <el-option label="No" :value="No"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.unusualBehaviour"
                  label="Unusual behaviour for the player"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.unusualBehaviour" placeholder="">
                    <el-option
                      v-for="item in yesNoOptions"
                      :key="item.value"
                      :label="item.option"
                      :value="item.value"
                    ></el-option>
                    <!-- <el-option label="Yes -Observed" :value="Yes - Observed"></el-option>
                    <el-option label="Yes -Reported" :value="Yes - Reported"></el-option>
                    <el-option label="No" :value="No"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.confusion"
                  label="Confusion or disorientation"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.confusion" placeholder="">
                    <el-option
                      v-for="item in yesNoOptions"
                      :key="item.value"
                      :label="item.option"
                      :value="item.value"
                    ></el-option>
                    <!-- <el-option label="Yes -Observed" :value="Yes - Observed"></el-option>
                    <el-option label="Yes -Reported" :value="Yes - Reported"></el-option>
                    <el-option label="No" :value="No"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.memoryImpairment"
                  label="Memory impairment (e.g. fails Maddocks questions symptoms - refer to CRT5)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.memoryImpairment" placeholder="">
                    <el-option
                      v-for="item in yesNoOptions"
                      :key="item.value"
                      :label="item.option"
                      :value="item.value"
                    ></el-option>
                    <!-- <el-option label="Yes -Observed" :value="Yes - Observed"></el-option>
                    <el-option label="Yes -Reported" :value="Yes - Reported"></el-option>
                    <el-option label="No" :value="No"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.playerReports"
                  label="Player reports or displays any other concussion symptoms (refer to CRT5)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.playerReports" placeholder="">
                    <el-option
                      v-for="item in yesNoOptions"
                      :key="item.value"
                      :label="item.option"
                      :value="item.value"
                    ></el-option>
                    <!-- <el-option label="Yes -Observed" :value="Yes - Observed"></el-option>
                    <el-option label="Yes -Reported" :value="Yes - Reported"></el-option>
                    <el-option label="No" :value="No"></el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Symptom Record" name="2">
            <symptom-record :injury="compcase" @setSymptoms="getSymptomRecords" />
            <el-col :span="10">
              <el-form-item
                prop="symptomRecord.playerSelfState"
                label="If you know the player (or ask parents/friends), how different is the player acting
              compared to their usual self?"
                :rules="{
                  required: true,
                  message: 'Symptom Record Field is required',
                  trigger: 'blur',
                }"
              >
                <el-select
                  v-model="playerSelfState"
                  placeholder="If you know the player (or ask parents/friends), how different is the player acting
              compared to their usual self?"
                >
                  <el-option value="undefined" :disabled="true"
                    >If you know the player (or ask parents/friends), how different is the player
                    acting compared to their usual self?</el-option
                  >
                  <el-option
                    v-for="item in playerSelfStateList"
                    :key="item.option"
                    :value="item.value"
                  >
                    {{ item.option }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-collapse-item>
        </el-collapse>
      </div>
      <h4
        v-if="compcase.type !== 2"
        class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400"
      >
        Notes
      </h4>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-w-full tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="12">
          <el-form-item prop="playerRemoveBy" label="How was the player removed from the field?">
            <el-select
              v-model="compcase.playerRemoveBy"
              placeholder="How was the player removed from the field?"
            >
              <el-option value="undefined" :disabled="true"
                >How was the player removed from the field?</el-option
              >
              <el-option
                v-for="method in methodRemovedFromField"
                :label="method"
                :key="method"
                :value="method"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="treatPlayer"
            label="Was an ambulance or doctor called to treat the player?"
          >
            <el-select
              v-model="compcase.treatPlayer"
              placeholder="Was an ambulance or doctor called to treat the player?"
            >
              <el-option value="undefined" :disabled="true"
                >Was an ambulance or doctor called to treat the player?</el-option
              >
              <el-option label="Yes" :value="true"></el-option>
              <el-option label="No" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="12" v-if="compcase.treatPlayer">
          <el-form-item prop="playerReferredTo" label="Who was the player referred to?">
            <el-select
              v-model="compcase.playerReferredTo"
              placeholder="Who was the player referred to?"
              filterable
              @change="otherCheck(compcase.playerReferredTo, 'playerReferredToOther')"
            >
              <el-option value="undefined" :disabled="true"
                >Who was the player referred to?</el-option
              >
              <el-option
                v-for="referral in referredTo"
                :label="referral"
                :key="referral"
                :value="referral"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="compcase.treatPlayer && compcase.playerReferredTo === 'Other'">
          <el-form-item prop="playerReferredToOther">
            <el-input
              v-model="compcase.playerReferredToOther"
              placeholder="Who was the player referred to?"
            />
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="12">
          <el-form-item
            prop="immediateCare"
            label="What was the immediate care applied to the injury?"
          >
            <el-select
              v-model="compcase.immediateCare"
              placeholder="What was the immediate care applied to the injury?"
              filterable=""
              @change="otherCheck(compcase.immediateCare, 'immediateCareOther')"
            >
              <el-option value="undefined" :disabled="true"
                >What was the immediate care applied to the injury?</el-option
              >
              <el-option
                v-for="care in immediateCare"
                :label="care"
                :key="care"
                :value="care"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="compcase.immediateCare === 'Other'">
          <el-form-item prop="immediateCareOther">
            <el-input
              v-model="compcase.immediateCareOther"
              placeholder="What was the immediate care applied to the injury?"
            />
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="12">
          <el-form-item
            prop="firstResponderName"
            label="First Responder Name"
            required
            :rules="{
              required: true,
              message: 'Field is required',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="compcase.firstResponderName"
              :maxlength="50"
              type="textarea"
              placeholder="First Responder Name"
            />
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="12">
          <el-form-item
            prop="firstResponderRole"
            label="First Responder Role"
            required
            :rules="{
              required: true,
              message: 'Field is required',
              trigger: 'blur',
            }"
          >
            <el-select v-model="compcase.firstResponderRole" placeholder="First Responder Role">
              <el-option value="undefined" :disabled="true">First Responder Role</el-option>
              <el-option
                v-for="role in firstResponderRoleList"
                :label="role.option"
                :key="role.value"
                :value="role.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="24">
          <el-form-item v-if="compcase.type !== 2" prop="note" label="Notes">
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 4 }"
              v-model="compcase.note"
            ></el-input>
          </el-form-item>
          <el-form-item v-else prop="otherSignificants" label="Any significant observations">
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 4 }"
              v-model="compcase.otherSignificants"
            ></el-input>
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2 && headInjuryExists"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="8">
          <el-form-item prop="availability" label="Availability">
            <el-select
              v-model="compcase.availability"
              placeholder="Is this player available to participate in upcoming games without medical
                clearance?"
              :disabled="compcase.symptons"
            >
              <el-option value="undefined" :disabled="true"
                >Is this player available to participate in upcoming games without medical
                clearance?</el-option
              >
              <el-option
                v-for="availability in injuryAvailability"
                :label="availability.label"
                :key="availability.label"
                :value="availability.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2 && headInjuryExists"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="24">
          <el-form-item
            prop="submitByFirstRes"
            label="Were all the required fields completed in the Head injury recognition and referral form submitted by the First responder?"
          >
            <el-select
              v-model="compcase.submitByFirstRes"
              placeholder=""
              @change="alertUser($event)"
            >
              <el-option value="undefined" :disabled="true"
                >Were all the required fields completed in the Head injury recognition and referral
                form submitted by the First responder?</el-option
              >
              <el-option
                v-for="item in yesOrNo"
                :label="item.option"
                :key="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <h4
        v-if="compcase.type === 2"
        class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400"
      >
        Admin Notes
      </h4>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="24">
          <el-form-item prop="adminNote">
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 4 }"
              v-model="compcase.adminNote"
            ></el-input>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col>
          <action-buttons cancel submit @submit="addCompcase()" @cancel="cancel" />
        </el-col>
      </div>
    </el-form>
  </div>
</template>
<script>
import _, { get } from "lodash";
import ActionButtons from "../../components/ActionButtons.vue";
import SymptomRecord from "./SymptomRecord.vue";
import {
  errormsg,
  caseCharges,
  compCasesTypes,
  tribunalStatus,
  compCaseScenes,
  onFieldActions,
  natureOfInjuryOptions,
  methodRemovedFromField,
  referredTo,
  immediateCare,
  injuryAvailability,
  howPlayerInjuredList,
  playerSelfStateList,
  yesNoOptions,
  yesOrNo,
  firstResponderRoleList,
} from "../../utils/constants";

export default {
  components: {
    ActionButtons,
    SymptomRecord,
  },
  data() {
    const validateGameTime = (rule, value, callback) => {
      const reg = new RegExp("^[0-9]+$");
      const num = parseInt(value);
      const max = rule.field === "meta.timeOfIncident.mins" ? 199 : 59;
      const min = 0;
      if (!reg.test(value) || num > max || num < min)
        callback(new Error("Please enter a valid time"));
      callback();
    };
    return {
      isSearchByName: false,
      get,
      yesNoOptions,
      yesOrNo,
      caseCharges,
      tribunalStatus,
      compCasesTypes,
      howPlayerInjuredList,
      playerSelfStateList,
      firstResponderRoleList,
      sceneType: compCaseScenes,
      chargeGradings: [1, 2, 3, 4, 5],
      onFieldActions,
      competitions: [],
      competition: undefined,
      round: undefined,
      matches: [],
      members: [],
      tabPosition: "0",
      search: {
        firstName: "",
        lastName: "",
      },
      charge: {
        id: "",
        name: "",
        description: "",
        recommendedGrading: "",
      },
      chargeRules: {
        id: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        description: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
      },
      injuryVenue: {
        name: undefined,
      },
      natureOfInjuryOptions,
      methodRemovedFromField,
      referredTo,
      immediateCare,
      injuryAvailability,
      playerSelfState: undefined,
      allVenues: [],
      compcase: {
        type: 1, // const casesType = [ 1 = 'Tribunal', 2 = 'Injury' ]
        scene: 1, // const sceneType = [ 1 = 'Match', 2 = 'Other', 3 = Training ]
        memberId: "",
        natureOfInjury: [],
        charges: [], // [ PositiveInt ] ==> const charges = [ { id, name, description, recommendedGrading, grading, outcome } ]
        note: "",
        status: "Pending",
        symptons: undefined,
        treatPlayer: undefined,
        playerReferredTo: undefined,
        immediateCare: undefined,
        immediateCareOther: undefined,
        concussion: {
          balanceDisturbance: undefined,
          clinicalFeatures: undefined,
          dazedOrBlank: undefined,
          impact: undefined,
          lossOfConsciousness: undefined,
          confusion: undefined,
          memoryImpairment: undefined,
          playerReports: undefined,
          protectiveAction: undefined,
          unusualBehaviour: undefined,
        },
        symptomRecord: {
          playerSelfState: undefined,
          headache: { step: 0, stepName: "None" },
          pressureInHead: { step: 0, stepName: "None" },
          neckPain: { step: 0, stepName: "None" },
          nauseaOrVomit: { step: 0, stepName: "None" },
          dizzy: { step: 0, stepName: "None" },
          blurVision: { step: 0, stepName: "None" },
          balanceProblem: { step: 0, stepName: "None" },
          lightSensitivity: { step: 0, stepName: "None" },
          noiseSensitivity: { step: 0, stepName: "None" },
          slowedDown: { step: 0, stepName: "None" },
          inAFog: { step: 0, stepName: "None" },
          notFeelRight: { step: 0, stepName: "None" },
          difficultyInConcentrating: { step: 0, stepName: "None" },
          difficultyRemembering: { step: 0, stepName: "None" },
          fatigued: { step: 0, stepName: "None" },
          confused: { step: 0, stepName: "None" },
          drowsy: { step: 0, stepName: "None" },
          emotional: { step: 0, stepName: "None" },
          irritable: { step: 0, stepName: "None" },
          sad: { step: 0, stepName: "None" },
          nervousOrAnxious: { step: 0, stepName: "None" },
        },
        howPlayerInjured: undefined,
        howPlayerInjuredOther: undefined,
        playerRemoveBy: undefined,
        otherSignificants: undefined,
        availability: undefined,
        submitByFirstRes: undefined,
        adminNote: "",
        dateOfInjury: undefined,
        dateOfInjuryStr: "",
        timeOfInjury: undefined,
        timeOfInjuryStr: "",
        meta: {
          competition: {
            _id: undefined,
            name: undefined,
          },
          matchId: undefined,
          teamId: undefined,
          appeal: {}, // { lodged: Boolean, dateTime: Timestamp, outcome: String },
          penalty: {}, // type: String, units: PositiveInt, startDate: Timestamp, expiryDate: Timestamp
          suspendedPenalty: {}, // { type: String, units: PositiveInt, expiryDate: Timestamp }
          onFieldAction: undefined,
          dateTimeIncident: undefined,
          timeOfIncident: {
            mins: undefined,
            secs: undefined,
          },
        },
        isActive: true,
        venue: {
          name: undefined,
          _id: undefined,
          formatted: undefined,
        },
        round: undefined,
      },
      rules: {
        type: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        isActive: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        scene: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.dateTimeIncident": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.timeOfIncident.mins": {
          required: false,
          validator: validateGameTime,
          trigger: "blur",
        },
        "meta.timeOfIncident.secs": {
          required: false,
          validator: validateGameTime,
          trigger: "blur",
        },
        memberId: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.competition._id": {
          required: false,
          message: errormsg.select_option,
          trigger: "blur",
        },
        "meta.matchId": {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.teamId": {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "venue._id": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        natureOfInjury: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        symptons: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        playerRemoveBy: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        playerReferredTo: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        treatPlayer: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        immediateCare: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        availability: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        returnDate: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        adminNote: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        dateOfInjury: {
          required: true,
          message: errormsg.input_option,
          trigger: blur,
        },
        timeOfInjury: {
          required: true,
          message: errormsg.input_option,
          trigger: blur,
        },
        howPlayerInjured: {
          required: true,
          message: errormsg.select_option,
          trigger: blur,
        },
        howPlayerInjuredOther: {
          required: true,
          message: errormsg.input_option,
          trigger: blur,
        },
        immediateCare: {
          required: true,
          message: errormsg.select_option,
          trigger: blur,
        },
        immediateCareOther: {
          required: true,
          message: errormsg.input_option,
          trigger: blur,
        },
        playerReferredTo: {
          required: true,
          message: errormsg.select_option,
          trigger: blur,
        },
        playerReferredToOther: {
          required: true,
          message: errormsg.input_option,
          trigger: blur,
        },
      },
    };
  },
  computed: {
    rounds() {
      if (this.matches && this.matches.length) {
        return _.chain(this.matches)
          .map("round")
          .orderBy(["type", "number"], ["desc", "asc"])
          .sortedUniqBy("displayName")
          .value();
      }
      return [];
    },
    filteredMatches() {
      if (this.matches && this.matches.length && this.round) {
        return this.matches.filter(
          (x) => x.round.type === this.round.type && x.round.number === this.round.number
        );
      }
      return this.matches;
    },
    teams() {
      let rs = [];
      // match scene
      if (this.compcase.scene === 1 && this.compcase.meta.matchId) {
        const match = _.find(this.matches, { _id: _.get(this.compcase, "meta.matchId") });
        if (match.homeTeam) rs.push(match.homeTeam);
        if (match.awayTeam) rs.push(match.awayTeam);
      }
      // other scene
      const competition = _.find(this.competitions, {
        _id: _.get(this.compcase, "meta.competition._id"),
      });
      if (this.compcase.scene === 2 && competition && competition.teams.length) {
        rs = competition.teams;
      }
      if (this.compcase.scene === 3 && this.competitions && this.competitions.length) {
        _.forEach(this.competitions, (comp) => {
          rs = _.concat(rs, comp.teams);
        });
      }
      return rs;
    },
    headInjuryExists() {
      let exists = _.find(this.compcase.natureOfInjury, { bodyPart: "Head/Face/Neck" });
      return exists ? true : false;
    },
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then((res) => {
        this.competitions = res.data.data;
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });

    this.compcase.concussion.lossOfConsciousness = this.convertBoolToString(
      this.compcase.concussion.lossOfConsciousness
    );
    this.compcase.concussion.protectiveAction = this.convertBoolToString(
      this.compcase.concussion.protectiveAction
    );
    this.compcase.concussion.impact = this.convertBoolToString(this.compcase.concussion.impact);
    this.compcase.concussion.balanceDisturbance = this.convertBoolToString(
      this.compcase.concussion.balanceDisturbance
    );
    this.compcase.concussion.dazedOrBlank = this.convertBoolToString(
      this.compcase.concussion.dazedOrBlank
    );
    this.compcase.concussion.unusualBehaviour = this.convertBoolToString(
      this.compcase.concussion.unusualBehaviour
    );
    this.compcase.concussion.confusion = this.convertBoolToString(
      this.compcase.concussion.confusion
    );
    this.compcase.concussion.memoryImpairment = this.convertBoolToString(
      this.compcase.concussion.memoryImpairment
    );
    this.compcase.concussion.playerReports = this.convertBoolToString(
      this.compcase.concussion.playerReports
    );
  },
  methods: {
    alertUser(e) {
      if (!e) {
        // In case of No , concussion Summary question if not answered set to No by default
        if (this.compcase.concussion.balanceDisturbance === undefined)
          this.compcase.concussion.balanceDisturbance = "No";
        if (this.compcase.concussion.clinicalFeatures === undefined)
          this.compcase.concussion.clinicalFeatures = false;
        if (this.compcase.concussion.dazedOrBlank === undefined)
          this.compcase.concussion.dazedOrBlank = "No";
        if (this.compcase.concussion.impact === undefined) this.compcase.concussion.impact = "No";
        if (this.compcase.concussion.lossOfConsciousness === undefined)
          this.compcase.concussion.lossOfConsciousness = "No";
        if (this.compcase.concussion.confusion === undefined)
          this.compcase.concussion.confusion = "No";
        if (this.compcase.concussion.memoryImpairment === undefined)
          this.compcase.concussion.memoryImpairment = "No";
        if (this.compcase.concussion.playerReports === undefined)
          this.compcase.concussion.playerReports = "No";
        if (this.compcase.concussion.protectiveAction === undefined)
          this.compcase.concussion.protectiveAction = "No";
        if (this.compcase.concussion.unusualBehaviour === undefined)
          this.compcase.concussion.unusualBehaviour = "No";

        // In case of No, the playerSelfState is set to "No different" by default
        if (this.compcase.symptomRecord.playerSelfState === undefined)
          this.compcase.symptomRecord.playerSelfState = "No different";

        // Make the Member unavailable
        if (
          this.compcase.symptons === true &&
          Array.isArray(this.compcase?.natureOfInjury) &&
          this.compcase?.natureOfInjury.find((i) => i?.injury === "Suspected Concussion")
        )
          this.compcase.availability = false;

        this.$alert(
          "Please follow up with First Responder to advise all fields in the form must be completed in full."
        );
      }
    },
    convertBoolToString(fieldName) {
      if (fieldName === undefined) return;
      let newFieldValue = fieldName.toString();
      // By default value when injury already existed with Bool value true
      if (newFieldValue === "true") return (newFieldValue = "Yes -Observed");
      else if (newFieldValue === "false") return (newFieldValue = "No");
      return newFieldValue;
    },
    changeTimeForBackend(e) {
      let time;
      if (e) {
        time = e;
      }
      const tempDate =
        this.compcase.dateOfInjuryStr ||
        this.moment(this.compcase.dateOfInjury).format("MM-DD-YYYY") ||
        this.moment().now().format("MM-DD-YYYY");
      const dt = new Date(`${tempDate} ${time}`);
      this.compcase.timeOfInjury = Date.parse(dt);
    },
    changeDateForBackend(e) {
      if (e) {
        const date = this.moment(e).valueOf();
        this.compcase.dateOfInjury = date;
        // update time timestamp to reflect date
        if (this.compcase.timeOfInjury) {
          const tempDate = e;
          const time =
            this.compcase.timeOfInjuryStr ||
            this.moment(this.compcase.timeOfInjury).format("HH:mm");
          const dt = new Date(`${tempDate} ${time}`);
          this.compcase.timeOfInjury = Date.parse(dt);
        }
      }
    },
    gameTimeChecks(e, t) {
      const num = parseInt(e);
      const max = t === "mins" ? 200 : 60;
      const min = 0;
      if (num < max && num >= min) return true;
      this.compcase.meta.timeOfIncident[t] = undefined;
    },
    otherCheck(field, type) {
      if (field !== "Other") this.compcase[type] = "";
    },
    getSymptomRecords(step, stepName, symptom) {
      const { symptomRecord } = this.compcase;
      symptomRecord[symptom].step = step;
      symptomRecord[symptom].stepName = stepName;
    },
    isConcussed() {
      if (this.compcase.symptons) this.compcase.availability = false;
    },
    cancel() {
      this.$router.push({
        name: "competition-cases.list",
      });
    },
    nameFormatter(item) {
      if (item.profile)
        return `${_.get(item, "profile.firstName")} ${_.get(item, "profile.lastName")}`;
      return `${_.get(item, "firstName")} ${_.get(item, "lastName")}`;
    },
    async getMatchVenue() {
      try {
        if (this.compcase.meta.matchId) {
          this.$store.commit("root/loading", true);
          const match = await this.$http.get(
            `/nrl/api/v1/admin/matches/${this.compcase.meta.matchId}`
          );
          if (!match.data || !match.data.data) throw new Error();
          const { venue } = match.data.data;
          this.injuryVenue.name = venue.name;
          this.suggest();
        }
        this.$store.commit("root/loading", false);
      } catch (error) {
        this.$store.commit("root/loading", false);
        this.$customError("Unable to retrieve match venue. Please search via venue table");
      }
    },
    getMembers() {
      if (this.compcase.scene === 1 && this.compcase.meta.teamId && this.compcase.meta.matchId) {
        this.$store.commit("root/loading", true);
        this.$http
          .get(
            `/nrl/api/v1/admin/teamlists/teams/${this.compcase.meta.teamId}/matches/${this.compcase.meta.matchId}`
          )
          .then((res) => {
            const teamlist = res.data.data[0];
            this.members = [];
            if (teamlist && teamlist.players) {
              this.members = this.members.concat(teamlist.players);
            }
            if (teamlist && teamlist.nonplayers) {
              this.members = this.members.concat(teamlist.nonplayers);
            }
            this.$store.commit("root/loading", false);
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      } else if (
        (this.compcase.scene === 2 || this.compcase.scene === 3) &&
        this.compcase.meta.teamId
      ) {
        this.$store.commit("root/loading", true);
        Promise.all([
          this.$http.get(`/nrl/api/v1/admin/squad/players/${this.compcase.meta.teamId}`),
          this.$http.get(`/nrl/api/v1/admin/squad/nonplayers/${this.compcase.meta.teamId}`),
        ])
          .then(([playersRes, nonplayersRes]) => {
            const playersBody = playersRes.data.data;
            const nonplayersBody = nonplayersRes.data.data;
            this.members = [];
            if (playersBody && playersBody.players) {
              this.members = this.members.concat(playersBody.players);
            }
            if (nonplayersBody && nonplayersBody.nonplayers) {
              this.members = this.members.concat(nonplayersBody.nonplayers);
            }
            this.$store.commit("root/loading", false);
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
    },
    addCharge() {
      this.$refs.charge.validate((val) => {
        if (val) {
          const charge = _.clone(this.charge);
          this.compcase.charges.push(charge);
          this.$refs.charge.resetFields();
          this.$refs.charge.clearValidate();
        }
      });
    },
    removeCharge(index) {
      this.compcase.charges.splice(index, 1);
    },
    addCompcase() {
      this.$refs.compcase.validate((val, obj) => {
        if (val) {
          if (!this.compcase.charges.length && this.compcase.type !== 2) {
            this.$customError("Charges are required for this type of competition cases.");
            return;
          }

          if (!this.compcase.natureOfInjury.length && this.compcase.type === 2) {
            this.$customError("Injuries are required for this type of competition cases.");
            return;
          }

          if (!this.compcase.memberId) {
            this.$customError("Member is required for this competition cases.");
            return;
          }

          if (!this.compcase.venue._id && this.compcase.type === 2) {
            this.$customError("Venue is required for this competition cases.");
            return;
          }

          if (!this.compcase.firstResponderName === "") {
            this.$customError("First Responder name is required");
            return;
          }

          if (!this.compcase.firstResponderRole === undefined) {
            this.$customError("First Responder role is required");
            return;
          }

          // In case we select "No" for question - "it is submitted by First responder" :
          if (
            this.compcase.submitByFirstRes === false &&
            this.compcase.symptons === true &&
            Array.isArray(this.compcase?.natureOfInjury) &&
            this.compcase?.natureOfInjury.find((i) => i?.injury === "Suspected Concussion")
          ) {
            this.compcase.availability = false;
          }
          this.$store.commit("root/loading", true);
          const url =
            this.compcase.type !== 2
              ? "/nrl/api/v1/admin/competition-cases"
              : "/nrl/api/v1/admin/injury-cases";

          if (this.compcase.type !== 2 && this.compcase.natureOfInjury) {
            delete this.compcase.natureOfInjury;
            delete this.compcase.symptomRecord;
            delete this.compcase.immediateCare;
            delete this.compcase.playerRemoveBy;
            delete this.compcase.playerReferredTo;
            delete this.compcase.otherSignificants;
          }
          if (this.compcase.type === 2) {
            this.compcase.status = "Completed";
            if (this.compcase.charges) delete this.compcase.charges;
          }
          this.$http
            .post(url, this.compcase)
            .then((res) => {
              this.$customSuccess();
              this.$store.commit("root/loading", false);
              if (res && res.data.data) {
                this.$router.push({
                  name: "competition-cases.update",
                  params: {
                    id: res.data.data,
                  },
                });
              } else {
                this.$router.push({
                  name: "competition-cases.list",
                });
              }
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          const reqFields = Object.keys(obj);
          const natureOfInjuryFieldCheck = reqFields.filter((f) =>
            f.toLowerCase().includes("natureofinjury")
          );
          const concussionSummaryFieldCheck = reqFields.filter((f) =>
            f.toLowerCase().includes("concussion")
          );
          const symptomRecordFieldCheck = reqFields.filter((f) =>
            f.toLowerCase().includes("symptomrecord")
          );
          if (natureOfInjuryFieldCheck.length && this.compcase.type === 2) {
            this.$customError(
              "All nature of injury questions are required for this type of competition cases."
            );
            return;
          }
          if (concussionSummaryFieldCheck.length && this.compcase.type === 2) {
            this.$customError(
              "All concussion summary questions are required for this type of competition cases."
            );
            return;
          }
          if (symptomRecordFieldCheck.length && this.compcase.type === 2) {
            this.$customError(
              "All symptom record questions are required for this type of competition cases."
            );
            return;
          }
          this.$customError("Check all required fields *");
          return;
        }
      });
    },
    selectMember(currentRow) {
      this.compcase.memberId = _.get(currentRow, "_id") || undefined;
    },
    selectVenue(currentRow) {
      if (currentRow) {
        this.compcase.venue.formatted = currentRow.address.formatted || undefined;
        this.compcase.venue._id = currentRow._id || undefined;
        this.compcase.venue.name = currentRow.name || undefined;
      }
    },
    searchMember() {
      this.isSearchByName = true;
      this.members = [];
      this.$store.commit("root/loading", true);
      this.$http
        .post("/nrl/api/v1/admin/competition-cases/members/search", this.search)
        .then((res) => {
          this.members = res.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    },
    resetFields() {
      this.round = undefined;
      this.compcase.meta.matchId = undefined;
      this.compcase.meta.teamId = undefined;
      this.compcase.memberId = undefined;
      this.members = [];
    },
    suggest() {
      const query = this.injuryVenue.name;
      this.compcase.venue = {
        _id: undefined,
        formatted: undefined,
        name: undefined,
      };
      if (query && query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then((response) => {
              this.allVenues = response.data.data;
              var data = this.allVenues.map((x) => ({ ...x, value: x.name }));
              // cb(this.allVenues.map((x) => ({ ...x, value: x.name })));
            })
            .catch(() => {});
        }, 200);
      } else {
        this.allVenues = [];
        // cb([]);
      }
    },
    chooseBodyParts(bodyType, bodyPart) {
      let exists = _.find(this.compcase.natureOfInjury, {
        bodyArea: bodyType,
        bodyPart: bodyPart,
      });
      if (!exists) {
        this.compcase.natureOfInjury.push({
          bodyArea: bodyType,
          bodyPart: bodyPart,
          injury: undefined,
        });
      } else {
        this.compcase.natureOfInjury = this.compcase.natureOfInjury.filter((obj) => {
          return obj.bodyArea == bodyType && obj.bodyPart == bodyPart ? false : true;
        });
      }
    },
    isActive(bodyType, bodyPart) {
      return _.find(this.compcase.natureOfInjury, {
        bodyArea: bodyType,
        bodyPart: bodyPart,
      })
        ? "#098255"
        : "#231f20";
    },
  },
  watch: {
    playerSelfState: function (state) {
      this.compcase.symptomRecord.playerSelfState = state;
    },
    "compcase.scene": function () {
      this.resetFields();
    },
    "charge.id": function (id) {
      if (id) this.charge.name = _.find(this.caseCharges, { type: id }).name;
    },
    "compcase.meta.competition._id": function (id) {
      this.resetFields();
      this.matches = [];
      // looking for matches if scene = 1 (Match)
      if (id && this.compcase.scene === 1) {
        this.$store.commit("root/loading", true);
        this.$http
          .get(`/nrl/api/v1/admin/matches/competition/${id}`)
          .then((res) => {
            this.matches = res.data.data;
            this.$store.commit("root/loading", false);
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
    },
    round() {
      if (this.round) {
        this.compcase.round = this.round;
        this.compcase.round.value = this.round.number;
      } else {
        this.compcase.round = undefined;
      }
      this.compcase.meta.matchId = undefined;
    },
    "compcase.meta.matchId": function () {
      this.compcase.meta.teamId = undefined;
      this.getMatchVenue();
    },
    "compcase.meta.teamId": function () {
      this.compcase.memberId = undefined;
      this.members = [];
      this.isSearchByName = false;
      this.getMembers();
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-control {
  margin-top: 50px;
}
.el-input,
.el-select {
  width: 100%;
}
.note {
  line-height: 20px;
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: -20px;
  color: $secondary;
  width: 150%;
}

.injury-table .el-form-item {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.error-field {
  color: #f56c6c !important;
}
.error-field .el-tabs__nav-wrap.is-top {
  border: 1px solid #f56c6c !important;
  border-radius: 4px;
}
</style>
