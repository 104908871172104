/* eslint-disable no-return-assign */
import _ from "lodash";

const initState = () => ({
  search: "",
  criteria: {
    firstName: "",
    lastName: "",
    ageLevel: null
  },
  data: [],
  pagination: {
    total: 0,
    pageSize: 0,
    page: 0
  },
  filters: {
    status: []
  }
});

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateSearch: (state, payload) => (state.search = payload.search),
    updateCriteria: (state, payload) => _.assign(state.criteria, payload.criteria),
    updateData: (state, payload) => (state.data = payload.data),
    updatePagination: (state, payload) => _.assign(state.pagination, payload.data),
    updateFilters: (state, payload) => {
      const { status } = payload;
      _.assign(state.filters, { status });
    }
  }
};
