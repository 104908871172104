<template>
  <div>
    <div v-if="!selectedCase">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-tabs type="card">
            <el-tab-pane label="Tribunal">
              <el-table :data="tribunalData" :border="true" @current-change="clickRow">
                <el-table-column fixed label="Type" :formatter="typeFormatter" prop="type">
                </el-table-column>
                <el-table-column
                  label="Club"
                  :formatter="clubFormatter"
                  prop="orgtree.club.name"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  label="Association"
                  :formatter="associationFormatter"
                  prop="orgtree.association.name"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  label="Penalty Expiry Date"
                  :formatter="expiryFormatter"
                  prop="meta.penalty.expiryDate"
                  width="150"
                >
                </el-table-column>
                <el-table-column label="Charge Grading" width="300">
                  <template slot-scope="scope">
                    <ol>
                      <li v-for="(charge, i) in scope.row.charges" :key="i">
                        {{ scope.row.charges > 1 ? `Charge ${scope.$index + 1}: ` : ""
                        }}{{ charge.grading || "" }}
                      </li>
                    </ol>
                  </template>
                </el-table-column>
                <el-table-column label="Charge Name" width="300">
                  <template slot-scope="scope">
                    <ol>
                      <li v-for="(charge, i) in scope.row.charges" :key="i">
                        {{ scope.row.charges > 1 ? `Charge ${scope.$index + 1}: ` : ""
                        }}{{ charge.name || "" }}
                      </li>
                    </ol>
                  </template>
                </el-table-column>
                <el-table-column label="Charge Description" width="300">
                  <template slot-scope="scope">
                    <ol>
                      <li v-for="(charge, i) in scope.row.charges" :key="i">
                        {{ scope.row.charges > 1 ? `Charge ${scope.$index + 1}: ` : ""
                        }}{{ charge.description || "" }}
                      </li>
                    </ol>
                  </template>
                </el-table-column>
                <el-table-column label="Outcome" width="150">
                  <template slot-scope="scope">
                    <ol>
                      <li v-for="(charge, i) in scope.row.charges" :key="i">
                        {{ scope.row.charges > 1 ? `Charge ${scope.$index + 1}: ` : ""
                        }}{{ charge.outcome || "" }}
                      </li>
                    </ol>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  :formatter="chargeGradingFormatter"
                  prop="charges[0].grading"
                  label="Charge Grading"
                  width="300"
                >
                </el-table-column>
                <el-table-column
                  :formatter="chargeNameFormatter"
                  prop="charges[0].name"
                  label="Charge Name"
                  width="300"
                >
                </el-table-column>
                <el-table-column
                  :formatter="chargeDescriptionFormatter"
                  prop="charges[0].description"
                  label="Charge Description"
                  width="300"
                > 
                </el-table-column> -->
                <el-table-column
                  :formatter="chargeLegacyGradingFormatter"
                  prop="meta.legacyChargeGrading"
                  label="Legacy Charge Grading"
                  width="300"
                >
                </el-table-column>
                <el-table-column label="Scene" :formatter="sceneFormatter" prop="scene">
                </el-table-column>
                <!-- <el-table-column prop="charges[0].outcome" label="Outcome" width="150">
                </el-table-column>
                <el-table-column prop="charges[1].name" label="Charge 1" width="300">
                </el-table-column>
                <el-table-column prop="charges[1].outcome" label="Outcome" width="150">
                </el-table-column>
                <el-table-column prop="charges[2].name" label="Charge 2" width="300">
                </el-table-column>
                <el-table-column prop="charges[2].outcome" label="Outcome" width="150">
                </el-table-column>
                <el-table-column prop="charges[3].name" label="Charge 3" width="300">
                </el-table-column>
                <el-table-column prop="charges[3].outcome" label="Outcome" width="150">
                </el-table-column>  -->
                <el-table-column
                  fixed="right"
                  prop="hearing.required"
                  :formatter="hearingFormatter"
                  label="Hearing"
                  width="80"
                >
                </el-table-column>
                <el-table-column
                  fixed="right"
                  prop="meta.penalty.type"
                  label="Penalty Type"
                  width="110"
                >
                </el-table-column>
                <el-table-column
                  fixed="right"
                  prop="meta.penalty.units"
                  label="Penalty Units"
                  width="110"
                >
                </el-table-column>
                <el-table-column fixed="right" prop="status" label="Status" width="80">
                </el-table-column>
                <el-table-column
                  :formatter="formatTimestamp"
                  fixed="right"
                  prop="createdAt"
                  label="Created At"
                  width="200"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="Injury">
              <el-table :data="injuryData" :border="true" @current-change="clickRow">
                <el-table-column prop="orgtree.club.name" label="Club Name" width="200">
                </el-table-column>
                <el-table-column
                  prop="orgtree.association.name"
                  label="Association Name"
                  width="200"
                >
                </el-table-column>
                <el-table-column prop="orgtree.program.name" label="Program Name" width="200">
                </el-table-column>
                <el-table-column
                  prop="memberId"
                  label="NRL ID Number"
                  width="120"
                ></el-table-column>
                <el-table-column prop="currentMember.firstName" label="First Name" width="120">
                </el-table-column>
                <el-table-column
                  prop="currentMember.lastName"
                  label="Surname"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="currentMember.dob"
                  label="Date of Birth"
                  width="120"
                ></el-table-column>
                <el-table-column prop="status" label="Status" width="120"> </el-table-column>
                <el-table-column
                  :formatter="sceneFormatter"
                  prop="scene"
                  label="Where did the injury occur?"
                  width="200"
                ></el-table-column>
                <el-table-column prop="venue.name" label="Venue" width="200"></el-table-column>
                <el-table-column
                  :formatter="dateFormatter"
                  prop="dateOfInjury"
                  label="Date and Time of Incident"
                  width="200"
                ></el-table-column>
                <el-table-column label="Body Part" width="200">
                  <template slot-scope="scope">
                    <ol>
                      <li v-for="(injuryCase, i) in scope.row.natureOfInjury" :key="i">
                        {{ injuryCase.name || injuryCase.bodyArea }} :
                        {{ injuryCase.type || injuryCase.bodyPart }}
                      </li>
                    </ol>
                  </template>
                </el-table-column>
                <el-table-column label="Nature of Injury" width="200">
                  <template slot-scope="scope">
                    <ol>
                      <li v-for="(nature, i) in scope.row.natureOfInjury" :key="i">
                        {{ nature.injury }}
                      </li>
                    </ol>
                  </template>
                </el-table-column>
                <el-table-column
                  :formatter="symptomsFormatter"
                  label="Concussion symptoms?"
                  width="100"
                ></el-table-column>
                <el-table-column
                  :formatter="concussionFormatter"
                  label="Concussion Confirmed"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="playerRemoveBy"
                  label="Removed from field?"
                  width="120"
                ></el-table-column>
                <el-table-column
                  :formatter="treatPlayerFormatter"
                  label="Ambulance or Doctor called?"
                  width="100"
                ></el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="Disciplinary">
              <data-tables :data="disciplinaryData" :border="true" @current-change="clickRow">
                <el-table-column :formatter="typeFormatter" prop="type" label="Type" width="100">
                </el-table-column>
                <el-table-column
                  :formatter="(row) => tribunalDateFormatter(row, 'incident')"
                  prop="meta.dateTimeIncident"
                  label="Date of Incident"
                  width="150"
                >
                </el-table-column>
                <el-table-column prop="round.displayName" label="Round" width="100">
                </el-table-column>
                <el-table-column prop="status" label="Status" width="200"> </el-table-column>
                <el-table-column prop="meta.carryOverPts" label="Carry Over Points" width="150">
                </el-table-column>
                <el-table-column prop="meta.competition.name" label="Competition" width="200">
                </el-table-column>
                <el-table-column
                  :formatter="createdByFormatter"
                  label="Form Submitted By"
                  width="200"
                ></el-table-column>
                <el-table-column
                  :formatter="(row) => tribunalDateFormatter(row, 'mod')"
                  prop="modifiedAt"
                  label="Last Modified"
                  width="200"
                >
                </el-table-column>
              </data-tables>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
    <div v-if="selectedCase">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-button @click="returnPanel" class="mb-1 go-back-button" icon="el-icon-arrow-up">
            Back to List</el-button
          >
        </el-col>
      </el-row>
      <div v-if="selectedCase.type === 1 || selectedCase.type === 3">
        <el-row :gutter="10">
          <el-col :span="6">
            <strong>Competition Name:</strong>
            <br />
            {{
              selectedCase.meta &&
              selectedCase.meta.competition &&
              selectedCase.meta.competition.name
                ? selectedCase.meta.competition.name
                : "N/A"
            }}
          </el-col>
          <el-col :span="6">
            <strong>Team Name:</strong>
            <br />
            {{ selectedCase.team && selectedCase.team.name ? selectedCase.team.name : "N/A" }}
          </el-col>
          <el-col :span="6">
            <strong>Carry Over Points:</strong>
            <br />
            {{
              selectedCase.meta && selectedCase.meta.carryOverPts
                ? selectedCase.meta.carryOverPts
                : "N/A"
            }}
          </el-col>
          <el-col :span="6">
            <strong>Reporter:</strong>
            <br />
            {{ currentCaseReporter }}
          </el-col>
        </el-row>

        <el-row v-for="c in selectedCase.charges" :key="c.id">
          <el-col :span="6">
            <strong>Action Identified:</strong>
            <br />
            {{ c.name }}
          </el-col>
          <el-col :span="6">
            <strong>{{ !c.grading ? "Recommended " : "" }}Grading:</strong>
            <br />
            {{ c.grading || c.recommendedGrading }}
          </el-col>
          <el-col :span="6">
            <strong>Description:</strong>
            <br />
            {{ c.description }}
          </el-col>
          <el-col :span="6">
            <strong>Outcome:</strong>
            <br />
            {{ c.outcome }}
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="6">
            <strong>Hearing Date:</strong>
            <br />
            {{
              selectedCase.hearing && selectedCase.hearing.dateTime
                ? formatDate(selectedCase.hearing.dateTime)
                : "N/A"
            }}
          </el-col>
          <el-col :span="6">
            <strong>Suspended Penalty:</strong>
            <br />
            {{ selectedCase.meta && selectedCase.meta.suspendedPenalty ? "Yes" : "No" }}
          </el-col>
          <el-col :span="6">
            <strong>Suspended Penalty Type:</strong>
            <br />
            {{
              selectedCase.meta &&
              selectedCase.meta.suspendedPenalty &&
              selectedCase.meta.suspendedPenalty.type
                ? selectedCase.meta.suspendedPenalty.type
                : "N/A"
            }}
          </el-col>
          <el-col :span="6">
            <strong>Suspended Penalty Expiry Date:</strong>
            <br />
            {{
              selectedCase.meta &&
              selectedCase.meta.suspendedPenalty &&
              selectedCase.meta.suspendedPenalty.expiryDate
                ? formatDate(selectedCase.meta.suspendedPenalty.expiryDate)
                : "N/A"
            }}
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <strong>Appeal Lodged:</strong>
            <br />
            {{
              selectedCase.meta && selectedCase.meta.appeal && selectedCase.meta.appeal.lodged
                ? "Yes"
                : "No"
            }}
          </el-col>
          <el-col :span="6">
            <strong>Appeal Date:</strong>
            <br />
            {{
              selectedCase.meta && selectedCase.meta.appeal && selectedCase.meta.appeal.dateTime
                ? formatDate(selectedCase.meta.appeal.dateTime)
                : "N/A"
            }}
          </el-col>
          <el-col :span="6">
            <strong>Appeal Outcome:</strong>
            <br />
            {{
              selectedCase.meta && selectedCase.meta.appeal && selectedCase.meta.appeal.outcome
                ? selectedCase.meta.appeal.outcome
                : "N/A"
            }}
          </el-col>
          <el-col :span="6">
            <strong>Witness:</strong>
            <br />
            N/A
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <strong>Notes:</strong>
            <ul v-if="selectedCase.notes && selectedCase.notes.length > 0">
              <li v-for="(note, index) in selectedCase.notes" :key="index">
                <strong>Note:</strong> {{ note.note }} <strong>Witness:</strong>
                {{ note.witness || "N/A" }}
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div v-else-if="selectedCase.type === 2">
        <el-row :gutter="10">
          <el-col :span="6">
            <strong>Immediate care applied:</strong>
            <br />
            {{ selectedCase.immediateCare ? selectedCase.immediateCare : "N/A" }}
          </el-col>
          <el-col :span="6">
            <strong>Round:</strong>
            <br />
            {{
              selectedCase.round && selectedCase.round.displayName
                ? selectedCase.round.displayName
                : "N/A"
            }}
          </el-col>
          <el-col :span="6">
            <strong>Competition Season:</strong>
            <br />
            {{ selectedCase.comp && selectedCase.comp.season ? selectedCase.comp.season : "N/A" }}
          </el-col>
          <el-col :span="6">
            <strong>Competition:</strong>
            <br />
            {{ selectedCase.comp && selectedCase.comp.name ? selectedCase.comp.name : "N/A" }}
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <strong>Team Name:</strong>
            <br />
            {{ selectedCase.team && selectedCase.team.name ? selectedCase.team.name : "N/A" }}
          </el-col>
          <el-col :span="6">
            <strong>Notes:</strong>
            <br />
            {{ selectedCase.otherSignificants ? selectedCase.otherSignificants : "N/A" }}
          </el-col>
          <el-col :span="6">
            <strong>Form Submitted By:</strong>
            <br />
            {{ createdByFormatter(selectedCase) }}
          </el-col>
          <el-col
            :span="6"
            v-if="
              selectedCase.updatedBy &&
              selectedCase.updatedBy.profile &&
              selectedCase.updatedBy.profile.firstName
            "
          >
            <strong>Form Last Updated By:</strong>
            <br />
            {{ updatedByFormatter(selectedCase) }}
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { roles, compCaseScenes, compCasesTypes } from "../../utils/constants";

export default {
  props: {
    currentMember: { type: Object },
  },
  data() {
    return {
      roles,
      compCaseScenes,
      compCasesTypes,
      compCases: [],
      legacyCompCases: [],
      mymedicalCases: [],
      selectedCase: undefined,
    };
  },
  mounted() {
    const { id } = this.$route.params;
    this.$http.get(`nrl/api/v1/admin/competition-cases/members/${id}`).then((res) => {
      const { compCases, legacyCompCases, mymedicalCases } = res.data.data;
      this.compCases = compCases;
      compCases ? _.filter(compCases, { isActive: true }) : [];
      this.legacyCompCases =
        legacyCompCases.length && legacyCompCases.map((x) => ({ ...x, legacy: true }));
      this.mymedicalCases = Array.isArray(mymedicalCases)
        ? mymedicalCases.map((x) => ({ ...x, mymedical: true }))
        : [];
    });
  },
  methods: {
    returnPanel() {
      let tabPanel = this.selectedCase.type === 1 ? "pane-0" : "pane-1";
      let tab = document.querySelectorAll(`#pane-2.el-tab-pane #${tabPanel}`);
      this.selectedCase = undefined;
    },
    associationFormatter(row) {
      return row.orgtree && row.orgtree.association && row.orgtree.association.name
        ? row.orgtree.association.name
        : "N/A";
    },
    clubFormatter(row) {
      return row.orgtree && row.orgtree.club && row.orgtree.club.name
        ? row.orgtree.club.name
        : "N/A";
    },
    chargeNameFormatter(row) {
      return row.charges ? row.charges[0].name : "";
    },
    chargeDescriptionFormatter(row) {
      return row.charges ? row.charges[0].description : "";
    },
    chargeGradingFormatter(row) {
      return row.charges ? row.charges[0].grading : "N/A";
    },
    chargeLegacyGradingFormatter(row) {
      return row.meta && row.meta.legacyChargeGrading ? row.meta.legacyChargeGrading : "N/A";
    },
    expiryFormatter(row) {
      if (row.meta && row.meta.penalty && row.meta.penalty.expiryDate) {
        const date = moment(row.meta.penalty.expiryDate).format("DD/MM/YYYY");
        return date;
      }
      return "N/A";
    },
    hearingFormatter(row) {
      if (!row.hearing) return "N/A";
      return row.hearing.required ? "Yes" : "No";
    },
    sceneFormatter(row) {
      const scene = compCaseScenes.find((x) => +row.scene === x.type);
      return (scene && scene.name) || "N/A";
    },
    typeFormatter(row) {
      const type = compCasesTypes.find((x) => +row.type === x.type);
      return (type && type.name) || "N/A";
    },
    tribunalDateFormatter(row, col) {
      switch (col) {
        case "incident":
          const dateInc =
            row.meta && row.meta.dateTimeIncident
              ? this.moment(row.meta.dateTimeIncident).format("DD/MM/YY")
              : undefined;

          return dateInc ? dateInc : "NA";
        case "mod":
          const dateMod = row.modifiedAt
            ? this.moment(row.modifiedAt).format("HH:mm, MMMM Do YYYY")
            : undefined;
          let name;
          if (row.updatedBy) {
            name =
              row.updatedBy.profile &&
              row.updatedBy.profile.firstName &&
              row.updatedBy.profile.lastName
                ? `${row.updatedBy.profile.firstName + " " + row.updatedBy.profile.lastName}`
                : "";
          }
          const updateStr = dateMod && name ? `${dateMod} by ${name}` : dateMod;
          return dateMod && updateStr ? updateStr : dateMod ? dateMod : "NA";
        default:
          return "NA";
      }
    },
    dateFormatter(row) {
      const timeGiven =
        typeof row.timeOfInjury === "number"
          ? this.moment(row.timeOfInjury).format("HH:mm")
          : row.timeOfInjury;
      const time = timeGiven ? timeGiven + ", " : "";
      return row.dateOfInjury ? time + this.moment(row.dateOfInjury).format("MMMM Do YYYY") : "NA";
    },
    symptomsFormatter(row) {
      return row.symptons ? (row.symptons === "head-neck" ? "NA" : "Yes") : "No";
    },
    concussionFormatter(row) {
      return row.concussionStatusUpdate &&
        row.concussionStatusUpdate.concussionConfirmed &&
        !row.mymedical
        ? row.concussionStatusUpdate.concussionConfirmed
        : row.mymedical &&
          row.assessment &&
          row.assessment.diagnosisConcussion &&
          row.assessment.diagnosisConcussion.key
        ? row.assessment.diagnosisConcussion.key === "hia_confirmed"
          ? "Yes"
          : row.assessment.diagnosisConcussion.key === "hia_suspected"
          ? "Suspected"
          : "No"
        : "NA";
    },
    treatPlayerFormatter(row) {
      return row.treatPlayer ? "Yes" : "No";
    },
    formatTimestamp(row) {
      return this.moment(row.createdAt).format("MMMM Do YYYY, h:m a");
    },
    statusFormatter(row) {
      if (row.expired === true) return row.deregistered === true ? "Deregistered" : "Inactive";
      return "Active";
    },
    createdByFormatter(row) {
      if (row.submittedBy && row.submittedBy.profile && row.submittedBy.profile.firstName) {
        const name = `${row.submittedBy.profile.firstName} ${row.submittedBy.profile.lastName}`;
        const words = name.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
        const fullName = words.join(" ");
        return fullName;
      } else if (row.mymedical && row.assessor) {
        return row.assessor;
      }
      return "N/A";
    },
    updatedByFormatter(row) {
      if (row.updatedBy && row.updatedBy.profile && row.updatedBy.profile.firstName) {
        const name = `${row.updatedBy.profile.firstName} ${row.updatedBy.profile.lastName}`;
        const words = name.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
        const fullName = words.join(" ");
        return fullName;
      }
      return "NA";
    },
    clickRow(row) {
      this.selectedCase = row;
      this.showDetail = true;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    data() {
      const mmCases = this.mymedicalCases.map((c) => {
        const findCompCase = compCaseScenes.find(
          (s) =>
            c.assessment && c.assessment.placeOfInjury && s.name === c.assessment.placeOfInjury.name
        );
        const epoch = new Date(`${c.assessment.date}, ${c.assessment.time}`);
        const assess_timestamp = epoch ? epoch.valueOf() : new Date(c.assessment.date).valueOf();
        return {
          _id: c._id,
          assessment: c.assessment,
          assessor: c.assessment && c.assessment.assessor,
          comp:
            findCompCase &&
            findCompCase.type === 1 &&
            c.assessment &&
            c.assessment.match &&
            c.assessment.match.competition &&
            c.assessment.match.competition.name
              ? {
                  name: c.assessment.match.competition.name,
                  season: c.season,
                }
              : undefined,
          createdAt: assess_timestamp || c.createdAt,
          currentMember: c.assessment && c.assessment.player && c.assessment.player.profile,
          dateOfInjury: c.assessment && c.assessment.date,
          dateOfInjuryStr: c.assessment && c.assessment.date,
          immediateCare: "Other",
          isActive: c.assessment_complete,
          memberId: c.member_id,
          natureOfInjury: [
            {
              bodyArea: "FRONT",
              bodyPart: "Head/Face/Neck",
              injury: "Suspected Concussion",
            },
            {
              bodyArea: "BACK",
              bodyPart: "Head/Face/Neck",
              injury: "Suspected Concussion",
            },
          ],
          orgtree: c.team_orgtree,
          otherSignificants: c.assessment && c.assessment.assessmentNotes,
          playerRemoveBy: "Assisted",
          round:
            findCompCase &&
            findCompCase.type === 1 &&
            c.assessment &&
            c.assessment.match &&
            c.assessment.match.round &&
            c.assessment.match.round.name
              ? {
                  displayName: c.assessment.match.round.name,
                }
              : undefined,
          scene: findCompCase ? findCompCase.type : 0,
          status: c.assessment_complete ? "Completed" : "In Progress",
          submittedBy: c.createdBy,
          symptons:
            c.assessment &&
            c.assessment.diagnosisConcussion &&
            c.assessment.diagnosisConcussion &&
            c.assessment.diagnosisConcussion.key &&
            c.assessment.diagnosisConcussion.key !== "hia_clear_invalid-removal"
              ? c.assessment.diagnosisConcussion.key
              : c.assessment && c.assessment.structuralHeadNeck
              ? "head-neck"
              : false,
          team:
            findCompCase &&
            findCompCase.type === 1 &&
            c.assessment &&
            c.assessment.match &&
            c.assessment.match.team &&
            c.assessment.match.team.name
              ? {
                  name: c.assessment.match.team.name,
                }
              : { name: c.team_name || "NA" },
          timeOfInjury: c.assessment && c.assessment.time,
          timeOfInjuryStr: c.assessment && c.assessment.time,
          treatPlayer: false,
          type: 2,
          venue: c.assessment && c.assessment.address,
          mymedical: true,
        };
      });
      const data = this.compCases.concat(this.legacyCompCases || [], mmCases || []);
      return _.orderBy(data, ["createdAt"], ["desc"]);
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    currentCaseReporter() {
      if (!this.selectedCase) return undefined;
      if (this.selectedCase.legacy) {
        return this.selectedCase.notes && this.selectedCase.notes[0]
          ? this.selectedCase.notes[0].createdBy.name ||
              this.selectedCase.notes[0].createdBy.firstName
          : "N/A";
      }
      return this.selectedCase.submittedBy &&
        this.selectedCase.submittedBy.profile &&
        this.selectedCase.submittedBy.profile.firstName &&
        this.selectedCase.submittedBy.profile.lastName
        ? `${this.selectedCase.submittedBy.profile.firstName} ${this.selectedCase.submittedBy.profile.lastName}`
        : "N/A";
    },
    injuryData() {
      const arr = _.filter(this.data, { type: 2 });
      arr.forEach((element) => {
        element.currentMember = this.currentMember.profile;
      });
      return arr;
    },
    tribunalData() {
      return _.filter(this.data, { type: 1 });
    },
    disciplinaryData() {
      return _.filter(this.data, { type: 3 });
    },
  },
};
</script>
