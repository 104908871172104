/**
 * @param entityId {number}
 * @param name {string}
 * @example membersByNameQuery(31, John)
 * @returns GraphQL query with entityId and name as a variable
 */
export const membersQuery = (entityId, firstName, lastName, dob, generic) => {
  const variables = { "entityId": entityId }

  if (firstName) {
    variables.firstName = [firstName, firstName[0].toUpperCase() + firstName.slice(1)];
  }
  if (lastName) {
    variables.lastName = [lastName, lastName[0].toUpperCase() + lastName.slice(1)];
  }
  if (dob) {
    variables.dob = dob;
  }
  if (generic) {
    variables.nrlId = parseInt(generic);
    variables.mobile = generic;
    variables.email = generic;
  }
  if (generic) {
    return {
      query: `query GetMembers($entityId: Int!, $nrlId: Int, $email: String, $mobile: String) {
          registrationsViews(
             query: {
              AND: [
                {OR: 
                  [
                    {memberId: $nrlId},
                    {member: {profile: {mobile: $mobile}}},
                    {member: {profile: {email: $email}}}
                  ]
                },
                {expired: false},
                {OR: 
                  [
                    {orgtree: { national: {_id: $entityId}}}, 
                    {orgtree: {state: {_id: $entityId}}}, 
                    {orgtree:{region: {_id: $entityId}}}, 
                    {orgtree: {association: {_id: $entityId}}}, 
                    {orgtree: {club: {_id: $entityId}}}
                  ]
                }
              ]
            }
            limit: 10000
          ) {
            _id
            memberId
            memberType
            entityType
            season
            entityId
            createdAt
            paid
            orgtree {
              national {
                name
              }
              state {
                name
                _id
              }
              region {
                name
              }
              association {
                name
              }
              club {
                name
              }
              team {
                name
              }
            }
            member {
              profile {
                firstName
                lastName
                dob
                gender
                email
                mobile
              }
              _id
              activeRegos {
                entityId
                entityType
                memberType
                national_id
                permit
                season
              }
            }
          }
        }`,
      variables: variables
    }
  }
  else {
  return {
    query: `query GetMembers($entityId: Int!, $firstName: [String], $lastName: [String], $dob: String) {
          registrationsViews(
             query: {
              AND: [
                {member: {profile: {firstName_in: $firstName, lastName_in: $lastName, dob: $dob}}},
                {expired: false},
                {OR: 
                  [
                    {orgtree: { national: {_id: $entityId}}}, 
                    {orgtree: {state: {_id: $entityId}}}, 
                    {orgtree:{region: {_id: $entityId}}}, 
                    {orgtree: {association: {_id: $entityId}}}, 
                    {orgtree: {club: {_id: $entityId}}}
                  ]
                }
              ]
            }
            limit: 10000
          ) {
            _id
            memberId
            memberType
            entityType
            season
            entityId
            createdAt
            paid
            orgtree {
              national {
                name
              }
              state {
                name
                _id
              }
              region {
                name
              }
              association {
                name
              }
              club {
                name
              }
              team {
                name
              }
            }
            member {
              profile {
                firstName
                lastName
                dob
                gender
                email
                mobile
              }
              _id
              activeRegos {
                entityId
                entityType
                memberType
                national_id
                permit
                season
              }
            }
          }
        }`,
    variables: variables
  }
}
}