// TODO: Create separate template /squad-management/:id

<template>
  <div class="find-member">
    <el-row class="search-bar" :gutter="10">
      <el-col :span="6">
        <el-input autocomplete="off" v-model="queryFilterValue" placeholder="Search..."> </el-input>
      </el-col>
      <el-col :span="6">
        <el-button @click="filterVisible = true"> Filters </el-button>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>

    <data-tables-server
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      :total="total"
      @query-change="queryChange"
      @row-click="handleSelectTeam"
      class="data-table"
    >
      <el-table-column fixed prop="_id" label="Id" width="100"> </el-table-column>
      <el-table-column fixed prop="name" label="Name" width="250"> </el-table-column>
      <el-table-column
        :formatter="competitionFormatter"
        prop="competitions"
        label="Competition Name"
        width="250"
      >
      </el-table-column>
      <el-table-column prop="orgtree.club.name" label="Club Name" width="250"> </el-table-column>
      <el-table-column prop="ageLvl" label="Age Level" width="150"> </el-table-column>
      <el-table-column :formatter="restrictedFormatter" label="Restricted" width="150">
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="250"
      >
      </el-table-column>
      <el-table-column prop="status" label="Status" width="150"> </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg, roles } from "../../utils/constants";
import FilterPage from "@/views/EntityFilterPage.vue";

export default {
  name: "SquadManagement",
  components: {
    FilterPage,
  },
  props: {},
  data() {
    const {
      data,
      filters,
      pagination: { total, pageSize, page },
    } = this.$store.state.entities.squadManagement;
    return {
      roles,
      errormsg,
      loading: true,
      teamAgeLvl: "",
      tableData: data,
      queryFilterValue: this.$store.state.entities.squadManagement.criteria,
      filters,
      currentPage: page,
      total,
      pageSize,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      queryInfo: {},
      filterVisible: false,
    };
  },
  mounted() {
    if (this.isTfa) {
      this.filters.isActive = ["active"];
      this.$store.commit("entities/updateFilters", {
        filters: this.filters,
        entity: "squadManagement",
      });
    }
  },
  methods: {
    restrictedFormatter(row) {
      if (!_.has(row, "meta.restrictedSquad")) return "";

      return _.get(row, "meta.restrictedSquad") ? "Yes" : "No";
    },
    modifiedFormatter(row) {
      return row.modifiedAt ? this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    handleSelectTeam(row) {
      if (this.isTfa) {
        this.$router.push({
          name: "squadManagement.detailsbeta",
          params: {
            id: row._id,
          },
        });
      } else {
        this.$router.push({
          name: "squadManagement.details",
          params: {
            id: row._id,
          },
        });
      }
    },
    competitionFormatter(row) {
      return row.competitions && row.competitions.length ? row.competitions.join(", ") : "NA";
    },
    queryChange(queryInfo) {
      this.queryInfo = queryInfo;
      this.fetchData();
    },
    fetchData() {
      const { pageSize, page } = this.queryInfo;
      this.loading = true;
      this.$http
        .get(`/nrl/api/v1/admin/teams/data-table/${pageSize}/${page}`, {
          params: { criteria: this.queryFilterValue, isActive: this.filters.isActive },
        })
        .then((response) => {
          this.total = response.data.data.total;
          this.tableData = response.data.data.pageData;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },
    sendFilter(e) {
      const { isActive } = e;
      this.filters.isActive = isActive;
      this.filterVisible = false;
      this.$store.commit("entities/updateFilters", {
        filters: this.filters,
        entity: "squadManagement",
      });
      this.fetchData();
    },
  },
  computed: {
    ageFilter: {
      get() {
        return this.teamAgeLvl;
      },
      set(val) {
        this.teamAgeLvl = val;
      },
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    isTfa() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
  },
  watch: {
    tableData(data) {
      this.tableData.forEach((team) => {
        team.status = team.isActive ? "Active" : "Inactive";
      });
      this.$store.commit("entities/updateData", { data, entity: "squadManagement" });
      this.$store.commit("entities/updatePagination", {
        data: { ..._.pick(this.queryInfo, ["pageSize", "page"]), total: this.total },
        entity: "squadManagement",
      });
    },
    // eslint-disable-next-line func-names
    queryFilterValue: _.debounce(function (criteria) {
      this.$store.commit("entities/updateCriteria", { criteria, entity: "squadManagement" });
      this.fetchData();
    }, 500),
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-table {
  width: 100%;
}

.go-back-button {
  float: left;
}
</style>
