<template>
  <div v-if="ladder">
    <el-row class="action-buttons">
      <el-button @click="back" icon="el-icon-arrow-left" class="fl-left"> Go Back </el-button>
      <el-button class="update" icon="el-icon-refresh" @click="refresh"> Update </el-button>
    </el-row>

    <data-tables
      v-if="
        !ladder.competition.hasPools ||
        (ladder.competition.hasPools &&
          (!ladder.competition.pools || ladder.competition.pools.length === 0))
      "
      v-loading="loading"
      :data="ladder.teams"
      :pagination-props="{ 'hide-on-single-page': true }"
      class="data-table"
    >
      <el-table-column fixed align="center" prop="name" label="Name" width="auto">
      </el-table-column>
      <el-table-column align="center" prop="stats.matchesPlayed" label="P" width="50">
      </el-table-column>
      <el-table-column align="center" prop="stats.totalMatchPoints" label="PTS" width="50">
      </el-table-column>
      <el-table-column width="150" align="center" label="PTS ADJ">
        <template slot-scope="scope">
          <el-button
            class="button-new-tag"
            size="mini"
            @click="adjust('teamPointADJs', scope.row._id)"
          >
            ({{ scope.row.stats.pointsADJ }})
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="stats.matchesWon" label="W" width="50">
      </el-table-column>
      <el-table-column align="center" prop="stats.matchesDrawn" label="D" width="50">
      </el-table-column>
      <el-table-column align="center" prop="stats.matchesLost" label="L" width="50">
      </el-table-column>
      <el-table-column align="center" prop="stats.byes" label="B" width="50"> </el-table-column>
      <el-table-column align="center" prop="stats.pointsFor" label="+" width="50">
      </el-table-column>
      <el-table-column width="150" align="center" label="+ ADJ">
        <template slot-scope="scope">
          <el-button
            class="button-new-tag"
            size="mini"
            @click="adjust('teamPointForADJs', scope.row._id)"
          >
            ({{ scope.row.stats.pointsForADJ }})
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="stats.pointsAgainst" label="-" width="50">
      </el-table-column>
      <el-table-column width="150" align="center" label="- ADJ">
        <template slot-scope="scope">
          <el-button
            class="button-new-tag"
            size="mini"
            @click="adjust('teamPointAgainstADJs', scope.row._id)"
          >
            ({{ scope.row.stats.pointsAgainstADJ }})
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="stats.pointsDifference" label="+/-" width="50">
      </el-table-column>
      <el-table-column align="center" prop="stats.matchWinRatio" label="%" width="50">
      </el-table-column>
    </data-tables>

    <data-tables
      v-else
      v-loading="loading"
      v-for="pool in Object.keys(teamsByPool)"
      :key="pool"
      :data="teamsByPool[pool]"
      :table-props="tableProps"
      :pagination-props="{ 'hide-on-single-page': true }"
      class="data-table"
    >
      >
      <el-table-column :label="pool" align="center" label-class-name="black-label">
        <el-table-column fixed align="center" prop="name" label="Name" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="stats.matchesPlayed" label="P" width="50">
        </el-table-column>
        <el-table-column align="center" prop="stats.totalMatchPoints" label="PTS" width="50">
        </el-table-column>
        <el-table-column width="150" align="center" label="PTS ADJ">
          <template slot-scope="scope">
            <el-button
              class="button-new-tag"
              size="mini"
              @click="adjust('teamPointADJs', scope.row._id)"
            >
              ({{ scope.row.stats.pointsADJ }})
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="stats.matchesWon" label="W" width="50">
        </el-table-column>
        <el-table-column align="center" prop="stats.matchesDrawn" label="D" width="50">
        </el-table-column>
        <el-table-column align="center" prop="stats.matchesLost" label="L" width="50">
        </el-table-column>
        <el-table-column align="center" prop="stats.byes" label="B" width="50"> </el-table-column>
        <el-table-column align="center" prop="stats.pointsFor" label="+" width="50">
        </el-table-column>
        <el-table-column width="150" align="center" label="+ ADJ">
          <template slot-scope="scope">
            <el-button
              class="button-new-tag"
              size="mini"
              @click="adjust('teamPointForADJs', scope.row._id)"
            >
              ({{ scope.row.stats.pointsForADJ }})
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="stats.pointsAgainst" label="-" width="50">
        </el-table-column>
        <el-table-column width="150" align="center" label="- ADJ">
          <template slot-scope="scope">
            <el-button
              class="button-new-tag"
              size="mini"
              @click="adjust('teamPointAgainstADJs', scope.row._id)"
            >
              ({{ scope.row.stats.pointsAgainstADJ }})
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="stats.pointsDifference" label="+/-" width="50">
        </el-table-column>
      </el-table-column>
    </data-tables>
    <el-dialog title="Adjust Points" :visible.sync="pointsADJVisible" width="40%">
      <div class="point-adjustment">
        <h4>Point Adjustment</h4>
        <el-input-number v-model="pointsADJValue"></el-input-number>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pointsADJVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submitPointADJ">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "LadderTemplate",
  data() {
    return {
      loading: true,
      ladder: false,
      pointsADJVisible: false,
      pointsADJType: false,
      pointsADJTeam: false,
      pointsADJValue: false,
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
    };
  },
  mounted() {
    const { id } = this.$route.params;
    this.$http
      .get(`/nrl/api/v1/admin/ladders/${id}`)
      .then((response) => {
        this.ladder = response.data.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.$customError();
      });
  },
  methods: {
    back() {
      this.$router.push("/ladders");
    },
    refresh() {
      const { _id } = this.ladder.competition;
      this.$store.commit("root/loading", true);
      this.$http
        .post(`/nrl/api/v1/admin/ladders/${_id}/refresh`)
        .then((response) => {
          this.ladder = response.data.data;
          this.$customSuccess();
          this.$store.commit("root/loading", false);
        })
        .catch((e) => {
          let message = "Sorry we could not process your request";
          if (e.response && e.response.data && e.response.data.message)
            message = e.response.data.message;
          this.$customError(message);
          this.$store.commit("root/loading", false);
        });
    },
    adjust(type, id) {
      this.pointsADJType = type;
      this.pointsADJTeam = id;
      this.pointsADJValue = 0;
      this.pointsADJVisible = true;
    },
    submitPointADJ() {
      this.$store.commit("root/loading", true);
      const { pointsADJType, pointsADJValue, pointsADJTeam } = this;
      const { _id } = this.ladder;
      this.$http
        .post(`/nrl/api/v1/admin/ladders/${_id}/adjustment`, {
          pointsADJType,
          pointsADJValue,
          pointsADJTeam,
        })
        .then((response) => {
          this.pointsADJVisible = false;
          this.pointsADJType = false;
          this.pointsADJValue = false;
          this.pointsADJTeam = false;
          this.ladder = response.data.data;
          this.$customSuccess();
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.pointsADJVisible = false;
          this.pointsADJType = false;
          this.pointsADJValue = false;
          this.pointsADJTeam = false;
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    },
  },
  computed: {
    teamsByPool() {
      const { teams } = this.ladder;
      return teams
        ? teams.reduce((result, item) => {
            const value = item.pool;
            result[value] = (result[value] || []).concat(item);
            return result;
          }, {})
        : false;
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-bottom: 1.25rem;
}

.el-pagination {
  margin-top: 1rem !important;
}

.point-adjustment {
  text-align: center;
  margin-bottom: 1.25rem;
}

.action-buttons {
  margin-bottom: 1rem;
  .update {
    float: right;
    margin-left: 1rem;
  }
}
</style>
