<template>
  <div class="d-flex-column">
    <CheckBoxGroup
      :choices="statuses"
      :min="0"
      :max="1"
      name="Active"
      v-model="inputVal.isActive"
    />

    <div class="mt-2 d-flex-column">
      <div class="m-1">
        <el-button
            @click="clear"
            class="full-width button-invisible">Clear</el-button>
        <el-button
            @click='submit'
            type="primary"
            class="full-width">Apply Filters</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import CheckBoxGroup from '@/components/CheckboxGroup.vue';
import { EventBus } from '../bus';

export default {
  name: 'FilterPage',
  components: { CheckBoxGroup },
  props: ['value'],
  methods: {
    submit() {
      this.$emit('submit', this.inputVal);
    },
    clear() {
      this.$set(this.inputVal, 'isActive', []);
      EventBus.$emit('clear');
      this.$emit('clear', this.inputVal);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  data() {
    return {
      inputVal: {
        isActive: [],
      },
      statuses: [{ name: 'Active', type: 'active' }, { name: 'Inactive', type: 'inactive' }]
    };
  },
  mounted() {
    const { isActive } = this.$store.state.entities[this.$route.name].filters;
    this.$set(this.inputVal, 'isActive', isActive || []);
  },
};
</script>

<style lang="scss" scoped>

</style>
