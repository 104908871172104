<template>
  <div>
    <el-form
      label-position="top"
      :model="competition.display"
      :rules="displayRules"
      class="display-form"
      id="displayRules"
      ref="displayRules"
    >
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="6">
          <el-form-item prop="public" label="Public">
            <el-select v-model="competition.display.public">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="result" label="Result">
            <el-select v-model="competition.display.result" :disabled="competitionType && (competitionType.isAllowedToDisplayLaddersAndResults === false)">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="ladder" label="Ladder">
            <el-select v-model="competition.display.ladder" :disabled="competitionType && (competitionType.isAllowedToDisplayLaddersAndResults === false)">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="teamlists" label="Team Lists">
            <el-select v-model="competition.display.teamlists">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <action-buttons submit cancel @submit="updateDisplay()" @cancel="cancelForm()" />

    <div class="tw-flex tw-flex-col tw-gap-3 tw-pt-3">
      <h4 class="tw-font-bold">Hide Individual Rounds</h4>
      <el-button class="tw-max-w-xs add-hide-round-button" @click="addHideDateVisible = true">
        Add Hide Date Range
      </el-button>

      <el-dialog
        title="Add Hide Date Range"
        :visible.sync="addHideDateVisible"
        width="70%"
        @closed="addHideDateVisible = false"
        :close-on-click-modal="false"
      >
        <el-date-picker
          value-format="timestamp"
          v-model="newHideRange"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addHideDateVisible = false">Close</el-button>
          <el-button @click="handleAddHideDate">Submit</el-button>
        </span>
      </el-dialog>

      <el-table size="small" :data="competition.display.hideRounds" :border="true">
        <el-table-column :formatter="fromFormatter" prop="from" label="From" width="auto">
        </el-table-column>
        <el-table-column :formatter="toFormatter" prop="to" label="To" width="250">
        </el-table-column>
        <el-table-column prop="id">
          <template slot-scope="scope">
            <div style="text-align: center">
              <el-button
                class="operation-button"
                type="danger"
                icon="el-icon-remove"
                size="small"
                @click="deleteRange(scope.row)"
              >
                Remove
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg } from "../../utils/constants";
import { EventBus } from "../../bus";

export default {
  data() {
    return {
      displayRules: {
        public: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        }
      },
      addHideDateVisible: false,
      newHideRange: []
    };
  },
  components: { ActionButtons },
  props: {
    competition: { type: Object, required: true },
    competitionType: { type: Object, required: true }
  },
  methods: {
    cancelForm() {
      this.$router.push({
        name: "competitions.list"
      });
    },
    updateDisplay() {
      this.$refs.displayRules.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const update = { display: this.competition.display };
          const url = `/nrl/api/v1/admin/competitions/${this.competition._id}`;
          this.$http
            .put(url, update)
            .then(response => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
              EventBus.$emit("updateCompetition", response.data.data);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
      });
    },
    handleAddHideDate() {
      const { newHideRange } = this;
      const [from, to] = newHideRange;
      if (from && to) {
        this.$store.commit("root/loading", true);
        this.$http
          .post(`/nrl/api/v1/admin/competitions/${this.competition._id}/add-hide-range`, {
            from,
            to
          })
          .then(() => {
            this.competition.display.hideRounds.push({ from, to });
            this.addHideDateVisible = false;
            this.newHideRange = false;
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$customError();
            this.addHideDateVisible = false;
            this.newHideRange = false;
            this.$store.commit("root/loading", false);
          });
      } else {
        this.$customError("Please select a valid date range");
      }
    },
    deleteRange(row) {
      const { from, to } = row;
      this.$store.commit("root/loading", true);
      this.$http
        .post(`/nrl/api/v1/admin/competitions/${this.competition._id}/remove-hide-range`, {
          from,
          to
        })
        .then(() => {
          this.competition.display.hideRounds = this.competition.display.hideRounds.filter(
            hide => !(hide.from === from && hide.to === to)
          );
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    },
    fromFormatter(row) {
      return row.from ? this.moment(row.from).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    toFormatter(row) {
      return row.to ? this.moment(row.to).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    }
  },
  computed: {
    hideRange: {
      get() {
        const { hideFrom, hideTo } = this.competition.display;
        return [hideFrom, hideTo];
      },
      set(range) {
        const { hideRounds } = this.competition.display;
        const [hideFrom, hideTo] = range;
        this.competition.display = {
          ...this.competition.display,
          hideRounds,
          hideFrom,
          hideTo
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
::v-deep .el-input__inner {
  width: 100% !important;
}

.add-hide-round-button {
  margin-bottom: 1.25rem;
}
</style>
