/* eslint-disable no-return-assign */
import _ from 'lodash';

const initState = () => ({
  criteria: '',
  data: [],
  pagination: {
    total: 0,
    pageSize: 0,
    page: 0
  }
});
export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateCriteria: (state, payload) => state.criteria = payload,
    updateData: (state, data) => state.data = data,
    updatePagination: (state, payload) => _.assign(state.pagination, payload.data),
  }
};
