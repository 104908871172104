<template>
  <div>
    <el-form label-position="top" :model="regiProduct" :rules="discountrules" ref="regidiscounts">
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="discountmembertype" label="Discount member type">
            <el-select v-model="discountmembertype" placeholder="Discount member type" filterable>
              <el-option
                v-for="item in allDiscountMemberTypes"
                :label="item.name"
                :key="item.type"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discountamount" label="Discount amount">
            <el-input
              type="text"
              autocomplete="off"
              v-model="discountamount"
              placeholder="Discount amount"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="familyfrom" label="Regos from">
            <el-input
              type="text"
              autocomplete="off"
              v-model="familyfrom"
              placeholder="Number of regos from"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="familyto" label="Regos to">
            <el-input
              type="text"
              autocomplete="off"
              v-model="familyto"
              placeholder="Number of regos to"
            />
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 ">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitDiscount('regidiscounts')">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <data-tables
      v-if="regiProduct.familyDiscounts.length > 0"
      :data="regiProduct.familyDiscounts"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="Member Type" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.discountMemberType"
            placeholder="Member type"
            :disabled="!familydiscountoptionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="item in allDiscountMemberTypes"
              :label="item.name"
              :key="item.type"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Discount Amount" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.discountAmount"
            :disabled="!familydiscountoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Number Regos From" width="170">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.familyFrom"
            :disabled="!familydiscountoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Number Regos To" width="170">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.familyTo"
            :disabled="!familydiscountoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" width="auto">
        <template slot-scope="scope">
          <el-button @click="handleEditRowDiscount(scope.$index)">
            <template v-if="!familydiscountoptionsdisabled[scope.$index]">
              Edit
            </template>
            <template v-else>
              Save
            </template>
          </el-button>
          <el-button @click="handleRemoveRowDiscount(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { errormsg, memberTypes, roles } from "../../utils/constants";

export default {
  name: "DiscountsVolume",
  props: ["regiProduct"],
  data() {
    const validatefamilyFrom = (rule, value, callback) => {
      if (
        !this.currentDiscount.familyFrom ||
        !parseInt(this.currentDiscount.familyFrom) ||
        parseInt(this.currentDiscount.familyFrom) <= 0
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validatefamilyTo = (rule, value, callback) => {
      if (
        !this.currentDiscount.familyTo ||
        !parseInt(this.currentDiscount.familyTo) ||
        parseInt(this.currentDiscount.familyTo) <= 0 ||
        parseInt(this.currentDiscount.familyTo) < parseInt(this.currentDiscount.familyFrom)
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountAmount = (rule, value, callback) => {
      if (
        !this.currentDiscount.discountAmount ||
        !parseFloat(this.currentDiscount.discountAmount) ||
        parseFloat(this.currentDiscount.discountAmount) <= 0
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountMemberType = (rule, value, callback) => {
      if (!this.currentDiscount.discountMemberType) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };

    return {
      currentDiscount: {
        familyFrom: null,
        familyTo: null,
        discountAmount: null,
        discountMemberType: null
      },
      familydiscountoptionsdisabled: [],
      discountrules: {
        familyfrom: {
          required: true,
          validator: validatefamilyFrom,
          message: errormsg.input_option,
          trigger: "blur"
        },
        familyto: {
          required: true,
          validator: validatefamilyTo,
          message: errormsg.input_option,
          trigger: "blur"
        },
        discountamount: {
          required: true,
          validator: validateDiscountAmount,
          message: errormsg.input_option,
          trigger: "blur"
        },
        discountmembertype: {
          required: true,
          validator: validateDiscountMemberType,
          message: errormsg.select_option,
          trigger: "change"
        }
      },
      tableProps: {
        border: true
      }
    };
  },
  methods: {
    submitDiscount(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const discount = {
            familyFrom: parseInt(this.currentDiscount.familyFrom),
            familyTo: parseInt(this.currentDiscount.familyTo),
            discountAmount_int: parseInt(
              parseFloat(this.currentDiscount.discountAmount).toFixed(2) * 100
            ),
            discountAmount: parseFloat(this.currentDiscount.discountAmount).toFixed(2),
            discountMemberType: this.currentDiscount.discountMemberType
          };
          if (discount.familyFrom < 1) {
            this.$customError(errormsg.regiproduct_invalidfamilydiscountfrom);
            return false;
          }
          if (discount.familyFrom > discount.familyTo) {
            this.$customError(errormsg.regiproduct_invalidfamilydiscountto);
            return false;
          }
          const discountSameRange = this.regiProduct.familyDiscounts.find(
            d =>
              d.discountMemberType === discount.discountMemberType &&
              !(d.familyFrom > discount.familyFrom || d.familyTo < discount.familyTo)
          );

          if (discountSameRange) {
            this.$customError(errormsg.regiproduct_overlap);
            return false;
          }
          this.$store.commit("root/loading", true);
          this.regiProduct.entity_type = Object.keys(roles)
            .find(key => roles[key] === this.$store.getters["user/activeRole"].type)
            .replace("Admin", "");
          this.regiProduct.entity_id = this.$store.getters["user/activeRole"].entity._id;

          const insert = {
            familyFrom: discount.familyFrom,
            familyTo: discount.familyTo,
            amount: discount.discountAmount_int,
            memberType: discount.discountMemberType
          };
          this.currentDiscount = {
            familyFrom: null,
            familyTo: null,
            discountAmount: null,
            discountMemberType: null
          };
          const url = "/nrl/api/v1/admin/registration-products/family-discounts";
          this.$http
            .post(url, insert)
            .then(response => {
              discount._id = response.data.data._id;
              this.regiProduct.familyDiscounts.push(discount);
              this.$store.commit("root/loading", false);
              this.familydiscountoptionsdisabled.push(false);
              this.$customSuccess();
            })
            .catch(error => {
              this.$store.commit("root/loading", false);
              this.$customError(error.response.data.message);
            });
          return true;
        }
        return false;
      });
    },
    handleEditRowDiscount(rowindex) {
      if (this.familydiscountoptionsdisabled[rowindex]) {
        const row = this.regiProduct.familyDiscounts[rowindex];
        if (
          !row.discountAmount ||
          !parseFloat(row.discountAmount) ||
          parseFloat(row.discountAmount) <= 0
        ) {
          this.$customError(errormsg.regiproduct_invaliddiscountamount);
          return false;
        }
        // const cddiscountFrom = this.moment(row.discountFrom).format('YYYY-MM-DD');
        // const cddiscountTo = this.moment(row.discountTo).format('YYYY-MM-DD');
        const cdFamilyTo = parseInt(row.familyTo);
        const cdFamilyFrom = parseInt(row.familyFrom);
        if (cdFamilyFrom < 1) {
          this.$customError(errormsg.regiproduct_invalidfamilydiscountfrom);
          return false;
        }
        if (cdFamilyFrom > cdFamilyTo) {
          this.$customError(errormsg.regiproduct_invalidfamilydiscountto);
          return false;
        }
        const discountSameRange = this.regiProduct.familyDiscounts.find(
          d =>
            row._id !== d._id &&
            d.discountMemberType === row.discountMemberType &&
            !(d.familyFrom > cdFamilyFrom || d.familyTo < cdFamilyTo)
        );

        if (discountSameRange) {
          this.$customError(errormsg.regiproduct_overlap);
          return false;
        }
        this.$store.commit("root/loading", true);
        this.regiProduct.familyDiscounts[rowindex].familyFrom = cdFamilyFrom;
        this.regiProduct.familyDiscounts[rowindex].familyTo = cdFamilyTo;
        this.regiProduct.familyDiscounts[rowindex].discountAmount = parseFloat(
          row.discountAmount
        ).toFixed(2);
        this.regiProduct.familyDiscounts[rowindex].discountAmount_int = parseInt(
          parseFloat(row.discountAmount).toFixed(2) * 100
        );
        this.$set(
          this.familydiscountoptionsdisabled,
          rowindex,
          !this.familydiscountoptionsdisabled[rowindex]
        );

        const discount = this.regiProduct.familyDiscounts[rowindex];
        const update = {
          familyFrom: cdFamilyFrom,
          familyTo: cdFamilyTo,
          amount: discount.discountAmount_int,
          memberType: discount.discountMemberType
        };
        const url = `/nrl/api/v1/admin/registration-products/family-discounts/${discount._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
            this.$set(
              this.familydiscountoptionsdisabled,
              rowindex,
              !this.familydiscountoptionsdisabled[rowindex]
            );
          })
          .catch(error => {
            this.$store.commit("root/loading", false);
            this.$customError(error.response.data.message);
          });
      }
      this.$set(
        this.familydiscountoptionsdisabled,
        rowindex,
        !this.familydiscountoptionsdisabled[rowindex]
      );
      return true;
    },
    handleRemoveRowDiscount(index) {
      this.$store.commit("root/loading", true);
      const discount = this.regiProduct.familyDiscounts[index];
      this.$http
        .delete(`/nrl/api/v1/admin/registration-products/family-discounts/${discount._id}`)
        .then(() => {
          this.regiProduct.familyDiscounts.splice(index, 1);
          this.familydiscountoptionsdisabled.splice(index, 1);
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(error => {
          this.$store.commit("root/loading", false);
          this.$customError(error.response.data.message);
        });
    }
  },
  computed: {
    allDiscountMemberTypes() {
      return memberTypes.filter(member =>
        this.regiProduct.products.map(product => product.role).includes(member.type)
      );
    },
    familyfrom: {
      get() {
        return this.currentDiscount.familyFrom != null ? this.currentDiscount.familyFrom : null;
      },
      set(value) {
        this.currentDiscount.familyFrom = Number(value) && parseFloat(value) > 0 ? value : null;
      }
    },
    familyto: {
      get() {
        return this.currentDiscount.familyTo != null ? this.currentDiscount.familyTo : null;
      },
      set(value) {
        this.currentDiscount.familyTo = Number(value) && parseFloat(value) > 0 ? value : null;
      }
    },
    discountamount: {
      get() {
        return this.currentDiscount.discountAmount != null
          ? this.currentDiscount.discountAmount
          : null;
      },
      set(value) {
        this.currentDiscount.discountAmount = Number(value) && parseFloat(value) > 0 ? value : null;
      }
    },
    discountmembertype: {
      get() {
        return this.currentDiscount.discountMemberType != null
          ? this.currentDiscount.discountMemberType
          : null;
      },
      set(value) {
        this.currentDiscount.discountMemberType = value;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
</style>
