<template>
  <div>
    <el-row class="search-bar" :gutter="20">
      <el-col :span="6">
        <el-input
          autocomplete="off"
          v-model="filters[0].value"
          placeholder="Search...">
        </el-input>
      </el-col>
      <el-col :span="6" v-if="clearance === roles.associationAdmin">
        <el-button @click="regrade" type="success" icon="el-icon-plus"> Re-Grade team </el-button>
      </el-col>
    </el-row>
    <data-tables
      v-loading="loading"
      :filters="filters"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table">
      <el-table-column
        prop="sourceCompetition.name"
        label="Source Competition"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="regradeTeam.name"
        label="Re-Graded Team"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="destCompetition.name"
        label="Destination Competition"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="designationOption"
        label="Team Designation"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="replaceTeam.name"
        label="Replaced Team"
        width="auto">
      </el-table-column>
      <el-table-column
        :formatter="createdAtFormatter"
        prop="createdAt"
        label="Regraded At"
        width="auto">
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { roles } from '@/utils/constants';

export default {
  data() {
    return {
      roles,
      loading: true,
      tableData: [],
      pageSize: 20,
      filters: [{
        value: '',
        filterFn: (row, filter) => Object.keys(row).some((prop) => {
          if (['destCompetition', 'sourceCompetition', 'regradeTeam', 'replaceTeam'].includes(prop)) {
            const { name } = row[prop];
            const filterRegex = new RegExp(filter.value, 'i');
            return name.toString().match(filterRegex);
          }
          return false;
        })
      }, {
        value: [],
      }],
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
    };
  },
  mounted() {
    this.$http
      .get('/nrl/api/v1/admin/team-regradings')
      .then((response) => {
        this.tableData = response.data.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.$customError();
      });
  },
  methods: {
    regrade() {
      this.$router.push({ name: 'regrading.regrade' });
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'regrading.details',
        params: {
          id: row._id
        },
      });
    },
    createdAtFormatter(row) {
      return (row.createdAt ? this.moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a') : 'NA');
    },
  },
  computed: {
    clearance() {
      return this.$store.getters['user/activeRole'].type;
    }
  }
};
</script>

<style scoped lang="scss">
    .search-bar {
      margin-bottom: 1rem;
    }

    .data-table {
        width: 100%;
    }
    .el-pagination {
        margin-top: 1rem !important;
    }
</style>
