<template>
  <div>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-form-item prop="ageLevel" label="Select an age level">
        <el-select value-key="name" v-model="filters.ageLevel" placeholder="Select an age level">
          <el-option
            v-for="ageLvl in ageLevels"
            :label="ageLvl.name"
            :key="ageLvl.name"
            :value="ageLvl"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item label="First Name">
            <el-input autocomplete="off" v-model="queryFilterFirstName" placeholder=""> </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="Last Name">
            <el-input autocomplete="off" v-model="queryFilterLastName" placeholder=""> </el-input>
          </el-form-item>
        </el-col>
      </div>

      <el-form-item label=" ">
        <div
          class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <el-col :span="4">
            <el-button @click="search" type="success">Search </el-button>
          </el-col>
          <el-col :span="3">
            <el-button @click="filterVisible = true"> Filters </el-button>
          </el-col>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>

    <data-tables-server
      v-if="filters.ageLevel"
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      :total="total"
      @query-change="queryChange"
      @row-click="handleRowClick"
      class="data-table"
    >
      <el-table-column prop="possibleDuplicates[0].firstName" label="First Name" width="auto">
      </el-table-column>
      <el-table-column prop="possibleDuplicates[0].middleName" label="Middle Name" width="auto">
      </el-table-column>
      <el-table-column prop="possibleDuplicates[0].lastName" label="Last Name" width="auto">
      </el-table-column>
      <el-table-column prop="possibleDuplicates[0].dob" label="DOB" width="auto"> </el-table-column>
      <el-table-column prop="resolved" :formatter="statusFormatter" label="Status" width="auto">
      </el-table-column>
      <el-table-column
        :formatter="dateFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="auto"
      >
      </el-table-column>
    </data-tables-server>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import FilterPage from "@/views/duplicate-members/FilterPage.vue";
import { roles, compCasesTypes, duplicateAgeLevels, errormsg } from "@/utils/constants";

export default {
  data() {
    return {
      season: null,
      ageLevels: duplicateAgeLevels,
      filters: {
        ageLevel: this.$store.state.duplicateMembers.criteria.ageLevel
      },

      filterRules: {
        ageLevel: [{ required: true, message: errormsg.select_option, trigger: "blur" }]
      },
      total: 0,
      currentPage: 1,
      filterVisible: false,
      loading: true,
      compCasesTypes,
      roles,
      tableData: [],
      pageSize: 50,
      tableProps: {
        border: true
      },
      paginationProps: {
        pageSizes: [50, 100, 150]
      },
      queryInfo: {},
      queryFilterFirstName: this.$store.state.duplicateMembers.criteria.firstName,
      queryFilterLastName: this.$store.state.duplicateMembers.criteria.lastName
    };
  },
  components: {
    FilterPage
  },
  mounted() {
    this.fetchStore();
    this.season = moment().year();
  },
  methods: {
    fetchStore() {
      const {
        data,
        pagination: { total, pageSize, page }
      } = this.$store.state.duplicateMembers;
      if (data.length) {
        this.loading = false;
        this.total = total;
        this.pageSize = pageSize;
        this.currentPage = page;
        this.tableData = data;
        return true;
      }
      return false;
    },
    handleRowClick(row) {
      this.$router.push({
        name: "duplicate-members.update",
        params: {
          id: row._id
        }
      });
    },
    queryChange(queryInfo) {
      this.queryInfo = queryInfo;
      if (!this.$store.state.duplicateMembers.data.length || queryInfo.type !== "init")
        this.search();
    },
    dateFormatter(row) {
      return row.modifiedAt ? this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    sendFilter(event) {
      // store params
      this.$store.commit("duplicateMembers/updateFilters", event);
      const { status: statusArray } = event;
      const status = statusArray.length > 0 ? statusArray[0] : null;
      this.fetchData(status);
    },
    search() {
      const statusFilter = this.$store.state.duplicateMembers.filters.status;
      const [status] = statusFilter; // "pending" || "resolved"
      this.fetchData(status);
    },
    async fetchData(status) {
      this.$refs.filters.validate(valid => {
        if (!valid) return false;
      });

      if (!this.filters.ageLevel) return;
      this.$store.commit("duplicateMembers/updateCriteria", {
        criteria: {
          ...this.$store.state.duplicateMembers.criteria,
          ageLevel: this.filters.ageLevel
        }
      });

      const currentYear = moment().year();
      const lower = currentYear - this.filters.ageLevel.max;
      const upper = currentYear - this.filters.ageLevel.min;

      const { pageSize, page } = this.queryInfo;

      this.$store.commit(
        "root/loading",
        "We are fetching your data. This process may take up to a minute"
      );
      try {
        const response = await this.$http.get(
          `/nrl/api/v1/admin/duplicate-members/data-table/${pageSize}/${page}`,
          {
            params: {
              firstName: this.queryFilterFirstName,
              lastName: this.queryFilterLastName,
              status,
              lower,
              upper
            }
          }
        );
        this.$set(this, "total", response.data.data.total);
        this.$set(this, "tableData", response.data.data.pageData);
        this.$store.commit("root/loading", false);
        this.loading = false;
      } catch (e) {
        this.$store.commit("root/loading", false);
        this.loading = false;
        this.$customError();
      }
    },
    statusFormatter(row) {
      return row.resolved === true ? "Resolved" : "Pending";
    }
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    }
  },
  watch: {
    tableData(data) {
      this.$store.commit("duplicateMembers/updateData", { data });
      this.$store.commit("duplicateMembers/updatePagination", {
        data: { ..._.pick(this.queryInfo, ["pageSize", "page"]), total: this.total }
      });
    },

    queryFilterFirstName(criteria) {
      this.$store.commit("duplicateMembers/updateCriteria", {
        criteria: { ...this.$store.state.duplicateMembers.criteria, firstName: criteria }
      });
    },
    queryFilterLastName(criteria) {
      this.$store.commit("duplicateMembers/updateCriteria", {
        criteria: { ...this.$store.state.duplicateMembers.criteria, lastName: criteria }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
