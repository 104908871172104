<template>
  <div class="tw-self-center tw-ml-auto">
    <div
      class="tw-flex tw-items-center tw-justify-center tw-absolute tw-h-screen tw-top-0 tw-left-0"
    ></div>
    <transition
      name="custom"
      enter-active-class="animate__animated animate__bounceInDown"
      leave-active-class="animate__animated animate__bounceOutUp"
    >
      <div
        v-if="showMatchModal"
        style="
          max-height: max-content;
          filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
          border-radius: 8px;
          z-index: 70;
        "
        class="modal admin-shadow__active tw-w-max tw-max-w-xl tw-m-auto tw-bg-white tw-flex tw-flex-col tw-fixed tw-self-center tw-top-0 tw-bottom-0 tw-left-0 tw-right-0"
      >
        <div
          class="tw-p-6"
          style="
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.03);
            display: flex;
            align-items: center;
            column-gap: 8px;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="width: 18px; height: 18px"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
            />
            <path
              fill-rule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clip-rule="evenodd"
            />
          </svg>
          <span style="font-weight: 700; font-size: 16px; line-height: 18px; color: #222222"
            >Edit Match</span
          >
        </div>
        <div style="background-color: #f6f6f6">
          <el-form
            id="match-edit-form"
            label-position="top"
            :rules="matchRules"
            ref="matchRules"
            :model="editMatch"
          >
            <div
              class="tw-flex tw-flex-col tw-gap-y-6 tw-p-6"
              style="border-bottom: 1px solid #ededed; border-top: 1px solid #e6e6e6"
            >
              <el-form-item prop="tba" class="admin-switch">
                <div class="label">
                  <span style="font-weight: 500">TBA Match</span>
                  <span style="font-weight: 400"
                    >Save this match but with no teams attributed.</span
                  >
                </div>
                <el-switch
                  v-model="tba"
                  @change="changeTba"
                  active-color="#00DB00"
                  inactive-color="#CCCCCC"
                >
                </el-switch>
              </el-form-item>
              <el-form-item prop="bye" class="admin-switch">
                <div class="label">
                  <span style="font-weight: 500">BYE Match</span>
                  <span style="font-weight: 400">Save this match as a BYE.</span>
                </div>
                <el-switch
                  v-model="bye"
                  @change="changeBye"
                  active-color="#00DB00"
                  inactive-color="#CCCCCC"
                >
                </el-switch>
              </el-form-item>
            </div>
            <div class="tw-flex tw-gap-x-4 tw-px-6 tw-items-end">
              <div class="tw-w-1/2">
                <el-form-item
                  prop="matchDate"
                  v-model="matchDate"
                  label="Date"
                  class="admin-date-picker"
                >
                  <button @click.prevent="timeJump('back', 'day')" class="jump-back">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-h-3.5 tw-w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  <button @click.prevent="timeJump('forward', 'day')" class="jump-forward">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-h-3.5 tw-w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  <el-input type="date" size="small" v-model="matchDate"></el-input>
                </el-form-item>
              </div>
              <div class="tw-w-1/2">
                <el-form-item prop="matchTime" label="Time" class="admin-time-picker">
                  <button @click.prevent="timeJump('back', 'mins')" class="jump-back">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-h-3.5 tw-w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  <button @click.prevent="timeJump('forward', 'mins')" class="jump-forward">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-h-3.5 tw-w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  <el-input type="time" size="small" v-model="matchTime" step="300"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="tw-flex tw-gap-x-4 tw-px-6">
              <el-form-item prop="homeTeam" label="Home Team" class="admin-input__std">
                <el-select
                  :disabled="
                    bye &&
                    awayTeamName !== undefined &&
                    awayTeamName !== null &&
                    awayTeamName !== ''
                  "
                  v-model="homeTeamName"
                  filterable
                  clearable
                  placeholder="Home team"
                  size="small"
                >
                  <el-option
                    v-for="item in allTeams"
                    :key="item.name"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="awayTeam" label="Away Team" class="admin-input__std">
                <el-select
                  :disabled="
                    bye &&
                    homeTeamName !== undefined &&
                    homeTeamName !== null &&
                    homeTeamName !== ''
                  "
                  v-model="awayTeamName"
                  filterable
                  clearable
                  placeholder="Away team"
                  size="small"
                >
                  <el-option
                    v-for="item in allTeams"
                    :key="item.name"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="tw-flex tw-gap-x-4 tw-px-6">
              <el-form-item prop="venue" label="Venue" class="admin-input__std">
                <el-select
                  v-model="matchVenueName"
                  :remote="true"
                  :remote-method="onFilterVenue"
                  placeholder="Search venue"
                  filterable
                  size="small"
                >
                  <el-option
                    v-for="item in allVenues"
                    :label="item.name"
                    :key="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="meta.fieldNo" label="Field No" class="admin-input__std">
                <el-input
                  maxlength="10"
                  autocomplete="off"
                  placeholder="Field No"
                  v-model="editMatch.meta.fieldNo"
                  size="small"
                ></el-input>
              </el-form-item>
            </div>
            <div class="tw-px-6">
              <el-form-item prop="status" label="Match Status" class="admin-radio">
                <el-radio
                  v-for="item in statusTypes"
                  v-model="matchStatus"
                  :key="item.type"
                  :label="item.type"
                  >{{ item.name }}</el-radio
                >
              </el-form-item>
            </div>
            <div class="tw-flex tw-px-6 tw-gap-x-4">
              <el-form-item
                v-if="matchStatus === 'forfeit'"
                prop="forfeitingTeam"
                label="Forfeiting Team"
                class="admin-input__std"
              >
                <el-select v-model="forfeitingTeamName" size="small" placeholder="Forfeiting Team">
                  <el-option v-for="item in matchTeams" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-y-6"
          style="background-color: #f6f6f6; padding: 16px 24px 24px; border-radius: 0px 0px 8px 8px"
        >
          <div>
            <p style="font-weight: 400; font-size: 12px; line-height: 10px; color: #333333">
              To edit scores and other match details, go to
              <span
                @click="
                  $router.push({
                    name: 'matches.list',
                    params: {
                      currentCompetition: editMatch.competition._id,
                    },
                  })
                "
                style="text-decoration: underline; font-weight: 500; cursor: pointer"
                >matches.</span
              >
            </p>
          </div>
          <div class="tw-flex tw-justify-between">
            <div class="tw-flex tw-gap-x-4">
              <button
                type="success"
                class="admin-button__medium admin-bg-highlight"
                @click="$emit('updateMatch', validMatchUpdate)"
              >
                <span style="color: #222222">Save</span>
              </button>
              <button
                type="default"
                class="admin-button__medium default"
                @click="$emit('cancelForm')"
              >
                <span style="color: #333333">Cancel Changes</span>
              </button>
            </div>
            <div>
              <button
                class="admin-button__medium alert"
                v-if="has(editMatch, '_id')"
                type="danger"
                @click="$emit('deleteMatchModal')"
              >
                <span>Delete Match</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="custom"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div
        v-if="showMatchModal"
        @click="$emit('hideMatchModal')"
        class="tw-bg-gray-700 tw-bg-opacity-50 tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-transition tw-duration-150 tw-ease-in-out tw-transform"
        style="backdrop-filter: blur(4px); z-index: 60"
      ></div>
    </transition>
    <transition
      name="custom"
      enter-active-class="animate__animated animate__bounceInDown"
      leave-active-class="animate__animated animate__bounceOutUp"
    >
      <div
        v-if="showDeleteModal"
        class="delete-modal tw-w-11/12 tw-max-w-xl tw-z-50 tw-m-auto admin-shadow__active tw-flex tw-flex-col tw-fixed tw-self-center tw-top-0 tw-bottom-0 tw-left-0 tw-right-0"
      >
        <div class="heading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="height: 18px; width: 18px"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
          <span>Are you sure you want to delete this field?</span>
        </div>
        <div class="content">
          <p>
            You will be removing the field as well as the games on this date. Do you still want to
            remove the field?
          </p>
          <div class="button-container">
            <button
              type="default"
              class="admin-button__medium default"
              @click.prevent="$emit('cancelDelete')"
            >
              <span style="color: #333333">Cancel Changes</span>
            </button>
            <button
              class="admin-button__medium alert"
              type="danger"
              @click.prevent="$emit('deleteMatch')"
            >
              <span>Delete Match</span>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { errormsg, matchStatus } from "@/utils/constants";
import { has } from "lodash";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "match/getField",
  mutationType: "match/updateField",
});

export default {
  name: "Edit-Match-Modal",
  props: {
    allTeams: Array,
    editMatch: Object,
    overrideScores: Boolean,
    showDeleteModal: Boolean,
    showMatchModal: Boolean,
    venues: Array,
  },
  data() {
    const awayTeamValidator = (_, value, callback) => {
      if (!this.awayTeamId && !this.tba) {
        if (!this.bye) {
          return callback(new Error(errormsg.select_option));
        }
        if (this.bye && !this.homeTeamId) {
          return callback(new Error(errormsg.select_option));
        }
      }

      if (this.homeTeamId === this.awayTeamId && !this.tba && !this.bye) {
        return callback(new Error(errormsg.duplicate_team));
      }

      return callback();
    };
    const byeAndTbaValidator = (_, value, callback) => {
      if (this.bye && this.tba) {
        return callback(new Error("A match cannot be TBA and BYE"));
      }
      return callback();
    };
    const dateTimeValidator = (_, value, callback) => {
      if (!parseInt(this.dateTime) || parseInt(this.dateTime) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const dateValidator = (_, value, callback) => {
      if (!parseInt(this.matchDate)) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const fieldValidator = (_, value, callback) => {
      if (this.fieldNo && this.fieldNo.length > 10) {
        return callback(new Error(errormsg.char_length));
      }
      return callback();
    };
    const validateForfeitingTeam = (_, value, callback) => {
      const { forfeitingTeamName } = this;
      if (!forfeitingTeamName) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const homeTeamValidator = (_, value, callback) => {
      if (!this.homeTeamId && !this.tba) {
        if (!this.bye) {
          return callback(new Error(errormsg.select_option));
        }
        if (this.bye && !this.awayTeamId) {
          return callback(new Error(errormsg.select_option));
        }
      }

      if (this.homeTeamId === this.awayTeamId && !this.tba && !this.bye) {
        return callback(new Error(errormsg.duplicate_team));
      }

      return callback();
    };
    const roundDisplayNameValidator = (_, value, callback) => {
      if (this.roundType === "Final" && !this.roundDisplayName) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const roundNumberValidator = (_, value, callback) => {
      if (!parseInt(this.roundNumber) || parseInt(this.roundNumber) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const roundTypeValidator = (_, value, callback) => {
      if (!this.roundType) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const statusValidator = (_, value, callback) => {
      if (!this.matchStatus) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const timeValidator = (_, value, callback) => {
      if (!parseInt(this.matchTime)) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const venueValidator = (_, value, callback) => {
      if (!this.matchVenueName && !this.tba && !this.bye) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    return {
      allVenues: this.venues,
      has,
      matchRules: {
        awayTeam: {
          required: true,
          trigger: "change",
          validator: awayTeamValidator,
        },
        bye: {
          required: false,
          trigger: "change",
          validator: byeAndTbaValidator,
        },
        competition: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
        fieldNo: {
          required: false,
          trigger: "change",
          validator: fieldValidator,
        },
        forfeitingTeam: {
          required: true,
          trigger: "blur",
          validator: validateForfeitingTeam,
          message: errormsg.select_option,
        },
        homeTeam: {
          required: true,
          trigger: "change",
          validator: homeTeamValidator,
        },
        matchDate: {
          required: true,
          message: "Please select a default start date",
          trigger: "change",
          validator: dateValidator,
        },
        matchTime: {
          required: true,
          message: "Please select a default start time",
          trigger: "change",
          validator: timeValidator,
        },
        roundDisplayName: {
          required: true,
          trigger: "change",
          validator: roundDisplayNameValidator,
        },
        roundNumber: {
          required: true,
          trigger: "change",
          validator: roundNumberValidator,
        },
        roundType: {
          required: true,
          trigger: "change",
          validator: roundTypeValidator,
        },
        status: {
          required: false,
          trigger: "change",
          validator: statusValidator,
          message: errormsg.select_option,
        },
        tba: {
          required: false,
          trigger: "change",
          validator: byeAndTbaValidator,
        },
        venue: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
          validator: venueValidator,
        },
      },
      statusTypes: matchStatus,
    };
  },
  methods: {
    changeBye(val) {
      if (val === true) {
        if (this.awayTeamName && this.homeTeamName) {
          this.awayTeamName = { name: null, _id: null };
          this.homeTeamName = { name: null, _id: null };
        } else if (this.awayTeamName) {
          this.homeTeamName = { name: null, _id: null };
        } else if (this.homeTeamName) {
          this.awayTeamName = { name: null, _id: null };
        }
        this.tba = false;
      }
    },
    changeTba(val) {
      if (val === true) {
        this.bye = false;
      }
    },
    // eslint-disable-next-line func-names
    onFilterVenue: _.debounce(function (query) {
      if (query.length >= 3) {
        this.allVenues = [];
        this.$http
          .post("/nrl/api/v1/admin/venues/search", { criteria: query })
          .then((response) => {
            this.allVenues = response.data.data;
          })
          .catch(() => {
            this.$customError();
          });
      }
    }, 500),
    timeJump(dir, int) {
      const timeInt = int === "day" ? 24 * 60 * 60 * 1000 : 5 * 60 * 1000;
      const { dateTime } = this;
      const updatedTime = dir === "back" ? dateTime - timeInt : dateTime + timeInt;
      if (int === "day") this.matchDate = this.formatDate(updatedTime);
      if (int === "mins") this.matchTime = this.formatTime(updatedTime);
    },
    formatDate(date) {
      const dateStr = new Date(date).toLocaleDateString("en-AU");
      const dArr = dateStr.split("/");
      const rearrange = [dArr[2], dArr[1], dArr[0]].join("-");
      return rearrange;
    },
    formatTime(timestamp) {
      const timeStr = new Intl.DateTimeFormat("en-GB", { timeStyle: "short" }).format(timestamp); // set to GB for easier formatting may need to revert to AU
      return timeStr;
    },
  },
  computed: {
    ...mapFields({
      allowScoring: "match.allowScoring",
      awayTeamId: "match.awayTeam._id",
      bye: "match.meta.isBye",
      dateTime: "match.dateTime",
      homeTeamId: "match.homeTeam._id",
      matchStatus: "match.status",
      roundDisplayName: "match.round.displayName",
      roundNumber: "match.round.number",
      roundType: "match.round.type",
      tba: "match.meta.isTba",
    }),
    awayScore: {
      get() {
        return this.$store.getters["match/awayScore"];
      },
      set(value) {
        this.$store.commit("match/setOverrideAwayScore", value);
      },
    },
    awayTeamName: {
      get() {
        return this.$store.getters["match/awayTeamName"];
      },
      set(value) {
        const { _id, name } = value;
        const homeName = this.$store.getters["match/homeTeamName"];
        if (name !== homeName || name === null) {
          this.$store.commit("match/setTeam", {
            type: "awayTeam",
            data: { _id, name },
          });
        } else {
          this.$customError(errormsg.duplicate_team);
        }
      },
    },
    forfeitingTeamName: {
      get() {
        return this.$store.getters["match/forfeitingTeamName"];
      },
      set(value) {
        const team = this.allTeams.find((t) => t.name === value);
        if (value) this.$store.commit("match/setForfeitingTeam", team || { name: "", _id: "" });
      },
    },
    homeScore: {
      get() {
        return this.$store.getters["match/homeScore"];
      },
      set(value) {
        this.$store.commit("match/setOverrideHomeScore", value);
      },
    },
    homeTeamName: {
      get() {
        return this.$store.getters["match/homeTeamName"];
      },
      set(value) {
        const { _id, name } = value;
        const awayName = this.$store.getters["match/awayTeamName"];
        if (name !== awayName || name === null) {
          this.$store.commit("match/setTeam", {
            type: "homeTeam",
            data: { _id, name },
          });
        } else {
          this.$customError(errormsg.duplicate_team);
        }
      },
    },
    matchTeams() {
      return this.$store.getters["match/matchTeams"];
    },
    matchDate: {
      get() {
        const { matchDate } = this.editMatch;
        return matchDate;
      },
      set(value) {
        const { matchTime } = this;
        const dateArr = [value, matchTime];
        const dateStr = dateArr.filter((v) => v).join(" ");
        const timestamp = new Date(dateStr).getTime();
        this.dateTime = timestamp;
        this.editMatch.matchDate = value;
      },
    },
    matchTime: {
      get() {
        const { matchTime } = this.editMatch;
        return matchTime;
      },
      set(value) {
        const { matchDate } = this;
        const dateArr = [matchDate, value];
        const dateStr = dateArr.filter((v) => v).join(" ");
        const timestamp = new Date(dateStr).getTime();
        this.dateTime = timestamp;
        this.editMatch.matchTime = value;
      },
    },
    matchVenueName: {
      get() {
        return this.$store.getters["match/venueName"];
      },
      set(value) {
        const { _id, name, venueTimezone } = value;
        this.$store.commit("match/setVenue", { _id, name, venueTimezone });
      },
    },
    async validMatchUpdate() {
      try {
        const check = await new Promise((resolve, reject) => {
          this.$refs.matchRules.validate((valid) => {
            if (!valid) reject(false);
            resolve(true);
          });
        });
        return check;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
<style>
.delete-modal {
  max-height: max-content;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
  background-color: #f6f6f6;
  border-radius: 8px;
  z-index: 100;
}
.delete-modal .heading {
  display: flex;
  background-color: #fff;
  padding: 20px 24px;
  gap: 8px;
  border-radius: 8px 8px 0 0;
}
.delete-modal .heading > span {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
}
.delete-modal .content {
  padding: 24px;
}
.delete-modal .content .button-container {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
</style>
