<script>
import NavBar from '@/components/NavBar.vue';

export default {
  components: {
    NavBar,
  },
  name: 'LoginLayout',
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<template>
  <div>
    <nav-bar />
    <el-container class="content">
      <slot />
    </el-container>
  </div>
</template>

<style scoped lang="scss">
.content {
  padding: 40px;
}
// Fix for loading layout moving templates around
.el-container {
  display: block !important;
}
</style>
