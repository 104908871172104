<template>
  <div>
    <el-button
      v-if="
        this.$store.getters['user/activeRole'].type === 1 ||
        this.$store.getters['user/activeRole'].type === 98 ||
        this.$store.getters['user/activeRole'].type === 99 ||
        this.$store.getters['user/activeRole'].type === 5 ||
        this.$store.getters['user/activeRole'].type === 105
      "
      icon="el-icon-plus"
      type="success"
      class="add-program-button"
      @click="handleAddProgram"
    >
      Add Program
    </el-button>
    <el-row>
      <el-row class="search-bar" :gutter="10">
        <el-col :span="6">
          <el-input
            id="filterSearch"
            autocomplete="off"
            v-model="filters[0].value"
            placeholder="Filter search..."
          >
          </el-input>
        </el-col>
        <el-col :span="18">
          <el-button @click="filterVisible = true">Filters</el-button>
          <el-button
            :disabled="showAll === false"
            :type="showAll === false ? 'error' : 'success'"
            style="float: right"
            @click="applyFilters"
            icon="el-icon-refresh-left"
            >Undo</el-button
          >
          <el-button
            :disabled="showAll === true"
            :type="showAll === true ? 'error' : 'success'"
            style="float: right"
            @click="clearFilters"
            >Show All Programs</el-button
          >
        </el-col>
      </el-row>
    </el-row>

    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>

    <data-tables
      :filters="filters"
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="onRowClicked"
      class="data-table"
    >
      <el-table-column fixed prop="settings.type" label="Program Type" width="auto">
      </el-table-column>
      <el-table-column fixed prop="name" label="Program Name" width="auto"> </el-table-column>

      <el-table-column
        v-if="
          this.$store.getters['user/activeRole'].type === 1 ||
          this.$store.getters['user/activeRole'].type === 2 ||
          this.$store.getters['user/activeRole'].type === 3 ||
          this.$store.getters['user/activeRole'].type === 4
        "
        label="Entity Name"
        width="auto"
        ><template slot-scope="scope">{{ entityName(scope.row) }}</template>
      </el-table-column>
      <el-table-column prop="settings.duration" label="No. of Sessions" width="auto">
      </el-table-column>
      <el-table-column prop="members" label="Members" width="auto"> </el-table-column>
      <el-table-column prop="venue.name" label="Venue" width="auto"> </el-table-column>
      <el-table-column prop="gdo.name" label="GDO Contact Name" width="auto"> </el-table-column>
      <el-table-column label="Start Date" width="150">
        <template slot-scope="scope">
          {{ formatDate(scope.row.startDate) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Program Status" width="auto"> </el-table-column>
      <el-table-column
        label="Remove"
        fixed="right"
        v-if="this.$store.getters['user/activeRole'].type === 1"
      >
        <template slot-scope="scope">
          <el-button @click="handleRemoveRow(scope.row, scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import moment from "moment";
import FilterPage from "./FiltersPage.vue";

export default {
  name: "ProgramsList",
  components: { FilterPage },
  props: {},
  data() {
    return {
      progMembers: [],
      loading: true,
      tableData: [],
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      filterVisible: false,
      showAll: false,
      filters: [
        {
          value: "",
          filterFn: (row, filter) =>
            Object.keys(row).some((prop) => {
              if (!filter.value || filter.value === "") return false;
              if (prop) {
                const { name } = row;
                const venueName = row.venue.name;
                const programType = row.settings.type;
                const nameFilter = name || "";
                const venueFilter = venueName || "";
                const programTypeFilter = programType || "";
                const filterRegex = new RegExp(_.escapeRegExp(filter.value), "i");
                return (
                  nameFilter.match(filterRegex) ||
                  venueFilter.match(filterRegex) ||
                  programTypeFilter.match(filterRegex)
                );
              }
              return false;
            }),
        },
        {
          value: [],
        },
      ],
    };
  },
  mounted() {
    this.loading = true;

    const allPromises = [
      this.$http.get("/nrl/api/v1/admin/members/program-members"),
      this.$http.get("/nrl/api/v1/admin/programs"),
    ];
    Promise.all(allPromises)
      .then(([progMembers, programs]) => {
        this.progMembers = progMembers.data.data;
        this.$store.commit(
          "programs/updateData",
          _.orderBy(programs.data.data, "startDate", "desc")
        );
        this.applyFilters();
        this.$store.commit("root/loading", false);
        this.loading = false;
      })
      .catch((e) => {
        this.$store.commit("root/loading", false);
        this.$customError(e);
      });
  },
  methods: {
    entityName(row) {
      return row.orgtree && row.orgtree.gamedevregion && row.orgtree.gamedevregion.name
        ? row.orgtree.gamedevregion.name
        : row.orgtree && row.orgtree.club && row.orgtree.club.name
        ? row.orgtree.club.name
        : "";
    },
    handleRemoveRow(row, rowindex) {
      this.$confirm(
        "Are you sure you want to remove this program from the list",
        "Remove program",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.tableData.splice(rowindex, 1);
          this.$store.commit("root/loading", true);
          const url = `/nrl/api/v1/admin/programs/${row._id}`;
          const update = { isActive: false };

          this.$http
            .put(url, update)
            .then(() => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        })
        .catch(() => {});
    },
    clearFilters() {
      this.tableData = this.$store.state.programs.data;
      this.tableData.forEach((data) => {
        const count = this.progMembers.find((p) => p._id === data._id);
        if (count && count.members) data.members = count.members;
        else data.members = 0;
      });
      this.tableData = _.filter(
        this.tableData,
        (row) => row.isActive || row.isActive === undefined
      );
      this.showAll = true;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    onRowClicked(row, index, event) {
      if (index.label !== "Remove") {
        this.$router.push({
          name: "program.update",
          params: {
            type: "update",
            program: row,
            settings: row.settings,
            id: row._id,
          },
        });
      }
    },
    handleAddProgram() {
      this.$router.push({
        name: "program.add",
        params: {
          type: "insert",
        },
      });
    },
    applyFilters() {
      this.showAll = false;
      const { status, matchDateRange, isActive } = this.$store.state.programs.filters;
      let filteredData;
      if (status && status.length > 0) {
        // filter this.tableDate where status is in status array
        filteredData = _.filter(this.$store.state.programs.data, (row) => {
          if (row.status) {
            const matchedStatus = status.find((element) => {
              if (row.status.includes(element)) {
                return true;
              }
            });
            if (matchedStatus !== undefined) {
              return true;
            }
          } else {
            if (status.includes("_blanks")) {
              return true;
            }
          }
        });
      } else {
        filteredData = this.$store.state.programs.data;
      }
      if (matchDateRange) {
        const [start, end] = matchDateRange;
        filteredData = _.filter(
          filteredData,
          (row) =>
            start <= _.get(row, "startDate") &&
            _.get(row, "startDate") <= +this.moment(end).add(1, "day")
        );
      }

      // Default case
      if (isActive.length <= 0) {
        isActive[0] = "active";
      }

      // When filter is selected
      if (isActive && isActive.length > 0) {
        const activeOrNot = isActive[0];

        if (activeOrNot === "inactive")
          filteredData = _.filter(filteredData, (row) => {
            return row.isActive === false;
          });
        else {
          filteredData = _.filter(
            filteredData,
            (row) => row.isActive || row.isActive === undefined
          );
        }
      }

      filteredData.forEach((data) => {
        const count = this.progMembers.find((p) => p._id === data._id);
        if (count && count.members) data.members = count.members;
        else data.members = 0;
      });

      this.tableData = filteredData;
    },
    sendFilter(event) {
      this.$store.commit("programs/updateStatusFilter", event.status);
      this.$store.commit("programs/updateDateRangeFilter", event.matchDateRange);
      this.$store.commit("programs/updateisActiveFilter", event.isActive);

      this.applyFilters();
      this.filterVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.add-program-button {
  margin-bottom: 1rem !important;
}
</style>
