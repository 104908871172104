import { render, staticRenderFns } from "./DiscountsVolume.vue?vue&type=template&id=0358420d&scoped=true&"
import script from "./DiscountsVolume.vue?vue&type=script&lang=js&"
export * from "./DiscountsVolume.vue?vue&type=script&lang=js&"
import style0 from "./DiscountsVolume.vue?vue&type=style&index=0&id=0358420d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0358420d",
  null
  
)

export default component.exports