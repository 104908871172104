<template>
  <div>
    <div class="operation-box">
      <el-row :gutter="10">
        <el-form :rules="reportRules" v-model="selection">
          <el-col :span="8">
            <el-form-item label="Report" prop="report">
              <el-select
                v-model="selection.report"
                placeholder="Select a report"
                clearable
                filterable
              >
                <el-option
                  v-for="report in reports"
                  :key="report.value"
                  :value="report.value"
                  :label="report.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="!selection.report.includes('both')">
            <el-form-item label="Team" prop="selectedTeam">
              <el-select v-model="selection.selectedTeam" clearable placeholder="Select a team">
                <el-option
                  v-for="team in teams"
                  :key="team._id"
                  :value="team._id"
                  :label="team.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <el-row class="fetch-pdf">
        <el-col :span="6">
          <el-button type="success" @click="fetchReport"> Generate PDF </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { matchReports, errormsg } from "../../utils/constants";
// import * as pdfMake from "pdfmake/build/pdfmake.js";
// if (pdfMake.vfs === undefined) {
//   const pdfFonts = require("pdfmake/build/vfs_fonts.js");
//   pdfMake.vfs = pdfFonts.pdfMake.vfs;
// }
export default {
  name: "MatchReport",
  props: {
    matchid: { type: Number, required: true },
    teams: { type: Array, required: true }
  },
  data() {
    const reportValidator = (_, value, callback) => {
      if (!this.selection.report || this.selection.report === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    const teamValidator = (_, value, callback) => {
      if (!this.selection.selectedTeam) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    return {
      definition: null,
      selection: {
        report: "",
        selectedTeam: null
      },
      reportRules: {
        report: {
          required: true,
          validator: reportValidator,
          trigger: "change"
        },
        selectedTeam: {
          required: true,
          validator: teamValidator,
          trigger: "change"
        }
      },
      matchReports
    };
  },
  mounted() {},
  computed: {
    reports() {
      const national = this.$store.getters["user/activeRole"].national_id;
      return this.matchReports.filter(x => x.nationals.includes(national));
    }
  },
  beforeCreate() {},
  beforeDestroy() {
    this.docurl = null;
  },
  methods: {
    fetchReport() {
      const { matchid, selection } = this;
      const { selectedTeam, report } = selection;
      if (!report || (!report.includes("both") && !selectedTeam)) {
        this.$customError("Please select a report and a team!");
        return;
      }
      this.$store.commit("root/loading", true);
      this.$http
        .post(`/nrl/api/v1/admin/matches/${matchid}/report`, { selectedTeam, report })
        .then(response => {
          if (response.data.data) {
            this.pdfMake
              .createPdf(response.data.data)
              .download(
                report.includes("both")
                  ? `${matchid}_${this.teams[0].name}V${this.teams[1].name}.pdf`
                  : `${matchid}_${this.teams.find(team => team._id === selectedTeam).name}.pdf`
              );
          } else {
            this.$customError("Error generating pdf");
          }
          this.$store.commit("root/loading", false);
        })
        .catch(e => {
          this.$customError("Error generating pdf");
          this.$store.commit("root/loading", false);
        });
    },
    createPDF() {}
  },
  watch: {}
};
</script>

<style scoped lang="scss">
.operation-box {
  padding-left: 0.5rem;
  .el-select {
    width: 100%;
  }
  .fetch-pdf {
    margin-top: 1rem;
  }
}
</style>
