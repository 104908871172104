<template>
  <div>
    <el-form label-position="top" :model="teamAllocation" id="teamAllocation" ref="teamAllocation">
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r">
        <el-form-item prop="association" label="Association">
          <el-select
            filterable
            @change="fetchTeamsForAssociation"
            v-model="teamAllocation.association"
            placeholder="Select an association"
          >
            <el-option
              v-for="item in allAssociations"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-col :span="12">
            <el-form-item label="Team Name">
              <el-input autocomplete="off" v-model="nameFilter" placeholder="Name" clearable></el-input>
            </el-form-item>
          </el-col> -->
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3">
        <el-col :span="12">
          <el-form-item prop="isActive" label="Team Status">
            <el-select v-model="isActive">
              <el-option label="Active" :value="true" />
              <el-option label="Inactive" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3">
        <div :span="24" class="tw-flex transfer-box">
          <el-form-item>
            <el-transfer
              class="transfer"
              v-model="teamAllocation.teams"
              :props="{
                key: '_id',
                label: 'mergedName',
                disabled: 'disabled',
              }"
              :button-texts="['Remove from Competition', 'Add To Competition']"
              :titles="['Available', 'Allocated']"
              :data="availableTeams"
            >
            </el-transfer>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <action-buttons submit cancel @submit="updateTeamAllocation()" @cancel="cancelForm()" />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { EventBus } from "../../bus";

const _ = require("lodash");

export default {
  data() {
    return {
      isActive: true,
    };
  },
  components: { ActionButtons },
  props: {
    comp: { type: Object, required: true },
    allAssociations: { type: Array, required: true },
    teamAlloc: { type: Object, required: true },
    voterAlloc: { type: Boolean, required: false },
    refAlloc: { type: Boolean, required: false },
  },
  methods: {
    cancelForm() {
      this.$router.push({
        name: "competitions.list",
      });
    },
    fetchTeamsForAssociation() {
      const assocId = this.teamAllocation.association;
      if (assocId) {
        this.$store.commit("root/loading", true);
        this.$http
          .get(`/nrl/api/v1/admin/teams/association/${assocId}`)
          .then((teamRes) => {
            const existing = this.teamAllocation.availableTeams.filter((team) =>
              this.teamAllocation.teams.includes(team._id)
            );
            const memberTypeArr = teamRes.data.data.filter((t) => {
              return this.refAlloc === true
                ? t.memberType === "referee" && !existing.some((entity) => entity._id === t._id)
                : t.memberType !== "referee" && !existing.some((entity) => entity._id === t._id);
            });
            this.teamAllocation.availableTeams = memberTypeArr.concat(existing);
            this.$store.commit("root/loading", false);
          })
          .catch(() => {
            this.$customError();
            this.$store.commit("root/loading", false);
          });
      }
    },
    updateTeamAllocation() {
      const allocatedTeams =
        this.teamAllocation.availableTeams.length > 0
          ? this.teamAllocation.availableTeams
              .filter((team) => this.teamAllocation.teams.some((entity) => entity === team._id))
              .map((team) => ({
                _id: team._id,
                orgtree: team.orgtree,
                name: team.name,
                ageLvl: team.ageLvl,
                maxPlayerPoints: team.maxPlayerPoints || 0,
              }))
          : [];

      // validate max no of team if competition declared the number.
      if (
        this.competition.meta.maxNoTeams &&
        this.competition.meta.maxNoTeams < allocatedTeams.length
      ) {
        this.$customError(
          `Number of allocated teams exceeds the maximum number of teams (${this.competition.meta.maxNoTeams})`
        );
        return;
      }

      // Update competition
      this.$store.commit("root/loading", true);
      const update = {};
      if (this.refAlloc === true) {
        update.refs = allocatedTeams;
      } else if (this.voterAlloc === true) {
        update.awardVoters = allocatedTeams;
      } else {
        update.teams = allocatedTeams;
      }
      const url = `/nrl/api/v1/admin/competitions/${this.competition._id}`;
      this.$http
        .put(url, update)
        .then((response) => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
          // TODO tidy up this eventbus has issues with timing,
          // this logic is trying to emit values that then feed back into itself,
          // as the component is already mounted and props are not being reflected properly
          // EventBus.$emit("updateCompetition", response.data.data);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
  },
  computed: {
    competition() {
      return Object.assign({ ageLvl: null, teams: [] }, this.comp);
    },
    teamAllocation() {
      return this.teamAlloc;
    },
    availableTeams() {
      const { ageLvl } = this.competition;
      // const nameFilter = this.nameFilter;
      if (ageLvl) {
        let mapped = this.teamAllocation.availableTeams.map((team) => ({
          ...team,
          mergedName: `${team.name} - ${team.orgtree.club ? team.orgtree.club.name : ""}`,
        }));
        mapped = _.filter(mapped, (t) => {
          if (this.teamAllocation.teams.includes(t._id)) {
            return t.ageLvl === ageLvl;
          } else {
            return t.ageLvl === ageLvl && t.isActive === this.isActive;
          }
        });
        // && (nameFilter ? t.mergedName.toLowerCase().includes(nameFilter.toLowerCase()) : true)
        return mapped;
      }

      // return this.teamAllocation.availableTeams.map(team => ({
      //   ...team,
      //   mergedName: `${team.name} - ${team.orgtree.club ? team.orgtree.club.name : ""}`
      // }));
      let mapped = this.teamAllocation.availableTeams.map((team) => ({
        ...team,
        mergedName: `${team.name} - ${team.orgtree.club ? team.orgtree.club.name : ""}`,
      }));
      mapped = _.filter(mapped, (t) => {
        if (this.teamAllocation.teams.includes(t._id)) {
          return t.ageLvl === ageLvl;
        } else {
          return t.ageLvl === ageLvl && t.isActive === this.isActive;
        }
      });
      // && (nameFilter ? t.mergedName.toLowerCase().includes(nameFilter.toLowerCase()) : true)
      return mapped;
    },
  },
  mounted() {
    EventBus.$on("updateCompetition", () => {
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>
