<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="seasons" label="Season">
            <el-select
              clearable
              filterable
              v-model="filters.seasons"
              multiple
              :multiple-limit="3"
              placeholder="Select a season"
            >
              <el-option v-for="ss in historicStatsSeasons" :label="ss" :key="ss" :value="ss">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" v-if="this.isTfa">
          <el-form-item prop="status" label="Status">
            <el-select
              v-model="filters.status"
              placeholder="Select a file type"
            >
              <el-option
                v-for="status in statuses"
                :label="status.name"
                :key="status.type"
                :value="status.type"
                :default="status.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="competitionId" label="Competition">
            <el-select
              clearable
              filterable
              multiple
              v-model="filters.competitionIds"
              placeholder="Select a competition"
            >
              <el-option
                v-for="entity in competitions"
                :label="entity.name"
                :key="get(entity, 'meta.legacyCompetitionId') || entity._id"
                :value="get(entity, 'meta.legacyCompetitionId') || entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row> -->
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import _, { get } from "lodash";
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { historicStatsSeasons, roles, errormsg } from "@/utils/constants";

export default {
  name: "PlayerStatsReport",
  components: {
    ActionButtons
  },
  mounted() {},
  data() {
    return {
      get,
      historicStatsSeasons,
      competitions: [],
      teams: [],
      filters: {
        fileType: "csv",
        seasons: [],
        competitionIds: undefined,
        status: "all"
      },
      filterRules: {
        seasons: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        },
        status: {
          required: false,
          message: errormsg.select_option,
          trigger: 'blur',
        },
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      statuses: [{ name: 'All', type: 'all', default: true }, { name: 'Active', type: 'active' }, { name: 'Inactive', type: 'inactive' }],
      roles
    };
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "player-stats",
            fileType: this.filters.fileType,
            filters: _.pick(this.filters, ["seasons", "competitionIds", "status"]),
            meta: {
              timezone: moment.tz.guess(),
              hasSeasonPrefix: false
            }
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then(response => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    isTfa() {
      return this.$store.getters['user/activeRole'].national_id === 31;
    }
  },
  watch: {
    // temp hide competitions
    // eslint-disable-next-line func-names
    // 'filters.seasons': _.debounce(function (seasons) {
    //   this.filters.competitionId = undefined;
    //   if (!seasons || !seasons.length) {
    //     this.competitions = [];
    //     return;
    //   }
    //   this.$store.commit('root/loading', true);
    //   this.$http.get('/nrl/api/v1/admin/legacy-competitions', { params: { seasons } })
    //     .then((res) => {
    //       this.competitions = res.data.data;
    //       this.$store.commit('root/loading', false);
    //     })
    //     .catch(() => {
    //       this.$store.commit('root/loading', false);
    //     });
    // }, 1000),
  }
};
</script>

<style scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>
