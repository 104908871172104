import axios from "axios";
import store from "../store";

// No Auth Requests
const httpmsnoauth = axios.create({
  baseURL: store.getters["root/msUrl"],
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export default httpmsnoauth;
