<template>
  <div>
    <el-form label-position="top" :model="program" :rules="rules" ref="program">
      <!-- <el-row :gutter="10">
          <el-col :span="6">
              <el-form-item prop="programtype" label="Program Type">
                  <el-input
                  autocomplete="off"
                  v-model="programtype"
                  placeholder="Select a program type" disabled/>
              </el-form-item>
          </el-col>
        </el-row> -->
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="6">
          <el-form-item prop="duration" label="No. Sessions">
            <el-select
              v-model="currentoption.duration"
              placeholder="Select no. of sessions"
              filterable
            >
              <el-option
                v-for="item in alldurations"
                :label="item.duration"
                :key="item.duration"
                :value="item.duration"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="minage" label="Minimum Age">
            <el-input
              type="number"
              autocomplete="off"
              v-model="minage"
              placeholder="Enter min. age"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="maxage" label="Maximum Age">
            <el-input
              type="number"
              autocomplete="off"
              v-model="maxage"
              placeholder="Enter max. age"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="price" label="Price">
            <el-input
              type="number"
              autocomplete="off"
              v-model="price"
              placeholder="Enter a price"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="activeKidsVoucherAvailable" label="Active kids voucher available">
            <el-select
              v-model="currentoption.activeKidsVoucher"
              placeholder="Active kids voucher"
              filterable
            >
              <el-option
                v-for="item in [
                  { value: false, label: 'No' },
                  { value: true, label: 'Yes' },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="hideShirtSize" label="Hide Shirt Size">
            <el-select
              v-model="currentoption.hideShirtSize"
              placeholder="Hide shirt size"
              filterable
            >
              <el-option
                v-for="item in [
                  { value: false, label: 'No' },
                  { value: true, label: 'Yes' },
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <action-buttons submit cancel @submit="submitForm('program')" @cancel="cancelForm()" />
        </el-col>
      </el-row>
    </el-form>
    <data-tables
      v-if="program.options.length > 0"
      :data="program.options"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="No. of Sessions" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.duration"
            placeholder="No. Of Sessions"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="item in alldurations"
              :label="item.duration"
              :key="item.duration"
              :value="item.duration"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Min Age" width="120">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.minAge"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Max Age" width="120">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.maxAge"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Price" width="120">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.price"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Active Kids Voucher" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.activeKidsVoucher"
            placeholder="Active kids voucher available"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="item in [
                { value: false, label: 'No' },
                { value: true, label: 'Yes' },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Hide Shirt Size" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.hideShirtSize"
            placeholder="Hide Shirt Size"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="item in [
                { value: false, label: 'No' },
                { value: true, label: 'Yes' },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" fixed="right" width="300">
        <template slot-scope="scope">
          <el-button @click="handleEditRow(scope.$index)">
            <template v-if="!optionsdisabled[scope.$index]"> Edit </template>
            <template v-else> Save </template>
          </el-button>
          <el-button @click="handleRemoveRow(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import _ from "lodash";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg } from "../../utils/constants";

export default {
  name: "ProgramsettingsProducts",
  components: { ActionButtons },
  data() {
    const validateDuration = (rule, value, callback) => {
      if (this.currentoption.duration === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const validateMinAge = (rule, value, callback) => {
      if (
        !this.currentoption.minAge ||
        !parseInt(this.currentoption.minAge) ||
        parseInt(this.currentoption.minAge) <= 0 ||
        parseInt(this.currentoption.minAge) % 1 !== 0
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateMaxAge = (rule, value, callback) => {
      if (
        !this.currentoption.maxAge ||
        !parseInt(this.currentoption.maxAge) ||
        parseInt(this.currentoption.maxAge) <= 0 ||
        parseInt(this.currentoption.maxAge) % 1 !== 0
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validatePrice = (rule, value, callback) => {
      if (!this.currentoption.price || parseFloat(this.currentoption.price) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    return {
      type: "insert",
      program: {
        type: "",
        options: [],
        saleableItems: [],
      },
      optionsdisabled: [],
      currentoption: {
        duration: "",
        minAge: 0,
        maxAge: 0,
        price: 0,
        activeKidsVoucher: false,
        hideShirtSize: false,
      },
      alldurations: [
        { duration: "1 session" },
        ...[2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => ({ duration: `${x} sessions` })),
      ],
      rules: {
        duration: [
          {
            type: "text",
            required: true,
            validator: validateDuration,
            message: errormsg.select_option,
            trigger: "blur",
          },
        ],
        minage: [
          {
            type: "number",
            required: true,
            validator: validateMinAge,
            message: errormsg.input_option,
            trigger: "blur",
          },
        ],
        maxage: [
          {
            type: "number",
            required: true,
            validator: validateMaxAge,
            message: errormsg.input_option,
            trigger: "blur",
          },
        ],
        price: [
          {
            type: "number",
            required: true,
            validator: validatePrice,
            message: errormsg.input_option,
            trigger: "blur",
          },
        ],
      },
      tableProps: {
        border: true,
      },
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, program, id } = this.$route.params;
    this.type = type || "update";
    if (id && id !== "" && id !== "add" && !program) {
      this.$http
        .get(`/nrl/api/v1/admin/programs-settings/${id}`)
        .then((response) => {
          this.program = _.merge(this.program, response.data.data);
          if (this.program.options.length > 0) {
            const options_pricefloat = this.program.options.map((o) => ({
              duration: o.duration,
              minAge: o.minAge,
              maxAge: o.maxAge,
              price: (parseFloat(o.price) / 100).toFixed(2),
              price_int: parseInt(o.price),
              activeKidsVoucher: o.activeKidsVoucher,
              hideShirtSize: o.hideShirtSize ? true : false,
            }));
            this.program.options = options_pricefloat;
            this.optionsdisabled = new Array(this.program.options.length).fill(false);
          }
          if (this.program.saleableItems && this.program.saleableItems.length > 0) {
            const items_pricefloat = this.program.saleableItems.map((o) => {
              o.price_int = parseInt(o.price);
              o.price = (parseFloat(o.price) / 100).toFixed(2);
              if (!o.gender) o.gender = "";
              return o;
            });
            this.program.saleableItems = items_pricefloat;
          }
          this.$emit("update-program", this.program);
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    } else {
      if (program) {
        this.program = program;
      }
      if (this.program.options.length > 0) {
        const options_pricefloat = this.program.options.map((o) => ({
          duration: o.duration,
          minAge: o.minAge,
          maxAge: o.maxAge,
          price: (parseFloat(o.price) / 100).toFixed(2),
          price_int: parseInt(o.price),
          activeKidsVoucher: o.activeKidsVoucher,
          hideShirtSize: o.hideShirtSize ? true : false,
        }));
        this.program.options = options_pricefloat;
        this.optionsdisabled = new Array(this.program.options.length).fill(false);
      }
      if (this.program.saleableItems && this.program.saleableItems.length > 0) {
        const items_pricefloat = this.program.saleableItems.map((o) => {
          o.price_int = parseInt(o.price);
          o.price = (parseFloat(o.price) / 100).toFixed(2);
          if (!o.gender) o.gender = "";
          return o;
        });
        this.program.saleableItems = items_pricefloat;
      }
      this.$emit("update-program", this.program);
      this.$store.commit("root/loading", false);
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type === "update") {
            // Check for invalid inputs
            if (parseInt(this.currentoption.minAge) > parseInt(this.currentoption.maxAge)) {
              this.$customError(errormsg.max_min);
              return false;
            }
            if (
              Math.floor(parseFloat(this.currentoption.price)) !==
              parseFloat(this.currentoption.price)
            ) {
              const decimalplaces = this.currentoption.price.toString().split(".")[1].length;
              if (decimalplaces > 2) {
                this.$customError(errormsg.price_decimal);
                return false;
              }
            }
            const current_duration = this.currentoption.duration;
            const current_minAge = parseInt(this.currentoption.minAge);
            const current_maxAge = parseInt(this.currentoption.maxAge);
            const current_hideShirtSize = this.currentoption.hideShirtSize;
            const overlappings = this.program.options.filter(
              (o) =>
                current_duration === o.duration &&
                current_minAge <= parseInt(o.maxAge) &&
                parseInt(o.minAge) <= current_maxAge
            );
            if (overlappings && overlappings.length > 0) {
              this.$customError(errormsg.age_overlap);
              return false;
            }
            // Add/update int/float price
            const floatprice = this.currentoption.price;
            this.program.options.push({
              duration: this.currentoption.duration,
              minAge: parseInt(this.currentoption.minAge),
              maxAge: parseInt(this.currentoption.maxAge),
              price: parseFloat(floatprice).toFixed(2),
              price_int: parseInt(parseFloat(floatprice) * 100),
              activeKidsVoucher: this.currentoption.activeKidsVoucher,
              hideShirtSize: current_hideShirtSize,
            });
            // Update program
            this.$store.commit("root/loading", true);
            const update = {
              options: this.program.options.map((o) => ({
                duration: o.duration,
                minAge: parseInt(o.minAge),
                maxAge: parseInt(o.maxAge),
                price: o.price_int,
                activeKidsVoucher: o.activeKidsVoucher,
                hideShirtSize: o.hideShirtSize ? true : false,
              })),
            };

            const url = `/nrl/api/v1/admin/programs-settings/${this.program._id}`;
            this.$http
              .put(url, update)
              .then(() => {
                // Reset Variables
                this.currentoption = {
                  duration: "",
                  minAge: 0,
                  maxAge: 0,
                  price: 0,
                  activeKidsVoucher: false,
                  hideShirtSize: false,
                };
                this.optionsdisabled.push(false);
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.program.options.pop();
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
          return true;
        }
        return false;
      });
    },
    cancelForm() {
      this.$router.push({
        name: "programsettings",
      });
    },
    handleEditRow(rowindex) {
      if (this.optionsdisabled[rowindex]) {
        const row = this.program.options[rowindex];
        if (!parseInt(row.minAge)) {
          this.$customError(errormsg.min_age);
          return false;
        }
        if (!parseInt(row.maxAge)) {
          this.$customError(errormsg.max_age);
          return false;
        }
        if (parseInt(row.maxAge) < parseInt(row.minAge)) {
          this.$customError(errormsg.max_min);
          return false;
        }
        const current_duration = row.duration;
        const current_minAge = parseInt(row.minAge);
        const current_maxAge = parseInt(row.maxAge);
        const overlappings = this.program.options.filter(
          (o, index) =>
            o.duration === current_duration &&
            index !== rowindex &&
            ((current_maxAge > parseInt(o.minAge) && current_maxAge <= parseInt(o.maxAge)) ||
              (current_minAge >= parseInt(o.minAge) && current_minAge < parseInt(o.maxAge)) ||
              (current_minAge <= parseInt(o.minAge) && current_maxAge >= parseInt(o.maxAge)))
        );
        if (overlappings && overlappings.length > 0) {
          this.$customError(errormsg.price_overlap);
          return false;
        }
        if (Math.floor(parseFloat(row.price)) !== parseFloat(row.price)) {
          const decimalplaces = row.price.toString().split(".")[1].length;
          if (decimalplaces > 2) {
            this.$customError(errormsg.price_decimal);
            return false;
          }
        }
        // Update program
        this.program.options[rowindex].price_int = parseInt(parseFloat(row.price) * 100);
        this.$store.commit("root/loading", true);
        const update = {
          options: this.program.options.map((o) => ({
            duration: o.duration,
            minAge: parseInt(o.minAge),
            maxAge: parseInt(o.maxAge),
            price: o.price_int,
            activeKidsVoucher: o.activeKidsVoucher,
            hideShirtSize: o.hideShirtSize,
          })),
        };
        const url = `/nrl/api/v1/admin/programs-settings/${this.program._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
      this.$set(this.optionsdisabled, rowindex, !this.optionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRow(rowindex) {
      this.program.options.splice(rowindex, 1);
      this.optionsdisabled.splice(rowindex, 1);
      this.$store.commit("root/loading", true);
      const update = {
        options: this.program.options.map((o) => ({
          duration: o.duration,
          minAge: parseInt(o.minAge),
          maxAge: parseInt(o.maxAge),
          price: o.price_int,
          activeKidsVoucher: o.activeKidsVoucher,
          hideShirtSize: o.hideShirtSize,
        })),
      };
      const url = `/nrl/api/v1/admin/programs-settings/${this.program._id}`;
      this.$http
        .put(url, update)
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
  },
  computed: {
    // programtype: {
    //   get() {
    //     return this.program.type;
    //   }
    // },
    duration: {
      get() {
        return this.currentoption.duration !== "" ? this.currentoption.duration : null;
      },
      set(value) {
        this.currentoption.duration = parseInt(value);
      },
    },
    minage: {
      get() {
        return this.currentoption.minAge ? this.currentoption.minAge : null;
      },
      set(value) {
        this.currentoption.minAge = parseInt(value);
      },
    },
    maxage: {
      get() {
        return this.currentoption.maxAge ? this.currentoption.maxAge : null;
      },
      set(value) {
        this.currentoption.maxAge = parseInt(value);
      },
    },
    price: {
      get() {
        return this.currentoption.price ? this.currentoption.price : null;
      },
      set(value) {
        this.currentoption.price = value;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}
</style>
