<template>
  <div class="contact">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="status" label="Status">
            <el-select v-model="filters.status" placeholder="Select an status">
              <el-option
                v-for="status in injuryStatus"
                :label="status"
                :key="status"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="dateRange" label="Date Range">
            <el-date-picker
              v-model="filters.dateRange"
              type="daterange"
              range-separator="|"
              start-placeholder="Start date"
              end-placeholder="End date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="7">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item> </el-col
      >
      <el-col :span="5">
          <el-form-item prop="includeDOB" label="Include Date of Birth">
            <el-checkbox v-model="filters.includeDOB" @change="checkDOB">
              Include DOB in report
            </el-checkbox>
          </el-form-item>
      </el-col>  
    </el-row>
    </el-form>
    <el-dialog
      title="Important Information"
      :visible.sync="showDOBInfo"
      width="30%"
      :before-close="closeDOBInfo"
    >
      <span v-html="dobPrivacyPolicy" :style="{'display': 'inline-block', 'wordBreak': 'break-word'}"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDOBInfo">I understand</el-button>
      </span>
    </el-dialog>
    <action-buttons submit submitText="SUBMIT" :cancel="false" @submit="sendReport()" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, injuryStatus, dobPrivacyPolicy } from "../../../utils/constants";

export default {
  name: "InjuryReport",
  components: {
    ActionButtons
  },
  data() {
    return {
      injuryStatus,
      filters: {
        fileType: "csv",
        status: undefined,
        dateRange: null,
        includeDOB: false,
      },
      filterRules: {
        fileType: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        },
        status: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        },
        dateRange: {
          required: true,
          trigger: "blur",
          validator: (obj, val, callback) => {
            if (!val) {
              return callback(new Error(errormsg.select_option));
            }
            return callback();
          }
        }
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      showDOBInfo: false,
      dobPrivacyPolicy
    };
  },
  computed: {},
  methods: {
    sendReport() {
      this.$refs.filters.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "injury-case",
            fileType: this.filters.fileType,
            filters: {
              status: this.filters.status,
              dateRange: this.filters.dateRange
            },
            meta: {
              timezone: moment.tz.guess()
            }
          };
          if (this.filters.includeDOB) {
            body.filters.includeDOB = true;
          }
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then(response => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
    checkDOB() {
      if (this.filters.includeDOB) {
        this.showDOBInfo = true; // Show pop-up when DOB checkbox is checked
      }
    },
    closeDOBInfo() {
      this.showDOBInfo = false; // Close the modal
    },
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-date-editor {
  width: 100%;
}
</style>
