<template>
  <el-row :gutter="30" class="d-flex">
    <el-col :span="12">
      <el-table
        v-if="master.accreditations && master.accreditations.length"
        :data="master.accreditations"
        :border="true"
      >
        <el-table-column prop="skillId" label="Accreditation Id" width="auto"> </el-table-column>
        <el-table-column prop="skillName" label="Name" width="auto"> </el-table-column>
        <el-table-column prop="skillDescription" label="Type" width="auto"> </el-table-column>
        <el-table-column
          :formatter="achievedValueFormatter"
          prop="achievedValue"
          label="Status"
          width="auto"
        >
        </el-table-column>
        <el-table-column prop="expiry" label="Expiry" width="auto"> </el-table-column>
      </el-table>
    </el-col>
    <el-col :span="12">
      <el-table
        v-if="slave.accreditations && slave.accreditations.length"
        :data="slave.accreditations"
        :border="true"
      >
        <el-table-column prop="skillId" label="Accreditation Id" width="auto"> </el-table-column>
        <el-table-column prop="skillName" label="Name" width="auto"> </el-table-column>
        <el-table-column prop="skillDescription" label="Type" width="auto"> </el-table-column>
        <el-table-column
          :formatter="achievedValueFormatter"
          prop="achievedValue"
          label="Status"
          width="auto"
        >
        </el-table-column>
        <el-table-column prop="expiry" label="Expiry" width="auto"> </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    masterId: { type: Number, required: true },
    slaveId: { type: Number, required: true }
  },
  data() {
    return {
      master: {},
      slave: {}
    };
  },
  mounted() {
    const { masterId, slaveId } = this;

    if (masterId) {
      this.$http
        .get(`/nrl/api/v1/admin/members/${masterId}`)
        .then(res => {
          this.master = res.data.data;
        })
        .catch(() => {});
    }

    if (slaveId) {
      this.$http
        .get(`/nrl/api/v1/admin/members/${slaveId}`)
        .then(res => {
          this.slave = res.data.data;
        })
        .catch(() => {});
    }
  },
  methods: {
    achievedValueFormatter(row) {
      if (row.achievedValue !== undefined) {
        switch (true) {
          case _.inRange(row.achievedValue, 0, 1):
            return "Expired";
          case _.inRange(row.achievedValue, 50, 51):
            return "In Training";
          case _.inRange(row.achievedValue, 1, 50):
          case _.inRange(row.achievedValue, 51, 101):
            return "Complete";
          default:
            return "N/A";
        }
      }
      return "N/A";
    }
  }
};
</script>

<style></style>
