<template>
  <div>
    <el-form label-position="top" :rules="rules" ref="comms" :model="comms">
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="6">
          <el-form-item prop="type" label="Comms Type">
            <el-select v-model="comms.type" placeholder="Select comms type" filterable>
              <el-option v-for="item in allComms" :label="item" :key="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="name" label="Comms Name">
            <el-input
              type="text"
              autocomplete="off"
              v-model="comms.name"
              placeholder="Enter comms name"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4" v-if="reminderActive">
          <el-form-item label="Reminder Days">
            <el-input
              autocomplete="off"
              placeholder="in days"
              type="number"
              min="0"
              v-model="comms.reminderDays"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="templateId" label="Third Party Campaign Id">
            <el-input
              autocomplete="off"
              placeholder="for example cdiewb-efwe-cddiwcwdcdw-wcw2"
              type="text"
              v-model="comms.templateId"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitForm('comms')">{{
              optionsdisabled[0] ? "Save" : "Add"
            }}</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <data-tables :data="commsTable" :table-props="tableProps" layout="table" class="data-table">
      <el-table-column prop="type" label="Comms Type" width="160">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Comms Type"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option v-for="item in allComms" :label="item" :key="item" :value="item">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Comms Name">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.name"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="reminderDays" label="Reminder Days">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            min="0"
            v-model="scope.row.reminderDays"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="templateId" label="Third Party Campaign Id">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.templateId"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column
        :formatter="createdByFormatter"
        prop="createdBy"
        label="Created By"
        width="auto"
      >
      </el-table-column>
      <el-table-column :formatter="dateFormat" prop="scope" label="Last Updated" width="auto">
      </el-table-column>

      <el-table-column label="Edit/Remove" fixed="right">
        <template slot-scope="scope">
          <el-button @click="handleEditRow(scope.$index)">
            <template v-if="!optionsdisabled[scope.$index]"> Edit </template>
            <template v-else> Save </template>
          </el-button>
          <el-button @click="handleRemoveRow(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import moment from "moment";
import { errormsg } from "../../utils/constants";

export default {
  name: "ProgramSettingsComms",
  props: ["program"],
  data() {
    const validateType = (rule, value, callback) => {
      if (this.comms.type === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const validateName = (rule, value, callback) => {
      if (this.comms.name === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateTemplateId = (rule, value, callback) => {
      if (!this.comms.templateId) {
        return callback(new Error("Field is required"));
      }
      return callback();
    };
    return {
      commsTable: [],
      comms: {
        type: "",
        name: "",
        reminderDays: 0,
        templateId: "",
        createdBy: {
          firstName: "",
          lastName: "",
          _id: undefined,
        },
        updated: "",
      },
      optionsdisabled: [],
      allComms: ["Registration", "Reminder"],
      rules: {
        type: {
          type: "text",
          required: true,
          validator: validateType,
          trigger: "blur",
        },
        name: {
          type: "text",
          required: true,
          validator: validateName,
          trigger: "blur",
        },
        templateId: {
          type: "text",
          required: true,
          validator: validateTemplateId,
          trigger: "blur",
        },
      },
      tableProps: { border: true },
      programId: undefined,
      createBy: "",
      lastUpdated: "",
    };
  },
  mounted() {
    this.fetchCommsData();
  },
  computed: {
    reminderActive() {
      return this.comms.type === "Reminder" ? true : false;
    },
  },
  methods: {
    async fetchCommsData() {
      this.$store.commit("root/loading", true);
      const { id } = this.$route.params;
      if (id) {
        try {
          const response = await this.$http.get(`/nrl/api/v1/admin/programs-settings/${id}`);
          this.commsTable = response.data.data.comms ? response.data.data.comms : [];
          this.optionsdisabled = new Array(this.commsTable.length).fill(false);
          if (response.data.data) {
            const programSettings = response.data.data;
            const url = `/nrl/api/v1/admin/programs-settings/${programSettings._id}`;
            this.$http
              .put(url, programSettings)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch((e) => {
                this.$store.commit("root/loading", false);
                this.$customError(e);
              });
          }

          this.$store.commit("root/loading", false);
        } catch (error) {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    handleEditRow(rowindex) {
      if (this.optionsdisabled[rowindex]) {
        this.commsTable[rowindex].updated = new Date().valueOf();
        this.$store.commit("root/loading", true);
        this.commsTable[rowindex].reminderDays = this.commsTable[rowindex].reminderDays
          ? parseInt(this.commsTable[rowindex].reminderDays)
          : 0;
        const update = { comms: this.commsTable };
        const templateId = `/nrl/api/v1/admin/programs-settings/${this.program._id}`;
        this.$http
          .put(templateId, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
      this.$set(this.optionsdisabled, rowindex, !this.optionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRow(rowindex) {
      this.commsTable.splice(rowindex, 1);
      this.optionsdisabled.splice(rowindex, 1);
      this.$store.commit("root/loading", true);
      const update = { comms: this.commsTable };
      const templateId = `/nrl/api/v1/admin/programs-settings/${this.program._id}`;
      this.$http
        .put(templateId, update)
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    dateFormat(row) {
      if (row.updated) {
        return moment(row.updated).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
    createdByFormatter(row) {
      if (
        row &&
        row.createdBy &&
        row.createdBy._id &&
        row.createdBy.firstName &&
        row.createdBy.lastName
      ) {
        return `${row.createdBy.firstName} ${row.createdBy.lastName}`;
      } else {
        return "Default";
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const templateId = this.comms.templateId;
          const profile = this.$store.getters["user/profile"];
          const created = {
            firstName: profile.firstName,
            lastName: profile.lastName,
            _id: profile._id,
          };
          this.comms.createdBy = created;
          this.comms.updated = Date.now();
          this.comms.reminderDays = this.comms.reminderDays ? parseInt(this.comms.reminderDays) : 0;
          if (this.commsTable) {
            this.commsTable.push({ ...this.comms, templateId: templateId });
          } else {
            this.commsTable = [{ ...this.comms, templateId: templateId }];
          }

          const update = { comms: this.commsTable };
          const url = `/nrl/api/v1/admin/programs-settings/${this.program._id}`;
          this.$http
            .put(url, update)
            .then(() => {
              // Reset Variables
              this.comms.type = "";
              this.comms.name = "";
              this.comms.reminderDays = 0;
              this.comms.templateId = "";
              this.optionsdisabled.push(false);
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}
</style>
