import _ from 'lodash';

const initState = () => ({
  eighteenmrp: {
    criteria: '',
    filters: {
      status: null,
      season: null,
    },
    data: [],
    pagination: {
      total: 0,
      pageSize: 20,
      currentPage: 1
    },
    params: {
      status: [],
      season: null,
    }
  }
});

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateEighteenMRP: (state, data) => {
      _.assign(state.eighteenmrp, data)
    },
    updateEighteenMRPParams: (state, data) => {
      state.eighteenmrp.params.status = data.status,
      state.eighteenmrp.params.season = data.season
    },
    updateEighteenMRPFilters: (state, payload) => {
      state.eighteenmrp.filters.status = payload.status
      state.eighteenmrp.filters.season = payload.season
    },
    updateData: (state, data) => state.data = data,
    updatePagination: (state, payload) => _.assign(state.pagination, payload.data),
  }
};
