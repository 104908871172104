<template>
  <div>
    <label
      class="tw-w-full el-form-item__label tw-mt-4 tw-pb-0 tw-text-left"
      v-if="regiProduct && regiProduct.length > 0"
      >Additional Questions at {{ heading }} level
    </label>
    <data-tables
      v-if="regiProduct && regiProduct.length > 0"
      :data="regiProduct"
      :table-props="{ border: true }"
      layout="table"
      class="mb-2 data-table"
    >
      <el-table-column label="Type" width="180">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Type"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option
              v-for="e in additionalQTypes"
              :label="e.name"
              :key="e.type"
              :value="e.type"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Report Column Name" width="200">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.fieldName"
            placeholder="E.g: Application Reason"
            :disabled="!editingRows[scope.$index]"
          />
        </template>
      </el-table-column>
      <el-table-column label="Question" width="500">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.question"
            maxlength="40"
            placeholder="E.g: Country / Area of Birth"
            :disabled="!editingRows[scope.$index]"
          />
        </template>
      </el-table-column>
      <el-table-column label="Options" width="300">
        <template v-slot="scope">
          <div v-if="scope.row.type === 2">
            <div v-for="(option, idx) in scope.row.meta.options" :key="idx">
              <div class="flex-row d-flex">
                <el-input
                  class="option"
                  type="text"
                  v-model="option.value"
                  :placeholder="'Option ' + (idx + 1)"
                  :disabled="!editingRows[scope.$index]"
                />
                <el-button
                  class="option-delete"
                  type="text"
                  icon="el-icon-delete"
                  @click="scope.row.meta.options.splice(idx, 1)"
                  v-if="editingRows[scope.$index]"
                />
              </div>
            </div>
            <div v-if="editingRows[scope.$index]">
              <el-button
                type="text"
                icon="el-icon-circle-plus-outline"
                @click="addOption(scope.row.meta.options)"
                >Add Option</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Member Type" width="300">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.memberType"
            placeholder="Member Type"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option v-for="e in memberTypes" :label="e.name" :key="e.type" :value="e.type" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Gender" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.gender"
            placeholder="Gender"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option v-for="e in genders" :label="e" :key="e" :value="e" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Age From" width="110">
        <template v-slot="scope">
          <el-input-number
            :min="1"
            :max="99"
            v-model="scope.row.ageFrom"
            :disabled="!editingRows[scope.$index]"
            controls-position="right"
          />
        </template>
      </el-table-column>
      <el-table-column label="Age To" width="110">
        <template v-slot="scope">
          <el-input-number
            :min="1"
            :max="99"
            v-model="scope.row.ageTo"
            :disabled="!editingRows[scope.$index]"
            controls-position="right"
          />
        </template>
      </el-table-column>
      <el-table-column v-if="isTFA" label="DOB From" width="200">
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.dateFrom"
            type="date"
            placeholder="Select a Date"
            format="dd/MM/yyyy"
            value-format="timestamp"
            :disabled="!editingRows[scope.$index]"
          >
          </el-date-picker>
        </template>
      </el-table-column>
      <el-table-column v-if="isTFA" label="DOB To" width="200">
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.dateTo"
            type="date"
            placeholder="Select a Date"
            format="dd/MM/yyyy"
            value-format="timestamp"
            :disabled="!editingRows[scope.$index]"
          >
          </el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="Required" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.required"
            placeholder="Required"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option :value="true" label="Yes"></el-option>
            <el-option :value="false" label="No"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Active" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.isActive"
            placeholder="Active"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option :value="true" label="Yes"></el-option>
            <el-option :value="false" label="No"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Entity Type" width="120" v-if="clearance == roles.superAdmin">
        <template v-slot="scope">
          <el-select v-model="scope.row.meta.entityType" placeholder="" :disabled="true">
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Entity Ids" width="120" v-if="clearance == roles.superAdmin">
        <template v-slot="scope">
          <p v-if="scope.row.meta && scope.row.meta.entityIds">
            {{ scope.row.meta.entityIds.join(",") }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" fixed="right" width="300" v-if="editData || removeData">
        <template v-slot="scope">
          <el-button class="px-1" @click="edit(scope.$index)" v-if="editData">
            {{ editingRows[scope.$index] ? "Save" : "Edit" }}
          </el-button>
          <el-button class="px-2" @click="remove(scope.$index)" v-if="removeData">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg, genders, additionalQTypes, memberTypes, roles } from "@/utils/constants";

const initAdditionalQuestion = () =>
  _.cloneDeep({
    type: undefined,
    question: undefined,
    fieldName: undefined,
    ageFrom: undefined,
    ageTo: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    gender: undefined,
    memberType: undefined,
    required: undefined,
    isActive: true,
    meta: {
      options: undefined,
      entityType: undefined,
      entityIds: undefined,
    },
  });

const addNewOption = (options) => {
  options.push({ type: "string", value: "" });
};

export default {
  props: ["regiProduct", "heading", "editData", "removeData"],
  data() {
    const defaultRequiredRule = { required: true, trigger: "blur", message: errormsg.input_option };
    const checkOptions = (rule, value, callback) => {
      if (!value || value.length === 0) return callback(new Error("Options is required"));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < value.length; i++) {
        if (value[i].value === "") return callback(new Error("Option value is required."));
      }
      return callback();
    };
    const validateAgeFrom = (rule, value, callback) => {
      if (!this.additionalQuestion.ageFrom && this.additionalQuestion.ageTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateAgeTo = (rule, value, callback) => {
      if (!this.additionalQuestion.ageTo && this.additionalQuestion.ageFrom) {
        return callback(new Error(errormsg.input_option));
      }
      if (parseInt(this.additionalQuestion.ageTo) < parseInt(this.additionalQuestion.ageFrom)) {
        return callback(new Error(errormsg.gte_age));
      }
      return callback();
    };

    const validateDateFrom = (rule, value, callback) => {
      if (!this.additionalQuestion.dateFrom && this.additionalQuestion.dateTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDateTo = (rule, value, callback) => {
      if (!this.additionalQuestion.dateTo && this.additionalQuestion.dateFrom) {
        return callback(new Error(errormsg.input_option));
      }
      if (this.additionalQuestion.dateTo < this.additionalQuestion.dateFrom) {
        return callback(new Error(errormsg.gte_date));
      }
      return callback();
    };
    return {
      memberTypes,
      entities: [],
      roles,
      additionalQuestion: initAdditionalQuestion(),
      additionalQTypes,
      genders,
      rules: {
        type: defaultRequiredRule,
        fieldName: [
          defaultRequiredRule,
          {
            min: 0,
            max: 25,
            message: "Length must be less than 25 chars",
            trigger: "blur",
          },
        ],
        question: defaultRequiredRule,
        memberType: { required: true, trigger: "blur", message: errormsg.input_option },
        gender: { required: true, trigger: "blur", message: errormsg.input_option },
        ageTo: {
          required: !this.$store.getters["user/activeRole"].national_id === 31,
          validator: validateAgeTo,
        },
        ageFrom: {
          required: !this.$store.getters["user/activeRole"].national_id === 31,
          validator: validateAgeFrom,
          message: errormsg.input_option,
        },
        dateTo: {
          required: this.$store.getters["user/activeRole"].national_id === 31,
          validator: validateDateTo,
        },
        dateFrom: {
          required: this.$store.getters["user/activeRole"].national_id === 31,
          validator: validateDateFrom,
          message: errormsg.input_option,
        },
        required: defaultRequiredRule,
        isActive: defaultRequiredRule,
        "meta.options": { trigger: "blur", validator: checkOptions },
        // 'meta.entityType': { trigger: 'blur', required: false, },
        // 'meta.entityIds': { trigger: 'blur', required: false, }
      },
      editingRows: [],
    };
  },
  computed: {
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
  },
  methods: {
    filter: _.debounce(function hello(query) {
      if (query !== "") {
        this.$store.commit("root/loading", true);
        this.$http
          .get("/nrl/api/v1/admin/entity-search", {
            params: { entity: this.additionalQuestion.meta.entityType, name: query },
          })
          .then((res) => {
            this.entities = res.data.data;
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      }
    }, 500),
    isAgeValid(object) {
      if (object.ageTo < object.ageFrom) {
        this.$customError(errormsg.gte_age);
        return false;
      }
      return true;
    },
    isDateValid(object) {
      if (object.dateTo < object.dateFrom) {
        this.$customError(errormsg.gte_date);
        return false;
      }
      return true;
    },
    isColumnNameValid(questions, question) {
      if (_.find(questions, (q) => q.fieldName === question.fieldName)) {
        this.$customError("Report column name already exists");
        return false;
      }
      return true;
    },
    add() {
      const isTFA = !this.$store.getters["user/activeRole"].national_id === 31;
      this.$refs.form.validate((valid) => {
        if (!valid) return;

        // Check if age is valid
        if (!isTFA)
          if (!this.isAgeValid(this.additionalQuestion)) return;
          else if (!this.isDateValid(this.additionalQuestion)) return;

        // Check if report column name exists
        if (!this.isColumnNameValid(this.regiProduct, this.additionalQuestion)) return;

        // Trim
        this.additionalQuestion.fieldName = _.trim(this.additionalQuestion.fieldName);

        // Event Edit
        this.$store.commit("root/loading", true);
        this.$http
          .post(
            "/nrl/api/v1/admin/registration-products/additional-questions",
            this.additionalQuestion
          )
          .then((res) => {
            this.additionalQuestion._id = res.data.data._id;
            this.regiProduct.push(this.additionalQuestion);
            this.additionalQuestion = initAdditionalQuestion();
            this.$customSuccess("Successful");
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      });
    },
    edit(rowIndex) {
      const isTFA = !this.$store.getters["user/activeRole"].national_id === 31;
      if (this.editingRows[rowIndex]) {
        // Save row
        const additionalQuestion = this.regiProduct[rowIndex];
        additionalQuestion.fieldName = _.trim(additionalQuestion.fieldName);

        // Check if age is valid
        if (!isTFA)
          if (!this.isAgeValid(this.additionalQuestion)) return;
          else if (!this.isDateValid(this.additionalQuestion)) return;

        if (!isTFA) {
          delete this.additionalQuestion.dateTo;
          delete this.additionalQuestion.dateFrom;
        }

        // Check if report column name exists
        const checkQs = _.cloneDeep(this.regiProduct);
        _.pullAt(checkQs, [rowIndex]);
        if (!this.isColumnNameValid(checkQs, additionalQuestion)) return false;

        this.$store.commit("root/loading", true);
        this.$http
          .put(
            `/nrl/api/v1/admin/registration-products/additional-questions/${additionalQuestion._id}`,
            additionalQuestion
          )
          .then(() => {
            this.$set(this.editingRows, rowIndex, false);
            this.$customSuccess("Successful");
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
            this.$set(this.editingRows, rowIndex, true);
          });
      } else this.$set(this.editingRows, rowIndex, true);
      return true;
    },
    remove(rowIndex) {
      this.$confirm("Are you sure you want to remove this additional question?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        // Event Edit
        const additionalQuestion = this.regiProduct[rowIndex];
        this.$store.commit("root/loading", true);
        this.$http
          .delete(
            `/nrl/api/v1/admin/registration-products/additional-questions/${additionalQuestion._id}`
          )
          .then(() => {
            this.regiProduct.splice(rowIndex, 1);
            this.$customSuccess("Successful");
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      });
    },
    addOption(options) {
      addNewOption(options);
    },
  },
  watch: {
    // eslint-disable-next-line
    "additionalQuestion.type": function (val) {
      if (val === 2 || val === 4) {
        this.additionalQuestion.meta.options = [];
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
      } else this.additionalQuestion.meta.options = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-input-number {
  width: 100%;
}
</style>
