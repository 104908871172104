<template>
  <div>
    <el-row v-if="currentMember && allowedDispensate">
      <el-form
        label-position="top"
        :model="currentDispensation"
        :rules="dispensationrules"
        ref="currentDispensation"
        class="login-form"
      >
        <el-row type="flex" align="bottom" :gutter="10">
          <el-col :span="8">
            <el-form-item prop="type" label="Dispensation Category">
              <el-select
                v-model="currentDispensation.type"
                placeholder="Dispensation Type"
                filterable
              >
                <el-option
                  v-for="item in dispensationTypes"
                  :label="item.type"
                  :key="item.type"
                  :value="item.type"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="rule" label="Dispensation Rule">
              <el-select
                v-model="currentDispensation.rule"
                placeholder="Dispensation Rule"
                filterable
              >
                <el-option
                  v-for="item in dispensationRules"
                  :label="item.rule"
                  :key="item.rule"
                  :value="item.rule"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="dispensated" label="Dispensation Outcome">
              <el-select
                v-model="currentDispensation.dispensated"
                placeholder="Dispensated"
                filterable
              >
                <el-option
                  v-for="item in [
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]"
                  :label="item.label"
                  :key="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" align="bottom" :gutter="10">
          <el-col :span="24">
            <el-form-item prop="note" label="Decision Note">
              <el-input
                autocomplete="off"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 20 }"
                v-model="currentDispensation.note"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <action-buttons submit @submit="updateMemberDispensation('currentDispensation')" />
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row :gutter="10" v-if="currentMember">
      <el-col :span="8">
        <h3 class="tw-font-bold">Member Dispensations</h3>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-if="currentMember">
      <el-col :span="24">
        <el-table
          v-if="currentMember.dispensations && currentMember.dispensations.length > 0"
          :data="currentMember.dispensations"
          :border="true"
        >
          <el-table-column prop="type" label="Type" width="250"> </el-table-column>
          <el-table-column prop="rule" label="Rule" width="250"> </el-table-column>
          <el-table-column :formatter="dispensatedFormatter" label="Dispensated" width="100">
          </el-table-column>
          <el-table-column prop="season" label="Season" width="100"> </el-table-column>
          <el-table-column prop="note" label="Note" width="auto"> </el-table-column>
          <el-table-column :formatter="dateFormatter" label="Date" width="150"> </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import ActionButtons from "@/components/ActionButtons.vue";
import { EventBus } from "../../bus";
import { roles, dispensationRules, dispensationTypes, errormsg } from "../../utils/constants";

const valValidate = (obj, value, callback) => {
  if (value == null || value === "") {
    return callback(new Error(errormsg.input_option));
  }
  return callback();
};

export default {
  components: { ActionButtons },
  mounted() {
    EventBus.$on("memberUpdated", () => {
      this.$forceUpdate();
    });
  },
  data() {
    return {
      currentDispensation: {
        type: undefined,
        rule: "",
        dispensated: undefined,
        note: undefined,
      },
      dispensationRules,
      dispensationTypes,
      dispensationrules: {
        type: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        rule: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        dispensated: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
      },
    };
  },
  props: {
    member: { type: Object, required: true },
    currentSeason: { type: Number, required: true },
  },
  methods: {
    dateFormatter(row) {
      return row.date ? this.moment(row.date).format("MMMM Do YYYY") : "NA";
    },
    dispensatedFormatter(row) {
      const { state } = row;
      if (row.dispensated == null) return "N/A";
      return state && state === "pending" ? "Pending" : row.dispensated ? "Yes" : "No";
    },
    updateMemberDispensation(formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const dispensation = { ...this.currentDispensation };
          dispensation.date = moment().valueOf();
          dispensation.note =
            this.currentDispensation.note && this.currentDispensation.note !== ""
              ? this.currentDispensation.note
              : "";
          const url = `/nrl/api/v1/admin/members/dispensation/${this.currentMember._id}`;
          this.$http
            .put(url, dispensation)
            .then(() => {
              dispensation.season = this.currentSeason;
              const placeholderIndex = _.findIndex(
                this.currentMember.dispensations,
                (d) => d.type === dispensation.type && d.rule === "To Be Actioned"
              );
              this.currentMember.dispensations.splice(placeholderIndex, 1);
              const index = _.findIndex(
                this.currentMember.dispensations,
                (d) => d.type === dispensation.type && d.rule === dispensation.rule
              );
              if (index !== -1) {
                this.currentMember.dispensations.splice(index, 1, dispensation);
              } else {
                this.currentMember.dispensations.push(dispensation);
              }
              this.currentDispensation = {
                type: undefined,
                rule: undefined,
                dispensated: undefined,
                note: undefined,
              };
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch((e) => {
              this.$store.commit("root/loading", false);
              this.$customError(e.response.data.message);
            });
        }
      });
    },
  },
  computed: {
    currentMember() {
      return this.member || { dispensations: [] };
    },
    allowedDispensate() {
      const { type } = this.$store.getters["user/activeRole"];
      return type <= roles.associationAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select,
.el-input {
  width: 100%;
}
</style>
