var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"regrading",attrs:{"model":_vm.regrading,"label-position":"top"}},[_c('div',{staticClass:"tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Source Competition"}},[_c('el-input',{attrs:{"disabled":"","type":"text"},model:{value:(_vm.regrading.sourceCompetition.name),callback:function ($$v) {_vm.$set(_vm.regrading.sourceCompetition, "name", $$v)},expression:"regrading.sourceCompetition.name"}})],1)],1)],1),_c('div',{staticClass:"tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Team to Regrade"}},[_c('el-input',{attrs:{"disabled":"","type":"text"},model:{value:(_vm.regrading.regradeTeam.name),callback:function ($$v) {_vm.$set(_vm.regrading.regradeTeam, "name", $$v)},expression:"regrading.regradeTeam.name"}})],1)],1)],1),_c('div',{staticClass:"tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Destination Competition"}},[_c('el-input',{attrs:{"disabled":"","type":"text"},model:{value:(_vm.regrading.destCompetition.name),callback:function ($$v) {_vm.$set(_vm.regrading.destCompetition, "name", $$v)},expression:"regrading.destCompetition.name"}})],1)],1)],1),_c('div',{staticClass:"tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Team Designation in Destination Competition"}},[_vm._l((_vm.designationOptions),function(ref){
var type = ref.type;
var name = ref.name;
return _c('div',{key:type},[_c('el-radio',{attrs:{"label":type,"disabled":""},model:{value:(_vm.regrading.designationOption),callback:function ($$v) {_vm.$set(_vm.regrading, "designationOption", $$v)},expression:"regrading.designationOption"}},[_vm._v(_vm._s(name))])],1)}),_c('el-form-item',{attrs:{"prop":"destTeamId"}},[_c('el-input',{attrs:{"disabled":"","type":"text","placeholder":"Select team to replace from destination competition"},model:{value:(_vm.regrading.replaceTeam.name),callback:function ($$v) {_vm.$set(_vm.regrading.replaceTeam, "name", $$v)},expression:"regrading.replaceTeam.name"}})],1)],2)],1)],1),(_vm.regrading.designationOption === 'new')?_c('div',{staticClass:"tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Destination Competition Matches"}},_vm._l((_vm.destMatchOptions),function(ref){
var type = ref.type;
var name = ref.name;
return _c('div',{key:type},[_c('el-radio',{attrs:{"label":type,"disabled":""},model:{value:(_vm.regrading.destMatchOption),callback:function ($$v) {_vm.$set(_vm.regrading, "destMatchOption", $$v)},expression:"regrading.destMatchOption"}},[_vm._v(_vm._s(name))])],1)}),0)],1)],1):_vm._e(),(_vm.regrading.designationOption === 'new')?_c('div',{staticClass:"tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Source Competition Matches"}},_vm._l((_vm.sourceMatchOptions),function(ref){
var type = ref.type;
var name = ref.name;
return _c('div',{key:type},[_c('el-radio',{attrs:{"label":type,"disabled":""},model:{value:(_vm.regrading.sourceMatchOption),callback:function ($$v) {_vm.$set(_vm.regrading, "sourceMatchOption", $$v)},expression:"regrading.sourceMatchOption"}},[_vm._v(_vm._s(name))])],1)}),0)],1)],1):_vm._e(),_c('div',{staticClass:"tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Ladder Options"}},_vm._l((_vm.ladderOptions),function(ref){
var type = ref.type;
var name = ref.name;
return _c('div',{key:type},[_c('el-radio',{attrs:{"label":type,"disabled":""},model:{value:(_vm.regrading.ladderOption),callback:function ($$v) {_vm.$set(_vm.regrading, "ladderOption", $$v)},expression:"regrading.ladderOption"}},[_vm._v(_vm._s(name))])],1)}),0)],1)],1),_c('div',{staticClass:"tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Regraded By"}},[_c('el-input',{attrs:{"disabled":"","type":"text"},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})],1)],1)],1),_c('div',{staticClass:"tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Regraded At"}},[_c('el-input',{attrs:{"formatter":_vm.dateFormatter,"disabled":"","type":"text","value":_vm.dateFormatter(_vm.regrading.createdAt)}})],1)],1)],1),_c('action-buttons',{attrs:{"cancel":"","cancelText":"Back"},on:{"cancel":function($event){return _vm.cancelForm()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }