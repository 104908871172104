<template>
  <div class="admin-users">
    <el-row>
      <el-col :span="18" class="btn-container mb-2">
        <h3 v-if="!!title">{{ title }}</h3>
      </el-col>
      <el-col :span="24" class="btn-container mb-2">
        <el-row>
          <el-button @click="hideMatchesReport" style="float:right">Back</el-button>
        </el-row>
      </el-col>
    </el-row>
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="season" label="Season">
            <el-select
              @change="updateCompetitions"
              v-model="filters.season"
              placeholder="Select a season"
            >
              <el-option
                v-for="season in seasons"
                :label="season.name"
                :key="season.value"
                :value="season.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="selectedCompetition" label="Competition">
            <el-select
              filterable
              v-model="filters.selectedCompetition"
              placeholder="Select a competition"
            >
              <el-option
                v-for="entity in competitions"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, mysidelineSeasons, roles } from "@/utils/constants";

export default {
  name: "RegradingMatchesReport",
  components: {
    ActionButtons
  },
  props: {
    title: String
  },
  mounted() {
    if (this.clearance <= roles.associationAdmin) {
      this.$store.commit("root/loading", true);
      this.$http
        .get("/nrl/api/v1/admin/competitions")
        .then(res => {
          this.$store.commit("root/loading", false);
          this.competitions = res.data.data;
          //   if (
          //     this.competitionId &&
          //     this.competitions.map(c => c._id).includes(this.competitionId)
          //   ) {
          //     this.$set(this.filters, "selectedCompetition", this.competitionId);
          //   }
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError("Unable to fetch competitions");
        });
    }
  },
  data() {
    return {
      competitions: [],
      filters: {
        fileType: "csv",
        selectedCompetition: null,
        season: null
      },
      filterRules: {
        selectedCompetition: {
          required: true,
          trigger: "blur",
          message: errormsg.select_option
        }
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }]
    };
  },
  computed: {
    seasons() {
      return mysidelineSeasons.map(v => ({ name: v.toString(), value: v }));
    }
  },
  methods: {
    updateCompetitions(season) {
      this.$store.commit("root/loading", true);
      if (this.clearance > roles.associationAdmin) return this.$store.commit("root/loading", false);
      let url = "/nrl/api/v1/admin/competitions?season=";
      url += season;
      this.$http
        .get(url)
        .then(res => {
          this.competitions = res.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError("Unable to fetch competitions");
        });
    },
    sendReport() {
      this.$refs.filters.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "matches",
            fileType: this.filters.fileType,
            filters: {
              competitionId: this.filters.selectedCompetition,
              season: this.filters.season
            },
            meta: {
              timezone: moment.tz.guess()
            }
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then(response => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
    hideMatchesReport() {
      this.$emit("showMatchesReport", false);
    }
  }
};
</script>
