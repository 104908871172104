<template>
  <div>
    <el-form label-position="top" :rules="rules" ref="asset" :model="asset">
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="6">
          <el-form-item prop="type" label="Asset Type">
            <el-select v-model="asset.type" placeholder="Select asset type" filterable>
              <el-option v-for="item in allAssets" :label="item" :key="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="name" label="Asset Name">
            <el-input
              type="text"
              autocomplete="off"
              v-model="asset.name"
              placeholder="Enter asset name"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="url" label="URL">
            <el-input
              autocomplete="off"
              placeholder="for example www.playrugbyleague.com"
              type="text"
              v-model="asset.url"
            >
              <template slot="prepend">https://</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitForm">{{
              optionsdisabled[0] ? "Save" : "Add"
            }}</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <label
      v-if="programSetting && programSetting.assets && programSetting.assets.length > 0"
      class="tw-w-full el-form-item__label tw-mt-4 tw-pb-0"
      >Program Type Settings Assets</label
    >
    <data-tables
      v-if="programSetting && programSetting.assets && programSetting.assets.length > 0"
      :data="programSetting.assets"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column prop="type" label="Asset Type" width="160">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Asset Type"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option v-for="item in allAssets" :label="item" :key="item" :value="item">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Asset Name">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.name"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="URL">
        <template slot-scope="scope">
          <a
            :href="scope.row.url"
            target="_blank"
            class="tw-text-green-600 tw-underline hover:tw-text-green-900"
            >{{ scope.row.url }}</a
          >
        </template>
      </el-table-column>

      <el-table-column
        :formatter="createdByFormatter"
        prop="createdBy"
        label="Created By"
        width="auto"
      >
      </el-table-column>
      <el-table-column :formatter="dateFormat" prop="scope" label="Last Updated" width="auto">
      </el-table-column>
    </data-tables>
    <label
      v-if="assetTable && assetTable.length > 0"
      class="tw-w-full el-form-item__label tw-mt-4 tw-pb-0"
      >Program Assets</label
    >
    <data-tables
      v-if="assetTable && assetTable.length > 0"
      :data="assetTable"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column prop="type" label="Asset Type" width="160">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Asset Type"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option v-for="item in allAssets" :label="item" :key="item" :value="item">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Asset Name">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.name"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="URL">
        <template slot-scope="scope">
          <a
            :href="scope.row.url"
            target="_blank"
            class="tw-text-green-600 tw-underline hover:tw-text-green-900"
            >{{ scope.row.url }}</a
          >
        </template>
      </el-table-column>

      <el-table-column
        :formatter="createdByFormatter"
        prop="createdBy"
        label="Created By"
        width="auto"
      >
      </el-table-column>
      <el-table-column :formatter="dateFormat" prop="scope" label="Last Updated" width="auto">
      </el-table-column>

      <el-table-column label="Edit/Remove" fixed="right">
        <template slot-scope="scope">
          <el-button @click="handleEditRow(scope.$index)">
            <template v-if="!optionsdisabled[scope.$index]"> Edit </template>
            <template v-else> Save </template>
          </el-button>
          <el-button @click="handleRemoveRow(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import moment from "moment";
import { errormsg } from "../../utils/constants";

export default {
  name: "ProgramAssets",
  props: ["program", "programSetting"],
  data() {
    const validateType = (rule, value, callback) => {
      if (this.asset.type === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const validateName = (rule, value, callback) => {
      if (this.asset.name === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateUrl = (rule, value, callback) => {
      if (typeof this.asset.url === "string" && this.asset.url.toLowerCase().includes("://"))
        return callback(new Error("Please do not type https:// as that is already included."));
      if (!this.asset.url) {
        return callback(new Error("Field is required"));
      }
      return callback();
    };
    return {
      assetTable: [],
      asset: {
        type: "",
        name: "",
        url: "",
        createdBy: {
          firstName: "",
          lastName: "",
          _id: undefined,
        },
        updated: "",
      },
      optionsdisabled: [],
      allAssets: [
        "Poster",
        "Social Media",
        "Survey",
        "Website",
        "Logo",
        "Equipment",
        "Video",
        "Education",
        "QR_Code",
        "Imagery",
        "Other",
      ],
      rules: {
        type: {
          type: "text",
          required: true,
          validator: validateType,
          trigger: "blur",
        },
        name: {
          type: "text",
          required: true,
          validator: validateName,
          trigger: "blur",
        },
        url: {
          type: "text",
          required: true,
          validator: validateUrl,
          trigger: "blur",
        },
      },
      tableProps: { border: true },
      programId: undefined,
      createBy: "",
      lastUpdated: "",
    };
  },
  mounted() {
    this.fetchAssetData();
  },
  methods: {
    async fetchAssetData() {
      this.$store.commit("root/loading", true);
      const { id } = this.$route.params;
      if (id) {
        try {
          const response = await this.$http.get(`/nrl/api/v1/admin/programs/${id}`);
          const assetsData = response.data.data;
          if (assetsData && assetsData.assets && assetsData.assets.length > 0) {
            this.assetTable = assetsData.assets;
            this.optionsdisabled = new Array(this.assetTable.length).fill(false);
          } else {
            this.assetTable = [];
          }
          this.$store.commit("root/loading", false);
        } catch (error) {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    handleEditRow(rowindex) {
      if (this.optionsdisabled[rowindex]) {
        this.assetTable[rowindex].updated = new Date().valueOf();
        this.$store.commit("root/loading", true);
        const update = { assets: this.assetTable };
        const url = `/nrl/api/v1/admin/programs/${this.program._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      }
      this.$set(this.optionsdisabled, rowindex, !this.optionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRow(rowindex) {
      this.assetTable.splice(rowindex, 1);
      this.optionsdisabled.splice(rowindex, 1);
      this.$store.commit("root/loading", true);
      const update = { assets: this.assetTable };
      const url = `/nrl/api/v1/admin/programs/${this.program._id}`;
      this.$http
        .put(url, update)
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    dateFormat(row) {
      if (row.updated) {
        return moment(row.updated).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
    createdByFormatter(row) {
      if (
        row &&
        row.createdBy &&
        row.createdBy._id &&
        row.createdBy.firstName &&
        row.createdBy.lastName
      ) {
        return `${row.createdBy.firstName} ${row.createdBy.lastName}`;
      } else {
        return "NA";
      }
    },
    submitForm() {
      this.$refs.asset.validate(async (valid) => {
        if (valid) {
          const urlLink = `https://${this.asset.url}`;
          const profile = this.$store.getters["user/profile"];
          const created = {
            firstName: profile.firstName,
            lastName: profile.lastName,
            _id: profile._id,
          };
          this.asset.createdBy = created;
          this.asset.updated = Date.now();
          if (this.assetTable) {
            this.assetTable.push({ ...this.asset, url: urlLink });
          } else {
            this.assetTable = [{ ...this.asset, url: urlLink }];
          }
          const update = { assets: this.assetTable };
          const url = `/nrl/api/v1/admin/programs/${this.program._id}`;
          this.$http
            .put(url, update)
            .then(() => {
              // Reset Variables
              this.asset.type = "";
              this.asset.name = "";
              this.asset.url = "";
              this.optionsdisabled.push(false);
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}
</style>
