<template>
  <div class="find-member">
    <el-row :gutter="10">
       <el-form :model="searchUser" :rules="rules" @submit.native.prevent ref="userSearch">
        <el-col :span="6">
         <el-form-item prop="search">
            <el-input
              autocomplete="off"
              size="large"
              v-model="searchUser.search"
              placeholder="Email Address"
              type="email"
              @keyup.enter="findUser"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button @click="findUser" native-type="submit" type="success">Search</el-button>
          </el-form-item>
        </el-col>
       </el-form>
    </el-row>
    <el-row>
      <data-tables
      :data="tableData"
      :table-props="tableProps"
      layout="table"
      @row-click="handleRowClick"
      class="data-table">
        <el-table-column
          fixed
          prop="_id"
          label="Id">
        </el-table-column>
        <el-table-column
          fixed
          prop="profile.firstName"
          label="First Name">
        </el-table-column>
        <el-table-column
          fixed
          prop="profile.lastName"
          label="Last Name">
        </el-table-column>
        <el-table-column
          fixed
          prop="profile.email"
          label="Email">
        </el-table-column>
      </data-tables>
    </el-row>
  </div>
</template>

<script>
// import _ from 'lodash';
import {
  orgtree, nationals, roles, errormsg
} from '../../utils/constants';

export default {
  name: 'UserManagement',
  props: {
  },
  data() {
    return {
      isProfileUpdating: false,
      roles,
      searchUser: {
        search: this.$store.state.users.search,
      },
      rules: {
        search: {
          type: 'email',
          required: true,
          message: errormsg.email,
          trigger: 'blur',
        },
      },
      tableProps: {
        border: true,
      },
      tableData: [],
      orgtree,
      nationals,
    };
  },
  mounted() {
    this.fetchStore();
  },
  computed: {
    activeRoleNational() {
      return this.$store.getters['user/activeRole'].national_id;
    },
  },
  methods: {
    fetchStore() {
      const { data } = this.$store.state.users;
      if (data.length) {
        this.tableData = data;
        return true;
      }
      return false;
    },
    findUser() {
      this.$refs.userSearch.validate().then((val) => {
        if (val) {
          // store query values
          this.$store.commit('users/updateSearch', this.searchUser.search);

          this.$store.commit('app/loading', true);
          this.$http
            .post('/nrl/api/v1/admin/users/search', { email: (this.searchUser.search).toLowerCase() })
            .then((response) => {
              this.$store.commit('app/loading', false);
              this.tableData = response.data.data;
              this.$store.commit('users/updateData', this.tableData);
            })
            .catch(() => {
              this.$store.commit('app/loading', false);
              this.$customError();
            });
        }
      }).catch(() => {
      });
    },
    handleRowClick(row) {
      const selectedUser = this.tableData.find(user => user._id === row._id);
      this.$router.push({
        name: 'userManagement.details',
        params: {
          id: row._id,
          user: selectedUser
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .text-right {
    text-align: right;
  }

  .entity {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
</style>
