<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-table
          :data="memberClearances"
          :border="true"
          scrollY
          :maxHeight="innerHeight"
          @current-change="handleCurrentChange"
          highlight-current-row
        >
          <el-table-column label="ID" width="200">
            <template slot-scope="scope">
              <span class="clickable-text">{{ getClearanceId(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Year" width="200">
            <template slot-scope="scope">
              <span>{{ getClearanceYear(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Type" width="200">
            <template slot-scope="scope">
              <span>{{ getClearanceType(scope.row.type) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Member Type" prop="memberType" width="200"> </el-table-column>
          <el-table-column label="From Club" prop="fromClub.name" width="200"> </el-table-column>
          <el-table-column
            label="From Association"
            prop="fromClub.orgtree.association.name"
            width="200"
          >
          </el-table-column>
          <el-table-column label="To Club" prop="toClub.name" width="200"> </el-table-column>
          <el-table-column
            label="To Association"
            prop="toClub.orgtree.association.name"
            width="200"
          >
          </el-table-column>
          <el-table-column label="Reason" prop="reason" width="200"> </el-table-column>
          <el-table-column label="Permit Type" prop="meta.permitType" width="200">
          </el-table-column>
          <el-table-column
            :formatter="startDateFormatter"
            label="Start Date"
            prop="startDate"
            width="200"
          >
          </el-table-column>
          <el-table-column
            :formatter="endDateFormatter"
            label="End Date"
            prop="endDate"
            width="200"
          >
          </el-table-column>
          <el-table-column
            :formatter="finalizeDateFormatter"
            label="Finalized Date"
            prop="meta.finalizedDate"
            width="200"
          >
          </el-table-column>
          <el-table-column
            :formatter="statusFormatter"
            label="Status"
            prop="approval.status"
            width="200"
          >
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { EventBus } from "../../bus";

export default {
  props: {
    clearances: { type: Array, required: true },
    member: { type: Object, required: true },
  },
  data() {
    return {
      innerHeight: window.innerHeight - 286 < 286 ? 286 : window.innerHeight - 250,
    };
  },
  mounted() {
    EventBus.$on("memberUpdated", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    getClearanceYear(row) {
      return row.meta && row.meta.clearanceYear
        ? row.meta.clearanceYear
        : this.moment(row.createdAt).format("MMMM Do YYYY");
    },
    getClearanceId(row) {
      return row.meta && row.meta.legacyClearanceId ? row.meta.legacyClearanceId : row._id;
    },
    getClearanceType(type) {
      return type === 1 ? "Permit" : "Clearance";
    },
    statusFormatter(row) {
      const { status } = row.approval;
      return `${status.charAt(0).toUpperCase()}${status.slice(1)}`;
    },
    finalizeDateFormatter(row) {
      if (row.meta && row.meta.finalizedDate) {
        return this.moment(row.meta.finalizedDate).format("MMMM Do YYYY");
      }
      return "NA";
    },
    startDateFormatter(row) {
      if (row.startDate) {
        return this.moment(row.startDate).format("MMMM Do YYYY");
      }
      return "NA";
    },
    endDateFormatter(row) {
      if (row.endDate) {
        return this.moment(row.endDate).format("MMMM Do YYYY");
      }
      return "NA";
    },
    handleCurrentChange(row) {
      row.member = this.memberObj;
      if (!row.member._id || !row.member.profile)
        return this.$router.push({
          name: "clearance-permit.list",
        });
      this.$router.push({
        name: "clearance-permit.update",
        params: {
          type: "update",
          clearancePermit: row,
          id: row._id,
        },
      });
    },
  },
  computed: {
    memberClearances() {
      return this.clearances || [];
    },
    memberObj() {
      const { _id, profile } = this.member;
      const member = { _id, profile };
      return member;
    },
  },
};
</script>

<style lang="scss" scoped>
.headers {
  margin-bottom: 1rem;
}

.add-to-club {
  margin-bottom: 1rem;
}

.el-dialog__body {
  .add-to-club-box {
    height: 365px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .operations {
      margin-top: 1.75rem;
      margin-bottom: 1.55rem;
    }
  }
}

.el-dialog__header {
  padding-bottom: 0px;
}

.centered-title {
  width: 100%;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
}

.clickable-text {
  color: #1a0dab;
  cursor: pointer;
}
</style>
