<template>
  <div>
    <h4 class="tw-py-4 tw-font-bold">Team Name - {{ team.name }}</h4>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="24" class="add-scoring">
        <el-form label-position="top" :model="add" :rules="scoringRules" ref="scoringRules">
          <el-form-item prop="playerid">
            <el-select v-model="add.playerid" placeholder="Player" clearable filterable>
              <el-option
                v-for="item in availablePlayersToAdd"
                :key="item.name"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="event">
            <el-select v-model="add.event" placeholder="Scoring event" clearable filterable>
              <el-option
                v-for="item in scoringEvents"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="min">
            <el-input
              autocomplete="off"
              type="number"
              v-model.number="add.min"
              placeholder="Min"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sec">
            <el-input
              autocomplete="off"
              type="number"
              v-model.number="add.sec"
              placeholder="Sec"
            ></el-input>
          </el-form-item>
          <el-form-item prop="period" v-if="competition && competition.meta.elite">
            <el-select v-model="add.period" placeholder="Period" clearable filterable>
              <el-option
                v-for="item in gamePeriods"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="handleAddScoring"> Add </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <data-tables
        v-if="allScoringEvents.length > 0"
        :data="allScoringEvents"
        :table-props="tableProps"
        :page-size="pageSize"
        @row-click="handleRemoveScoring"
        id="np-teamlist"
        class="data-table"
      >
        <el-table-column fixed prop="playerName" label="Player"> </el-table-column>
        <el-table-column fixed prop="meta.player.position.name" label="Position"> </el-table-column>
        <el-table-column prop="name" label="Scoring Type"> </el-table-column>
        <el-table-column prop="value" label="Points"> </el-table-column>
        <el-table-column prop="min" label="Minute"> </el-table-column>
        <el-table-column prop="sec" label="Second"> </el-table-column>
        <el-table-column align="center" prop="" label="Actions">
          <template>
            <i class="el-icon-delete remove-icon"></i>
          </template>
        </el-table-column>
      </data-tables>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { errormsg, nrlScoring, tfaScoring, gamePeriods } from "../../utils/constants";

const formatWithName = (pl) => {
  const preferFirstName = pl.preferNameStatus ? ( pl.preferFirstName || pl.firstName ) : pl.firstName;
  const preferLastName =  pl.preferNameStatus ? (pl.preferLastName || pl.lastName) : pl.lastName;
  const name =`${preferFirstName} ${preferLastName}`;
  return { ...pl, name };
};

export default {
  name: "Scoring",
  props: {
    team: Object,
    teamid: Number,
    matchid: Number,
    isHomeTeam: Boolean,
    competitionId: Number
  },
  data() {
    return {
      add: {
        playerid: null,
        event: null,
        min: null,
        sec: null,
        period: undefined
      },
      scoringRules: {
        playerid: { required: false, message: errormsg.select_option, trigger: "blur" },
        event: { required: true, message: errormsg.select_option, trigger: "blur" },
        period: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        }
      },
      tableProps: {
        border: true
      },
      pageSize: 100,
      nrlScoring,
      tfaScoring,
      gamePeriods,
      competition: null
    };
  },
  mounted() {
    if(this.$store.getters["user/activeRole"].national_id === 31){
      this.add.event = this.tfaScoring[0].type;
    }
    this.$store.commit("root/loading", true);
    this.$http
      .get(`/nrl/api/v1/admin/competitions/${this.competitionId}`)
      .then(res => {
        this.competition = res.data.data;

        // if the competition is elite so player is marked as required in scoring fields.
        if (this.competition.meta.elite) {
          this.scoringRules.playerid.required = true;
        }

        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  created() {
    const { matchid, isHomeTeam } = this;
    this.$store.commit("root/loading", true);
    if (matchid) {
      this.$store
        .dispatch("match/getScorings", { isHomeTeam, matchid })
        .then(() => {
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }
  },
  methods: {
    ...mapMutations("match", ["changeScore", "addScoringEvent", "removeScoringEvent"]),
    handleAddScoring() {
      new Promise((resolve, reject) => {
        this.$refs.scoringRules.validate(valid => (valid ? resolve() : reject()));
      })
        .then(() => {
          this.$store.commit("root/loading", true);
          const { playerid, event, min, sec, period } = this.add;
          const { team, matchid, isHomeTeam } = this;
          const eventInfo = this.scoringEvents.find(e => e.type === event);
          const player = this.availablePlayers.find(p => p._id === playerid);
          const preferFirstName = player.preferNameStatus ? (player.preferFirstName || player.firstName) : player.firstName;
          const preferLastName = player.preferNameStatus ? (player.preferLastName || player.lastName) : player.lastName;
          const playerName = `${preferFirstName} ${preferLastName}`;
          const scoringEvent = {
            ...eventInfo,
            teamId: team._id,
            isHomeTeam,
            min: min || 0,
            sec: sec || 0,
            meta: {
              player,
              period
            }
          };
          this.$http
            .post(`/nrl/api/v1/admin/matches/scorings/${matchid}`, scoringEvent)
            .then(response => {
              this.$customSuccess();
              scoringEvent._id = response.data.data;
              this.allScoringEvents.push({ ...scoringEvent, playerName });
              this.addScoringEvent(scoringEvent);
              this.changeScore({ isHomeTeam, score: scoringEvent.value, add: true });
              this.$store.commit("root/loading", false);
              if(!this.isTFA){
                this.add = {
                  playerid: null,
                  event: null,
                  min: null,
                  sec: null,
                  period: undefined
                };
              }
            })
            .catch(() => {
              this.$customError();
              this.$store.commit("root/loading", false);
            });
        })
        .catch(() => {});
    },
    handleRemoveScoring(row) {
      const { matchid } = this;
      this.$confirm("Are you sure you want to remove this scoring event?", "Remove Scoring Event", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      })
        .then(() => {
          this.$store.commit("root/loading", true);
          this.$http
            .delete(`/nrl/api/v1/admin/matches/scorings/${matchid}`, { data: { eventid: row._id } })
            .then(() => {
              const { isHomeTeam } = this;
              const { value } = row;
              this.allScoringEvents.splice(
                this.allScoringEvents.findIndex(e => e._id === row._id),
                1
              );
              this.removeScoringEvent(row._id);
              this.changeScore({ isHomeTeam, score: value, add: false });
              this.$customSuccess();
              this.$store.commit("root/loading", false);
            })
            .catch(() => {
              this.$customError();
              this.$store.commit("root/loading", false);
            });
        })
        .catch(() => {});
    }
  },
  computed: {
    allScoringEvents: {
      get() {
        return this.$store.getters["match/scorings"](this.isHomeTeam);
      },
      set(data) {
        this.$store.commit("match/setScoring", { isHomeTeam: this.isHomeTeam, data });
      }
    },
    availablePlayers() {
      return this.$store.getters["match/teamlist"](this.isHomeTeam);
    },
    availablePlayersToAdd() {
      return this.availablePlayers.map(p => formatWithName(p));
    },
    scoringEvents() {
      return this.$store.getters["user/activeRole"].national_id === 32
        ? this.nrlScoring
        : this.tfaScoring;
    },
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
  },
  watch: {}
};
</script>

<style scoped lang="scss">
.add-scoring {
  .el-form {
    .el-form-item {
      max-width: 22%;
      float: left;
      padding-right: 1rem;
    }
    .el-button {
      width: 12.5rem;
    }
  }
  margin-bottom: 1.5rem;
}
.el-col-6 {
  text-align: right;
}
.data-table {
  margin-bottom: 2rem !important;
}

.remove-icon {
  color: red;
  font-size: 2rem;
}
</style>