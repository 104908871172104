<template>
  <div>
    <el-form label-position="top" :model="regiProduct" :rules="discountrules" ref="regidiscounts">
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="discounttype" label="Discount type">
            <el-select v-model="discounttype" placeholder="Discount type" filterable>
              <el-option v-for="item in allDiscountTypes" :label="item" :key="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discountname" label="Discount name">
            <el-input
              type="text"
              autocomplete="off"
              v-model="discountname"
              placeholder="Discount name"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discountmembertype" label="Discount member type">
            <el-select v-model="discountmembertype" placeholder="Discount member type" filterable>
              <el-option
                v-for="item in allDiscountMemberTypes"
                :label="item.name"
                :key="item.type"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="discountdatefrom" label="Discount from">
            <el-date-picker
              format="dd/MM/yyyy"
              type="date"
              v-model="discountdatefrom"
              placeholder="Discount from date"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discountdateto" label="Discount to">
            <el-date-picker
              format="dd/MM/yyyy"
              type="date"
              v-model="discountdateto"
              placeholder="Discount to date"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discountamount" label="Discount amount">
            <el-input
              type="text"
              autocomplete="off"
              v-model="discountamount"
              placeholder="Discount amount"
            />
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitDiscount('regidiscounts')">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <data-tables
      v-if="regiProduct.discounts.length > 0"
      :data="regiProduct.discounts"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="Type" width="90">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Type"
            :disabled="!discountoptionsdisabled[scope.$index]"
            filterable
          >
            <el-option v-for="item in allDiscountTypes" :label="item" :key="item" :value="item">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Discount Name" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.discountName"
            :disabled="!discountoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Discount Amount" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.discountAmount"
            :disabled="!discountoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Discount From" width="170">
        <template slot-scope="scope">
          <el-date-picker
            format="dd/MM/yyyy"
            type="date"
            v-model="scope.row.discountFrom"
            placeholder="Discount from date"
            value-format="timestamp"
            :disabled="!discountoptionsdisabled[scope.$index]"
            :clearable="false"
          />
        </template>
      </el-table-column>
      <el-table-column label="Discount To" width="170">
        <template slot-scope="scope">
          <el-date-picker
            format="dd/MM/yyyy"
            type="date"
            v-model="scope.row.discountTo"
            placeholder="Discount to date"
            value-format="timestamp"
            :disabled="!discountoptionsdisabled[scope.$index]"
            :clearable="false"
          />
        </template>
      </el-table-column>
      <el-table-column label="Member Type" width="140">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.discountMemberType"
            placeholder="Member Type"
            :disabled="!discountoptionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="item in allDiscountMemberTypes"
              :label="item.name"
              :key="item.type"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" width="300">
        <template slot-scope="scope">
          <el-button @click="handleEditRowDiscount(scope.$index)">
            <template v-if="!discountoptionsdisabled[scope.$index]"> Edit </template>
            <template v-else> Save </template>
          </el-button>
          <el-button @click="handleRemoveRowDiscount(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { errormsg, memberTypes, roles } from "../../utils/constants";
import dayjs from "dayjs";

export default {
  name: "DiscountsDateRange",
  props: [],
  data() {
    const validateDiscountType = (rule, value, callback) => {
      if (this.currentDiscount.type == null) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountName = (rule, value, callback) => {
      if (!this.currentDiscount.discountName || this.currentDiscount.discountName === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountFrom = (rule, value, callback) => {
      if (!this.currentDiscount.discountFrom) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountTo = (rule, value, callback) => {
      if (!this.currentDiscount.discountTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountAmount = (rule, value, callback) => {
      if (
        !this.currentDiscount.discountAmount ||
        !parseFloat(this.currentDiscount.discountAmount) ||
        parseFloat(this.currentDiscount.discountAmount) <= 0
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountMemberType = (rule, value, callback) => {
      if (!this.currentDiscount.discountMemberType) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };

    return {
      regiProduct: {
        _id: "",
        entity_type: "",
        entity_id: -1,
        products: [],
        discounts: [],
        familyDiscounts: [],
        saleableitems: [],
        competitionProducts: [],
        competitionSaleableItems: [],
        competitionDiscounts: [],
        additionalQuestions: [],
        competitionAdditionalQuestions: [],
      },
      currentDiscount: {
        type: null,
        discountName: null,
        discountFrom: null,
        discountTo: null,
        discountAmount: null,
        discountMemberType: null,
        discountMemberType: null,
      },
      discountoptionsdisabled: [],
      allDiscountTypes: ["$", "%"],
      discountrules: {
        discounttype: {
          required: true,
          validator: validateDiscountType,
          message: errormsg.select_option,
          trigger: "change",
          trigger: "change",
        },
        discountname: {
          required: true,
          validator: validateDiscountName,
          message: errormsg.input_option,
          trigger: "blur",
          trigger: "blur",
        },
        discountdatefrom: {
          required: true,
          validator: validateDiscountFrom,
          message: errormsg.input_option,
          trigger: "blur",
          trigger: "blur",
        },
        discountdateto: {
          required: true,
          validator: validateDiscountTo,
          message: errormsg.input_option,
          trigger: "blur",
          trigger: "blur",
        },
        discountamount: {
          required: true,
          validator: validateDiscountAmount,
          message: errormsg.input_option,
          trigger: "blur",
          trigger: "blur",
        },
        discountmembertype: {
          required: true,
          validator: validateDiscountMemberType,
          message: errormsg.select_option,
          trigger: "change",
        },
      },
      tableProps: {
        border: true,
      },
    };
  },
  mounted() {
    this.$http.get("/nrl/api/v1/admin/registration-products").then((response) => {
      const product =
        response.data.data && response.data.data.length > 0 ? response.data.data[0] : null;

      if (product) {
        const {
          _id,
          entityType: entity_type,
          entityId: entity_id,
          saleableItems,
          products,
          familyDiscounts,
          discounts,
        } = product;

        if (entity_type === "") return;

        this.regiProduct = {
          _id,
          entity_type,
          entity_id,
          saleableitems: saleableItems
            ? saleableItems.map((p) => ({
                _id: p._id,
                name: p.name,
                description: p.description,
                types: p.memberTypes,
                price_int: p.price,
                price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                requireSizes: p.requireSizes || false,
                mandatory: p.mandatory || false,
                seasonPass: p.seasonPass || false,
                seasonPassFrom: p.seasonPassFrom,
                seasonPassTo: p.seasonPassTo,
                seasonPassExComps: p.seasonPassExComps,
                gender: p.gender || "",
                ageFrom: p.ageFrom,
                ageTo: p.ageTo,
                dateFrom: p.dateFrom,
                dateTo: p.dateTo,
                url: p.url,
                meta: {
                  options: _.get(p, "meta.options", []),
                  entityIds: _.get(p, "meta.entityIds", []),
                  entityType: _.get(p, "meta.entityType", undefined),
                },
              }))
            : [],
          familyDiscounts: familyDiscounts
            ? familyDiscounts.map((p) => ({
                _id: p._id,
                familyFrom: p.familyFrom,
                familyTo: p.familyTo,
                discountAmount: (parseFloat(p.amount) / 100).toFixed(2).toString(),
                discountAmount_int: p.amount,
                discountMemberType: p.memberType,
              }))
            : [],
          products: products.map((p) => ({
            _id: p._id,
            role: p.memberType,
            gender: p.gender,
            agefrom: p.ageFrom,
            ageto: p.ageTo,
            datefrom: p.dateFrom,
            dateto: p.dateTo,
            price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
            price_int: p.price,
            status: p.isRegoOpen,
            payment: p.onlinePaymentRequired,
          })),
          discounts: discounts
            ? discounts.map((p) => ({
                _id: p._id,
                type: p.type === "dollar" ? "$" : "%",
                discountName: p.name,
                discountFrom: p.fromDate,
                discountTo: p.toDate,
                discountAmount:
                  p.type === "dollar"
                    ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                    : p.amount.toString(),
                discountAmount_int: p.amount,
                discountMemberType: p.memberType,
              }))
            : [],
        };

        this.optionsdisabled = new Array(this.regiProduct.products.length).fill(false);
        this.discountoptionsdisabled = new Array(this.regiProduct.discounts.length).fill(false);
        this.familydiscountoptionsdisabled = new Array(
          this.regiProduct.familyDiscounts.length
        ).fill(false);
        this.saleableoptionsdisabled = new Array(this.regiProduct.saleableitems.length).fill(false);
      }
      this.$store.commit("root/loading", false);
    });
  },
  methods: {
    submitDiscount(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.currentDiscount.type === "%" &&
            parseFloat(this.currentDiscount.discountAmount) >= 100
          ) {
            this.$customError(errormsg.regiproduct_invalidpercetageamount);
            return false;
          }
          const cd = this.currentDiscount;

          const today = this.moment().startOf("day").valueOf();
          const cddiscountFrom = this.moment(cd.discountFrom, "YYYY-MM-DD").valueOf();

          const cddiscountTo = this.moment(cd.discountTo, "YYYY-MM-DD").valueOf();

          if (cddiscountFrom < today) {
            this.$customError(errormsg.regiproduct_invaliddiscountfrom);
            return false;
          }
          if (cddiscountFrom > cddiscountTo) {
            this.$customError(errormsg.regiproduct_invaliddiscountfrom);
            return false;
          }

          const discountSameRange = this.regiProduct.discounts.find(
            (d) =>
              d.discountMemberType === cd.discountMemberType &&
              !(d.discountFrom > cddiscountTo || d.discountTo < cddiscountFrom)
          );

          if (discountSameRange) {
            this.$customError(errormsg.regiproduct_overlap);
            return false;
          }

          this.$store.commit("root/loading", true);
          this.regiProduct.entity_type = Object.keys(roles)
            .find((key) => roles[key] === this.$store.getters["user/activeRole"].type)
            .replace("Admin", "");
          this.regiProduct.entity_id = this.$store.getters["user/activeRole"].entity._id;
          const discount = {
            type: this.currentDiscount.type,
            discountName: this.currentDiscount.discountName,
            discountFrom: cddiscountFrom,
            discountTo: cddiscountTo,
            discountAmount_int: parseInt(
              parseFloat(this.currentDiscount.discountAmount).toFixed(2) * 100
            ),
            discountAmount: Math.floor(this.currentDiscount.discountAmount),
            discountMemberType: this.currentDiscount.discountMemberType,
            discountMemberType: this.currentDiscount.discountMemberType,
          };

          const insert = {
            type: discount.type === "$" ? "dollar" : "percentage",
            name: discount.discountName,
            fromDate: cddiscountFrom,
            toDate: cddiscountTo,
            amount: discount.type === "$" ? discount.discountAmount_int : discount.discountAmount,
            memberType: discount.discountMemberType,
            memberType: discount.discountMemberType,
          };
          this.currentDiscount = {
            type: null,
            discountName: null,
            discountFrom: null,
            discountTo: null,
            discountAmount: null,
            discountMemberType: null,
            discountMemberType: null,
          };
          const url = "/nrl/api/v1/admin/registration-products/discounts";
          this.$http
            .post(url, insert)
            .then((response) => {
              discount._id = response.data.data._id;
              this.regiProduct.discounts.push(discount);
              this.$store.commit("root/loading", false);
              this.discountoptionsdisabled.push(false);
              this.$customSuccess();
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(error.response.data.message);
            });
          return true;
        }
        return false;
      });
    },
    handleEditRowDiscount(rowindex) {
      if (this.discountoptionsdisabled[rowindex]) {
        const row = this.regiProduct.discounts[rowindex];
        if (!row.discountName || row.discountName === "") {
          this.$customError(errormsg.regiproduct_invaliddiscountname);
          return false;
        }
        if (
          !row.discountAmount ||
          !parseFloat(row.discountAmount) ||
          parseFloat(row.discountAmount) <= 0
        ) {
          this.$customError(errormsg.regiproduct_invaliddiscountamount);
          return false;
        }
        if (row.type === "%" && parseFloat(row.discountAmount) >= 100) {
          this.$customError(errormsg.regiproduct_invalidpercetageamount);
          return false;
        }
        // const cddiscountFrom = this.moment(row.discountFrom).format('YYYY-MM-DD');
        // const cddiscountTo = this.moment(row.discountTo).format('YYYY-MM-DD');
        const cddiscountFromValue = row.discountFrom;
        const cddiscountToValue = row.discountTo;
        const today = this.moment().startOf("day").valueOf();
        if (cddiscountFromValue < today) {
          this.$customError(errormsg.regiproduct_invaliddiscountfrom);
          return false;
        }
        if (cddiscountFromValue > cddiscountToValue) {
          this.$customError(errormsg.regiproduct_invaliddiscountfrom);
          return false;
        }
        const discountSameRange = this.regiProduct.discounts.find(
          (d) => (d) =>
            row._id !== d._id &&
            d.discountMemberType === row.discountMemberType &&
            !(d.discountFrom > cddiscountToValue || d.discountTo < cddiscountFromValue)
        );
        if (discountSameRange) {
          this.$customError(errormsg.regiproduct_overlap);
          return false;
        }
        this.$store.commit("root/loading", true);
        this.regiProduct.discounts[rowindex].discountFrom = cddiscountFromValue;
        this.regiProduct.discounts[rowindex].discountTo = cddiscountToValue;
        this.regiProduct.discounts[rowindex].discountAmount =
          row.type === "$"
            ? parseFloat(row.discountAmount).toFixed(2)
            : Math.floor(row.discountAmount);
        this.regiProduct.discounts[rowindex].discountAmount_int = parseInt(
          parseFloat(row.discountAmount).toFixed(2) * 100
        );
        this.$set(this.discountoptionsdisabled, rowindex, !this.discountoptionsdisabled[rowindex]);

        const discount = this.regiProduct.discounts[rowindex];
        const update = {
          type: discount.type === "$" ? "dollar" : "percentage",
          name: discount.discountName,
          fromDate: cddiscountFromValue,
          toDate: cddiscountToValue,
          amount:
            discount.type === "$"
              ? discount.discountAmount_int
              : Math.floor(discount.discountAmount),
          memberType: discount.discountMemberType,
          memberType: discount.discountMemberType,
        };
        const url = `/nrl/api/v1/admin/registration-products/discounts/${discount._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
            this.$set(
              this.discountoptionsdisabled,
              rowindex,
              !this.discountoptionsdisabled[rowindex]
            );
          })
          .catch((error) => {
            this.$store.commit("root/loading", false);
            this.$customError(error.response.data.message);
          });
      }
      this.$set(this.discountoptionsdisabled, rowindex, !this.discountoptionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRowDiscount(index) {
      this.$store.commit("root/loading", true);
      const discount = this.regiProduct.discounts[index];
      this.$http
        .delete(`/nrl/api/v1/admin/registration-products/discounts/${discount._id}`)
        .then(() => {
          this.regiProduct.discounts.splice(index, 1);
          this.discountoptionsdisabled.splice(index, 1);
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch((error) => {
          this.$store.commit("root/loading", false);
          this.$customError(error.response.data.message);
        });
    },
  },
  computed: {
    allDiscountMemberTypes() {
      return memberTypes.filter((member) =>
        this.regiProduct.products.map((product) => product.role).includes(member.type)
      );
    },
    discounttype: {
      get() {
        return this.currentDiscount.type != null ? this.currentDiscount.type : null;
      },
      set(value) {
        this.currentDiscount.type = value;
      },
    },
    discountname: {
      get() {
        return this.currentDiscount.discountName != null ? this.currentDiscount.discountName : null;
      },
      set(value) {
        this.currentDiscount.discountName = value;
      },
    },
    discountdatefrom: {
      get() {
        return this.currentDiscount.discountFrom;
      },
      set(value) {
        const formattedDate = dayjs(
          value.toString().split(" ").slice(0, 5).join(" "),
          "ddd MMM DD YYYY"
        ).format("YYYY-MM-DD");
        this.currentDiscount.discountFrom = value && value !== "" ? formattedDate : null;
      },
    },
    discountdateto: {
      get() {
        return this.currentDiscount.discountTo;
      },
      set(value) {
        const formattedDate = dayjs(value.toString().split(" ").slice(0, 5).join(" "), {
          format: "ddd MMM DD YYYY",
        }).format("YYYY-MM-DD");
        this.currentDiscount.discountTo = value && value !== "" ? formattedDate : null;
      },
    },
    discountamount: {
      get() {
        return this.currentDiscount.discountAmount != null
          ? this.currentDiscount.discountAmount
          : null;
      },
      set(value) {
        this.currentDiscount.discountAmount = Number(value) && parseFloat(value) > 0 ? value : null;
      },
    },
    discountmembertype: {
      get() {
        return this.currentDiscount.discountMemberType != null
          ? this.currentDiscount.discountMemberType
          : null;
      },
      set(value) {
        this.currentDiscount.discountMemberType = value;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
</style>
