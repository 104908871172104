<template>
  <div class="tw-flex tw-justify-center">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-w-full tw-max-w-xl tw-px-10 tw-py-3 tw-bg-white tw-rounded tw-shadow-2xl"
    >
      <img class="tw-max-w-screen-sm " src="~@/assets/MySideline_PrimaryLogo_black.svg" />
      <h3 class="tw-pt-3 tw-text-lg tw-font-bold">Unsupported Device</h3>
      <p class="tw-py-3 tw-text-justify">
        Unfortunately, The Admin does not currently support mobile devices at this time.
      </p>
      <el-button type="success" @click="continueAnyway()">Continue anyway</el-button>

      <p class="tw-py-3 tw-text-justify">Wanting to manage match day or training operations?</p>

      <el-button type="success" @click="goManager()">Go To Manager</el-button>
    </div>
  </div>
  <!-- <div class="flex-container">
    <div class="center">
      <div class="flex-0">
          <img src="@/assets/MySideline_PrimaryLogo_black.svg" alt="My Sideline">
      </div>
      <div class="flex-0">
        <h1>Unsupported Device</h1>
        <p>Unfortunately, The Admin does not currently support mobile devices at this time.</p>
      </div>
      <el-button type="success" @click="continueAnyway()">Continue anyway</el-button>
      <div>
        <p>Wanting to manage match day or training operations?</p>
      </div>
      <el-button type="success" @click="goManager()">Go To Manager</el-button>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "UnsupportedScreen",
  props: {},
  created() {},
  data() {
    return {};
  },
  methods: {
    continueAnyway() {
      this.$store.commit("user/ignoreWarning", true);
    },
    goManager() {
      const m =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://test-manager.mysideline.xyz/"
          : "https://manager.mysideline.com.au/";
      window.location = m;
    }
  }
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  overflow: hidden;
  background-color: #f6f6f6;
  color: #0d0d0d;
  font-family: Helvetica, Arial, sans-serif;
}

.flex-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 726px) {
  .center {
    flex: 0 0 auto;
    height: 50%;
    width: 50%;
    margin: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 20px;
  }
}

.center {
  width: 80%;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 20px;
}

.flex-0 {
  flex: 0 0 auto;
}

.center img {
  height: 10rem;
  margin: 20px;
}
</style>
