<template>
  <div>
    <!-- <el-steps v-if="steps.length > 1" simple :active="active" finish-status="success" class="mb-2">
            <el-step v-for="(item, index) in steps" :key="item.type" :title="`Step ${index + 1}`"></el-step>
        </el-steps> -->

    <regrading-form ref="0" v-show="active === 0" v-model="regrading" />

    <regrading-redo-draw
      key="1"
      ref="1"
      v-if="active === 1 && showMatches === false && showDB === false"
      v-model="regrading"
      v-bind:isDest="true"
      title="Redo Draw For Destination Competition"
      @showMatchesReport="showMatchesReport"
      @showDrawBuilder="showDrawBuilder"
    />
    <regrading-redo-draw
      key="2"
      ref="2"
      v-if="active === 2 && showMatches === false && showDB === false"
      v-model="regrading"
      v-bind:isDest="false"
      title="Redo Draw For Source Competition"
      @showMatchesReport="showMatchesReport"
      @showDrawBuilder="showDrawBuilder"
    />

    <regrading-matches-report
      v-if="showMatches === true"
      title="Redo Draw Matches Report"
      @showMatchesReport="showMatchesReport"
    ></regrading-matches-report>

    <regrading-matches-draw-builder
      v-if="showDB === true"
      v-model="regrading"
      :isDest="active === 0 ? true : false"
      :active="active"
      title="Matches Redo Draw Builder"
      @showDrawBuilder="showDrawBuilder"
      @nextStep="nextStep"
    ></regrading-matches-draw-builder>

    <el-row class="btn-container mt-2" v-if="showMatches === false && showDB === false">
      <el-button type="success" @click="submit"> {{ submitText }} </el-button>
      <el-button @click="cancel"> {{ cancelText }} </el-button>
    </el-row>
  </div>
</template>
<script>
import _ from "lodash";
import RegradingForm from "./RegradingForm.vue";
import RegradingRedoDraw from "./RegradingRedoDraw.vue";
import RegradingMatchesReport from "./RegradingMatchesReport.vue";
import RegradingMatchesDrawBuilder from "./RegradingMatchesRedoDrawBuilder.vue";

export default {
  components: {
    RegradingForm,
    RegradingRedoDraw,
    RegradingMatchesReport,
    RegradingMatchesDrawBuilder
  },
  data() {
    return {
      steps: [{ type: 0, name: "Step 1" }],
      active: 0,
      cancelText: "Cancel",
      submitText: "Submit",
      regrading: {
        sourceCompetition: { _id: null, name: null },
        regradeTeam: { _id: null, name: null },
        destCompetition: { _id: null, name: null },
        replaceTeam: { _id: null, name: null },
        designationOption: "new",
        sourceMatchOption: "replace",
        destMatchOption: "replace",
        ladderOption: "nocopy",
        destDraw: [],
        sourceDraw: [],
        destImportedDraw: [],
        sourceImportedDraw: []
      },
      showMatches: false,
      showDB: false
    };
  },
  methods: {
    nextStep() {
      if (this.hasNext()) {
        this.next();
      }
    },
    submit() {
      // call individually submit
      this.$refs[this.active]
        .submit()
        .then(val => {
          if (val) {
            if (this.hasNext()) {
              this.next();
            } else {
              this.$store.commit("root/loading", true);
              this.$confirm(
                "Are you sure you want to re-grade? This operation cannot be undone.",
                "Warning",
                {
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                  type: "warning"
                }
              )
                .then(() => {
                  this.$http
                    .post("/nrl/api/v1/admin/team-regradings/regrade", this.regrading)
                    .then(() => {
                      this.$customSuccess();
                      this.$store.commit("root/loading", false);
                      this.$router.push({ name: "regrading.list" });
                    })
                    .catch(err => {
                      this.$customError(err.response.data.message);
                      this.$store.commit("root/loading", false);
                    });
                })
                .catch(() => {
                  this.$store.commit("root/loading", false);
                });
            }
          }
        })
        .catch(() => {});
    },
    cancel() {
      if (this.isCancel()) {
        this.$router.push({ name: "regrading.list" });
      } else {
        this.previous();
      }
    },
    next() {
      if (this.hasNext()) {
        this.active = this.steps[this.steps.findIndex(x => x.type === this.active) + 1].type;
      }
    },
    previous() {
      if (this.hasPrevious()) {
        this.active = this.steps[this.steps.findIndex(x => x.type === this.active) - 1].type;
      }
    },
    hasNext() {
      return this.active < this.steps[this.steps.length - 1].type;
    },
    hasPrevious() {
      return this.active > 0;
    },
    isCancel() {
      return this.cancelText === "Cancel";
    },
    textChange() {
      this.submitText = this.hasNext() ? "Next" : "Submit";
      this.cancelText = this.hasPrevious() ? "Back" : "Cancel";
    },
    showMatchesReport(val) {
      this.showMatches = val;
    },
    showDrawBuilder(val) {
      this.showDB = val;
      // TODO clean up
      // if (this.hasNext()) {
      //   this.next();
      // }
    }
  },
  watch: {
    active() {
      this.textChange();
    },
    "regrading.sourceMatchOption": function() {
      if (this.regrading.sourceMatchOption === "redo") {
        this.steps.push({ type: 2, name: "Redo Draw Source" });
      } else {
        this.steps = _.remove(this.steps, x => x.type !== 2);
      }
      this.steps = _.orderBy(this.steps, "type");
      this.textChange();
    },
    "regrading.destMatchOption": function() {
      if (this.regrading.destMatchOption === "redo") {
        this.steps.push({ type: 1, name: "Redo Draw Destination" });
      } else {
        this.steps = _.remove(this.steps, x => x.type !== 1);
      }
      this.steps = _.orderBy(this.steps, "type");
      this.textChange();
    }
  }
};
</script>
