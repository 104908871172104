/* eslint-disable no-return-assign */
import _ from 'lodash';

const initState = () => ({
  search: {
    dateRange: undefined,
    venueIds: undefined,
    fieldNo: undefined,
    competitionIds: undefined,
    ageLvls: undefined,
    duration: 2,
    isClashOnly: undefined
  },
  matches: [],
  timeline: {
    window: {
      start: undefined,
      end: undefined
    }
  },
  venues: [], // remote search result, binding purpose
});

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateSearch: (state, payload) => state.search = payload,
    updateMatches: (state, payload) => state.matches = payload,
    updateWindow: (state, payload) => state.timeline.window = payload,
    updateVenues: (state, payload) => state.venues = payload,
    clearWindow: state => state.timeline.window = {
      start: undefined,
      end: undefined
    }
  }
};
