<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="24">
        <vue-upload-component
          v-if="this.$route.params.type !== 'insert'"
          ref="docupload"
          inputId="supportDocUpload"
          :drop="true"
          v-model="docuploads"
          :extensions="documentExtensions"
          :accept="documentAccept"
          :post-action="supportDocumentURL"
          :headers="uploadHeaders"
          :data="memberData"
          @input-file="inputFileSupportDoc"
          @input-filter="inputFilterSupportDoc"
        >
          <el-button type="success" class="add-document-button" icon="el-icon-plus"
            >Add A Document</el-button
          >
        </vue-upload-component>
        <el-dialog
          title="Add A Supporting Document"
          :visible.sync="supportDocumentDialogVisible"
          center
        >
          <el-table v-if="docuploads.length !== 0" :data="docuploads" style="width: 100%">
            <el-table-column prop="name" width="200" label="File Name"> </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-select
                  id="display-input"
                  v-model="scope.row.data.displayName"
                  prop="displayName"
                  placeholder="Display Name"
                  @change="valueExists"
                >
                  <el-option v-for="id in idTypes" :key="id.type" :label="id.name" :value="id.type">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="150">
              <template>
                <el-button
                  size="small"
                  @click="
                    supportDocumentDialogVisible = false;
                    docuploads = [];
                  "
                  type="danger"
                >
                  Delete
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <span slot="footer" class="dialog-footer" v-if="docuploads.length > 0">
            <el-button
              @click="
                supportDocumentDialogVisible = false;
                docuploads = [];
              "
              >Cancel</el-button
            >
            <el-button type="success" @click="next()">Confirm</el-button>
          </span>
        </el-dialog>
      </el-col>
    </el-row>
    <el-table
      v-if="currentMember.documents && currentMember.documents.length !== 0"
      :data="currentMember.documents"
      style="width: 100%"
    >
      <el-table-column
        prop="docType"
        label="Type"
        :formatter="(row, column, value, index) => value.replace(/^\w/, (c) => c.toUpperCase())"
      >
      </el-table-column>
      <el-table-column label="Name" prop="displayName"> </el-table-column>
      <el-table-column align="center">
        <template slot-scope="scope">
          <el-button
            class="operation-button"
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="goToDocument(scope.row._id)"
          >
            View
          </el-button>
          <el-button
            :disabled="scope.row.type === 1"
            @click.prevent="deleteRow(scope.$index, uploads)"
            type="danger"
            size="small"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { EventBus } from "../../bus";
import { supportDocumentURL, idTypes } from "../../utils/constants";

export default {
  mounted() {
    EventBus.$on("memberUpdated", () => {
      this.$forceUpdate();
    });
  },
  props: {
    member: { type: Object, required: true },
  },
  data() {
    return {
      docuploads: [],
      supportDocumentDialogVisible: false,
      documentExtensions: "jpg, jpeg, png, pdf, doc, docx",
      documentAccept:
        "image/png, image/jpg, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      supportDocumentURL,
      idTypes,
    };
  },
  methods: {
    goToDocument(id) {
      const { _id: memberId } = this.currentMember;
      this.$store.commit("root/loading", true);
      this.$http
        .get(
          `${this.$store.getters["root/apiUrl"]}/nrl/api/v1/admin/documents/${id}?memberId=${memberId}`
        )
        .then((response) => {
          window.open(response.data.url, "_blank");
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    },
    deleteRow(index) {
      this.$confirm(
        "Are you sure you want to remove this supporting document?",
        "Remove Supporting Document",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          const row = this.currentMember.documents[index];
          const { _id: memberid } = this.currentMember;
          const { _id: documentid } = row;
          this.$store.commit("root/loading", true);
          this.$http
            .post("/nrl/api/v1/admin/remove-supporting-document", { memberid, documentid })
            .then(() => {
              this.currentMember.documents.splice(index, 1);
              this.$customSuccess();
              this.$store.commit("root/loading", false);
            })
            .catch(() => {
              this.$customError();
              this.$store.commit("root/loading", false);
            });
        })
        .catch(() => {});
    },
    inputFileSupportDoc(newFile) {
      if (newFile && newFile.error) {
        this.$store.commit("app/loading", false);
        this.$customError("Document upload has failed");
        this.docuploads = this.docuploads.filter((file) => file.id !== newFile.id);
      } else if (newFile && !newFile.active && newFile.response) {
        setTimeout(() => {
          if (newFile.response && newFile.response.data) {
            if (!this.currentMember.documents) {
              this.currentMember.documents = [];
            }
            const { displayName, _id } = newFile.response.data;
            const docObj = {
              _id,
              displayName,
              docType: newFile.type || "other",
            };
            this.currentMember.documents.push(docObj);
            this.supportDocumentDialogVisible = false;
            this.docuploads = [];
          }
          this.$store.commit("app/loading", false);
        }, 2500);
      }
      return undefined;
    },
    inputFilterSupportDoc(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg|pdf|doc|docx)$/i.test(newFile.name)) {
        this.$customError("Please submit a valid file type (png/jpg/jpeg/pdf/doc/docx)");
        return prevent();
      }

      this.supportDocumentDialogVisible = true;
      this.$store.commit("app/loading", true);
      if (newFile.active === false && newFile.response && newFile.response.data) {
        this.supportDocumentDialogVisible = false;
      }
      return undefined;
    },
    next() {
      if (this.docuploads[0].data.displayName) {
        this.$refs.docupload.active = true;
        this.supportDocumentDialogVisible = false;
      } else {
        this.$customError("Please enter a display name for document.");
        const displayNameInput = document.querySelector("input#display-input.el-input__inner");
        displayNameInput.style.borderColor = "#F56C6C";
      }
    },
    valueExists(v) {
      if (v) {
        const displayNameInput = document.querySelector("input#display-input.el-input__inner");
        displayNameInput.style.borderColor = "#C0C4CC";
      }
    },
  },
  computed: {
    memberData() {
      return {
        _id: this.currentMember._id,
      };
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    currentMember() {
      return this.member || { documents: [] };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-document-button {
  margin-bottom: 1rem;
}
</style>
