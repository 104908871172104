<template>
  <div>
    <label
      class="tw-w-full el-form-item__label tw-mt-4 tw-pb-0 tw-text-left"
      v-if="regiProduct && regiProduct.length > 0"
      >Saleable Items at {{ heading }} level
    </label>
    <data-tables
      v-if="regiProduct.length > 0 && !updating"
      :data="regiProduct"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="Image" width="150">
        <template slot-scope="scope">
          <div v-if="bulkEdit">
            <vue-upload-component
              :ref="`productUpload-${scope.$index}`"
              :inputId="`productUpload-${scope.$index}`"
              :drop="false"
              v-model="editedUploadsObj[scope.$index]"
              :extensions="extensions"
              :accept="accept"
              :post-action="productUploadURL"
              :headers="uploadHeaders"
              :data="{ productid: scope.row._id, name: scope.row.name, _id: regiProdId }"
              :multiple="true"
              @input-file="(newFile, oldFile) => inputEditFile(newFile, oldFile, scope)"
              @input-filter="
                (newFile, oldFile, prevent) => inputEditFilter(newFile, oldFile, prevent, scope)
              "
              class="el-button el-button--default upload"
              :class="{ hasEditUpload: scope.row.url }"
              :disabled="!bulkEdit"
            >
              <span v-if="!scope.row.url">Upload</span>
              <div v-else class="avatar-wrap">
                <div
                  :style="{ 'background-image': `url(${scope.row.url})` }"
                  alt=""
                  class="avatar"
                ></div>
                <div class="upload-logo">
                  <svg
                    width="18px"
                    height="16px"
                    viewBox="0 0 18 16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        id="Artboard"
                        transform="translate(-47.000000, -88.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <path
                          d="M48.9756098,103.852104 C47.8887171,103.852104 47,102.913299 47,101.765148 L47,92.4897855 C47,91.3416348 47.8887171,90.402829 48.9756098,90.402829 L51.7538,90.402829 L52.5220927,88.3810806 C52.5867013,88.2038545 52.74706,88.0861414 52.9268293,88.0839791 L59.0731707,88.0839791 C59.25294,88.0861414 59.4132987,88.2038545 59.4779073,88.3810806 L60.2462,90.402829 L63.0243902,90.402829 C64.1112829,90.402829 65,91.3416348 65,92.4897855 L65,101.765148 C65,102.913299 64.1112829,103.852104 63.0243902,103.852104 L48.9756098,103.852104 Z M48.9756098,102.924568 L63.0243902,102.924568 C63.6400341,102.924568 64.1219512,102.41549 64.1219512,101.765148 L64.1219512,92.4897855 C64.1219512,91.8394435 63.6400341,91.3303652 63.0243902,91.3303652 L59.9512195,91.3303652 C59.7731474,91.3293876 59.6132696,91.2148899 59.5464829,91.0405101 L58.7781902,89.0115154 L53.2218098,89.0115154 L52.4535171,91.0405101 C52.3867304,91.2148899 52.2268526,91.3293876 52.0487805,91.3303652 L48.9756098,91.3303652 C48.3599659,91.3303652 47.8780488,91.8394435 47.8780488,92.4897855 L47.8780488,101.765148 C47.8780488,102.41549 48.3599659,102.924568 48.9756098,102.924568 Z M56,101.30138 C53.8230317,101.30138 52.0487805,99.4271536 52.0487805,97.1274667 C52.0487805,94.8277565 53.8230317,92.9535536 56,92.9535536 C58.1770122,92.9535536 59.9512195,94.8277797 59.9512195,97.1274667 C59.9512195,99.4271536 58.1770122,101.30138 56,101.30138 Z M56,100.373843 C57.7024707,100.373843 59.0731707,98.9258667 59.0731707,97.1274667 C59.0731707,95.3290435 57.7024707,93.8810899 56,93.8810899 C54.2975732,93.8810899 52.9268293,95.3290435 52.9268293,97.1274667 C52.9268293,98.9258667 54.2975732,100.373843 56,100.373843 Z"
                          id="Shape"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </vue-upload-component>
            <div
              v-if="scope.row && scope.row.url && bulkEdit"
              style="text-align: left; padding-left: calc(5em + 12px); margin-top: -10px"
            >
              <el-dropdown @command="(command) => onCommandDropdown(command, scope)">
                <span style="" class="el-dropdown-link">
                  <i class="el-icon-remove-outline el-icon--right" style="font-size: 1.6rem"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="replace-image-edit">Replace image</el-dropdown-item>
                  <el-dropdown-item command="remove-image">Remove image</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div v-else-if="scope.row.url && !bulkEdit" class="avatar-wrap">
            <div
              :style="{ 'background-image': `url(${scope.row.url})` }"
              :alt="scope.row.name + ' product image'"
              class="product-img"
            ></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Name" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.name"
            :disabled="!bulkEdit"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Description" width="300">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.description"
            :disabled="!bulkEdit"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Price" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.price"
            :disabled="!bulkEdit"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Member Types" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.types"
            placeholder="Member types"
            multiple
            :disabled="!bulkEdit"
          >
            <el-option
              v-for="item in saleableMemberTypes"
              :label="item.name"
              :key="item.type"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Excluded Associations" width="250" v-if="showExcludedAssociations">
        <template v-slot="scope">
          <el-tag
            v-for="tag in scope.row.saleableExcludedAssociationsData"
            :key="tag._id"
            :closable="bulkEdit"
            size="small"
            color="#F5F7FA"
            :style="{ color: '#666' }"
            :type="tag.type"
            :disable-transitions="false"
            @close="handleClose(tag, scope.row)"
          >
            {{ tag.name + " - " + tag._id }}
          </el-tag>
          <el-select
            multiple
            :remote="true"
            :remote-method="filterAssociations"
            v-model="scope.row.saleableExcludedAssociations"
            placeholder="Enter name to start searching"
            filterable
            :disabled="!bulkEdit"
          >
            <el-option
              v-for="item in associations"
              :label="`${item.name} - ${item._id}`"
              :key="item._id"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Require Sizes" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.requireSizes"
            placeholder="Require sizes"
            :disabled="!bulkEdit"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Mandatory" width="200">
        <template slot-scope="scope">
          <el-select v-model="scope.row.mandatory" placeholder="Mandatory" :disabled="!bulkEdit">
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column label="Single season" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.seasonPass"
            placeholder="Single season purchase"
            :disabled="true"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Season Pass From Date" width="200" v-if="authRoles">
        <template slot-scope="scope">
          <el-date-picker
            format="dd/MM/yyyy"
            type="date"
            v-model="scope.row.seasonPassFrom"
            placeholder=""
            value-format="timestamp"
            disabled
            :clearable="false"
          />
        </template>
      </el-table-column>
      <el-table-column label="Season Pass To Date" width="200" v-if="authRoles">
        <template slot-scope="scope">
          <el-date-picker
            format="dd/MM/yyyy"
            type="date"
            v-model="scope.row.seasonPassTo"
            placeholder=""
            value-format="timestamp"
            disabled
            :clearable="false"
          />
        </template>
      </el-table-column>
      <el-table-column label="Gender" width="200">
        <template slot-scope="scope">
          <el-select v-model="scope.row.gender" placeholder="Gender" :disabled="!bulkEdit">
            <el-option
              v-for="item in [
                { label: 'All', value: '' },
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Age From" width="100">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.ageFrom"
            :disabled="!bulkEdit"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Age To" width="100">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.ageTo"
            :disabled="!bulkEdit"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column v-if="isTFA" label="DOB From" width="200">
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.dateFrom"
            type="date"
            placeholder="Select a Date"
            format="dd/MM/yyyy"
            value-format="timestamp"
            :disabled="!bulkEdit"
          >
          </el-date-picker>
        </template>
      </el-table-column>
      <el-table-column v-if="isTFA" label="DOB To" width="200">
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.dateTo"
            type="date"
            placeholder="Select a Date"
            format="dd/MM/yyyy"
            value-format="timestamp"
            :disabled="!bulkEdit"
          >
          </el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="Entity Type" width="120" v-if="clearance == roles.superAdmin">
        <template v-slot="scope">
          <el-select
            v-if="scope.row.meta"
            v-model="scope.row.meta.entityType"
            placeholder=""
            :disabled="true"
          >
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Entity Ids" width="120" v-if="clearance == roles.superAdmin">
        <template v-slot="scope">
          <p v-if="scope.row.meta && scope.row.meta.entityIds">
            {{ scope.row.meta.entityIds.join(",") }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Excluded Competitions" width="250" v-if="showExcludedEntities">
        <template v-slot="scope">
          <el-tag
            v-for="tag in scope.row.seasonPassExCompsData"
            :key="tag._id"
            :closable="bulkEdit"
            size="small"
            color="#F5F7FA"
            :style="{ color: '#666' }"
            :type="tag.type"
            :disable-transitions="false"
            @close="handleClose(tag, scope.row)"
          >
            {{ tag.name + " - " + tag._id }}
          </el-tag>
          <el-select
            multiple
            :remote="true"
            :remote-method="filterCompetitions"
            v-model="scope.row.seasonPassExComps"
            placeholder="Enter name to start searching"
            filterable
            :disabled="!bulkEdit"
          >
            <el-option
              v-for="item in competitions"
              :label="`${item.name} - ${item._id}`"
              :key="item._id"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Remove" width="225" v-if="remove">
        <template slot-scope="scope">
          <el-button @click="handleRemoveRowSaleable(scope.$index)" :disabled="bulkEdit"
            >Remove</el-button
          >
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { errormsg, memberTypes, productUploadURL, roles, nationals } from "../../utils/constants";
import filter from "lodash/fp/filter";
import contains from "lodash/fp/contains";

export default {
  name: "SaleableItemsData",
  props: ["products", "regiProduct", "heading", "edit", "remove", "bulkEdit"],
  data() {
    const validateSaleableName = (rule, value, callback) => {
      if (!this.currentSaleableItem.name || this.currentSaleableItem.name === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSaleableDesc = (rule, value, callback) => {
      if (!this.currentSaleableItem.description || this.currentSaleableItem.description === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSaleablePrice = (rule, value, callback) => {
      if (!this.currentSaleableItem.price || parseFloat(this.currentSaleableItem.price) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSaleableMembertype = (rule, value, callback) => {
      if (this.currentSaleableItem.types.length === 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSaleableAgeFrom = (rule, value, callback) => {
      if (!this.currentSaleableItem.ageFrom && this.currentSaleableItem.ageTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSaleableAgeTo = (rule, value, callback) => {
      if (!this.currentSaleableItem.ageTo && this.currentSaleableItem.ageFrom) {
        return callback(new Error(errormsg.input_option));
      }
      if (parseInt(this.currentSaleableItem.ageTo) < parseInt(this.currentSaleableItem.ageFrom)) {
        return callback(new Error(errormsg.gte_age));
      }
      return callback();
    };

    const validateSaleableDateFrom = (rule, value, callback) => {
      if (!this.currentSaleableItem.dateFrom && this.currentSaleableItem.dateTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSaleableDateTo = (rule, value, callback) => {
      if (!this.currentSaleableItem.dateTo && this.currentSaleableItem.dateFrom) {
        return callback(new Error(errormsg.input_option));
      }
      if (this.currentSaleableItem.dateTo < this.currentSaleableItem.dateFrom) {
        return callback(new Error(errormsg.gte_date));
      }
      return callback();
    };

    return {
      entities: [],
      competitions: [],
      associations: [],
      roles,
      currentSaleableItem: {
        name: null,
        description: null,
        price: null,
        types: [],
        requireSizes: false,
        mandatory: false,
        seasonPass: false,
        seasonPassFrom: undefined,
        seasonPassTo: undefined,
        seasonPassExComps: [],
        seasonPassExCompsData: [],
        saleableExcludedAssociations: [],
        saleableExcludedAssociationsData: [],
        gender: "",
        ageFrom: null,
        ageTo: null,
        dateFrom: "",
        dateTo: "",
        url: null,
        meta: {
          options: null,
          entityType: "",
          entityIds: [],
        },
      },
      uneditedSaleableItemsObj: {},
      saleableoptionsdisabled: [],
      saleableitemsrules: {
        saleableitemname: {
          required: true,
          validator: validateSaleableName,
          message: errormsg.input_option,
          trigger: "blur",
        },
        saleableitemdesc: {
          required: true,
          validator: validateSaleableDesc,
          message: errormsg.input_option,
          trigger: "blur",
        },
        saleableitemprice: {
          required: true,
          validator: validateSaleablePrice,
          message: errormsg.input_option,
          trigger: "blur",
        },
        salelablemembertype: {
          required: true,
          validator: validateSaleableMembertype,
          message: errormsg.select_option,
          trigger: "blur",
        },
        ageTo: {
          validator: validateSaleableAgeTo,
        },
        ageFrom: {
          validator: validateSaleableAgeFrom,
          message: errormsg.input_option,
        },
        dateTo: {
          validator: validateSaleableDateTo,
        },
        dateFrom: {
          validator: validateSaleableDateFrom,
          message: errormsg.input_option,
        },
      },
      tableProps: {
        border: true,
      },
      uploads: [],
      editedUploadsObj: {},
      extensions: "jpg,jpeg,png",
      accept: "image/png,image/jpg,image/jpeg",
      updating: false,
      productImages: {},
    };
  },
  watch: {
    regiProduct: {
      handler(updatedRegiProduct) {
        if (
          updatedRegiProduct &&
          updatedRegiProduct.length > 0 &&
          this.$store.getters["user/activeRole"].national_id === 31 &&
          [1, 2].includes(this.$store.getters["user/activeRole"].type)
        ) {
          updatedRegiProduct.forEach((saleableItem, saleableItemIndex) => {
            if (saleableItem.seasonPassExComps && saleableItem.seasonPassExComps.length > 0) {
              const tempComps = [];
              saleableItem.seasonPassExComps.forEach((compId, compIndex) => {
                this.$http.get(`nrl/api/v1/admin/competitions/${compId}`).then((response) => {
                  if (response.status === 200) {
                    const { _id, name } = response.data.data;
                    tempComps.push({
                      _id,
                      name,
                    });
                  }
                });
              });
              this.$set(
                this.$props.regiProduct.saleableitems[saleableItemIndex],
                "seasonPassExCompsData",
                tempComps
              );
              this.$set(
                this.$props.regiProduct.saleableitems[saleableItemIndex],
                "seasonPassExComps",
                []
              );
            }
          });
        }
        if (
          updatedRegiProduct &&
          updatedRegiProduct.length > 0 &&
          this.$store.getters["user/activeRole"].national_id === 32 &&
          [2].includes(this.$store.getters["user/activeRole"].type)
        ) {
          updatedRegiProduct.forEach(async (saleableItem, saleableItemIndex) => {
            if (
              saleableItem.saleableExcludedAssociations &&
              saleableItem.saleableExcludedAssociations.length > 0
            ) {
              const tempAssociations = [];
              saleableItem.saleableExcludedAssociations.forEach(
                (associationId, associationIndex) => {
                  this.$http
                    .get(`/nrl/api/v1/admin/associations/${associationId}`)
                    .then((response) => {
                      if (response.status === 200) {
                        const { _id, name } = response.data.data;
                        tempAssociations.push({
                          _id,
                          name,
                        });
                      }
                    });
                }
              );
              this.$set(
                this.$props.regiProduct[saleableItemIndex],
                "saleableExcludedAssociationsData",
                tempAssociations
              );
              this.$set(
                this.$props.regiProduct[saleableItemIndex],
                "saleableExcludedAssociations",
                []
              );
            }
          });
        }
      },
      immediate: true, // Executes the handler immediately when the component is created
    },
  },
  methods: {
    filterCompetitions: _.debounce(function hello(query) {
      if (query !== "") {
        this.$store.commit("root/loading", true);
        this.$http
          .get("/nrl/api/v1/admin/competition-search", {
            params: { name: query },
          })
          .then((res) => {
            this.competitions = res.data.data;
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.competitions = [];
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      }
    }, 500),
    filterAssociations: _.debounce(function hello(query) {
      if (query !== "") {
        this.$store.commit("root/loading", true);
        this.$http
          .get("/nrl/api/v1/admin/association-search", {
            params: { name: query },
          })
          .then((res) => {
            this.associations = res.data.data;
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.associations = [];
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      }
    }, 500),
    filter: _.debounce(function hello(query) {
      if (query !== "") {
        this.$store.commit("root/loading", true);
        this.$http
          .get("/nrl/api/v1/admin/entity-search", {
            params: { entity: this.currentSaleableItem.meta.entityType, name: query },
          })
          .then((res) => {
            this.entities = res.data.data;
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            this.entities = [];
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      }
    }, 500),
    handleClose(tag, saleableItem) {
      this.regiProduct.forEach((item) => {
        if (item._id === saleableItem._id) {
          item.seasonPassExCompsData?.splice(item.seasonPassExCompsData.indexOf(tag), 1);
          item.saleableExcludedAssociationsData?.splice(
            item.saleableExcludedAssociationsData.indexOf(tag),
            1
          );
        }
      });
    },
    replaceNewProdImg() {
      this.removeImageS3(this.currentSaleableItem);
      const uploader = this.$refs.upload;
      uploader.$children[0].$el.click();
    },
    revertTableRow(rows) {
      this.updating = true;
      this.$store.commit("root/loading", true);
      setTimeout(() => {
        const cancelChanges = this.uneditedSaleableItemsObj;
        rows.forEach((index) => {
          this.regiProduct[index] = cancelChanges[index];
          this.editedUploadsObj[index] = [];
        });
        this.updating = false;
        this.$store.commit("root/loading", false);
      }, 1000);
    },
    submitSaleableitem(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let seasonPassFrom = 0,
            seasonPassTo = 0;

          // If Season Pass is Yes then dates are mandatory
          if (this.authRoles) {
            if (
              this.currentSaleableItem.seasonPass &&
              (this.currentSaleableItem.seasonPassFrom === undefined ||
                this.currentSaleableItem.seasonPassTo === undefined)
            ) {
              this.$customError("Season Pass Dates are required");
              return;
            }

            // Hide Require Sizes
            // if (this.currentSaleableItem.seasonPass) this.currentSaleableItem.requireSizes = false;

            seasonPassFrom = this.setToStartOfDay(this.currentSaleableItem.seasonPassFrom);
            seasonPassTo = this.setToEndOfDay(this.currentSaleableItem.seasonPassTo);

            // // Check if the end date is in past or not
            const currentDate = new Date();
            const startOfCurrentDay = currentDate.setHours(0, 0, 0, 0);
            if (seasonPassTo < startOfCurrentDay) {
              this.$customError("Season pass cannot include past dates");
              return;
            }
          }

          const saleableitemsamename = this.regiProduct.filter(
            (d) => d.name === this.currentSaleableItem.name
          );
          if (saleableitemsamename && saleableitemsamename.length > 0) {
            this.$customError(errormsg.regiproduct_saleableitemsamename);
            return false;
          }
          this.$store.commit("root/loading", true);

          const saleableItem = {
            name: this.currentSaleableItem.name,
            description: this.currentSaleableItem.description,
            price_int: parseInt(parseFloat(this.currentSaleableItem.price).toFixed(2) * 100),
            price: parseFloat(this.currentSaleableItem.price).toFixed(2),
            types: this.currentSaleableItem.types,
            requireSizes: this.currentSaleableItem.requireSizes,
            mandatory: this.currentSaleableItem.mandatory,
            seasonPass: this.currentSaleableItem.seasonPass,
            meta: this.currentSaleableItem.meta,
          };

          if (
            this.currentSaleableItem.seasonPass &&
            this.currentSaleableItem.seasonPassFrom &&
            this.currentSaleableItem.seasonPassTo
          ) {
            saleableItem["seasonPassFrom"] = seasonPassFrom;
            saleableItem["seasonPassTo"] = seasonPassTo;
          }

          if (this.currentSaleableItem.seasonPass && this.currentSaleableItem.seasonPassExComps) {
            saleableItem["seasonPassExComps"] = this.currentSaleableItem.seasonPassExComps;
          }

          if (this.currentSaleableItem.saleableExcludedAssociations) {
            saleableItem["saleableExcludedAssociations"] =
              this.currentSaleableItem.saleableExcludedAssociations;
          }

          // handle optional fields
          saleableItem.gender = this.currentSaleableItem.gender;
          if (this.currentSaleableItem.ageFrom)
            saleableItem.ageFrom = parseInt(this.currentSaleableItem.ageFrom);
          if (this.currentSaleableItem.ageTo)
            saleableItem.ageTo = parseInt(this.currentSaleableItem.ageTo);
          if (this.currentSaleableItem.dateFrom)
            saleableItem.dateFrom = this.currentSaleableItem.dateFrom;
          if (this.currentSaleableItem.dateTo)
            saleableItem.dateTo = this.currentSaleableItem.dateTo;
          if (this.currentSaleableItem.url) saleableItem.url = this.currentSaleableItem.url;

          const insert = {
            name: this.currentSaleableItem.name,
            description: this.currentSaleableItem.description,
            price: saleableItem.price_int,
            memberTypes: saleableItem.types,
            requireSizes: saleableItem.requireSizes,
            mandatory: saleableItem.mandatory,
            seasonPass: saleableItem.seasonPass,
            gender: saleableItem.gender || undefined,
            ageFrom: saleableItem.ageFrom,
            ageTo: saleableItem.ageTo,
            dateFrom: saleableItem.dateFrom,
            dateTo: saleableItem.dateTo,
            url: saleableItem.url,
            meta: saleableItem.meta,
          };

          if (
            this.currentSaleableItem.seasonPass &&
            this.currentSaleableItem.seasonPassFrom &&
            this.currentSaleableItem.seasonPassTo
          ) {
            insert["seasonPassFrom"] = seasonPassFrom;
            insert["seasonPassTo"] = seasonPassTo;
          }

          if (this.currentSaleableItem.seasonPass && this.currentSaleableItem.seasonPassExComps) {
            insert["seasonPassExComps"] = this.currentSaleableItem.seasonPassExComps;
          }

          if (this.currentSaleableItem.saleableExcludedAssociations) {
            saleableItem["saleableExcludedAssociations"] =
              this.currentSaleableItem.saleableExcludedAssociations;
          }

          const url = "/nrl/api/v1/admin/registration-products/saleable-items";
          this.$http
            .post(url, insert)
            .then((response) => {
              saleableItem._id = response.data.data._id;
              this.regiProduct.push(saleableItem);
              this.$store.commit("root/loading", false);
              this.saleableoptionsdisabled.push(false);
              this.$customSuccess();
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(error.response.data.message);
            });

          this.currentSaleableItem = {
            name: null,
            description: null,
            price: null,
            types: [],
            requireSizes: false,
            mandatory: false,
            seasonPass: false,
            seasonPassExComps: [],
            saleableExcludedAssociations: [],
            gender: "",
            ageFrom: null,
            ageTo: null,
            dateFrom: "",
            dateTo: "",
            url: null,
            meta: {
              entityType: "",
              entityIds: [],
            },
          };
          this.uploads = [];

          return true;
        }
        return false;
      });
    },
    setToEndOfDay(value) {
      const endDate = new Date(value);
      const endOfDay = endDate.setHours(23, 59, 59, 999);
      return endOfDay;
    },
    setToStartOfDay(value) {
      const startDate = new Date(value);
      const startOfDay = startDate.setHours(0, 0, 0, 0);
      return startOfDay;
    },
    submitChanges() {
      const rows = this.regiProduct;
      this.$store.commit("root/loading", true);
      let requiresSoftRefresh = [];
      rows.forEach((row, rowindex) => {
        let rowID = row._id;
        if (!row.name || row.name === "") {
          this.$customError(errormsg.input_option);
          requiresSoftRefresh.push(rowindex);
          return false;
        }
        if (Number.isNaN(parseFloat(row.price)) || parseFloat(row.price) < 0) {
          this.$customError(errormsg.price_invalid);
          requiresSoftRefresh.push(rowindex);
          return false;
        }
        if (!row.types || row.types.length === 0) {
          this.$customError(errormsg.input_option);
          requiresSoftRefresh.push(rowindex);
          return false;
        }
        const saleableitemsamename = this.regiProduct.filter(
          (d, index) => d.name === row.name && index !== rowindex
        );
        if (saleableitemsamename && saleableitemsamename.length > 0) {
          this.$customError(errormsg.regiproduct_saleableitemsamename);
          requiresSoftRefresh.push(rowindex);
          return false;
        }
        row.price = parseFloat(row.price).toFixed(2);
        row.price_int = parseInt(parseFloat(row.price).toFixed(2) * 100);
        const saleableItem = row;
        const update = {
          name: saleableItem.name,
          description: saleableItem.description,
          price: saleableItem.price_int,
          memberTypes: saleableItem.types,
          requireSizes: saleableItem.requireSizes,
          mandatory: saleableItem.mandatory,
          seasonPass: saleableItem.seasonPass,
          seasonPassExComps: saleableItem.seasonPassExComps,
          saleableExcludedAssociations: saleableItem.saleableExcludedAssociations,
        };

        // handle optional fields
        if (saleableItem.gender) update.gender = saleableItem.gender;
        if (saleableItem.ageFrom) update.ageFrom = parseInt(saleableItem.ageFrom);
        if (saleableItem.ageTo) update.ageTo = parseInt(saleableItem.ageTo);
        if (saleableItem.dateFrom) update.dateFrom = saleableItem.dateFrom;
        if (saleableItem.dateTo) update.dateTo = saleableItem.dateTo;
        if (saleableItem.url) update.url = saleableItem.url;
        if (saleableItem.meta) update.meta = saleableItem.meta;
        if (saleableItem.seasonPassExComps) {
          update.seasonPassExComps = saleableItem.seasonPassExCompsData
            ? saleableItem.seasonPassExComps.concat(
                saleableItem.seasonPassExCompsData.map((item) => item._id)
              )
            : saleableItem.seasonPassExComps;
          update.seasonPassExComps = [...new Set(update.seasonPassExComps)];
        }
        if (saleableItem.saleableExcludedAssociations) {
          update.saleableExcludedAssociations = saleableItem.saleableExcludedAssociationsData
            ? saleableItem.saleableExcludedAssociations.concat(
                saleableItem.saleableExcludedAssociationsData.map((item) => item._id)
              )
            : saleableItem.saleableExcludedAssociations;
          update.saleableExcludedAssociations = [...new Set(update.saleableExcludedAssociations)];
        }
        const url = `/nrl/api/v1/admin/registration-products/saleable-items/${rowID}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$customSuccess(`${update.name} saved`);
          })
          .catch((error) => {
            requiresSoftRefresh.push(rowindex);
            this.revertTableRow(requiresSoftRefresh);
            this.$customError(`${update.name}: ${error.response.data.message}`);
          });

        let prodImgS3DeleteVSave = [];
        if (this.productImages[rowID] && this.productImages[rowID].length > 0) {
          prodImgS3DeleteVSave.push(
            this.productImages[rowID].filter((p) => {
              return (!update.url || p !== update.url[0]) && p;
            })
          );
        }
        prodImgS3DeleteVSave.flat().forEach((img) => {
          const imageUrl = `products/resize/${img.split("products/resize/")[1]}`;
          const url = "/nrl/api/v1/admin/s3/image/remove";
          this.$http
            .post(url, { imageId: imageUrl })
            .then(() => {
              this.$store.commit("root/loading", false);
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(
                "Unused images not removed from database ",
                error.response.data.message
              );
            });
        });
      });
      if (requiresSoftRefresh.length > 0) {
        this.revertTableRow(requiresSoftRefresh);
      }
      this.productImages = {};
      this.bulkEdit = false;
      this.$store.commit("root/loading", false);
      return true;
    },
    handleBulkEdit() {
      if (!this.bulkEdit) {
        const uneditedProducts = this.regiProduct;
        uneditedProducts.forEach((product, i) => {
          const productCapture = {
            _id: product._id,
            name: product.name,
            description: product.description,
            price: product.price,
            types: product.types,
            requireSizes: product.requireSizes,
            mandatory: product.mandatory,
            seasonPass: product.seasonPass,
            seasonPassExComps: product.seasonPassExComps,
            seasonPassExCompsData: product.seasonPassExCompsData,
            saleableExcludedAssociations: product.saleableExcludedAssociations,
            saleableExcludedAssociationsData: product.saleableExcludedAssociationsData,
            gender: "",
            ageFrom: null,
            ageTo: null,
            dateFrom: "",
            dateTo: "",
            url: null,
            meta: null,
          };

          // handle optional fields
          if (product.gender) productCapture.gender = product.gender;
          if (product.ageFrom) productCapture.ageFrom = parseInt(product.ageFrom);
          if (product.ageTo) productCapture.ageTo = parseInt(product.ageTo);
          if (product.dateFrom) productCapture.dateFrom = parseInt(product.dateFrom);
          if (product.dateTo) productCapture.dateTo = parseInt(product.dateTo);
          if (product.url) {
            productCapture.url = product.url;
            if (this.productImages[product._id] && this.productImages[product._id].length > 0) {
              if (product.url && product.url.length > 0)
                this.productImages[product._id].push(product.url[0]);
            } else {
              if (product.url && product.url.length > 0)
                this.productImages[product._id] = [...product.url];
            }
          }
          if (product.meta) productCapture.meta = product.meta;

          this.uneditedSaleableItemsObj[i] = productCapture;
        });
      } else {
        try {
          this.$store.commit("root/loading", true);
          this.updating = true;
          let prodImgS3Delete = [];
          setTimeout(() => {
            const cancelChanges = this.uneditedSaleableItemsObj;
            console.log(cancelChanges);
            this.regiProduct.forEach((product, i) => {
              let prodID = product._id;
              if (this.productImages[prodID] && this.productImages[prodID].length > 0) {
                prodImgS3Delete.push(
                  this.productImages[prodID].filter((p) => {
                    return (!cancelChanges[i].url || p !== cancelChanges[i].url[0]) && p;
                  })
                );
              }
              this.regiProduct[i] = cancelChanges[i];
              this.editedUploadsObj[i] = [];
            });
            prodImgS3Delete.flat().forEach((img) => {
              const imageUrl = `products/resize/${img.split("products/resize/")[1]}`;
              const url = "/nrl/api/v1/admin/s3/image/remove";
              this.$http
                .post(url, { imageId: imageUrl })
                .then(() => {
                  this.$store.commit("root/loading", false);
                })
                .catch((error) => {
                  this.$store.commit("root/loading", false);
                  this.$customError(
                    "Unused images not removed from database ",
                    error.response.data.message
                  );
                });
            });
            this.productImages = {};
            this.updating = false;
            this.$store.commit("root/loading", false);
          }, 1000);
        } catch (e) {
          this.updating = false;
        }
      }
      this.bulkEdit = !this.bulkEdit;
    },
    handleRemoveRowSaleable(index) {
      this.$store.commit("root/loading", true);
      const saleableItem = this.regiProduct[index];
      this.$http
        .delete(`/nrl/api/v1/admin/registration-products/saleable-items/${saleableItem._id}`)
        .then(() => {
          this.regiProduct.splice(index, 1);
          if (saleableItem.url && saleableItem.url.length > 0) this.removeImageS3(saleableItem);
          this.saleableoptionsdisabled.splice(index, 1);
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch((error) => {
          this.$store.commit("root/loading", false);
          this.$customError(error.response.data.message);
        });
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
            this.$refs.upload.update(newFile, { error: "size" });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
          this.$store.commit("root/loading", false);
          this.$customError("Product Image upload has failed");
          this.uploads = this.uploads.filter((file) => file.id !== newFile.id);
        }
        if (newFile.success && !oldFile.success) {
          // success
          setTimeout(() => {
            this.$set(this.currentSaleableItem, "url", newFile.response.url);
            this.$store.commit("root/loading", false);
          }, 2500);
        }
      }

      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      return undefined;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }

      if (
        newFile &&
        newFile.error === "" &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }

      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    inputEditFile(newFile, oldFile, scope) {
      if (newFile && newFile.error) {
        // error
        this.$store.commit("root/loading", false);
        this.$customError("Product Image upload has failed");
        //here
        this.editedUploadsObj[scope.$index] = this.editedUploadsObj[scope.$index].filter(
          (file) => file.id !== newFile.id
        );
      } else if (newFile && !newFile.active && newFile.response) {
        // success
        setTimeout(() => {
          scope.row.url = new Array(newFile.response.url);
          const prodID = scope.row._id;
          if (this.productImages[prodID] && this.productImages[prodID].length > 0) {
            if (scope.row.url && scope.row.url.length > 0)
              this.productImages[prodID].push(scope.row.url[0]);
          } else {
            if (scope.row.url && scope.row.url.length > 0)
              this.productImages[prodID] = [...scope.row.url];
          }
          this.$store.commit("root/loading", false);
        }, 2500);
      }
      return undefined;
    },
    inputEditFilter(newFile, oldFile, prevent, scope) {
      if (newFile && !/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }

      if (
        newFile &&
        newFile.error === "" &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }

      this.$refs[`productUpload-${scope.$index}`].active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    async onCommandDropdown(command, scope) {
      // new product commands
      if (command === "replace-image") {
        this.removeImageS3(this.currentSaleableItem);
        const uploader = this.$refs.upload;
        uploader.$children[0].$el.click();
      }
      if (command === "remove-image-product") {
        this.uploads = [];
        this.removeImageS3(this.currentSaleableItem);
        this.$store.commit("root/loading", true);
      }

      // edit products command
      if (command === "replace-image-edit") {
        const uploader = this.$refs[`productUpload-${scope.$index}`];
        uploader.$children[0].$el.click();
      }
      if (command === "remove-image") {
        try {
          this.editedUploadsObj[scope.$index] = [];
          this.$store.commit("root/loading", true);
          // await this.$http.post(`${productUploadURL}`, { productid: scope.row._id });
          scope.row.url = null;
          this.$store.commit("root/loading", false);
        } catch (e) {
          this.$store.commit("root/loading", false);
        }
      }
    },
    removeImageS3(newItem) {
      const image = typeof newItem.url === "string" ? newItem.url : newItem.url[0];
      const imageUrl = `products/resize/${image.split("products/resize/")[1]}`;
      const url = "/nrl/api/v1/admin/s3/image/remove";
      this.$http
        .post(url, { imageId: imageUrl })
        .then(() => {
          newItem.url = null;
          this.$store.commit("root/loading", false);
        })
        .catch((error) => {
          this.$store.commit("root/loading", false);
          this.$customError(error.response.data.message);
        });
    },
  },
  computed: {
    showExcludedAssociations() {
      if (
        this.$store.getters["user/activeRole"].national_id === 32 &&
        [2].includes(this.$store.getters["user/activeRole"].type)
      ) {
        return true;
      }
      return false;
    },
    showExcludedEntities() {
      if (
        this.$store.getters["user/activeRole"].national_id === 31 &&
        [1, 2].includes(this.$store.getters["user/activeRole"].type)
      ) {
        return true;
      }
      return false;
    },
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    authRoles() {
      if (
        this.clearance === roles.superAdmin ||
        this.clearance === roles.stateAdmin ||
        this.clearance === roles.regionAdmin ||
        this.clearance === roles.associationAdmin
      ) {
        return true;
      }
      return false;
    },
    currentUpload() {
      if (this.uploads && this.uploads.length > 0) {
        const mostRecent = this.uploads.length - 1;
        return this.uploads[mostRecent];
      }
      return null;
    },
    saleableItemData() {
      const { _id } = this.regiProduct;
      const { name } = this.currentSaleableItem;
      return { _id, name };
    },
    regiProdId() {
      const { _id } = this.regiProduct;
      return _id;
    },
    productUploadURL() {
      return productUploadURL;
    },
    saleableMemberTypes() {
      return memberTypes.filter((member) =>
        this.products.map((product) => product.role).includes(member.type)
      );
    },
    saleableitemname: {
      get() {
        return this.currentSaleableItem.name;
      },
      set(value) {
        this.currentSaleableItem.name = value;
      },
    },
    saleableitemdesc: {
      get() {
        return this.currentSaleableItem.description;
      },
      set(value) {
        this.currentSaleableItem.description = value;
      },
    },
    saleableitemprice: {
      get() {
        return this.currentSaleableItem.price;
      },
      set(value) {
        this.currentSaleableItem.price =
          !Number.isNaN(parseFloat(value)) && parseFloat(value) >= 0 ? value : null;
      },
    },
    salelablemembertype: {
      get() {
        return this.currentSaleableItem.types.length > 0 ? this.currentSaleableItem.types : null;
      },
      set(value) {
        this.currentSaleableItem.types = value;
      },
    },
    saleableitemurl: {
      get() {
        return this.currentSaleableItem.url;
      },
      set(value) {
        this.currentSaleableItem.url = value;
      },
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
.is-disabled {
  border-color: #e4e7ed !important;
  color: #c0c4cc !important;
  background-color: #f5f7fa !important;
}
.hasUpload {
  border-radius: 25%;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.hasEditUpload {
  border-radius: 25%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  width: 100%;
}
.hasEditUpload .avatar-wrap {
  width: 100%;
  height: 100px;
}
.hasEditUpload .avatar {
  height: 100%;
}
.avatar,
.product-img {
  background-size: contain;
  background-position: center;
  height: 100%;
}
.product-img {
  height: 100% !important;
}
.avatar-wrap {
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid #fff;
  -webkit-box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  border-radius: 25%;
  width: 8rem;
  height: 8rem;
}
.upload-logo {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 3.5px;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: black;
  opacity: 0.6;
}
.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.product-upload {
  padding: 15px;
  border: 1px solid #ebeef5;
  background-color: #fff;
}

.el-input input {
  padding: 0 30px !important;
  border: 1px solid red;
}

.el-tag {
  margin-bottom: 0.8em;
  margin-right: 0.4em;
  // text inside color #666
}
</style>
