<template>
  <div>
    <el-table
      v-if="playerStats && playerStats.length > 0"
      size="small"
      :data="playerStats"
      :border="true"
      scrollY
      :maxHeight= "innerHeight"
    >
      <el-table-column
        prop="competition.season"
        label="Season"
        width="auto"
      >
      </el-table-column>
      <el-table-column
        prop="competition.orgtree.association.name"
        label="Association"
        width="auto"
      >
      </el-table-column>
      <el-table-column prop="club.name" label="Club" width="auto" >
        <template slot-scope="scope">
          {{ getClubName(playerStats[scope.$index]) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="competition.name"
        label="Competition"
        width="auto"
      >
      </el-table-column>
      <el-table-column
        prop="competition.ageLvl"
        label="Competition Age Group"
        width="auto"
      >
      </el-table-column>
      <el-table-column prop="team.name" label="Team" width="auto" >
      </el-table-column>
      <el-table-column prop="games" label="Games Played" width="auto" >
      </el-table-column>
      <el-table-column prop="tries" label="Tries" width="auto" >
      </el-table-column>
      <el-table-column prop="goals" label="Goals" width="auto" >
      </el-table-column>
      <el-table-column
        prop="fieldGoals"
        label="1pt Field Goals"
        width="auto"
      >
      </el-table-column>
      <el-table-column
        prop="fieldGoals2pt"
        label="2pt Field Goals"
        width="auto"
      >
      </el-table-column>
      <el-table-column
        prop="totalPoints"
        label="Total Points"
        width="auto"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import _ from "lodash";
import { EventBus } from "../../bus";



export default {
  data(){
    return {
       innerHeight: (window.innerHeight - 286) < 286 ? 286 : window.innerHeight - 250
    }
  },
  mounted() {
    EventBus.$on("memberUpdated", () => {
      this.$forceUpdate();
    });
  },
  props: {
    stats: { type: Array, required: true },
  },
  methods: {
    getClubName(data) {
      return _.get(
        data,
        "club.name",
        _.get(data, "team.orgtree.club.name", " ")
      );
    },
  },
  computed: {
    playerStats() {
      return this.stats || [];
    },
   
  },
};
</script>

<style lang="scss" scoped>

</style>
