<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br>
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="programIds" label="Program Name">
            <el-select
              v-model="filters.programIds"
              multiple
              filterable placeholder="Select a program">
              <el-option
                clearable
                v-for="item in myprograms"
                :key="item._id"
                :label="`${item.name} - ${formatStartDate(item.startDate)}`"
                :value="item._id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select
              v-model="filters.fileType"
              placeholder="Select a file type"
            >
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="includeDOB" label="Include Date of Birth">
            <el-checkbox v-model="filters.includeDOB" @change="checkDOB">
              Include DOB in report
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      title="Important Information"
      :visible.sync="showDOBInfo"
      width="30%"
      :before-close="closeDOBInfo"
    >
      <span v-html="dobPrivacyPolicy" :style="{'display': 'inline-block', 'wordBreak': 'break-word'}"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDOBInfo">I understand</el-button>
      </span>
    </el-dialog>
    <action-buttons
      submit
      submitText="Submit"
      :cancel="false"
      @submit="sendReport"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import ActionButtons from '@/components/ActionButtons.vue';
import {  dobPrivacyPolicy } from '../../../utils/constants';

export default {
  name: 'ProgramRegoReport',
  components: {
    ActionButtons
  },
  data() {
    return {
      filters: {
        fileType: 'csv',
        programIds: [],
        includeDOB: false,
      },
      filterRules: {
      },
      fileTypes: [
        { name: 'CSV', type: 'csv', default: true }
      ],
      myprograms: [],
      showDOBInfo: false,
      dobPrivacyPolicy,
    };
  },
  mounted() {
    this.loading = true;
    this.$http
      .get('/nrl/api/v1/admin/programs')
      .then((response) => {
        this.myprograms = response.data.data;
        this.$store.commit('root/loading', false);
        this.loading = false;
      })
      .catch(() => {
        this.$customError();
      });
  },
  methods: {
    formatStartDate(timestamp) {
      return moment(timestamp).format('DD/MM/YYYY HH:mm');
    },
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          const { programIds } = this.filters;
          this.$store.commit('root/loading', true);
          const body = {
            reportType: 'program-rego',
            fileType: this.filters.fileType,
            filters: { programIds },
            meta: {
              timezone: moment.tz.guess(),
            }
          };
          if (this.filters.includeDOB) {
            body.filters.includeDOB = true;
          }
          const url = '/nrl/api/v1/admin/reports';
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit('root/loading', false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit('root/loading', false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
    checkDOB() {
      if (this.filters.includeDOB) {
        this.showDOBInfo = true; // Show pop-up when DOB checkbox is checked
      }
    },
    closeDOBInfo() {
      this.showDOBInfo = false; // Close the modal
    },
  }
};
</script>

<style lang="scss" scoped>
  ::v-deep .el-input {
    width: 100% !important;
  }

  ::v-deep .el-select {
    width: 100% !important;
  }
</style>
