<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
    </p>
    <el-form
      label-position2="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="reportType" label="Report Type">
            <el-select v-model="filters.reportType" placeholder="Select a report type">
              <el-option
                v-for="reportType in reports"
                :label="reportType.name"
                :key="reportType.value"
                :value="reportType.value"
                :default="reportType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="dateTimeRange" label="Date Time Range">
            <el-date-picker
              v-model="filters.dateTimeRange"
              type="datetimerange"
              range-separator="|"
              start-placeholder="Start date"
              end-placeholder="End date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item
            prop="selectedEntity"
            :label="clearance === roles.clubAdmin ? 'Team' : 'Competition'"
          >
            <el-select
              :multiple="clearance === roles.clubAdmin"
              v-model="filters.selectedEntity"
              :placeholder="
                clearance === roles.clubAdmin ? 'Select a team' : 'Select a competition'
              "
              filterable
            >
              <el-option
                v-for="entity in myentity"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-button type="success" @click="sendReport">Individual sheets</el-button>
      <el-button v-if="isTFA" type="success" @click="sendConsolidateReport"
        >Consolidate Sheets</el-button
      >
    </div>
    <!-- <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" /> -->
  </div>
</template>

<script>
// import moment from 'moment-timezone';
import _ from "lodash";
import { saveAs } from "file-saver";
import ActionButtons from "@/components/ActionButtons.vue";
import { roles, errormsg, matchReports, nationals } from "@/utils/constants";

export default {
  name: "EntityDetailsReport",
  components: {
    ActionButtons
  },

  data() {
    return {
      nationals,
      myentity: false,
      filters: {
        reportType: null,
        dateTimeRange: null,
        selectedEntity: null
      },
      filterRules: {
        reportType: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        },
        dateTimeRange: {
          required: true,
          trigger: "blur",
          validator: (obj, val, callback) => {
            if (!val) {
              return callback(new Error(errormsg.select_option));
            }
            const [start, end] = val;
            const days = this.moment(end).diff(start, "days");
            if (days > 14) {
              return callback(new Error(errormsg.two_week_ranges));
            }
            return callback();
          }
        },
        selectedEntity: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        }
      },
      matchReports,
      roles
    };
  },
  beforeCreate() {},
  async mounted() {
    const { type, entity } = this.$store.getters["user/activeRole"];
    if (type === roles.clubAdmin) {
      // get my teams
      this.$http
        .get(`/nrl/api/v1/admin/teams/club/${entity._id}`)
        .then(response => {
          this.myentity = response.data.data;
        })
        .catch(() => {
          this.$customError("Error fetching teams");
        });
    } else {
      if (this.$store.getters["user/activeRole"].national_id === nationals.TFA) {
        this.filterRules.selectedEntity.required = false;
      }
      // get my competitions
      this.$http
        .get("/nrl/api/v1/admin/competitions")
        .then(response => {
          this.myentity = response.data.data;
        })
        .catch(() => {
          this.$customError("Error fetching teams");
        });
    }
  },
  computed: {
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    reports() {
      const national = this.$store.getters["user/activeRole"].national_id;
      return this.matchReports.filter(x => x.nationals.includes(national));
    }
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate(async valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            ...this.filters
          };
          const url = "/nrl/api/v1/admin/matches/bulk-report";
          try {
            const response = await this.$http.post(url, body);

            if (response.data.data && response.data.data.length > 0) {
              const zip = new this.Zipper();
              const promises = [];

              for (const [index, obj] of response.data.data.entries()) {
                if (obj && obj.definition) {
                  const [start, end] = this.filters.dateTimeRange;
                  const prefix =
                    this.filters.selectedEntity ||
                    _.get(obj.match, "competition._id", "") ||
                    `${this.moment(start).format("DD-MM-YYYYY")}_${this.moment(end).format(
                      "DD-MM-YYYYY"
                    )}`;
                  // eslint-disable-next-line no-loop-func
                  promises.push(
                    new Promise(resolve => {
                      const document = this.pdfMake.createPdf(obj.definition);
                      document.getBlob(blob => {
                        zip.file(`${prefix}_${this.filters.reportType}_${index + 1}.pdf`, blob);
                        resolve();
                      });
                    })
                  );
                }
              }
              try {
                await Promise.all(promises);
                zip.generateAsync({ type: "blob" }).then(blob => {
                  saveAs(blob, "match_reports.zip");
                });
              } catch (e2) {
                this.$customError("Error generating zip file");
                throw new Error("Error generating zip file");
              }
            } else if (response.data.data) {
              this.$customError("There are no matches within the given parameter");
            } else {
              this.$customError("Error generating pdf");
            }
            this.$store.commit("root/loading", false);
          } catch (e) {
            this.$store.commit("root/loading", false);
            this.$customError();
          }
        } else {
          return false;
        }
        return true;
      });
    },
    sendConsolidateReport() {
      this.$refs.filters.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            ...this.filters
          };
          const url = "/nrl/api/v1/admin/matches/consolidate/bulk-report";
          this.$http
            .post(url, body)
            .then(response => {
              if (response.data.data && response.data.data.length > 0) {
                const zip = new this.Zipper();
                const promises = [];

                for (const [index, obj] of response.data.data.entries()) {
                  if (obj && obj.definition) {
                    const [start, end] = this.filters.dateTimeRange;
                    const prefix =
                      this.filters.selectedEntity ||
                      _.get(obj.match, "competition._id", "") ||
                      `${this.moment(start).format("DD-MM-YYYYY")}_${this.moment(end).format(
                        "DD-MM-YYYYY"
                      )}`;
                    let fileName = "";
                    if (obj.match._id) {
                      fileName = `${prefix}_${this.filters.reportType}_${index + 1}`;
                    } else {
                      fileName = `${obj.match.year}_${this.filters.reportType}_${obj.match.timestamp}`;
                    }
                    // eslint-disable-next-line no-loop-func
                    promises.push(
                      new Promise(resolve => {
                        const document = this.pdfMake.createPdf(obj.definition);
                        document.getBlob(blob => {
                          // zip.file(`${prefix}_${this.filters.reportType}_${index + 1}.pdf`, blob);
                          zip.file(`${fileName}.pdf`, blob);
                          resolve();
                        });
                      })
                    );
                  }
                }

                Promise.all(promises)
                  .then(() => {
                    zip.generateAsync({ type: "blob" }).then(blob => {
                      saveAs(blob, "match_reports.zip");
                    });
                  })
                  .catch(e => {
                    this.$customError("Error generating zip file");
                  });
              } else if (response.data.data) {
                this.$customError("There are no matches within the given parameter");
              } else {
                this.$customError("Error generating pdf");
              }
              this.$store.commit("root/loading", false);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-date-editor {
  width: 100%;
}
</style>
