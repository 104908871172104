/* eslint-disable no-return-assign */
import _ from 'lodash';

const initState = () => ({
  search: '',
  data: [],
});

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateSearch: (state, payload) => state.search = payload,
    updateData: (state, data) => state.data = data,
    updateUser: (state, user) => {
      if (state.data.length) {
        const index = _.findIndex(state.data, x => x._id === user._id);
        _.merge(state.data[index], user);
      }
    }
  }
};
