<template>
  <div class="contact">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="status" label="Status">
            <el-select v-model="filters.status" placeholder="Select a status">
              <el-option
                v-for="status in tribunalStatus"
                :label="status"
                :key="status"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="season" label="Season">
            <el-select v-model="filters.season" filterable multiple placeholder="Select a season">
              <el-option
                v-for="season in seasons"
                :label="season.name"
                :key="season.value"
                :value="season.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :gutter="8">
          <el-form-item prop="includeDOB" label="Include Date of Birth">
            <el-checkbox v-model="filters.includeDOB" @change="checkDOB">
              Include DOB in report
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      title="Important Information"
      :visible.sync="showDOBInfo"
      width="30%"
      :before-close="closeDOBInfo"
    >
    <span v-html="dobPrivacyPolicy" :style="{'display': 'inline-block', 'wordBreak': 'break-word'}"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDOBInfo">I understand</el-button>
      </span>
    </el-dialog>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, tribunalStatus, mysidelineSeasons, dobPrivacyPolicy } from "../../../utils/constants";

export default {
  name: "TribunalReport",
  components: {
    ActionButtons,
  },
  data() {
    return {
      tribunalStatus,
      filters: {
        fileType: "csv",
        status: undefined,
        season: "",
        includeDOB: false,
      },
      filterRules: {
        fileType: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        status: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        season: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      showDOBInfo: false,
      dobPrivacyPolicy,
    };
  },
  computed: {
    seasons() {
      return mysidelineSeasons.map((v) => ({ name: v.toString(), value: v }));
    },
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "tribunal",
            fileType: this.filters.fileType,
            filters: {
              status: this.filters.status,
              seasons: this.filters.season,
            },
            meta: {
              timezone: moment.tz.guess(),
            },
          };
          if (this.filters.includeDOB) {
            body.filters.includeDOB = true;
          }
          const url = "/nrl/api/v1/admin/reports";
          if (body.filters.status === "All") delete body.filters.status;
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
    checkDOB() {
      if (this.filters.includeDOB) {
        this.showDOBInfo = true; // Show pop-up when DOB checkbox is checked
      }
    },
    closeDOBInfo() {
      this.showDOBInfo = false; // Close the modal
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>
