<template>
  <div v-if="competition">
    <el-form
      label-position="top"
      :model="competition.meta.playerPoints"
      id="playerPoints"
      ref="playerPoints"
    >
      <div class="tw-flex tw-items-end tw-gap-3 tw-pb-3 tw-border-r tw-border-gray-100">
        <el-col :span="8">
          <el-form-item prop="use" label="Use">
            <el-select v-model="competition.meta.playerPoints.use">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <data-tables :data="competition.teams">
        <el-table-column prop="name" label="Name"> </el-table-column>
        <el-table-column label="Max Points">
          <template slot-scope="scope">
            <el-input type="number" v-model="scope.row.maxPlayerPoints" min="0" />
          </template>
        </el-table-column>
      </data-tables>
    </el-form>
    <action-buttons submit cancel @submit="updatePlayerPoints()" @cancel="cancelForm()" />
  </div>
</template>

<script>
import ActionButtons from '@/components/ActionButtons.vue'
import { EventBus } from '../../bus'

export default {
  components: { ActionButtons },
  props: {
    comp: { type: Object, required: true }
  },
  data () {
    return {
      competition: undefined
    }
  },
  mounted () {
    const { id } = this.$route.params;
    this.$http
      .get(`/nrl/api/v1/admin/competitions/${id}`)
      .then(response => {
        // eslint-disable-next-line no-multi-assign
        this.competition = response.data.data
        this.loading = false
      })
      .catch(() => {
        this.loading = false
        this.$customError()
      })
  },
  methods: {
    cancelForm () {
      this.$router.push({
        name: 'competitions.list'
      })
    },
    updatePlayerPoints () {
      const { use } = this.competition.meta.playerPoints
      this.$store.commit('root/loading', true)
      const url = `/nrl/api/v1/admin/competitions/${this.competition._id}`
      const update = {
        meta: {
          playerPoints: {
            use
          }
        },
        teams: this.competition.teams.map(team => ({
          ...team,
          maxPlayerPoints: parseInt(team.maxPlayerPoints) || 0
        }))
      }
      this.$http
        .put(url, update)
        .then(response => {
          this.$store.commit('root/loading', false)
          this.$customSuccess()
          EventBus.$emit('updateCompetition', response.data.data)
        })
        .catch(() => {
          this.$store.commit('root/loading', false)
          this.$customError()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.display-form {
  margin-top: 0.45rem;
}

.el-input,
.el-select {
  width: 100%;
}
</style>
