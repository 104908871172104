var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"autoapproval",attrs:{"label-position":"top","rules":_vm.rules,"model":_vm.currentAutoApproval}},[_c('el-row',{attrs:{"type":"flex","align":"bottom","gutter":10}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"memberType","label":"Member Type"}},[_c('el-select',{attrs:{"placeholder":"Select type"},model:{value:(_vm.currentAutoApproval.memberType),callback:function ($$v) {_vm.$set(_vm.currentAutoApproval, "memberType", $$v)},expression:"currentAutoApproval.memberType"}},_vm._l((_vm.filteredMemberTypes),function(item){return _c('el-option',{key:item.type,attrs:{"label":item.name,"value":item.type}})}),1)],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"dobFrom","label":"DOB From"}},[_c('el-date-picker',{attrs:{"type":"date","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","placeholder":""},model:{value:(_vm.currentAutoApproval.dobFrom),callback:function ($$v) {_vm.$set(_vm.currentAutoApproval, "dobFrom", $$v)},expression:"currentAutoApproval.dobFrom"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"dobTo","label":"DOB To"}},[_c('el-date-picker',{attrs:{"type":"date","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","placeholder":""},model:{value:(_vm.currentAutoApproval.dobTo),callback:function ($$v) {_vm.$set(_vm.currentAutoApproval, "dobTo", $$v)},expression:"currentAutoApproval.dobTo"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"autoApproval","label":"Set Auto-approvals for"}},[_c('el-select',{attrs:{"placeholder":""},model:{value:(_vm.currentAutoApproval.autoApproval),callback:function ($$v) {_vm.$set(_vm.currentAutoApproval, "autoApproval", $$v)},expression:"currentAutoApproval.autoApproval"}},_vm._l((_vm.autoApprovals),function(ref){
var type = ref.type;
var name = ref.name;
return _c('el-option',{key:type,attrs:{"label":name,"value":type}})}),1)],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{staticClass:"btn-container"},[_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.submitForm('autoapproval')}}},[_vm._v("Add")])],1)],1)],1)],1),(_vm.entityApprovals && _vm.entityApprovals.length > 0)?_c('data-tables',{staticClass:"data-table",attrs:{"data":_vm.entityApprovals,"table-props":_vm.tableProps,"layout":"table"}},[_c('el-table-column',{attrs:{"label":"Member Type","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{attrs:{"disabled":!_vm.optionsdisabled[scope.$index],"placeholder":"Member Type","filterable":""},model:{value:(scope.row.memberType),callback:function ($$v) {_vm.$set(scope.row, "memberType", $$v)},expression:"scope.row.memberType"}},_vm._l((_vm.filteredMemberTypes),function(ref){
var name = ref.name;
var type = ref.type;
return _c('el-option',{key:type,attrs:{"label":name,"value":type}})}),1)]}}],null,false,472598808)}),_c('el-table-column',{attrs:{"label":"Date of Birth From","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-date-picker',{attrs:{"type":"date","disabled":!_vm.optionsdisabled[scope.$index],"format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","placeholder":""},model:{value:(scope.row.dobFrom),callback:function ($$v) {_vm.$set(scope.row, "dobFrom", $$v)},expression:"scope.row.dobFrom"}})]}}],null,false,2915900789)}),_c('el-table-column',{attrs:{"label":"Date of Birth To","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-date-picker',{attrs:{"type":"date","disabled":!_vm.optionsdisabled[scope.$index],"format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","placeholder":""},model:{value:(scope.row.dobTo),callback:function ($$v) {_vm.$set(scope.row, "dobTo", $$v)},expression:"scope.row.dobTo"}})]}}],null,false,1955417688)}),_c('el-table-column',{attrs:{"label":"Auto-approvals for","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{attrs:{"disabled":!_vm.optionsdisabled[scope.$index],"placeholder":""},model:{value:(scope.row.autoApproval),callback:function ($$v) {_vm.$set(scope.row, "autoApproval", $$v)},expression:"scope.row.autoApproval"}},_vm._l((_vm.autoApprovals),function(ref){
var type = ref.type;
var name = ref.name;
return _c('el-option',{key:type,attrs:{"label":name,"value":type}})}),1)]}}],null,false,889829517)}),_c('el-table-column',{attrs:{"label":"Edit/Remove","fixed":"right","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{on:{"click":function($event){return _vm.handleEditRow(scope.$index)}}},[(!_vm.optionsdisabled[scope.$index])?[_vm._v(" Edit ")]:[_vm._v(" Save ")]],2),_c('el-button',{on:{"click":function($event){return _vm.handleRemoveRow(scope.$index)}}},[_vm._v("Remove")])]}}],null,false,3978897086)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }