<template>
  <div>
    <el-button icon='el-icon-plus'
      v-if="clearance === roles.superAdmin"
      type="success"
      @click="handleAddRegion" >
      Add Region
    </el-button>
    <el-row class="search-bar" :gutter="10">
      <el-col :span="6">
        <el-input
          autocomplete="off"
          v-model="filters[0].value"
          placeholder="Search...">
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-button @click="filterVisible=true"> Filters </el-button>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>

    <data-tables
      v-loading="loading"
      :filters="filters"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table">
      <el-table-column
        fixed
        prop="_id"
        label="Id"
        width="100">
      </el-table-column>
      <el-table-column
        fixed
        prop="name"
        label="Name"
        width="250">
      </el-table-column>
      <el-table-column
        prop="orgtree.state.name"
        label="State Name"
        width="250">
      </el-table-column>
      <el-table-column
        prop="orgtree.national.name"
        label="National Name"
        width="250">
      </el-table-column>
      <el-table-column
        :formatter="statusFormatter"
        prop="isActive"
        label="Status"
        width="250">
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="250">
      </el-table-column>
    </data-tables>

  </div>
</template>

<script>
import _ from 'lodash';
import { roles } from '@/utils/constants';
import FilterPage from '@/views/EntityFilterPage.vue';

export default {
  name: 'RegionsList',
  components: { FilterPage },
  props: {
  },
  data() {
    return {
      loading: true,
      tableData: [],
      filterVisible: false,
      filters: [{
        value: this.$store.state.entities.regions.search,
        prop: 'name',
      }, {
        value: [],
      }],
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      roles,
      allStatus: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false }
      ]
    };
  },
  mounted() {
    const dataStored = this.fetchStore();
    if (!dataStored) {
      this.$http
        .get('/nrl/api/v1/admin/regions')
        .then((response) => {
          this.tableData = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    } else {
      this.loading = false;
    }
  },
  methods: {
    fetchStore() {
      const { data } = this.$store.state.entities.regions;
      if (data.length) {
        this.tableData = data;
        return true;
      }
      return false;
    },
    sendFilter(event) {
      // store filter
      this.$store.commit('entities/updateFilters', { filters: { isActive: event.isActive }, entity: 'regions' });

      this.$store.commit('root/loading', true);
      const { isActive: activeArray } = event;
      const isActive = activeArray.length > 0 ? activeArray[0] : null;
      const params = { isActive };
      this.$http
        .get('/nrl/api/v1/admin/regions', { params })
        .then((response) => {
          this.tableData = response.data.data;
          this.$store.commit('root/loading', false);
          this.filterVisible = false;
        })
        .catch(() => {
          this.$store.commit('root/loading', false);
          this.$customError();
          this.filterVisible = false;
        });
    },
    filterStatus(value, row) {
      return value === row.isActive;
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'regions.update',
        params: {
          type: 'update',
          region: row,
          id: row._id,
        },
      });
    },
    handleAddRegion() {
      this.$router.push({
        name: 'regions.add',
        params: {
          type: 'insert',
        },
      });
    },
    modifiedFormatter(row) {
      return (row.modifiedAt ? this.moment(row.modifiedAt).format('MMMM Do YYYY, h:mm:ss a') : 'NA');
    },
    statusFormatter(row) {
      return row.isActive ? 'Active' : 'Inactive';
    }
  },
  computed: {
    clearance() {
      return this.$store.getters['user/activeRole'].type;
    },
  },
  watch: {
    tableData(data) {
      this.$store.commit('entities/updateData', { data, entity: 'regions' });
    },
    filters: {
      deep: true,
      // eslint-disable-next-line func-names
      handler: _.debounce(function (val) { this.$store.commit('entities/updateSearch', { search: val[0].value, entity: 'regions' }); }, 500)
    }
  }
};
</script>

<style scoped lang="scss">
  .search-bar {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .data-table {
    width: 100%;
  }

  .el-pagination {
    margin-top: 1rem !important;
  }


</style>
