<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br>
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="fileType" label="File Type">
            <el-select
              v-model="filters.fileType"
              placeholder="Select a file type"
            >
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="season" label="Season">
            <el-select
              v-model="filters.season"
              filterable
              multiple
              placeholder="Select a season"
            >
              <el-option
                v-for="season in seasons"
                :label="season.name"
                :key="season.value"
                :value="season.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="4">
          <el-form-item prop="ageLevel" label="Age Level">
            <el-select
              clearable
              v-model="filters.selectedAgeLevel"
              placeholder="Select a team age level"
              multiple
            >
              <el-option
                v-for="{ name } in ageLevels"
                :label="name"
                :key="name"
                :value="name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-row :gutter="10" v-if="clearance === roles.associationAdmin">
        <el-col :span="8">
          <el-form-item prop="Competition" label="Competition">
            <el-select
              clearable
              filterable
              v-model="filters.selectedCompetition"
              placeholder="Select a competition"
              multiple
            >
              <el-option
                v-for="{ name, _id } in competitions"
                :label="name"
                :key="_id"
                :value="_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons
      submit
      submitText="Submit"
      :cancel="false"
      @submit="sendReport"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import ActionButtons from '@/components/ActionButtons.vue';
import {
  errormsg, mysidelineSeasons, roles, ageLevels
} from '@/utils/constants';

export default {
  name: 'CompetitionCasesInjuryReport',
  components: {
    ActionButtons
  },
  mounted() {
    if (this.clearance === roles.associationAdmin) {
      this.$store.commit('root/loading', true);
      this.$http.get('/nrl/api/v1/admin/competitions')
        .then((res) => {
          this.competitions = res.data.data;
          this.$store.commit('root/loading', false);
        })
        .catch(() => {
          this.$customError('Unable to fetch competitions');
          this.$store.commit('root/loading', false);
        });
    }
  },
  data() {
    return {
      competitions: [],
      filters: {
        season: '',
        fileType: 'csv',
        selectedTeam: null,
        // selectedAgeLevel: null,
      },
      filterRules: {
        fileType: {
          required: true,
          message: errormsg.select_option,
          trigger: 'blur',
        },
        selectedTeam: {
          required: true,
          trigger: 'blur',
          message: errormsg.select_option,
        },
        season: {
          required: true,
          message: errormsg.select_option,
          trigger: 'blur',
        }
      },
      fileTypes: [
        { name: 'CSV', type: 'csv', default: true }
      ],
      myteams: [],
      // ageLevels,
      roles,
    };
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit('root/loading', true);
          const body = {
            reportType: 'ladders',
            fileType: this.filters.fileType,
            filters: {
              seasonIds: this.filters.season,
              competitionIds: this.filters.selectedCompetition,
              // ageFilter: this.filters.selectedAgeLevel,
            },
            meta: {
              timezone: moment.tz.guess(),
            }
          };
          const url = '/nrl/api/v1/admin/reports';
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit('root/loading', false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit('root/loading', false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  },
  computed: {
    seasons() {
      return mysidelineSeasons.map(v => ({ name: v.toString(), value: v }));
    },
    clearance() {
      return this.$store.getters['user/activeRole'].type;
    },
  },
};
</script>
