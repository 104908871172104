<template>
  <!-- <div class="container">
    <el-card class="card">
      <img src="~@/assets/MySideline_PrimaryLogo_black.svg">
      <h3>Under Construction</h3>
      <p>We're working to bring you more and better features.</p>
    </el-card>
  </div> -->
  <div class="tw-flex tw-justify-center tw-mt-4 ">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-w-full tw-max-w-xl tw-p-10 tw-bg-white tw-rounded tw-shadow-2xl"
    >
      <img class="tw-max-w-xs " src="~@/assets/MySideline_PrimaryLogo_black.svg" />
      <h3 class="tw-pt-10 tw-text-lg tw-font-bold">Under Construction</h3>
      <p>We're working to bring you more and better features.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnderConstruction"
};
</script>

<style scoped lang="scss">
.container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .card {
    margin: 20px auto;
    width: 50%;
    max-width: 100%;
    overflow: hidden;
    text-align: center;

    img {
      width: 70%;
    }
  }
  @media only screen and (max-width: $sm) {
    .card {
      width: 80%;
    }
  }
}
</style>
