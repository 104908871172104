<template>
  <div>
    <label
      >Log ID
      <div class="text">{{ logDetail._id }}</div>
    </label>

    <!-- <label
      >Season
      <div class="text">{{ logDetail.season }}</div>
    </label> -->

    <label
      >Entity Name
      <div class="text">{{ logDetail.entity }}</div>
    </label>

    <label
      >Send Date
      <div class="text">{{ dateFormatter(logDetail) }}</div>
    </label>

    <label
      >Sender
      <div class="text">{{ logDetail.sender }}</div>
    </label>

    <label
      >Subject
      <div class="text">{{ logDetail.subject }}</div>
    </label>

    <label
      >Content
      <div class="text" v-html="logDetail.content"></div>
    </label>

    <label
      >Recipients
      <div class="text tw-break-words">
        {{ logDetail.recipients }}
      </div>
    </label>
  </div>
</template>

<script>
import { get } from "lodash";
export default {
  data() {
    return {
      logDetail: {},
    };
  },
  mounted() {
    this.$http
      .get(`/nrl/api/v1/admin/email-comms/${this.$route.params.id}`)
      .then((response) => {
        this.logDetail = response.data.data;
      })
      .catch((error) => {
        this.$customError(get(error, "response.data.message"));
        this.$store.commit("root/loading", false);
      });
  },
  methods: {
    dateFormatter(row) {
      const timeGiven =
        typeof row.createdAt === "number"
          ? this.moment(row.sendDate).format("HH:mm")
          : row.createdAt;
      const time = timeGiven ? timeGiven + ", " : "";
      return row.createdAt ? time + this.moment(row.createdAt).format("MMMM Do YYYY") : "NA";
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .text {
    width: 100% !important;
  }
}

label {
  @apply tw-text-sm tw-leading-10 tw-box-border tw-text-gray-600 tw-uppercase;
}

.text {
  @apply tw-text-sm tw-leading-10 tw-box-border tw-text-gray-400 tw-normal-case tw-bg-gray-100 tw-border tw-border-gray-200 tw-font-normal tw-w-2/4 tw-px-2.5;
}
</style>
