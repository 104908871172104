<template>
  <scroll-sync
    horizontal
    class="scroller venue-container syncscroll tw-flex tw-overflow-y-hidden tw-overflow-x-hidden tw-flex-1 tw-gap-x-2"
  >
    <div
      class="track time tw-sticky tw-left-0"
      style="background-color: #f6f6f6; border-radius: 2px 2px 0 0; height: 56px"
    ></div>
    <!-- flex-basis: calc(11% * ${venue.venueFields.length}); -->
    <div
      v-for="(venue, i) in grid.groupsLvl1"
      class="track tw-grid tw-gap-1"
      :style="`flex-basis: calc(11% * ${venue.venueFields.length}); grid-template-columns: repeat(${venue.venueFields.length}, minmax(266px, ${columnMaxWidth}));`"
      :key="i"
    >
      <div
        class="venue"
        :style="`grid-column: span ${venue.venueFields.length} / span ${venue.venueFields.length}; background: #FFFFFF; border-radius: 2px; padding: 0 16px;`"
      >
        <div class="heading">
          <span>{{ venue.content + " " }}</span
          ><span v-if="venue.venueTz && venue.venueTz !== timezone">
            {{ " (" + showTimezone(venue.venueTz) + ")" }}</span
          >
        </div>
      </div>
      <div
        v-for="(field, i) in venue.venueFields"
        :key="`${field}-${i}`"
        style="background: #f6f6f6; border-radius: 2px 2px 0 0"
      >
        <div class="sub-heading">
          {{ field.content !== "undefined" ? field.content : "NA" }}
        </div>
      </div>
    </div>
    <div
      v-if="!grid.groupsLvl1.length"
      class="track tw-grid tw-gap-1"
      style="grid-template-columns: repeat(1, minmax(248px, 1fr))"
    >
      <div class="venue" style="background: rgba(255, 255, 255, 0.8); border-radius: 2px">
        <div class="heading">
          <span> No Scheduled Matches</span>
        </div>
      </div>
    </div>
  </scroll-sync>
</template>
<script>
import _ from "lodash";
import ScrollSync from "vue-scroll-sync";

export default {
  name: "Venues",
  props: {
    grid: Object,
    timezone: String,
  },
  components: {
    ScrollSync,
  },
  computed: {
    columnMaxWidth() {
      if (this.noCols === 1) return "700px";
      if (this.noCols === 2) return "500px";
      if (this.noCols >= 3) return "266px";
      return "1fr";
    },
    noCols() {
      let gridNoCols = 0;
      this.grid.groupsLvl1.forEach((v) => {
        gridNoCols += v.venueFields.length;
      });
      return gridNoCols;
    },
  },
  methods: {
    showTimezone(tz) {
      const tzArr = tz.split("/");
      if (tzArr[0] === "Australia") return tzArr[1] + " Time";
      return tz;
    },
  },
};
</script>
<style scoped>
.headers .time {
  z-index: 20;
}

.heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  text-align: center;
  color: #098255;
  z-index: 1;
  padding-left: 16px;
}
.heading span {
  font-weight: 500;
  font-size: 9px;
  line-height: 8px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.sub-heading {
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: sticky;
  top: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #4d4d4d;
  z-index: 1;
}

.time {
  flex: 0 0 64px !important;
  position: -webkit-sticky;
  overflow-x: hidden;
}

.tracks .time {
  z-index: 15;
}
.track {
  flex: 1 0 11%;
}
.track:last-of-type > div {
  border-right: 0;
}
.track.time {
  background-color: #f6f6f6;
  border-radius: 0 0 2px 2px;
}
.track + .track {
  margin-left: -1px;
}

.venue {
  height: 24px;
  display: flex;
  align-items: center;
}
.track .venue:only-child {
  height: 100%;
}
</style>
