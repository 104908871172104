<template>
  <div class="input-container tw-flex tw-gap-x-4 tw-mt-8 tw-mb-4">
    <div class="tw-inline-flex tw-flex-col tw-gap-y-1.5">
      <label> Date </label>
      <div class="tw-inline-flex tw-relative tw-justify-self-start">
        <button @click="$emit('jumpDay', 'subtract')" class="jump-back" style="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="tw-h-3.5 tw-w-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button @click="$emit('jumpDay', 'add')" class="jump-forward">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="tw-h-3.5 tw-w-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <el-date-picker
          v-model="grid.date.day"
          :placeholder="grid.date.day.format('DD/MM/YYYY')"
          type="date"
          value-format="timestamp"
          align="right"
          prefix-icon="tw-hidden"
          class="admin-input__date-picker"
          :clearable="false"
          disabled
        >
        </el-date-picker>
      </div>
    </div>
    <div class="tw-inline-flex tw-flex-col tw-gap-y-1.5">
      <label> Timeslot </label>
      <el-select
        class="admin-input__date-picker"
        :value="increment"
        @change="$emit('updateIncrement', $event)"
      >
        <el-option label="1 Hour" key="1-hour" :value="60"></el-option>
        <el-option label="30 minutes" key="30-mins" :value="30"></el-option>
        <el-option label="15 minutes" key="15-minutes" :value="15"></el-option>
        <el-option label="5 minutes" key="5-mins" :value="5"></el-option>
      </el-select>
    </div>
    <div class="tw-inline-flex tw-flex-col tw-gap-y-1.5">
      <label> Between </label>
      <div class="tw-flex tw-gap-1">
        <el-time-select
          placeholder="Start time"
          v-model="startTime"
          class="admin-input__date-picker"
          value-format="H:mma"
          format="H:mma"
          :clearable="false"
          :picker-options="{
            step: '01:00',
            start: '00:00',
            end: '23:00',
          }"
          @change="$emit('updateTimeRange', true, $event)"
        >
        </el-time-select>
        <span
          class="tw-self-center"
          style="
            font-weight: 500;
            font-size: 10px;
            line-height: 8px;
            text-align: center;
            color: #333333;
          "
          >&</span
        >
        <el-time-select
          placeholder="End time"
          v-model="endTime"
          class="admin-input__date-picker"
          value-format="H:mma"
          :clearable="false"
          format="H:mma"
          :picker-options="{
            step: `01:00`,
            start: '01:00',
            end: '24:00',
            minTime: startTime,
          }"
        >
        </el-time-select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Match-Grid-Inputs",
  props: {
    grid: Object,
    timesBetween: Object,
    timeslotIncrements: Number,
  },
  computed: {
    endTime: {
      get() {
        const timeStr = `${this.timesBetween.finish}:00`;
        const inputTime = timeStr.length === 4 ? "0" + timeStr : timeStr;
        return inputTime;
      },
      set(value) {
        this.$emit("updateTimeRange", false, value);
      },
    },
    increment() {
      return this.timeslotIncrements;
    },
    startTime: {
      get() {
        const timeStr = `${this.timesBetween.start}:00`;
        const inputTime = timeStr.length === 4 ? "0" + timeStr : timeStr;
        return inputTime;
      },
      set(value) {
        this.$emit("updateTimeRange", true, value);
      },
    },
  },
};
</script>

<style scoped>
.input-container label {
  font-weight: 500;
  font-size: 9px;
  line-height: 8px;
  color: #333333;
}
</style>
