<template>
  <div
    :data-game-id="match.id"
    :class="`${match.className} tw-flex tw-flex-col tw-gap-0.5 details tw-relative tw-z-10 admin-shadow__subtle`"
    :style="`border-top:2px solid ${cardColor};`"
    :draggable="match.selectable"
  >
    <div
      class="accordion tw-flex tw-flex-col tw-gap-2"
      data-target=".panel"
      aria-expanded="false"
      aria-has-icon="true"
    >
      <div class="tw-flex tw-justify-between tw-items-center">
        <div class="tw-flex tw-gap-1 tw-items-center">
          <span :class="`status ${match.status}`">{{ matchStatus }}</span>
          <span
            class="round-time"
            style="
              font-size: 10px;
              line-height: 10px;
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-end;
            "
            >{{ match.time }}-{{ match.endTime }}</span
          >
        </div>
        <div class="icon-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="tw-w-4 tw-h-4 no-edit"
          >
            <path
              fill-rule="evenodd"
              d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
              clip-rule="evenodd"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="tw-h-4 tw-w-4 icon-default"
            style="background-color: #ededed; color: #666666; border-radius: 100%"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
      <div class="tw-inline-flex match-title">
        <span style="" v-html="match.title"></span>
      </div>
    </div>
    <div class="panel">
      <p>
        {{ match.competition.name }}
        <br />{{ match.round }}
      </p>
      <button
        class="admin-button__tiny prim-bg"
        style="padding-top: 4px; padding-bottom: 4px; align-items: center; gap: 2px"
      >
        <span
          style="
            color: #fff;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.25px;
          "
        >
          Edit
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="tw-self-baseline"
          style="color: #fff"
        >
          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
          <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Match-Card",
  props: {
    colors: Array,
    compList: Array,
    match: Object,
  },
  computed: {
    cardColor() {
      return this.colors[
        _.findIndex(this.compList, {
          _id: _.get(this.match.competition, "_id"),
        }) % this.colors.length
      ][1];
    },
    matchStatus() {
      let status;
      switch (this.match.status) {
        case "pre-game":
          status = "Pre-Game";
          break;
        case "final":
          status = "Finalised";
          break;
        case "washout":
          status = "Washed Out";
          break;
        case "postponed":
          status = "Postponed";
          break;
        case "forfeit":
          status = "Forfeit";
          break;
        case "disputed":
          status = "Disputed";
          break;
        default:
          status = "";
          break;
      }
      return status;
    },
  },
};
</script>
<style scoped>
.status {
  padding: 4px 5px 3px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 9px;
  line-height: 9px;
}
.status.pre-game {
  background-color: #daece5;
  color: #26463a !important;
}
.status.pre-game ~ .round-time,
.status.final ~ .round-time {
  color: #26463a;
}
.status.final {
  background-color: #26463a;
  color: #fff !important;
}
.status.disputed,
.status.washout,
.status.forfeit {
  background-color: #d0011b;
  color: #fff !important;
}
.status.forfeit ~ .round-time,
.status.disputed ~ .round-time,
.status.washout ~ .round-time {
  color: #d0011b;
}
.read-only {
  opacity: 0.5;
}

.icon-box {
  display: flex;
  column-gap: 8px;
}
.read-only .icon-box .no-edit {
  display: initial;
}
.no-edit {
  display: none;
}

.match-title span {
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: #222222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
