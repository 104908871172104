<template>
  <div>
    <el-form
      label-position="top"
      :model="comp && comp.additionalDetails"
      :rules="additionalDetailsRules"
      id="additionalDetailsRules"
      ref="additionalDetailsRules"
      v-if="comp"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="interchangeCapacity" label="Interchange Capacity">
            <el-select v-model="comp.additionalDetails.interchangeCapacity">
              <el-option
                v-for="option in options"
                :key="option"
                :label="option.toString()"
                :value="option"
              />
              <el-option label="Unlimited" value="unlimited" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item
            prop="headInjuryAssessmentInUse"
            label="Head Injury Assessment In Use"
          >
            <el-select
              v-model="comp.additionalDetails.headInjuryAssessmentInUse"
            >
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
          <el-alert type="info" :closable="false">
            <p>15 minutes for Assessment</p>
          </el-alert>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="sinBinInUse" label="Sin Bin In Use">
            <el-select v-model="comp.additionalDetails.sinBinInUse">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10" v-if="comp.additionalDetails.sinBinInUse">
          <el-form-item prop="durationOfSinBin" label="Duration of Sin Bin">
            <el-input
              autocomplete="off"
              placeholder="Enter duration of sin bin in minutes"
              type="number"
              v-model.number="comp.additionalDetails.durationOfSinBin"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item prop="sendOffInUse" label="Send off in use">
            <el-select v-model="comp.additionalDetails.sendOffInUse">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item
            prop="foulPlayInterchangeInUse"
            label="Foul Play Interchange in use"
          >
            <el-select
              v-model="comp.additionalDetails.foulPlayInterchangeInUse"
            >
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item
            prop="replacementPlayerInUse"
            label="Replacement Player in use"
          >
            <el-select v-model="comp.additionalDetails.replacementPlayerInUse">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 class="mt-2">Golden Point</h4>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="goldenPoint" label="Enable Golden Point">
            <el-select v-model="comp.additionalDetails.goldenPoint">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="comp.additionalDetails.goldenPoint">
          <el-form-item prop="goldenPointInRounds" label="Enable in Rounds">
            <el-select v-model="comp.additionalDetails.goldenPointInRounds">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="comp.additionalDetails.goldenPoint">
          <el-form-item prop="goldenPointInFinals" label="Enable in Finals">
            <el-select v-model="comp.additionalDetails.goldenPointInFinals">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 class="mt-2">Extra time</h4>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="extraTime" label="Enable Extra Time">
            <el-select v-model="comp.additionalDetails.extraTime">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="comp.additionalDetails.extraTime">
          <el-form-item prop="extraTimeInRounds" label="Enable in Rounds">
            <el-select v-model="comp.additionalDetails.extraTimeInRounds">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="comp.additionalDetails.extraTime">
          <el-form-item prop="extraTimeInFinals" label="Enable in Finals">
            <el-select v-model="comp.additionalDetails.extraTimeInFinals">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :span="12" class="mb-1">
          <el-form-item
            prop="judiciaryCodeOfProcedure"
            label="Judiciary Code of Procedure"
          >
            <el-input
              type="text"
              autocomplete="off"
              v-model="comp.additionalDetails.judiciaryCodeOfProcedure"
              placeholder=""
            >
              <template slot="prepend">https://</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="mb-1">
          <el-form-item
            prop="policiesAndGuidelines"
            label="Policies and Guidelines"
          >
            <el-input
              type="text"
              autocomplete="off"
              v-model="comp.additionalDetails.policiesAndGuidelines"
              placeholder=""
            >
              <template slot="prepend">https://</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="mb-1">
          <el-form-item prop="operationsManual" label="Operations Manual">
            <el-input
              type="text"
              autocomplete="off"
              v-model="comp.additionalDetails.operationsManual"
              placeholder=""
            >
              <template slot="prepend">https://</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="onFieldPolicy" label="On Field Policy">
            <el-select v-model="comp.additionalDetails.onFieldPolicy">
              <el-option
                label="Community (League First Aid, League Safe, Level 1, Level 2)"
                value="Community (League First Aid, League Safe, Level 1, Level 2)"
              />
              <el-option
                label="Elite, Pathways and Major Competitions (Level 1, Level 2, AMO, CMO)"
                value="Elite, Pathways and Major Competitions (Level 1, Level 2, AMO, CMO)"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons
      submit
      cancel
      @submit="updateAdditionalDetails()"
      @cancel="cancelForm()"
    />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg } from "../../utils/constants";
import { EventBus } from "../../bus";

export default {
  data() {
    const validateWebsite = (rule, val, callback) => {
      const expression = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const regex = new RegExp(expression);

      if (val) {
        if (val.length && !val.match(regex)) {
          return callback(new Error(errormsg.invalidUrl));
        }
      }
      return callback();
    };

    return {
      comp: undefined,
      interchangeCapacity: undefined,
      activeNames: [],
      options: Array.from({ length: 51 }, (_, index) => index), // Generates an array from 0 to 50
      emailList:
        this.comp &&
        this.comp.additionalDetails &&
        this.comp.additionalDetails.notificationList
          ? this.comp.additionalDetails.notificationList
          : [],
      reportList:
        this.comp &&
        this.comp.additionalDetails &&
        this.comp.additionalDetails.reportList
          ? this.comp.additionalDetails.reportList
          : [],
      notification: { newEmail: "", newReportEmail: "" },
      additionalDetailsRules: {
        judiciaryCodeOfProcedure: {
          validator: validateWebsite,
          trigger: "blur",
        },
        policiesAndGuidelines: {
          validator: validateWebsite,
          trigger: "blur",
        },
        operationsManual: {
          validator: validateWebsite,
          trigger: "blur",
        },
      },
    };
  },
  components: { ActionButtons },
  props: {
    competition: { type: Object, required: true },
  },
  async mounted() {
    const comp = {
        ...this.competition,
        additionalDetails: this.competition.additionalDetails || {
          interchangeCapacity: undefined,
          headInjuryAssessmentInUse: undefined,
          sinBinInUse: undefined,
          durationOfSinBin: undefined,
          sendOffInUse: undefined,
          foulPlayInterchangeInUse: undefined,
          replacementPlayerInUse: undefined,
          goldenPoint: undefined,
          goldenPointInRounds: undefined,
          goldenPointInFinals: undefined,
          extraTime: undefined,
          extraTimeInFinals: undefined,
          extraTimeInRounds: undefined,
          judiciaryCodeOfProcedure: undefined,
          policiesAndGuidelines: undefined,
          operationsManual: undefined,
          onFieldPolicy: undefined,
        },
      }
      this.comp = comp;
  },
  updated() {
    if (this.comp && this.comp.additionalDetails) {
      if (this.comp.additionalDetails.notificationList)
        this.emailList = this.comp.additionalDetails.notificationList;
      if (this.comp.additionalDetails.reportList)
        this.reportList = this.comp.additionalDetails.reportList;
    }
  },
  methods: {
    cancelForm() {
      this.$router.push({
        name: "competitions.list",
      });
    },
    updateAdditionalDetails() {
      this.$refs.additionalDetailsRules.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const update = {
            additionalDetails: {
              ...this.comp.additionalDetails,
            },
          };
          const url = `/nrl/api/v1/admin/competitions/${this.comp._id}`;
          this.$http
            .put(url, update)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
              EventBus.$emit("updateCompetition", response.data.data);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
      });
    },
  },
  computed: {
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}

.small-card {
  margin: 0.5rem;
}

.add-email {
  text-align: center;
}
</style>
