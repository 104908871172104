<template>
  <div>
    <el-row>
      <h2>Add or Update your Account Details</h2>
    </el-row>
    <el-row>
      <el-form
        label-position="top"
        id="accountDetails"
        :rules="rules"
        :model="current"
        ref="accountDetails"
      >
        <el-row :gutter="20" type="flex" align="bottom">
          <el-col :span="5">
            <el-form-item prop="bsb" label="BSB">
              <el-input autocomplete="off" v-model="current.bsb" placeholder="123XXX" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="number" label="Account Number">
              <el-input autocomplete="off" v-model="current.number" placeholder="456XXX123" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="name" label="Account Name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="current.name"
                placeholder="Account Name"
              />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="email" label="Email">
              <el-input
                type="email"
                autocomplete="off"
                v-model="current.email"
                placeholder="myname@example.com"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button type="success" @click="submitForm('accountDetails')">{{
                myaccount.length === 0 ? "Add" : "Update"
              }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <h2>Account Status</h2>
    </div>
    <el-row>
      <data-tables :data="myaccount" layout="table" class="data-table">
        <el-table-column prop="bank_account.bsb" label="BSB" width="150"> </el-table-column>
        <el-table-column prop="bank_account.number" label="Account No." width="150">
        </el-table-column>
        <el-table-column prop="bank_account.name" label="Account Name" width="200">
        </el-table-column>
        <el-table-column prop="email" label="Email" width="250"> </el-table-column>
        <el-table-column prop="kyc_status" label="KYC" width="150"> </el-table-column>
        <el-table-column prop="" label="" width="150">
          <el-button type="success" @click="refresh()">Refresh</el-button>
        </el-table-column>
      </data-tables>
    </el-row>
    <el-row v-if="missingKYC" class="missing">
      <h2 class="tw-py-6 tw-text-2xl tw-font-bold tw-text-red-500">Missing KYC</h2>
      <p class="tw-pb-4">
        Know Your Customer (KYC) refers to the process of verifying the identity of your customers,
        either before or during the time that they start doing business with you. The term “KYC”
        also references the regulated bank customer identity verification practices to assess and
        monitor customer risk.
        <br />
        <br />
        Please note you must complete the ID verification process to enable online payments to your
        bank account. Once completed use the refresh button above to update your KYC status.
      </p>
      <el-button type="success" @click="verify()">Verify</el-button>
    </el-row>
  </div>
</template>

<script>
import { errormsg } from "../../utils/constants";

const pinApi = new Pin.Api(process.env.VUE_APP_PIN_PK, process.env.VUE_APP_PIN_ENV);

export default {
  name: "AccountDetails",
  props: {},
  components: {},
  data() {
    const validateNumberOnly = (rule, value, callback) => {
      const reg = new RegExp("^[0-9]+$");
      if (!reg.test(value)) {
        callback(new Error("Please enter numbers only"));
      } else {
        callback();
      }
    };
    return {
      myaccount: [],
      current: {
        bsb: null,
        number: null,
        name: "",
        email: "",
      },
      rules: {
        bsb: [
          {
            required: true,
            validator: validateNumberOnly,
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            validator: validateNumberOnly,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: errormsg.input_option,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: errormsg.input_option,
            trigger: "blur",
          },
          {
            type: "email",
            message: errormsg.email,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http
      .get("/nrl/api/v1/admin/recipient")
      .then((response) => {
        const myaccount = response.data.data;
        if (myaccount) {
          this.myaccount = [myaccount];
        }
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const { email } = this.current;
          const bank = {
            name: this.current.name,
            bsb: this.current.bsb,
            number: this.current.number,
          };
          pinApi
            .createBankAccountToken(bank)
            .then((response) => {
              this.$http
                .post("/nrl/api/v1/admin/recipient", {
                  bank: response,
                  email,
                })
                .then(() => {
                  this.$http
                    .get("/nrl/api/v1/admin/recipient")
                    .then((recipient) => {
                      const myaccount = recipient.data.data;
                      if (myaccount) {
                        this.myaccount = [myaccount];
                      }
                      this.$store.commit("root/loading", false);
                    })
                    .catch(() => {
                      this.$store.commit("root/loading", false);
                      this.$customError();
                    });
                })
                .catch(() => {
                  this.$store.commit("root/loading", false);
                  this.$customError();
                });
            })
            .catch((err) => {
              this.$store.commit("root/loading", false);
              this.$customError(
                err.messages && err.messages.length > 0
                  ? err.messages[0].message
                  : err.error_description
              );
            })
            .done();
          return true;
        }
        return false;
      });
    },
    verify() {
      window.open(
        this.myaccount.length > 0 && this.myaccount[0].verification_url
          ? this.myaccount[0].verification_url
          : "https://www.nrl.com/",
        "_blank"
      );
    },
    refresh() {
      this.$store.commit("root/loading", true);
      this.$http
        .post("/nrl/api/v1/admin/recipient/refresh")
        .then(() => {
          this.$http
            .get("/nrl/api/v1/admin/recipient")
            .then((response) => {
              const myaccount = response.data.data;
              if (myaccount) {
                this.myaccount = [myaccount];
              }
              this.$store.commit("root/loading", false);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
  },
  computed: {
    missingKYC() {
      return this.myaccount.length > 0 && this.myaccount[0].kyc_status !== "verified";
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}
.missing {
  h2 {
    color: red;
  }

  p {
    font-family: $fontRegular;
    font-size: 12px;
  }
}
</style>
