import axios from "axios";
import moment from "moment";
import router from "../router";
import store from "../store";

// Require Auth Requests
const httpms = axios.create({
  baseURL: store.getters["root/msUrl"],
  headers: {
    "Content-Type": "application/json"
  }
});

// Handle Refresh Token
httpms.interceptors.request.use(
  config => {
    const originalRequest = config;
    const now = moment().valueOf();
    const expiry = store.getters["user/expiry"];
    const userid = store.getters["user/userid"];

    // Update Auth Header
    const accessToken = store.getters["user/accessToken"];
    const authheader = `${userid}:${accessToken}`;
    const encodedString = btoa(authheader);
    originalRequest.headers.Authorization = `Bearer ${encodedString}`;
    originalRequest.headers["active-Role"] = store.getters["user/activeRoleId"];
    originalRequest.headers["role"] = JSON.stringify(store.getters["user/activeRole"]);
    return originalRequest;
  },
  err => Promise.reject(err)
);

// Handle Global Error Handler
httpms.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response || { status: 520 };

    if (status === 401) {
      // Not Authorized, redirect to login
      store.commit("user/logout");
      store.commit("root/loading", false);
      router.go("/login");
    }

    if (status === 520) {
      // Adding an unknown error handler to catch all error responses with no error msg from API in future
      throw new Error(`An unexpected error happened: ${error}`);
    }

    return Promise.reject(error);
  }
);

export default httpms;
